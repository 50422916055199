import React, { MouseEventHandler } from "react";

import { CourseQuestionnaire } from "../../lib/types/course";
import { ButtonUI } from "../Button";
import { QuestionnairePreviewUI } from "../QuestionnairePreview";
import styles from "./QuestionnairePreviewModal.module.scss";

type QuestionnairePreviewModalUIProps = {
  questionnaires: CourseQuestionnaire[];
  visibility: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
};

const QuestionnairePreviewModalUI = ({
  questionnaires,
  visibility,
  onClose,
}: QuestionnairePreviewModalUIProps) => {
  return visibility ? (
    <div
      className="fixed z-20 inset-0 overflow-y-auto pl-24"
      data-testid="questionnaire-preview-modal"
    >
      <div className="flex justify-center pt-40 max-w-screen-md mx-auto">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="flex flex-col transform transition-all items-center">
          <div
            className={`bg-white  shadow-lg overflow-y-scroll ${styles.modalMaxHeight}`}
          >
            <QuestionnairePreviewUI questionnaires={questionnaires} />
          </div>
          <ButtonUI
            data-testid="questionnaire-preview-modal-btn"
            className="mt-10 bg-white"
            size="xs"
            buttonType="secondary"
            onClick={onClose}
          >
            閉じる
          </ButtonUI>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default QuestionnairePreviewModalUI;
