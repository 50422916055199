import React, { ButtonHTMLAttributes } from "react";

import style from "./ArrowButton.module.css";
import { ReactComponent as LeftArrow } from "./left-arrow.svg";
import { ReactComponent as RightArrow } from "./right-arrow.svg";

export type ArrowButtonProps = {
  arrowType: "left" | "right";
} & ButtonHTMLAttributes<HTMLButtonElement>;

const getArrowComponent = (arrowType: "left" | "right") => {
  return arrowType === "left" ? LeftArrow : RightArrow;
};

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  arrowType = "left",
  className,
  ...rest
}) => {
  const ArrowComponent = getArrowComponent(arrowType);
  return (
    <button className={`${style.arrowContanier} ${className}`} {...rest}>
      <ArrowComponent className={style.arrowSvg}></ArrowComponent>
    </button>
  );
};
