/**
 * 決済情報の不一致種別
 */
export enum PaymentMismatchType {
  /**
   * 決済ステータスの不一致
   */
  StatusMismatch = "statusMismatch",

  /**
   * 金額の不一致
   */
  PriceMismatch = "priceMismatch",

  /**
   * 決済処理日時の不一致
   */
  SoldDateTimeMismatch = "soldDateTimeMismatch",

  /**
   * 教育PFに決済情報が存在しない
   */
  NotExistInStudyPF = "notExistInStudyPF",

  /**
   * かんたん決済に決済情報が存在しない
   */
  NotExistInMPL = "notExistInMPL",

  /**
   * システムau IDの不一致
   */
  SystemAuIdMismatch = "systemAuIdMismatch",

  /**
   * 加盟店管理番号の不一致
   */
  MemberManageNoMismatch = "memberManageNoMismatch",
}

/**
 * 決済不整合の対応状況
 */
export enum PaymentMismatchStatus {
  /**
   * 未対応
   */
  Unhandled = "unhandled",

  /**
   * 対応中
   */
  Handling = "handling",

  /**
   * 対応済み
   */
  Done = "done",
}

export type PaymentMismatchStatusLabel = {
  id: string;
  value: string;
  label: string;
};

export const paymentMismatchStatuses: PaymentMismatchStatusLabel[] = [
  {
    id: "unhandled",
    value: PaymentMismatchStatus.Unhandled,
    label: "未対応",
  },
  {
    id: "handling",
    value: PaymentMismatchStatus.Handling,
    label: "対応中",
  },
  {
    id: "done",
    value: PaymentMismatchStatus.Done,
    label: "対応済",
  },
];

/**
 * かんたん決済日次 CSV のデータ種別
 */
export enum MplProcessedDataKind {
  /**
   * 後日売上取消、又は返金（全額）
   */
  CanceledOrRefundAll = "canceled_or_refund_all",

  /**
   * 返金（一部）
   * CSV に同一決済情報番号のデータが２件存在する。
   * １件は正負号欄が空白＝売上、もう１件は正負号欄がマイナス
   */
  RefundPart = "refund_part",

  /**
   * 売上
   */
  Sold = "sold",

  /**
   * 追加料金
   * 突合マニュアルにも「＋」のケースは記載されておらず、ドキュメントには
   * 【※プラスの場合は"+"が設定されます】とだけ記載されており、具体的に何を意味するのか不明。
   * 本システムでは発生し得ないデータと思われる。
   */
  AdditionalCharge = "aditional_charge",

  /**
   * 想定外
   * 同一決済情報番号で正負号が空白のデータが複数あるなどの想定されていないケース
   */
  Unexpected = "unexpected",
}
