import { saveAs } from "file-saver";

/**
 * @deprecated studypf-b2b-frontend/src/lib/utils/fileUtils.tsのfileSaveを使ってください
 */
export const FileSave = (url: string, name: string) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => saveAs(blob, name));
};
