import { PriceType, ContentsTargetType } from "../../generated/graphql";

export const targetGrade = [
  { value: "中学１年生", label: "中学１年生", order: 1 },
  { value: "中学２年生", label: "中学２年生", order: 2 },
  { value: "中学３年生", label: "中学３年生", order: 3 },
  { value: "高校１年生", label: "高校１年生", order: 4 },
  { value: "高校２年生", label: "高校２年生", order: 5 },
  { value: "高校３年生", label: "高校３年生", order: 6 },
  { value: "大学生", label: "大学生", order: 7 },
];

export const targetAges = Array.from(new Array(116)).map((v, i) => ({
  value: `${i + 5}`,
  label: `${i + 5}歳`,
  order: i,
}));

export const targetTypes = [
  { value: ContentsTargetType.Age, label: "年齢" },
  { value: ContentsTargetType.Grade, label: "学年" },
  { value: ContentsTargetType.None, label: "指定なし" },
];

export const prices = [
  { value: PriceType.Once, label: "従量課金" },
  { value: PriceType.Monthly, label: "月額課金" },
  { value: PriceType.Free, label: "無料" },
];

export const PriceTypeName = {
  [PriceType.Once]: "従量課金",
  [PriceType.Monthly]: "月額課金",
  [PriceType.Free]: "無料",
} as const;

export const targetOption: {
  [key: string]: [] | { value: string; label: string; order: number }[];
} = {
  [ContentsTargetType.None]: [],
  [ContentsTargetType.Age]: targetAges,
  [ContentsTargetType.Grade]: targetGrade,
};

export const contentDetail: {
  [key: string]: [] | { value: string; label: string }[];
} = {
  [ContentsTargetType.None]: [],
  [ContentsTargetType.Age]: targetAges,
  [ContentsTargetType.Grade]: targetGrade,
};
