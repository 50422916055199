import React, {
  useRef,
  forwardRef,
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  useEffect,
  useState,
} from "react";

import { Link, LinkProps, useLocation } from "react-router-dom";

import { ServiceKind } from "../../generated/graphql";
import { useCurrentServiceKind } from "../../lib/apollo-client";
import { ReactComponent as ArrowDown } from "../arrow-down.svg";
import { ReactComponent as ArrowUp } from "../arrow-up.svg";
import styles from "./SideBarMenuItem.module.css";

interface SideBarProps {
  title: string;
  opened: boolean;
  currentServiceKindKey?: ServiceKind;
  onClickMenuItem: (open: boolean) => void;
  onClickSubMenuItem?: () => void;
  items: SideBarMenuSubItem[];
}

export interface SideBarMenuSubItem {
  title: string;
  to: LinkProps["to"];
  availableServices: ServiceKind[];
}

type AnchorLinkProps = {
  navigate: any;
} & DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  (props, ref) => {
    const { navigate, ...rest } = props;
    return (
      <a
        ref={ref}
        {...rest}
        onClick={(e) => {
          e.preventDefault();
          navigate();
          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  }
);

const SideBarMenuItemUI: React.FC<SideBarProps> = ({
  title,
  opened,
  items,
  onClickMenuItem,
  onClickSubMenuItem,
}) => {
  const toggleAccordion = (open: boolean) => {
    onClickMenuItem(open);
  };
  const location = useLocation();
  const currentServiceKind = useCurrentServiceKind();

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  useEffect(() => {
    const scrollHeight = ref.current?.scrollHeight || 0;
    setHeight(scrollHeight);
  }, [currentServiceKind?.key, ref.current?.scrollHeight, setHeight]);

  return (
    <li className={`${styles.item}`}>
      {title === "Top" ? (
        <Link to="/">
          <div
            className={`${styles.mainItem} ${styles.closedMainItem}`}
            onClick={() => toggleAccordion(!opened)}
          >
            <div className="flex-grow">{title}</div>
          </div>
        </Link>
      ) : (
        <div
          className={`${styles.mainItem} ${
            opened ? styles.openedMainItem : styles.closedMainItem
          }`}
          onClick={() => toggleAccordion(!opened)}
        >
          <div className="flex-grow">{title}</div>
          <div className="flex-none ml-3">
            {opened ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      )}
      <div
        ref={ref}
        className={`relative overflow-hidden transition-all duration-300 ${
          opened ? "translate-y-0" : "-translate-y-full"
        }`}
        style={{ maxHeight: opened ? height : 0 }}
      >
        {items
          .filter(
            (item) =>
              currentServiceKind?.key &&
              item.availableServices.includes(currentServiceKind?.key)
          )
          .map((item) => (
            <div key={item.title}>
              <Link
                to={item.to}
                className={`${styles.subItem} ${
                  location.pathname === item.to ? styles.active : ""
                }`}
                component={AnchorLink}
                onClick={onClickSubMenuItem}
              >
                {item.title}
              </Link>
            </div>
          ))}
      </div>
    </li>
  );
};
export default SideBarMenuItemUI;
