import React from "react";

import { VideoLessonRatingTableRowFragment } from "../../../../generated/graphql";
import { VideoRatingTableUI } from "./VideoRatingTable";

export type VideoLessonRatingTableRowProps = {
  lessonRowData: VideoLessonRatingTableRowFragment;
  index: number;
};

const style = {
  cell: "border-r border-dark-gray pl-2 pr-1",
};

/** 評価点数の最大値 */
const MAX_RATING = 5;

const VideoLessonRatingTableRowUI: React.VFC<
  VideoLessonRatingTableRowProps
> = ({ lessonRowData, index }) => {
  const { videoLesson } = lessonRowData;

  return (
    <tr
      data-testid="lesson-rating-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className={`${style.cell}`} data-testid="lesson-rating-table-row-id">
        {videoLesson.id}
      </td>
      <td
        className={`${style.cell}`}
        data-testid="lesson-rating-table-row-name"
      >
        {videoLesson.name}
      </td>
      <td
        className={`${style.cell}`}
        data-testid="lesson-rating-table-expert-name"
      >{`${videoLesson.expert.familyName} ${videoLesson.expert.givenName}`}</td>
      <td className={`${style.cell} py-2`}>
        <VideoRatingTableUI lessonRowData={lessonRowData} />
      </td>
      <td
        className={`${style.cell} pl-2`}
        data-testid="lesson-rating-table-row-rating"
      >
        {lessonRowData.rating == null
          ? "-"
          : `${Number(lessonRowData.rating).toFixed(1)} / ${MAX_RATING}`}
      </td>
    </tr>
  );
};

export default VideoLessonRatingTableRowUI;
