import React, { TextareaHTMLAttributes } from "react";

import { RegisterParams } from "../common-interfaces";
import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";

/** @deprecated 代わりに studypf-b2b-frontend/src/components/ValidatableTextarea/NewValidatableTextarea.tsx を使用してください */
export type ValidatableTextareaUIProps = {
  registerParams: RegisterParams;
  errorIdPrefix?: string;
  isCheckIcon?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

/** @deprecated 代わりに studypf-b2b-frontend/src/components/ValidatableTextarea/NewValidatableTextarea.tsx を使用してください */
export const ValidatableTextareaUI: React.FC<ValidatableTextareaUIProps> = ({
  className = "",
  registerParams,
  errorIdPrefix,
  children,
  isCheckIcon = true,
  ...rest
}) => {
  return (
    <div>
      <div className="flex items-center space-x-3">
        {registerParams.error && isCheckIcon && (
          <ErrorCheckIcon data-testid={`${errorIdPrefix}-icon`} />
        )}
        <textarea
          className={`leading-5 pl-4 tracking-wider border rounded ${className} ${
            registerParams.error ? "border-error" : ""
          }`}
          {...registerParams.register(registerParams.label, {
            ...registerParams.conditions,
          })}
          {...rest}
        />
      </div>
      {registerParams.error && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`${errorIdPrefix}-message`}
        >
          {registerParams.error.message}
        </p>
      )}
    </div>
  );
};
