import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  useState,
} from "react";

import dayjs from "dayjs";

import { DisplayLesson } from "../../lib/constants/classes";
import { range } from "../../lib/functions";
import { RegisterParams } from "../common-interfaces";
import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";
import { ReactComponent as CalendarIcon } from "./calendar.svg";

export type LessonDateFormUIProps = {
  lesson: DisplayLesson;
  registerParams: RegisterParams;
  errorIdPrefix?: string;
  changeLessonDateInputs: (id: string, value: DateInput) => void;
} & InputHTMLAttributes<HTMLInputElement>;

type DateInputType =
  | "year"
  | "month"
  | "date"
  | "fromhour"
  | "fromminute"
  | "tohour"
  | "tominute";

export type DateInput = { [key in DateInputType]: number };

// monthとdateは0はあり得ないが、入力中は0も許容しておかないと使い勝手が悪くなるため配列に含めている
const dateRange = {
  year: range(0, 9999),
  month: range(0, 12),
  date: range(0, 31),
  fromhour: range(0, 23),
  tohour: range(0, 23),
  fromminute: range(0, 59),
  tominute: range(0, 59),
};

const getDateInputRange = (dateType: DateInputType): number[] => {
  return dateRange[dateType];
};

export const LessonDateFormUI: React.FC<LessonDateFormUIProps> = ({
  className = "",
  lesson,
  registerParams,
  errorIdPrefix,
  changeLessonDateInputs,
  children,
  ...rest
}) => {
  const startedAt = dayjs(lesson.startedAt);
  const endedAt = dayjs(lesson.endedAt);
  const [editingLessonDate, setEditingLessonDate] = useState<DateInput>({
    year: startedAt.year(),
    month: startedAt.month() + 1,
    date: startedAt.date(),
    fromhour: startedAt.hour(),
    fromminute: startedAt.minute(),
    tohour: endedAt.hour(),
    tominute: endedAt.minute(),
  });

  const convertDate = useCallback(
    (type: DateInputType): string => {
      return `${editingLessonDate[type]}`.padStart(2, "0");
    },
    [editingLessonDate]
  );

  const onChangeDateInputs: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const keys = event.target.id.split("-");
      const dateType = keys[0] as DateInputType;
      const valueNum = Number(event.target.value);

      if (!getDateInputRange(dateType).includes(valueNum)) return;

      setEditingLessonDate((prev) => ({
        ...prev,
        [dateType]: valueNum,
      }));
    },
    []
  );
  const onBlur = () => {
    // monthとdateは入力欄からfocusが外れるまでは0を許容するためここでチェックしている
    setEditingLessonDate((prev) => ({
      ...prev,
      month: prev.month === 0 ? 1 : prev.month,
      date: prev.date === 0 ? 1 : prev.date,
    }));
    changeLessonDateInputs(lesson.lessonMasterId, editingLessonDate);
  };

  const errorBorder = registerParams.error ? "border-error" : "";
  return (
    <div className="flex">
      {registerParams.error && (
        <ErrorCheckIcon
          data-testid={`${errorIdPrefix}-icon`}
          className="mt-2.5 mr-4"
        />
      )}
      <CalendarIcon className="mt-1.5 mr-2.5" />
      <div className="flex flex-col">
        <div className="flex flex-row items-center space-x-2.5">
          <input
            value={`${lesson.startedAt}-${lesson.endedAt}`}
            className="hidden"
            {...registerParams.register(registerParams.label, {
              ...registerParams.conditions,
            })}
            {...rest}
          />

          <input
            id={`year-${lesson.lessonMasterId}`}
            type="number"
            data-testid={`lesson-date-form-year-${lesson.lessonMasterId}`}
            placeholder="YYYY"
            min="1"
            value={convertDate("year")}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-20 ${errorBorder}`}
            onChange={onChangeDateInputs}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <div>/</div>
          <input
            type="number"
            placeholder="MM"
            data-testid={`lesson-date-form-month-${lesson.lessonMasterId}`}
            min="1"
            max="12"
            id={`month-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("month")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <div>/</div>
          <input
            type="number"
            data-testid={`lesson-date-form-date-${lesson.lessonMasterId}`}
            placeholder="DD"
            min="1"
            max={dayjs(
              new Date(editingLessonDate.year, editingLessonDate.month - 1)
            ).daysInMonth()}
            id={`date-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("date")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <input
            type="number"
            data-testid={`lesson-date-form-from-hour-${lesson.lessonMasterId}`}
            placeholder="HH"
            min="0"
            max="23"
            id={`fromhour-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("fromhour")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <div>:</div>
          <input
            type="number"
            data-testid={`lesson-date-form-from-minute-${lesson.lessonMasterId}`}
            placeholder="MM"
            min="0"
            max="59"
            id={`fromminute-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("fromminute")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <div>〜</div>
          <input
            type="number"
            data-testid={`lesson-date-form-to-hour-${lesson.lessonMasterId}`}
            placeholder="HH"
            min="0"
            max="23"
            id={`tohour-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("tohour")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
          <div>:</div>
          <input
            type="number"
            data-testid={`lesson-date-form-to-minute-${lesson.lessonMasterId}`}
            placeholder="MM"
            min="0"
            max="59"
            id={`tominute-${lesson.lessonMasterId}`}
            className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14 ${errorBorder}`}
            onChange={onChangeDateInputs}
            value={convertDate("tominute")}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            onBlur={onBlur}
          />
        </div>
        <span className="mt-1.5">半角英数入力</span>
        {registerParams.error && (
          <p
            className="text-error mt-1.5"
            data-testid={`${errorIdPrefix}-message`}
          >
            {registerParams.error.message}
          </p>
        )}
      </div>
    </div>
  );
};
