import React from "react";

import {
  ParticipantTableRowFragment,
  ParticipantTableFragment,
} from "../../../../generated/graphql";
import ParticipantItemUI from "./ParticipantsItem";

export type ParticipantsUIProps = {
  participants: ParticipantTableFragment;
};

export const ParticipantsUI: React.FC<ParticipantsUIProps> = ({
  participants,
}) => {
  return (
    <table className="border-collapse border border-dark-gray">
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-12">
          <th className="border border-dark-gray text-center w-16">項番</th>
          <th className="border border-dark-gray text-center w-28">
            お客様番号
          </th>
          <th className="border border-dark-gray text-center w-32">性別</th>
          <th className="border border-dark-gray text-center w-16">年齢</th>
          <th className="border border-dark-gray pl-5 w-32">申込日</th>
        </tr>
      </thead>
      <tbody>
        {participants.nodes.map(
          (participant: ParticipantTableRowFragment, index: number) => (
            <ParticipantItemUI
              key={participant.customerId}
              index={index}
              participant={participant}
            ></ParticipantItemUI>
          )
        )}
      </tbody>
    </table>
  );
};
