import React, { HTMLAttributes } from "react";

type RadioMarkUIProps = HTMLAttributes<HTMLElement>;

export const RadioMarkUI: React.FC<RadioMarkUIProps> = ({
  className,
  ...rest
}) => {
  return (
    <div
      className={`w-5 h-5 border-2 border-dark-gray rounded-full bg-white ${className}`}
      {...rest}
    ></div>
  );
};
