import React, {
  InputHTMLAttributes,
  MouseEventHandler,
  ChangeEventHandler,
} from "react";

import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as SearchIcon } from "./search.svg";

export type CoursesSearchInputProps = {
  onClickSearch: MouseEventHandler;
  onClickDelete: MouseEventHandler;
  onChange: ChangeEventHandler;
} & InputHTMLAttributes<HTMLInputElement>;

const CoursesSearchInputUI: React.FC<CoursesSearchInputProps> = ({
  className,
  onClickSearch,
  onClickDelete,
  onChange,
  ...rest
}) => {
  return (
    <div className={`flex border-2 rounded h-12 ${className}`}>
      <SearchIcon
        className="flex-none h-full mx-3 cursor-pointer"
        data-testid="courses-search-icon"
        onClick={(e) => onClickSearch(e)}
      />
      <input
        className="flex-grow h-full border-l-2 pl-3 outline-none"
        type="text"
        placeholder="システムID/コース名を入力"
        data-testid="courses-search-input"
        onChange={(e) => onChange(e)}
        {...rest}
      />
      <DeleteIcon
        className="h-full mr-5 cursor-pointer"
        data-testid="courses-delete-icon"
        onClick={(e) => onClickDelete(e)}
      />
    </div>
  );
};

export default CoursesSearchInputUI;
