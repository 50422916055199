export const preventFromSubmittingByEnter = (event: React.KeyboardEvent) => {
  event.key === "Enter" &&
    (event.target as HTMLElement).tagName !== "TEXTAREA" &&
    event.preventDefault();
};

export const setLocation = (href: string) => {
  window.location.href = href;
};

export const range = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }).map((_, k) => k + start);
};
