import React, { MouseEventHandler, ReactNode } from "react";

import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";

export type ErrorModalUIProps = {
  title: string;
  btnTitle: string | ReactNode;
  visibility: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ErrorModalUI: React.FC<ErrorModalUIProps> = ({
  title,
  btnTitle,
  visibility,
  onClick,
  children,
}) => {
  return (
    <ModalUI
      data-testid="error-modal"
      visibility={visibility}
      className="w-112"
    >
      <div className="inline-block px-5 py-5 text-center">
        <h1
          className="text-center text-error text-lg font-bold tracking-wider"
          data-testid="error-modal-title"
        >
          {title}
        </h1>
        <hr className="border-error mt-4"></hr>
        <div
          className="mt-4 tracking-wider"
          data-testid="error-modal-description"
        >
          {children}
        </div>
        <ButtonUI
          data-testid="error-modal-btn"
          buttonType="secondary"
          className="px-2 mt-10"
          size="auto"
          onClick={onClick}
        >
          {btnTitle}
        </ButtonUI>
      </div>
    </ModalUI>
  );
};
