import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useForm, ValidateResult } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { ButtonUI } from "../../../components/Button";
import { RegisterParams } from "../../../components/common-interfaces";
import CourseMultiSelectorUI from "../../../components/CourseMultiSelector/CourseMultiSelector";
import { ReactComponent as ErrorCheckIcon } from "../../../components/errorCheck.svg";
import { LabelUI } from "../../../components/Label";
import { RadioUI } from "../../../components/Radio";
import { ValidatableInputUI } from "../../../components/ValidatableInput";
import { ValidatableTextareaUI } from "../../../components/ValidatableTextarea";
import {
  DiscountType,
  useDiscountByCodeOnNewQuery,
  useMaximumDiscountAmountQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import styles from "./DiscountEdit.module.css";
import { validatePriceValueNoTax } from "./validator";

dayjs.extend(utc);
dayjs.extend(tz);

export interface InternalDiscountCourse {
  id: string;
  systemId: string;
  name: string;
}

export type EditingDiscount = {
  id?: string;
  type: DiscountType;
  code?: string;
  name?: string;
  description?: string;
  startedAt?: Date;
  endedAt?: Date;
  durationMonths?: number;
  priceValueNoTax?: number;
  priceValue?: number;
  limitedCourses?: InternalDiscountCourse[];
};

export type DiscountEditUIProps = {
  isNew: boolean;
  title: string;
  discount?: EditingDiscount;
  onConfirm: (values: EditingDiscount) => void;
};

type DiscountEditForm = {
  code: string;
  name: string;
  type: DiscountType;
  description?: string;
  startYMD: string;
  startTime: string;
  endYMD: string;
  endTime: string;
  durationMonths?: number;
  priceValueNoTax: number;
  limitedCourses?: InternalDiscountCourse[];
};

const DiscountEditUI: React.FC<DiscountEditUIProps> = ({
  isNew,
  title,
  discount,
  onConfirm,
}) => {
  const history = useHistory();
  // 現在日時
  const [currentDate] = useState<Date>(new Date());
  const [maximumDiscountAmount, setMaximumDiscountAmount] = useState<number>(0);
  // 登録後は変更できません or 変更できません
  const labelAnnotation: string = useMemo((): string => {
    return isNew ? "※ 登録後は変更できません" : "※ 編集不可";
  }, [isNew]);

  // リベラリーのサービス種別は3
  const serviceKindId = "3";

  useMaximumDiscountAmountQuery({
    variables: {
      serviceKindId: serviceKindId,
    },
    onCompleted: (result) => {
      setMaximumDiscountAmount(result.maximumDiscountAmount);
    },
  });

  // 編集データ
  const [data, setData] = useState<DiscountEditForm>({
    code: discount?.code || "",
    name: discount?.name || "",
    type: discount?.type || DiscountType.Coupon,
    description: discount?.description || "",
    startYMD:
      discount && discount?.startedAt
        ? dayjs(discount?.startedAt).tz("Asia/Tokyo").format("YYYY/MM/DD")
        : dayjs(currentDate).tz("Asia/Tokyo").add(1, "d").format("YYYY/MM/DD"),
    startTime:
      discount && discount?.startedAt
        ? dayjs(discount?.startedAt).tz("Asia/Tokyo").format("HH:mm")
        : "00:00",
    endYMD:
      discount && discount.endedAt
        ? dayjs(discount?.endedAt).tz("Asia/Tokyo").format("YYYY/MM/DD")
        : dayjs(currentDate)
            .tz("Asia/Tokyo")
            .add(1, "d")
            .add(1, "M")
            .format("YYYY/MM/DD"),
    endTime:
      discount && discount.endedAt
        ? dayjs(discount?.endedAt).tz("Asia/Tokyo").format("HH:mm")
        : "23:59",
    durationMonths: discount?.durationMonths,
    priceValueNoTax: discount?.priceValueNoTax || 0,
    limitedCourses: discount?.limitedCourses || [],
  });

  // 割引種別
  const discountTypeLabel: { [enumValue: string]: string } = {
    [DiscountType.Coupon]: "クーポン（従量）",
    [DiscountType.CourseCoupon]: "コース限定クーポン（従量）",
    [DiscountType.InitialCoupon]: "初月割引クーポン（月額）",
    [DiscountType.PlanCoupon]: "クーポン（月額）",
  };

  const [internalSelectedCourses, setInternalSelectedCourses] = useState<
    InternalDiscountCourse[] | undefined
  >(undefined);

  /**
   * 内部保持用コース・リストの初期値を作成
   * ※ 初回１回のみ
   */
  useEffect(() => {
    if (internalSelectedCourses === undefined) {
      setInternalSelectedCourses(discount?.limitedCourses || []);
    }
  }, [discount?.limitedCourses, internalSelectedCourses]);

  // 項目別最大文字数
  const maxLenDict: {
    code: number;
    name: number;
    durationMonths: number;
    description: number;
  } = {
    code: 20,
    name: 20,
    durationMonths: 2,
    description: 100,
  };

  // 項目別エラー・メッセージ
  const fieldMsgDict: {
    code: string;
    name: string;
    durationMonths: string;
    priceNoTax: string;
    priceNoTaxDiscount: string;
    description: string;
  } = {
    code: `${maxLenDict.code}文字以内の半角英数字で登録してください。`,
    name: `${maxLenDict.name}文字以内で登録してください。`,
    durationMonths: `${maxLenDict.durationMonths}桁以内の数字で登録してください。`,
    priceNoTax: `100円単位で入力してください。`,
    priceNoTaxDiscount: `初月割引クーポン（月額）は、月額金額未満を入力してください。`, // ここも変える必要ありそう
    description: `${maxLenDict.description}文字以内で登録してください。`,
  };

  /*********************
   * Form Validator
   *********************/
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    trigger,
    setError,
  } = useForm<DiscountEditForm>({
    mode: "onSubmit",
    defaultValues: {
      ...data,
    },
  });

  /**
   * 年月日書式チェック
   * @param value
   * @returns
   */
  const validateYMD = useCallback((value: string): boolean => {
    if (!value) {
      return false;
    }
    const ymd: string[] = value.split("/");
    if (ymd.length !== 3) {
      return false;
    }
    try {
      const d = new Date(`${ymd[0]}-${ymd[1]}-${ymd[2]}`);
      if (isNaN(d.getTime())) {
        // invalid date
        return false;
      }
      return true;
    } catch {
      // ここには入らないかも。念の為.

      return false;
    }
  }, []);

  /**
   * 時刻書式チェック
   * @param value
   * @returns
   */
  const validateTime = useCallback(
    (value: string): boolean => {
      if (!value) {
        return false;
      }
      const hhmm: string[] = value.split(":");
      if (hhmm.length !== 2) {
        return false;
      }
      try {
        const hours: number = parseInt(hhmm[0]);
        const minutes: number = parseInt(hhmm[1]);
        if (!hhmm[0] || isNaN(hours) || !hhmm[1] || isNaN(minutes)) {
          return false;
        }
        const ymd: string = dayjs(currentDate)
          .tz("Asia/Tokyo")
          .format("YYYY-MM-DD");
        const d = new Date(`${ymd}T${hhmm[0]}:${hhmm[1]}:00.000+09:00`);
        if (isNaN(d.getTime())) {
          // invalid date
          return false;
        }
        return true;
      } catch {
        // invalid time
        return false;
      }
    },
    [currentDate]
  );

  /**
   * 発効日時と終了日時の文字列を Date に変換する
   * ymd: YYYY/MM/DD
   * hhmm: H:m
   * seconds: 00 or 59
   * milliSeconds: 000 or 999 ※ 3桁必須
   */
  const converToDate = useCallback(
    (
      ymd: string,
      hhmm: string,
      seconds: string,
      milliSeconds: string
    ): Date => {
      return dayjs
        .tz(
          `${ymd} ${hhmm}:${seconds}.${milliSeconds}`,
          "YYYY/M/D H:m:s.SSS",
          "Asia/Tokyo"
        )
        .toDate();
    },
    []
  );

  /**
   * 発効日時 < 終了日時 ヴァリデーション
   */
  const [startDate, setStartDate] = useState<Date | null>(
    discount?.startedAt ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    discount?.endedAt ?? null
  );
  const validateStartEndTerm = useCallback(() => {
    if (!startDate || !endDate) {
      // 日時未確定
      return;
    }
    if (startDate.getTime() < endDate.getTime()) {
      // 発効日時より終了日時の方が未来
      return;
    }
    // 発効日時より終了日時の方が過去
    setError("endYMD", {
      message: "終了日時は発効日時よりも未来に設定して下さい。",
    });
  }, [endDate, setError, startDate]);

  /**
   * validateStartDate
   * 発効日時チェック
   */
  const validateStartDate = useCallback(
    (withTime: boolean): string | undefined => {
      if (withTime) {
        if (!data.startYMD) {
          return undefined;
        }
        if (data.startYMD && !validateYMD(data.startYMD)) {
          return undefined;
        }
      }
      const values = getValues();
      const ymd: string = values.startYMD;
      const time: string = values.startTime;
      let d: Date;
      clearErrors("startYMD");
      clearErrors("startTime");
      setStartDate(null);
      try {
        if (withTime) {
          d = converToDate(ymd, time, "00", "000");
        } else {
          d = converToDate(ymd, "23:59", "59", "999");
        }
        if (!d) {
          throw new Error("invalid date format");
        }
        if (d.getTime() < currentDate.getTime()) {
          return "現在より未来の日時を設定してください。";
        }
        if (!withTime) {
          trigger("startTime");
        } else {
          setStartDate(d);
          validateStartEndTerm();
        }
        return undefined;
      } catch (e) {
        // invalid date
        // 他のヴァリデーションでHITするので、ここでは何もしない。
        return undefined;
      }
    },
    [
      clearErrors,
      converToDate,
      currentDate,
      data.startYMD,
      getValues,
      trigger,
      validateStartEndTerm,
      validateYMD,
    ]
  );

  /**
   * validateEndDate
   * 発効日時チェック
   */
  const validateEndDate = useCallback(
    (withTime: boolean): string | undefined => {
      if (withTime) {
        if (!data.endYMD) {
          return undefined;
        }
        if (data.endYMD && !validateYMD(data.endYMD)) {
          return undefined;
        }
      }
      const values = getValues();
      const ymd: string = values.endYMD;
      const time: string = values.endTime;
      let d: Date;
      clearErrors("endYMD");
      clearErrors("endTime");
      setEndDate(null);
      try {
        if (withTime) {
          d = converToDate(ymd, time, "59", "999");
        } else {
          d = converToDate(ymd, "23:59", "59", "999");
        }
        if (!d) {
          throw new Error("invalid date format");
        }
        if (d.getTime() < currentDate.getTime()) {
          return "現在より未来の日時を設定してください。";
        }
        if (!withTime) {
          trigger("endTime");
        } else {
          setEndDate(d);
          validateStartEndTerm();
        }
        return undefined;
      } catch {
        // invalid date
        // 他のヴァリデーションでHITするので、ここでは何もしない。
        return undefined;
      }
    },
    [
      clearErrors,
      converToDate,
      currentDate,
      data.endYMD,
      getValues,
      trigger,
      validateStartEndTerm,
      validateYMD,
    ]
  );

  /**
   * onSubmit
   */
  const submitProxy = useCallback(() => {
    const { startYMD, startTime, endYMD, endTime } = getValues();

    // コース限定クーポン時にコースが選択されているかをチェック
    if (
      data.type === DiscountType.CourseCoupon &&
      (!data.limitedCourses || data.limitedCourses.length === 0)
    ) {
      setError(
        "limitedCourses",
        { message: "コースを選択して下さい" },
        { shouldFocus: true }
      );
      return;
    }

    const startedAt: Date = converToDate(startYMD, startTime, "00", "000");
    const endedAt: Date = converToDate(endYMD, endTime, "59", "999");
    const state: EditingDiscount = {
      id: discount && discount.id,
      type: data.type as DiscountType,
      code: data.code,
      name: data.name,
      startedAt: startedAt,
      endedAt: endedAt,
      durationMonths: data.durationMonths,
      priceValueNoTax: data.priceValueNoTax,
      description: data.description,
      limitedCourses:
        data.type === DiscountType.CourseCoupon
          ? data.limitedCourses
          : undefined,
    };
    history.replace(history.location.pathname, state);
    if (isNew) {
      onConfirm(state);
    } else {
      if (!(discount && discount.id)) {
        return;
      }
      onConfirm(state);
    }
  }, [
    converToDate,
    data.code,
    data.description,
    data.durationMonths,
    data.limitedCourses,
    data.name,
    data.priceValueNoTax,
    data.type,
    discount,
    getValues,
    history,
    isNew,
    onConfirm,
    setError,
  ]);

  /**
   * コードの重複チェック
   */
  const handleErrorRouter = useErrorRouter();
  // Lazy では2回目以降のリクエスト await できない（no-cache でも前回のレスポンスが即時に返る）
  const { refetch } = useDiscountByCodeOnNewQuery({
    variables: {
      code: getValues().code,
    },
    // この skip がみそ。skip が true の場合はリクエストしない
    // Lazy を使わず手動でリクエストをトリガーし、かつ await を有効にするには
    // この skip と refetch を利用する
    skip: isNew === false || !getValues().code,
    onError: handleErrorRouter,
    fetchPolicy: "no-cache",
  });
  const checkCodeDuplication = useCallback(
    async (code: string) => {
      try {
        const res = await refetch({ code });
        if (res && res.data && res.data.discountByCode) {
          return `コード『${code}』は既に登録されています。`;
        } else {
          return undefined;
        }
      } catch {
        // 重複チェックAPIでエラーが発生した際もメッセージを返してonSubmitが呼び出されないようにする
        return "コードの重複チェックでエラーが発生しました。再度お試し下さい。";
      }
    },
    [refetch]
  );

  // 変数の初期化を待つ
  if (!internalSelectedCourses) {
    return <></>;
  }

  return (
    <>
      <div className="mb-40">
        <h1 className="text-2xl mb-2" data-testid="discount-editUI-title">
          {title}
        </h1>
        <hr className="border-gray border mb-2"></hr>

        <div className="">
          {/* FormGroupUI を使用すると配置がデザイン通りにならないので使用しない */}
          <form onSubmit={handleSubmit(submitProxy)}>
            {/* クーポンタイプ */}
            <div className="flex flex-col align-middle mt-5">
              <div className="flex items-center">
                <LabelUI className={`flex ${styles.formLabel}`}>
                  クーポンタイプ
                </LabelUI>
              </div>
              <div
                className={`flex flex-row pt-4 text-base space-x-7`}
                data-testid="discount-editUI-discountType-box"
              >
                {Object.values(DiscountType).map((type) => {
                  return (
                    <RadioUI
                      name="discount-new-discountType"
                      data-testid={`discount-new-discountType-${type}`}
                      id={`discount-new-discountType-${type}`}
                      key={`discount-new-discountType-${type}`}
                      value={type}
                      checked={data.type === type}
                      onChange={() => {
                        setValue("type", type);
                        setData({
                          ...data,
                          limitedCourses:
                            type === DiscountType.Coupon
                              ? []
                              : internalSelectedCourses,
                          type: type,
                        });
                      }}
                    >
                      {discountTypeLabel[type]}
                    </RadioUI>
                  );
                })}
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* コース選択 */}
            {data.type === DiscountType.CourseCoupon && (
              <>
                <div className={`flex flex-col align-middle mt-6 ml-0`}>
                  <div className="flex items-center">
                    <LabelUI className={`flex ${styles.formLabel}`}>
                      対象コース
                    </LabelUI>
                  </div>
                  <div
                    className={`flex flex-col pt-4`}
                    data-testid="discount-editUI-limitedCourses-box"
                  >
                    <CourseMultiSelectorUI
                      testIdPrefix="discount-edit-courses"
                      values={data.limitedCourses}
                      onSelectedCoursesChange={(newList) => {
                        if (newList && newList.length) {
                          clearErrors("limitedCourses");
                        }
                        setValue("limitedCourses", newList);
                        setData({
                          ...data,
                          limitedCourses: newList,
                        });
                        setInternalSelectedCourses(newList);
                      }}
                    />
                    {(!data.limitedCourses ||
                      data.limitedCourses.length === 0 ||
                      errors.limitedCourses !== undefined) && (
                      <div
                        className={`${styles.inputAnnotationBox}`}
                        data-testid="discount-editUI-limitedCourseIds-note"
                      >
                        <span
                          className={`${
                            errors.limitedCourses === undefined
                              ? styles.inputAnnoutationText
                              : "text-error"
                          }`}
                        >
                          限定するコースを選択して下さい。
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <hr
                  className={`border-gray border mt-5 ${
                    data.type === DiscountType.CourseCoupon ? "" : "hidden"
                  }`}
                ></hr>
              </>
            )}

            {/* クーポンコード */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <div className="flex items-center">
                <LabelUI className={`flex ${styles.formLabel}`}>
                  クーポンコード
                </LabelUI>
                <div
                  className={`flex ml-2 items-center ${styles.labelAnnotationBox}`}
                >
                  <span
                    className={`${styles.labelAnnotationText}`}
                    data-testid="discount-editUI-code-label-annotation"
                  >
                    {labelAnnotation}
                  </span>
                </div>
              </div>
              <div className={`flex flex-col pt-4`}>
                <ValidatableInputUI
                  data-testid="discount-editUI-code"
                  errorIdPrefix="discount-editUI-code-validation"
                  registerParams={{
                    register,
                    label: "code",
                    error: errors.code,
                    conditions: {
                      required: fieldMsgDict.code,
                      pattern: {
                        value: new RegExp(
                          `^([a-zA-Z0-9]{1,${maxLenDict.code}})$`,
                          "g"
                        ),
                        message: fieldMsgDict.code,
                      },
                      validate: async (value): Promise<string | undefined> => {
                        if (isNew && value !== null && value !== undefined) {
                          // 新規登録時はコードの重複チェックをする
                          return await checkCodeDuplication(value);
                        } else {
                          return undefined;
                        }
                      },
                    },
                  }}
                  placeholder="KDDISPRING2204"
                  className={`${styles.formInput}`}
                  style={{ width: "600px" }}
                  disabled={!isNew}
                  value={data.code}
                  onChange={(event) =>
                    setData({ ...data, code: event.currentTarget.value })
                  }
                />
                {errors.code === undefined && (
                  <div
                    className={`${styles.inputAnnotationBox}`}
                    data-testid="discount-editUI-code-note"
                  >
                    <span className={`${styles.inputAnnoutationText}`}>
                      {fieldMsgDict.code}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* クーポン名 */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <LabelUI className={`flex ${styles.formLabel}`}>
                クーポン名
              </LabelUI>
              <div className={`flex flex-col pt-4`}>
                <ValidatableInputUI
                  data-testid="discount-editUI-name"
                  errorIdPrefix="discount-editUI-name-validation"
                  registerParams={{
                    register,
                    label: "name",
                    error: errors.name,
                    conditions: {
                      required: fieldMsgDict.name,
                      maxLength: {
                        value: maxLenDict.name,
                        message: fieldMsgDict.name,
                      },
                    },
                  }}
                  placeholder="GW限定クーポン"
                  className={`${styles.formInput}`}
                  style={{ width: "600px" }}
                  value={data.name}
                  onChange={(event) =>
                    setData({ ...data, name: event.currentTarget.value })
                  }
                />
                {errors.name === undefined && (
                  <div
                    className={`${styles.inputAnnotationBox}`}
                    data-testid="discount-editUI-name-note"
                  >
                    <span className={`${styles.inputAnnoutationText}`}>
                      {fieldMsgDict.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* 発効日時 */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <LabelUI className={`flex ${styles.formLabel}`}>発効日時</LabelUI>
              <div className={`flex flex-row pt-4`}>
                <ValidatableDateTimeUI
                  errorIdPrefix="discount-editUI-startDate-validation"
                  ymdProps={{
                    testid: "discount-editUI-startYMD",

                    registerparams: {
                      register,
                      label: "startYMD",
                      error: errors.startYMD,
                      conditions: {
                        required: "発効年月日を入力してください。",
                        validate: (value: string): ValidateResult => {
                          if (value === "" || value === null) {
                            return "発効年月日を入力してください。";
                          }
                          if (!validateYMD(value)) {
                            return "正しい年月日を入力してください。";
                          }
                          return validateStartDate(false);
                        },
                      },
                    },
                    placeholder: `${dayjs(currentDate)
                      .tz("Asia/Tokyo")
                      .format("YYYY/MM/DD")}`,
                    style: {
                      width: "132px",
                    },
                    value: data.startYMD,
                    onChange: (event) =>
                      setData({ ...data, startYMD: event.currentTarget.value }),
                  }}
                  timeProps={{
                    testid: "discount-editUI-startTime",
                    registerparams: {
                      register,
                      label: "startTime",
                      error: errors.startTime,
                      conditions: {
                        required: "発効時刻を入力してください。",
                        validate: (value: string): ValidateResult => {
                          if (!validateTime(value)) {
                            return "正しい時刻を入力してください。";
                          }
                          return validateStartDate(true);
                        },
                      },
                    },
                    placeholder: `${dayjs(currentDate)
                      .tz("Asia/Tokyo")
                      .format("HH:mm")}`,
                    value: data.startTime,
                    onChange: (event) =>
                      setData({
                        ...data,
                        startTime: event.currentTarget.value,
                      }),
                  }}
                />
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* 終了日時 */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <LabelUI className={`flex ${styles.formLabel}`}>終了日時</LabelUI>
              <div className={`flex flex-row pt-4`}>
                <ValidatableDateTimeUI
                  errorIdPrefix="discount-editUI-endDate-validation"
                  ymdProps={{
                    testid: "discount-editUI-endYMD",
                    registerparams: {
                      register,
                      label: "endYMD",
                      error: errors.endYMD,
                      conditions: {
                        required: "終了年月日を入力してください。",
                        validate: (value: string): ValidateResult => {
                          if (!validateYMD(value)) {
                            return "正しい年月日を入力してください。";
                          }
                          return validateEndDate(false);
                        },
                      },
                    },
                    placeholder: `${dayjs(currentDate)
                      .tz("Asia/Tokyo")
                      .add(1, "m")
                      .format("YYYY/MM/DD")}`,
                    value: data.endYMD,
                    onChange: (event) =>
                      setData({ ...data, endYMD: event.currentTarget.value }),
                  }}
                  timeProps={{
                    testid: "discount-editUI-endTime",
                    registerparams: {
                      register,
                      label: "endTime",
                      error: errors.endTime,
                      conditions: {
                        required: "終了時刻を入力してください。",
                        validate: (value: string): ValidateResult => {
                          if (!validateTime(value)) {
                            return "正しい時刻を入力してください。";
                          }
                          return validateEndDate(true);
                        },
                      },
                    },
                    placeholder: `${dayjs(currentDate)
                      .tz("Asia/Tokyo")
                      .add(1, "m")
                      .format("HH:mm")}`,
                    value: data.endTime,
                    onChange: (event) =>
                      setData({ ...data, endTime: event.currentTarget.value }),
                  }}
                />
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* 割引期間（月） */}
            {data.type === DiscountType.PlanCoupon && (
              <>
                <div className="flex flex-col align-middle mt-6 ml-0">
                  <div className="flex items-center">
                    <LabelUI className={`flex ${styles.formLabel}`}>
                      割引期間（月）
                    </LabelUI>
                    <div
                      className={`flex ml-2 items-center ${styles.labelAnnotationBox}`}
                    >
                      <span
                        className={`${styles.labelAnnotationText}`}
                        data-testid="discount-editUI-durationMonths-label-annotation"
                      >
                        {labelAnnotation}
                      </span>
                    </div>
                  </div>
                  <div className={`flex flex-col pt-4`}>
                    <ValidatableInputUI
                      data-testid="discount-editUI-durationMonths"
                      errorIdPrefix="discount-editUI-durationMonths-validation"
                      registerParams={{
                        register,
                        label: "durationMonths",
                        error: errors.durationMonths,
                        conditions: {
                          required: fieldMsgDict.durationMonths,
                          maxLength: {
                            value: maxLenDict.durationMonths,
                            message: fieldMsgDict.durationMonths,
                          },
                        },
                      }}
                      placeholder="1"
                      className={`${styles.formInput}`}
                      style={{ width: "100px" }}
                      disabled={!isNew}
                      value={getDurationMonths(data)}
                      onChange={(event) => {
                        try {
                          const strPrice: string =
                            event.currentTarget.value || "";
                          const val: number = parseInt(
                            strPrice.replace(",", "")
                          );
                          setData({
                            ...data,
                            durationMonths: val,
                          });
                        } catch {
                          // do nothing
                        }
                      }}
                    />
                    {errors.durationMonths === undefined && (
                      <div
                        className={`${styles.inputAnnotationBox}`}
                        data-testid="discount-editUI-durationMonths-note"
                      >
                        <span className={`${styles.inputAnnoutationText}`}>
                          {fieldMsgDict.durationMonths}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="border-gray border mt-5"></hr>
              </>
            )}

            {/* 割引金額 */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <div className="flex items-center">
                <LabelUI className={`flex ${styles.formLabel}`}>
                  割引金額
                </LabelUI>
                <div
                  className={`flex ml-2 items-center ${styles.labelAnnotationBox}`}
                >
                  <span
                    className={`${styles.labelAnnotationText}`}
                    data-testid="discount-editUI-priceValueNoTax-lable-annotation"
                  >
                    {labelAnnotation}
                  </span>
                </div>
              </div>
              <div className={`flex flex-col pt-4`}>
                <div className="flex items-end">
                  <ValidatableDiscountPriceInputUI
                    data-testid="discount-editUI-priceValueNoTax"
                    errorIdPrefix="discount-editUI-priceValueNoTax-validation"
                    registerParams={{
                      register,
                      label: "priceValueNoTax",
                      error: errors.priceValueNoTax,
                      conditions: {
                        required: "税抜価格を入力してください。",
                        validate: (value) =>
                          validatePriceValueNoTax(
                            value,
                            data.type,
                            maximumDiscountAmount
                          ),
                      },
                    }}
                    placeholder="000,000"
                    maxLength={6}
                    className={`${styles.formInput} mr-4 text-right pt-3 pb-3 pl-4 pr-4 h-12`}
                    style={{ width: "100px", height: "48px" }}
                    disabled={!isNew}
                    value={
                      data.priceValueNoTax
                        ? Intl.NumberFormat("ja-JP").format(
                            data.priceValueNoTax
                          )
                        : ""
                    }
                    onChange={(event) => {
                      try {
                        const strPrice: string =
                          event.currentTarget.value || "";
                        const val: number = parseInt(strPrice.replace(",", ""));
                        setData({
                          ...data,
                          priceValueNoTax: val,
                        });
                      } catch {
                        // do nothing
                      }
                    }}
                  />
                </div>
                {errors.priceValueNoTax === undefined && (
                  <div
                    className={`${styles.inputAnnotationBox}`}
                    data-testid="discount-editUI-priceValueNoTax-note"
                  >
                    <span className={`${styles.inputAnnoutationText}`}>
                      {fieldMsgDict.priceNoTax}
                      <br />
                      {fieldMsgDict.priceNoTaxDiscount}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            {/* 社内メモ */}
            <div className="flex flex-col align-middle mt-6 ml-0">
              <LabelUI className={`flex ${styles.formLabel}`}>社内メモ</LabelUI>
              <div className={`flex flex-col pt-4`}>
                <ValidatableTextareaUI
                  data-testid="discount-editUI-description"
                  errorIdPrefix="discount-editUI-description-validation"
                  registerParams={{
                    register,
                    label: "description",
                    error: errors.description,
                    conditions: {
                      maxLength: {
                        value: maxLenDict.description,
                        message: fieldMsgDict.description,
                      },
                    },
                  }}
                  placeholder="Memo"
                  className={`${styles.formInput} ${styles.descriptionTextArea}`}
                  style={{ width: "600px", height: "72px" }}
                  onChange={(event) =>
                    setData({ ...data, description: event.currentTarget.value })
                  }
                >
                  {data.description}
                </ValidatableTextareaUI>
                {errors.description === undefined && (
                  <div
                    className={`${styles.inputAnnotationBox}`}
                    data-testid="discount-editUI-description-note"
                  >
                    <span className={`${styles.inputAnnoutationText}`}>
                      {fieldMsgDict.description}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <hr className="border-gray border mt-5"></hr>

            <div className="flex  w-full justify-center mt-6 space-x-4">
              <ButtonUI
                data-testid="discount-editUI-btn-goBack"
                type="button"
                size="l"
                buttonType="secondary"
                style={{ width: "120px", height: "40px" }}
                onClick={() => history.goBack()}
              >
                戻る
              </ButtonUI>
              <ButtonUI
                data-testid="discount-editUI-btn-submit"
                type="submit"
                size="l"
                buttonType="primary"
                style={{ width: "120px", height: "40px" }}
              >
                確認
              </ButtonUI>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

/*****************************************
 *
 * 年月日＋時刻用ヴァリデーション表示が可能な UI
 * ※ 既存の ValidatableInputUI のスタイルだとデザイン通りにならないので別途定義
 *
 *****************************************/
type ValidatableUIProps = {
  registerparams: RegisterParams;
  testid?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "className">;

type ValidatableDateTimeUIProps = {
  errorIdPrefix: string;
  ymdProps: ValidatableUIProps;
  timeProps: ValidatableUIProps;
};

export const ValidatableDateTimeUI: React.FC<ValidatableDateTimeUIProps> = ({
  errorIdPrefix,
  ymdProps,
  timeProps,
}) => {
  const getErrorMsg = useCallback((): string => {
    const messages: string[] = [];
    if (ymdProps.registerparams.error) {
      messages.push(ymdProps.registerparams.error.message);
    }
    if (timeProps.registerparams.error) {
      messages.push(timeProps.registerparams.error.message);
    }
    return messages.length ? messages.join(" ") : "";
  }, [timeProps.registerparams.error, ymdProps.registerparams.error]);
  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-4">
        {(ymdProps.registerparams.error || timeProps.registerparams.error) && (
          <ErrorCheckIcon data-testid={`${errorIdPrefix}-icon`} />
        )}
        <input
          data-testid={ymdProps.testid}
          className={`${
            styles.formInput
          } h-12 leading-5 pl-4 tracking-wider border rounded ${
            ymdProps.registerparams.error ? "border-error" : ""
          }`}
          style={{ width: "132px" }}
          {...ymdProps.registerparams.register(ymdProps.registerparams.label, {
            ...ymdProps.registerparams.conditions,
          })}
          {...ymdProps}
        />
        <input
          data-testid={timeProps.testid}
          className={`${
            styles.formInput
          } h-12 leading-5 pl-4 tracking-wider border rounded ${
            timeProps.registerparams.error ? "border-error" : ""
          }`}
          style={{ width: "78px" }}
          {...timeProps.registerparams.register(
            timeProps.registerparams.label,
            {
              ...timeProps.registerparams.conditions,
            }
          )}
          {...timeProps}
        />
      </div>
      {(ymdProps.registerparams.error || timeProps.registerparams.error) && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`${errorIdPrefix}-message`}
        >
          {getErrorMsg()}
        </p>
      )}
    </div>
  );
};

/*****************************************
 *
 * 価格のヴァリデーション表示が可能な UI
 * ※ 既存の ValidatablePriceValueInputUI だとエラー表示がないので別途定義
 *
 *****************************************/
type ValidatableDiscountPriceInputUIProps = {
  registerParams: RegisterParams;
  errorIdPrefix?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const ValidatableDiscountPriceInputUI: React.FC<
  ValidatableDiscountPriceInputUIProps
> = ({ className = "", registerParams, errorIdPrefix, children, ...rest }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-3">
        {registerParams.error && (
          <ErrorCheckIcon data-testid={`${errorIdPrefix}-icon`} />
        )}
        <input
          className={`h-12 leading-5 pl-4 tracking-wider border rounded ${className} ${
            registerParams.error ? "border-error" : ""
          }`}
          {...registerParams.register(registerParams.label, {
            ...registerParams.conditions,
          })}
          {...rest}
        />
        <span className={`${styles.zeinukiLabel} place-self-end`}>
          円（税抜）
        </span>{" "}
      </div>
      {registerParams.error && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`${errorIdPrefix}-message`}
        >
          {registerParams.error.message}
        </p>
      )}
    </div>
  );
};

/**
 * DiscountType.PlanCoupon で使用する「割引期間（月）」の入力制限
 * 数字のみ、２桁まで
 * @param data
 * @returns
 */
const getDurationMonths = (data: DiscountEditForm) => {
  const inputValue: string = data.durationMonths
    ? Math.floor(data.durationMonths).toString().slice(0, 2)
    : "";
  const isValidInput: boolean = /^\d{1,2}$/.test(inputValue);

  const value = isValidInput ? inputValue : "";
  return value;
};

export default DiscountEditUI;
