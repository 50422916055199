import React, { MouseEventHandler, useCallback, useState } from "react";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useCategoriesSortPageQuery,
  useUpdateSubjectOrdersMutation,
} from "../../../generated/graphql";
import { useErrorRouter, useErrorState } from "../../../hooks/errorRouter";
import CategoriesSortPage, {
  CategoriesSortPageProps,
} from "./CategoriesSortPage";

const CategoriesSortPageContainer: React.VFC<{}> = () => {
  const errorRouter = useErrorRouter();
  const { error, clearError, onError } = useErrorState();
  const [visibility, setVisibility] = useState(false);
  const { data, loading, refetch } = useCategoriesSortPageQuery({
    onError: errorRouter,
    fetchPolicy: "no-cache",
  });

  const [updating, setUpdating] = useState(false);
  const [updateSubjectOrders] = useUpdateSubjectOrdersMutation({
    onError: (e) => {
      onError(e);
      setUpdating(false);
    },
    onCompleted: () => {
      refetch();
      setUpdating(false);
      setVisibility(true);
    },
  });

  const handleClickDone: CategoriesSortPageProps["onClickDone"] = useCallback(
    (categories) => {
      const ids = categories.map((category) => category.id);
      setUpdating(true);
      updateSubjectOrders({ variables: { input: { ids } } });
    },
    [updateSubjectOrders]
  );

  const handleClickModalButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (_event) => {
        setVisibility(false);
      },
      [setVisibility]
    );

  const handleClickErrorModalButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (_event) => {
        clearError();
        refetch();
      },
      [clearError, refetch]
    );

  if (loading || !data) {
    return <LoadingUI title="検索中" />;
  }
  if (updating) {
    return <LoadingUI title="送信中" />;
  }

  return (
    <>
      <CompletedModalUI
        title="並び替え完了"
        btnTitle="興味のあるカテゴリ並び替えへ"
        description="並び順を確定しました。"
        visibility={visibility}
        onClick={handleClickModalButton}
      />
      <ErrorModalUI
        title="並び替えに失敗しました"
        btnTitle={
          <>
            <p>興味のあるカテゴリ</p>
            <p>並び替えへ</p>
          </>
        }
        onClick={handleClickErrorModalButton}
        visibility={!!error}
      >
        <p>並び順の確定に失敗しました。</p>
        <p>しばらく時間をおいてから、もう一度お試しください。</p>
      </ErrorModalUI>
      <CategoriesSortPage data={data} onClickDone={handleClickDone} />
    </>
  );
};

export default CategoriesSortPageContainer;
