import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { ButtonUI } from "../../../components/Button";
import ExpertDetail from "../components/ExpertDetail/ExpertDetail";
import { ExpertEditFormState } from "../components/ExpertEdit";

dayjs.extend(utc);
dayjs.extend(timezone);

type ExpertEditConfirmPageProps = {
  onUpdate: () => void;
  onBack: () => void;
  input: ExpertEditFormState;
};

const ExpertEditConfirmPage: React.VFC<ExpertEditConfirmPageProps> = ({
  onUpdate,
  onBack,
  input,
}) => {
  const [profilePhoto] = useState<string | undefined>(
    input.profilePhoto instanceof File
      ? URL.createObjectURL(input.profilePhoto)
      : input.profilePhoto
  );
  const [headshot] = useState<string | undefined>(
    input.headshot instanceof File
      ? URL.createObjectURL(input.headshot)
      : input.headshot
  );

  useEffect(() => {
    return () => {
      if (profilePhoto && input.profilePhoto instanceof File) {
        URL.revokeObjectURL(profilePhoto);
      }
      if (headshot && input.headshot instanceof File) {
        URL.revokeObjectURL(headshot);
      }
    };
  }, [headshot, input.headshot, input.profilePhoto, profilePhoto]);

  return (
    <div>
      <h1 className="text-2xl mb-3">有識者確認</h1>
      <hr className="border-gray border mb-4" />
      <p>
        登録が完了すると設定した公開日時までのあいだ、「有識者ラインナップ」にcoming
        soonが表示されます。
      </p>
      <ExpertDetail
        className="mt-8"
        expert={{
          ...input,
          alt: `${input.familyName} ${input.givenName}`,
          headshot,
          profilePhoto,
        }}
      />
      <div className="mt-10 mx-auto mb-28 space-x-10 flex justify-center">
        <ButtonUI
          data-testid="back-button"
          buttonType="secondary"
          onClick={onBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI data-testid="update-button" onClick={onUpdate}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default ExpertEditConfirmPage;
