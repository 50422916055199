import React, {
  ChangeEvent,
  ChangeEventHandler,
  InputHTMLAttributes,
} from "react";

export type DateInputUIProps = {
  onDateChange?: (dateText: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const DateInputUI: React.FC<DateInputUIProps> = ({
  className = "",
  children,
  onDateChange,
  ...rest
}) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const txt: string = event.target.value;
    onDateChange && onDateChange(txt);
    if (rest.onChange !== undefined) {
      rest.onChange(event);
    }
  };

  return (
    <input
      type="date"
      className={`${className} h-9 leading-5 pl-4 tracking-wider border rounded`}
      onChange={onChange}
      max="9999-12-31"
      {...rest}
    />
  );
};
