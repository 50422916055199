import React, { useCallback, InputHTMLAttributes } from "react";
import "./ToggleButton.scss";

export type ToggleButtonUIProps = {
  onCheckChange?: (checked: boolean) => void;
  required: boolean;
  disabled?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const ToggleButtonUI: React.FC<ToggleButtonUIProps> = ({
  className,
  children,
  required = false,
  disabled = false,
  id,
  name,
  onCheckChange,
  ...rest
}) => {
  const changeState = useCallback(() => {
    !disabled && onCheckChange && onCheckChange(!required);
  }, [required, disabled, onCheckChange]);

  let toggle = required ? "switch on" : "switch";
  return (
    <div
      className={`${toggle} ${className}`}
      onClick={changeState}
      id={id}
      {...rest}
    ></div>
  );
};
