export enum GmoRecurringPaymentStatus {
  /**
   * 自動売上不可（該当会員の削除等により自動売上ができなかった場合など）
   */
  Invalid = "INVALID",
  /**
   * 自動売上成功
   */
  Capture = "CAPTURE",
  /**
   * 自動売上エラー（自動売上を行ったが、カード会社からエラーが返された場合など）
   */
  Fail = "FAIL",
}

export type GmoRecurringPaymentStatusLabel = {
  id: string;
  value: string;
  label: string;
};

export const gmoRecurringPaymentStatuses: GmoRecurringPaymentStatusLabel[] = [
  {
    id: "gmoRecurringInvalid",
    value: GmoRecurringPaymentStatus.Invalid,
    label: "自動売上不可",
  },
  {
    id: "gmoRecurringCapture",
    value: GmoRecurringPaymentStatus.Capture,
    label: "自動売上成功",
  },
  {
    id: "gmoRecurringFail",
    value: GmoRecurringPaymentStatus.Fail,
    label: "自動売上エラー",
  },
];
