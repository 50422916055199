import React, { useState, useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";

import {
  useGenerateUploadAvatorUrlMutation,
  ProfileEditFragment,
  useUpdateProfileMutation,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { UserEdit } from "../../../lib/types";
import ProfileConfirmPage from "./ProfileConfirmPage";

const ProfileConfirmPageContainer = () => {
  const history = useHistory();
  const [updateProfile] = useUpdateProfileMutation();
  const [generateUploadAvatorUrl] = useGenerateUploadAvatorUrlMutation();
  const location = useLocation<{
    profileEdit: UserEdit;
    user: ProfileEditFragment;
  }>();
  const { user, profileEdit } = location.state;
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const uploadAvator = useCallback(async (): Promise<void> => {
    const presignedPostResult = await generateUploadAvatorUrl({
      variables: {
        userId: user.id,
        fileName: profileEdit.avator?.name as string,
      },
    });
    const presignedPostData = presignedPostResult.data?.generateUploadAvatorUrl;

    if (!presignedPostData) {
      return;
    }
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      formData.append("file", profileEdit.avator!);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${presignedPostData.url}`, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  }, [generateUploadAvatorUrl, user.id, profileEdit.avator]);

  const handleUpdate = useCallback(async () => {
    const {
      userId,
      familyName,
      givenName,
      avator,
      yearsOfTeaching,
      roleIds,
      status,
      extra,
      ...input
    } = profileEdit;
    if (!input.birthday) {
      input.birthday = null;
    }
    try {
      await updateProfile({
        variables: {
          input: {
            ...input,
            // UserEditとUserInputでid, emailがnullableかどうかの型不一致がある
            id: input.id!,
            email: input.email!,
            yearsOfTeaching: yearsOfTeaching ? +yearsOfTeaching : null,
          },
        },
      });
      if (avator) {
        await uploadAvator();
      }
      setIsOpenCompleteModal(true);
    } catch (error: any) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      setConfirmErrorModalVisibility(true);
    }
  }, [
    history,
    profileEdit,
    updateProfile,
    uploadAvator,
    setIsOpenCompleteModal,
  ]);

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
  };

  const clearErrorModal = () => {
    confirmErrorModalVisibility && setConfirmErrorModalVisibility(false);
  };

  return (
    <ProfileConfirmPage
      user={user}
      profileEdit={profileEdit}
      isOpenCompleteModal={isOpenCompleteModal}
      confirmErrorModalVisibility={confirmErrorModalVisibility}
      confirmModalVisibility={confirmModalVisibility}
      handleUpdate={handleUpdate}
      clearCompletedModal={clearCompletedModal}
      clearErrorModal={clearErrorModal}
      setConfirmModalVisibility={setConfirmModalVisibility}
    />
  );
};

export default ProfileConfirmPageContainer;
