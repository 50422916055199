import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";

import {
  LessonsResultsFragment,
  useLessonsPageQuery,
} from "../../../generated/graphql";
import { usePageStateOnSessionStorage } from "../../../hooks/pageState";
import { searchSettingsForLesson as setting } from "../../../lib/constants";
import { ErrorType } from "../../../lib/constants/error";
import { TeachingMaterialsModalUI } from "./components/TeachingMaterialsModal";
import LessonsPage from "./LessonsPage";

export type teachingMaterial = {
  id: string;
  name: string;
  url: string;
};

const LessonsPageContainer = () => {
  const history = useHistory();
  const { limit, offset, setLimit, setOffset } = useLessonsPageState();

  const [lessons, setLessons] = useState<LessonsResultsFragment>({
    totalCount: -1,
    nodes: [],
  });
  const [isOperationMonitor, setIsOperationMonitor] = useState<boolean>(true);
  const [
    teachingMaterialsModalVisibility,
    setTeachingMaterialsModalVisibility,
  ] = useState<boolean>(false);
  const [teachingMaterialsModalTitle, setTeachingMaterialsModalTitle] =
    useState("");
  const [teachingMaterials, setTeachingMaterials] = useState<
    teachingMaterial[]
  >([]);

  const { data, error, refetch } = useLessonsPageQuery({
    variables: {
      limit,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data) {
        const roleIds: String[] = data.me.roles.map((role) => role.id);
        setIsOperationMonitor(
          roleIds.find((role) => role === "7") ? true : false
        );
      }
    },
  });

  useEffect(() => {
    if (data?.lessons) {
      setLessons(data?.lessons);
    }
  }, [data?.lessons]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setOffset(offset + setting.limit);
    setLimit(limit + setting.limit);
    await refetch({
      limit,
    });
  }, [refetch, setLimit, setOffset, limit, offset]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const openTeachingMaterialsModal = useCallback(
    (courseTitle: string, teachingMaterials: teachingMaterial[]) => {
      setTeachingMaterials(teachingMaterials);
      setTeachingMaterialsModalTitle(courseTitle);
      setTeachingMaterialsModalVisibility(true);
    },
    []
  );

  useEffect(() => {
    if (error) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    }
  }, [error, history]);

  return (
    <>
      <LessonsPage
        lessons={lessons}
        isOperationMonitor={isOperationMonitor}
        onDisplayMore={onDisplayMore}
        openTeachingMaterialsModal={openTeachingMaterialsModal}
      />
      <TeachingMaterialsModalUI
        title={teachingMaterialsModalTitle}
        onCancel={() => {
          setTeachingMaterialsModalVisibility(false);
        }}
        visibility={teachingMaterialsModalVisibility}
        teachingMaterials={teachingMaterials}
      />
    </>
  );
};
export default LessonsPageContainer;

const useLessonsPageState = (): {
  limit: number;
  offset: number;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
} => {
  // 以前に表示していたページの状態を、sessionStorageから取得。
  type LessonsPageState = {
    limit: number;
    offset: number;
  };
  const [previousPageState, pageStateSaver] =
    usePageStateOnSessionStorage<LessonsPageState>();

  const [limit, setLimit] = useState<number>(
    previousPageState?.limit || setting.limit
  );
  const [offset, setOffset] = useState<number>(
    previousPageState?.offset || setting.offset
  );

  // 別のページに遷移した際に、自動でsessionStorageにページの状態を保存する。
  useEffect(() => {
    pageStateSaver.autoSaveOnLeave(() => {
      return {
        limit,
        offset,
      };
    });
  }, [limit, offset, pageStateSaver]);

  // レスポンス
  const result = useMemo<ReturnType<typeof useLessonsPageState>>(
    () => ({
      limit,
      offset,
      setLimit,
      setOffset,
    }),
    [limit, offset]
  );
  return result;
};
