import React, { useCallback } from "react";

import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { useAnnouncementsEditPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { AnnouncementEdit } from "../../../lib/types/announcement";
import { AnnouncementsEditPageProps } from "./AnnouncementsEditPage";
import AnnouncementsEditPage from "./AnnouncementsEditPage";

type AnnouncementsEditPageParams = {
  id: string;
};

const AnnounementsEditPageContaier: React.VFC = () => {
  const { state } = useLocation<AnnouncementEdit>();
  const { id } = useParams<AnnouncementsEditPageParams>();
  const handleErrorRouter = useErrorRouter();
  const { data, loading } = useAnnouncementsEditPageQuery({
    variables: {
      id,
    },
    onError: handleErrorRouter,
    // レスポンスの中身がキャッシュを用いられることがありバグのもとになるので、再取得させる。
    fetchPolicy: "no-cache",
    // stateがある場合（編集画面から戻ってきたとき）はクエリを呼ばない
    skip: !!state,
  });
  const history = useHistory();

  const handleSubmit: AnnouncementsEditPageProps["onSubmit"] = useCallback(
    (announcement) => {
      history.push(`/announcements/${id}/confirm`, announcement);
    },
    [history, id]
  );

  const handleGoBack: AnnouncementsEditPageProps["onClickGoBack"] =
    useCallback(() => {
      history.push(`/announcements/${id}`);
    }, [history, id]);

  if (loading || (!data && !state)) {
    return <LoadingUI title="取得中" />;
  }

  return (
    <AnnouncementsEditPage
      announcement={!!state ? state : data!.announcement}
      onSubmit={handleSubmit}
      onClickGoBack={handleGoBack}
    />
  );
};

export default AnnounementsEditPageContaier;
