import React from "react";

import dayjs from "dayjs";

import { ContBillPaymentMismatchHeaderFragment } from "../../../../../generated/graphql";
import { getMismatchTypeJA } from "../../../../../lib/utils/payment";

type ContBillPaymentMismatchHeaderUIProps = {
  data: ContBillPaymentMismatchHeaderFragment;
};

export const ContBillPaymentMismatchHeaderUI: React.VFC<
  ContBillPaymentMismatchHeaderUIProps
> = ({ data }) => {
  return (
    <>
      <p className="mb-2" data-testid="payment-mismatch-detected-time">
        検知日時　
        {data && dayjs(data.detectedDateTime).format("YYYY/MM/DD HH:mm")}
      </p>
      <p className="mb-2" data-testid="payment-mismatch-continue-account-id">
        継続課金ID　{data && data.continueAccountId}
      </p>
      <p className="text-lg" data-testid="payment-mismatch-type">
        不整合項目　
        {data && getMismatchTypeJA(data.type)}
      </p>
    </>
  );
};
