import React, { MouseEventHandler, HTMLAttributes } from "react";

import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";

type Props = {
  title: string;
  leftBtnTitle: string;
  rightBtnTitle: string;
  visibility: boolean;
  onLeftButtonClick: MouseEventHandler<HTMLButtonElement>;
  onRightButtonClick: MouseEventHandler<HTMLButtonElement>;
} & HTMLAttributes<HTMLElement>;

export const SelectModalUI = ({
  title,
  leftBtnTitle,
  rightBtnTitle,
  visibility,
  onLeftButtonClick,
  onRightButtonClick,
  children,
}: Props) => {
  return (
    <ModalUI
      data-testid="select-modal"
      visibility={visibility}
      className="w-112"
    >
      <div className="inline-block px-5 py-5 text-center">
        <h1
          className="text-center text-subtitle text-lg font-bold tracking-wider"
          data-testid="select-modal-title"
        >
          {title}
        </h1>
        <hr className="border-horizontalrule mt-4"></hr>
        <div
          className="whitespace-pre-wrap mt-4"
          data-testid="select-modal-description"
        >
          {children}
        </div>
        <ButtonUI
          data-testid="select-modal-left-btn"
          buttonType="secondary"
          className="px-2 mt-10"
          size="auto"
          onClick={onLeftButtonClick}
        >
          {leftBtnTitle}
        </ButtonUI>
        <ButtonUI
          data-testid="select-modal-right-btn"
          buttonType="primary"
          className="ml-10 px-2 mt-10"
          size="auto"
          onClick={onRightButtonClick}
        >
          {rightBtnTitle}
        </ButtonUI>
      </div>
    </ModalUI>
  );
};
