export enum PaymentType {
  /**
   * 従量課金
   */
  PayCert = "payCert",

  /**
   * 継続課金
   */
  ContBill = "contBill",

  /**
   * GMO初月課金
   */
  ContBillFirst = "contBillFirst",

  /**
   * GMO継続課金
   */
  ContBillNext = "contBillNext",
}
