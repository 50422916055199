import React, { HTMLAttributes } from "react";

type CheckBoxMarkUIProps = HTMLAttributes<HTMLElement>;

export const CheckBoxMarkUI: React.FC<CheckBoxMarkUIProps> = ({
  className,
  ...rest
}) => {
  return (
    <div
      className={`w-5 h-5 border border-black bg-white ${className}`}
      {...rest}
    ></div>
  );
};
