import React, { useMemo, useState } from "react";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import { TrUI, TdUI } from "../../../../components/TableUI";
import { AnnouncementsTableRowFragment } from "../../../../generated/graphql";
import { AnnouncementKind } from "../../../../lib/constants";
import { ReactComponent as DisabledIcon } from "./disabledIcon.svg";
import { ReactComponent as EnableIcon } from "./enableIcon.svg";

export type AnnouncementsTableRowProps = {
  announcement: AnnouncementsTableRowFragment;
  index: number;
};

const AnnouncementsTableRowUI: React.FC<AnnouncementsTableRowProps> = ({
  announcement,
  index,
}) => {
  const [fontColor, setFontColor] = useState<string>("");
  const kind = useMemo(() => {
    const k: AnnouncementKind = announcement.kind as AnnouncementKind;
    switch (k) {
      case AnnouncementKind.News:
        setFontColor("#ff8c00");
        return "お知らせ";
      case AnnouncementKind.Maintenance:
        setFontColor("#f0371c");
        return "メンテナンス";
      case AnnouncementKind.Campaign:
        setFontColor("#21a0e3");
        return "キャンペーン";
    }
  }, [announcement.kind]);

  return (
    <TrUI data-testid={`announcements-${index}`}>
      <TdUI className="pl-5">
        <div
          data-testid={`announcements-${index}-published`}
          className="flex mx-auto justify-center mr-4"
        >
          {announcement.published ? (
            <>
              <EnableIcon className="mr-2" />
              <span className={`text-blue-navy`}>公開</span>
            </>
          ) : (
            <>
              <DisabledIcon className="mr-2" />
              <span className={`text-gray-400`}>非公開</span>
            </>
          )}
        </div>
      </TdUI>
      <TdUI className="pl-5">
        <LinkTextUI
          data-testid={`announcements-${index}-systemId`}
          to={`/announcements/${announcement.id}`}
        >
          {announcement.systemId}
        </LinkTextUI>
      </TdUI>
      <TdUI
        className="pl-5"
        data-testid={`announcements-${index}-kind`}
        style={{ color: fontColor }}
      >
        {kind}
      </TdUI>
      <TdUI className="pl-5" data-testid={`announcements-${index}-subject`}>
        {announcement.subject}
      </TdUI>
    </TrUI>
  );
};

export default AnnouncementsTableRowUI;
