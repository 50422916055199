import React, { useCallback, useState } from "react";

import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useCreateVideoLessonMutation,
  useGenerateUploadVideoLessonImageUrlMutation,
  useVideoLessonNewPageQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { ErrorType } from "../../../lib/constants/error";
import { upload } from "../../../lib/utils/uploadImage";
import { VideoLessonEditFormState } from "../components/VideoLessonEdit/VideoLessonEdit";
import VideoLessonNewPage from "./VideoLessonNewPage";

const VideoLessonNewPageContainer: React.VFC<{
  defaultValues?: Partial<VideoLessonEditFormState>; //UTようにpropsで入力値を設定できるようにしている
}> = (props) => {
  const errorRouter = useErrorRouter();
  const { data, loading } = useVideoLessonNewPageQuery();
  const [generateUrl] = useGenerateUploadVideoLessonImageUrlMutation();
  const [createVideoLesson] = useCreateVideoLessonMutation({
    onError: (error) => {
      if (
        error.graphQLErrors[0]?.extensions?.code ===
        ErrorType.DuplicateVideoLessonAlias
      ) {
        setIsLoading(false);
        setIsOpenErrorModal(true);
        return;
      }
      errorRouter(error);
    },
    onCompleted: async (data) => {
      try {
        const { data: generatedData } = await generateUrl({
          variables: {
            input: {
              videoLessonId: data.createVideoLesson.id,
              fileName: (methods.getValues("fieldItemImg") as File).name,
            },
          },
        });
        await upload(
          generatedData?.generateUploadVideoLessonImageUrl,
          methods.getValues("fieldItemImg") as File
        );
        setIsLoading(false);
        setIsOpenCompleteModal(true);
      } catch {
        history.push("/error/internalservererror");
      }
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const defaultValue: Partial<VideoLessonEditFormState> =
    props.defaultValues ?? {
      videos: [
        {
          name: "",
          order: 1,
          jStreamMId: "",
        },
        {
          name: "",
          order: 2,
          jStreamMId: "",
        },
        {
          name: "",
          order: 3,
          jStreamMId: "",
        },
      ],
    };

  const methods = useForm<VideoLessonEditFormState>({
    defaultValues: defaultValue,
  });

  const onCreateVideoLesson = useCallback(
    async (input: VideoLessonEditFormState) => {
      setIsOpenConfirmModal(false);
      setIsLoading(true);
      await createVideoLesson({
        variables: {
          input: {
            name: input.name,
            description: input.description,
            fieldId: input.fieldId,
            videoTagId: input.videoTagId,
            jStreamSampleMId: input.jStreamSampleMId,
            videos: input.videos,
            expertId: id,
            publishedAt: input.publishedAt,
            closedAt: input.closedAt,
            alias: input.alias,
          },
        },
      });
    },
    [createVideoLesson, id]
  );

  const handleOnClickRegister = useCallback(
    async (input: VideoLessonEditFormState) => {
      // 即時公開となる場合は確認モーダルを表示する
      if (dayjs(input.publishedAt).isBefore(dayjs())) {
        setIsOpenConfirmModal(true);
        return;
      }
      await onCreateVideoLesson(input);
    },
    [onCreateVideoLesson]
  );

  const handleOnBack = useCallback(() => {
    history.push("/experts");
  }, [history]);

  const handleOnCancelConfirmModal = useCallback(() => {
    setIsOpenConfirmModal(false);
  }, []);

  const clearCompletedModal = useCallback(() => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    history.push("/experts");
  }, [isOpenCompleteModal, history]);

  if (loading || !data || isLoading) {
    return <LoadingUI />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <VideoLessonNewPage
          onSubmit={handleOnClickRegister}
          onGoBack={handleOnBack}
          videoTags={data.videoTags.nodes}
          fields={data.fields.nodes}
        />
      </FormProvider>
      <ConfirmModalUI
        title="確認"
        description="登録内容が即時で反映されますが、よろしいでしょうか？"
        onSubmit={async () => {
          await onCreateVideoLesson(methods.getValues());
        }}
        onCancel={handleOnCancelConfirmModal}
        visibility={isOpenConfirmModal}
      />
      <CompletedModalUI
        title="追加完了"
        description="追加が完了しました。"
        btnTitle="有識者一覧へ"
        visibility={isOpenCompleteModal}
        onClick={clearCompletedModal}
      />
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        visibility={isOpenErrorModal}
        onClick={() => setIsOpenErrorModal(false)}
      >
        <p>同じURL表記が既に登録されています。</p>
        <p>別のURL表記を設定してください。</p>
      </ErrorModalUI>
    </>
  );
};

export default VideoLessonNewPageContainer;
