import React, { useCallback, ReactElement } from "react";

import { EditPenButtonUI } from "../EditPenButton/EditPenButton";

import type { Column, Row } from "./types";

export type MasterTableRowProps<T extends Row> = {
  row: T;
  columns: Column[];
  willEdit: (row: T) => void;
};

function MasterTableRowUI<T extends Row>({
  row,
  columns,
  willEdit,
}: MasterTableRowProps<T>): ReactElement {
  const onEditBtnClickHandler = useCallback(() => {
    willEdit(row);
  }, [willEdit, row]);

  return (
    <tr data-testid="table-row" className={`h-16 odd:bg-light-blue`}>
      <td className="border-r border-dark-gray">
        <div className="flex justify-center">
          <EditPenButtonUI
            data-testid={`table-row-edit-pen-${row.id}`}
            onClick={onEditBtnClickHandler}
          />
        </div>
      </td>
      {columns.map((column) => (
        <td
          key={column.name}
          data-testid={`table-row-${column.name}-${row.id}`}
          className={`border-r border-dark-gray ${
            column.rowClassName ? column.rowClassName : ""
          }`}
        >
          {column.formatter
            ? column.formatter(row[column.name])
            : row[column.name]}
        </td>
      ))}
    </tr>
  );
}

export default MasterTableRowUI;
