import React from "react";

import { useHistory } from "react-router-dom";

type DeepPartialReadonly<T> = T extends object
  ? {
      readonly [P in keyof T]?: DeepPartialReadonly<T[P]>;
    }
  : T;

/**
 * 「ページを表示→他のページへ遷移→...→元のページへ戻ってくる」というシナリオで、最初のページ表示時の状態を復元したい場合に使用するReact Hook。
 *
 * Usage 1: 他のページ遷移する直前に自動で状態を保存する場合
 *   // 状態の型を定義。
 *   type MyPageState = { ... };
 *
 *   // hookを呼び出して、過去の状態とセッターを取得。
 *   const [previousPageState, pageStateSaver] = usePageStateOnSessionStorage<MyPageState>();
 *
 *   // autoSaveOnLeave()を呼び出して、状態のファクトリ関数を設定。
 *   useEffect(() => {
 *     pageStateSaver.autoSaveOnLeave(() => { ... });
 *   }, [pageStateSaver]);
 *
 * Usage 2: 明示的に状態を保存する場合
 *   // 状態の型を定義。
 *   type MyPageState = { ... };
 *
 *   // hookを呼び出して、過去の状態とセッターを取得。
 *   const [previousPageState, pageStateSaver] = usePageStateOnSessionStorage<MyPageState>();
 *   ...
 *   // 状態を保存。
 *   pageStateSaver.save({ ... });
 */
export const usePageStateOnSessionStorage = <STATE extends object>(params?: {
  /**
   * 状態の一意キー。
   * 指定しない場合、URLのpathnameが使用される。
   */
  key?: string;
}): [
  /**
   * ページの状態。
   *
   * 1. 旧Verのフロントエンドで状態保存。
   * 2. 新Verリリース。
   * 3. 旧Verのページの状態を新Verのフロントエンドで利用。
   * した場合に「新Verフロントエンドではnot nullableと想定している値が、実際にはnull」という事態が起こり得る。
   * DeepPartial化しているのは、各ページ実装時に型チェックでエラーが出て、fallback実装が必要であることに気がつくようにするため。
   */
  DeepPartialReadonly<STATE> | null,
  /**
   * ページの状態を保存する関数。
   */
  {
    save: (newState: STATE) => void;
    autoSaveOnLeave: (factory: () => STATE) => void;
  }
] => {
  const history = useHistory();
  const path = React.useRef<string>(
    _normalizePathname(window.location.pathname)
  );
  const onLeave = React.useRef<(() => STATE) | undefined>(undefined);
  const dictionaryKey = React.useMemo<string>(
    () => params?.key || path.current,
    [params?.key]
  );

  React.useEffect(() => {
    // beforeunloadイベント時に、状態を保存。（React Routeを使用しないでページ遷移した際）
    const beforeunloadHandler = () => {
      if (!onLeave.current) {
        return;
      }

      _save(dictionaryKey, onLeave.current());
    };
    window.addEventListener("beforeunload", beforeunloadHandler);

    // React Routeのhistoryが変化した際に、状態を保存。（React Routerを使用してページ遷移した際）
    const unregisterForHistory = history.listen(() => {
      if (!onLeave.current) {
        return;
      }

      // URLのハッシュ（#...)が変化しただけの場合には、保存しない。
      if (_normalizePathname(window.location.pathname) === path.current) {
        return;
      }

      _save(dictionaryKey, onLeave.current());
    });

    return () => {
      window.removeEventListener("beforeunload", beforeunloadHandler);
      unregisterForHistory();
    };
  }, [dictionaryKey, history]);

  const pageState = React.useMemo(
    () => _restore<STATE>(dictionaryKey),
    [dictionaryKey]
  );

  const pageStateSave = React.useMemo(
    () => ({
      save: (newState: STATE) => {
        _save(dictionaryKey, newState);
      },
      autoSaveOnLeave: (factory: () => STATE) => {
        onLeave.current = factory;
      },
    }),
    [dictionaryKey]
  );

  return [pageState, pageStateSave];
};

const DICTIONARY_SESSION_STORAGE_KEY = "studypf-page-state";
type Dictionary = Record<string, any>; // key: 状態の一意キー  value: 状態

/**
 * sessionStorage上のDictionaryを取得する。
 */
const _getDictionaryFromSessionStorage = (): Dictionary | null => {
  const itemStr = window.sessionStorage.getItem(DICTIONARY_SESSION_STORAGE_KEY);
  if (!itemStr) {
    return null;
  }

  try {
    return JSON.parse(itemStr) as Dictionary;
  } catch (e) {
    window.sessionStorage.removeItem(DICTIONARY_SESSION_STORAGE_KEY);
    return null;
  }
};

/**
 * sessionStorage上のDictionaryに、ページの状態を保存する。
 */
const _save = <STATE>(key: string, state: STATE): void => {
  const dict: Dictionary = _getDictionaryFromSessionStorage() || {};

  dict[key] = state;
  try {
    window.sessionStorage.setItem(
      DICTIONARY_SESSION_STORAGE_KEY,
      JSON.stringify(dict)
    );
  } catch (e) {
    console.error(`Cannot save pageState for ${key}`, e);
  }
};

/**
 * sessionStorage上のDictionaryから、ページの状態を取得する。
 */
const _restore = <STATE>(key: string): DeepPartialReadonly<STATE> | null => {
  const dict = _getDictionaryFromSessionStorage();
  if (!dict) {
    return null;
  }

  if (key in dict) {
    return dict[key];
  } else {
    return null;
  }
};

/**
 * pathnameの末尾に "/" がある場合に取り除く。
 */
const _normalizePathname = (pathname: string): string => {
  if (pathname.endsWith("/")) {
    return pathname.substring(0, pathname.length - 1);
  } else {
    return pathname;
  }
};
