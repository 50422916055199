import React from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import { BillingCustomersTableRowFragment } from "../../../../generated/graphql";
dayjs.extend(utc);
dayjs.extend(tz);

export const getPayService = (payService: number) => {
  switch (payService) {
    case 0:
      return "auかんたん決済";
    case 1:
      return "クレジットカード決済";
    default:
      return "";
  }
};

const BillingCustomersTableRowUI: React.FC<{
  billingCustomer: BillingCustomersTableRowFragment;
  index: number;
}> = ({ billingCustomer, index }) => {
  const { customerId, submittedAt, canceledAt, plan, payService } =
    billingCustomer;
  return (
    <tr
      data-testid="billing-customers-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className={`border-dark-gray pl-5`}>{index + 1}</td>
      <td className={`border-dark-gray pl-5`}>
        <LinkTextUI
          data-testid="billing-customers-table-row-customerId-link"
          to={`/customers/${customerId}`}
        >
          {customerId}
        </LinkTextUI>
      </td>
      <td className="border-dark-gray pl-5">{plan.name}</td>
      <td className="border-dark-gray pl-5">{getPayService(payService)}</td>
      <td className="border-dark-gray pl-5">
        {dayjs(submittedAt).tz("Asia/Tokyo").format("YYYY/MM/DD")}
      </td>
      <td className="border-dark-gray pl-5">
        {canceledAt
          ? dayjs(canceledAt).tz("Asia/Tokyo").format("YYYY/MM/DD")
          : ""}
      </td>
    </tr>
  );
};

export default BillingCustomersTableRowUI;
