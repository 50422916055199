import React, { useCallback, useEffect, useState } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ServiceKind } from "../../../generated/graphql";
import { currentServiceKind } from "../../../lib/cache";
import LessonRequestQuestionnairesPage from "../LessonRequestQuestionnairesPage";
import LiberalArtsVideoLessonRatingPage from "../LiberalArtsVideoLessonRatingPage";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "アンケート管理",
  },
];

/** アンケート種別 */
type QuestionnairesType =
  | "lessonRequest"
  | "liberalArtsVideoLesson"
  | "auVideo";

const QuestionnairesPage: React.VFC = () => {
  const serviceKind = currentServiceKind();
  const [questionnairesType, setQuestionnairesType] =
    useState<QuestionnairesType>(
      serviceKind?.key === ServiceKind.AuEnglish ? "auVideo" : "lessonRequest"
    );

  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const onChangeType = useCallback(
    (newQuestionnairesType: QuestionnairesType) => {
      setQuestionnairesType(newQuestionnairesType);
    },
    []
  );

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">アンケート管理</h1>
      <hr className="border-dark-gray border mb-10"></hr>
      <div className="ml-1.5">
        <div className="flex justify-start space-x-5 mb-10">
          {serviceKind?.key !== ServiceKind.AuEnglish && (
            <ButtonUI
              data-testid="lesson-request-questionnaires-btn"
              buttonType={
                questionnairesType === "lessonRequest"
                  ? "selecting"
                  : "secondary"
              }
              size="custom"
              className="w-60 h-10"
              base="rounded20"
              onClick={() => {
                onChangeType("lessonRequest");
              }}
            >
              申込時アンケート
            </ButtonUI>
          )}

          {(serviceKind?.key === ServiceKind.LiberalArts ||
            serviceKind?.key === ServiceKind.AuEnglish) && (
            <ButtonUI
              data-testid="questionnaire-after-watching-btn"
              buttonType={
                questionnairesType === "liberalArtsVideoLesson" ||
                questionnairesType === "auVideo"
                  ? "selecting"
                  : "secondary"
              }
              size="custom"
              className="w-60 h-10"
              base="rounded20"
              onClick={() => {
                onChangeType(
                  serviceKind?.key === ServiceKind.LiberalArts
                    ? "liberalArtsVideoLesson"
                    : "auVideo"
                );
              }}
            >
              動画視聴後アンケート
            </ButtonUI>
          )}

          <ButtonUI
            data-testid="others-questionnaire-btn"
            buttonType={"secondary"}
            size="custom"
            className="w-60 h-10 cursor-default"
            base="rounded20"
            disabled={true}
          >
            その他アンケート
          </ButtonUI>
        </div>
        {questionnairesType === "lessonRequest" && (
          <LessonRequestQuestionnairesPage />
        )}
        {questionnairesType === "liberalArtsVideoLesson" && (
          <LiberalArtsVideoLessonRatingPage />
        )}
      </div>
    </div>
  );
};
export default QuestionnairesPage;
