import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  LessonParticipantsPageItemFragment,
  ParticipantTableFragment,
  useLessonParticipantsPageQuery,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import LessonParticipants from "./LessonParticipantsPage";

type LessonParticipantsPageParams = {
  id: string;
};

const LessonParticipantsPageContainer = () => {
  const history = useHistory();
  const { id } = useParams<LessonParticipantsPageParams>();
  const [participants, setParticipants] = useState<ParticipantTableFragment>({
    totalCount: -1,
    nodes: [],
  });

  const { data, loading, error } = useLessonParticipantsPageQuery({
    variables: {
      lessonId: id,
    },
  });

  useEffect(() => {
    if (data?.participants) {
      setParticipants(data?.participants);
    }
  }, [data?.participants]);

  useEffect(() => {
    if (error) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    }
  }, [error, history]);

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <LessonParticipants
      lesson={data.lesson as LessonParticipantsPageItemFragment}
      participants={participants}
    />
  );
};
export default LessonParticipantsPageContainer;
