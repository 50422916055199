import React from "react";

import { CreateDiscountInput } from "../../../generated/graphql";
import DiscountConfirmUI from "../components/DiscountConfirm";
import { InternalDiscountCourse } from "../components/DiscountEdit";

export type DiscountNewConfirmPageProps = {
  input: CreateDiscountInput;
  limitedCourses?: InternalDiscountCourse[];
  onSubmit: () => void;
};

const DiscountNewConfirmPage: React.FC<DiscountNewConfirmPageProps> = ({
  input,
  limitedCourses,
  onSubmit,
}) => {
  return (
    <DiscountConfirmUI
      isNew={true}
      discount={input}
      limitedCourses={limitedCourses}
      onSubmit={onSubmit}
    />
  );
};

export default DiscountNewConfirmPage;
