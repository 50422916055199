import React, { ReactNode } from "react";

import { useCurrentServiceKind } from "../../lib/cache";

export type Waiting = ReactNode | (() => ReactNode);

export type AppInitWaiterProps = {
  waiting?: Waiting;
};

export const AppInitWaiter: React.VFC<
  React.PropsWithChildren<AppInitWaiterProps>
> = ({ waiting, children }) => {
  const currentServiceKind = useCurrentServiceKind();
  if (!currentServiceKind) {
    return typeof waiting === "function" ? waiting() : waiting ?? <></>;
  }
  return children;
};
