import React, { useMemo } from "react";

import { ButtonUI } from "../../../../../components/Button";
import { ModalUI } from "../../../../../components/Modal";
import { StepFunctionsExecutionStatus } from "../../../../../generated/graphql";

export type StepFunctionsExecutionStatusModalProps = {
  status: StepFunctionsExecutionStatus | undefined;
  onClickCloseButton: () => void;
};

export const StepFunctionsExecutionStatusModal: React.VFC<
  StepFunctionsExecutionStatusModalProps
> = ({ status, onClickCloseButton }) => {
  const visibility = useMemo(() => status !== undefined, [status]);

  const { message, messageClassName, showCloseButton } = useMemo(() => {
    switch (status) {
      case undefined:
        return {
          message: "",
          messageClassName: "",
          showCloseButton: false,
        };
      case StepFunctionsExecutionStatus.Running:
        return {
          message: "実行中... しばらくお待ちください。",
          messageClassName: "",
          showCloseButton: false,
        };
      case StepFunctionsExecutionStatus.Succeeded:
        return {
          message: "正常に処理が終了しました。",
          messageClassName: "",
          showCloseButton: true,
        };
      case StepFunctionsExecutionStatus.Failed:
        return {
          message: "処理が失敗しました。",
          messageClassName: "text-error",
          showCloseButton: true,
        };
      case StepFunctionsExecutionStatus.TimedOut:
        return {
          message: "処理が失敗しました（タイムアウト）。",
          messageClassName: "text-error",
          showCloseButton: true,
        };
      case StepFunctionsExecutionStatus.Aborted:
        return {
          message: "処理が失敗しました（中断）。",
          messageClassName: "text-error",
          showCloseButton: true,
        };
    }
  }, [status]);

  return (
    <ModalUI visibility={visibility}>
      <div
        data-testid="payment-StepFunctionsExecutionStatusModal"
        className="inline-block px-5 py-5 text-center"
      >
        <div
          data-testid="payment-StepFunctionsExecutionStatusModal-message"
          className={`${messageClassName} text-center text-subtitle text-lg font-bold tracking-wider`}
        >
          {message}
        </div>
        {showCloseButton && (
          <div>
            <ButtonUI
              data-testid="payment-StepFunctionsExecutionStatusModal-closeButton"
              className="px-2 mt-5"
              buttonType="secondary"
              onClick={onClickCloseButton}
            >
              閉じる
            </ButtonUI>
          </div>
        )}
      </div>
    </ModalUI>
  );
};
