import React, { MouseEventHandler, useEffect, useState } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CoursesDetailUI } from "../../../components/CoursesDetail";
import { CourseImage } from "../../../generated/graphql";
import { PageType } from "../../../lib/constants/pageType";
import { CourseDisplay, CourseNew } from "../../../lib/types/course";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "コース追加",
  },
  {
    label: "コース追加確認",
  },
];

export type CoursesNewConfirmPageProps = {
  isLoading: boolean;
  courseNew: CourseNew;
  handleCreate: () => Promise<void>;
  handleBack: MouseEventHandler<HTMLButtonElement>;
};

const CoursesNewConfirmPage: React.FC<CoursesNewConfirmPageProps> = ({
  isLoading,
  courseNew,
  handleCreate,
  handleBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const [courseDisplay] = useState<CourseDisplay>({
    name: courseNew.name,
    courseId: courseNew.courseId || "",
    organizer: courseNew.organizer,
    targets: {
      type: courseNew.targets?.type!,
      from: courseNew.targets?.from,
      to: courseNew.targets?.to,
    },
    subjectEntity1: courseNew.subjectEntity1,
    subjectEntity2: courseNew.subjectEntity2,
    levelEntity: courseNew.levelEntity,
    price: courseNew.price,
    overview: courseNew.overview,
    details: courseNew.details,
    lessonMasters:
      courseNew.lessonMasters?.map((lessonMaster) => ({
        order: lessonMaster.order,
        name: lessonMaster.name,
        teachingMaterials:
          lessonMaster?.teachingMaterials?.map((file: File) => file.name) ?? [],
      })) ?? [],
    images: courseNew.courseImages!.map((file: File) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    })) as CourseImage[],
    videos: courseNew.videos || [],
    teachingMaterials:
      courseNew.teachingMaterials?.map((file: File) => file.name) || [],
    questionnaires: courseNew.questionnaires,
  });

  useEffect(() => {
    return () => {
      if (courseDisplay.images) {
        // メモリ解放
        courseDisplay.images.forEach((img) => {
          if (img.url) {
            URL.revokeObjectURL(img.url);
          }
        });
      }
    };
  }, [courseDisplay.images]);

  return (
    <div>
      <h1 className="text-2xl mb-3">コース追加確認</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className=" ml-5">
        <CoursesDetailUI
          course={courseDisplay}
          pageType={PageType.CoursesNewConfirmPage}
        ></CoursesDetailUI>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="course-confirm-back"
            buttonType="secondary"
            onClick={handleBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI
            disabled={isLoading}
            data-testid="course-confirm-submit"
            onClick={handleCreate}
          >
            追加
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default CoursesNewConfirmPage;
