import React, { useEffect, useState } from "react";
import { useCallback } from "react";

import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useFormContext } from "react-hook-form";

import styles from "./index.module.css";
import { ReactComponent as JpgIcon } from "./jpg.svg";
import { ReactComponent as PngIcon } from "./png.svg";

export type ImageAttr = {
  src: string;
  alt: string;
};

export type ImageInputUIProps = {
  name: string;
  image?: ImageAttr;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const ImageInputUI: React.VFC<ImageInputUIProps> = React.forwardRef<
  HTMLInputElement,
  ImageInputUIProps
>(({ name, image, ...rest }, ref) => {
  const { register, unregister, setValue, setError } = useFormContext();
  const [imageAttr, setImageAttr] = useState<ImageAttr | undefined>(image);

  const onDrop: DropzoneOptions["onDrop"] = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles || !acceptedFiles[0]) {
        return;
      }
      const file = acceptedFiles[0];
      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        setError(name, {
          type: "custom",
          message:
            ".jpg または .png 形式で、340 x 293px 以下の画像を指定してください。",
        });
        return;
      }
      if (file.size > 10 * 1000 * 1000) {
        setError(name, {
          type: "custom",
          message: "アップロード可能なファイルサイズは最大10MBです。",
        });
        return;
      }

      const image = new Image();
      if (imageAttr && imageAttr.src) URL.revokeObjectURL(imageAttr.src);
      const objectUrl = URL.createObjectURL(file);

      image.onload = () => {
        if (image.width > 340 || image.height > 293) {
          setError(name, {
            type: "custom",
            message:
              ".jpg または .png 形式で、340 x 293px 以下の画像を指定してください。",
          });
          return;
        }
        setImageAttr({
          src: objectUrl,
          alt: file.name,
        });
        setValue(name, file, { shouldValidate: true });
      };

      image.src = objectUrl;
    },
    [imageAttr, setImageAttr, setValue, name, setError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    register(name, { required: "カード画像は必須項目です。" });
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <div>
      <div className="flex flex-row">
        <div {...getRootProps()} ref={ref}>
          <input {...getInputProps()} {...rest} data-testid="file-input" />
          {imageAttr ? (
            <img
              className={styles.featuredVideoImg}
              src={imageAttr.src}
              alt={imageAttr.alt}
              onLoad={() => {
                URL.revokeObjectURL(imageAttr.src);
              }}
              onError={() => {
                URL.revokeObjectURL(imageAttr.src);
              }}
            />
          ) : (
            <div className="px-3 pt-1 pb-4 border border-dashed border-base-blue">
              <p className="text-xs">
                ファイルを <br />
                ドラッグアンドドロップ <br />
                または <br />
                アップロードしてください
              </p>
              <div className="mt-2.5 flex flex-row space-x-1 justify-center">
                <JpgIcon className="w-8" />
                <PngIcon className="w-8" />
              </div>
            </div>
          )}
        </div>
        <p className="mt-4 ml-4 text-xs text-dark-gray">
          アップロード可能なファイルサイズは最大10MBです
          <br />
          340px x 293pxの画像を指定してください
        </p>
      </div>
      <p className="mt-2">{imageAttr?.alt}</p>
    </div>
  );
});
