import { ContactStatus } from "../../generated/graphql";
import { ServiceKindDBValue } from "./Product";

export const statusTypes = [
  { value: ContactStatus.Unsupported, label: "未対応", order: 1 },
  { value: ContactStatus.InProgress, label: "対応中", order: 2 },
  { value: ContactStatus.Supported, label: "対応済", order: 3 },
];

export const contactsSearchSettings = {
  limit: 20,
  offset: 20,
};

export const contactsSearchSettingsForAllData = {
  limit: 1000,
  offset: 0,
};

export const ContactServiceKindOptions = [
  { value: ServiceKindDBValue.Aeon, label: "AEON" },
  {
    value: ServiceKindDBValue.ElementarySchool,
    label: "小学生",
  },
  {
    value: ServiceKindDBValue.LiberalArts,
    label: "社会人",
  },
];
