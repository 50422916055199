import React from "react";

import { SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";

import { useUsersEditQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { UserEdit } from "../../../lib/types";
import { UserEditFormValues } from "./types";
import UsersEditPage from "./UsersEditPage";
import UsersExternalEditPage from "./UsersExternalEditPage";

type Params = {
  id: string;
};

const UsersEditPageContainer = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const errorRouter = useErrorRouter();
  const { loading, data } = useUsersEditQuery({
    variables: {
      id,
    },
    onError: errorRouter,
  });
  const location = useLocation<{
    from?: string;
  }>();

  const from = location?.state?.from;

  const onSubmit: SubmitHandler<UserEditFormValues> = (userEditValues) => {
    if (!data?.user) return;
    const userEdit: UserEdit = {
      ...userEditValues,
      roleIds: [userEditValues.roleIds],
      userId: data.user.userId,
      familyName: data.user.familyName,
      givenName: data.user.givenName,
      extra: false,
    };
    history.push(`/users/${id}/confirm`, {
      userEdit: userEdit,
      user: data?.user,
      from,
      services: data?.services,
    });
  };

  const onBack: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (from) {
      history.push(`/users/${id}`);
    } else {
      history.push("/users");
    }
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {data.user.extra ? (
        <UsersExternalEditPage data={data} from={location?.state?.from} />
      ) : (
        <UsersEditPage data={data} onSubmit={onSubmit} onBack={onBack} />
      )}
    </>
  );
};

export default UsersEditPageContainer;
