import {
  CreateClassAndLessonsInput,
  LessonMaster,
  LessonInput,
} from "../../generated/graphql";

/** 開催場所の種類 */
export enum eventLocationType {
  /** Zoom */
  Zoom = "zoom",
  SchoolEvent = "schoolEvent",
}

export const eventLocations = {
  [eventLocationType.Zoom]: "Zoom",
  [eventLocationType.SchoolEvent]: "会場開催",
};

export type Teacher = {
  id: string;
  familyName: string;
  givenName: string;
  userId: string;
  selected?: boolean;
  extra: boolean;
};

export type DisplayLessonMaster = {
  teachers: Teacher[];
} & LessonMaster;

export type DisplayCourse = {
  systemId: string;
  name: string;
};

/**
 * データの表示や受け渡しがしやすくなるように
 * CreateClassAndLessonsInputから既存のlessonsを除き
 * DiplayLesson[]型を再定義している
 */
export type DisplayClass = {
  id?: string;
  systemId?: String;
  lessons: DisplayLesson[];
} & Omit<CreateClassAndLessonsInput, "lessons">;

export type DisplayLesson = {
  id?: string;
  lessonMaster: LessonMaster;
  teachers: Teacher[];
} & Omit<LessonInput, "teacherIds">;
