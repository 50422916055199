import React, { TdHTMLAttributes } from "react";

export type TdUIProps = TdHTMLAttributes<HTMLTableCellElement>;

export const TdUI = React.forwardRef<HTMLTableCellElement, TdUIProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <td ref={ref} {...rest} className={`py-4 text-sm ${className}`}>
        {children}
      </td>
    );
  }
);
