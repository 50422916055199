import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import ServerErrorPageUI from "../components/ServerErrorPage/ServerErrorPage";
import unAuthenticatedSvg from "./unAuthenticated.svg";
const BREAD_CRUMBS: Breadcrumb[] = [];

const NotFoundErrorPage: React.VFC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return (
    <ServerErrorPageUI
      iconImage={
        <img
          className="object-contain mx-auto"
          src={unAuthenticatedSvg}
          alt="unAuthenticated"
          data-testid="error-image"
        />
      }
    >
      この機能を利用する権限がありません。
      <br />
      PF運用責任者に問い合わせてください。
    </ServerErrorPageUI>
  );
};

export default NotFoundErrorPage;
