import { PresignedPostResult } from "../../generated/graphql";

export const isFile = (target: File | string | undefined): target is File =>
  !!target && target instanceof File;

export const upload = async (
  data: PresignedPostResult | null | undefined,
  file: File | string | undefined
) => {
  if (!data || !isFile(file)) return;
  try {
    return await new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(data.fields).forEach((key) => {
        formData.append(key, data.fields[key]);
      });
      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${data.url}`, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve(0) : reject(this.responseText);
      };
    });
  } catch (error) {
    throw new Error("uploadExpertImages error");
  }
};
