import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export type SellingItem = {
  sellingStartedAt: Date;
  sellingEndedAt: Date;
  startedAt: Date;
};

export const validateSellingItem = ({
  sellingStartedAt,
  sellingEndedAt,
  startedAt,
}: SellingItem) => {
  const startDate = dayjs(startedAt).tz("Asia/Tokyo");
  const ninetyDatesBeforeLessonStartDate = dayjs(startDate).subtract(62, "day");
  return (
    dayjs(sellingEndedAt).isAfter(sellingStartedAt) && // 販売開始日 < 販売終了日
    dayjs(startDate).isAfter(sellingEndedAt, "day") && // 販売終了日は授業開始日の前日以前まで
    dayjs(sellingStartedAt).isAfter(ninetyDatesBeforeLessonStartDate, "day") // 販売開始日は授業開始の62日前まで
  );
};
