import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useHistory, useLocation } from "react-router-dom";

import DownloadCsv, { CsvLinkProps } from "../../../components/DownloadCsv";
import {
  PaymentsFilterInput,
  PaymentsSearchResultsFragment,
  usePaymentsSearchResultsQuery,
  usePaymentCsvDownloadsLazyQuery,
  ServiceKind,
  PaymentCsvDownloadsQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { currentServiceKind } from "../../../lib/cache";
import {
  searchSettings,
  searchSettingsForAllData,
} from "../../../lib/constants";
import {
  getPaymentTypeJA,
  getPaiedProductName,
  getPaymentStatusJA,
  getPayService,
} from "./components/PaymentsTableRow";
import { aeonHeaders, liberalArtsHeaders, elementaryHeaders } from "./headers";
import PaymentsSearchResultsPage from "./PaymentsSearchResultsPage";

dayjs.extend(utc);
dayjs.extend(tz);

export type CsvData = {
  index: string;
  payInfoNo: string;
  orderId: string;
  payService: string;
  memberId: string;
  customerName: string;
  organizerName?: string;
  paiedProductName: string;
  paymentTypeJA: string;
  priceValue: string;
  priceValueNoTax: string;
  paymentStatusJA: string;
  submittedAt: string;
  soldDateTime: string;
};

const PaymentsSearchResultsPageContainer: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{
    filter: PaymentsFilterInput;
  }>();
  const [csvData, setCsvData] = useState<CsvData[]>();

  const errorRouter = useErrorRouter();

  if (location.state === undefined || location.state.filter === undefined) {
    // 直接、検索画面を開いた時の対策
    history.push("/payment/search");
    // 上記の history.push した後も、後続の処理が一度動くので
    // location.state が undefined で例外にならないようにやり過ごす
    location.state = { filter: {} };
  }

  const { filter } = location.state;
  const [limit, setLimit] = useState(searchSettings.limit);
  const [payments, setPayments] = useState<PaymentsSearchResultsFragment>({
    totalCount: -1, // TODO: 下記の※1の対応で、一旦検索中...を表示させないために対応
    nodes: [],
  });

  // TODO: index側でQueryを複数回実行すると、後発のクエリが完了段階で先発のクエリも再実行されてしまうバグがある（おそらく全画面）
  // TODO: 決済情報照会画面においては、loadingステータスを読み込んで検索中...と表示している関係上、後発クエリが完了した瞬間に検索中...が一瞬表示されてしまう。
  // TODO: 一旦上記を回避するために検索中...を表示させないように対応。（※1）色々見直しが必要そう。
  const loading = false;
  const { data, refetch } = usePaymentsSearchResultsQuery({
    onCompleted: () => {},
    onError: errorRouter,
    variables: {
      filter,
      limit,
    },
  });

  /**
   * graphql → CSV用のオブジェクトへ変換
   */
  const replaceToCsvData = useCallback(
    (queryData: PaymentCsvDownloadsQuery): CsvData[] => {
      return queryData?.payments?.nodes?.map(
        (payment, index: number): CsvData => {
          const csvData: CsvData = {
            index: `${index + 1}`,
            payInfoNo: `${payment.payInfoNo}`,
            orderId: `${payment.orderId}`,
            payService: `${getPayService(payment.payService)}`,
            memberId: `${payment.memberId}`,
            customerName: `${payment.customerName}`,
            paiedProductName: `${getPaiedProductName(payment)}`,
            paymentTypeJA: `${getPaymentTypeJA(payment.typename)}`,
            priceValue: `${
              payment!.priceValue &&
              Intl.NumberFormat("ja").format(payment!.priceValue)
            }`,
            priceValueNoTax: `${
              payment!.priceValueNoTax &&
              Intl.NumberFormat("ja").format(payment!.priceValueNoTax)
            }`,
            paymentStatusJA: `${getPaymentStatusJA(payment.paymentStatus)}`,
            submittedAt: `${
              payment.submittedAt &&
              dayjs(payment.submittedAt).format("YYYY/MM/DD")
            }`,
            soldDateTime: `${
              payment.soldDateTime &&
              dayjs(payment.soldDateTime).format("YYYY/MM/DD")
            }`,
          };

          if (currentServiceKind()?.key === ServiceKind.ElementarySchool) {
            // NOTE: 事業者は小学生のみの機能
            csvData.organizerName = `${payment.organizerName || ""}`;
          }

          return csvData;
        }
      );
    },
    []
  );

  const [fetchCSVData, { loading: loadingCSVQuery }] =
    usePaymentCsvDownloadsLazyQuery({
      onCompleted: (data) => {
        const replacedData = replaceToCsvData(data);
        setCsvData(replacedData);
      },
      onError: errorRouter,
      variables: {
        filter,
        offset: searchSettingsForAllData.offset,
        limit: searchSettingsForAllData.limit,
      },
    });

  const getHeaders = useCallback((kind: ServiceKind | undefined) => {
    switch (kind) {
      case ServiceKind.Aeon:
        return aeonHeaders;
      case ServiceKind.LiberalArts:
        return liberalArtsHeaders;
      case ServiceKind.ElementarySchool:
        return elementaryHeaders;
      default:
        return aeonHeaders;
    }
  }, []);

  const csvLinkProps: CsvLinkProps | undefined = useMemo(() => {
    // validate
    if (loadingCSVQuery || !csvData) {
      return undefined;
    }

    return {
      filename: `決済情報_${dayjs().format("YYYYMMDD")}.csv`,
      headers: getHeaders(currentServiceKind()?.key),
      data: csvData,
    };
  }, [csvData, getHeaders, loadingCSVQuery]);

  const onGobackSearchCondition = () => {
    history.push("/payment/search", { prevFilter: filter });
  };

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter,
      limit: limit + searchSettings.offset,
    });
  }, [filter, refetch, limit]);

  useEffect(() => {
    if (data?.paymentsForDisplay) {
      setPayments(data?.paymentsForDisplay);
    }
  }, [data?.paymentsForDisplay, setPayments]);

  return (
    <>
      <PaymentsSearchResultsPage
        loading={loading}
        payments={loading ? { totalCount: 0, nodes: [] } : payments}
        hasAdminAuthority={data?.hasAdminAuthority || false}
        onGoBackSearchCondition={onGobackSearchCondition}
        onDisplayMore={onDisplayMore}
        onClickDownload={fetchCSVData}
      />
      <DownloadCsv csvLinkProps={csvLinkProps} />
    </>
  );
};
export default PaymentsSearchResultsPageContainer;
