import React, { useState } from "react";

import Select, { components } from "react-select";

import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "./arrowUp.svg";
import styles from "./SelectCustomStyle.module.scss";

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectUIProps = {
  options: SelectOption[];
  placeholder?: string | number;
  id?: string;
  name: string;
  className?: string;
  value?: string;
  blackBorder?: boolean;
  errorBorder?: boolean;
  disabled?: boolean;
  testId?: string;
  onChange: ({ name, value }: { name: string; value: string }) => void;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </components.DropdownIndicator>
  );
};

const customStylesOpen = {
  option: (provided: any, state: any) => {
    let backgroundColor = "white";
    const color = "black";
    if (state.isSelected || state.isFocused) {
      backgroundColor = "#E9F7FF";
    }
    return {
      ...provided,
      padding: 10,
      backgroundColor,
      color,
      ":active": {
        backgroundColor: "#E9F7FF",
      },
      fontWeight: "normal",
    };
  },
  control: () => ({
    width: "100%",
    display: "flex",
    border: "1px solid #55ACEE",
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderBottom: "none",
    height: "3rem",
    background: "white",
    fontSize: "13px",
    fontFamily: "initial",
  }),
  container: () => ({
    position: "relative",
    width: "100%",
  }),
  menu: () => ({
    border: "1px solid #55ACEE",
    borderTop: "none",
    width: "100%",
    background: "white",
    zIndex: "999",
    fontSize: "13px",
    fontFamily: "initial",
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export const SelectCustomStyleUI: React.FC<SelectUIProps> = ({
  options,
  id,
  name,
  placeholder = "選択項目",
  className,
  value,
  blackBorder = false,
  errorBorder = false,
  disabled = false,
  onChange,
  testId,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChange = (e: any) => {
    onChange({ name, value: e?.value ?? null });
  };

  const customStyles = {
    option: (provided: any, state: any) => {
      let backgroundColor = "white";
      const color = "black";
      if (state.isSelected || state.isFocused || state.isActive) {
        backgroundColor = "#E9F7FF";
      }
      return {
        ...provided,
        padding: 10,
        backgroundColor,
        color,
        ":active": {
          backgroundColor: "#E9F7FF",
        },
      };
    },
    control: () => ({
      width: "100%",
      display: "flex",
      border: `1px solid ${
        blackBorder ? "#000" : errorBorder ? "#F0371C" : "#e5e7eb"
      }`,
      borderRadius: "4px",
      height: "3rem",
      background: "white",
      fontSize: "13px",
      fontFamily: "initial",
    }),
    menu: () => ({
      border: "1px solid #55ACEE",
      borderTop: "none",
      background: "white",
      fontSize: "13px",
      fontFamily: "initial",
    }),
    singleValue: (
      provided: any,
      state: { isDisabled: any; selectProps: any }
    ) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <div className={`${styles.wrap} ${className}`} data-testid={testId}>
      <div
        className={`${className} ${
          isOpen ? styles.wrapBodyActive : styles.wrapBody
        }`}
      >
        <Select
          options={options}
          isClearable={true}
          id={id}
          value={value ? { value, label: value } : null}
          name={name}
          styles={isOpen ? customStylesOpen : customStyles}
          classNamePrefix="react-select"
          isDisabled={disabled}
          onMenuOpen={() => {
            setIsOpen(true);
          }}
          onMenuClose={() => {
            setIsOpen(false);
          }}
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          placeholder={placeholder}
          onChange={handleChange}
          {...rest}
        />
      </div>
    </div>
  );
};
