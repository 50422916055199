import React, {
  ChangeEventHandler,
  FormEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { NetworkStatus } from "@apollo/client";
import * as History from "history";
import { Prompt, useHistory, useLocation } from "react-router-dom";

import { CheckboxUI } from "../../../../../components/Checkbox";
import { PaginationUI } from "../../../../../components/Pagination";
import {
  Level,
  useCreateLevelMutation,
  useLevelsLazyQuery,
  useUpdateLevelMutation,
} from "../../../../../generated/graphql";
import { useErrorRouter } from "../../../../../hooks/errorRouter";
import { ErrorType } from "../../../../../lib/constants/error";
import { LevelEditModalUI, LevelEditMode } from "../LevelEditModal";
import { Column, MasterTableUI } from "../MasterTable";
import { PlusButtonUI } from "../PlusButton/PlusButton";
import { SearchInputUI } from "../SearchInput/SearchInput";

type ModalControll = {
  visible: boolean;
  mode: LevelEditMode;
  level?: Level;
};

export type LevelsUIProps = {} & HTMLAttributes<HTMLDivElement>;

const columns: Column[] = [
  {
    title: "コード",
    name: "systemId",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
  },
  {
    title: "レベル名",
    name: "name",
    headerClassName: "text-left pl-2.5 w-auto",
    rowClassName: "text-left pl-2.5 ",
  },
  {
    title: "ステータス",
    name: "status",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
    formatter: (value) => (value ? "有効" : "無効"),
  },
];

export const LevelsUI: React.FC<LevelsUIProps> = (props) => {
  const history = useHistory();
  const limit = 20;

  const errorRouter = useErrorRouter();
  const location = useLocation();
  const state = location.state as string;
  const [searchWord, setSearchWord] = useState<string>(state ? state : "");

  // レベル追加/編集モーダル制御
  const [modalControll, setModalControll] = useState<ModalControll>({
    visible: false,
    mode: LevelEditMode.CREATE,
    level: undefined,
  });

  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);
  // クエリー・パラメター
  const [offset, setOffset] = useState(0);

  const [loadLevels, { data, loading, networkStatus }] = useLevelsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onError: errorRouter,
  });

  // レベル作成 Mutation
  const [createLevel] = useCreateLevelMutation();
  // レベル更新 Mutation
  const [updateLevel] = useUpdateLevelMutation();

  // レベル追加/編集モーダルの表示制御
  const setModalVisible = useCallback(
    (visible: boolean) => {
      setModalControll({
        ...modalControll,
        visible: visible,
      });
    },
    [setModalControll, modalControll]
  );

  // ブラウザバック時にモーダルを非表示化
  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (location.pathname === "/masters" && action === "POP") {
        setModalVisible(false);
        return false;
      }
      return true;
    },
    [setModalVisible]
  );

  // レベル作成ハンドラー
  const handleCreateLevel = useCallback(
    async (name: string, status: boolean) => {
      try {
        await createLevel({
          variables: {
            input: {
              name,
              status,
            },
          },
        });
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (!errCode) {
          // エラーコードを取れない場合は内部サーバエラーに飛ばす
          history.push("/error/internalservererror");
        } else if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
        } else {
          history.push("/error/internalservererror");
        }
        return;
      }
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    [setModalVisible, createLevel, setExecQueryTrigger, history]
  );

  // レベル更新ハンドラー
  const handleUpdateLevel = useCallback(
    async (id: string, name: string, status: boolean) => {
      try {
        await updateLevel({
          variables: {
            input: {
              id,
              name,
              status,
            },
          },
        });
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (!errCode) {
          // エラーコードを取れない場合は内部サーバエラーに飛ばす
          history.push("/error/internalservererror");
        } else if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
        } else {
          history.push("/error/internalservererror");
        }
        return;
      }
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    [setModalVisible, updateLevel, setExecQueryTrigger, history]
  );

  // 検索用「status」パラメータ変更ハンドラー
  const [filterActiveFlg, setFilterActiveFlg] = useState<true | undefined>(
    undefined
  );
  const onChangeAciveFilterCheckbox: ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      const active: true | undefined = e.target.checked ? true : undefined;
      setFilterActiveFlg(active);
      setOffset(0);
      setExecQueryTrigger(true);
    }, []);

  // 検索用レベル名パラメータ変更ハンドラー
  const onChangeLevelsSearchFilter: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        setSearchWord(event.currentTarget.value);
      },
      [setSearchWord]
    );

  // 検索用レベル名クリアハンドラー
  const onDeleteSearchFilter: MouseEventHandler = useCallback(() => {
    setSearchWord("");
    setOffset(0);
    setExecQueryTrigger(true);
  }, [setSearchWord]);

  // 検索ボタンのクリックハンドラー
  const onSearch: FormEventHandler = (event) => {
    event.preventDefault();
    setOffset(0);
    setExecQueryTrigger(true);
  };

  // ページネーションの前ページボタンクリックハンドラー
  const onClickPrevios = useCallback(() => {
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションの次ページボタンクリックハンドラー
  const onClickNext = useCallback(() => {
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションのページ指定ボタンクリックハンドラー
  const onClickPaginationItem = useCallback(
    (index: number) => {
      setOffset(index * limit);
      setExecQueryTrigger(true);
    },
    [limit]
  );

  // レベル作成ボタンのクリックハンドラー
  const onLevelNewBtnClick: MouseEventHandler = useCallback(
    (event) => {
      setModalControll({
        mode: LevelEditMode.CREATE,
        visible: true,
        level: undefined,
      });
    },
    [setModalControll]
  );

  // レベル編集ボタンのクリックハンドラー
  const onLevelEditBtnClick = useCallback(
    (level: Level) => {
      setModalControll({
        mode: LevelEditMode.UPDATE,
        visible: true,
        level,
      });
    },
    [setModalControll]
  );

  useEffect(() => {
    if (execQueryTrigger) {
      loadLevels({
        variables: {
          filter: {
            active: filterActiveFlg,
            searchWord: searchWord ? searchWord : undefined,
          },
          offset: offset,
          limit,
        },
      });
      setExecQueryTrigger(false);
    }
  }, [execQueryTrigger, loadLevels, offset, searchWord, filterActiveFlg]);

  return (
    <div {...props}>
      <form className="flex space-x-7 mt-4" onSubmit={onSearch}>
        <SearchInputUI
          className="flex-grow"
          data-testid="levels-search-input"
          placeholder="コード、レベル名"
          value={searchWord}
          onChange={onChangeLevelsSearchFilter}
          onClickSearch={onSearch}
          onClickDelete={onDeleteSearchFilter}
        ></SearchInputUI>
        <CheckboxUI
          className="flex-none"
          style={{ marginRight: "10px" }}
          data-testid="levels-active-checkbox"
          type="m"
          onChange={onChangeAciveFilterCheckbox}
        >
          ステータスが有効のみ表示する
        </CheckboxUI>
      </form>
      <div className="flex mt-5 justify-end">
        <PlusButtonUI
          data-testid="level-new-btn"
          to="/level/new"
          onClick={onLevelNewBtnClick}
        ></PlusButtonUI>
      </div>
      {(loading ||
        data === undefined ||
        networkStatus === NetworkStatus.refetch) && (
        <div className="mt-4">Loading...</div>
      )}
      {!loading && data && data.levels!.totalCount > 0 && (
        <>
          <p
            className="tracking-wider mt-2 mb-3"
            data-testid="levels-display-count"
          >{`全${data.levels!.totalCount}件中${offset + 1}件〜${
            offset + data.levels!.nodes.length
          }件を表示`}</p>
          <MasterTableUI
            rows={data.levels.nodes}
            columns={columns}
            willEdit={onLevelEditBtnClick}
          ></MasterTableUI>
          {data.levels!.totalCount > 0 && (
            <PaginationUI
              className="mt-20 mb-20"
              selectedIndex={Math.ceil(offset / limit)}
              count={Math.ceil(data.levels.totalCount / limit)}
              onClickPrevios={onClickPrevios}
              onClickNext={onClickNext}
              onClickItem={onClickPaginationItem}
            ></PaginationUI>
          )}
        </>
      )}
      {!loading && data && data.levels.totalCount === 0 && (
        <div className="bg-gray-200 text-center mt-5">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-level-exists-message"
          >
            該当するレベルがありません。
          </p>
          <p>検索条件に該当するレベルがありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
      {modalControll.visible && (
        <LevelEditModalUI
          editMode={modalControll.mode}
          visibility={true}
          level={modalControll.level}
          onCancel={() => {
            setModalVisible(false);
          }}
          onCreate={handleCreateLevel}
          onUpdate={handleUpdateLevel}
        />
      )}
      <Prompt when={true} message={handleBlockBack} />{" "}
    </div>
  );
};
