import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
} from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  PublishClazzFragment,
  PublishCoursesFragment,
  PublishCoursesItemFragment,
} from "../../../generated/graphql";
import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";
import CoursesItemUI from "./components/CoursesItem";
import CoursesSearchInputUI from "./components/CoursesSearchInput";
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
];

export type CoursesPageProps = {
  courses: PublishCoursesFragment;
  searchFilter: string;
  offset: number;
  loading?: boolean;
  onChangeSearchFilterHandler: ChangeEventHandler;
  onClickDeleteHandler: MouseEventHandler;
  onSearch: FormEventHandler;
  onDisplayMore: () => void;
  onOpenPublishModal: (courseId: string, clazz: PublishClazzFragment) => void;
  changePreviewToggle: (course: PublishCoursesItemFragment) => Promise<void>;
};

const CoursesPage: React.FC<CoursesPageProps> = ({
  courses,
  searchFilter,
  offset,
  loading,
  onChangeSearchFilterHandler,
  onClickDeleteHandler,
  onSearch,
  onDisplayMore,
  onOpenPublishModal,
  changePreviewToggle,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const onClickDisplayMore = useCallback(() => {
    onDisplayMore();
  }, [onDisplayMore]);

  return (
    <div data-testid="courses-display-component">
      <form className="ml-12 mt-4" action="" onSubmit={(e) => onSearch(e)}>
        <CoursesSearchInputUI
          className="mb-9"
          value={searchFilter}
          onChange={onChangeSearchFilterHandler}
          onClickSearch={onSearch}
          onClickDelete={onClickDeleteHandler}
        ></CoursesSearchInputUI>
      </form>

      {loading ? (
        <div className=" ml-8">Loading...</div>
      ) : courses!.totalCount > 0 ? (
        <>
          <div className="ml-8">
            <p
              className="tracking-wider mt-5"
              data-testid="courses-display-count"
            >{`全${courses!.totalCount}件中${offset + 1}件〜${
              offset + courses!.nodes.length
            }件を表示`}</p>
          </div>
          <div>
            <div className="bg-gray-light -ml-3 -mr-5 pl-3 pr-5 pt-4 pb-1">
              {courses.nodes.map((course: PublishCoursesItemFragment) => (
                <CoursesItemUI
                  key={course.id}
                  course={course}
                  onOpenPublishModal={onOpenPublishModal}
                  changePreviewToggle={changePreviewToggle}
                ></CoursesItemUI>
              ))}
            </div>

            {courses.totalCount > courses.nodes.length && (
              <div className="text-center bg-gray-light -ml-3 -mr-5 pl-3 pr-5 pt-4 pb-12">
                <button
                  data-testid="display-more-btn"
                  className="text-blue text-lg"
                  onClick={onClickDisplayMore}
                >
                  もっと表示する <ArrowDownIcon className="inline-block ml-2" />
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="bg-gray-200 text-center mt-5 ml-12">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-publication-course-exists-message"
          >
            該当するコースがありません。
          </p>
          <p>検索条件に該当するコースがありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
    </div>
  );
};

export default CoursesPage;
