import React, { HTMLAttributes } from "react";

import styles from "./Loading.module.scss";
import Loading from "./LoadingForToE.gif";

export type LoadingUIProps = HTMLAttributes<HTMLElement>;

export const LoadingUI: React.FC<LoadingUIProps> = ({
  className,
  children,
  title,
  ...rest
}) => {
  return (
    <div className={styles.loadingWrapper}>
      <div className={styles.loading}>
        <h3 className="text-center text-gray-400 text-lg tracking-widest">
          {title}
        </h3>
        <img src={Loading} alt="ローディング" />
      </div>
    </div>
  );
};
