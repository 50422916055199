import React from "react";

import {
  QuestionnaireOptionInput,
  QuestionnaireType,
} from "../../generated/graphql";
import { CheckBoxMarkUI } from "../CheckBoxMark";
import { RegisterParams } from "../common-interfaces";
import { InputUI } from "../Input";
import { RadioMarkUI } from "../RadioMark";
import { ReactComponent as CloseIcon } from "./close.svg";
import styles from "./Questionnaire.module.scss";

export type SelectQuestionOptionUIProps = {
  index: number;
  option: QuestionnaireOptionInput;
  questionnaireKey: number;
  disabled: boolean;
  questionnaireType:
    | QuestionnaireType.SelectQuestionnaire
    | QuestionnaireType.MultiSelectQuestionnaire;
  onDelete: (optionIndex: number) => void;
  onChangeInput: (index: number, value: string) => void;
  registerParams: RegisterParams;
  errorIdPrefix?: string;
};

export const SelectQuestionOptionUI: React.FC<SelectQuestionOptionUIProps> = ({
  index,
  option,
  questionnaireKey,
  disabled = false,
  questionnaireType,
  onDelete,
  onChangeInput,
  registerParams,
  errorIdPrefix,
  ...rest
}) => {
  return (
    <div>
      <div key={index} className="inline-flex items-center mb-2.5">
        {questionnaireType === QuestionnaireType.SelectQuestionnaire ? (
          <RadioMarkUI />
        ) : (
          <CheckBoxMarkUI />
        )}
        <input
          value={option.name}
          className="hidden"
          {...registerParams.register(registerParams.label, {
            ...registerParams.conditions,
          })}
          {...rest}
        />
        <InputUI
          className={`${styles.textBox} ml-4 mr-2.5 ${
            registerParams.error
              ? "border-error"
              : (option.name?.length || 0) > 0
              ? "border-black"
              : ""
          }`}
          data-testid={`questionnaire-${questionnaireKey}-question-${index}`}
          placeholder={`選択肢${index + 1}`}
          value={option.name}
          disabled={disabled}
          id={`${index}`}
          name={`${index}`}
          onChange={(e) => {
            onChangeInput(index, e.target.value);
          }}
          {...rest}
        />
        {!disabled && (
          <CloseIcon
            data-testid={`questionnaire-${questionnaireKey}-question-${index}-remove-icon`}
            className={`${styles.deleteIcon} stroke-current text-blue`}
            onClick={() => onDelete(index)}
          />
        )}
      </div>
      <div>
        {registerParams.error && (
          <p
            className="text-error ml-10 mb-3"
            data-testid={`${errorIdPrefix}-message`}
          >
            {registerParams.error.message}
          </p>
        )}
      </div>
    </div>
  );
};
