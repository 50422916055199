import React from "react";

import {
  ContactsFragment,
  ContactsTableRowFragment,
} from "../../../../generated/graphql";
import ContactsTableRowUI from "./ContactsTableRow";

export type ContactsTableUIProps = {
  contacts: ContactsFragment;
};

export const ContactsTableUI: React.FC<ContactsTableUIProps> = ({
  contacts,
}) => {
  return (
    <div className="overflow-scroll">
      <table
        className={`border border-collapse border-dark-gray middle:w-contact-table large:w-full`}
      >
        <thead className=" bg-subtitle text-white">
          <tr className="text-left h-16">
            <th className="border-r border-white pl-5">対応状況</th>
            <th className="border-r border-white pl-5">お問い合わせ番号</th>
            <th className="pl-5">お客様番号</th>
            <th className="pl-5">お問い合わせ種別</th>
            <th className="border-r border-white pl-5">お問い合わせ経路</th>
            <th className="pl-5">登録日</th>
            <th className="pl-5">最終更新者</th>
            <th className="pl-5">最終更新日</th>
          </tr>
        </thead>
        <tbody>
          {contacts.nodes.map(
            (contact: ContactsTableRowFragment, index: number) => (
              <ContactsTableRowUI
                data-testid="contacts-table-row"
                key={contact.id}
                index={index}
                contact={contact}
              ></ContactsTableRowUI>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
