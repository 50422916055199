import React, { ChangeEventHandler, FormEventHandler, useState } from "react";

import dayjs from "dayjs";

import { ButtonUI } from "../../../../../components/Button";
import { ModalUI } from "../../../../../components/Modal";
import { RadioUI } from "../../../../../components/Radio";
import { PublishSettings } from "../../../../../lib/constants/publish";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";
import { validateSellingItem } from "../../../../../lib/utils/publication";
import { PublishDateFormUI } from "../PublishDateForm";

type Props = {
  title: string;
  visibility: boolean;
  clazz: UpdateDateAndStatus;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  updateClazz: (clazz: UpdateDateAndStatus) => void;
};

export const PublishModalUI = ({
  title,
  visibility,
  onSubmit,
  onCancel,
  clazz,
  updateClazz,
}: Props) => {
  const [isSaleDatesError, setIsSaleDatesError] = useState(false);

  const onChangeRadio: ChangeEventHandler<HTMLInputElement> = (event) => {
    let published: boolean = clazz.published;
    if (event.target.value === PublishSettings[0].label) {
      published = PublishSettings[0].value;
    } else if (event.target.value === PublishSettings[1].label) {
      published = PublishSettings[1].value;
    }
    updateClazz({
      ...clazz,
      published,
    });
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    const isValid = validateSellingItem({
      sellingStartedAt: clazz.saleStartedAt,
      sellingEndedAt: clazz.saleEndedAt,
      startedAt: clazz.lessons[0].startedAt,
    });
    setIsSaleDatesError(!isValid);
    if (isValid) {
      onSubmit();
    }
  };

  const handleCancel = () => {
    setIsSaleDatesError(false);
    onCancel();
  };

  return (
    <ModalUI
      data-testid="publish-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-10 px-3">
        <h1
          className="text-center text-2xl text-subtitle tracking-wider"
          data-testid="publish-modal-title"
        >
          {title}
        </h1>
        <hr className="border-under-line mt-5"></hr>
        <form
          onSubmit={handleSubmit}
          onKeyDown={(event) => preventFromSubmittingByEnter(event)}
        >
          <div className="flex items-center space-x-4 mt-7 ml-10 text-lg">
            {PublishSettings.map((publishSetting) => (
              <RadioUI
                data-testid="subject-edit-status"
                key={publishSetting.label}
                id={publishSetting.label}
                name="publishSetting"
                value={publishSetting.label}
                checked={publishSetting.value === clazz?.published!}
                onChange={onChangeRadio}
              >
                {publishSetting.label}
              </RadioUI>
            ))}
          </div>
          <div className="text-xl tracking-wider text-subtitle mt-5 ml-10">
            販売期間（キャンセル可能期間）
          </div>

          <PublishDateFormUI
            clazz={clazz}
            data-testid={`publilsh`}
            updateClazz={updateClazz}
            isSaleDatesError={isSaleDatesError}
          />
          <div className="text-xl tracking-wider text-subtitle mt-5 ml-10">
            授業日程
          </div>
          {clazz?.lessons?.map((lesson, i) => (
            <div key={i} className="tracking-wider">
              <span className="text-xl text-subtitle ml-20 mt-3">
                第{lesson.lessonMaster.order}回
              </span>
              <span className="ml-3">
                {dayjs(lesson.startedAt).format("YYYY/MM/DD HH:mm")}~
                {dayjs(lesson.endedAt).format("HH:mm")}
              </span>
            </div>
          ))}
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              type="button"
              data-testid="contents-publish-cancel"
              buttonType="secondary"
              onClick={handleCancel}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="contents-publish-submit">登録</ButtonUI>
          </div>
        </form>
      </div>
    </ModalUI>
  );
};
