export enum PaymentTransactionStatus {
  /**
   * 決済認可済み
   */
  PayCerted = 10,
  /**
   * 規約同意済み
   */
  Agreed = 20,
  /**
   * 暗証番号認証済み
   */
  PinAuthenticated = 30,
  /**
   * 決済完了済み
   */
  Completed = 40,
  /**
   * 無効
   */
  Invalid = 90,
}
