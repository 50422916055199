import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { NetworkStatus } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { useCoursesForCoursesPageLazyQuery as useCoursesLazyQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { useQueryParams } from "../../../hooks/query";
import CoursesPage from "./CoursesPage";

const getParamOffset = (params: URLSearchParams): number => {
  const n: string | null = params && params.get("offset");
  if (!n || isNaN(parseInt(n))) {
    return 0;
  } else {
    return parseInt(n);
  }
};
const getParamKeyword = (params: URLSearchParams): string => {
  const s: string = (params && params.get("keyword")) || "";
  return s;
};

const CoursesPageContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const limit = 20;

  const errorRouter = useErrorRouter();

  // Lazy クエリーを実行した際のパラメータ Ref
  const lastExecParamsRef = useRef<string | undefined>(undefined);
  // Lazy クエリー発行後に history.push() を行うか、否かのフラグ
  const [pushHistory, setPushHistory] = useState<boolean>(false);

  // 現在の URL パラメータ
  const currentParams = useQueryParams();
  // Lazy クエリーの offset
  const [offset, setOffset] = useState(getParamOffset(currentParams));

  // 検索キーワードを管する変数
  const state = (location.state as string) || "";
  const [searchWord, setSearchWord] = useState<string>(() => {
    const paramKeyword: string = getParamKeyword(currentParams);
    const word = paramKeyword ? paramKeyword : state;
    return word;
  });

  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);
  // コース一覧取得用 Lazy クエリー
  const [loadCourses, { data, loading, networkStatus }] = useCoursesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onError: errorRouter,
    onCompleted: (data) => {
      const count: number = data?.courses?.totalCount || 0;
      if (count < offset) {
        // データの変更等で指定されたページ位置（オフセット）にデータがない場合は、最終ページを表示する
        setOffset(Math.floor(count / limit) * limit);
        setExecQueryTrigger(true);
      }
    },
  });

  /**
   * 検索キーワードの入力/編集イベント・ハンドラー
   */
  const onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        setSearchWord(event.target.value);
      },
      [setSearchWord]
    );

  /**
   * コース一覧画面でのブラウザ・バック / フォーワード対応
   */
  useEffect(() => {
    if (!lastExecParamsRef.current || pushHistory) {
      return;
    }
    // 前回 Lazy クエリー実行時のパラメータ
    const lastParams = new URLSearchParams(lastExecParamsRef.current);
    const lastOffset = getParamOffset(lastParams);
    const lastKeyword = getParamKeyword(lastParams);

    // 現在の URL パラメータ
    const currentOffset = getParamOffset(currentParams);
    // state に検索キーワードが保管されている場合は state を使用する
    const currentKeyword = state || getParamKeyword(currentParams);

    // パラメータに変更があれば Lazy クエリーをキック
    if (currentOffset !== lastOffset || currentKeyword !== lastKeyword) {
      setOffset(currentOffset);
      setSearchWord(currentKeyword);
      setExecQueryTrigger(true);
    }
  }, [currentParams, pushHistory, state]);

  /**
   * コース一覧の取得
   */
  useEffect(() => {
    if (execQueryTrigger) {
      setExecQueryTrigger(false);
      loadCourses({
        variables: {
          filter: { searchWord: searchWord },
          limit,
          offset,
        },
      });
      if (pushHistory) {
        // history に残す場合
        setPushHistory(false);
        history.push(
          `/courses?offset=${offset}&keyword=${searchWord}`,
          searchWord
        );
      }
      lastExecParamsRef.current = `?&offset=${offset}&keyword=${searchWord}`;
    }
  }, [execQueryTrigger, history, loadCourses, offset, pushHistory, searchWord]);

  /**
   * 検索実行
   */
  const onSearch: FormEventHandler = useCallback(async (event) => {
    event.preventDefault();
    setPushHistory(true);
    setOffset(0);
    setExecQueryTrigger(true);
  }, []);

  /**
   * ページングの『前へ』ボタンをクリック
   */
  const onClickPrevios = useCallback(() => {
    setPushHistory(true);
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset]);

  /**
   * ページングの『次へ』ボタンをクリック
   */
  const onClickNext = useCallback(() => {
    setPushHistory(true);
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset]);

  /**
   * ページングの『ページ番号』ボタンをクリック
   */
  const onClickPaginationItem = useCallback((index: number) => {
    setPushHistory(true);
    setOffset(index * limit);
    setExecQueryTrigger(true);
  }, []);

  /**
   * 検索キーワードのクリア・ボタンをクリック
   */
  const onClickDeleteHandler: MouseEventHandler = useCallback(() => {
    setPushHistory(true);
    setSearchWord("");
    setOffset(0);
    setExecQueryTrigger(true);
  }, []);

  return (
    <CoursesPage
      loading={loading || networkStatus === NetworkStatus.refetch}
      limit={limit}
      courses={data && data.courses ? data.courses : undefined}
      searchFilter={searchWord}
      offset={offset}
      onSearch={onSearch}
      onChangeSearchFilterHandler={onChangeSearchFilterHandler}
      onClickDeleteHandler={onClickDeleteHandler}
      onClickPrevios={onClickPrevios}
      onClickNext={onClickNext}
      onClickPaginationItem={onClickPaginationItem}
    />
  );
};
export default CoursesPageContainer;
