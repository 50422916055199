import React from "react";

// https://reactjs.org/docs/react-dom-client.html
import { ApolloProvider } from "@apollo/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import { client } from "./lib/apollo-client";
import reportWebVitals from "./reportWebVitals";

import "./lib/amplify-configure";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
