import { useMemo } from "react";

import { UploadResult } from "../../generated/graphql";
import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";

export type CorporationResultModalProps = {
  title: string;
  description: string;
  totalCount: number;
  errorCount: number;
  visibility: boolean;
  uploadResult: UploadResult;
  onClose: () => void;
};
const CorporationResultModal: React.FC<CorporationResultModalProps> = ({
  title,
  description,
  totalCount,
  errorCount,
  visibility,
  uploadResult,
  onClose,
}) => {
  const isError = useMemo<boolean>(() => {
    return uploadResult === UploadResult.Failed;
  }, [uploadResult]);
  const titleTextClass = useMemo<string>(() => {
    if (isError) {
      return "text-error";
    }
    return "text-subtitle";
  }, [isError]);

  const descriptionTextClass = useMemo<string>(() => {
    if (isError) {
      return "text-error";
    }
    return "";
  }, [isError]);
  return (
    <ModalUI visibility={visibility}>
      <div className="px-5 pt-5 pb-8 text-center">
        <h2
          className={`text-center text-lg font-bold tracking-wider ${titleTextClass}`}
        >
          {title}
        </h2>
        <hr className="border-under-line mt-4" />
        <div
          className={`mt-4 text-base tracking-wider leading-normal ${descriptionTextClass}`}
          data-testid="completed-modal-description"
        >
          {description}
        </div>
        {isError || (
          <div className="text-base tracking-wider leading-normal">
            <span>{totalCount}件</span>
            {errorCount > 0 && (
              <span className="text-error">(エラー：{errorCount}件)</span>
            )}
          </div>
        )}
        <div className="mt-6 flex flex-row gap-5 justify-center">
          <ButtonUI
            onClick={onClose}
            buttonType="secondary"
            size="custom"
            className="w-44 h-10"
          >
            閉じる
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default CorporationResultModal;
