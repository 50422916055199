import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import {
  Maybe,
  Payment,
  PaymentsTableRowFragment,
  PaymentType,
} from "../../../../generated/graphql";
import {
  gmoFirstPaymentStatuses,
  GmoFirstPaymentStatusLabel,
} from "../../../../lib/constants/GmoFirstPaymentStatus";
import {
  gmoRecurringPaymentStatuses,
  GmoRecurringPaymentStatusLabel,
} from "../../../../lib/constants/GmoRecurringPaymentStatus";
import {
  paymentStatuses,
  PaymentStatusLable,
} from "../../../../lib/constants/PaymentStatus";
// TODO : local timezone を取れるようにする
dayjs.locale(ja);

export type PaymentsTableRowProps = {
  payment: PaymentsTableRowFragment;
  index: number;
};

export const getPaymentTypeJA = (paymentType: Maybe<String> | undefined) => {
  switch (paymentType) {
    case PaymentType.PayCert:
      return "従量課金";
    case PaymentType.ContBill:
      return "継続課金";
    case PaymentType.ContBillFirst:
      return "初月課金";
    case PaymentType.ContBillNext:
      return "継続課金";
    default:
      return "";
  }
};

export const getPaiedProductName = (
  payment: Pick<
    Payment,
    | "contentName"
    | "contentSytemId"
    | "courseName"
    | "courseSytemId"
    | "planName"
  >
) => {
  if (payment.contentName) {
    return `${payment.contentName}(ID:${payment.contentSytemId})`;
  } else if (payment.courseName) {
    return `${payment.courseName}(ID:${payment.courseSytemId})`;
  }

  if (payment.planName) return payment.planName;

  return "";
};

export const getPaymentStatusJA = (
  paymentStatus: Maybe<number> | undefined
) => {
  if (!paymentStatus) {
    return "";
  }
  const status: PaymentStatusLable | undefined = paymentStatuses.find(
    (item: PaymentStatusLable) => item.value === paymentStatus
  );
  return status && status.label;
};

export const getGmoFirstPaymentStatusJA = (
  gmoFirstPaymentStatus: Maybe<string> | undefined
) => {
  if (!gmoFirstPaymentStatus) {
    return "";
  }
  const status: GmoFirstPaymentStatusLabel | undefined =
    gmoFirstPaymentStatuses.find(
      (item: GmoFirstPaymentStatusLabel) => item.value === gmoFirstPaymentStatus
    );
  return status && status.label;
};

export const getGmoRecurringPaymentStatusJA = (
  gmoRecurringPaymentStatus: Maybe<string> | undefined
) => {
  if (!gmoRecurringPaymentStatus) {
    return "";
  }
  const status: GmoRecurringPaymentStatusLabel | undefined =
    gmoRecurringPaymentStatuses.find(
      (item: GmoRecurringPaymentStatusLabel) =>
        item.value === gmoRecurringPaymentStatus
    );
  return status && status.label;
};

export const getPayService = (payService: Maybe<number> | undefined) => {
  switch (payService) {
    case 0:
      return "かんたん決済";
    case 1:
      return "クレカ決済";
    default:
      return "";
  }
};

// かんたん決済: 0, GMOクレカ決済初月: 1, GMOクレカ決済次月以降: 2
export const getPaymentTable = (
  payService: Maybe<number> | undefined,
  paymentType: Maybe<String> | undefined
) => {
  if (payService === 0) {
    return 0;
  } else if (payService === 1 && paymentType === PaymentType.ContBillFirst) {
    return 1;
  } else if (payService === 1 && paymentType === PaymentType.ContBillNext) {
    return 2;
  } else {
    return 3;
  }
};

const PaymentsTableRowUI: React.FC<PaymentsTableRowProps> = ({
  payment,
  index,
}) => {
  const urlWithQueryParams = {
    pathname: `/payments/${payment.id}`,
    search: `?paymentType=${getPaymentTable(
      payment.payService,
      payment.typename
    )}`,
  };
  return (
    <tr
      data-testid="payments-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className="border-r border-dark-gray pl-5 pr-1">
        <LinkTextUI to={urlWithQueryParams}>{1 + index}</LinkTextUI>
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {payment.payInfoNo}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-right">
        {payment.orderId}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-right">
        {getPayService(payment.payService)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {payment.customerId}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {getPaiedProductName(payment)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-center">
        {getPaymentTypeJA(payment.typename)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-right">
        {payment!.priceValue &&
          Intl.NumberFormat("ja").format(payment!.priceValue)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-right">
        {payment!.priceValueNoTax &&
          Intl.NumberFormat("ja").format(payment!.priceValueNoTax)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-center">
        {getPaymentStatusJA(payment.paymentStatus)}
        {getGmoFirstPaymentStatusJA(payment.gmoFirstPaymentStatus)}
        {getGmoRecurringPaymentStatusJA(payment.gmoRecurringPaymentStatus)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-center">
        {payment.submittedAt && dayjs(payment.submittedAt).format("YYYY/MM/DD")}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1 text-center">
        {payment.soldDateTime &&
          dayjs(payment.soldDateTime).format("YYYY/MM/DD")}
      </td>
    </tr>
  );
};

export default PaymentsTableRowUI;
