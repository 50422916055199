import { useCallback, useState } from "react";

import dayjs from "dayjs";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useGenerateUploadExpertImageUrlMutation,
  useUpdateExpertMutation,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { ErrorType } from "../../../lib/constants/error";
import { isFile, upload } from "../../../lib/utils/uploadImage";
import { ExpertEditFormState } from "../components/ExpertEdit";
import ExpertNewConfirmPage from "./ExpertEditConfirmPage";

const ExpertNewConfirmPageContainer: React.VFC = () => {
  const history = useHistory();
  const { id: expertId } = useParams<{ id: string }>();
  const errorRouter = useErrorRouter();
  const location = useLocation<ExpertEditFormState>();
  const input = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [generateUrl] = useGenerateUploadExpertImageUrlMutation();
  const [updateExpert] = useUpdateExpertMutation({
    variables: {
      input: {
        id: expertId,
        familyName: input.familyName,
        givenName: input.givenName,
        familyNameKana: input.familyNameKana,
        givenNameKana: input.givenNameKana,
        alias: input.alias,
        company: input.company,
        position: input.position,
        title: input.title,
        career: input.career,
        publishedAt: input.publishedAt,
        closedAt: input.closedAt,
        booksListText: input.booksListText?.trim(),
        siteUrl: input.siteUrl?.trim(),
        twitterUrl: input.twitterUrl?.trim(),
        facebookUrl: input.facebookUrl?.trim(),
        instagramUrl: input.instagramUrl?.trim(),
        youtubeUrl: input.youtubeUrl?.trim(),
        wikipediaUrl: input.wikipediaUrl?.trim(),
      },
    },
    onError: (error) => {
      setIsLoading(false);
      if (
        error.graphQLErrors[0]?.extensions?.code ===
        ErrorType.DuplicateExpertAlias
      ) {
        setIsOpenErrorModal(true);
        return;
      }
      errorRouter(error);
    },
    onCompleted: async (data) => {
      // 新たに画像が設定されていない場合はスキップ
      if (
        !(input.profilePhoto instanceof File) &&
        !(input.headshot instanceof File)
      ) {
        setIsLoading(false);
        setIsOpenCompleteModal(true);
        return;
      }

      try {
        const { data: generatedData } = await generateUrl({
          variables: {
            input: {
              expertId: data.updateExpert.id,
              profilePhotoFileName: isFile(input.profilePhoto)
                ? input.profilePhoto.name
                : undefined,
              headShotFileName: isFile(input.headshot)
                ? input.headshot.name
                : undefined,
            },
          },
        });
        await Promise.all(
          [
            {
              data: generatedData?.generateUploadExpertImageUrl.profilePhoto,
              file: input.profilePhoto,
            },
            {
              data: generatedData?.generateUploadExpertImageUrl.headshot,
              file: input.headshot,
            },
          ].map((obj) => upload(obj.data, obj.file))
        );
        setIsLoading(false);
        setIsOpenCompleteModal(true);
      } catch {
        history.push("/error/internalservererror");
      }
    },
  });

  const onUpdateExpert = useCallback(async () => {
    setIsOpenConfirmModal(false);
    setIsLoading(true);
    await updateExpert();
  }, [updateExpert]);

  const handleOnClickRegister = useCallback(() => {
    // 即時公開となる場合は確認モーダルを表示する
    if (dayjs(input.publishedAt).isBefore(dayjs())) {
      setIsOpenConfirmModal(true);
      return;
    }
    onUpdateExpert();
  }, [input.publishedAt, onUpdateExpert]);

  const handleOnBack = useCallback(() => {
    history.push(`/experts/${expertId}/edit`, input);
  }, [expertId, history, input]);

  const handleOnCancelConfirmModal = useCallback(() => {
    setIsOpenConfirmModal(false);
  }, []);

  const clearCompletedModal = useCallback(() => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    // 確認画面に戻らせないようにするためreplace
    history.replace("/experts");
  }, [history, isOpenCompleteModal]);

  if (isLoading) {
    return <LoadingUI title="送信中" />;
  }
  return (
    <>
      <ExpertNewConfirmPage
        onUpdate={handleOnClickRegister}
        onBack={handleOnBack}
        input={input}
      />
      <ConfirmModalUI
        title="確認"
        description="登録内容が即時で反映されますが、よろしいでしょうか？"
        onSubmit={onUpdateExpert}
        onCancel={handleOnCancelConfirmModal}
        visibility={isOpenConfirmModal}
      />
      <CompletedModalUI
        title="編集完了"
        description="編集が完了しました。"
        btnTitle="有識者一覧へ"
        visibility={isOpenCompleteModal}
        onClick={clearCompletedModal}
      />
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        visibility={isOpenErrorModal}
        onClick={() => setIsOpenErrorModal(false)}
      >
        <p>同じURL表記が既に登録されています。</p>
        <p>別のURL表記を設定してください。</p>
      </ErrorModalUI>
    </>
  );
};

export default ExpertNewConfirmPageContainer;
