import React, { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  MeFragment,
  ServiceKind,
  UseableSerivceFragment,
} from "../../generated/graphql";
import { currentServiceKind as currentServiceKindVar } from "../../lib/apollo-client";
import { useCurrentServiceKind } from "../../lib/cache";
import { ReactComponent as Avator } from "../avator.svg";
import { DropdownMenuUI } from "../DropdownMenu";
import { ReactComponent as AeonIcon } from "./header_aeon_logo.svg";
import { ServiceSelect } from "./ServiceSelect";

export type HeaderUIProps = {
  me?: MeFragment;
};

export const HeaderUI: React.VFC<HeaderUIProps> = ({ me }) => {
  const avator = me;
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const history = useHistory();

  const onClickAvator = () => {
    setShowDropdownMenu(true);
  };

  const onHiddenDropdownMenu = () => {
    setShowDropdownMenu(false);
  };

  /**
   * Aeon,ElementarySchool,AuEnglishサービスの選択はできないようにしている
   */
  const useableServiceKinds = useMemo(() => {
    if (!me?.useableServiceKinds) return [];
    return me?.useableServiceKinds.filter((useableServiceKind) => {
      return ![
        ServiceKind.Aeon,
        ServiceKind.ElementarySchool,
        ServiceKind.AuEnglish,
      ].some((val) => val === useableServiceKind.key);
    });
  }, [me?.useableServiceKinds]);

  const currentServiceKind = useCurrentServiceKind() || useableServiceKinds[0];
  useEffect(() => {
    if (!currentServiceKindVar() && currentServiceKind) {
      currentServiceKindVar({
        id: currentServiceKind.id,
        key: currentServiceKind.key,
      });
    }
  }, [currentServiceKind]);

  const handleOnChange = (current: UseableSerivceFragment) => {
    currentServiceKindVar({
      id: current.id,
      key: current.key,
    });
    history.push("/");
  };

  return (
    <header className="flex flex-none justify-between items-center h-16 pl-6 pr-5 bg-white z-50 border-b border-dark-gray">
      <div className="flex items-center">
        <AeonIcon></AeonIcon>
        <div className="ml-4" data-testid="service-select-div">
          <p className="text-sm text-blue-active">選択中のサービス</p>
          <ServiceSelect
            useableServiceKinds={useableServiceKinds}
            currentServiceKindId={currentServiceKind?.id}
            onChange={handleOnChange}
          />
        </div>
      </div>
      {avator && (
        <>
          <div
            className="flex flex-row items-center space-x-4 h-full relative cursor-pointer"
            onClick={onClickAvator}
          >
            <Avator></Avator>
            <span className="text-lg text-blue" data-testid="header-username">
              {avator.familyName} {avator.givenName}
            </span>
            <DropdownMenuUI
              hasUseableServices={
                !!(
                  avator.useableServiceKinds &&
                  avator.useableServiceKinds.length
                )
              }
              visibility={showDropdownMenu}
              onHidden={onHiddenDropdownMenu}
            />
          </div>
        </>
      )}
    </header>
  );
};
