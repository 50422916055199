import React, { MouseEventHandler, useEffect } from "react";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CreateAnnouncementInput } from "../../../generated/graphql";
import { AnnouncementEditUI } from "../components/AnnouncementEdit";

export type AnnouncementsNewPageProps = {
  announcement?: CreateAnnouncementInput;
  onSubmit: SubmitHandler<CreateAnnouncementInput>;
  onClickGoBack: MouseEventHandler<HTMLButtonElement>;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

const AnnouncementsNewPage: React.VFC<AnnouncementsNewPageProps> = ({
  announcement,
  onSubmit,
  onClickGoBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const methods = useForm<CreateAnnouncementInput>({
    reValidateMode: "onSubmit",
    defaultValues: announcement,
  });

  const handleOnSubmit = methods.handleSubmit;

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-4"></hr>

      <div className="mb-10">
        <h1 className="text-1.5xl mb-10 text-title-black">お知らせ新規登録</h1>
        <FormProvider {...methods}>
          <form action="submit" onSubmit={handleOnSubmit(onSubmit)}>
            <AnnouncementEditUI />
            <div className="flex flex-row justify-center space-x-10 mt-20">
              <ButtonUI
                type="button"
                buttonType="secondary"
                onClick={onClickGoBack}
                data-testid="announcements-new-back"
              >
                戻る
              </ButtonUI>
              <ButtonUI type="submit" data-testid="announcements-new-submit">
                確認
              </ButtonUI>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AnnouncementsNewPage;
