import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
} from "react";

import {
  CustomerFilterInput,
  CustomerSearchResultsFragment,
} from "../../generated/graphql";
import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";
import { CustomersTableUI } from "./CustomersTable";

export type CustomerSearchModalUIProps = {
  visibility: boolean;
  customersSearchInput: CustomerFilterInput;
  searchedCustomers: CustomerSearchResultsFragment;
  selectedCustomerId?: string | null;
  selectedCustomerFamilyName?: string | null;
  selectedCustomerGiveName?: string | null;
  selectedCustomerFamilyNameKana?: string | null;
  selectedCustomerGiveNameKana?: string | null;
  selectedCustomerEmail?: string | null;
  selectedCustomerTel?: string | null;
  noUserSelect: boolean;
  onChangeCustomersSearchInput: ChangeEventHandler<HTMLInputElement>;
  onSearchCustomers: FormEventHandler;
  onSelectedCustomer: ChangeEventHandler<HTMLInputElement>;
  onApply: MouseEventHandler<HTMLButtonElement>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
};

const CustomerSearchModalUI = ({
  visibility,
  customersSearchInput,
  searchedCustomers,
  selectedCustomerId,
  selectedCustomerFamilyName,
  selectedCustomerGiveName,
  selectedCustomerFamilyNameKana,
  selectedCustomerGiveNameKana,
  selectedCustomerEmail,
  selectedCustomerTel,
  noUserSelect,
  onChangeCustomersSearchInput,
  onSearchCustomers,
  onSelectedCustomer,
  onApply,
  onCancel,
}: CustomerSearchModalUIProps) => {
  return (
    <ModalUI
      data-testid="customers-search-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-6 px-3">
        <h2
          className="text-2xl font-bold"
          data-testid="customers-search-modal-title"
        >
          会員検索
        </h2>
        <hr className="mt-2" />
        <div className="flex flex-row space-x-6 mt-6 mx-2">
          <div className="flex flex-col w-1/2">
            <div className="flex justify-between items-center mb-3">
              <span
                data-testid="customer-search-modal-name-label"
                className="text-sm text-subtitle w-3/12 font-bold"
              >
                氏名
              </span>
              <input
                data-testid="customers-search-modal-name"
                value={customersSearchInput.customerName}
                placeholder="au 太郎"
                id="customerName"
                className="h-9 leading-5 pl-3 tracking-wider border  border-darkgray rounded w-9/12"
                onChange={onChangeCustomersSearchInput}
              />
            </div>
            <div className="flex justify-between items-center">
              <span
                data-testid="customers-search-modal-kana-label"
                className="text-sm text-subtitle w-3/12 font-bold"
              >
                フリガナ
              </span>
              <input
                data-testid="customers-search-modal-kana"
                value={customersSearchInput.customerNameKana}
                placeholder="エーユー　タロウ"
                id="customerNameKana"
                className="h-9 leading-5 pl-3 tracking-wider border  border-darkgray rounded w-9/12"
                onChange={onChangeCustomersSearchInput}
              />
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <div className="flex items-center mb-3">
              <span
                data-testid="customers-search-modal-email-label"
                className="text-sm text-subtitle w-3/12 font-bold"
              >
                会員ID (メールアドレス)
              </span>
              <input
                data-testid="customers-search-modal-email"
                value={customersSearchInput.memberId}
                placeholder="sample@sample.com"
                id="memberId"
                className="h-9 leading-5 pl-3 tracking-wider border  border-darkgray rounded w-9/12"
                onChange={onChangeCustomersSearchInput}
              />
            </div>
            <div className="flex justify-between items-center">
              <span
                data-testid="customers-search-modal-tel-label"
                className="text-sm text-subtitle w-3/12 font-bold"
              >
                電話番号
                <br />
                (ハイフンなし)
              </span>
              <input
                data-testid="customers-search-modal-tel"
                value={customersSearchInput.tel}
                placeholder="0123456789"
                id="tel"
                className="h-9 leading-5 pl-3 tracking-wider border  border-darkgray rounded w-9/12"
                onChange={onChangeCustomersSearchInput}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-7">
          <ButtonUI
            data-testid="customers-search-modal-search-btn"
            onClick={onSearchCustomers}
          >
            検索
          </ButtonUI>
        </div>
        <div className="flex justify-center w-full px-2">
          {searchedCustomers.totalCount > 0 && (
            <div className="flex flex-col w-full">
              <span
                data-testid="customers-search-modal-search-results-total-count"
                className="mb-2"
              >
                全{searchedCustomers.totalCount}件中 1〜
                {searchedCustomers.nodes.length}件表示
              </span>
              <div className="h-60">
                <CustomersTableUI
                  customers={searchedCustomers}
                  selectedCustomerId={selectedCustomerId}
                  selectedCustomerFamilyName={selectedCustomerFamilyName}
                  selectedCustomerGiveName={selectedCustomerGiveName}
                  selectedCustomerFamilyNameKana={
                    selectedCustomerFamilyNameKana
                  }
                  selectedCustomerGiveNameKana={selectedCustomerGiveNameKana}
                  selectedCustomerEmail={selectedCustomerEmail}
                  selectedCustomerTel={selectedCustomerTel}
                  onSelectedCustomer={onSelectedCustomer}
                ></CustomersTableUI>
              </div>
              {noUserSelect && (
                <p
                  className={`text-error mt-1.5`}
                  data-testid={"customers-search-modal-error-message"}
                >
                  対象者を選択してください。
                </p>
              )}
              <div className="flex justify-center space-x-10 mt-4">
                <ButtonUI
                  data-testid="customers-search-modal-cancel"
                  buttonType="secondary"
                  onClick={onCancel}
                >
                  キャンセル
                </ButtonUI>
                <ButtonUI
                  data-testid="customers-search-modal-select"
                  onClick={onApply}
                >
                  設定
                </ButtonUI>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalUI>
  );
};
export default CustomerSearchModalUI;
