import React, { useCallback } from "react";

import { FieldErrors, UseFormRegister } from "react-hook-form";

import {
  QuestionnaireOptionInput,
  QuestionnaireType,
} from "../../generated/graphql";
import {
  CourseEdit,
  CourseNew,
  CourseQuestionnaire,
} from "../../lib/types/course";
import { ReactComponent as AddIcon } from "../add.svg";
import { ForValidation } from "../CourseEdit";
import { SelectQuestionOptionUI } from "./SelectQuestionnaireOption";

export type CheckQuestionsUIProps = {
  questionnaire: CourseQuestionnaire;
  disabled: boolean;
  onChangeQuestionnaire: (questionnaire: CourseQuestionnaire) => void;
  register: UseFormRegister<CourseNew | CourseEdit>;
  errors: FieldErrors<ForValidation>;
  questionnaireKey: number;
};

const newOption: QuestionnaireOptionInput = {
  name: "",
};

export const CheckQuestionsUI: React.FC<CheckQuestionsUIProps> = ({
  questionnaire,
  disabled = false,
  onChangeQuestionnaire,
  register,
  errors,
  questionnaireKey,
}) => {
  const addOption = useCallback(() => {
    const newOptions: QuestionnaireOptionInput[] = [
      ...questionnaire.options,
      newOption,
    ];
    const newQuestionnaire: CourseQuestionnaire = {
      ...questionnaire,
      options: newOptions,
    };
    onChangeQuestionnaire(newQuestionnaire);
  }, [questionnaire, onChangeQuestionnaire]);

  const delOption = useCallback(
    (delTarget: number) => {
      const newOptions = questionnaire.options.filter(
        (_, index: number) => index !== delTarget
      );
      if (newOptions.length === 0) {
        newOptions.splice(0, 0, newOption);
      }
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        options: newOptions,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  const onChangeInput = useCallback(
    (index: number, value: string) => {
      const newOptions = Array.from(questionnaire.options);
      newOptions.splice(index, 1, {
        name: value,
      });
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        options: newOptions,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  const onValidate = useCallback(
    (index: number, options: QuestionnaireOptionInput) => {
      if (options.name === "") {
        return `選択肢${index + 1} は必須項目です。`;
      }
      const result = questionnaire.options.filter(
        (opt) => opt.name === options.name
      );
      if (result.length > 1) return "同じ回答内容が存在します。";
    },
    [questionnaire]
  );

  return (
    <div className="w-full">
      {questionnaire.options.map(
        (question: QuestionnaireOptionInput, index: number) => (
          <SelectQuestionOptionUI
            key={index}
            index={index}
            questionnaireKey={questionnaireKey}
            disabled={disabled}
            option={question}
            questionnaireType={QuestionnaireType.MultiSelectQuestionnaire}
            onDelete={delOption}
            onChangeInput={onChangeInput}
            errorIdPrefix={`course-questionnaires-${questionnaireKey}-options-${index}-name-error`}
            registerParams={{
              register,
              error:
                errors.questionnaires?.[questionnaireKey]?.options?.[index]
                  ?.name,
              label: `questionnaires.${questionnaireKey}.options.${index}.name`,
              conditions: {
                validate: () => onValidate(index, question),
              },
            }}
          />
        )
      )}
      {!disabled ? (
        <div className="flex justify-end mt-2.5 mb-5 w-11/12 text-blue items-center">
          <button type="button" onClick={addOption}>
            <AddIcon data-testid="" className="inline-block" />
            <p className="inline-block align-middle">選択肢追加</p>
          </button>
        </div>
      ) : (
        <div className="mb-3"></div>
      )}
    </div>
  );
};
