import { useEffect, useState, useCallback } from "react";

import { SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmDeleteModalUI } from "../../../components/ConfirmDeleteModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { LoadingUI } from "../../../components/Loading";
import { SelectOption } from "../../../components/SelectCustomStyle";
import {
  useContactPageQuery,
  useCreateContactHistoryMutation,
  useDeleteContactPageMutation,
  CreateContactHistoryInput,
  ContactStatus,
  ContactHistory,
  ServiceKind,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { ChangeContactHistoryHandler } from "./components/ContactStatus";
import ContactPage from "./ContactPage";

type ContactPageParams = {
  id: string;
};

type ConfirmErrorConfig = {
  title: string;
  description: string;
  btnTitle: string;
};

type CompletedConfig = {
  title: string;
  description: string;
  btnTitle: string;
};

const ContactPageContainer = () => {
  const history = useHistory();
  const { id } = useParams<ContactPageParams>();
  const [contactHistoryInput, setContactHistoryInput] =
    useState<CreateContactHistoryInput>({
      contactId: "",
      oldStatus: ContactStatus.Unsupported,
      status: ContactStatus.Unsupported,
      serviceKind: ServiceKind.Aeon,
    });
  const { data, loading, error } = useContactPageQuery({
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (
        data &&
        data.contact.histories &&
        data.contact.histories[0] &&
        data.contact.histories[0].status
      ) {
        setContactHistoryInput({
          contactId: id,
          oldStatus: data.contact.histories[0].status,
          status: data.contact.histories[0].status,
          serviceKind: data.contact.serviceKind,
        });
      } else if (data && data.contact) {
        setContactHistoryInput({
          contactId: id,
          oldStatus: ContactStatus.Unsupported,
          status: ContactStatus.Unsupported,
          serviceKind: data.contact.serviceKind,
        });
      }
    },
  });
  const [contactHistories, setContactHistories] = useState<ContactHistory[]>(
    []
  );
  const [createContactHistory] = useCreateContactHistoryMutation({
    onCompleted: () => {
      setIsOpenCompleteModal(true);
    },
  });
  const [deleteContact] = useDeleteContactPageMutation({
    onCompleted: () => {
      setIsOpenCompleteModal(true);
    },
  });

  const [confirmErrorConfig, setConfirmErrorConfig] =
    useState<ConfirmErrorConfig>({
      title: "",
      description: "",
      btnTitle: "",
    });
  const [completedConfig, setCompletedConfig] = useState<CompletedConfig>({
    title: "",
    description: "",
    btnTitle: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] =
    useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [contactServiceKinds, setContactServiceKinds] = useState<
    SelectOption[]
  >([]);

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  useEffect(() => {
    if (data?.contact.histories) {
      setContactHistories(data.contact.histories!);
    }
  }, [data?.contact.histories]);
  useEffect(() => {
    return () => {
      isOpenConfirmDeleteModal && setIsOpenConfirmDeleteModal(false);
    };
  }, [isOpenConfirmDeleteModal]);
  useEffect(() => {
    return () => {
      isOpenCompleteModal && setIsOpenCompleteModal(false);
    };
  }, [isOpenCompleteModal]);

  const clearCompletedModal = useCallback(() => {
    setIsOpenCompleteModal(false);
    history.push("/contacts");
  }, [history]);

  const onChangeContactHistory: ChangeContactHistoryHandler = useCallback(
    (contactInput: CreateContactHistoryInput) => {
      setContactHistoryInput(contactInput);
    },
    []
  );

  const onCreateContactHistory: SubmitHandler<CreateContactHistoryInput> =
    useCallback(async () => {
      setIsLoading(true);
      try {
        Object.assign(contactHistoryInput, { contactId: id });
        await createContactHistory({
          variables: {
            input: contactHistoryInput,
          },
        });
        setCompletedConfig({
          title: "更新完了",
          description: "対応履歴を更新しました。",
          btnTitle: "お問い合わせ一覧へ",
        });
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setConfirmErrorConfig({
          title: "登録に失敗しました。",
          description: `お問い合わせ履歴の登録に失敗しました。
          しばらく時間を置いたのちに、もう一度お試しください。`,
          btnTitle: "閉じる",
        });
        !isOpenErrorModal && setIsOpenErrorModal(true);
        return;
      } finally {
        setIsLoading(false);
      }
    }, [
      id,
      isOpenErrorModal,
      contactHistoryInput,
      createContactHistory,
      history,
    ]);

  const confirmDelete = useCallback(() => {
    setIsOpenConfirmDeleteModal(true);
  }, [setIsOpenConfirmDeleteModal]);

  const handleDelete = useCallback(async () => {
    setIsOpenConfirmDeleteModal(false);
    setIsLoading(true);
    try {
      await deleteContact({
        variables: {
          id: id,
        },
      });
      setCompletedConfig({
        title: "削除完了",
        description: "削除が完了しました。",
        btnTitle: "お問い合わせ一覧へ",
      });
    } catch (error: any) {
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      setConfirmErrorConfig({
        title: "削除に失敗しました。",
        description: `お問い合わせ情報の削除に失敗しました。
        しばらく時間を置いたのちに、もう一度お試しください。`,
        btnTitle: "閉じる",
      });
      !isOpenErrorModal && setIsOpenErrorModal(true);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [id, isOpenErrorModal, deleteContact, history]);

  const clearErrorModal = () => {
    isOpenErrorModal && setIsOpenErrorModal(false);
  };

  useEffect(() => {
    // TODO: 直書きした結果、UT側でpushが呼ばれ続ける事象が発生してたのでuseEffectで対応。
    if (!error) {
      return;
    }
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (!errCode) {
      // エラーコードを取れない場合は内部サーバエラーに飛ばす
      history.push("/error/internalservererror");
    } else if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    }
  }, [error, history]);

  useEffect(() => {
    if (!data && !loading) {
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      console.error("No Data");
      history.push("/error/internalservererror");
    }
  }, [data, loading, history, error]);

  useEffect(() => {
    setContactServiceKinds(
      data?.services.map((service) => ({
        value: service.key,
        label: service.shortName,
      })) || []
    );
  }, [data?.services]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return data ? (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <ContactPage
        contact={data.contact}
        contactHistories={contactHistories}
        contactHistoryInput={contactHistoryInput}
        contactServiceKinds={contactServiceKinds}
        onChangeContactHistory={onChangeContactHistory}
        onCreateContactHistory={onCreateContactHistory}
        handleDelete={confirmDelete}
      />
      <ConfirmDeleteModalUI
        title="削除確認"
        description={`お問い合わせを完全に削除します。
  本当に削除してよろしいですか？`}
        onCancel={() => {
          isOpenConfirmDeleteModal && setIsOpenConfirmDeleteModal(false);
        }}
        onSubmit={handleDelete}
        visibility={isOpenConfirmDeleteModal}
      />
      <CompletedModalUI
        title={completedConfig.title}
        description={completedConfig.description}
        btnTitle={completedConfig.btnTitle}
        onClick={clearCompletedModal}
        visibility={isOpenCompleteModal}
      />
      <ConfirmErrorModalUI
        title={confirmErrorConfig.title}
        description={confirmErrorConfig.description}
        btnTitle={confirmErrorConfig.btnTitle}
        onClick={clearErrorModal}
        visibility={isOpenErrorModal}
      />
    </>
  ) : (
    <></>
  );
};
export default ContactPageContainer;
