import React from "react";

import { AnsweredCoursesFragment } from "../../../../generated/graphql";
import { AnsweredCoursesTableRowFragment } from "../../../../generated/graphql";
import { CourseQuestionnaire } from "../../../../lib/types/course";
import QuestionnairesTableRowUI from "./QuestionnairesTableRow";

export type QuestionnairesTableUIProps = {
  hasAdminAuthority: boolean;
  answeredCourses: AnsweredCoursesFragment;
  onPreview: (questionnaires: CourseQuestionnaire[]) => void;
  onClickDownload: (courseId: string) => void;
};

export const QuestionnairesTableUI: React.FC<QuestionnairesTableUIProps> = ({
  hasAdminAuthority,
  answeredCourses,
  onPreview,
  onClickDownload,
}) => {
  return (
    <table className={`w-full border border-collapse border-dark-gray`}>
      <thead className="bg-subtitle text-white">
        <tr className="text-left h-12">
          <th className="border border-dark-gray w-14 text-center">項番</th>
          <th className="border border-r-0 border-dark-gray w-auto pl-5">
            対応商品
          </th>
          <th className="w-80 border-dark-gray border-b"></th>
        </tr>
      </thead>
      <tbody>
        {answeredCourses.nodes.map(
          (answeredCourse: AnsweredCoursesTableRowFragment, index: number) => (
            <QuestionnairesTableRowUI
              hasAdminAuthority={hasAdminAuthority}
              data-testid="questionnaires-table-row"
              key={answeredCourse.id}
              index={index}
              answeredCourse={answeredCourse}
              onPreview={onPreview}
              onClickDownload={onClickDownload}
            ></QuestionnairesTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
