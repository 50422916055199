import React, { InputHTMLAttributes } from "react";

import styles from "./Radio.module.css";

export type RadioUIProps = InputHTMLAttributes<HTMLInputElement>;

export const RadioUI = React.forwardRef<HTMLInputElement, RadioUIProps>(
  ({ className, children, id, ...rest }, ref) => {
    return (
      <div
        className={`flex items-center ${
          className !== undefined ? className : ""
        }`}
      >
        <input
          ref={ref}
          className={`appearance-none mr-2.5 ${styles.check}`}
          id={id}
          type="radio"
          {...rest}
        />
        <label htmlFor={id}>{children}</label>
      </div>
    );
  }
);
