import React, { FormEventHandler, MouseEventHandler, useCallback } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { useHistory } from "react-router-dom";

import { teachingMaterial } from "..";
import { ButtonUI } from "../../../../components/Button";
import lessonIcon from "../../../../components/lessonIcon.svg";
import LinkTextUI from "../../../../components/LinkText/LinkText";
import { TextUI } from "../../../../components/Text";
import { LessonItemRowFragment } from "../../../../generated/graphql";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as DownloadBlueIcon } from "./downloadBlue.svg";
import { ReactComponent as DownloadGrayIcon } from "./downloadGray.svg";
import styles from "./LessonsItem.module.scss";

dayjs.locale(ja);

export type LessonItemProps = {
  lesson: LessonItemRowFragment;
  isOperationMonitor: boolean;
  openTeachingMaterialsModal: (
    courseTitle: string,
    teachingMaterials: teachingMaterial[]
  ) => void;
};

const LessonsItemUI: React.FC<LessonItemProps> = ({
  lesson,
  isOperationMonitor,
  openTeachingMaterialsModal,
}) => {
  const history = useHistory();
  const onSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push(`/lessons/${lesson.id}`);
    },
    [history, lesson]
  );

  const onStartLesson: MouseEventHandler = useCallback(() => {
    const win = window.open(lesson.lessonUrl || "", "_blank");
    win?.focus();
  }, [lesson]);

  const getScheduledDate = useCallback((startedAt: Date, endedAt: Date) => {
    return (
      startedAt &&
      endedAt &&
      `${dayjs(startedAt).format("YYYY/MM/DD(dd) HH:mm")} - ${dayjs(
        endedAt
      ).format("HH:mm")}`
    );
  }, []);

  return (
    <div className={styles.container} data-testid="lessons-item">
      <form onSubmit={onSubmit}>
        <div className="">
          <div className="m-5">
            <div className="ml-2 mt-2">
              <img
                className="w-6 h-6 object-cover inline-block"
                src={lessonIcon}
                alt="lessonIcon"
              />
              <TextUI
                data-testid="lesson-item-schedule-date"
                className="inline-block pl-3 align-middle"
              >
                {getScheduledDate(lesson.startedAt, lesson.endedAt)}
              </TextUI>
            </div>
            <div className="ml-2 mt-2">
              <p
                data-testid="lesson-item-course-name"
                className={`${styles.title} ${styles.titleCourse}`}
              >
                {lesson.clazz?.course?.name}
              </p>
              <p
                data-testid="lesson-item-course-systemId"
                className="text-xl inline-block align-top pl-1"
              >
                (ID:
                <LinkTextUI
                  className="inline-block"
                  to={`/courses/${lesson.clazz?.course?.id}`}
                >
                  {lesson.clazz?.course?.systemId}
                </LinkTextUI>
                )
              </p>
            </div>
            <div className="inline-block ml-2 mt-2">
              <TextUI
                data-testid="lesson-item-class-name"
                className={`${styles.title} ${styles.titleClass}`}
              >
                {lesson.clazz?.name}
              </TextUI>
              <TextUI
                data-testid="lesson-item-class-systemId"
                className="inline-block align-top pl-1"
              >
                (ID:
                <LinkTextUI
                  to={`/courses/${lesson.clazz?.course?.id}/classes/${lesson.clazz?.id}`}
                >
                  {lesson.clazz?.systemId}
                </LinkTextUI>
                )
              </TextUI>
            </div>
            <div className="ml-2 mt-2">
              <TextUI
                data-testid="lesson-item-lesson-order"
                className={`${styles.title} ${styles.titleLesson}`}
              >
                第{lesson.lessonMaster.order}回&nbsp;
                {lesson.lessonMaster.name}
              </TextUI>
            </div>
            <div className="ml-2 mt-2">
              <span className="inline-block">
                {lesson.participantsCount}/{lesson.clazz?.capacity}名
              </span>
            </div>
            {!isOperationMonitor && (
              <div>
                {lesson.clazz?.course?.teachingMaterials?.length === 0 ? (
                  <button
                    data-testid="lessons-item-teachingMaterials-disabled-btn"
                    type="button"
                    disabled={true}
                    className={`bg-white border-solid border-2 border-gray-dark w-10/12 mx-auto h-10 mt-5 flex items-center justify-center ${styles.btnDownload} ${styles.tooltipContainer} ${styles.rounded20}`}
                  >
                    <div className={`${styles.tooltip}`}>
                      <span>教材が登録されていません。</span>
                    </div>
                    <DownloadGrayIcon className={`mr-3 w-6 h-6`} />
                    <span className="text-gray-dark">教材ダウンロード</span>
                  </button>
                ) : (
                  <ButtonUI
                    data-testid="lessons-item-teachingMaterials-btn"
                    size="custom"
                    type="button"
                    buttonType="secondary"
                    className={`w-10/12 rounded-lg mx-auto h-10 mt-5 flex items-center justify-center ${styles.btnDownload}`}
                    base="rounded20"
                    onClick={() => {
                      openTeachingMaterialsModal(
                        lesson.clazz?.course?.name!,
                        lesson.clazz?.course?.teachingMaterials!
                      );
                    }}
                  >
                    <DownloadBlueIcon
                      className={`icon mr-3 w-6 h-6 ${styles.icon}`}
                    />
                    <DownloadIcon
                      className={`mr-3 w-6 h-6 ${styles.iconActive}`}
                    />
                    教材ダウンロード
                  </ButtonUI>
                )}
                <div className="ml-2 mt-6 flex justify-center">
                  <ButtonUI
                    data-testid="lessons-item-participants-info-btn"
                    buttonType="primary"
                    size="xs"
                    className={`mr-2 inline-flex items-center justify-items-center justify-center`}
                  >
                    受講者情報
                  </ButtonUI>
                  <ButtonUI
                    data-testid="lessons-item-start-lesson-btn"
                    type="button"
                    size="xs"
                    onClick={onStartLesson}
                    disabled={!lesson.lessonUrl}
                    className={`ml-2 inline-flex items-center justify-items-center justify-center ${styles.tooltipContainer}`}
                  >
                    {!lesson.lessonUrl && (
                      <div className={`${styles.tooltip}`}>
                        <span>URLが登録されていません</span>
                      </div>
                    )}
                    授業開始
                  </ButtonUI>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default LessonsItemUI;
