import React, { InputHTMLAttributes } from "react";

import { ReactComponent as ErrorCheckIcon } from "../../../../../components/errorCheck.svg";

export type FeaturedVideoInputUIProps = {
  error?:
    | boolean
    | {
        message?: string;
      };
} & InputHTMLAttributes<HTMLInputElement>;

export const FeaturedVideoInputUI = React.forwardRef<
  HTMLInputElement,
  FeaturedVideoInputUIProps
>(({ className = "", error, children, ...rest }, ref) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-3 relative">
        <input
          ref={ref}
          className={`h-12 leading-5 pl-4 tracking-wider border rounded ${className} 
          ${error ? "border-error" : ""}
          `}
          {...rest}
        />
        {error && <ErrorCheckIcon className="absolute right-4" />}
      </div>
      {typeof error !== "boolean" && error?.message && (
        <p className="text-error mt-2.5">{error?.message}</p>
      )}
    </div>
  );
});
