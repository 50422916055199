import React, {
  useEffect,
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
} from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  PublishClazzFragment,
  PublishCoursesFragment,
  PublishCoursesItemFragment,
} from "../../../generated/graphql";
import CoursesPage from "./CoursesPage";
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "公開管理",
  },
];

export type PublicationsPageProps = {
  loading: boolean;
  courses: PublishCoursesFragment;
  searchFilter: string;
  offset: number;
  onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement>;
  onClickDeleteHandler: MouseEventHandler;
  onSearch: FormEventHandler;
  onDisplayMore: () => void;
  onOpenPublishModal: (courseId: string, clazz: PublishClazzFragment) => void;
  changePreviewToggle: (course: PublishCoursesItemFragment) => Promise<void>;
};

const PublicationsPage: React.FC<PublicationsPageProps> = ({
  loading,
  courses,
  searchFilter,
  offset,
  onChangeSearchFilterHandler,
  onClickDeleteHandler,
  onSearch,
  onDisplayMore,
  onOpenPublishModal,
  changePreviewToggle,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">公開管理</h1>
      <hr className="border-dark-gray border mb-10"></hr>
      <CoursesPage
        courses={courses}
        searchFilter={searchFilter}
        offset={offset}
        loading={loading}
        onSearch={onSearch}
        onChangeSearchFilterHandler={onChangeSearchFilterHandler}
        onClickDeleteHandler={onClickDeleteHandler}
        onDisplayMore={onDisplayMore}
        onOpenPublishModal={onOpenPublishModal}
        changePreviewToggle={changePreviewToggle}
      />
    </div>
  );
};

export default PublicationsPage;
