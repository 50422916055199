import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useHistory, useLocation } from "react-router-dom";

import { CreateAnnouncementInput } from "../../../generated/graphql";
import { AnnouncementKind } from "../../../lib/constants";
import AnnouncementsNewPage from "./AnnouncementsNewPage";
import { AnnouncementsNewPageProps } from "./AnnouncementsNewPage";

dayjs.extend(utc);
dayjs.extend(tz);

const AnnouncementsNewPageContainer: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<CreateAnnouncementInput>();

  const [defaultAnnouncement, setDefaultAnnouncement] =
    useState<CreateAnnouncementInput>();

  // AnnouncementsNewPage に渡す初期値を設定
  useEffect(() => {
    if (location.state !== undefined) {
      // ブラウザバック想定
      setDefaultAnnouncement(location.state);
    } else if (defaultAnnouncement === undefined) {
      // 初回１回のみ
      setDefaultAnnouncement({
        kind: AnnouncementKind.News,
        subject: "",
        publishStartedAt: dayjs(new Date())
          .tz("Asia/Tokyo")
          .add(1, "day")
          .startOf("day")
          .toDate(), // 翌日の午前ゼロ時（毎回『年』から全部入力するのは面倒）
        publishEndedAt: dayjs(new Date())
          .tz("Asia/Tokyo")
          .add(8, "day")
          .endOf("day")
          .toDate(), // 翌日から１週間後（毎回『年』から全部入力するのは面倒）
        body: "",
      });
    }
  }, [defaultAnnouncement, location.state]);

  //
  const handleSubmit: AnnouncementsNewPageProps["onSubmit"] = useCallback(
    (announcement) => {
      history.push("/announcement/confirm", announcement);
    },
    [history]
  );

  const handleGoBack: AnnouncementsNewPageProps["onClickGoBack"] =
    useCallback(() => {
      history.push(`/announcements`);
    }, [history]);

  if (defaultAnnouncement === undefined) {
    //　初期化処理が終わるまで画面を表示しない
    return <></>;
  }

  return (
    <AnnouncementsNewPage
      announcement={defaultAnnouncement}
      onSubmit={handleSubmit}
      onClickGoBack={handleGoBack}
    />
  );
};

export default AnnouncementsNewPageContainer;
