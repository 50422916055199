import React, { useState, useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";

import {
  useCreateExternalUserMutation,
  useGenerateUploadAvatorUrlMutation,
  CreateExternalUserInput,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { UserEdit } from "../../../lib/types";
import UsersExternalNewConfirmPage from "./UsersExternalNewConfirmPage";

const confirmErrorModalDescriptionForNormal = `教師スタッフの情報登録に失敗しました。
  しばらく時間を置いてから、もう一度お試しください。`;
const confirmErrorModalDescriptionForRoles = `教師スタッフの情報登録に失敗しました。
  ステータス有効な管理者が0名となる変更となっています。
  1名以上となるようにしてください。`;

const UsersExternalNewConfirmPageContainer = () => {
  const history = useHistory();
  const [createExternalUser] = useCreateExternalUserMutation();
  const [generateUploadAvatorUrl] = useGenerateUploadAvatorUrlMutation();
  const location = useLocation<{
    user: UserEdit;
  }>();
  const { user } = location.state;
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalDescription, setConfirmErrorModalDescription] =
    useState<string>(confirmErrorModalDescriptionForNormal);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const uploadAvator = useCallback(
    async (userId: string): Promise<void> => {
      const presignedPostResult = await generateUploadAvatorUrl({
        variables: {
          userId,
          fileName: user.avator?.name as string,
        },
      });
      const presignedPostData =
        presignedPostResult.data?.generateUploadAvatorUrl;
      if (!presignedPostData) {
        return;
      }
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
        formData.append("file", user.avator!);
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${presignedPostData.url}`, true);
        xhr.send(formData);
        xhr.onload = function () {
          this.status === 204 ? resolve() : reject(this.responseText);
        };
      });
    },
    [generateUploadAvatorUrl, user.avator]
  );

  const handleCreate = useCallback(async () => {
    const input: CreateExternalUserInput = {
      userId: user.userId,
      familyName: user.familyName,
      givenName: user.givenName,
      familyNameKana: user.familyNameKana,
      givenNameKana: user.givenNameKana,
      tel: user.tel,
      gender: user.gender,
      birthday: user.birthday,
      birthplace: user.birthplace,
      yearsOfTeaching: user.yearsOfTeaching ? +user.yearsOfTeaching : null,
      message: user.message,
      selfIntroduction: user.selfIntroduction,
      hobby: user.hobby,
      status: user.status,
    };

    try {
      const newUser = await createExternalUser({
        variables: {
          input,
        },
      });
      if (user.avator && newUser.data?.createExternalUser) {
        await uploadAvator(newUser.data?.createExternalUser.id);
      }

      setIsOpenCompleteModal(true);
    } catch (error: any) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UpdateUserFailedRoles) {
        setConfirmErrorModalDescription(confirmErrorModalDescriptionForRoles);
      } else if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      setConfirmErrorModalVisibility(true);
    }
  }, [history, user, createExternalUser, uploadAvator, setIsOpenCompleteModal]);

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
  };

  const clearErrorModal = () => {
    confirmErrorModalDescription &&
      setConfirmErrorModalDescription(confirmErrorModalDescriptionForNormal);
    confirmErrorModalVisibility && setConfirmErrorModalVisibility(false);
  };

  return (
    <UsersExternalNewConfirmPage
      userEdit={user}
      confirmModalVisibility={confirmModalVisibility}
      isOpenCompleteModal={isOpenCompleteModal}
      confirmErrorModalDescription={confirmErrorModalDescription}
      confirmErrorModalVisibility={confirmErrorModalVisibility}
      handleUpdate={handleCreate}
      clearCompletedModal={clearCompletedModal}
      clearErrorModal={clearErrorModal}
      setConfirmModalVisibility={setConfirmModalVisibility}
    />
  );
};

export default UsersExternalNewConfirmPageContainer;
