import React, { useCallback, useEffect, useState } from "react";

import saveAs from "file-saver";
import Papa, { ParseResult } from "papaparse";

import CorporationResultModal from "../../../components/CorporationResultModal";
import { LoadingUI } from "../../../components/Loading";
import {
  UploadCorporationCsvCheckResult,
  UploadResult,
  useCorporationPageLazyQuery,
  useUploadCorporationCsvCheckResultLazyQuery,
  useUploadCorporationCsvMutation,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import CorporationPage from "./CorporationPage";

/**
 * CSVの列数は固定
 */
const CSV_ELEMENT_LENGTH = 4;

/**
 * デフォルトで表示するページは1ページ目
 */
const DEFAULT_PAGE = 1;

export type CsvRow = {
  corporationName: string;
  email: string;
  aist2StartDate: string;
  aist2EndDate: string;
};

export type CsvUploadResult = {
  fileName: string;
  uploadResult: UploadResult;
  count: number;
  failedCount: number;
  filePath: string;
};

export const getTitle = (uploadResult: UploadResult): string => {
  switch (uploadResult) {
    case UploadResult.Success:
    case UploadResult.ParticalSuccess:
      return "登録完了";
    case UploadResult.Failed:
      return "CSVファイルのアップロードに失敗しました";
    default:
      throw new Error(`invalid status. status: ${uploadResult}`);
  }
};

export const getDescription = (uploadResult: UploadResult): string => {
  switch (uploadResult) {
    case UploadResult.Success:
    case UploadResult.ParticalSuccess:
      return "登録に成功しました。";
    case UploadResult.Failed:
      return "何らかの原因でサーバに接続できませんでした。 しばらくたってから再度お試しください。";
    default:
      throw new Error(`invalid status. status: ${uploadResult}`);
  }
};

export const downloadFile = async (url: string, fileName: string) => {
  try {
    if (url.length === 0 || fileName.length === 0) {
      throw new Error(`url or fileName length is 0`);
    }
    await fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }
        throw new Error(`status: ${res.statusText}`);
      })
      .then((blob) => {
        saveAs(blob, `${fileName}.csv`);
      })
      .catch((error) => {
        throw new Error(error?.message ?? "");
      });
  } catch (error) {
    alert("CSVの取得に失敗しました。");
    throw error;
  }
};

export const replaceCsvRow = (
  results: ParseResult<Array<string>>
): CsvRow[] => {
  const parseList = results.data;
  // ヘッダの情報を削除
  parseList.shift();
  // 空行の削除
  const formattedParseList = parseList.filter(
    (target) => !(target.length === 1 && target[0] === null)
  );
  // データが0件なら失敗
  let isValid = formattedParseList.length !== 0;
  // カラム数が正しくないなら失敗
  if (isValid) {
    isValid = formattedParseList.every(
      (target) => target.length === CSV_ELEMENT_LENGTH
    );
  }
  if (!isValid) {
    alert("CSVの形式が異なります。");
    throw new Error("invalid input csv.");
  }
  return formattedParseList.map((row) => ({
    corporationName: row[0].toString(),
    email: row[1].toString(),
    aist2StartDate: row[2] ? row[2].toString() : "",
    aist2EndDate: row[3] ? row[3].toString() : "",
  }));
};

export const onCompletedParse = (
  fileName: string,
  upload: (
    fileName: string,
    csvRows: CsvRow[]
  ) => Promise<UploadCorporationCsvCheckResult | undefined>,
  replaceCsvRow: (results: ParseResult<Array<string>>) => CsvRow[],
  onCompletedUploading: (result: CsvUploadResult) => void
) => {
  return async (results: ParseResult<Array<string>>) => {
    const csvRows = replaceCsvRow(results);
    let resultPorps: CsvUploadResult;
    try {
      const result = await upload(fileName, csvRows);
      if (result) {
        const { uploadResult, count, failedCount, filePath } = result;
        if (!filePath) {
          throw new Error("filePath is undefined.");
        }
        resultPorps = {
          fileName,
          uploadResult,
          count,
          failedCount,
          filePath,
        };
      }
    } catch (error) {
      resultPorps = {
        fileName,
        uploadResult: UploadResult.Failed,
        count: 0,
        failedCount: 0,
        filePath: "",
      };
    }
    onCompletedUploading(resultPorps!);
  };
};

export const substringFileName = (file: File): string => {
  const SUFFIX = ".csv";
  const START_INDEX = 0;
  const name = file.name;
  if (name.endsWith(SUFFIX)) {
    const lastIndex = name.lastIndexOf(SUFFIX);
    const result = name.substring(START_INDEX, lastIndex);
    if (result.length === 0) {
      throw new Error("File name length is 0");
    }
    return result;
  }

  throw new Error("File is not CSV.");
};

export const parseCsv = (
  onCompleteFactory: (
    fileName: string
  ) => (results: ParseResult<Array<string>>) => Promise<void>
) => {
  return (file: File) => {
    const onComplete = onCompleteFactory(substringFileName(file));
    Papa.parse(file, {
      dynamicTyping: true,
      complete: onComplete,
    });
  };
};

export const useUploadingCsv = (
  onCompletedUploading: (result: CsvUploadResult) => void,
  setIsUploading: (loading: boolean) => void
) => {
  const [uploadCorporationCsv] = useUploadCorporationCsvMutation({
    onError: () => {
      setIsUploading(false);
    },
  });

  const [uploadCorporationCsvCheckResult] =
    useUploadCorporationCsvCheckResultLazyQuery();

  const upload = async (
    fileName: string,
    csvRows: CsvRow[]
  ): Promise<UploadCorporationCsvCheckResult | undefined> => {
    setIsUploading(true);
    const result = await uploadCorporationCsv({
      variables: {
        input: {
          fileName,
          csvRows: csvRows,
        },
      },
    });

    // uploadCorporationCsvでエラーが発生した場合throw
    if (result.errors) {
      throw new Error("uploadCorporationCsvでエラー発生");
    }

    // upload結果を取得する
    const corporationCsvId: string | undefined =
      result.data?.uploadCorporationCsv.corporationCsvId;
    if (corporationCsvId) {
      let checkResult = undefined;

      while (true) {
        // 結果を取得
        checkResult = await uploadCorporationCsvCheckResult({
          variables: {
            corporationCsvId,
          },
        });
        // UploadResultがProcessing(処理中)以外、もしくはエラーが返却、データがない場合にループを抜ける
        if (
          checkResult.error ||
          !checkResult.data ||
          checkResult.data.uploadCorporationCsvCheckResult.uploadResult !==
            UploadResult.Processing
        ) {
          break;
        }
        // 1秒待つ
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      setIsUploading(false);
      return checkResult.data?.uploadCorporationCsvCheckResult;
    }

    return undefined;
  };

  const factory = (fileName: string) => {
    return onCompletedParse(
      fileName,
      upload,
      replaceCsvRow,
      onCompletedUploading
    );
  };

  return parseCsv(factory);
};

const CorporationPageContainer: React.FC = () => {
  const errorRouter = useErrorRouter();
  const [visibility, setVisibility] = useState<boolean>(false);
  const [currPage, setCurrPage] = useState<number>(DEFAULT_PAGE);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [csvUploadResult, setCsvUploadResult] = useState<CsvUploadResult>();

  const [fetchCorpoHistory, { loading, data: corpoHistory }] =
    useCorporationPageLazyQuery({
      onError: errorRouter,
      fetchPolicy: "no-cache",
    });

  const onCompletedUploading = useCallback((result: CsvUploadResult) => {
    setCsvUploadResult(result);
    setVisibility(true);
  }, []);

  const uploadFile = useUploadingCsv(onCompletedUploading, setIsUploading);

  useEffect(() => {
    fetchCorpoHistory({ variables: { page: currPage } });
  }, [currPage, fetchCorpoHistory]);

  if (loading) {
    return <LoadingUI>Loading...</LoadingUI>;
  }

  if (isUploading) {
    return <LoadingUI title="アップロード中" />;
  }

  return (
    <>
      <CorporationPage
        corpoCsvList={corpoHistory?.corporationCsvUploadHistory?.csvData ?? []}
        currentPage={currPage}
        totalPage={
          corpoHistory?.corporationCsvUploadHistory?.maxPageNum ?? DEFAULT_PAGE
        }
        pushPage={(pageNumber: number) => setCurrPage(pageNumber)}
        nextPage={() => setCurrPage((page) => page + 1)}
        prevPage={() =>
          setCurrPage((page) => {
            const prev = page - 1;
            return prev < 1 ? 1 : prev;
          })
        }
        uploadFile={uploadFile}
        onCsvDownload={downloadFile}
      />

      {csvUploadResult && (
        <CorporationResultModal
          title={getTitle(csvUploadResult.uploadResult)}
          description={getDescription(csvUploadResult.uploadResult)}
          totalCount={csvUploadResult.count}
          errorCount={csvUploadResult.failedCount}
          uploadResult={csvUploadResult.uploadResult}
          visibility={visibility}
          onClose={async () => {
            await fetchCorpoHistory({ variables: { page: currPage } });
            setVisibility(false);
          }}
        />
      )}
    </>
  );
};

/**
 * UT用
 */
export const _test = {
  replaceCsvRow,
  downloadFile,
  getDescription,
  getTitle,
};

export default CorporationPageContainer;
