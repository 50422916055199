import React, { useEffect, useState } from "react";

import { useBreadcrumb, Breadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { LIBERAL_ARTS_URL } from "../const";

import { FeaturedVideosState } from ".";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "注目の動画管理",
  },
];

export type FeaturedVideosEditConfirmPageProps = FeaturedVideosState & {
  handleRegister: React.MouseEventHandler<HTMLButtonElement>;
  handleBack: React.MouseEventHandler<HTMLButtonElement>;
};

const FeaturedVideosEditConfirmPage: React.VFC<
  FeaturedVideosEditConfirmPageProps
> = ({ featuredVideos, handleRegister, handleBack }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [objectUrls] = useState<(string | undefined)[]>(
    featuredVideos.map((featuredVideo) =>
      featuredVideo.file === "default"
        ? undefined
        : URL.createObjectURL(featuredVideo.file)
    )
  );

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">注目の動画管理</h1>
      <hr className="border-gray border mb-2" />
      <h2 className="text-1.5xl">注目の動画差し替え</h2>

      <div className="mt-10 space-y-10">
        {featuredVideos.map((featuredVideo, index) => (
          <div key={index} data-testid="featured-video-edit-confirm">
            <p
              className="font-bold text-1.5xl"
              data-testid="featured-video-index"
            >
              {index + 1}枚目
            </p>
            <hr className="mt-2 border-b-1 border-under-line" />
            <div className="mt-4 divide-y space-y-5 divide-under-line">
              <div>
                <p className="text-lg mr-2.5 font-bold">カード画像</p>
                <div className="mt-2.5">
                  <div className="flex flex-row">
                    <img
                      className="mr-5"
                      key={index}
                      src={
                        featuredVideo.file === "default"
                          ? featuredVideo.thumbnailUrl
                          : URL.createObjectURL(featuredVideo.file)
                      }
                      alt={
                        featuredVideo.file === "default"
                          ? featuredVideo.fileName
                          : featuredVideo.file.name
                      }
                      onLoad={() => {
                        const url = objectUrls[index];
                        url && URL.revokeObjectURL(url);
                      }}
                      onError={() => {
                        const url = objectUrls[index];
                        url && URL.revokeObjectURL(url);
                      }}
                      data-testid="featured-video-edit"
                    />
                    <p className="mt-4 text-xs text-dark-gray">
                      アップロード可能なファイルサイズは最大10MBです
                      <br />
                      340px x 293pxの画像を指定してください
                    </p>
                  </div>
                  <p
                    className="mt-2"
                    data-testid="featured-video-edit-confirm-file-name"
                  >
                    {featuredVideo.file === "default"
                      ? featuredVideo.fileName
                      : featuredVideo.file.name}
                  </p>
                </div>
              </div>

              <div className="pt-5">
                <p className="text-lg mr-2.5 mb-2.5 font-bold">遷移先URL</p>
                <div className="flex flex-row items-center">
                  <p className="flex-none text-dark-gray">{LIBERAL_ARTS_URL}</p>
                  <span data-testid="featured-video-edit-confirm-alias">
                    {featuredVideo.alias}
                  </span>
                </div>
              </div>

              <div className="pt-5">
                <p className="text-lg mr-2.5 mb-2.5 font-bold">
                  イベントアクション名
                </p>
                <p data-testid="featured-video-edit-confirm-name">
                  {featuredVideo.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col mt-20 items-center">
        <p>※登録完了後、変更が即時にお客様側へ反映されます。</p>
        <div className="mt-4 mb-28 space-x-10">
          <ButtonUI buttonType="secondary" onClick={handleBack}>
            戻る
          </ButtonUI>
          <ButtonUI type="submit" onClick={handleRegister}>
            登録
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideosEditConfirmPage;
