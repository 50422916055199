import { useState } from "react";

import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import {
  CourseForEditPageFragment,
  CourseImage,
  CourseTeachingMaterial,
  LessonMasterTeachingMaterial,
  LevelFragment,
  OrganizerFragment,
  SubjectSelectorItemFragment,
  useCoursesEditPageQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { CourseEdit } from "../../../lib/types/course";
import CoursesEditPage from "./CoursesEditPage";

type CoursesEditPageParams = {
  id: string;
};

export type CourseEditLocationState = {
  courseEdit: CourseEdit;
  courseImages: CourseImage[];
  teachingMaterials: CourseTeachingMaterial[];
  deleteTeachingMaterialIds: string[];
  orderToLessonMaterialMap: Map<number, LessonMasterTeachingMaterial[]>;
  deleteLessonMasterTeachingMaterialIds: string[];
  deleteCourseImageIds: string[];
};

const CoursesEditPageContaier = () => {
  const { id } = useParams<CoursesEditPageParams>();
  const location = useLocation<CourseEditLocationState>();
  const errorRouter = useErrorRouter();
  const [organizers, setOrganizers] = useState<OrganizerFragment[]>([]);
  const [subjects, setSubjects] = useState<SubjectSelectorItemFragment[]>([]);
  const [levels, setLevels] = useState<LevelFragment[]>([]);
  const [course, setCourse] = useState<CourseForEditPageFragment | undefined>(
    undefined
  );
  const { loading } = useCoursesEditPageQuery({
    variables: {
      id,
      // 固定値なのでqueryの中に埋め込みたかったが
      // graphqlの引数で **が計算できないため引数で渡している
      selectorsLimit: 2 ** 31 - 1,
    },
    // レスポンスの中身がキャッシュを用いられることがありバグのもとになるので、再取得させる。
    fetchPolicy: "no-cache",
    onError: errorRouter,
    onCompleted: (data) => {
      setOrganizers(data.organizers.nodes);
      setSubjects(data.subjects.nodes);
      setLevels(data.levels.nodes);
      if (course === undefined) {
        setCourse(data.course);
      }
    },
  });

  if (course === undefined) {
    return <>loading...</>;
  }

  return (
    <CoursesEditPage
      loadingMasters={loading}
      courseOrigin={course!}
      state={location.state}
      organizers={organizers}
      subjects={subjects}
      levels={levels}
    />
  );
};

export default CoursesEditPageContaier;
