import React from "react";

import {
  VideoLessonRatingSummariesFragment,
  VideoLessonRatingTableRowFragment,
} from "../../../../generated/graphql";
import VideoLessonRatingTableRowUI from "./VideoLessonRatingTableRow";

export type VideoLessonRatingTableProps = {
  ratingData: VideoLessonRatingSummariesFragment;
};

export const VideoLessonRatingTableUI: React.VFC<
  VideoLessonRatingTableProps
> = ({ ratingData }) => {
  return (
    <table className={`w-full border border-collapse border-dark-gray`}>
      <thead className="bg-subtitle text-white">
        <tr className="h-12 text-left ">
          <th className="border border-dark-gray w-12 px-4">ID</th>
          <th className="border border-dark-gray w-64 px-4">シリーズ名</th>
          <th className="border border-dark-gray w-24 px-4">有識者</th>
          <th className="border border-dark-gray w-112 px-4">評価</th>
          <th className="border border-dark-gray w-20 px-2">総合評価</th>
        </tr>
      </thead>
      <tbody>
        {ratingData.nodes.map(
          (videoRating: VideoLessonRatingTableRowFragment, index: number) => (
            <VideoLessonRatingTableRowUI
              lessonRowData={videoRating}
              index={index}
              key={index}
            ></VideoLessonRatingTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
