import { useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { DiscountType } from "../../../generated/graphql";
import DiscountEditUI, { EditingDiscount } from "../components/DiscountEdit";

// 足跡
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "クーポン一覧",
  },
  {
    label: "クーポン登録",
  },
];

export type DiscountNewPageProps = {
  editingDiscount?: EditingDiscount;
};

const DiscountNewPage: React.FC<DiscountNewPageProps> = ({
  editingDiscount,
}) => {
  // 足跡
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const history = useHistory();

  const onConfirm = useCallback(
    (values: EditingDiscount) => {
      history.push("/discount/confirm", values);
    },
    [history]
  );

  return (
    <DiscountEditUI
      isNew={true}
      title="クーポン登録"
      discount={
        editingDiscount === undefined
          ? { type: DiscountType.Coupon } // 新規登録時の初期値
          : editingDiscount // 新規登録編集中のデータ（ブラウザバック時）
      }
      onConfirm={onConfirm}
    />
  );
};

export default DiscountNewPage;
