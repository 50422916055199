import React from "react";

import {
  VideoLessonRatingTableRowFragment,
  VideoRatingTableRowFragment,
} from "../../../../generated/graphql";
import VideoRatingTableRowUI from "./VideoRatingTableRow";

export type VideoRatingTableProps = {
  lessonRowData: VideoLessonRatingTableRowFragment;
};

export const VideoRatingTableUI: React.VFC<VideoRatingTableProps> = ({
  lessonRowData,
}) => {
  return (
    <table className={`border-collapse border-dark-gray w-23/24`}>
      <thead className="bg-subtitle text-white">
        <tr className="text-left h-5">
          <th className="border border-dark-gray w-auto px-2.5">動画名</th>
          <th className="border border-dark-gray w-14 px-2.5">評価</th>
          <th className="border border-dark-gray w-24 px-2.5">評価数</th>
        </tr>
      </thead>
      <tbody>
        {lessonRowData.videoRatingSummaries.map(
          (videoRating: VideoRatingTableRowFragment, index: number) => (
            <VideoRatingTableRowUI
              videoRowData={videoRating}
              key={index}
            ></VideoRatingTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
