import React, { useCallback, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { LoadingUI } from "../../../components/Loading";
import {
  FeaturedVideo,
  useGenerateUploadFeaturedVideoImageUrlMutation,
  useUpdateFeaturedVideosMutation,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { ErrorType } from "../../../lib/constants/error";
import { FeaturedVideoInput } from "../FeaturedVideosEditPage/FeaturedVideosEditPage";
import FeaturedVideosEditConfirmPage from "./FeaturedVideosEditConfirmPage";

export type FeaturedVideoState = FeaturedVideoInput &
  Pick<FeaturedVideo, "fileName" | "thumbnailUrl">;

export type FeaturedVideosState = {
  featuredVideos: FeaturedVideoState[];
};

const FeaturedVideosEditConfirmPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const location = useLocation<FeaturedVideosState>();
  const history = useHistory<FeaturedVideosState>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);

  const featuredVideos = location.state.featuredVideos;

  const [generateUrl] = useGenerateUploadFeaturedVideoImageUrlMutation();
  const [updateFeaturedVideos] = useUpdateFeaturedVideosMutation({
    onCompleted: async (data) => {
      try {
        await Promise.all(
          data.updateFeaturedVideos.map(async (featuredVideo, index) => {
            const file = featuredVideos[index].file;
            if (file === "default") {
              return;
            }

            const presignedPostResult = await generateUrl({
              variables: {
                featuredVideoId: featuredVideo.id,
                fileName: file.name,
                order: featuredVideo.order,
              },
            });

            const presignedPostData =
              presignedPostResult.data?.generateUploadFeaturedVideoImageUrl;

            if (!presignedPostData) {
              return;
            }
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", file);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadCourseImages error");
            });
          })
        );
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        history.push("/error/internalservererror");
      }
      setIsLoading(false);
      setIsOpenCompleteModal(true);
    },
    onError: errorRouter,
  });

  const handleRegister: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      setIsLoading(true);
      updateFeaturedVideos({
        variables: {
          input: featuredVideos.map((featuredVideo) => ({
            name: featuredVideo.name,
            order: featuredVideo.order,
            path: featuredVideo.alias,
          })),
        },
      });
    }, [updateFeaturedVideos, featuredVideos]);

  const handleBack: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/featuredvideos/edit", { featuredVideos });
    }, [history, featuredVideos]);

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    // 確認画面に戻らせないようにするためreplace
    history.replace("/featuredvideos");
  };

  if (isLoading) {
    return <LoadingUI title="送信中" />;
  }

  return (
    <>
      <FeaturedVideosEditConfirmPage
        featuredVideos={featuredVideos}
        handleRegister={handleRegister}
        handleBack={handleBack}
      />
      <CompletedModalUI
        title="差し替え完了"
        description="差し替えが完了しました。"
        btnTitle="注目の動画管理一覧へ"
        onClick={clearCompletedModal}
        visibility={isOpenCompleteModal}
      />
    </>
  );
};

export default FeaturedVideosEditConfirmPageContainer;
