import { useRef, useEffect } from "react";

/**
 * 一定時間間隔で処理を実行するためのReact Hook。
 * バックエンドに一定時間間隔でGraphQL queryを発行する場合などに、使用する。
 *
 * Usage:
 *   const { beginPolling, stopPolling } = usePolling();
 *
 *   ...
 *
 *   // 定期実行を開始する。
 *   beginPolling(
 *     // 処理を実行し、定期実行を継続するかを返す。
 *     () => {
 *       ...
 *       return true;
 *     },
 *     // ポーリングのインターバル（msec）
 *     2000
 *   );
 *
 *   ...
 *
 *   // 強制的に定期実行を停止する。
 *   stopPolling();
 */
export const usePolling = (): {
  /**
   * 定期実行を開始する。
   */
  beginPolling: (
    /**
     * 定期実行する関数。
     * @return true=定期実行を継続する。false=定期実行を終了する。
     */
    execute: () => Promise<boolean> | boolean,
    /**
     * 定期実行のインターバル（msec）。省略した際には1000。
     */
    interval?: number
  ) => void;
  /**
   * 定期実行を停止する。
   */
  stopPolling: () => void;
} => {
  const DEFAULT_INTERVAL = 1000;
  type beginPollingType = ReturnType<typeof usePolling>["beginPolling"];
  type executeType = Parameters<beginPollingType>[0];

  const execute = useRef<executeType | undefined>(undefined);
  const interval = useRef(DEFAULT_INTERVAL);

  const timer = useRef(0);

  const stopPolling = useRef(() => {
    if (timer.current) {
      window.clearTimeout(timer.current);

      execute.current = undefined;
      interval.current = DEFAULT_INTERVAL;
      timer.current = 0;
    }
  });

  const doPollOne = useRef(() => {
    timer.current = window.setTimeout(async () => {
      const exec = execute.current;
      if (!exec) {
        stopPolling.current();
        return;
      }

      if ((await exec()) === false) {
        // falseを受け取ったらpolling自体止まる
        return;
      }

      doPollOne.current();
    }, interval.current);
  });

  const beginPolling = useRef<beginPollingType>((...args) => {
    stopPolling.current();

    execute.current = args[0];
    interval.current = args[1] || DEFAULT_INTERVAL;

    doPollOne.current();
  });

  // アンマウントされる際に、定期実行を停止する。
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      stopPolling.current();
    };
  }, []);

  return {
    beginPolling: beginPolling.current,
    stopPolling: stopPolling.current,
  };
};
