import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { UserEditUI } from "../../../components/UserEdit";
import {
  EmployeeStatus,
  Gender,
  useExistUserLazyQuery,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { PageType } from "../../../lib/constants/pageType";
import { UserEdit } from "../../../lib/types";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "教師スタッフ一覧",
  },
  {
    label: "【外部】教師スタッフ追加",
  },
];

const UsersExternalNewPage = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const [avatorUrl, setAvatorURL] = useState<string | null>(null);
  const [isErrorSelectImage, setIsErrorSelectImage] = useState(false);
  const [isErrorFileSizeOver, setIsErrorFileSizeOver] = useState(false);
  const [isErrorExistsUser, setIsErrorExistsUser] = useState(false);

  const [user, setUser] = useState<UserEdit>({
    familyName: "",
    givenName: "",
    familyNameKana: "",
    givenNameKana: "",
    userId: "",
    status: EmployeeStatus.Active,
    tel: "",
    gender: Gender.None,
    birthday: "",
    email: "",
    birthplace: "",
    yearsOfTeaching: 0,
    message: "",
    selfIntroduction: "",
    hobby: "",
    avator: null,
    extra: true,
  });

  const [getUser, { data, error }] = useExistUserLazyQuery({
    variables: {
      userId: user.userId,
    },
  });

  const onHandleSubmit: SubmitHandler<UserEdit> = useCallback(() => {
    if (isErrorSelectImage || isErrorFileSizeOver) {
      return;
    }
    history.push("/user/external/confirm", {
      user,
    });
  }, [user, history, isErrorSelectImage, isErrorFileSizeOver]);

  const handleBack = () => {
    history.push(`/users`);
  };

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      if (event.target.name === "email") {
        getUser();
        setUser({
          ...user,
          userId: event.target.value,
          email: event.target.value,
        });
      } else {
        setUser({
          ...user,
          [event.target.name]: event.target.value,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const onChangeRadio: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      });
    },
    [user]
  );

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let isFileError = false;
      setIsErrorFileSizeOver(false);
      setIsErrorSelectImage(false);
      if (
        event.target?.files &&
        event.target?.files[0] &&
        event.target?.files[0].size > 10 * 1000 * 1000
      ) {
        setIsErrorFileSizeOver(true);
        isFileError = true;
      }
      if (
        event.target?.files &&
        event.target?.files[0] &&
        !["image/jpeg", "image/png"].includes(event.target?.files[0].type)
      ) {
        setIsErrorSelectImage(true);
        isFileError = true;
      }
      if (!event.target?.files || isFileError) {
        return;
      }
      setUser({
        ...user,
        [event.target.name]: event.target.files && event.target.files[0],
      });
    },
    [user]
  );

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    let avator: string | null = null;

    setAvatorURL(avator);
    return () => {
      avatorUrl && URL.revokeObjectURL(avatorUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (data) {
      if (data["existUser"]) {
        setIsErrorExistsUser(true);
      } else {
        setIsErrorExistsUser(false);
      }
    }
    return () => {};
  }, [data]);

  if (error) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <div>
      <h1 className="text-2xl mb-3">【外部】教師スタッフ追加</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <UserEditUI
        type="user"
        user={user}
        onHandleSubmit={onHandleSubmit}
        onChangeInput={onChangeInput}
        onChangeRoles={() => {}}
        onChangeRadio={onChangeRadio}
        onChangeFile={onChangeFile}
        handleBack={handleBack}
        avatorUrl={avatorUrl}
        isErrorSelectImage={isErrorSelectImage}
        isErrorFileSizeOver={isErrorFileSizeOver}
        pageType={PageType.UsersExternalNewPage}
        isErrorExistsUser={isErrorExistsUser}
      ></UserEditUI>
    </div>
  );
};

export default UsersExternalNewPage;
