import React from "react";

import { Link } from "react-router-dom";

import LinkTextUI from "../../../components/LinkText/LinkText";
import { ReactComponent as PenIcon } from "../../../components/pen.svg";
import {
  EmployeeStatus,
  UsersTableRowFragment,
} from "../../../generated/graphql";

export type UsersTableRowProps = {
  user: UsersTableRowFragment;
  extra: boolean;
};

const StatusLabel = {
  [EmployeeStatus.Active]: "有効",
  [EmployeeStatus.Inactive]: "無効",
};

const statusLabel = (status: EmployeeStatus): string => {
  return StatusLabel[status];
};

const UsersTableRowUI: React.FC<UsersTableRowProps> = ({ user, extra }) => {
  return (
    <tr
      data-testid="users-table-row"
      className="border-b border-under-line h-16"
    >
      <td>
        <Link to={`/users/${user.id}/edit`}>
          <PenIcon></PenIcon>
        </Link>
      </td>
      <td>
        <LinkTextUI to={`/users/${user.id}`}>{user.userId}</LinkTextUI>
      </td>
      <td>
        {user.familyName} {user.givenName}
      </td>
      {!extra && (
        <td className="py-2">
          {user.useableServiceKinds
            ? user.useableServiceKinds.map((serviceKind, i, row) => {
                return (
                  <React.Fragment key={i}>
                    {serviceKind.shortName}
                    {i !== row.length - 1 && <br />}
                  </React.Fragment>
                );
              })
            : ""}
        </td>
      )}
      {!extra && (
        <td>{user.roles ? user.roles.map((r) => r.name).join(", ") : ""}</td>
      )}

      <td>{statusLabel(user.status)}</td>
    </tr>
  );
};

export default UsersTableRowUI;
