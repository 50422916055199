import React from "react";

export type UrlToLinkProps = {
  value: string | null | undefined;
};

/**
 * URLが含まれる文字列を、URL部分をリンクに置換しながらレンダリングする。
 *
 * 例) value = "これはhttps://localhost/example001へのリンクです"
 *    ↓
 *    <>
 *      これは
 *      <a href="https://localhost/example001">https://localhost/example001</a>
 *      へのリンクです
 *    </>
 */
export const UrlToLink: React.VFC<UrlToLinkProps> = ({ value }) => {
  const components = React.useMemo(() => {
    if (!value) {
      return [];
    }

    const result: React.ReactElement[] = [];
    const regexp =
      // eslint-disable-next-line no-useless-escape
      /https?\:\/\/[\-_\.\!\~\*\'\(\)a-zA-Z0-9\;\/\?\:\@\&\=\+\$\,\%\#]+/g;
    let keyIndex = 1;
    let index = 0;
    let match: RegExpExecArray | null;
    while ((match = regexp.exec(value)) !== null) {
      const url = match[0];

      if (index < match.index) {
        result.push(
          <React.Fragment key={keyIndex}>
            {value.substring(index, match.index)}
          </React.Fragment>
        );
        keyIndex++;
      }
      index = match.index + url.length;

      result.push(
        <a
          key={keyIndex}
          href={url}
          className="text-blue hover:underline active:text-blue-active active:underline"
          {...(url.startsWith(window.location.origin)
            ? {}
            : { target: "_blank", rel: "noopener noreferrer" })}
        >
          {url}
        </a>
      );
      keyIndex++;
    }

    if (index < value.length) {
      result.push(
        <React.Fragment key={keyIndex}>{value.substring(index)}</React.Fragment>
      );
      keyIndex++;
    }

    return result;
  }, [value]);

  return <>{components}</>;
};
