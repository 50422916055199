import { useDrag, useDrop } from "react-dnd";

import { ValidatedFile } from "../../lib/types";
import { ReactComponent as ErrorCheck } from "../errorCheck.svg";
import styles from "./ImagesInput.module.scss";

export type DnDThumbProps<ValidatedFile> = {
  file: ValidatedFile;
  url: string;
  itemType: string;
  onDelete: (previewId: string) => void;
  onReselect: (previewId: string) => void;
  moveCard: (id: number, atIndex: number) => void;
  findCard: (id: number) => { index: number };
  setCard: () => void;
};

type Item = {
  id: number;
  originalIndex: number;
};

export const DnDThumb: React.VFC<DnDThumbProps<ValidatedFile>> = ({
  file,
  url,
  itemType,
  onDelete,
  onReselect,
  moveCard,
  findCard,
  setCard,
}) => {
  const originalIndex = findCard(file.previewId).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: itemType,
      item: { id: file.previewId, originalIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [file, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: itemType,
      hover: ({ id: draggedId }: Item, _monitor) => {
        const overIndex = findCard(file.previewId).index;
        moveCard(draggedId, overIndex);
      },
      drop: () => {
        setCard();
      },
    }),
    [file, moveCard]
  );

  return (
    <div
      key={`content-images-thumb-${String(file.previewId)}`}
      className="flex flex-col"
      ref={(node) => preview(drop(node), { captureDraggingState: true })}
    >
      <label htmlFor={String(file.previewId)} className={styles.label}>
        {file.isError ? (
          <>
            <div
              className={`${styles.errorBox} text-error relative`}
              data-testid={`content-images-preview-error-${file.previewId}`}
              style={{
                width: "214px",
                height: "131px",
                objectFit: "cover",
              }}
            >
              <div className="flex justify-center pt-3">
                <ErrorCheck />
              </div>
              {file.errExt && (
                <p className="text-sm ml-3 mr-3">
                  ・.jpg または .png 形式の画像を指定してください。
                </p>
              )}
              {file.errSize && (
                <p className="text-sm ml-3 mr-3">
                  ・ファイルサイズは最大10MBまでです。
                </p>
              )}
              <div className="text-center absolute inset-x-0 bottom-2">
                <span
                  className="text-sm text-blue"
                  data-testid={`content-images-file-reslect-${file.previewId}`}
                  onClick={() => onReselect(String(file.previewId))}
                >
                  ファイルを選び直す
                </span>
              </div>
            </div>
            <div
              data-testid={`content-images-preview-error-close-button-${file.previewId}`}
              className={styles.labelText}
              onClick={() => onDelete(String(file.previewId))}
            >
              <span>×</span>
            </div>
            <p>{file.name ? file.name : ""}</p>
          </>
        ) : (
          <div
            ref={drag}
            className={isDragging ? "bg-light-gray" : "even:bg-light-blue"}
          >
            <div
              className={`${styles.previewBox}`}
              id={file.key}
              data-testid={`content-images-preview-${file.previewId}`}
              style={{
                backgroundImage: `url(${url})`,
                width: "214px",
                height: "131px",
                objectFit: "cover",
              }}
            ></div>
            <div
              data-testid={`content-images-preview-close-button-${file.previewId}`}
              className={styles.labelText}
              onClick={() => onDelete(String(file.previewId))}
            >
              <span>×</span>
            </div>
            <p>{file.name ? file.name : ""}</p>
          </div>
        )}
      </label>
    </div>
  );
};
