import React from "react";

import { ContactHistory } from "../../../../generated/graphql";
import ContactHistoryTableRowUI from "./ContactHistoryTableRow";

export type ContactHistoryTableUIProps = {
  contactHistories?: ContactHistory[];
};

export const ContactHistoryTableUI: React.FC<ContactHistoryTableUIProps> = ({
  contactHistories,
}) => {
  return (
    <div>
      <div className="overflow-y-auto w-full h-48">
        {contactHistories?.map((contactHistory) => (
          <ContactHistoryTableRowUI
            data-testid="contact-history-table-row"
            contactHistory={contactHistory}
            key={contactHistory?.id}
          ></ContactHistoryTableRowUI>
        ))}
      </div>
    </div>
  );
};

export default ContactHistoryTableUI;
