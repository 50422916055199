import React, { HTMLAttributes } from "react";

import { SideBarMenuUI } from "../SideBarMenu";

export type SideBarUIProps = {} & HTMLAttributes<HTMLDivElement>;

export const SideBarUI: React.FC<SideBarUIProps> = ({ className }) => {
  return (
    <div
      className={`overflow-auto bg-light-blue w-44 h-full z-40 ${className}`}
    >
      <div className="flex flex-col">
        <SideBarMenuUI></SideBarMenuUI>
      </div>
    </div>
  );
};
