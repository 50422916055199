import React, { MouseEventHandler } from "react";

import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";

type Props = {
  title: string;
  description: string;
  btnTitle: string;
  visibility: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ConfirmErrorModalUI = ({
  title,
  description,
  btnTitle,
  visibility,
  onClick,
}: Props) => {
  return (
    <ModalUI
      data-testid="confirm-error-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-10 px-3 text-center">
        <h1
          className="text-center text-2xl text-error font-bold tracking-wider"
          data-testid="confirm-error-modal-title"
        >
          {title}
        </h1>
        <hr className="border-error mt-5"></hr>
        <div
          className="mt-5 text-2xl tracking-wider whitespace-pre-wrap"
          data-testid="confirm-error-modal-description"
        >
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
        <ButtonUI
          data-testid="confirm-error-modal-btn"
          className="px-12 mt-10"
          size="auto"
          onClick={onClick}
        >
          {btnTitle}
        </ButtonUI>
      </div>
    </ModalUI>
  );
};
