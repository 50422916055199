import React, { useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import {
  usePaymentDetailQuery,
  usePaymentCourseClazzLazyQuery,
  PaymentCourseClazzFragment,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import PaymentPage from "./PaymentPage";

type PaymentPageParams = {
  id: string;
};

const PaymentPageContainer: React.VFC = () => {
  const { id } = useParams<PaymentPageParams>();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const paymentType = searchParams.get("paymentType")
    ? searchParams.get("paymentType")
    : "0";

  const [courseOrderClazz, setCourseOrderClazz] =
    useState<PaymentCourseClazzFragment>();
  const [clazzQueryKicked, setClazzQueryKicked] = useState<boolean>(false);
  const history = useHistory();

  // 決済詳細情報取得
  const {
    data: paymentData,
    error: paymentError,
    loading: paymentLoading,
    refetch: refetchPayment,
  } = usePaymentDetailQuery({
    variables: {
      input: { id: id, paymentType: paymentType },
    },
  });
  const [getClazz, { error: clazzError, loading: clazzLoading }] =
    usePaymentCourseClazzLazyQuery({
      onCompleted: (data) => {
        setCourseOrderClazz(data.clazz);
      },
    });

  if (paymentLoading) {
    return <LoadingUI />;
  }

  if (paymentError || !paymentData) {
    console.error(paymentError);
    const errCode = paymentError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  if (!paymentData) {
    console.error("No Data");
    history.push("/error/internalservererror");
    return <></>;
  }

  // 決済対象がコースだった場合、クラスに紐づくレッスン情報を取得する
  if (paymentData?.payment?.clazzId) {
    if (!clazzQueryKicked) {
      setClazzQueryKicked(true);
      getClazz({
        variables: {
          id: paymentData.payment.clazzId,
        },
      });
      return <LoadingUI />;
    }
    if (clazzLoading) {
      return <LoadingUI />;
    }
    if (clazzError) {
      console.error(clazzError);
      const errCode = clazzError?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
      return <></>;
    }
  }

  return (
    <PaymentPage
      payment={paymentData.payment}
      clazz={courseOrderClazz}
      refetchPayment={refetchPayment}
    />
  );
};
export default PaymentPageContainer;
