import React, {
  HTMLAttributes,
  useCallback,
  ChangeEvent,
  ChangeEventHandler,
} from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../components/Button";
import { ReactComponent as ErrorCheckIcon } from "../../../../components/errorCheck.svg";
import {
  SelectCustomStyleUI,
  SelectOption,
} from "../../../../components/SelectCustomStyle";
import { ValidatableTextareaUI } from "../../../../components/ValidatableTextarea";
import {
  ContactStatus,
  CreateContactHistoryInput,
} from "../../../../generated/graphql";
import { statusTypes } from "../../../../lib/constants";
import { preventFromSubmittingByEnter } from "../../../../lib/functions";
dayjs.locale(ja);

export type ChangeContactHistoryHandler = (
  contactHistoryInput: CreateContactHistoryInput
) => void;

export type ContactStatusUIProps = {
  contactStatus: ContactStatus;
  contactHistoryInput: CreateContactHistoryInput;
  contactServiceKinds: SelectOption[];
  onChangeContactHistory: ChangeContactHistoryHandler;
  onHandleSubmit: SubmitHandler<CreateContactHistoryInput>;
} & HTMLAttributes<HTMLDivElement>;

export const ContactStatusUI: React.VFC<ContactStatusUIProps> = ({
  contactStatus,
  contactHistoryInput,
  contactServiceKinds,
  onChangeContactHistory,
  onHandleSubmit,
  className,
  ...rest
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateContactHistoryInput>({
    defaultValues: {
      ...contactHistoryInput,
    },
  });

  const onChangeCustomSelect = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      onChangeContactHistory({
        ...contactHistoryInput,
        [name]: value,
      });
    },
    [contactHistoryInput, onChangeContactHistory]
  );

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const idSplits = event.target.id.split("-");
      onChangeContactHistory({
        ...contactHistoryInput,
        [idSplits[1]]: event.target.value,
      });
    },
    [contactHistoryInput, onChangeContactHistory]
  );

  return (
    <div className={className} {...rest}>
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        onKeyDown={(event) => preventFromSubmittingByEnter(event)}
      >
        <div className="flex items-center space-x-3">
          <SelectCustomStyleUI
            data-testid="contact-status-serviceKind"
            className="w-48 mb-10"
            options={contactServiceKinds}
            id="serviceKind"
            errorBorder={errors.serviceKind ? true : false}
            value={`${
              contactServiceKinds.find(
                (serviceKind) =>
                  serviceKind.value === contactHistoryInput.serviceKind
              )?.label
            }`}
            name="serviceKind"
            onChange={onChangeCustomSelect}
          />
          <input className="hidden" {...register("serviceKind")} />
        </div>
        <div className="flex items-center space-x-3 mt-4">
          {errors.status && (
            <div className="mr-3">
              <ErrorCheckIcon data-testid={"contact-status-error-icon"} />
            </div>
          )}
          <SelectCustomStyleUI
            data-testid="contact-status-status"
            className="w-48 mb-2"
            options={statusTypes}
            id="status"
            errorBorder={errors.status ? true : false}
            value={`${
              statusTypes.find(
                (status) => status.value === contactHistoryInput.status
              )?.label
            }`}
            name="status"
            onChange={onChangeCustomSelect}
          />
          <input
            className="hidden"
            {...register("status", {
              validate: () => {
                return (
                  (contactHistoryInput.memo !== "" &&
                    contactHistoryInput.memo !== undefined) ||
                  contactHistoryInput.status !== contactStatus
                );
              },
            })}
          />
        </div>
        <ValidatableTextareaUI
          registerParams={{
            register,
            error: errors.memo,
            label: "memo",
            conditions: {
              maxLength: {
                value: 400,
                message: "４００文字以内で入力してください。",
              },
            },
          }}
          data-testid="contact-status-memo"
          errorIdPrefix="contact-status-memo-error"
          rows={6}
          className="mt-14 w-full px-2.5 h-40"
          placeholder="対応メモ（４００文字）"
          id="history-memo"
          value={contactHistoryInput.memo || ""}
          onChange={onChangeInput}
        />
        {errors.status && (
          <p
            className={`text-error mt-1.5 ${errors.memo ? "pl-8" : ""}`}
            data-testid="contact-status-error-message"
          >
            対応ステータスの変更または対応履歴の記載を行ってください。
          </p>
        )}
        <div className="flex justify-end mt-4">
          <ButtonUI
            data-testid="contact-status-register-btn"
            buttonType="primary"
            base="rounded20"
            type="submit"
            size="xs"
            className="mr-2.5"
          >
            登録
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};

export default ContactStatusUI;
