import React, { useCallback } from "react";

import {
  PaymentCancelable,
  PaymentDetailFragment,
  useCancelAuthorizedCourseMutation,
  usePaymentCancelAuthorizedCourseExecutionStatusLazyQuery,
} from "../../../../../generated/graphql";
import {
  ExecuteStepFunctionsButton,
  ExecuteStepFunctionsButtonProps,
} from "../ExecuteStepFunctionsButton";

export type CancelAuthorizedCourseButtonProps = {
  payment: PaymentDetailFragment;
  refetchPayment: () => void;
};

export const CancelAuthorizedCourseButton: React.VFC<
  CancelAuthorizedCourseButtonProps
> = (props) => {
  if (
    !props.payment.clazzId ||
    props.payment.cancelable !== PaymentCancelable.CancelAuthory
  ) {
    return <></>;
  }

  return <Inner {...props} />;
};

const Inner: React.VFC<CancelAuthorizedCourseButtonProps> = ({
  payment,
  refetchPayment,
}) => {
  // 決済取消
  const [cancelAuthorizedCourse] = useCancelAuthorizedCourseMutation({
    fetchPolicy: "no-cache",
  });
  // 決済取消実行状況取得
  const [getCancelAuthorizedCourseExecutionStatus] =
    usePaymentCancelAuthorizedCourseExecutionStatusLazyQuery({
      fetchPolicy: "no-cache",
    });

  const callMutation: ExecuteStepFunctionsButtonProps["callMutation"] =
    useCallback(async () => {
      const response = await cancelAuthorizedCourse({
        variables: {
          paymentId: payment.id,
        },
      });

      return [
        response.data ? response.data.cancelAuthorizedCourse : undefined,
        response.errors,
      ];
    }, [cancelAuthorizedCourse, payment]);

  const pollExecutionStatus: ExecuteStepFunctionsButtonProps["pollExecutionStatus"] =
    useCallback(
      async (executionARN: string) => {
        const response = await getCancelAuthorizedCourseExecutionStatus({
          variables: {
            executionARN,
          },
        });

        return [
          response.data
            ? {
                status:
                  response.data.cancelAuthorizedCourseExecutionStatus.status,
              }
            : undefined,
          response.error,
        ];
      },
      [getCancelAuthorizedCourseExecutionStatus]
    );

  const onComplete: ExecuteStepFunctionsButtonProps["onComplete"] =
    refetchPayment;

  return (
    <ExecuteStepFunctionsButton
      label="オーソリ取消"
      confirmModal={{
        title: "確認",
        description: "本当にオーソリ取消して、よろしいでしょうか？",
      }}
      callMutation={callMutation}
      pollExecutionStatus={pollExecutionStatus}
      onComplete={onComplete}
    />
  );
};
