import React, { ChangeEventHandler, useCallback } from "react";

import dayjs from "dayjs";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { BadgeUI } from "../../../../components/Badge";
import { ButtonUI } from "../../../../components/Button";
import { ReactComponent as ErrorCheckIcon } from "../../../../components/errorCheck.svg";
import { FormGroupUI } from "../../../../components/FormGroup";
import { LabelUI } from "../../../../components/Label";
import {
  DateInput,
  LessonDateFormUI,
} from "../../../../components/LessonDateForm";
import { RadioUI } from "../../../../components/Radio";
import { TextUI } from "../../../../components/Text";
import { ToggleButtonUI } from "../../../../components/ToggleButton";
import { ValidatableInputUI } from "../../../../components/ValidatableInput";
import { ValidatableRadioUI } from "../../../../components/ValidatableRadio";
import { eventLocations } from "../../../../lib/constants/";
import {
  DisplayClass,
  DisplayCourse,
  DisplayLesson,
  eventLocationType,
  Teacher,
} from "../../../../lib/constants/classes";
import { PageType } from "../../../../lib/constants/pageType";
import { preventFromSubmittingByEnter } from "../../../../lib/functions";
import styles from "./ClassEdit.module.scss";
import { ReactComponent as CloseIcon } from "./close.svg";

export interface ClassEditUIProps {
  pageType: PageType;
  course: DisplayCourse;
  clazzInput: DisplayClass;
  onRemoveTeacher: (lesson: DisplayLesson, teacherId: string) => void;
  onHandleSubmit: SubmitHandler<DisplayClass>;
  onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeLessonInputs: (lessonInputs: DisplayLesson[]) => void;
  onChangeRadioType: ChangeEventHandler<HTMLInputElement>;
  onOpenTeacherListModal: (lesson: DisplayLesson) => void;
  onChangeAutoCreateZoomURL: (required: boolean) => void;
}

export const ClassEditUI: React.VFC<ClassEditUIProps> = ({
  pageType,
  course,
  clazzInput,
  onHandleSubmit,
  onChangeInput,
  onChangeLessonInputs,
  onChangeRadioType,
  onOpenTeacherListModal,
  onRemoveTeacher,
  onChangeAutoCreateZoomURL,
}) => {
  // TODO: 遷移の挙動が決まったらコンポーネント内ではなくindexで書くようにする
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DisplayClass>({
    defaultValues: {
      ...clazzInput,
    },
  });
  /**
   * 各授業の日程と１つ前の授業回の日程と比較して
   * 重複していれば時間が重複している場合falseを返す
   */
  const validateDate = useCallback(
    (targetLesson: DisplayLesson): boolean => {
      // 秒以下は切り捨てる
      const targetStartedAt = dayjs(targetLesson.startedAt!).startOf("minute");
      const targetEndedAt = dayjs(targetLesson.endedAt!).startOf("minute");
      return clazzInput.lessons.every((lesson: DisplayLesson) => {
        if (targetLesson.lessonMasterId === lesson.lessonMasterId) {
          return targetStartedAt.isBefore(targetEndedAt);
        }
        const endedAt = dayjs(lesson.endedAt!).startOf("minute");
        // 該当授業より1つ前の授業と比較して、該当授業の開始時刻が１つ前の授業の終了時刻よりも後である ? 正常 : 異常
        if (targetLesson.lessonMaster.order - lesson.lessonMaster.order === 1) {
          return (
            targetStartedAt.isAfter(endedAt) || targetStartedAt.isSame(endedAt)
          );
        }
        return true;
      });
    },
    [clazzInput]
  );

  const getDate = useCallback(
    (lessonDate: DateInput, fromOrTo: "from" | "to"): Date =>
      dayjs()
        .year(lessonDate.year)
        .month(lessonDate.month - 1)
        .date(lessonDate.date)
        .hour(lessonDate[`${fromOrTo}hour`])
        .minute(lessonDate[`${fromOrTo}minute`])
        .second(0)
        .toDate(),
    []
  );

  const changeLessonInputs = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const [target, lessonMasterId] = event.target.id.split("-") as [
        "lessonUrl" | "anyLessonId",
        string
      ];
      let lessonInput: DisplayLesson = clazzInput.lessons.find(
        (lessonInput: DisplayLesson) =>
          lessonInput.lessonMasterId === lessonMasterId
      )!;
      Object.assign(lessonInput, {
        [target]: event.target.value,
      });
      const lessons: DisplayLesson[] = clazzInput.lessons.map(
        (lesInput: DisplayLesson) => {
          return lesInput.lessonMasterId === lessonInput.lessonMasterId
            ? lessonInput
            : lesInput;
        }
      );
      onChangeLessonInputs(lessons);
    },
    [clazzInput.lessons, onChangeLessonInputs]
  );

  const changeLessonDateInputs = useCallback(
    (id: string, value: DateInput) => {
      const lessonInput: DisplayLesson = clazzInput.lessons.find(
        (lessonInput: DisplayLesson) => lessonInput.lessonMasterId === id
      )!;
      lessonInput.startedAt = getDate(value, "from");
      lessonInput.endedAt = getDate(value, "to");
      const lessons = clazzInput.lessons.map((lesson) => {
        return lesson.lessonMasterId === id ? lessonInput : lesson;
      });
      onChangeLessonInputs(lessons);
    },
    [clazzInput.lessons, getDate, onChangeLessonInputs]
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        onKeyDown={(event) => preventFromSubmittingByEnter(event)}
        className="pb-20"
      >
        <FormGroupUI>
          <LabelUI className="text-lg">システムID</LabelUI>

          <TextUI data-testid="clazz-edit-clazz-system-id">
            {pageType === PageType.ClassNewPage
              ? "登録後に発行されます。"
              : clazzInput.systemId}
          </TextUI>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">システムID:コース名</LabelUI>
            {pageType === PageType.ClassNewPage && <BadgeUI>必須</BadgeUI>}
          </div>
          <div className="flex">
            <TextUI data-testid="clazz-edit-course-system-id-course-name">
              {course.systemId}： {course.name}
            </TextUI>
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">クラス名</LabelUI>
          </div>
          <div className="flex">
            <ValidatableInputUI
              id="name"
              name="name"
              data-testid="clazz-edit-clazz-name"
              errorIdPrefix="clazz-edit-clazz-name-error"
              registerParams={{
                register,
                error: errors.name,
                label: "name",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              value={clazzInput.name!}
              className="w-10/12"
              placeholder="クラス名を入力してください"
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">クラスID</LabelUI>
          </div>
          <div className="flex">
            <ValidatableInputUI
              data-testid="clazz-edit-clazz-any-clazzid"
              errorIdPrefix="clazz-any-clazzid-error"
              registerParams={{
                register,
                error: errors.anyClassId,
                label: "anyClassId",
                conditions: {
                  maxLength: {
                    value: 20,
                    message:
                      "２０文字以内の-, _を含む半角英数字を入力してください。",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9-_]+$/,
                    message:
                      "２０文字以内の-, _を含む半角英数字を入力してください。",
                  },
                },
              }}
              value={clazzInput.anyClassId!}
              className="w-10/12"
              placeholder="クラスIDを入力してください"
              id="anyClassId"
              name="anyClassId"
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI className="text-lg">開催場所</LabelUI>
          <div className="flex items-center flex-shrink-0">
            {errors.eventLocation && (
              <div className="mr-3">
                <ErrorCheckIcon
                  data-testid={"clazz-edit-event-location-error-icon"}
                />
              </div>
            )}
            <div className="w-64">
              <div className="flex space-x-4 h-12">
                {Object.entries(eventLocations).map(([key, val]) => (
                  <ValidatableRadioUI
                    registerParams={{
                      register,
                      error: errors.eventLocation,
                      label: "eventLocation",
                      conditions: {},
                    }}
                    data-testid="clazz-edit-eventLocation"
                    key={val}
                    id={`eventLocation-${val}`}
                    name="eventLocation"
                    value={val}
                    onChange={onChangeRadioType}
                    checked={val === clazzInput.eventLocation!}
                  >
                    {val}
                  </ValidatableRadioUI>
                ))}
              </div>
            </div>
          </div>
        </FormGroupUI>
        <hr />
        {pageType === PageType.ClassNewPage &&
        clazzInput.eventLocation === eventLocations[eventLocationType.Zoom] ? (
          <>
            <FormGroupUI>
              <LabelUI
                className="text-lg"
                data-testid="clazz-edit-clazz-zoom-label"
              >
                ZoomURL自動発行設定
              </LabelUI>
              <div className="flex flex-row">
                <span className="mr-6">URLを発行</span>
                <ToggleButtonUI
                  data-testid="clazz-edit-clazz-zoom-toggle-button"
                  required={clazzInput.autoCreateZoomURL}
                  onCheckChange={onChangeAutoCreateZoomURL}
                />
              </div>
            </FormGroupUI>
            <hr />
          </>
        ) : (
          ""
        )}
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">定員</LabelUI>
            {<BadgeUI>必須</BadgeUI>}
          </div>
          <div className="flex space-x-4">
            <div>
              <ValidatableInputUI
                data-testid="clazz-edit-clazz-capacity"
                errorIdPrefix="clazz-edit-clazz-capacity-error"
                registerParams={{
                  register,
                  error: errors.capacity,
                  label: "capacity",
                  conditions: {
                    required: {
                      value: true,
                      message: "定員は入力必須項目です。",
                    },
                    min: {
                      value: 1,
                      message: "1以上、9999以下の半角数字で入力してください。",
                    },
                    max: {
                      value: 9999,
                      message: "1以上、9999以下の半角数字で入力してください。",
                    },
                  },
                }}
                type="number"
                onWheelCapture={(e) => {
                  e.currentTarget.blur();
                }}
                value={clazzInput.capacity}
                className="w-56"
                placeholder="定員数を入力してください"
                id="capacity"
                name="capacity"
                onChange={onChangeInput}
              />
            </div>
            <p className="pt-7">名</p>
            <div className="text-gray-dark">
              <p>※上限は9,999人まで設定ができます。</p>
            </div>
          </div>
        </FormGroupUI>
        <hr />

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">申込者数表示設定</LabelUI>
          </div>
          <div className="flex space-x-4">
            <div className="flex flex-row space-x-4">
              <RadioUI
                data-testid="clazz-edit-clazz-applicant-count-visibility"
                id="applicantCountVisibility-visible"
                name="applicantCountVisibility"
                checked={clazzInput.applicantCountVisibility}
                value="1"
                onChange={onChangeRadioType}
              >
                <TextUI className="pl-1">表示</TextUI>
              </RadioUI>
              <RadioUI
                data-testid="clazz-edit-clazz-applicant-count-visibility"
                id="applicantCountVisibility-hidden"
                name="applicantCountVisibility"
                checked={!clazzInput.applicantCountVisibility}
                value="0"
                onChange={onChangeRadioType}
              >
                <TextUI className="pl-1">非表示</TextUI>
              </RadioUI>
            </div>
          </div>
          <div className="text-gray-dark">
            ※ 『表示』にするとカスタマーのコース詳細画面に申込者数が表示されます
          </div>
        </FormGroupUI>
        <hr />

        <FormGroupUI>
          <h2 className="text-subtitle text-xl">授業設定</h2>
          {clazzInput.lessons?.map((lesson: DisplayLesson, index: number) => {
            return (
              <div key={lesson.lessonMasterId} className="border-b pb-4">
                <p className="text-subtitle text-xl">
                  第{lesson.lessonMaster.order}回
                </p>
                <div className="flex flex-col space-y-3 ml-4">
                  <LabelUI className="text-lg">授業ID</LabelUI>
                  <ValidatableInputUI
                    id={`anyLessonId-${lesson.lessonMasterId}`}
                    name={`anyLessonId-${lesson.lessonMasterId}`}
                    data-testid={`clazz-edit-clazz-lessons-${index}-any-lessonid`}
                    errorIdPrefix={`clazz-edit-clazz-lessons-${index}-any-lessonid-error`}
                    registerParams={{
                      register,
                      error: errors.lessons?.[index]?.anyLessonId,
                      label: `lessons.${index}.anyLessonId`,
                      conditions: {
                        // TODO: なぜかmaxLengthとpatternがLessonIdのときだけ効かず
                        // validateは有効なので実装しています。原因わかりましたらリファクタお願いします。
                        validate: () => {
                          return (
                            /^[a-zA-Z0-9-_]{0,20}$/.test(lesson.anyLessonId!) ||
                            "２０文字以内の-, _を含む半角英数字を入力してください。"
                          );
                        },
                      },
                    }}
                    value={lesson.anyLessonId!}
                    className="w-4/12"
                    placeholder="授業IDを入力してください"
                    onChange={changeLessonInputs}
                  />
                  <LabelUI className="text-lg">授業名</LabelUI>
                  <TextUI data-testid={`clazz-edit-lessons-${index}-name`}>
                    {lesson.lessonMaster.name}
                  </TextUI>
                  <LabelUI className="text-lg">
                    日時{" "}
                    {pageType === PageType.ClassNewPage && (
                      <BadgeUI>必須</BadgeUI>
                    )}
                  </LabelUI>
                  <LessonDateFormUI
                    lesson={lesson}
                    data-testid={`clazz-edit-clazz-lessons-${index}-date`}
                    errorIdPrefix={`clazz-edit-clazz-lessons-${index}-date-error`}
                    registerParams={{
                      register,
                      error: errors.lessons?.[index]?.startedAt,
                      label: `lessons.${index}.startedAt`,
                      conditions: {
                        validate: () =>
                          validateDate(lesson) ||
                          "他授業回と日時が重複しています。",
                      },
                    }}
                    changeLessonDateInputs={changeLessonDateInputs}
                  />
                  <LabelUI className="text-lg">教師</LabelUI>
                  <div className="flex">
                    <div className="flex flex-col">
                      <div
                        className="cursor-pointer border border-blue text-center text-blue w-96 h-10 leading-10 rounded"
                        onClick={() => onOpenTeacherListModal(lesson)}
                      >
                        <span>教師選択</span>
                      </div>
                    </div>
                  </div>
                  {lesson.teachers.map((teacher: Teacher) => (
                    <div key={teacher.id}>
                      <span
                        className={`${styles.fileName}`}
                        data-testid={`clazz-edit-clazz-lessons-${index}-teachers-${teacher.id}`}
                      >
                        {teacher.familyName} {teacher.givenName}
                        {teacher.extra && "【外部】"}
                        <br />
                        ID: {teacher.userId}
                        <CloseIcon
                          className={styles.deleteIcon}
                          data-testid="users-delete-icon"
                          onClick={() => {
                            onRemoveTeacher(lesson, teacher.id);
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
                {pageType === PageType.ClassEditPage &&
                clazzInput.eventLocation ===
                  eventLocations[eventLocationType.Zoom] ? (
                  <FormGroupUI>
                    <div className="flex flex-row items-end space-x-2.5">
                      <LabelUI className="text-lg">授業URL</LabelUI>
                    </div>
                    <div className="flex">
                      <ValidatableInputUI
                        id={`lessonUrl-${lesson.lessonMasterId}`}
                        name={`lessonUrl-${lesson.lessonMasterId}`}
                        data-testid={`clazz-edit-clazz-lessons-${index}-lessonUrl`}
                        errorIdPrefix={`clazz-edit-clazz-lessons-${index}-lessonUrl-error`}
                        registerParams={{
                          register,
                          error: errors.lessons?.[index]?.lessonUrl,
                          label: `lessons.${index}.lessonUrl`,
                          conditions: {},
                        }}
                        value={lesson.lessonUrl!}
                        className="w-4/12"
                        placeholder=""
                        onChange={changeLessonInputs}
                      />
                    </div>
                  </FormGroupUI>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </FormGroupUI>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            type="button"
            data-testid="clazz-edit-back"
            buttonType="secondary"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI type="submit" data-testid="clazz-edit-submit">
            確認
          </ButtonUI>
        </div>
      </form>
    </>
  );
};
