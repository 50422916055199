import { MouseEventHandler } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../../components/Button";
import { LabelUI } from "../../../../../components/Label";
import { ModalUI } from "../../../../../components/Modal";
import { ModalFormGroupUI } from "../../../../../components/MordalFormGroup";
import { NewValidatableInputUI } from "../../../../../components/ValidatableInput";
import { NewValidatableTextareaUI } from "../../../../../components/ValidatableTextarea";
import {
  CreateFieldInput,
  Field,
  UpdateFieldInput,
} from "../../../../../generated/graphql";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";
import { FieldNew } from "../../../../../lib/types";
import { getValidationUrl } from "../../../../../validation/regularExpressions";

export enum FieldEditMode {
  CREATE = "create",
  UPDATE = "update",
}

type FieldEditModalProps = {
  visibility: boolean;
  onCreate?: (input: CreateFieldInput) => void;
  onUpdate?: (input: UpdateFieldInput) => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  editMode: FieldEditMode;
  field?: Omit<Field, "serviceKindId">;
};

export const FieldEditModalUI: React.VFC<FieldEditModalProps> = ({
  visibility,
  onCreate,
  onUpdate,
  onCancel,
  editMode,
  field,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldNew>({
    defaultValues: {
      name: field?.name,
      subTitle: field?.subTitle,
      summary: field?.summary,
      alias: field?.alias,
    },
  });

  const onCancelProxy: MouseEventHandler<HTMLButtonElement> = (event) => {
    onCancel(event);
  };

  const onHandleSubmit: SubmitHandler<FieldNew> = (input) => {
    if (editMode === FieldEditMode.CREATE) {
      onCreate!({
        name: input.name,
        subTitle: input.subTitle,
        summary: input.summary,
        alias: input.alias,
      });
    } else if (editMode === FieldEditMode.UPDATE) {
      onUpdate!({
        id: field!.id,
        name: input.name,
        subTitle: input.subTitle,
        summary: input.summary,
        alias: input.alias,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
    >
      <ModalUI
        data-testid="field-edit-modal"
        visibility={visibility}
        className="w-full"
      >
        <div className="inline-block pt-4 pb-10 px-3 text-center">
          <h1
            className="text-left text-2xl tracking-wider text-subtitle"
            data-testid="field-edit-modal-title"
          >
            項目{editMode === FieldEditMode.CREATE ? "追加" : "編集"}
          </h1>
          <hr className="border-under-line mt-1"></hr>
          <div
            className="mt-4 text-2xl tracking-wider"
            data-testid="field-edit-description"
          >
            <div className="whitespace-pre-wrap"></div>
          </div>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">コード</LabelUI>
              <p data-testid="field-system-code">
                {editMode === FieldEditMode.CREATE
                  ? "自動で採番されます。"
                  : field?.id}
              </p>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">URL表記</LabelUI>
              <div
                className="text-left w-10/12"
                data-testid="field-edit-alias-container"
              >
                <NewValidatableInputUI
                  data-testid="field-edit-alias"
                  {...register("alias", {
                    required: "URL表記は必須項目です。",
                    pattern: {
                      value: getValidationUrl(),
                      message:
                        "40文字以内の半角英数字,「_」で入力してください。",
                    },
                  })}
                  error={errors.alias}
                  className="w-full"
                  placeholder="URL表記を入力してください。"
                  id="alias"
                  name="alias"
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">分野名</LabelUI>
              <div
                className="text-left w-10/12"
                data-testid="field-edit-name-container"
              >
                <NewValidatableInputUI
                  data-testid="field-edit-name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "分野名は必須項目です。",
                    },
                    maxLength: {
                      value: 40,
                      message: "４０文字以内で入力してください。",
                    },
                  })}
                  error={errors.name}
                  className="w-full"
                  placeholder="分野名を入力してください。"
                  id="name"
                  name="name"
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">サブタイトル</LabelUI>
              <div
                className="text-left w-10/12"
                data-testid="field-edit-subTitle-container"
              >
                <NewValidatableInputUI
                  data-testid="field-edit-subTitle"
                  {...register("subTitle", {
                    required: {
                      value: true,
                      message: "サブタイトルは必須項目です。",
                    },
                    maxLength: {
                      value: 30,
                      message: "３０文字以内で入力してください。",
                    },
                  })}
                  error={errors.subTitle}
                  className="w-7/12"
                  placeholder="サブタイトルを入力してください。"
                  id="subTitle"
                  name="subTitle"
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">説明文</LabelUI>
              <div
                className="text-left w-10/12"
                data-testid="field-edit-summary-container"
              >
                <NewValidatableTextareaUI
                  data-testid="field-edit-summary"
                  {...register("summary", {
                    required: {
                      value: true,
                      message: "説明文は必須項目です。",
                    },
                    maxLength: {
                      value: 80,
                      message: "８０文字以内で入力してください。",
                    },
                  })}
                  error={errors.summary}
                  className="w-full"
                  placeholder="説明文を入力してください。"
                  id="summary"
                  name="summary"
                  rows={4}
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              data-testid="field-edit-cancel"
              type="button"
              buttonType="secondary"
              onClick={onCancelProxy}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="field-edit-submit" type="submit">
              {editMode === FieldEditMode.CREATE && "登録"}
              {editMode === FieldEditMode.UPDATE && "更新"}
            </ButtonUI>
          </div>
        </div>
      </ModalUI>
    </form>
  );
};
