import React, { ChangeEventHandler } from "react";

import { CourseQuestionnaire } from "../../lib/types/course";
import { RegisterParams } from "../common-interfaces";
import { InputUI } from "../Input";

export type QuestionnaireTitleUIProps = {
  disabled: boolean;
  questionnaire: CourseQuestionnaire;
  onChangeInput: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  registerParams: RegisterParams;
  errorIdPrefix?: string;
};

export const QuestionnaireTitleUI: React.FC<QuestionnaireTitleUIProps> = ({
  disabled = false,
  questionnaire,
  onChangeInput,
  registerParams,
  errorIdPrefix,
  ...rest
}) => {
  return (
    <div>
      <div className="ml-8 mt-2">
        <input
          value={questionnaire.title}
          className="hidden"
          {...registerParams.register(registerParams.label, {
            ...registerParams.conditions,
          })}
          {...rest}
        />
        <InputUI
          data-testid={`questionnaire-title-${questionnaire.order}`}
          value={questionnaire.title}
          disabled={disabled}
          placeholder="問いを記入してください。"
          id="title"
          name="title"
          className={`w-10/12 text-black ${
            registerParams.error ? "border-error" : "border-black"
          }`}
          onChange={onChangeInput}
        />
      </div>
      <div>
        {registerParams.error && (
          <p
            className="text-error ml-9 mt-1.5"
            data-testid={`${errorIdPrefix}-message`}
          >
            {registerParams.error.message}
          </p>
        )}
      </div>
    </div>
  );
};
