import React from "react";

import UsersExternalNewPage from "./UsersExternalNewPage";

const UsersExternalNewPageContainer = () => {
  /**
   * TODO:
   * サーバーとのデータ通信（データ取得や書き込み）処理はindex.tsxに記述し、
   * データの表示やUIについての記述はUsersExternalNewPage.tsxに記述するようにする。
   * 現在はすべての処理がUsersExternalNewPage.tsxに記述されており、役割分担ができていない
   */
  return <UsersExternalNewPage />;
};

export default UsersExternalNewPageContainer;
