import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { useHistory } from "react-router";

import { BadgeUI } from "../../../components/Badge";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { LabelUI } from "../../../components/Label";
import {
  Maybe,
  MismatchHandlingHistoryFragment,
  PaymentMismatchCsvFragment,
  PaymentMismatchDetailFragment,
  PaymentMismatchStatus,
} from "../../../generated/graphql";
import { getMismatchTypeJA } from "../../../lib/utils/payment";
import { HandlingHistoryTableUI } from "./components/HandlingHistoryTable";
import { HandlingStatusRegisterUI } from "./components/HandlingStatusRegister";
import { MplStatusTableUI } from "./components/MplStatusTable";
import { PaymentTableUI } from "./components/PaymentTable";

dayjs.locale(ja);

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済不整合",
  },
  {
    label: "決済不整合詳細",
  },
];

export type PaymentMismatchPageProps = {
  paymentMismatch: PaymentMismatchDetailFragment;
  handlingHistories?: MismatchHandlingHistoryFragment[];
  mplStatuses?: PaymentMismatchCsvFragment[];
  payment?: PaymentMismatchDetailFragment["payment"];
  createHandlingHistory: (
    handlingStatus: string,
    note: string
  ) => Promise<void>;
};

export const getHandlingStatusJA = (
  handlingStatus: Maybe<string> | undefined
) => {
  switch (handlingStatus) {
    case PaymentMismatchStatus.Unhandled:
      return <BadgeUI type={"unhandle"}>未対応</BadgeUI>;
    case PaymentMismatchStatus.Handling:
      return <BadgeUI type={"handling"}>対応中</BadgeUI>;
    case PaymentMismatchStatus.Done:
      return <BadgeUI type={"done"}>対応済</BadgeUI>;
  }
};

const PaymentMismatchPage: React.FC<PaymentMismatchPageProps> = ({
  paymentMismatch,
  mplStatuses,
  handlingHistories,
  payment,
  createHandlingHistory,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const [, setTableWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
    return () => {
      // unmount
    };
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">決済情報不整合詳細</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-5 mb-36">
        <p className="mb-2" data-testid="payment-mismatch-handling-status">
          {getHandlingStatusJA(paymentMismatch.handlingStatus)}
        </p>
        <p className="mb-2" data-testid="payment-mismatch-detected-time">
          検知日時　
          {paymentMismatch &&
            dayjs(paymentMismatch.detectedDateTime).format("YYYY/MM/DD HH:mm")}
        </p>
        <p className="mb-2" data-testid="payment-mismatch-payinfo-no">
          決済情報番号　{paymentMismatch && paymentMismatch.payInfoNo}
        </p>
        <p className="text-lg" data-testid="payment-mismatch-type">
          不整合項目　
          {paymentMismatch && getMismatchTypeJA(paymentMismatch.type)}
        </p>
        <div className="mb-8">
          <LabelUI className="mt-9 mb-3">かんたん決済の決済情報</LabelUI>
          {mplStatuses && mplStatuses.length > 0 ? (
            mplStatuses.map((mplStatus, i) => (
              <MplStatusTableUI
                mplStatus={mplStatus}
                key={i}
                data-testid="payment-mismatch-mpl-status"
              />
            ))
          ) : (
            <div className="bg-light-blue flex h-24">
              <div className="m-auto">
                <p
                  className="text-lg"
                  data-testid="payment-mismatch-no-mpl-status"
                >
                  かんたん決済に決済情報が存在しません。
                </p>
              </div>
            </div>
          )}
        </div>
        <LabelUI className="mb-3">教育PFの決済情報</LabelUI>
        {payment ? (
          <div className="overflow-x-auto">
            <PaymentTableUI
              payment={payment}
              onWidthChange={(w) => setTableWidth(w)}
            />
          </div>
        ) : (
          <div className="bg-light-blue flex h-24">
            <div className="m-auto">
              <p className="text-lg" data-testid="payment-mismatch-no-payment">
                教育PFに決済情報が存在しません。
              </p>
            </div>
          </div>
        )}
        <LabelUI className="mt-6 mb-3">対応履歴</LabelUI>
        {handlingHistories && 1 <= handlingHistories.length ? (
          <HandlingHistoryTableUI handlingHistories={handlingHistories} />
        ) : (
          <div className="bg-light-blue flex h-48">
            <div className="m-auto">
              <p
                className="text-lg"
                data-testid="payment-mismatch-no-handling-history"
              >
                現在、対応履歴はありません。
              </p>
            </div>
          </div>
        )}
        <HandlingStatusRegisterUI
          handlingStatus={paymentMismatch && paymentMismatch.handlingStatus}
          onCreate={createHandlingHistory}
        />
        <div className="flex justify-center">
          <ButtonUI
            data-testid="payment-goback"
            buttonType="primary"
            size="s"
            className="mt-20"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default PaymentMismatchPage;
