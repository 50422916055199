import React, { useCallback, useEffect, useState } from "react";

import { ButtonUI } from "../../../../components/Button";
import { CheckboxUI } from "../../../../components/Checkbox";
import { InputUI } from "../../../../components/Input";
import { LabelUI } from "../../../../components/Label";
import { PaymentMismatchesFilterInput } from "../../../../generated/graphql";
import {
  PaymentMismatchStatus,
  paymentMismatchStatuses,
} from "../../../../lib/constants/PaymentMismatch";

export type PaymentMismatchesSearchPageProps = {
  onSearch: (filter: PaymentMismatchesFilterInput) => void;
};

type FilterInputs = {
  payInfoNo: string;
  handlingStatus: string[];
};

export const PaymentMismatchesSearchUI: React.FC<
  PaymentMismatchesSearchPageProps
> = ({ onSearch }) => {
  const [inputs, setInputs] = useState<FilterInputs>({
    payInfoNo: "",
    handlingStatus: [
      PaymentMismatchStatus.Unhandled,
      PaymentMismatchStatus.Handling,
    ],
  });
  const [searchFilter, setSearchFilter] =
    useState<PaymentMismatchesFilterInput>({
      ...inputs,
    });

  useEffect(() => {
    setSearchFilter({
      ...inputs,
    });
    return () => {
      // unmount
    };
  }, [setSearchFilter, inputs]);

  return (
    <div className="max-w-screen-md">
      <p className="mt-8 mb-2 text-lg">絞込条件設定</p>
      <div className="border-b-2">
        <form
          onSubmit={useCallback(
            (event) => {
              event.preventDefault();
              onSearch(searchFilter);
            },
            [searchFilter, onSearch]
          )}
          className="mt-2.5 mb-4"
        >
          <div className="flex items-start pl-5 pt-4 pb-4 bg-light-blue">
            <LabelUI className="w-3/12 text-left text-sm">対応状況</LabelUI>
            <div className="grid gap-4 grid-cols-3 items-start">
              {paymentMismatchStatuses.map((status) => {
                return (
                  <CheckboxUI
                    data-testid="payment-mismatches-search-payment-status"
                    style={{ marginRight: "8px" }}
                    id={status.id}
                    name="status"
                    value={status.value}
                    checked={
                      inputs.handlingStatus &&
                      inputs.handlingStatus.findIndex(
                        (element) => element === status.value
                      ) >= 0
                    }
                    key={status.id}
                    onCheckChange={(checked: boolean, value: string) => {
                      const a: string[] = inputs.handlingStatus;
                      if (checked) {
                        a.push(value);
                        setInputs({
                          ...inputs,
                          handlingStatus: Array.from(new Set(a)), // 重複除去
                        });
                      } else {
                        const b = a.filter((item) => item !== value);
                        setInputs({
                          ...inputs,
                          handlingStatus: Array.from(new Set(b)), // 重複除去
                        });
                      }
                    }}
                  >
                    {status.label}
                  </CheckboxUI>
                );
              })}
            </div>
          </div>
          <div className="flex items-center mt-4 pl-5">
            <LabelUI className="w-3/12 text-sm text-left">決済情報番号</LabelUI>
            <InputUI
              data-testid="payment-mismatches-search-pay-info-no"
              value={inputs.payInfoNo}
              id="payInfoNo"
              name="payInfoNo"
              className="w-72"
              placeholder="123456789"
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  payInfoNo: text,
                });
              }}
            />
          </div>
          <div className="flex justify-end mt-12">
            <ButtonUI
              data-testid="payment-mismatches-search-submit"
              buttonType="primary"
              type="submit"
              size="xs"
              className="mr-6"
            >
              絞込
            </ButtonUI>
          </div>
        </form>
      </div>
    </div>
  );
};
