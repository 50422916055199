import React, { useEffect, useMemo, useState } from "react";

import {
  CoursesFragment,
  CoursesItemFragment,
} from "../../../../generated/graphql";
import { useQueryParams } from "../../../../hooks/query";
import CoursesItemUI from "./CoursesItem";

export type CoursesUIProps = {
  courses: CoursesFragment;
};

export const CoursesUI: React.FC<CoursesUIProps> = ({ courses }) => {
  const params = useQueryParams();
  const courseId = useMemo(() => {
    return params.get("courseId");
  }, [params]);
  const clazzId = useMemo(() => {
    return params.get("classId");
  }, [params]);
  const [scrolled, setScrolled] = useState(
    courseId !== null || clazzId !== null ? false : true
  );

  useEffect(() => {
    if (!scrolled) {
      setScrolled(true);
      const elementName =
        clazzId === null
          ? `courses-courseId-${courseId}`
          : `clazzes-clazzId-${clazzId}`;
      const element = document.getElementById(elementName);
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [clazzId, courseId, scrolled]);

  return (
    <div>
      {courses.nodes.map((course: CoursesItemFragment) => (
        <CoursesItemUI
          key={course.id}
          course={course}
          isShow={course.id === courseId && clazzId !== null}
        ></CoursesItemUI>
      ))}
    </div>
  );
};
