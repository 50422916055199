import React, { ChangeEventHandler, useCallback } from "react";

import { useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../../components/Badge";
import { ButtonUI } from "../../../../../components/Button";
import { FileInputUI } from "../../../../../components/FileInput";
import { FormGroupUI } from "../../../../../components/FormGroup";
import { InputUI } from "../../../../../components/Input";
import { LabelUI } from "../../../../../components/Label";
import { RadioUI } from "../../../../../components/Radio";
import { SelectUI } from "../../../../../components/Select";
import { TextUI } from "../../../../../components/Text";
import { DateInputWithCalendarIconUI } from "../../../../../components/UserEdit/components/DateInputWithCalendarIcon";
import { NewValidatableInputUI } from "../../../../../components/ValidatableInput";
import { NewValidatableTextareaUI } from "../../../../../components/ValidatableTextarea";
import {
  ServiceInputFragment,
  UsersEditPageFragment,
} from "../../../../../generated/graphql";
import {
  genders,
  yearsOfTeaching,
  statuses,
} from "../../../../../lib/constants";
import { UserEditFormValues } from "../../types";
import { RoleInputUI } from "../RoleInput";
import { ServiceInputUI } from "../ServiceInput";

type UserEditUIProps = {
  hasAdminAuthority?: boolean;
  user: UsersEditPageFragment;
  onBack: React.MouseEventHandler<HTMLButtonElement>;
  services: ServiceInputFragment[];
};

export const UserEditUI: React.VFC<UserEditUIProps> = ({
  hasAdminAuthority = false,
  user,
  onBack,
  services,
}) => {
  const {
    register,
    formState: { errors },
    setError,
    setValue,
  } = useFormContext<UserEditFormValues>();

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target?.files || !event.target?.files.length) {
        return;
      }

      if (
        event.target?.files &&
        event.target?.files[0] &&
        event.target?.files[0].size > 10 * 1000 * 1000
      ) {
        setError("avator", {
          type: "custom",
          message: "アップロード可能なファイルサイズは最大10MBです。",
        });
        return;
      }
      if (
        event.target?.files &&
        event.target?.files[0] &&
        !["image/jpeg", "image/png"].includes(event.target?.files[0].type)
      ) {
        setError("avator", {
          type: "custom",
          message: ".jpg または .png 形式の画像を指定してください。",
        });
        return;
      }
      setValue("avator", event.target.files[0]);
    },
    [setError, setValue]
  );

  return (
    <div>
      <div className="divide-y divide-under-line ml-5">
        <FormGroupUI>
          <LabelUI>ユーザーID</LabelUI>
          <TextUI data-testid="users-edit-userId">{user.userId}</TextUI>
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>名前</LabelUI>
          <TextUI data-testid="users-edit-name">
            {user.familyName} {user.givenName}
          </TextUI>
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>名前（フリガナ）</LabelUI>
          <div className="flex items-center space-x-10">
            <div className="space-x-2.5">
              <InputUI
                data-testid="users-edit-familyNameKana"
                id="familyNameKana"
                placeholder="エーユー"
                {...register("familyNameKana")}
              />
            </div>
            <div className="space-x-2.5">
              <InputUI
                data-testid="users-edit-givenNameKana"
                id="givenNameKana"
                placeholder="タロウ"
                {...register("givenNameKana")}
              />
            </div>
          </div>
        </FormGroupUI>

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>サービス別権限</LabelUI>
            <BadgeUI>必須</BadgeUI>
          </div>
          {hasAdminAuthority ? (
            <ServiceInputUI services={services} />
          ) : (
            <TextUI data-testid="users-edit-serviceKinds">
              {user.useableServiceKinds
                .map((serviceKind) => serviceKind.shortName)
                .join(", ")}
            </TextUI>
          )}
        </FormGroupUI>

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>ロール別権限</LabelUI>
            <BadgeUI>必須</BadgeUI>
          </div>
          {/* 管理者権限を持つユーザーはシステム管理者または運用監視者ロール"以外"のユーザーの権限を変更できる */}
          {hasAdminAuthority &&
          !user.roles?.some((role) => role.id === "1" || role.id === "7") ? (
            <RoleInputUI data-testid="users-edit-roleIds-input" />
          ) : (
            <TextUI data-testid="users-edit-roleIds">
              {user.roles!.map((role) => role.name).join(", ")}
            </TextUI>
          )}
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>メールアドレス</LabelUI>
          <NewValidatableInputUI
            data-testid="users-edit-email"
            {...register("email", {
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                message: "メールアドレス形式で入力してください。",
              },
            })}
            error={errors.email}
            className="w-7/12"
            placeholder="メールアドレスを入力してください。"
            id="email"
          />
        </FormGroupUI>

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>電話番号(ハイフンなし)</LabelUI>
          </div>
          <div className="flex">
            <NewValidatableInputUI
              data-testid="users-edit-tel"
              className="w-7/12"
              placeholder="電話番号(ハイフンなし)を入力してください。"
              id="tel"
              {...register("tel", {
                pattern: {
                  value: /^[0-9]{0,30}$/,
                  message: "30文字以内の半角数字を入力してください。",
                },
              })}
              error={errors.tel}
            />
          </div>
        </FormGroupUI>

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>性別</LabelUI>
          </div>
          <div className="flex space-x-10">
            {genders.map((gender) => (
              <RadioUI
                data-testid="users-edit-gender"
                key={gender.value}
                id={`gender-${gender.value}`}
                value={gender.value}
                {...register("gender")}
              >
                {gender.label}
              </RadioUI>
            ))}
          </div>
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>生年月日</LabelUI>
          <DateInputWithCalendarIconUI
            data-testid="users-edit-birthday"
            id="birthday"
            {...register("birthday")}
          />
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>出身国</LabelUI>
          <NewValidatableInputUI
            data-testid="users-edit-birthplace"
            className="w-7/12"
            type="text"
            id="birthplace"
            {...register("birthplace", {
              maxLength: {
                value: 100,
                message: "100文字以内で入力してください。",
              },
            })}
            error={errors.birthplace}
          />
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>職歴</LabelUI>
          {/* div で囲わないと FormGroupUI による左マージンがつかない */}
          <div>
            <SelectUI
              data-testid="users-edit-yearsOfTeaching"
              className="w-5/12"
              options={yearsOfTeaching}
              id="yearsOfTeaching"
              {...register("yearsOfTeaching")}
            />
          </div>
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>メッセージ</LabelUI>
          <NewValidatableTextareaUI
            data-testid="users-edit-message"
            rows={3}
            className="w-10/12"
            id="message"
            placeholder="メッセージを入力してください。"
            {...register("message", {
              maxLength: {
                value: 100,
                message: "100文字以内で入力してください。",
              },
            })}
            error={errors.message}
          />
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>自己紹介</LabelUI>
          <NewValidatableTextareaUI
            data-testid="users-edit-selfIntroduction"
            rows={3}
            className="w-10/12"
            placeholder="自己紹介を入力してください。"
            id="selfIntroduction"
            {...register("selfIntroduction", {
              maxLength: {
                value: 200,
                message: "200文字以内で入力してください。",
              },
            })}
            error={errors.selfIntroduction}
          />
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>趣味</LabelUI>
          <NewValidatableTextareaUI
            data-testid="users-edit-hobby"
            rows={3}
            className="w-10/12"
            id="hobby"
            placeholder="趣味を入力してください。"
            {...register("hobby", {
              maxLength: {
                value: 200,
                message: "200文字以内で入力してください。",
              },
            })}
            error={errors.hobby}
          />
        </FormGroupUI>

        <FormGroupUI>
          <LabelUI>画像</LabelUI>
          <div className="flex flex-row">
            <FileInputUI
              data-testid="users-edit-avator"
              id="avatorUpload"
              name="avator"
              onChange={onChangeFile}
              src={user.avator ? user.avator : undefined}
            />
            <div className="flex flex-col justify-center pl-16">
              <TextUI>画像形式 : JPEG または PNG</TextUI>
              <TextUI>推奨サイズ : 1500ピクセル × 1500ピクセル</TextUI>
              <TextUI>ファイルサイズ : 最大 10 MB</TextUI>
              {errors.avator && (
                <TextUI className="text-error">{errors.avator.message}</TextUI>
              )}
            </div>
          </div>
        </FormGroupUI>

        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>ステータス</LabelUI>
            <BadgeUI>必須</BadgeUI>
          </div>
          {statuses.map((status) => (
            <RadioUI
              data-testid="users-edit-status"
              key={status.value}
              id={`status-${status.value}`}
              value={status.value}
              {...register("status")}
            >
              {status.label}
            </RadioUI>
          ))}
        </FormGroupUI>
      </div>
      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI
          type="button"
          data-testid="users-edit-back"
          buttonType="secondary"
          onClick={onBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI type="submit" data-testid="users-edit-submit">
          確認
        </ButtonUI>
      </div>
    </div>
  );
};
