import React, { InputHTMLAttributes, useState } from "react";

import { UserAvatorUI } from "../UserAvator";
import styles from "./FileInput.module.scss";

export type FileInputUIProps = {
  src?: string;
  previewWidth?: number;
  previewHeight?: number;
} & InputHTMLAttributes<HTMLInputElement>;

export const FileInputUI: React.FC<FileInputUIProps> = ({
  id,
  className = "",
  src,
  previewWidth = 100,
  previewHeight = 100,
  onChange,
  children,
  ...rest
}) => {
  const [url, setUrl] = useState<string>();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    url && URL.revokeObjectURL(url);
    const file = e.target.files && e.target.files[0];
    const newUrl =
      file && file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : undefined;
    setUrl(newUrl);

    onChange && onChange(e);
  };

  const previewUrl = url || src;

  return (
    <>
      <label htmlFor={id} className={styles.label}>
        <UserAvatorUI src={previewUrl} />
        <div className={styles.labelText}>
          <span>＋</span>
          <span>画像追加</span>
        </div>
      </label>
      <input
        id={id}
        type="file"
        className="hidden"
        onChange={handleChange}
        {...rest}
      />
    </>
  );
};
