import React, { useMemo } from "react";

import { ButtonUI, ButtonUIProps } from "../Button";
import { ReactComponent as DownloadIcon } from "./download.svg";

export type CsvDownloadButtonUIProps = ButtonUIProps & { buttonTitle?: string };

export const CsvDownloadButtonUI: React.VFC<CsvDownloadButtonUIProps> = ({
  buttonTitle,
  ...rest
}) => {
  const title = useMemo<string>(() => {
    return buttonTitle ?? "全てダウンロード";
  }, [buttonTitle]);
  return (
    <div>
      <ButtonUI
        data-testid="csv-download-btn"
        buttonType="secondary"
        size="csvBtnSize"
        base="rounded15"
        className="border border-blue bg-white text-blue hover:bg-blue hover:text-white mr-2.5 inline-flex items-center justify-center"
        {...rest}
      >
        <DownloadIcon className="fill-current mr-2.5 w-5 h-5" />
        {title}
      </ButtonUI>
    </div>
  );
};
