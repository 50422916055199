import React, { ChangeEventHandler } from "react";

import { RadioUI } from "../../components/Radio";
import { CustomersTableRowFragment } from "../../generated/graphql";

export type CustomersTableRowProps = {
  customer: CustomersTableRowFragment;
  index: number;
  selectedCustomerId?: string | null;
  onSelectedCustomer?: ChangeEventHandler<HTMLInputElement>;
};

const CustomersTableRowUI: React.FC<CustomersTableRowProps> = ({
  customer,
  index,
  selectedCustomerId = "",
  onSelectedCustomer,
}) => {
  return (
    <tr data-testid="customers-table-row" className="h-16">
      <td className="border">
        <div className="flex justify-center">
          <RadioUI
            data-testid="selected-customer-id"
            value={customer.id}
            checked={customer.id === selectedCustomerId}
            onChange={onSelectedCustomer}
          ></RadioUI>
        </div>
      </td>
      <td className="border text-center">{customer.id}</td>
    </tr>
  );
};

export default CustomersTableRowUI;
