import React, {
  ChangeEvent,
  ChangeEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { PriceUI } from "../../components/Price";
import { QuestionnaireUI } from "../../components/Questionnaire";
import {
  CourseDetailCategory,
  CourseImage,
  CoursePriceInput,
  CourseTargetType,
  CourseTeachingMaterial,
  CreateCourseDetailInput,
  LessonMasterTeachingMaterial,
  LevelFragment,
  OrganizerFragment,
  ServiceKind,
  SubjectSelectorItemFragment,
  UpdateCourseDetailInput,
} from "../../generated/graphql";
import { currentServiceKind } from "../../lib/cache";
import {
  maxPrice,
  targetOption,
  targetTypes,
} from "../../lib/constants/courses";
import { PageType } from "../../lib/constants/pageType";
import { preventFromSubmittingByEnter } from "../../lib/functions";
import { ValidatedFile } from "../../lib/types";
import {
  CourseEdit,
  CourseNew,
  CourseQuestionnaire,
} from "../../lib/types/course";
import { LessonMasterEdit } from "../../lib/types/lessonMaster";
import { getInitialQuestionnaireData } from "../../lib/utils/questionnaires";
import { BadgeUI } from "../Badge";
import { ButtonUI } from "../Button";
import { ReactComponent as SmallPlusIcon } from "../Button/small-plus.svg";
import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";
import { FileInputTypeDocumentUI } from "../FileInputTypeDocument";
import { FormGroupUI } from "../FormGroup";
import { ImagesInputUI } from "../ImagesInput";
import { LabelUI } from "../Label";
import { LevelSelector } from "../LevelSelector/LevelSelector";
import { OrganizerSelector } from "../OrganizerSelector/OrganizerSelector";
import { SelectCustomStyleUI } from "../SelectCustomStyle";
import { SubjectSelector } from "../SubjectSelector/SubjectSelector";
import { TextUI } from "../Text";
import { ValidatableInputUI } from "../ValidatableInput";
import { NewValidatableInputUI } from "../ValidatableInput";
import { ValidatableRadioUI } from "../ValidatableRadio";
import { ValidatableTextareaUI } from "../ValidatableTextarea";
import { PriceFormGroupUI } from "./components/PriceFormGroup";
import styles from "./CourseEdit.module.scss";

// 1つのコースの最大授業回数
const LESSON_COUNT_MAX: number = 40;

export interface CourseEditUIProps {
  pageType: PageType;
  course: CourseNew | CourseEdit;
  loadingMasters: boolean;
  organizers: OrganizerFragment[] | undefined;
  subjects: SubjectSelectorItemFragment[];
  levels: LevelFragment[];
  courseImages?: CourseImage[];
  editingCourseImageFiles?: File[]; // 編集中のコース画像
  teachingMaterials?: CourseTeachingMaterial[];
  editingTeachingMaterialFiles?: File[]; // 編集中の教材
  orderToLessonMaterialMap?: Map<number, LessonMasterTeachingMaterial[]>;
  onHandleSubmit: SubmitHandler<CourseEdit>;
  onChangeInput: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeVideoInput: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeCustomSelect: ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => void;
  onOrganizerChange: (level: OrganizerFragment | undefined) => void;
  onSubject1Change: (subject: SubjectSelectorItemFragment | undefined) => void;
  onSubject2Change: (subject: SubjectSelectorItemFragment | undefined) => void;
  onLevelChange: (level: LevelFragment | undefined) => void;
  onChangeInputDetail: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeLessonMasters: (lessonMasters: LessonMasterEdit[]) => void;
  onChangePrice: (input: CoursePriceInput) => void;
  onChangeRadioType: ChangeEventHandler<HTMLInputElement>;
  onChangeMaterialFile: (file: File) => void;
  onChangeErrMaterialFiles: (files: string[]) => void;
  onDeleteMaterialFile: (removeFileName: string) => void;
  onChangeLessonMasterMaterialFile: (targetIndex: number, file: File) => void;
  onDeleteLessonMasterMaterialFile: (
    targetIndex: number,
    removeFileName: string
  ) => void;
  onDeleteImageFile?: (removeFileName: string, id?: string) => void;
  onChangeCourseImages?: (files: ValidatedFile[]) => void;
  onChangeQuestionnaires: (questionnaires: CourseQuestionnaire[]) => void;
  handleBack?: () => void;
  videoUrlError?: boolean | { message: string };
}

export interface ForValidation extends CourseNew {
  description: string;
  recommendation: string;
  notes: string;
}

export const CourseEditUI: React.VFC<CourseEditUIProps> = ({
  pageType,
  course,
  loadingMasters,
  organizers,
  subjects,
  levels,
  courseImages,
  editingCourseImageFiles,
  teachingMaterials,
  editingTeachingMaterialFiles,
  orderToLessonMaterialMap,
  onHandleSubmit,
  onChangeInput,
  onChangeVideoInput,
  onChangeCustomSelect,
  onOrganizerChange,
  onSubject1Change,
  onSubject2Change,
  onLevelChange,
  onChangeInputDetail,
  onChangeLessonMasters,
  onChangePrice,
  onChangeRadioType,
  onChangeMaterialFile,
  onChangeErrMaterialFiles,
  onDeleteMaterialFile,
  onChangeLessonMasterMaterialFile,
  onDeleteLessonMasterMaterialFile,
  onDeleteImageFile,
  onChangeCourseImages,
  handleBack,
  onChangeQuestionnaires,
  videoUrlError,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      ...course,
    },
  });

  const [lessonMastersCount, setLessonMastersCount] = useState(
    course.lessonMasters?.length || 1
  );

  const createLessonMasterFrames = useCallback(() => {
    if (
      lessonMastersCount === 0 ||
      lessonMastersCount === course.lessonMasters.length
    ) {
      return;
    }
    // コース追加画面の最初に展開を押したときはカウントだけ空の枠を追加する
    if (course.lessonMasters.length === 0) {
      const emptyLessonMasters = [...Array(lessonMastersCount)].map(
        (_, index) => ({
          order: index + 1,
          name: "",
          teachingMaterials: [],
        })
      );
      onChangeLessonMasters(emptyLessonMasters);
    } else if (lessonMastersCount > course.lessonMasters.length) {
      // 授業マスターの数が実際の枠より大きいときは差分だけ空の枠を追加する
      onChangeLessonMasters([
        ...course.lessonMasters,
        ...[...Array(lessonMastersCount - course.lessonMasters.length)].map(
          (_, index) => ({
            order: course.lessonMasters.length + index + 1,
            name: "",
            teachingMaterials: [],
          })
        ),
      ]);
    } else {
      // 授業マスターの数が実際の枠より小さいときは差分だけ枠を下から削除する
      onChangeLessonMasters(
        course.lessonMasters.filter(
          (_: LessonMasterEdit, index: number) =>
            index < Number(lessonMastersCount)
        )
      );
    }
  }, [onChangeLessonMasters, lessonMastersCount, course.lessonMasters]);

  const handleInputLessonCount: ChangeEventHandler<HTMLInputElement> =
    useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const count = Number(event.target.value.replace(/[^0-9]{1}/g, ""));
      if (count > LESSON_COUNT_MAX) return;
      setLessonMastersCount(count);
    }, []);

  const onAddQuestionnaire: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      onChangeQuestionnaires([
        ...course.questionnaires,
        getInitialQuestionnaireData(course.questionnaires.length + 1),
      ]);
    },
    [onChangeQuestionnaires, course.questionnaires]
  );

  const onChangeQuestionnaire = useCallback(
    (newQuestionnaire: CourseQuestionnaire) => {
      onChangeQuestionnaires(
        course.questionnaires.map((questionnaire: CourseQuestionnaire) =>
          questionnaire.order === newQuestionnaire.order
            ? newQuestionnaire
            : questionnaire
        )
      );
    },
    [onChangeQuestionnaires, course.questionnaires]
  );

  const onDeleteQuestionnaire = useCallback(
    (order: number) => {
      onChangeQuestionnaires(
        course.questionnaires
          .filter(
            // 指定の番号のアンケートを取り除き
            (questionnaire: CourseQuestionnaire) =>
              questionnaire.order !== order
          ) // orderだけ順番に振り直す
          .map((questionnaire: CourseQuestionnaire, index: number) => {
            questionnaire.order = index + 1;
            return questionnaire;
          })
      );
    },
    [onChangeQuestionnaires, course.questionnaires]
  );

  const onChangeInputLessons: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const targetOrder: number = Number(event.target.id.split("-")[1]);

        const newLessonMasters: LessonMasterEdit[] = course.lessonMasters.map(
          (lessonMaster: LessonMasterEdit) => {
            return targetOrder !== lessonMaster.order
              ? lessonMaster
              : {
                  ...lessonMaster,
                  name: event.target.value,
                };
          }
        );
        onChangeLessonMasters(newLessonMasters);
      },
      [course.lessonMasters, onChangeLessonMasters]
    );

  const videoUrl = useCallback(() => {
    return course.videos !== null &&
      course.videos !== undefined &&
      course.videos.length > 0
      ? course.videos[0].url
      : "";
  }, [course.videos]);

  const onChangeCourseImagesProxy = (files: ValidatedFile[]) => {
    if (courseImages) {
      for (let item of courseImages) {
        let found = false;
        for (let f of files) {
          if (f.imageId === item.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          onDeleteImageFile && onDeleteImageFile(item.name, item.id);
        }
      }
    }
    onChangeCourseImages && onChangeCourseImages(files);
  };

  const onOrganierChangeProxy = useCallback(
    (selectedOrganizer: OrganizerFragment | undefined) => {
      if (selectedOrganizer === undefined) {
        onOrganizerChange(undefined);
      } else {
        onOrganizerChange(selectedOrganizer);
      }
    },
    [onOrganizerChange]
  );

  const onLevelChangeProxy = useCallback(
    (selectedLevel: LevelFragment | undefined) => {
      if (selectedLevel === undefined) {
        onLevelChange(undefined);
      } else {
        onLevelChange(selectedLevel);
      }
    },
    [onLevelChange]
  );

  const getCourseDetailByCategory = useCallback(
    (category: CourseDetailCategory) => {
      return course.details.find(
        (detail: CreateCourseDetailInput | UpdateCourseDetailInput) =>
          detail.category === category
      )?.description;
    },
    [course.details]
  );

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
      className="pb-20"
    >
      <FormGroupUI>
        <LabelUI className="text-lg">システムID</LabelUI>
        <TextUI data-testid="course-edit-system-id">
          {pageType === PageType.CoursesNewPage
            ? "登録後に発行されます。"
            : course.systemId}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コース名</LabelUI>
          <BadgeUI>必須</BadgeUI>
          <CharcterLengthUI value={course.name} max={40} className="pl-2" />
        </div>
        <div className="flex">
          <ValidatableInputUI
            data-testid="course-edit-course-name"
            errorIdPrefix="course-edit-course-name-error"
            registerParams={{
              register,
              error: errors.name,
              label: "name",
              conditions: {
                required: {
                  value: true,
                  message: "コース名は入力必須項目です。",
                },
                maxLength: {
                  value: 40,
                  message: "４０文字以内で入力してください。",
                },
              },
            }}
            value={course.name}
            className="w-7/12"
            placeholder="コース名を入力してください"
            id="name"
            name="name"
            onChange={onChangeInput}
          />
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コースID</LabelUI>
          <CharcterLengthUI value={course.courseId} max={20} className="pl-2" />
        </div>

        <div className="flex">
          <ValidatableInputUI
            data-testid="course-edit-course-id"
            errorIdPrefix="course-edit-course-id-error"
            registerParams={{
              register,
              error: errors.courseId,
              label: "courseId",
              conditions: {
                maxLength: {
                  value: 20,
                  message:
                    "２０文字以内の-, _を含む半角英数字を入力してください。",
                },
                pattern: {
                  value: /^[a-zA-Z0-9-_]+$/,
                  message:
                    "２０文字以内の-, _を含む半角英数字を入力してください。",
                },
              },
            }}
            value={course.courseId!}
            className="w-7/12"
            placeholder="コースIDを入力してください"
            id="courseId"
            name="courseId"
            onChange={onChangeInput}
          />
        </div>
      </FormGroupUI>
      <hr />

      {/* 事業者 */}
      {currentServiceKind()?.key === ServiceKind.ElementarySchool && (
        <>
          <FormGroupUI data-testid="course-edit-organizer-form">
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">事業者</LabelUI>
              <BadgeUI>必須</BadgeUI>
            </div>

            <div className="flex gap-4 h-12">
              {errors.organizer && (
                <ErrorCheckIcon
                  data-testid={"course-edit-organizer-error-icon"}
                />
              )}
              {(loadingMasters || !organizers) && "loading..."}
              {!loadingMasters && organizers && (
                <>
                  <OrganizerSelector
                    data-testid="course-edit-organizer"
                    className="w-64"
                    id="organizer"
                    placeholder="事業者を選択してください"
                    name="organizer"
                    errorBorder={!!errors.organizer}
                    defaultOrganizer={course.organizer}
                    aria-label="course-edit-organizer"
                    organizers={organizers}
                    onOrganizerChange={onOrganierChangeProxy}
                  />
                  <input
                    className="hidden"
                    {...register("organizer", {
                      validate: () => !!course.organizer,
                    })}
                  />
                </>
              )}
            </div>
            {errors.organizer && (
              <p
                className={`text-error  mt-1.5 ml-8`}
                data-testid="course-edit-organizer-error-message"
              >
                事業者は選択必須項目です。
              </p>
            )}
          </FormGroupUI>
          <hr />
        </>
      )}

      <FormGroupUI>
        <LabelUI className="text-lg">対象年齢/学年</LabelUI>
        <div className="flex items-center flex-shrink-0">
          {errors.targets && (
            <div className="mr-3">
              <ErrorCheckIcon data-testid={"course-edit-targets-error-icon"} />
            </div>
          )}
          <div className="w-64">
            <div className="flex space-x-4 h-12">
              {targetTypes.map((type) => (
                <ValidatableRadioUI
                  registerParams={{
                    register,
                    error: errors.targets,
                    label: "targets",
                    conditions: {
                      validate: {
                        range: () =>
                          course.targets?.from! &&
                          course.targets?.to! &&
                          targetOption[course.targets?.type!].find(
                            (target) => target.value === course.targets?.from!
                          )?.order! <=
                            targetOption[course.targets?.type!].find(
                              (target) => target.value === course.targets?.to!
                            )?.order!,
                        unselected: () =>
                          course.targets?.type === CourseTargetType.None ||
                          (course.targets?.from !== undefined &&
                            course.targets?.from !== null) ||
                          (course.targets?.to !== undefined &&
                            course.targets?.to !== null),
                      },
                    },
                  }}
                  data-testid="course-edit-target-type"
                  key={type.value}
                  id={`targetType-${type.value}`}
                  name="targetType"
                  value={type.value}
                  onChange={onChangeRadioType}
                  defaultChecked={type.value === course.targets?.type!}
                >
                  {type.label}
                </ValidatableRadioUI>
              ))}
            </div>
          </div>
          {course.targets?.type !== CourseTargetType.None && (
            <div className=" flex">
              <div className="w-48">
                <SelectCustomStyleUI
                  data-testid="course-edit-target-from"
                  className="w-48"
                  options={targetOption[course.targets?.type!]}
                  id="targetFrom"
                  value={
                    course.targets?.from &&
                    course.targets.type === CourseTargetType.Age
                      ? `${course.targets.from!}歳`
                      : course.targets?.from!
                  }
                  name="targetFrom"
                  onChange={onChangeCustomSelect}
                  aria-label="course-edit-target-from"
                />
              </div>
              <div className="h-12 p-3.5">〜</div>
              <div className="w-48">
                <SelectCustomStyleUI
                  data-testid="course-edit-target-to"
                  className="w-48"
                  options={targetOption[course.targets?.type!]}
                  id="targetTo"
                  name="targetTo"
                  value={
                    course.targets?.to &&
                    course.targets.type === CourseTargetType.Age
                      ? `${course.targets.to!}歳`
                      : course.targets?.to!
                  }
                  onChange={onChangeCustomSelect}
                  aria-label="course-edit-target-to"
                />
              </div>
            </div>
          )}
        </div>
        {errors.targets && errors.targets.type === "range" && (
          <p
            className="text-error mt-1.5 ml-8"
            data-testid={"course-edit-targets-error-range-message"}
          >
            正しい範囲を指定してください。
          </p>
        )}
        {errors.targets && errors.targets.type === "unselected" && (
          <p
            className="text-error mt-1.5 ml-8"
            data-testid={"course-edit-targets-error-unselected-message"}
          >
            始まりの値もしくは終わりの値を入力してください。
          </p>
        )}
      </FormGroupUI>
      <hr />

      {/* レベル */}
      <FormGroupUI>
        {currentServiceKind()?.key === ServiceKind.LiberalArts ? (
          <div className="flex flex-col">
            <LabelUI className="text-lg">レベル</LabelUI>
            <div className="mt-2.5 ml-2.5 text-dark-gray text-base">
              ※設定しない場合、お客様様画面では「設定なし」と表示されます。
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">レベル</LabelUI>
            <BadgeUI>必須</BadgeUI>
          </div>
        )}
        <div className="flex gap-4 h-12">
          {errors.level && (
            <ErrorCheckIcon data-testid={"course-edit-level-error-icon"} />
          )}
          {(loadingMasters || !levels) && "loading..."}
          {!loadingMasters && levels && (
            <>
              <LevelSelector
                data-testid="course-edit-level"
                className="w-64"
                id="level"
                placeholder="レベルを選択してください"
                name="level"
                errorBorder={!!errors.level}
                onLevelChange={onLevelChangeProxy}
                defaultLevel={course.levelEntity}
                aria-label="course-edit-level"
                levels={levels}
              />
              {currentServiceKind()?.key === ServiceKind.LiberalArts ? (
                <input className="hidden" {...register("level")} />
              ) : (
                <input
                  className="hidden"
                  {...register("level", {
                    validate: () => !!course.levelEntity,
                  })}
                />
              )}
            </>
          )}
        </div>
        {errors.level && (
          <p
            className={`text-error  mt-1.5 ml-8`}
            data-testid="course-edit-level-error-message"
          >
            レベルは選択必須項目です。
          </p>
        )}
      </FormGroupUI>
      <hr />

      {/* 商品カテゴリ */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">商品カテゴリ</LabelUI>
          <BadgeUI>必須</BadgeUI>
        </div>
        <div className="flex flex-row gap-4 h-12">
          {errors.subject && (
            <ErrorCheckIcon data-testid={"course-edit-subject-error-icon"} />
          )}
          {(loadingMasters || !subjects) && "loading..."}
          {!loadingMasters && subjects && (
            <>
              <SubjectSelector
                data-testid="course-edit-subject-1"
                className="w-64"
                id="subject1"
                placeholder="商品カテゴリを選択してください"
                name="subject1"
                errorBorder={!!errors.subject}
                onSubjectChange={onSubject1Change}
                defaultSubject={course.subjectEntity1}
                aria-label="course-edit-subject-1"
                subjects={subjects}
              />
              <input
                className="hidden"
                {...register("subject", {
                  validate: () => !!course.subjectEntity1,
                })}
              />
              <SubjectSelector
                data-testid="course-edit-subject-2"
                className="w-64"
                id="subject2"
                placeholder="商品カテゴリを選択してください"
                name="subject2"
                onSubjectChange={onSubject2Change}
                defaultSubject={course.subjectEntity2}
                aria-label="course-edit-subject-2"
                subjects={subjects}
              />
            </>
          )}
        </div>
        {errors.subject && (
          <p
            className={`text-error  mt-1.5 ml-8`}
            data-testid="course-edit-subject-error-message"
          >
            商品カテゴリは選択必須項目です。
          </p>
        )}
      </FormGroupUI>
      <hr />

      {/* 教材 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">教材（コース共通）</LabelUI>
        </div>
        <div>
          <FileInputTypeDocumentUI
            data-testid="course-edit-documents"
            existMaterials={
              editingTeachingMaterialFiles
                ? editingTeachingMaterialFiles
                : teachingMaterials
                ? teachingMaterials
                : undefined
            }
            id="documentsUpload"
            name="documents"
            onChangeErrMaterialFiles={onChangeErrMaterialFiles}
            onChangeMaterialFile={onChangeMaterialFile}
            onDeleteFile={onDeleteMaterialFile}
          />
        </div>
      </FormGroupUI>
      <hr />

      {/* 料金設定 */}
      {pageType === PageType.CoursesEditPage &&
      (course as CourseEdit).published ? (
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">料金設定</LabelUI>
          </div>
          <PriceUI
            data-testid="course-edit-price-text"
            price={course.price}
          ></PriceUI>
        </FormGroupUI>
      ) : (
        <PriceFormGroupUI
          price={course.price!}
          pageType={pageType}
          errors={errors}
          register={register}
          reset={reset}
          onChangePrice={onChangePrice}
          maxPrice={maxPrice[currentServiceKind()!.key]}
        ></PriceFormGroupUI>
      )}
      <hr />

      {/* コース概要 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コース概要</LabelUI>
          <BadgeUI>必須</BadgeUI>
          <CharcterLengthUI
            value={course.overview}
            max={300}
            className="pl-2"
          />
        </div>
        <ValidatableTextareaUI
          registerParams={{
            register,
            error: errors.overview,
            label: "overview",
            conditions: {
              required: {
                value: true,
                message: "コース概要は入力必須項目です。",
              },
              maxLength: {
                value: 300,
                message: "３００文字以内で入力してください。",
              },
            },
          }}
          data-testid="course-edit-overview"
          errorIdPrefix="course-edit-overview-error"
          rows={4}
          value={course.overview!}
          className="w-10/12"
          placeholder="３００文字以内"
          id="overview"
          name="overview"
          onChange={onChangeInput}
        />
      </FormGroupUI>
      <hr />

      {/* コース詳細 */}
      <div className="mt-4">
        <LabelUI className="text-lg">コース詳細</LabelUI>
        <div className="pl-2">
          <FormGroupUI>
            <div className="flex flex-row">
              <LabelUI className="text-base">説明</LabelUI>
              <CharcterLengthUI
                value={getCourseDetailByCategory(
                  CourseDetailCategory.Description
                )}
                max={10000}
                className="pl-4 place-self-end"
              />
            </div>
            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.description,
                label: "description",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              data-testid="course-edit-description"
              errorIdPrefix="course-edit-description-error"
              rows={6}
              className="w-10/12"
              placeholder="１万文字以内"
              id="description"
              name="description"
              value={getCourseDetailByCategory(
                CourseDetailCategory.Description
              )}
              onChange={onChangeInputDetail}
            />
          </FormGroupUI>

          {/* こんな人におすすめ */}
          <FormGroupUI>
            <div className="flex flex-row">
              <LabelUI className="text-base">こんな人におすすめ！</LabelUI>
              <CharcterLengthUI
                value={getCourseDetailByCategory(
                  CourseDetailCategory.Recommendation
                )}
                max={10000}
                className="pl-4 place-self-end"
              />
            </div>

            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.recommendation,
                label: "recommendation",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              data-testid="course-edit-recommendation"
              errorIdPrefix="course-edit-recommendation-error"
              rows={6}
              className="w-10/12"
              placeholder="１万文字以内"
              id="recommendation"
              name="recommendation"
              value={getCourseDetailByCategory(
                CourseDetailCategory.Recommendation
              )}
              onChange={onChangeInputDetail}
            />
          </FormGroupUI>

          {/* 準備物 */}
          <FormGroupUI>
            <div className="flex flex-row">
              <LabelUI className="text-base">準備物</LabelUI>
              <CharcterLengthUI
                value={getCourseDetailByCategory(
                  CourseDetailCategory.Preparation
                )}
                max={10000}
                className="pl-4 place-self-end"
              />
            </div>
            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.preparation,
                label: "preparation",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              data-testid="course-edit-preparation"
              errorIdPrefix="course-edit-preparation-error"
              rows={6}
              className="w-10/12"
              placeholder="１万文字以内"
              id="preparation"
              name="preparation"
              value={getCourseDetailByCategory(
                CourseDetailCategory.Preparation
              )}
              onChange={onChangeInputDetail}
            />
          </FormGroupUI>

          {/* 当日の流れ */}
          <FormGroupUI>
            <div className="flex flex-row">
              <LabelUI className="text-base">当日の流れ</LabelUI>
              <CharcterLengthUI
                value={getCourseDetailByCategory(
                  CourseDetailCategory.Lessonflow
                )}
                max={10000}
                className="pl-4 place-self-end"
              />
            </div>
            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.lessonflow,
                label: "lessonflow",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              data-testid="course-edit-lessonflow"
              errorIdPrefix="course-edit-lessonflow-error"
              rows={6}
              className="w-10/12"
              placeholder="１万文字以内"
              id="lessonflow"
              name="lessonflow"
              value={getCourseDetailByCategory(CourseDetailCategory.Lessonflow)}
              onChange={onChangeInputDetail}
            />
          </FormGroupUI>

          {/* 注意事項 */}
          <FormGroupUI>
            <div className="flex flex-row">
              <LabelUI className="text-base">注意事項</LabelUI>
              <CharcterLengthUI
                value={getCourseDetailByCategory(CourseDetailCategory.Notes)}
                max={10000}
                className="pl-4 place-self-end"
              />
            </div>
            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.notes,
                label: "notes",
                conditions: {
                  maxLength: {
                    value: 10000,
                    message: "１万文字以内で入力してください。",
                  },
                },
              }}
              data-testid="course-edit-notes"
              errorIdPrefix="course-edit-notes-error"
              rows={6}
              className="w-10/12"
              placeholder="１万文字以内"
              id="notes"
              name="notes"
              value={getCourseDetailByCategory(CourseDetailCategory.Notes)}
              onChange={onChangeInputDetail}
            />
          </FormGroupUI>
        </div>
      </div>
      <hr />

      {/* 商品イメージ */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">商品イメージ</LabelUI>
        </div>
        <ImagesInputUI
          onFilesChange={onChangeCourseImagesProxy}
          editingImageFiles={editingCourseImageFiles}
          initialImages={courseImages}
        />
      </FormGroupUI>
      <hr />

      {currentServiceKind()?.key !== ServiceKind.LiberalArts && (
        <>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">イメージ動画URL</LabelUI>
            </div>
            <div className="flex">
              <NewValidatableInputUI
                data-testid="course-edit-video-url"
                className="w-7/12"
                placeholder="コース動画URLを入力してください"
                id="videoUrl"
                {...register("videoUrl", {
                  maxLength: {
                    value: 100,
                    message: "100文字以内で入力してください。",
                  },
                })}
                value={videoUrl()}
                error={videoUrlError}
                onChange={onChangeVideoInput}
              />
            </div>
          </FormGroupUI>
          <hr />
        </>
      )}

      {/* レッスン・マスター */}
      <div className="flex flex-row items-end space-x-2.5 mt-4">
        <LabelUI className="text-lg">授業回数/授業名</LabelUI>
        {pageType === PageType.CoursesNewPage && <BadgeUI>必須</BadgeUI>}
      </div>
      <div className="flex flex-row items-end mt-2">
        <label
          className={`text-subtitle text-lg tracking-wider leading-none mr-5`}
          htmlFor="lessons"
        >
          {errors[`lesson-count`] && (
            <ErrorCheckIcon
              className="inline-block mr-3"
              data-testid={`lesson-count`}
            />
          )}
          授業回数　全
          <input
            id="lesson-count"
            data-testid="course-edit-lesson-count"
            className={`border ml-5 mr-5 w-14 rounded text-lg text-center p-1
            ${errors[`lesson-count`] ? "border-error" : ""}`}
            type="number"
            {...(pageType === PageType.CoursesNewPage && {
              ...register(`lesson-count`, {
                required: {
                  value: true,
                  message: "授業回数は入力必須項目です。",
                },
                pattern: {
                  value: /^\+?0*[1-9]\d*$/,
                  message: "授業を登録してください。",
                },
              }),
            })}
            value={lessonMastersCount}
            onChange={(e) => handleInputLessonCount(e)}
            onWheelCapture={(e) => {
              e.currentTarget.blur();
            }}
            disabled={pageType === PageType.CoursesEditPage}
          />
          <span>回</span>
        </label>
        {pageType === PageType.CoursesNewPage && (
          <button
            type="button"
            data-testid={`course-edit-lesson-open`}
            className={`w-56 text-center border border-primary rounded p-1 m-0 ${
              !lessonMastersCount
                ? "border-gray-light text-gray-light"
                : "border-blue text-blue"
            } `}
            onClick={createLessonMasterFrames}
          >
            展開
          </button>
        )}
      </div>
      {errors[`lesson-count`] && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`course-edit-lesson-count-error-message`}
        >
          {errors[`lesson-count`].message}
        </p>
      )}
      {course.lessonMasters.length > 0 &&
        course.lessonMasters.map(
          (lessonMaster: LessonMasterEdit, index: number) => (
            <div className="flex flex-row mt-4" key={`lesson-master-${index}`}>
              <label className="text-subtitle text-lg w-32 pr-3 text-right leading-10 pt-1">
                {errors[`lessonMasters-${lessonMaster.order}`] && (
                  <ErrorCheckIcon
                    className="inline-block mr-3"
                    data-testid={`course-edit-lesson-error-icon-${lessonMaster.order}`}
                  />
                )}
                第{index + 1}回
              </label>

              <div className="flex flex-col w-full">
                <div className="flex flex-col items-center space-x-3 w-full">
                  <div className="w-full">
                    <input
                      id={`lessonMaster-${lessonMaster.order}`}
                      data-testid={`course-edit-lesson-title-${lessonMaster.order}`}
                      className={`w-10/12 ml-2 h-12 leading-5 pl-4 tracking-wider border rounded ${
                        errors[`lessonMasters-${lessonMaster.order}`]
                          ? "border-error"
                          : ""
                      }`}
                      {...(pageType === PageType.CoursesNewPage && {
                        ...register(`lessonMasters-${lessonMaster.order}`, {
                          required: {
                            value: true,
                            message: "授業名は入力必須項目です。",
                          },
                          maxLength: {
                            value: 100,
                            message: "１００文字以内で入力してください。",
                          },
                        }),
                      })}
                      value={lessonMaster.name}
                      onChange={onChangeInputLessons}
                      disabled={pageType === PageType.CoursesEditPage}
                    />
                    <CharcterLengthUI
                      value={lessonMaster.name}
                      max={100}
                      className="ml-8"
                    />
                  </div>
                  <div className="w-full py-8 space-y-2">
                    <div className="flex flex-row items-end space-x-2.5">
                      <LabelUI className="text-lg">
                        教材（授業終了後配布）
                      </LabelUI>
                    </div>
                    <div>
                      <FileInputTypeDocumentUI
                        data-testid={`course-edit-lesson-master-documents-${index}`}
                        existMaterials={
                          orderToLessonMaterialMap?.get(lessonMaster.order) ??
                          lessonMaster.teachingMaterials ??
                          undefined
                        }
                        id={`lesson-master-documents-upload-${lessonMaster.order}`}
                        name={`lessonMasterDocuments${lessonMaster.order}`}
                        onChangeErrMaterialFiles={onChangeErrMaterialFiles}
                        onChangeMaterialFile={(file: File) => {
                          onChangeLessonMasterMaterialFile(
                            lessonMaster.order,
                            file
                          );
                        }}
                        onDeleteFile={(removeFileName: string) => {
                          onDeleteLessonMasterMaterialFile(
                            lessonMaster.order,
                            removeFileName
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                {errors[`lessonMasters-${lessonMaster.order}`] && (
                  <p
                    className="text-error mt-1.5 ml-8"
                    data-testid={`course-edit-lessonMasters-error-messege-${lessonMaster.order}`}
                  >
                    {errors[`lessonMasters-${lessonMaster.order}`]?.message}
                  </p>
                )}
              </div>
            </div>
          )
        )}

      {/* TODO: 課金形態、アンケートの変更について申し込みがない場合は編集可能とする対応が必要。STUDYPF-3815 */}
      <div className="flex flex-row items-end space-x-2.5 mt-10">
        <LabelUI className="text-lg">申込時アンケート</LabelUI>
      </div>
      <div className="flex flex-row items-end mt-2 w-full"></div>
      <div className="flex flex-col ml-16">
        {course.questionnaires.map((questionnaire: CourseQuestionnaire) => (
          <QuestionnaireUI
            key={questionnaire.order}
            errors={errors}
            register={register}
            questionnaire={questionnaire}
            className="mt-5"
            editable={!course.questionnaireAnswered}
            deleteDisabled={course.questionnaires.length === 1}
            onChangeQuestionnaire={onChangeQuestionnaire}
            onDeleteQuestionnaire={onDeleteQuestionnaire}
          />
        ))}
        {!course.questionnaireAnswered && (
          <div className={`flex flex-row mt-5 ${styles.questionnaireWidth}`}>
            <div className="w-32"></div>
            <div className="flex justify-end w-full">
              <ButtonUI
                data-testid="course-add-questionnaire"
                size="xs"
                buttonType="secondary"
                className={`inline-flex items-center justify-items-center justify-center`}
                onClick={onAddQuestionnaire}
              >
                <SmallPlusIcon className="stroke-current" />
                新規追加
              </ButtonUI>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI
          type="button"
          data-testid="course-edit-back"
          buttonType="secondary"
          onClick={handleBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI type="submit" data-testid="course-edit-submit">
          確認
        </ButtonUI>
      </div>
    </form>
  );
};

// 入力文字数と
type CharacterLengthUIProps = {
  value: string | undefined | null;
  max: number;
} & HTMLAttributes<HTMLDivElement>;

const CharcterLengthUI: React.VFC<CharacterLengthUIProps> = ({
  value,
  max,
  ...rest
}) => {
  const { className, ...restRest } = rest;

  const characterLength = useMemo(() => {
    if (!value) {
      return 0;
    }
    return value.length;
  }, [value]);

  return (
    <div
      className={`${styles.charcterLength} ${
        className !== undefined ? className : ""
      }`}
      {...restRest}
    >
      {"文字数： "}
      <span className={`${characterLength > max ? styles.overColor : ""}`}>
        {characterLength}
      </span>
      {` / ${max}`}
    </div>
  );
};
