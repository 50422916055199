import { useEffect, useState, useCallback, MouseEventHandler } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useUpdateCourseMutation,
  useGenerateUploadCourseImageUrlMutation,
  useGenerateUploadCourseTeachingMaterialUrlMutation,
  UpdateCourseDetailInput,
  useDeleteCourseTeachingMaterialsMutation,
  useDeleteCourseProductImagesMutation,
  ProductType,
  UpdateCourseInput,
  useUpdateCourseImageOrdersMutation,
  CourseImageOrderInput,
  useDeleteLessonMasterTeachingMaterialsMutation,
  useGenerateUploadLessonMasterTeachingMaterialUrlMutation,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { CourseEditLocationState } from "../CoursesEditPage";
import CoursesEditConfirmPage from "./CoursesEditConfirmPage";

const CoursesEditConfirmPageContainer = () => {
  const history = useHistory();
  const [updateCourse] = useUpdateCourseMutation();
  const [updateCourseImageOrders] = useUpdateCourseImageOrdersMutation();
  const [generateUploadCourseImageUrl] =
    useGenerateUploadCourseImageUrlMutation();
  const [generateUploadCourseTeachingMaterialUrl] =
    useGenerateUploadCourseTeachingMaterialUrlMutation();
  const [generateUploadLessonMasterTeachingMaterialUrl] =
    useGenerateUploadLessonMasterTeachingMaterialUrlMutation();
  const location = useLocation<CourseEditLocationState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [isOpenConfirmModalForPublished, setIsOpenConfirmModalForPublished] =
    useState<boolean>(false);
  const [isOpenConfirmErrorModal, setIsOpenConfirmErrorModal] =
    useState<boolean>(false);
  const [errorModalTitle, setErrorModalTitle] = useState("更新に失敗しました");
  const [errorModalDescription, setErrorModalDescription] = useState(
    "コースの更新に失敗しました。<br />しばらく時間を置いてから、もう一度お試しください。"
  );
  useEffect(() => {
    return () => {
      isOpenCompleteModal && setIsOpenCompleteModal(false);
    };
  }, [isOpenCompleteModal]);

  useEffect(() => {
    return () => {
      isOpenConfirmModalForPublished &&
        setIsOpenConfirmModalForPublished(false);
    };
  }, [isOpenConfirmModalForPublished]);

  const {
    courseEdit,
    courseImages,
    teachingMaterials,
    orderToLessonMaterialMap,
    deleteTeachingMaterialIds,
    deleteCourseImageIds,
    deleteLessonMasterTeachingMaterialIds,
  } = location.state;

  const [deleteCourseImages] = useDeleteCourseProductImagesMutation();

  const [deleteTeachingMaterials] = useDeleteCourseTeachingMaterialsMutation();
  const onDeleteTeachingMaterials = useCallback(
    async (teachingMaterialIds: string[]): Promise<void> => {
      try {
        await deleteTeachingMaterials({
          variables: {
            input: {
              productType: ProductType.Course,
              teachingMaterialIds,
            },
          },
        });
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        throw new Error("deleteTeachingMaterials error");
      }
    },
    [history, deleteTeachingMaterials]
  );

  const [deleteLessonMasterTeachingMaterials] =
    useDeleteLessonMasterTeachingMaterialsMutation();
  const onDeleteLessonMasterTeachingMaterials = useCallback(
    async (materialIds: string[]): Promise<void> => {
      await deleteLessonMasterTeachingMaterials({
        variables: {
          input: {
            teachingMaterialIds: materialIds,
          },
        },
      }).catch((error) => {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        throw new Error("deleteLessonMasterTeachingMaterials error");
      });
    },
    [deleteLessonMasterTeachingMaterials, history]
  );

  const uploadCourseTeachingMaterials = useCallback(
    async (courseId: string): Promise<void> => {
      const uploadTasks = [];
      if (!courseEdit || !courseEdit.teachingMaterials) {
        return;
      }
      try {
        for (const material of courseEdit.teachingMaterials) {
          const presignedPostResult =
            await generateUploadCourseTeachingMaterialUrl({
              variables: {
                courseId: courseId,
                fileName: material?.name as string,
              },
            });
          const presignedPostData =
            presignedPostResult.data?.generateUploadCourseTeachingMaterialUrl;

          if (!presignedPostData) {
            return;
          }
          uploadTasks.push(
            new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", material);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadCourseTeachingMaterials error");
            })
          );
        }
        await Promise.all(uploadTasks);
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setErrorModalTitle("一部更新に失敗しました");
        setErrorModalDescription(
          "コースの更新は完了しましたが<br />教材の更新に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadCourseTeachingMaterials error");
      }
    },
    [history, generateUploadCourseTeachingMaterialUrl, courseEdit]
  );

  const uploadLessonMasterTeachingMaterials = useCallback(
    async (
      lessonMasterId: string,
      lessonMasterOrder: number
    ): Promise<Promise<unknown>[]> => {
      const uploadTasks: Promise<unknown>[] = [];
      const lessonMaster = courseEdit.lessonMasters.find(
        (master) => master.order === lessonMasterOrder
      );
      const masterTeachingLength = lessonMaster?.teachingMaterials?.length ?? 0;
      if (!(courseEdit && masterTeachingLength > 0)) {
        return [];
      }

      try {
        for (const material of lessonMaster!.teachingMaterials!) {
          const presignedPostResult =
            await generateUploadLessonMasterTeachingMaterialUrl({
              variables: {
                lessonMasterId: lessonMasterId,
                fileName: material?.name as string,
              },
            });
          const presignedPostData =
            presignedPostResult.data
              ?.generateUploadLessonMasterTeachingMaterialUrl;

          if (!presignedPostData) {
            throw new Error("presignedPostData error");
          }
          uploadTasks.push(
            new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", material);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadLessonMasterTeachingMaterials error");
            })
          );
        }
        return uploadTasks;
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return [];
        }
        setErrorModalTitle("一部更新に失敗しました");
        setErrorModalDescription(
          "コースの更新は完了しましたが<br />授業終了後配布教材の更新に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadLessonMasterTeachingMaterials error");
      }
    },
    [courseEdit, generateUploadLessonMasterTeachingMaterialUrl, history]
  );

  const onDeleteCourseImages = useCallback(
    async (courseImageIds: string[]): Promise<void> => {
      try {
        await deleteCourseImages({
          variables: {
            input: {
              productType: ProductType.Course,
              imageIds: courseImageIds,
            },
          },
        });
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        throw new Error("deleteCourseImages error");
      }
    },
    [history, deleteCourseImages]
  );

  const uploadCourseImages = useCallback(
    async (courseId: string): Promise<void> => {
      const uploadTasks = [];
      if (!courseEdit || !courseEdit.courseImages) {
        return;
      }
      try {
        // 画像表示順更新
        let updatedOrders: CourseImageOrderInput[] = [];
        // 既にDBに情報がある画像についてはorder更新
        courseImages.forEach((c) => {
          if (c.id !== "") {
            updatedOrders.push({
              id: c.id,
              order: c.order,
            });
          }
        });
        if (updatedOrders.length > 0) {
          updateCourseImageOrders({
            variables: {
              input: { courseImages: updatedOrders },
            },
          });
        }
        // TODO: コードを時間があるときにでリファクタする（コンテンツ編集確認画面のコメントと合わせている）
        let index = 0;
        for (const image of courseEdit.courseImages) {
          if (!image) {
            index++;
            continue;
          }

          if (!courseImages[index].id) {
            const presignedPostResult = await generateUploadCourseImageUrl({
              variables: {
                courseId: courseId,
                fileName: image.name as string,
                order: image.order,
              },
            });
            const presignedPostData =
              presignedPostResult.data?.generateUploadCourseImageUrl;

            if (!presignedPostData) {
              return;
            }
            uploadTasks.push(
              new Promise((resolve, reject) => {
                const formData = new FormData();
                Object.keys(presignedPostData.fields).forEach((key) => {
                  formData.append(key, presignedPostData.fields[key]);
                });
                formData.append("file", image);
                const xhr = new XMLHttpRequest();
                xhr.open("POST", `${presignedPostData.url}`, true);
                xhr.send(formData);
                xhr.onload = function () {
                  this.status === 204 ? resolve(0) : reject(this.responseText);
                };
              }).catch(() => {
                throw new Error("uploadCourseImages error");
              })
            );
          }
          index++;
        }
        await Promise.all(uploadTasks);
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setErrorModalTitle("一部更新に失敗しました");
        setErrorModalDescription(
          "コースの更新は完了しましたが<br />教材と商品イメージの更新に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadCourseImages error");
      }
    },
    [
      courseEdit,
      courseImages,
      updateCourseImageOrders,
      generateUploadCourseImageUrl,
      history,
    ]
  );

  const handleSubmit = async () => {
    if (!isLoading) {
      if (courseEdit.published) {
        setIsOpenConfirmModalForPublished(true);
      } else {
        await handleUpdate();
      }
    }
  };

  const handleUpdate = useCallback(async () => {
    const {
      courseImages,
      teachingMaterials,
      systemId,
      organizer,
      subjectEntity1,
      subjectEntity2,
      questionnaires,
      levelEntity,
      lessonMasters,
      ...input
    } = courseEdit;
    setIsOpenConfirmModalForPublished(false);
    setIsLoading(true);
    try {
      const formattedCourse: UpdateCourseInput = {
        id: input.id,
        name: input.name,
        courseId: input.courseId,
        organizerId: input.organizerId,
        targets: {
          type: input?.targets?.type!,
          from: input?.targets?.from,
          to: input?.targets?.to,
        },
        subjectIds: [subjectEntity1?.id || "", subjectEntity2?.id || ""].filter(
          (id) => id !== ""
        ),
        levelId: input.levelId,
        price: {
          type: input.price.type,
          valueNoTax: input.price.valueNoTax,
        },
        overview: input.overview,
        details: input.details.reduce(
          (acc: UpdateCourseDetailInput[], curr: UpdateCourseDetailInput) => {
            acc.push({
              id: curr.id,
              category: curr.category,
              description: curr.description,
            });
            return acc;
          },
          []
        ),
        videos: input.videos,
        // TODO: selectableNumberがstringで渡される問題を解決したらこっちでいい。
        // questionnaires: courseNew.questionnaires.map((questionnaire) => {
        //   const { typenameDisplay, ...ret } = questionnaire;
        //   return ret;
        // }),
        questionnaires: questionnaires.map((questionnaire) => ({
          order: questionnaire.order,
          title: questionnaire.title,
          typename: questionnaire.typename,
          required: questionnaire.required,
          options: questionnaire.options,
          selectableNumber: Number(questionnaire.selectableNumber),
          condition: questionnaire.condition,
          maxScale: Number(questionnaire.maxScale),
          minLabel: questionnaire.minLabel,
          maxLabel: questionnaire.maxLabel,
        })),
      };

      const updateResult = await updateCourse({
        variables: {
          input: formattedCourse,
        },
      });

      if (
        updateResult.data?.updateCourse &&
        courseImages &&
        courseImages.length > 0
      ) {
        await uploadCourseImages(updateResult.data?.updateCourse.id!);
      }
      if (teachingMaterials && teachingMaterials.length > 0) {
        await uploadCourseTeachingMaterials(
          updateResult.data?.updateCourse.id!
        );
      }

      // lesson master teaching material tasks
      let uploadTasks: Promise<unknown>[] = [];
      const existsMasterMaterials = lessonMasters.some(
        (master) => master?.teachingMaterials?.length ?? 0 > 1
      );
      if (existsMasterMaterials) {
        for (const lessonMaster of lessonMasters) {
          const tasks = await uploadLessonMasterTeachingMaterials(
            lessonMaster.id!,
            lessonMaster.order
          );
          uploadTasks = uploadTasks.concat(tasks);
        }
      }
      if (uploadTasks.length > 0) {
        await Promise.all(uploadTasks);
      }

      if (deleteCourseImageIds.length > 0) {
        await onDeleteCourseImages(deleteCourseImageIds);
      }
      if (deleteTeachingMaterialIds.length > 0) {
        await onDeleteTeachingMaterials(deleteTeachingMaterialIds);
      }
      if (deleteLessonMasterTeachingMaterialIds.length > 0) {
        await onDeleteLessonMasterTeachingMaterials(
          deleteLessonMasterTeachingMaterialIds
        );
      }
      setIsOpenCompleteModal(true);
    } catch (error: any) {
      console.error(error);
      const graphQLErrors = error?.graphQLErrors ?? [];
      if (graphQLErrors.length) {
        const errCode: string = graphQLErrors[0]?.extensions?.code ?? "";
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
      }
      setIsOpenConfirmErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    courseEdit,
    updateCourse,
    deleteCourseImageIds,
    deleteTeachingMaterialIds,
    deleteLessonMasterTeachingMaterialIds,
    uploadCourseImages,
    uploadCourseTeachingMaterials,
    uploadLessonMasterTeachingMaterials,
    onDeleteCourseImages,
    onDeleteTeachingMaterials,
    onDeleteLessonMasterTeachingMaterials,
    history,
  ]);

  const handleBack: MouseEventHandler<HTMLButtonElement> = () => {
    // 確認画面に戻らせないようにするためreplace
    history.replace(`/courses/${courseEdit.id}/edit`, location.state);
  };

  const handleGotoCourseList = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    // 確認画面に戻らせないようにするためreplace
    history.replace("/courses");
  };

  return (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <CoursesEditConfirmPage
        isLoading={isLoading}
        courseEdit={courseEdit}
        courseImages={courseImages}
        teachingMaterials={teachingMaterials}
        orderToLessonMaterialMap={orderToLessonMaterialMap}
        handleUpdate={handleSubmit}
        handleBack={handleBack}
      />
      <CompletedModalUI
        title="編集完了"
        description="編集が完了しました。"
        btnTitle="コース一覧へ"
        onClick={handleGotoCourseList}
        visibility={isOpenCompleteModal}
      />
      <ConfirmModalUI
        title="確認"
        description={`お客様画面で既に公開されているため、
        変更が即時で反映されますが、よろしいでしょうか？`}
        onCancel={() => {
          isOpenConfirmModalForPublished &&
            setIsOpenConfirmModalForPublished(false);
        }}
        onSubmit={handleUpdate}
        visibility={isOpenConfirmModalForPublished}
      />
      <ConfirmErrorModalUI
        title={errorModalTitle}
        description={errorModalDescription}
        btnTitle="閉じる"
        onClick={() => {
          isOpenConfirmErrorModal && setIsOpenConfirmErrorModal(false);
        }}
        visibility={isOpenConfirmErrorModal}
      />
    </>
  );
};

export default CoursesEditConfirmPageContainer;
