import React, { HTMLAttributes } from "react";

import styles from "./FormGroup.module.css";

export type FormGroupUIProps = HTMLAttributes<HTMLElement>;

export const FormGroupUI: React.FC<FormGroupUIProps> = ({
  className,
  children,
}) => {
  return (
    <div className={`pt-6 pb-8 space-y-2 ${className} ${styles.formGroup}`}>
      {children}
    </div>
  );
};
