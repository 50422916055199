import React, { MouseEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ReactComponent as SmallPlusIcon } from "../../../components/Button/small-plus.svg";
import { CsvDownloadButtonUI } from "../../../components/CsvDownloadButton";
import { ReactComponent as MoreIcon } from "../../../components/more.svg";
import { ContactsFragment } from "../../../generated/graphql";
import { ContactsTableUI } from "./components/ContactsTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "お問い合わせ対応",
  },
];

export type ContactsPageProps = {
  contacts: ContactsFragment;
  hasAdminAuthority: boolean;
  onDisplayMore: MouseEventHandler;
  onClickDownload: () => void;
};

const ContactsPage: React.VFC<ContactsPageProps> = ({
  contacts,
  hasAdminAuthority,
  onDisplayMore,
  onClickDownload,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">お問い合わせ対応</h1>
      <hr className="border-dark-gray border mb-2"></hr>
      <div className="flex flex-row justify-between items-center w-full mb-2">
        <span className="text-xl ml-6">お問い合わせ一覧</span>
        <ButtonUI
          data-testid="contacts-add-contact-btn"
          size="xs"
          buttonType="secondary"
          className={`inline-flex items-center justify-items-center justify-center`}
          to={`/contact/new`}
        >
          <SmallPlusIcon className="stroke-current" />
          新規登録
        </ButtonUI>
      </div>
      <hr className="ml-6 border-b-1 border-under-line" />
      <div className="ml-6 mt-5">
        {contacts.totalCount > 0 ? (
          <>
            <div className="flex justify-between mb-1.5 items-end">
              <span data-testid="contacts-page-total-count">
                全{contacts.totalCount}件中{contacts.nodes.length}件表示
              </span>
              {hasAdminAuthority && (
                <CsvDownloadButtonUI
                  disabled={contacts.totalCount > 1000}
                  onClick={onClickDownload}
                />
              )}
            </div>
            <ContactsTableUI contacts={contacts}></ContactsTableUI>
            {contacts.totalCount !== contacts.nodes.length && (
              <div className="flex justify-center mt-9 mb-10">
                <button
                  data-testid="contacts-page-display-more-btn"
                  className="text-blue hover:underline active:text-blue-active active:underline h-8"
                  onClick={onDisplayMore}
                >
                  <MoreIcon></MoreIcon>
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="contacts-page-no-contact-exists-message"
              >
                該当するお問い合わせがありません。
              </p>
              <p>検索条件に該当するお問い合わせがありません。</p>
              <p>内容をご確認のうえ、再度検索を行ってください。</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactsPage;
