import React, { useCallback, ChangeEventHandler } from "react";

import { InputUI } from "../../components/Input";
import { CourseQuestionnaire } from "../../lib/types/course";

export type LinearScaleQuestionUIProps = {
  questionnaire: CourseQuestionnaire;
  disabled: boolean;
  onChangeQuestionnaire: (questionnaire: CourseQuestionnaire) => void;
};

export const LinearScaleQuestionUI: React.FC<LinearScaleQuestionUIProps> = ({
  questionnaire,
  disabled = false,
  onChangeQuestionnaire,
}) => {
  const onChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        [event.target.name]: event.target.value,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  return (
    <div className="w-full mb-4">
      <div className="inline-block">
        最小値
        <br />
        ラベル
      </div>
      <InputUI
        data-testid={`course-questionnaires-${questionnaire.order}-min-label`}
        value={questionnaire.minLabel || ""}
        placeholder="例:低い（任意）"
        id="minLabel"
        name="minLabel"
        disabled={disabled}
        className="text-black inline-block ml-3 align-top border-black"
        onChange={onChangeInput}
      />
      <div className="inline-block ml-5">
        最大値
        <br />
        ラベル
      </div>
      <InputUI
        data-testid={`course-questionnaires-${questionnaire.order}-max-label`}
        value={questionnaire.maxLabel || ""}
        placeholder="例:高い（任意）"
        id="maxLabel"
        name="maxLabel"
        disabled={disabled}
        className=" text-black inline-block ml-3 align-top border-black"
        onChange={onChangeInput}
      />
    </div>
  );
};
