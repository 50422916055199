import React from "react";

import dayjs from "dayjs";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import { CourseClassDisplay } from "../../../../lib/types/course";
export type ClassItemProps = {
  clazz: CourseClassDisplay;
  index: number;
};

const ClassesItemUI: React.FC<ClassItemProps> = ({ clazz, index }) => {
  return (
    <tr
      data-testid="participants-item"
      className={`h-12 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className="border-r border-dark-gray text-center">{index + 1}</td>
      <td className="border-r border-dark-gray text-center">
        <LinkTextUI to={`/courses/${clazz.courseId}/classes/${clazz.id}`}>
          {clazz.systemId}
        </LinkTextUI>
      </td>
      <td className="border-r border-dark-gray text-center">
        {clazz.anyClassId}
      </td>
      <td className="border-r border-dark-gray text-center">{clazz.name}</td>
      <td className="border-r border-dark-gray text-center">
        {clazz.teacherNames &&
          clazz.teacherNames.map((teacherName: string, index: number) => (
            <div key={index}>{teacherName}</div>
          ))}
      </td>
      <td className="border-r border-dark-gray text-center">
        {clazz.schedule && dayjs(clazz.schedule).format("YYYY/MM/DD")}
      </td>
      <td className="border-r border-dark-gray text-center">
        {clazz.achievement}
      </td>
      <td className="border-r border-dark-gray text-center">
        {clazz.canceledCount}
      </td>
    </tr>
  );
};

export default ClassesItemUI;
