import React, { HTMLAttributes, PropsWithChildren } from "react";

import { BadgeUI } from "../Badge";

export type NewFormGroupUIProps = {
  label: string;
  htmlFor?: string;
  required?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const NewFormGroupUI: React.VFC<PropsWithChildren<NewFormGroupUIProps>> = ({
  label,
  htmlFor,
  required,
  className,
  children,
}) => {
  return (
    <div className={`pt-5 pb-4 ${className}`}>
      <div className="flex flex-row mb-2.5 items-center">
        <label htmlFor={htmlFor} className="text-lg font-bold">
          {label}
        </label>
        {required && <BadgeUI className="ml-2.5">必須</BadgeUI>}
      </div>
      {children}
    </div>
  );
};

export default NewFormGroupUI;
