import React, { PropsWithChildren, ReactNode } from "react";

import { HeaderUI } from "../../../../components/Header";
import { MeFragment } from "../../../../generated/graphql";
import style from "./ErrorPage.module.css";

type ErrorPageProps = {
  title: ReactNode;
  me?: MeFragment;
};

const ErrorPageUI: React.VFC<PropsWithChildren<ErrorPageProps>> = ({
  title,
  me,
  children,
}) => {
  return (
    <div>
      <HeaderUI me={me}></HeaderUI>
      <div
        className={`${style.errorContainer} container w-96 mx-auto text-center mt-36`}
      >
        <p
          className="text-error text-2xl tracking-wider mb-3.5"
          data-testid="error-page-title"
        >
          {title}
        </p>
        <div className="border border-error mb-3.5" />
        <p className="text-lg  tracking-wider" data-testid="error-page-content">
          {children}
        </p>
      </div>
    </div>
  );
};

export default ErrorPageUI;
