import React from "react";

import { useMeQuery } from "../../generated/graphql";
import ErrorPageUI from "./components/ErrorPage/ErrorPage";

const ServiceUnAuthorizedErrorPage: React.VFC = () => {
  const { data } = useMeQuery();
  return (
    <ErrorPageUI title="権限設定エラー" me={data?.me}>
      サービス権限が設定されていません。PF運用責任者にお問い合わせください。
    </ErrorPageUI>
  );
};

export default ServiceUnAuthorizedErrorPage;
