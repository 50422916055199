import { useMemo } from "react";

export type UploadIconProps = {
  pathFill: string;
  length?: string;
  rectFill?: string;
  className?: string;
};

const DEFAULT_SIZE = "24";

const UploadIcon: React.FC<UploadIconProps> = ({
  length,
  rectFill,
  className,
  pathFill,
}) => {
  const len = useMemo<string>(() => {
    return length ?? DEFAULT_SIZE;
  }, [length]);
  return (
    <svg
      id="download_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width={len}
      height={len}
      viewBox={`0 0 ${len} ${len}`}
      className={`${className ?? ""}`}
    >
      <rect id="Rectangle" width={len} height={len} fill={rectFill ?? "none"} />
      <path
        id="Shape"
        d="M0,16.7V14.736H14V16.7Zm4-3.93V6.876H0L7,0l7,6.876H10V12.77Z"
        transform="translate(5 2.947)"
        fill={pathFill}
      />
    </svg>
  );
};

export default UploadIcon;
