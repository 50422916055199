import React, { TextareaHTMLAttributes } from "react";

export type TextareaUIProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextareaUI: React.FC<TextareaUIProps> = ({
  className = "",
  children,
  ...rest
}) => {
  return (
    <textarea
      className={`${className} h-12 leading-5 pl-4 tracking-wider border rounded`}
      {...rest}
    />
  );
};
