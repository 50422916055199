import React, { MouseEventHandler, useEffect, useState } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CoursesDetailUI } from "../../../components/CoursesDetail";
import {
  CourseImage,
  CourseTeachingMaterial,
  LessonMasterTeachingMaterial,
} from "../../../generated/graphql";
import { PageType } from "../../../lib/constants/pageType";
import { CourseEdit, CourseDisplay } from "../../../lib/types/course";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "コース詳細",
  },
  {
    label: "コース編集",
  },
  {
    label: "コース編集確認",
  },
];

export type CoursesEditConfirmPageProps = {
  isLoading: boolean;
  courseEdit: CourseEdit;
  courseImages?: (CourseImage | (CourseImage & File))[];
  teachingMaterials?: CourseTeachingMaterial[];
  orderToLessonMaterialMap: Map<number, LessonMasterTeachingMaterial[]>;
  handleUpdate: () => Promise<void>;
  handleBack: MouseEventHandler<HTMLButtonElement>;
};

const CoursesEditConfirmPage: React.FC<CoursesEditConfirmPageProps> = ({
  isLoading,
  courseEdit,
  courseImages,
  teachingMaterials,
  orderToLessonMaterialMap,
  handleUpdate,
  handleBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const [courseDisplay] = useState<CourseDisplay>({
    id: courseEdit.id,
    systemId: courseEdit.systemId,
    name: courseEdit.name,
    courseId: courseEdit.courseId || "",
    organizer: courseEdit.organizer || undefined,
    targets: {
      type: courseEdit.targets?.type!,
      from: courseEdit.targets?.from,
      to: courseEdit.targets?.to,
    },
    subjectEntity1: courseEdit.subjectEntity1 || undefined,
    subjectEntity2: courseEdit.subjectEntity2 || undefined,
    levelEntity: courseEdit.levelEntity || undefined,
    price: {
      type: courseEdit.price?.type!,
      valueNoTax: courseEdit.price?.valueNoTax,
    },
    overview: courseEdit.overview,
    details: courseEdit.details,
    lessonMasters:
      courseEdit?.lessonMasters?.map((lessonMaster) => {
        const materials: LessonMasterTeachingMaterial[] =
          orderToLessonMaterialMap.get(lessonMaster.order) ?? [];
        return {
          name: lessonMaster.name,
          order: lessonMaster.order,
          teachingMaterials: materials.map((material) => material.name),
        };
      }) ?? [],
    images:
      courseEdit?.courseImages?.map((f) => {
        f.url = URL.createObjectURL(f);
        return f;
      }) ||
      courseImages?.map((f) => {
        if (f instanceof File && !f.url) {
          f.url = URL.createObjectURL(f);
        }
        return f;
      }) ||
      [],
    videos: courseEdit.videos || [],
    teachingMaterials:
      teachingMaterials?.map(
        (teachingMaterial: CourseTeachingMaterial) => teachingMaterial.name
      ) || [],
    questionnaires: courseEdit.questionnaires,
  });

  useEffect(() => {
    return () => {
      if (courseDisplay.images) {
        // メモリ解放
        courseDisplay.images.forEach((img) => {
          if (img.url) {
            URL.revokeObjectURL(img.url);
          }
        });
      }
    };
  }, [courseDisplay.images]);

  return (
    <div>
      <h1 className="text-2xl mb-3">コース編集確認</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className=" ml-5">
        <CoursesDetailUI
          course={courseDisplay}
          pageType={PageType.CoursesEditConfirmPage}
        ></CoursesDetailUI>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="courses-confirm-back"
            buttonType="secondary"
            onClick={handleBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI
            disabled={isLoading}
            data-testid="courses-confirm-submit"
            onClick={handleUpdate}
          >
            登録
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default CoursesEditConfirmPage;
