import React, { ChangeEventHandler } from "react";

import { CustomerIdNode } from "..";
import LinkTextUI from "../../../../components/LinkText/LinkText";
import { RadioUI } from "../../../../components/Radio";
import { PageType } from "../../../../lib/constants/pageType";
import styles from "./CustomerIdsGridCell.module.css";

export type CustomerIdsGridCellProps = {
  customer: CustomerIdNode;
  pageType?: PageType;
  index: number;
  selectedCustomerId?: string | null;
  onSelectedCustomer?: ChangeEventHandler<HTMLInputElement>;
};

const CustomerIdsGridCellUI: React.FC<CustomerIdsGridCellProps> = ({
  customer,
  pageType = PageType.CustomerSearchResultsPage,
  index,
  selectedCustomerId = "",
  onSelectedCustomer,
}) => {
  return (
    <div
      data-testid="customers-grid-cell"
      className={`${styles.cell} ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      {pageType === PageType.ContactsNewPage && (
        <div className="flex justify-center">
          <RadioUI
            data-testid="users-edit-gender"
            value={customer.id}
            checked={customer.id === selectedCustomerId}
            onChange={onSelectedCustomer}
          ></RadioUI>
        </div>
      )}
      <div className={`${pageType !== PageType.ContactsNewPage ? "" : ""}`}>
        {pageType === PageType.ContactsNewPage ? (
          <>{customer.id}</>
        ) : (
          <LinkTextUI to={`/customers/${customer.id}`}>
            {customer.id}
          </LinkTextUI>
        )}
      </div>
    </div>
  );
};

export default CustomerIdsGridCellUI;
