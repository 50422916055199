import React from "react";

import { CourseClassDisplay } from "../../../../lib/types/course";
import ClassesItemUI from "./ClassesItem";

export type ClassesUIProps = {
  classes: CourseClassDisplay[];
};

export const ClassesUI: React.FC<ClassesUIProps> = ({ classes }) => {
  return (
    <table className="border-collapse border border-dark-gray w-full">
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-12">
          <th className="border border-dark-gray text-center">No</th>
          <th className="border border-dark-gray text-center">システムID</th>
          <th className="border border-dark-gray text-center">クラスID</th>
          <th className="border border-dark-gray text-center">クラス名</th>
          <th className="border border-dark-gray text-center">教師</th>
          <th className="border border-dark-gray text-center">日程</th>
          <th className="border border-dark-gray text-center">実績</th>
          <th className="border border-dark-gray text-center">
            キャンセル人数
          </th>
        </tr>
      </thead>
      <tbody>
        {classes.map((clazz: CourseClassDisplay, index: number) => (
          <ClassesItemUI
            key={clazz.id}
            index={index}
            clazz={clazz}
          ></ClassesItemUI>
        ))}
      </tbody>
    </table>
  );
};
