const maxAcc = (max: Date, date: Date) => {
  return date > max ? date : max;
};

const minAcc = (min: Date, date: Date) => {
  return date < min ? date : min;
};

export const max = (dates: Date[], defaultValue?: Date): Date | undefined => {
  return dates.length ? dates.reduce(maxAcc) : defaultValue;
};

export const min = (dates: Date[], defaultValue?: Date): Date | undefined => {
  return dates.length ? dates.reduce(minAcc) : defaultValue;
};

export type TermItem = {
  startedAt: Date;
  endedAt: Date;
};

export const endpointAtOfTermItems = (
  items: Partial<TermItem>[]
): Partial<TermItem> => {
  return {
    startedAt: min(
      items.map((item) => item.startedAt).filter((d): d is Date => !!d)
    ),
    endedAt: max(
      items.map((item) => item.endedAt).filter((d): d is Date => !!d)
    ),
  };
};
