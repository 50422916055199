import React, {
  ChangeEventHandler,
  useEffect,
  useState,
  useCallback,
} from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../components/Button";
import { FormGroupUI } from "../../../../components/FormGroup";
import { SelectCustomStyleUI } from "../../../../components/SelectCustomStyle";
import { ValidatableTextareaUI } from "../../../../components/ValidatableTextarea";
import { Maybe } from "../../../../generated/graphql";
import {
  PaymentMismatchStatus,
  paymentMismatchStatuses,
} from "../../../../lib/constants/PaymentMismatch";
import { preventFromSubmittingByEnter } from "../../../../lib/functions";
import styles from "./HandlingStatusRegister.module.scss";

export type HandlingStatusRegisterProps = {
  handlingStatus?: Maybe<string>;
  onCreate: (handlingStatus: string, note: string) => Promise<void>;
};

export type Validation = {
  note: string;
};

export const HandlingStatusRegisterUI: React.FC<
  HandlingStatusRegisterProps
> = ({ handlingStatus, onCreate }) => {
  const [label, setLabel] = useState<string>("");
  const [prevLabel, setPrevLabel] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [note, setNote] = useState<string>("");

  const onChangeNote: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setNote(event.target.value);
    },
    []
  );

  const onChangeStatus = useCallback(
    ({ name, value }: { name: string; value: string }): void => {
      for (const mismatchStatus of paymentMismatchStatuses) {
        if (mismatchStatus.value === value) {
          setStatus(value);
          setLabel(mismatchStatus.label);
        }
      }
    },
    []
  );

  const onHandleSubmit: SubmitHandler<Validation> = async () => {
    if (prevLabel === label) {
      await onCreate(status, note);
      return;
    }
    await onCreate(
      status,
      `${note}\n対応状況を「${prevLabel}」から「${label}」に変更`
    );
    return;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Validation>({
    defaultValues: {
      note: "",
    },
  });

  useEffect(() => {
    handlingStatus && setStatus(handlingStatus);
    switch (handlingStatus) {
      case PaymentMismatchStatus.Unhandled:
        setPrevLabel("未対応");
        setLabel("未対応");
        break;
      case PaymentMismatchStatus.Handling:
        setPrevLabel("対応中");
        setLabel("対応中");
        break;
      case PaymentMismatchStatus.Done:
        setPrevLabel("対応済");
        setLabel("対応済");
        break;
    }
  }, [handlingStatus]);

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
      className="w-full"
    >
      <FormGroupUI>
        <SelectCustomStyleUI
          className="w-52"
          options={paymentMismatchStatuses}
          value={label}
          name="handlingStatus"
          onChange={onChangeStatus}
        />
      </FormGroupUI>
      <FormGroupUI>
        <div className="mt-2">
          <ValidatableTextareaUI
            isCheckIcon={false}
            registerParams={{
              register,
              error: errors.note,
              label: "note",
              conditions: {
                maxLength: {
                  value: 200,
                  message: "200文字以内で入力してください。",
                },
                validate: () =>
                  !(prevLabel === label && note === "") ||
                  "対応ステータスの変更または対応履歴の記載を行ってください。",
              },
            }}
            data-testid="handling-status-note"
            errorIdPrefix="handling-status-note"
            rows={5}
            className="w-full"
            id="note"
            name="note"
            value={note}
            placeholder="対応状況メモ（200文字以内）"
            onChange={onChangeNote}
          />
        </div>
      </FormGroupUI>
      <div className="flex justify-end">
        <ButtonUI
          data-testid="hisotry-register-btn"
          size="xs"
          base="rounded20"
          className={`inline-flex items-center justify-items-center justify-center ${styles.btnAdd}`}
        >
          登録
        </ButtonUI>
      </div>
    </form>
  );
};
