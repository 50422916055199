import React from "react";

import { MismatchHandlingHistoryFragment } from "../../../../generated/graphql";
import HandlingHistoryTableRowUI from "./HandlingHistoryTableRow";

export type HandlingHistoryTableUIProps = {
  handlingHistories?: MismatchHandlingHistoryFragment[];
};

export const HandlingHistoryTableUI: React.FC<HandlingHistoryTableUIProps> = ({
  handlingHistories,
}) => {
  return (
    <div className="overflow-y-auto w-full h-48">
      {handlingHistories?.map(
        (handlingHistory: MismatchHandlingHistoryFragment) => (
          <HandlingHistoryTableRowUI
            data-testid="payment-mismatch-handling-histories-table-row"
            handlingHistory={handlingHistory}
            key={handlingHistory.id}
          ></HandlingHistoryTableRowUI>
        )
      )}
    </div>
  );
};
