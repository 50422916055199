import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CreateAnnouncementInput } from "../../../generated/graphql";
import AnnouncementDetailUI from "../components/AnnouncementDetail/AnnouncementDetail";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

const AnnouncementsNewConfirmPage: React.FC<{
  input: CreateAnnouncementInput;
  onBack: () => void;
  onRegister: () => void;
}> = ({ input, onBack, onRegister }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <h2 className="text-1.5xl text-title-black">お知らせ新規登録</h2>
      <AnnouncementDetailUI
        className="mt-8"
        systemId="登録後に発行されます。"
        kind={input.kind}
        publishStartedAt={input.publishStartedAt}
        publishEndedAt={input.publishEndedAt}
        subject={input.subject}
        body={input.body}
      />
      <div className="py-20 flex justify-center space-x-10">
        <ButtonUI
          data-testid="announcement-back-btn"
          buttonType="secondary"
          onClick={onBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI data-testid="announcement-create-btn" onClick={onRegister}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default AnnouncementsNewConfirmPage;
