import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../../components/Button";
import { LabelUI } from "../../../../../components/Label";
import { ModalUI } from "../../../../../components/Modal";
import { ModalFormGroupUI } from "../../../../../components/MordalFormGroup";
import { RadioUI } from "../../../../../components/Radio";
import { ValidatableInputUI } from "../../../../../components/ValidatableInput";
import { SubjectsResultItemFragment } from "../../../../../generated/graphql";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";
import { SubjectNew } from "../../../../../lib/types";

export enum SubjectEditMode {
  CREATE = "create",
  UPDATE = "update",
}

enum SubjectStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

type Props = {
  visibility: boolean;
  onCreate?: (name: string, status: boolean) => Promise<void>;
  onUpdate?: (id: string, name: string, status: boolean) => Promise<void>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  editMode: SubjectEditMode;
  subject?: SubjectsResultItemFragment;
};

export const SubjectEditModalUI = ({
  visibility,
  onCreate,
  onUpdate,
  onCancel,
  editMode,
  subject,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubjectNew>({
    defaultValues: {
      name: subject?.name,
    },
  });

  const [subjectName, setSubjectName] = useState<string>(
    editMode === SubjectEditMode.UPDATE ? subject!.name : ""
  );
  const [subjectStatus, setSubjectStatus] = useState<string>(
    editMode === SubjectEditMode.UPDATE && subject!.status === false
      ? SubjectStatus.INACTIVE
      : SubjectStatus.ACTIVE
  );

  const onStatusChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSubjectStatus(event.target.value);
    },
    []
  );

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setSubjectName(event.target.value);
    },
    []
  );

  const onCancelProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onCancel(event);
    },
    [onCancel]
  );

  const onHandleSubmit: SubmitHandler<SubjectNew> = () => {
    if (editMode === SubjectEditMode.CREATE) {
      onCreate!(subjectName, subjectStatus === SubjectStatus.ACTIVE);
    } else if (editMode === SubjectEditMode.UPDATE) {
      onUpdate!(
        subject!.id,
        subjectName,
        subjectStatus === SubjectStatus.ACTIVE
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
    >
      <ModalUI
        data-testid="subject-edit-modal"
        visibility={visibility}
        className="w-full"
      >
        <div className="inline-block pt-4 pb-10 px-3 text-center">
          <h1
            className="text-left text-2xl tracking-wider text-subtitle"
            data-testid="subject-edit-modal-title"
          >
            項目{editMode === SubjectEditMode.CREATE ? "追加" : "編集"}
          </h1>
          <hr className="border-under-line mt-1"></hr>
          <div
            className="mt-4 text-2xl tracking-wider"
            data-testid="subject-edit-description"
          >
            <div className="whitespace-pre-wrap"></div>
          </div>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">コード</LabelUI>
              <p data-testid="subject-system-code">
                {editMode === SubjectEditMode.CREATE
                  ? "自動で採番されます。"
                  : subject?.systemId}
              </p>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">商品カテゴリ名</LabelUI>
              <div className="text-left w-10/12">
                <ValidatableInputUI
                  data-testid="subject-edit-name"
                  errorIdPrefix="subject-edit-name-error"
                  registerParams={{
                    register,
                    error: errors.name,
                    label: "name",
                    conditions: {
                      required: {
                        value: true,
                        message: "商品カテゴリ名は必須項目です。",
                      },
                      maxLength: {
                        value: 20,
                        message: "２０文字以内で入力してください。",
                      },
                    },
                  }}
                  className="w-10/12"
                  placeholder="商品カテゴリ名を入力してください。"
                  id="name"
                  name="name"
                  value={subjectName}
                  onChange={onChangeName}
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">ステータス</LabelUI>
              <div className="flex space-x-10">
                {[
                  { label: "有効", value: SubjectStatus.ACTIVE },
                  { label: "無効", value: SubjectStatus.INACTIVE },
                ].map((status) => (
                  <RadioUI
                    data-testid="subject-edit-status"
                    key={status.value}
                    id={`status-${status.value}`}
                    name="status"
                    value={status.value}
                    checked={subjectStatus === status.value}
                    onChange={onStatusChange}
                  >
                    {status.label}
                  </RadioUI>
                ))}
              </div>
            </div>
          </ModalFormGroupUI>
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              data-testid="subject-edit-cancel"
              type="button"
              buttonType="secondary"
              onClick={onCancelProxy}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="subject-edit-submit" type="submit">
              {editMode === SubjectEditMode.CREATE && "登録"}
              {editMode === SubjectEditMode.UPDATE && "更新"}
            </ButtonUI>
          </div>
        </div>
      </ModalUI>
    </form>
  );
};
