import { useCallback, useEffect, useRef, useState } from "react";

import { Level } from "../../generated/graphql";
import { SelectCustomStyleUI, SelectOption } from "../SelectCustomStyle";

export interface LevelSelectorProps {
  placeholder?: string;
  id?: string;
  name: string;
  className?: string;
  value?: string;
  defaultLevel?: Level;
  errorBorder?: boolean;
  levels: Level[];
  onLevelChange: (level: Level | undefined) => void;
}

export const LevelSelector: React.VFC<LevelSelectorProps> = ({
  id,
  name,
  placeholder = "選択項目",
  className,
  value,
  defaultLevel,
  errorBorder,
  levels,
  onLevelChange,
  ...rest
}) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const levelsRef = useRef<Level[]>([]);
  const [levelOptions, setLevelOptions] = useState<SelectOption[]>([]);
  const [responseLevels, setResponseLevels] = useState<Level[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string>("");

  useEffect(() => {
    if (levelsRef.current !== levels) {
      levelsRef.current = levels;
      setInitialized(false);
    }
  }, [levels, onLevelChange]);

  useEffect(() => {
    if (!initialized) {
      setResponseLevels(levels);
      const options: SelectOption[] = levels.map((level: Level) => {
        if (defaultLevel && defaultLevel.id === level.id) {
          setSelectedLevel(defaultLevel.name);
        }
        return {
          label: level.name,
          value: level.id,
        };
      });
      if (defaultLevel && !defaultLevel.status) {
        // status がDB上で false となっているときに選択肢に追加する処理
        options.unshift({
          label: defaultLevel.name,
          value: defaultLevel.id,
        });
        setResponseLevels([...levels, defaultLevel]);
        setSelectedLevel(defaultLevel.name);
      }
      options.unshift({
        label: "",
        value: "",
      });
      setLevelOptions(options);
      setInitialized(true);
    }
  }, [defaultLevel, initialized, levels]);

  const onSelectionChange = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (!value) {
        onLevelChange(undefined);
        setSelectedLevel("");
        return;
      }
      for (const level of responseLevels) {
        if (level.id === value) {
          onLevelChange(level);
          setSelectedLevel(level.name);
          return;
        }
      }
    },
    [onLevelChange, responseLevels]
  );

  return (
    <SelectCustomStyleUI
      placeholder={placeholder}
      options={levelOptions}
      name="level"
      className={className}
      id={id}
      value={selectedLevel}
      errorBorder={errorBorder}
      onChange={onSelectionChange}
      {...rest}
    />
  );
};

export const LEVEL_SELECTOR_QUERY_VARIABLES = {
  filter: {
    active: true,
  },
  offset: 0,
  limit: 2 ** 31 - 1, // 2147483647 32 bit signed integer
};
