import React, {
  ChangeEventHandler,
  FormEventHandler,
  HtmlHTMLAttributes,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { NetworkStatus } from "@apollo/client";
import { Location, Action } from "history";
import { Prompt, useLocation } from "react-router-dom";

import { CheckboxUI } from "../../../../../components/Checkbox";
import { PaginationUI } from "../../../../../components/Pagination";
import {
  MasterPageVideoTagFragment,
  useMasterPageVideoTagsLazyQuery,
  useCreateVideoTagMutation,
  useUpdateVideoTagMutation,
  CreateVideoTagInput,
  UpdateVideoTagInput,
} from "../../../../../generated/graphql";
import { useErrorRouter } from "../../../../../hooks/errorRouter";
import { Column, MasterTableUI } from "../MasterTable";
import { PlusButtonUI } from "../PlusButton/PlusButton";
import { SearchInputUI } from "../SearchInput/SearchInput";
import { VideoTagEditModalUI, VideoTagEditMode } from "../VideoTagEditModal";

type ModalControl = {
  visible: boolean;
  mode: VideoTagEditMode;
  videoTag?: MasterPageVideoTagFragment;
};

const columns: Column[] = [
  {
    title: "コード",
    name: "id",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
  },
  {
    title: "動画タグ",
    name: "name",
    headerClassName: "text-left pl-2.5 w-auto",
    rowClassName: "text-left pl-2.5 ",
  },
  {
    title: "ステータス",
    name: "status",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
    formatter: (value) => (value ? "有効" : "無効"),
  },
];

export const VideoTagsUI: React.VFC<HtmlHTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const location = useLocation();
  const state = location.state as string;
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [searchWord, setSearchWord] = useState<string>(state ?? "");
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);

  // レベル追加/編集モーダル制御
  const [modalControl, setModalControl] = useState<ModalControl>({
    visible: false,
    mode: VideoTagEditMode.CREATE,
    videoTag: undefined,
  });

  const errorRouter = useErrorRouter();
  const [loadVideoTags, { loading, data, networkStatus }] =
    useMasterPageVideoTagsLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onError: errorRouter,
    });

  const [createVideoTag] = useCreateVideoTagMutation({
    onCompleted: () => {
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    onError: errorRouter,
  });
  const [updateVideoTag] = useUpdateVideoTagMutation({
    onCompleted: () => {
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    onError: errorRouter,
  });

  // 動画タグ追加/編集モーダルの表示制御
  const setModalVisible = useCallback((visible: boolean) => {
    setModalControl((prev) => ({
      ...prev,
      visible,
    }));
  }, []);

  // ブラウザバック時にモーダルを非表示化
  const handleBlockBack = useCallback(
    (location: Location, action: Action) => {
      if (location.pathname === "/masters" && action === "POP") {
        setModalVisible(false);
        return false;
      }
      return true;
    },
    [setModalVisible]
  );

  const handleOnCreateVideoTag = useCallback(
    (input: CreateVideoTagInput) => {
      createVideoTag({
        variables: {
          input,
        },
      });
    },
    [createVideoTag]
  );

  const handleOnUpdateVideoTag = useCallback(
    (input: UpdateVideoTagInput) => {
      updateVideoTag({
        variables: {
          input,
        },
      });
    },
    [updateVideoTag]
  );

  const onVideoTagNewBtnClick: MouseEventHandler = useCallback(() => {
    setModalControl({
      mode: VideoTagEditMode.CREATE,
      visible: true,
      videoTag: undefined,
    });
  }, []);

  const onVideoTagEditBtnClick = useCallback(
    (videoTag: MasterPageVideoTagFragment) => {
      setModalControl({
        mode: VideoTagEditMode.UPDATE,
        visible: true,
        videoTag,
      });
    },
    []
  );

  const onChangeVideoTagsSearchFilter: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        setSearchWord(event.currentTarget.value);
      },
      [setSearchWord]
    );

  const onSearch: FormEventHandler = (event) => {
    event.preventDefault();
    setOffset(0);
    setExecQueryTrigger(true);
  };

  const onDeleteSearchFilter: MouseEventHandler = useCallback(() => {
    setSearchWord("");
    setOffset(0);
    setExecQueryTrigger(true);
  }, [setSearchWord]);

  const [filterActiveFlg, setFilterActiveFlg] = useState<true | undefined>(
    undefined
  );

  const onChangeActiveFilterCheckbox: ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      const active: true | undefined = e.target.checked ? true : undefined;
      setFilterActiveFlg(active);
      setOffset(0);
      setExecQueryTrigger(true);
    }, []);

  // ページネーションの前ページボタンクリックハンドラー
  const onClickPrevious = useCallback(() => {
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションの次ページボタンクリックハンドラー
  const onClickNext = useCallback(() => {
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションのページ指定ボタンクリックハンドラー
  const onClickPaginationItem = useCallback(
    (index: number) => {
      setOffset(index * limit);
      setExecQueryTrigger(true);
    },
    [limit]
  );

  useEffect(() => {
    if (!execQueryTrigger) return;

    loadVideoTags({
      variables: {
        filter: {
          active: filterActiveFlg,
          searchWord,
        },
        offset: offset,
        limit,
      },
    });
    setExecQueryTrigger(false);
  }, [execQueryTrigger, offset, searchWord, filterActiveFlg, loadVideoTags]);

  return (
    <div {...props}>
      <form className="flex space-x-7 mt-4" onSubmit={onSearch}>
        <SearchInputUI
          className="flex-grow"
          data-testid="videoTag-search-input"
          placeholder="コード、動画タグ名"
          value={searchWord}
          onChange={onChangeVideoTagsSearchFilter}
          onClickSearch={onSearch}
          onClickDelete={onDeleteSearchFilter}
        ></SearchInputUI>
        <CheckboxUI
          className="flex-none"
          style={{ marginRight: "10px" }}
          data-testid="videoTag-active-checkbox"
          type="m"
          onChange={onChangeActiveFilterCheckbox}
        >
          ステータスが有効のみ表示する
        </CheckboxUI>
      </form>
      <div className="flex mt-5 justify-end">
        <PlusButtonUI
          data-testid="plus-button"
          onClick={onVideoTagNewBtnClick}
        />
      </div>
      {(loading ||
        data === undefined ||
        networkStatus === NetworkStatus.refetch) && (
        <div className="mt-4">Loading...</div>
      )}
      {!loading && data && data.videoTags.totalCount > 0 && (
        <>
          <p
            className="tracking-wider mt-2 mb-3"
            data-testid="videoTags-display-count"
          >{`全${data.videoTags!.totalCount}件中${offset + 1}件〜${
            offset + data.videoTags!.nodes.length
          }件を表示`}</p>
          <MasterTableUI
            rows={data.videoTags.nodes}
            columns={columns}
            willEdit={onVideoTagEditBtnClick}
          />
          {data.videoTags!.totalCount > 0 && (
            <PaginationUI
              className="mt-20 mb-20"
              selectedIndex={Math.ceil(offset / limit)}
              count={Math.ceil(data.videoTags!.totalCount / limit)}
              onClickPrevios={onClickPrevious}
              onClickNext={onClickNext}
              onClickItem={onClickPaginationItem}
            />
          )}
        </>
      )}
      {!loading && data?.videoTags.totalCount === 0 && (
        <div className="bg-gray-200 text-center mt-5">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-videoTag-exists-message"
          >
            該当する動画タグがありません。
          </p>
          <p>検索条件に該当する動画タグがありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
      {modalControl.visible && (
        <VideoTagEditModalUI
          editMode={modalControl.mode}
          visibility={modalControl.visible}
          videoTag={modalControl.videoTag}
          onCancel={() => {
            setModalVisible(false);
          }}
          onCreate={handleOnCreateVideoTag}
          onUpdate={handleOnUpdateVideoTag}
        />
      )}
      <Prompt when={true} message={handleBlockBack} />
    </div>
  );
};
