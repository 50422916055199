import dayjs from "dayjs";

import "dayjs/locale/ja";
import { CoursesDnDTableItemFragment } from "../../../../generated/graphql";
import { endpointAtOfTermItems, TermItem } from "../../../../lib/utils/date";
dayjs.locale("ja");

export type ClazzFragment = CoursesDnDTableItemFragment["clazzes"][0];

const endpointAtOfClazz = (clazz: ClazzFragment): Partial<TermItem> => {
  if (!clazz.lessons) {
    return {};
  }
  return endpointAtOfTermItems(clazz.lessons);
};

const endpointAtOfCourse = (
  course: CoursesDnDTableItemFragment
): Partial<TermItem> | undefined => {
  const publishedClazzes = course.clazzes.filter((c) => c.published);
  return endpointAtOfTermItems(
    publishedClazzes.map((c) => endpointAtOfClazz(c))
  );
};

export const formattedTerm = (course: CoursesDnDTableItemFragment): string => {
  const { startedAt, endedAt } = endpointAtOfCourse(course) || {};
  const format = "YYYY年MM月DD日(ddd)HH:mm";
  const formattedStart = startedAt ? dayjs(startedAt).format(format) : "";
  const formattedEnd = endedAt ? dayjs(endedAt).format(format) : "";
  return !formattedStart && !formattedEnd
    ? ""
    : `${formattedStart}～${formattedEnd}`;
};
