import {
  ChangeEventHandler,
  FormEventHandler,
  HtmlHTMLAttributes,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { NetworkStatus } from "@apollo/client";
import { Location, Action } from "history";
import { Prompt, useLocation } from "react-router-dom";

import { PaginationUI } from "../../../../../components/Pagination";
import {
  MasterPageOrganizerFragment,
  useMasterPageOrganizersLazyQuery,
  useCreateOrganizerMutation,
  useUpdateOrganizerMutation,
  CreateOrganizerInput,
  UpdateOrganizerInput,
} from "../../../../../generated/graphql";
import { useErrorRouter } from "../../../../../hooks/errorRouter";
import { Column, MasterTableUI } from "../MasterTable";
import { OrganizerEditModalUI, OrganizerEditMode } from "../OrganizerEditModal";
import { PlusButtonUI } from "../PlusButton/PlusButton";
import { SearchInputUI } from "../SearchInput/SearchInput";

type ModalControl = {
  visible: boolean;
  mode: OrganizerEditMode;
  organizer?: MasterPageOrganizerFragment;
};

const columns: Column[] = [
  {
    title: "コード",
    name: "id",
    formatter: (value) => `org-${("000000" + value).slice(-6)}`,
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
  },
  {
    title: "事業者名",
    name: "name",
    headerClassName: "text-left pl-2.5 w-auto",
    rowClassName: "text-left pl-2.5 ",
  },
];

export const OrganizersUI: React.VFC<HtmlHTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const location = useLocation();
  const state = location.state as string;
  const limit = 20;
  // クエリー・パラメター
  const [offset, setOffset] = useState(0);
  const [searchWord, setSearchWord] = useState<string>(state ?? "");
  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);

  // レベル追加/編集モーダル制御
  const [modalControl, setModalControl] = useState<ModalControl>({
    visible: false,
    mode: OrganizerEditMode.CREATE,
    organizer: undefined,
  });

  const errorRouter = useErrorRouter();
  const [loadOrganizers, { loading, data, networkStatus }] =
    useMasterPageOrganizersLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onError: errorRouter,
    });

  const [createOrganizer] = useCreateOrganizerMutation({
    onCompleted: () => {
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    onError: errorRouter,
  });

  const [updateOrganizer] = useUpdateOrganizerMutation({
    onCompleted: () => {
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    onError: errorRouter,
  });

  // 事業者追加/編集モーダルの表示制御
  const setModalVisible = useCallback((visible: boolean) => {
    setModalControl((prev) => ({
      ...prev,
      visible,
    }));
  }, []);

  // ブラウザバック時にモーダルを非表示化
  const handleBlockBack = useCallback(
    (location: Location, action: Action) => {
      if (location.pathname === "/masters" && action === "POP") {
        setModalVisible(false);
        return false;
      }
      return true;
    },
    [setModalVisible]
  );

  const handleOnCreateOrganizer = useCallback(
    (input: CreateOrganizerInput) => {
      createOrganizer({
        variables: {
          input,
        },
      });
    },
    [createOrganizer]
  );

  const handleOnUpdateOrganizer = useCallback(
    (input: UpdateOrganizerInput) => {
      updateOrganizer({
        variables: {
          input,
        },
      });
    },
    [updateOrganizer]
  );

  const onOrganizerNewBtnClick: MouseEventHandler = useCallback(() => {
    setModalControl({
      mode: OrganizerEditMode.CREATE,
      visible: true,
      organizer: undefined,
    });
  }, []);

  const onOrganizerEditBtnClick = useCallback(
    (organizer: MasterPageOrganizerFragment) => {
      setModalControl({
        mode: OrganizerEditMode.UPDATE,
        visible: true,
        organizer,
      });
    },
    []
  );

  const onChangeOrganizersSearchFilter: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        setSearchWord(event.currentTarget.value);
      },
      [setSearchWord]
    );

  const onSearch: FormEventHandler = (event) => {
    event.preventDefault();
    setOffset(0);
    setExecQueryTrigger(true);
  };

  const onDeleteSearchFilter: MouseEventHandler = useCallback(() => {
    setSearchWord("");
    setOffset(0);
    setExecQueryTrigger(true);
  }, [setSearchWord]);

  // ページネーションの前ページボタンクリックハンドラー
  const onClickPrevious = useCallback(() => {
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションの次ページボタンクリックハンドラー
  const onClickNext = useCallback(() => {
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションのページ指定ボタンクリックハンドラー
  const onClickPaginationItem = useCallback(
    (index: number) => {
      setOffset(index * limit);
      setExecQueryTrigger(true);
    },
    [limit]
  );

  useEffect(() => {
    if (!execQueryTrigger) return;

    loadOrganizers({
      variables: {
        filter: {
          searchWord,
        },
        offset: offset,
        limit,
      },
    });
    setExecQueryTrigger(false);
  }, [execQueryTrigger, offset, searchWord, loadOrganizers]);

  return (
    <div {...props}>
      <form className="flex space-x-7 mt-4" onSubmit={onSearch}>
        <SearchInputUI
          className="flex-grow"
          data-testid="organizer-search-input"
          placeholder="コード、事業者名"
          value={searchWord}
          onChange={onChangeOrganizersSearchFilter}
          onClickSearch={onSearch}
          onClickDelete={onDeleteSearchFilter}
        ></SearchInputUI>
      </form>
      <div className="flex mt-5 justify-end">
        <PlusButtonUI
          data-testid="plus-button"
          onClick={onOrganizerNewBtnClick}
        />
      </div>
      {(loading ||
        data === undefined ||
        networkStatus === NetworkStatus.refetch) && (
        <div className="mt-4">Loading...</div>
      )}
      {!loading && data && data.organizers.totalCount > 0 && (
        <>
          <p
            className="tracking-wider mt-2 mb-3"
            data-testid="organizers-display-count"
          >{`全${data.organizers!.totalCount}件中${offset + 1}件〜${
            offset + data.organizers!.nodes.length
          }件を表示`}</p>
          <MasterTableUI
            rows={data.organizers.nodes}
            columns={columns}
            willEdit={onOrganizerEditBtnClick}
          />
          {data.organizers!.totalCount > 0 && (
            <PaginationUI
              className="mt-20 mb-20"
              selectedIndex={Math.ceil(offset / limit)}
              count={Math.ceil(data.organizers!.totalCount / limit)}
              onClickPrevios={onClickPrevious}
              onClickNext={onClickNext}
              onClickItem={onClickPaginationItem}
            />
          )}
        </>
      )}
      {!loading && data?.organizers.totalCount === 0 && (
        <div className="bg-gray-200 text-center mt-5">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-organizer-exists-message"
          >
            該当する事業者がありません。
          </p>
          <p>検索条件に該当する事業者がありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
      {modalControl.visible && (
        <OrganizerEditModalUI
          editMode={modalControl.mode}
          visibility={modalControl.visible}
          organizer={modalControl.organizer}
          onCancel={() => {
            setModalVisible(false);
          }}
          onCreate={handleOnCreateOrganizer}
          onUpdate={handleOnUpdateOrganizer}
        />
      )}
      <Prompt when={true} message={handleBlockBack} />
    </div>
  );
};
