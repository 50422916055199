import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  ContentTeachingMaterial,
  CourseTeachingMaterial,
  LessonMasterTeachingMaterial,
} from "../../generated/graphql";
import { ReactComponent as CloseIcon } from "./close.svg";
import styles from "./FileInputTypeDocument.module.scss";

export type FileInputTypeDocumentUIProps = {
  previewWidth?: number;
  previewHeight?: number;
  existMaterials?: (
    | ContentTeachingMaterial
    | CourseTeachingMaterial
    | LessonMasterTeachingMaterial
    | File
  )[];
  onDeleteFile: (removeFileName: string) => void;
  onChangeMaterialFile: (file: File) => void;
  onChangeErrMaterialFiles: (files: string[]) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const FileInputTypeDocumentUI: React.FC<
  FileInputTypeDocumentUIProps
> = ({
  id,
  className = "",
  previewWidth = 100,
  previewHeight = 100,
  existMaterials,
  onChangeMaterialFile,
  onChangeErrMaterialFiles,
  onDeleteFile,
  children,
  ...rest
}) => {
  // 制限サイズ 1G
  const sizeLimit = 1024 * 1024 * 1024 * 1;
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [errFileNames, setErrFileNames] = useState<string[]>([]);
  const [duplicateFilename, setDuplicateFilename] = useState(false);
  const [typeErrFileName, setTypeErrFileName] = useState(false);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e.target.files && e.target.files[0];

      // 同じファイルを選択した場合でも動作するように対応
      e.target.value = "";

      if (file?.name) {
        if (fileNames.includes(file.name)) {
          setDuplicateFilename(true);
          return;
        } else {
          setDuplicateFilename(false);
        }
      }

      if (
        file?.type !== "application/pdf" &&
        file?.type !== "application/msword" &&
        file?.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file?.type !== "application/vnd.ms-excel" &&
        file?.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file?.type !== "video/mp4"
      ) {
        setTypeErrFileName(true);
        return;
      } else {
        setTypeErrFileName(false);
      }

      if (file?.size! > sizeLimit) {
        const newFiles = [file?.name!, ...errFileNames].filter((v) => v);
        setErrFileNames(newFiles);
        onChangeErrMaterialFiles(newFiles!);
      } else {
        setFileNames([file?.name!, ...fileNames].filter((v) => v));
        onChangeMaterialFile(file!);
      }
    },
    [
      errFileNames,
      fileNames,
      onChangeErrMaterialFiles,
      onChangeMaterialFile,
      sizeLimit,
    ]
  );

  const handleDelete = useCallback(
    (fileName: string) => {
      setFileNames([...fileNames.filter((key) => key !== fileName)]);
      onDeleteFile(fileName);
    },
    [fileNames, onDeleteFile]
  );

  const handleErrDelete = useCallback(
    (fileName: string) => {
      const newFiles = [...errFileNames.filter((key) => key !== fileName)];
      setErrFileNames(newFiles);
      onChangeErrMaterialFiles(newFiles!);
      onDeleteFile(fileName);
    },
    [errFileNames, onChangeErrMaterialFiles, onDeleteFile]
  );

  useEffect(() => {
    if (existMaterials) {
      setFileNames([
        ...existMaterials.map((existMaterial) => existMaterial.name!),
      ]);
    }
  }, [existMaterials]);

  return (
    <>
      <label htmlFor={id} className={styles.label} data-testid="file-upload">
        <div className={styles.labelText}>
          <span>教材を追加</span>
        </div>
      </label>
      {duplicateFilename && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`file-input-duplicate-filename-error-message`}
        >
          すでに同じ名前のファイルが選択されています。別のファイルを選択してください。
        </p>
      )}
      {typeErrFileName && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`file-input-type-error-filename-error-message`}
        >
          アップロードできるファイルはPDF,Word,Excel,MP4形式のみです。別のファイルを選択してください。
        </p>
      )}
      {fileNames.map((fileName, index) => (
        <div key={`fileName-${index}`}>
          <span className={`${styles.fileName}`}>
            {fileName}
            <CloseIcon
              className={styles.deleteIcon}
              data-testid="users-delete-icon"
              onClick={() => handleDelete(fileName)}
            />
          </span>
        </div>
      ))}
      {errFileNames.map((fileName) => (
        <div
          key={fileName}
          className={`leading-5 border rounded border-error ${styles.errFileName} text-error`}
        >
          <span data-testid="file-input-type-docs-error-message">
            ファイルアップロードエラー
            <br />
            アップロード可能なファイルサイズは最大1GBです。
            <br />
            {fileName}
            <CloseIcon
              className={styles.deleteIcon}
              data-testid="users-delete-icon"
              onClick={() => handleErrDelete(fileName)}
            />
          </span>
        </div>
      ))}
      <input
        id={id}
        data-testid="file-input-type-docs"
        type="file"
        className="hidden"
        onChange={handleChange}
        {...rest}
      />
    </>
  );
};
