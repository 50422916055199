import React, { HTMLAttributes } from "react";

import style from "./PaginationItem.module.css";

export type PaginationItemProps = {
  selected?: boolean;
} & HTMLAttributes<HTMLElement>;

export const PaginationItem: React.FC<PaginationItemProps> = ({
  children,
  selected = false,
  ...rest
}) => {
  return (
    <div
      className={`${style.paginationItemContanier} ${
        selected ? style.selected : ""
      }`}
      data-selected={selected}
      {...rest}
    >
      {children}
    </div>
  );
};
