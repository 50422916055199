import { useCallback, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import {
  Discount,
  DiscountType,
  useUpdateDiscountMutation,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { getGraphQLEnum } from "../../../lib/utils/grapghQlEnum";
import { EditingDiscount } from "../components/DiscountEdit";
import DiscountConfirmModalUI from "../components/modals/DiscountConfirmModal";
import DiscountEditConfirmPage from "./DiscountEditConfirmPage";

const DiscountEditConfirmPageContainer: React.VFC = () => {
  const history = useHistory();
  const errorRouter = useErrorRouter();
  const { state } = useLocation<EditingDiscount>();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // 編集対象データ
  const [input] = useState<Discount>({
    ...(state as Discount),
  });

  // updateDiscount ミューテーション
  const [mutation] = useUpdateDiscountMutation({
    variables: {
      input: {
        id: input.id,
        type: getGraphQLEnum(DiscountType, input.type) as DiscountType,
        name: input.name,
        description: input.description,
        startedAt: input.startedAt,
        endedAt: input.endedAt,
        limitedCourseIds:
          state.limitedCourses && state.limitedCourses.map((c) => c.id),
      },
    },
    onError: errorRouter,
    onCompleted: (res) => {
      setModalVisibility(true);
    },
  });

  /**
   * 更新処理
   */
  const onSubmit = useCallback(async () => {
    await mutation();
  }, [mutation]);

  /**
   * 更新完了ダイアログの OK ハンドラー
   */
  const onConfirm = useCallback(() => {
    history.push(`/discounts`);
  }, [history]);

  return (
    <>
      <DiscountEditConfirmPage
        input={input}
        limitedCourses={state.limitedCourses}
        onSubmit={onSubmit}
      />
      ;
      {modalVisibility && (
        <DiscountConfirmModalUI
          title="更新完了"
          message="クーポンを更新しました。"
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default DiscountEditConfirmPageContainer;
