import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
} from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { PaginationUI } from "../../../components/Pagination";
import { CoursesFragment } from "../../../generated/graphql";
import { CoursesUI } from "./components/Courses";
import CoursesSearchInputUI from "./components/CoursesSearchInput";
import { ReactComponent as PlusIcon } from "./components/plus.svg";
import { ReactComponent as PlusBlueIcon } from "./components/plusBlue.svg";
import styles from "./Courses.module.scss";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
];

export type CoursesPageProps = {
  loading: boolean;
  limit: number;
  courses: CoursesFragment | undefined;
  searchFilter: string;
  offset: number;
  onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement>;
  onClickDeleteHandler: MouseEventHandler;
  onSearch: FormEventHandler;
  onClickPrevios: () => void;
  onClickNext: () => void;
  onClickPaginationItem: (index: number) => void;
};

const CoursesPage: React.FC<CoursesPageProps> = ({
  loading,
  limit,
  courses,
  searchFilter,
  offset,
  onChangeSearchFilterHandler,
  onClickDeleteHandler,
  onSearch,
  onClickPrevios,
  onClickNext,
  onClickPaginationItem,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const history = useHistory();
  const gotoCourseNewPage = useCallback(() => {
    history.push("/course/new");
  }, [history]);

  return (
    <div>
      <h1 className="text-2xl mb-3">コース一覧</h1>
      <hr className="border-dark-gray border mb-8"></hr>

      <div className="flex justify-between mt-10 ml-8">
        <ButtonUI
          onClick={() => gotoCourseNewPage()}
          size="ll"
          className={`inline-flex items-center w-14 justify-items-center justify-center ${styles.btnAdd}`}
        >
          <PlusBlueIcon className={`mr-2.5 w-4 h-4 ${styles.iconActive}`} />
          <PlusIcon className={`mr-2.5 w-4 h-4 ${styles.icon}`} />
          コース追加
        </ButtonUI>
      </div>
      <form className="ml-8 mt-4" action="" onSubmit={onSearch}>
        <CoursesSearchInputUI
          className="mb-9"
          value={searchFilter}
          onChange={onChangeSearchFilterHandler}
          onClickSearch={onSearch}
          onClickDelete={onClickDeleteHandler}
        ></CoursesSearchInputUI>
      </form>
      {loading ? (
        <div className=" ml-8">Loading...</div>
      ) : courses && courses!.totalCount > 0 ? (
        <div className="ml-8">
          <p
            className="tracking-wider mt-5"
            data-testid="courses-display-count"
          >{`全${courses!.totalCount}件中${offset + 1}件〜${
            offset + courses!.nodes.length
          }件を表示`}</p>
          <hr className="border my-2 mb-0"></hr>
          <CoursesUI courses={courses}></CoursesUI>
          <PaginationUI
            className="mt-20"
            selectedIndex={Math.ceil(offset / limit)}
            count={Math.ceil(courses.totalCount / limit)}
            onClickPrevios={onClickPrevios}
            onClickNext={onClickNext}
            onClickItem={onClickPaginationItem}
          ></PaginationUI>
        </div>
      ) : (
        <div className="bg-gray-200 text-center mt-5 ml-12">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-course-exists-message"
          >
            該当するコースがありません。
          </p>
          <p>検索条件に該当するコースがありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
    </div>
  );
};

export default CoursesPage;
