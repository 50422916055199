import React, { useCallback, useState } from "react";

import UploadIcon from "../UploadIcon";
import styles from "./index.module.scss";

export type UploadButtonProps = {
  id: string;
  className?: string;
  typeErrorMessage: string;
  fileTypes: string[];
  buttonName: string;
  onChangeFile: (file: File) => void;
};

const MAIN_COLOR = "#fff";
const SUB_COLOR = "#00A0E8";

const UploadButton: React.FC<UploadButtonProps> = ({
  id,
  className,
  fileTypes,
  buttonName,
  typeErrorMessage,
  onChangeFile,
}) => {
  const [mainColor, setMainColor] = useState<string>(MAIN_COLOR);
  const [typeErrFileName, setTypeErrFileName] = useState(false);

  const changeFile: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e.target.files && e.target.files[0];

      if (!file) {
        console.warn("ファイルが空です。");
        return;
      }

      // 同じファイルを選択した場合でも動作するように対応
      e.target.value = "";

      if (fileTypes.some((fileType) => fileType === file?.type)) {
        setTypeErrFileName(false);
      } else {
        setTypeErrFileName(true);
        return;
      }

      onChangeFile(file);
    },
    [fileTypes, onChangeFile]
  );
  return (
    <div
      className={`${className ?? ""}`}
      onMouseEnter={() => {
        setMainColor(SUB_COLOR);
      }}
      onMouseLeave={() => {
        setMainColor(MAIN_COLOR);
      }}
    >
      <label
        htmlFor={id}
        className={`flex items-center justify-center w-44 h-10 ${styles.label} ${className}`}
      >
        <UploadIcon className="fill-current mr-2.5" pathFill={mainColor} />
        <span>{buttonName}</span>
      </label>
      <input id={id} className={`hidden`} type="file" onChange={changeFile} />
      {typeErrFileName && (
        <p
          className="text-error mt-1.5"
          data-testid={`file-input-type-error-filename-error-message`}
        >
          {typeErrorMessage}
        </p>
      )}
    </div>
  );
};

export default UploadButton;
