import React, { useCallback, useState } from "react";

import { ButtonUI } from "../../../../components/Button";
import { CompletedModalUI } from "../../../../components/CompletedModal";
import { ConfirmModalUI } from "../../../../components/ConfirmModal";
import { ErrorModalUI } from "../../../../components/ErrorModal";
import { ModalUI } from "../../../../components/Modal";
import { useDeleteCourseMutation } from "../../../../generated/graphql";
import { useErrorRouter } from "../../../../hooks/errorRouter";

export type CourseDeleteButtonProps = {
  courseId: string;
  onCompleteDelete: () => void;
};

const CourseDeleteButton: React.VFC<CourseDeleteButtonProps> = ({
  courseId,
  onCompleteDelete,
}) => {
  const commonErrorRouter = useErrorRouter();

  const [disabled, setDisabled] = useState(false);
  const [openedModal, setOpenedModal] = useState<
    | "confirm"
    | "proceeding"
    | "succeed"
    | "error_published"
    | "error_hasOrder"
    | undefined
  >(undefined);

  const [deleteCourse] = useDeleteCourseMutation({
    onCompleted: () => {
      setOpenedModal("succeed");
    },
    onError: (error) => {
      if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].extensions
      ) {
        const { code } = error.graphQLErrors[0].extensions;
        if (code === "DELETE_CLAZZ_AND_LESSON_PUBLISHED") {
          setOpenedModal("error_published");
          return;
        }
        if (code === "DELETE_CLAZZ_AND_LESSON_HAS_ORDER") {
          setOpenedModal("error_hasOrder");
          return;
        }
      }

      commonErrorRouter(error);
    },
  });

  // 確認モーダルを表示
  const showConfirm = useCallback(async () => {
    setOpenedModal("confirm");
  }, []);

  // 確認モーダルで「はい」ボタンが押下された際
  const onSubmitConfirm = useCallback(async () => {
    setOpenedModal("proceeding");
    setDisabled(true);

    await deleteCourse({
      variables: {
        input: {
          courseId,
        },
      },
    });

    setDisabled(false);
  }, [deleteCourse, courseId]);

  // 各種モーダルを閉じる
  const closeModal = useCallback(() => {
    setOpenedModal(undefined);
  }, []);

  return (
    <>
      <ButtonUI
        data-testid="course-delete-btn"
        buttonType="danger"
        onClick={showConfirm}
        disabled={disabled}
      >
        削除
      </ButtonUI>
      <ConfirmModal
        visibility={openedModal === "confirm"}
        onSubmit={onSubmitConfirm}
        onCancel={closeModal}
      />
      <ProceedingModal visibility={openedModal === "proceeding"} />
      <SucceedModal
        visibility={openedModal === "succeed"}
        onClickButton={onCompleteDelete}
      />
      <PublishedErrorModal
        visibility={openedModal === "error_published"}
        onClickButton={closeModal}
      />
      <HasOrderErrorModal
        visibility={openedModal === "error_hasOrder"}
        onClickButton={closeModal}
      />
    </>
  );
};

const ConfirmModal: React.VFC<{
  visibility: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({ visibility, onSubmit, onCancel }) => {
  return (
    <ConfirmModalUI
      visibility={visibility}
      title="コース削除"
      description="削除します。よろしいですか？"
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

const ProceedingModal: React.VFC<{
  visibility: boolean;
}> = ({ visibility }) => {
  return (
    <ModalUI visibility={visibility}>
      <div
        data-testid="course-delete-proceeding-modal"
        className="px-5 py-5 text-center"
      >
        削除中・・・
      </div>
    </ModalUI>
  );
};

const SucceedModal: React.VFC<{
  visibility: boolean;
  onClickButton: () => void;
}> = ({ visibility, onClickButton }) => {
  return (
    <CompletedModalUI
      visibility={visibility}
      title="削除完了"
      description="削除が完了しました。"
      btnTitle="前の画面へ戻る"
      onClick={onClickButton}
    />
  );
};

const PublishedErrorModal: React.VFC<{
  visibility: boolean;
  onClickButton: () => void;
}> = ({ visibility, onClickButton }) => {
  return (
    <ErrorModalUI
      visibility={visibility}
      title="削除できませんでした"
      btnTitle="閉じる"
      onClick={onClickButton}
    >
      <div data-testid="course-delete-error-published-modal">
        公開中のクラスがあるため、削除できません
      </div>
    </ErrorModalUI>
  );
};

const HasOrderErrorModal: React.VFC<{
  visibility: boolean;
  onClickButton: () => void;
}> = ({ visibility, onClickButton }) => {
  return (
    <ErrorModalUI
      visibility={visibility}
      title="削除できませんでした"
      btnTitle="閉じる"
      onClick={onClickButton}
    >
      <div data-testid="course-delete-error-has-order-modal">
        申込した受講生が居るため、削除できません
      </div>
    </ErrorModalUI>
  );
};

export default CourseDeleteButton;
