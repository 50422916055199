import React, {
  MouseEventHandler,
  useCallback,
  useState,
  useMemo,
} from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import DownloadCsv, { CsvLinkProps } from "../../../components/DownloadCsv";
import { LoadingUI } from "../../../components/Loading";
import {
  ContactsFragment,
  useContactsQuery,
  useContactCsvDownloadsLazyQuery,
  ContactCsvDownloadsQuery,
  ContactStatus,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import {
  contactsSearchSettings,
  contactsSearchSettingsForAllData as csvSetting,
} from "../../../lib/constants";
import { getRouteName, getStatusName } from "../../../lib/utils/contacts";
import ContactsPage from "./ContactsPage";

dayjs.extend(utc);
dayjs.extend(tz);

export type CsvData = {
  status: string;
  contactId: string;
  customerName: string;
  memberId: string;
  category: string;
  route: string;
  registeredDate: string;
  supporterName: string;
  updatedDate: string;
  content: string;
};

export const headers = [
  { label: "対応状況", key: "status" },
  { label: "お問い合わせ番号", key: "contactId" },
  { label: "氏名", key: "customerName" },
  { label: "会員ID", key: "memberId" },
  { label: "お問い合わせ種別", key: "category" },
  { label: "お問い合わせ経路", key: "route" },
  { label: "登録日", key: "registeredDate" },
  { label: "最終更新者", key: "supporterName" },
  { label: "最終更新日", key: "updatedDate" },
  { label: "お問い合わせ内容", key: "content" },
];

const ContactsPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();

  const [limit, setLimit] = useState(contactsSearchSettings.limit);
  const [contacts, setContacts] = useState<ContactsFragment>({
    totalCount: 0,
    nodes: [],
  });
  const [csvData, setCsvData] = useState<CsvData[]>();

  const { data, loading, refetch } = useContactsQuery({
    onCompleted: (data) => {
      data.contactsForDisplay && setContacts(data.contactsForDisplay);
    },
    onError: errorRouter,
    variables: {
      limit,
    },
    fetchPolicy: "no-cache",
  });

  const [fetchCSVData, { loading: loadingCSVQuery }] =
    useContactCsvDownloadsLazyQuery({
      onCompleted: (data) => {
        const replacedData = replaceToCsvData(data);
        setCsvData(replacedData);
      },
      onError: errorRouter,
      variables: {
        limit: csvSetting.limit,
      },
      fetchPolicy: "no-cache",
    });

  /**
   * graphql → CSV用のオブジェクトへ変換
   */
  const replaceToCsvData = useCallback(
    (queryData: ContactCsvDownloadsQuery) => {
      return queryData.contacts.nodes.map((contact): CsvData => {
        const customerName = contact.customer
          ? `${contact.customer.profile?.familyName} ${contact.customer.profile?.givenName}`
          : `${contact.address?.familyName ? contact.address.familyName : ""} ${
              contact.address?.givenName ? contact.address.givenName : ""
            }`;

        const latestHistory =
          contact.histories && contact.histories[0]
            ? contact.histories[0]
            : null;
        const supporterName = latestHistory
          ? `${latestHistory.supporterFamilyName} ${latestHistory.supporterGivenName}`
          : "";
        return {
          status: latestHistory
            ? getStatusName(latestHistory.status)
            : getStatusName(ContactStatus.Unsupported),
          contactId: contact.systemId,
          customerName: customerName,
          memberId: contact.customer?.profile
            ? contact.customer.profile.memberId!
            : "",
          category: contact.category?.value ? contact.category.value : "",
          route: getRouteName(contact.route!),
          registeredDate: dayjs(contact.createdAt).format("YYYY/MM/DD"),
          supporterName: supporterName,
          updatedDate: latestHistory
            ? dayjs(latestHistory?.createdAt).format("YYYY/MM/DD")
            : "",
          content: contact.content!,
        };
      });
    },
    []
  );

  const csvLinkProps: CsvLinkProps | undefined = useMemo(() => {
    // validate
    if (loadingCSVQuery || !csvData) {
      return undefined;
    }

    return {
      filename: `お問い合わせ一覧_${dayjs().format("YYYYMMDD")}.csv`,
      headers: headers,
      data: csvData,
    };
  }, [csvData, loadingCSVQuery]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + contactsSearchSettings.offset);
    await refetch({
      limit: limit + contactsSearchSettings.offset,
    });
  }, [limit, setLimit, refetch]);

  return loading && contacts.totalCount === 0 ? (
    <LoadingUI title="検索中" />
  ) : (
    <>
      <ContactsPage
        contacts={contacts}
        hasAdminAuthority={data?.hasAdminAuthority || false}
        onDisplayMore={onDisplayMore}
        onClickDownload={fetchCSVData}
      />
      <DownloadCsv csvLinkProps={csvLinkProps} />
    </>
  );
};
export default ContactsPageContainer;
