import React, { useEffect, useRef, useState } from "react";

import { PaymentMismatchDetailFragment } from "../../../../generated/graphql";
import PaymentTableRowUI from "./PaymentTableRow";

export type PaymentTableUIProps = {
  payment: PaymentMismatchDetailFragment["payment"];
  onWidthChange?: (width: number | undefined) => void;
};

export const PaymentTableUI: React.FC<PaymentTableUIProps> = ({
  payment,
  onWidthChange,
}) => {
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);
  const table = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (table && onWidthChange) {
      const rect: DOMRect | undefined = table.current?.getBoundingClientRect();
      const w = rect !== undefined ? Math.ceil(rect.width) : undefined;
      if (tableWidth !== w) {
        setTableWidth(w);
        onWidthChange(w);
      }
    }
  }, [table, onWidthChange, setTableWidth, tableWidth]);

  return (
    <table
      ref={table}
      className="border-collapse border border-dark-gray"
      style={{ width: "max-content" }}
    >
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-12">
          <th className="border border-dark-gray pl-2 pr-1">決済情報番号</th>
          <th className="border border-dark-gray pl-2 pr-1">申込番号</th>
          <th className="border border-dark-gray pl-2 pr-1">会員ID</th>
          <th className="border border-dark-gray pl-2 pr-1">氏名</th>
          <th className="border border-dark-gray pl-2 pr-1">フリガナ</th>
          <th className="border border-dark-gray pl-2 pr-1">決済商品</th>
          <th className="border border-dark-gray pl-2 pr-1">課金種別</th>
          <th className="border border-dark-gray pl-2 pr-1">売上金額</th>
          <th className="border border-dark-gray pl-2 pr-1">決済ステータス</th>
          <th className="border border-dark-gray pl-2 pr-1">申込年月日</th>
          <th className="border border-dark-gray pl-2 pr-1">決済年月日</th>
          <th className="border border-dark-gray pl-2 pr-1">
            サービス/商品提供年月日
          </th>
          <th className="border border-dark-gray pl-2 pr-1">最終処理年月日</th>
        </tr>
      </thead>
      <tbody>
        <PaymentTableRowUI
          data-testid="payment-mismatch-payment-table"
          payment={payment}
        ></PaymentTableRowUI>
      </tbody>
    </table>
  );
};
