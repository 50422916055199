import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { LabelUI } from "../../../../components/Label";
import LinkTextUI from "../../../../components/LinkText/LinkText";
import { TextUI } from "../../../../components/Text";
import { ClazzDetailFragment } from "../../../../generated/graphql";
import {
  eventLocations,
  eventLocationType,
  Teacher,
} from "../../../../lib/constants/classes";
dayjs.locale(ja);

export interface ClassDetailUIProps {
  clazz: ClazzDetailFragment;
}

export const ClassDetailUI: React.VFC<ClassDetailUIProps> = ({ clazz }) => {
  const [participantsCount, setParticipantsCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);
  const getScheduledDate = (startedAt: Date, endedAt: Date) => {
    return (
      startedAt &&
      endedAt &&
      `${dayjs(startedAt).format("YYYY/MM/DD(dd) HH:mm")} ~ ${dayjs(
        endedAt
      ).format("HH:mm")}`
    );
  };

  useEffect(() => {
    let particiCount = 0;
    if (clazz?.lessons) {
      particiCount += Number(clazz.lessons[0].participantsCount);
    }
    setParticipantsCount(particiCount);

    setCanceledCount(Number(clazz.canceledCount));
  }, [clazz, clazz.lessons]);

  return (
    <div>
      <div className={`pt-6 pb-8 space-y-2`}>
        <LabelUI className="text-lg">システムID</LabelUI>
        <TextUI data-testid="clazz-system-id" className="pl-3">
          {clazz.systemId}
        </TextUI>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コース名</LabelUI>
        </div>
        <div className="flex pl-3">
          <LinkTextUI
            to={`/courses/${clazz.courseId}`}
            data-testid="clazz-course-name"
          >
            {clazz.course?.name}
          </LinkTextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">クラス名</LabelUI>
        </div>
        <div className="flex pl-3">
          <TextUI data-testid="clazz-name">{clazz.name}</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">クラスID</LabelUI>
        </div>
        <div className="flex pl-3">
          <TextUI data-testid="clazz-any-clazz-id">{clazz.anyClassId}</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">開催場所</LabelUI>
        </div>
        <div data-testid="clazz-event-location" className="flex pl-3">
          {clazz.eventLocation}
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">定員</LabelUI>
        </div>
        <div data-testid="clazz-capacity" className="flex pl-3">
          <TextUI>{clazz.capacity}</TextUI>
          <TextUI>名</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">申込者数表示</LabelUI>
        </div>
        <div
          data-testid="clazz-applicant-count-visibility"
          className="flex pl-3"
        >
          <TextUI>{clazz.applicantCountVisibility ? "表示" : "非表示"}</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">予約人数</LabelUI>
        </div>
        <div data-testid="clazz-participants" className="flex pl-3">
          <TextUI>{participantsCount}</TextUI>
          <TextUI>名</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">キャンセル人数</LabelUI>
        </div>
        <div data-testid="clazz-canceled-count" className="flex pl-3">
          <TextUI>{canceledCount}</TextUI>
          <TextUI>名</TextUI>
        </div>
      </div>
      <hr />
      <div className={`pt-6 pb-8 space-y-2`}>
        <h2 className="text-subtitle text-xl">授業設定</h2>
        <table className="w-full border-collapse border border-dark-gray text-center">
          <thead className="border border-dark-gray bg-subtitle text-white">
            <tr className="h-16" data-testid="class-detail-table-head">
              <th className="border border-dark-gray w-1/12">授業回</th>
              <th className="border border-dark-gray w-2/12">授業ID</th>
              <th className="border border-dark-gray w-3/12">授業名</th>
              <th className="border border-dark-gray w-3/12">開催日時</th>
              <th className="border border-dark-gray w-1/12">教師</th>
              {clazz?.eventLocation ===
                eventLocations[eventLocationType.Zoom] && (
                <th className="border border-dark-gray w-2/12">授業URL</th>
              )}
            </tr>
          </thead>
          <tbody>
            {clazz?.lessons?.map((lesson: any, lessonIndex: number) => (
              <tr
                key={lessonIndex}
                data-testid="class-detail-table-row"
                className={`h-16 ${
                  lessonIndex % 2 !== 0 ? "bg-light-blue" : ""
                }`}
              >
                <td className="border-r border-dark-gray px-1 text-subtitle">
                  第{lesson.lessonMaster.order}回
                </td>
                <td className="border-r border-dark-gray px-1">
                  <LinkTextUI to={`/lessons/${lesson.id}`}>
                    {lesson.anyLessonId}
                  </LinkTextUI>
                </td>
                <td className="border-r border-dark-gray px-1">
                  {lesson.lessonMaster.name}
                </td>
                <td className="border-r border-dark-gray px-1">
                  {getScheduledDate(
                    lesson.startedAt || dayjs().toDate(),
                    lesson.endedAt || dayjs().toDate()
                  )}
                </td>
                <td className="border-r border-dark-gray px-1">
                  {lesson.teachers.map(
                    (teacher: Teacher, teacherIndex: number) => (
                      <div className="mt-2.5" key={teacherIndex}>
                        <TextUI
                          data-testid={`lesson-${lessonIndex}-teacher-name-${teacherIndex}`}
                        >
                          {teacher.familyName} {teacher.givenName}
                        </TextUI>
                      </div>
                    )
                  )}
                </td>
                {clazz?.eventLocation ===
                  eventLocations[eventLocationType.Zoom] && (
                  <td className="border-r border-dark-gray px-1">
                    {lesson.lessonUrl}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  );
};
