import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { MismatchHandlingHistoryFragment } from "../../../../generated/graphql";
dayjs.locale(ja);

export type HandlingHistoryTableRowProps = {
  handlingHistory: MismatchHandlingHistoryFragment;
};

const HandlingHistoryTableRowUI: React.FC<HandlingHistoryTableRowProps> = ({
  handlingHistory,
}) => {
  return (
    <div
      className="bg-light-blue p-3 border-b border-under-line"
      data-testid="payment-mismatch-handling-histories-table-row"
    >
      <div className="grid grid-cols-4 divide-x-2 divide-under-line">
        <div className="col-span-1">
          <div>
            <p
              className="font-bold mb-1"
              data-testid="payment-mismatch-handling-hisotry-handler"
            >
              {`${handlingHistory.handler?.familyName} ${handlingHistory.handler?.givenName}`}
            </p>
            <p
              className="text-sm"
              data-testid="payment-mismatch-handling-hisotry-user-id"
            >
              {handlingHistory.handler?.userId}
            </p>
          </div>
        </div>
        <div className="col-span-3">
          <div className="pl-2">
            <p
              className="text-base mb-1"
              style={{ whiteSpace: "pre-line" }}
              data-testid="payment-mismatch-handling-hisotry-note"
            >
              {handlingHistory.note}
            </p>
            <p
              className="text-dark-gray text-right text-sm"
              data-testid="payment-mismatch-handling-hisotry-date"
            >
              {dayjs(handlingHistory.updatedAt).format("YYYY/MM/DD HH:mm")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandlingHistoryTableRowUI;
