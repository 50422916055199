import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useHistory } from "react-router";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { DownloadButtonUI } from "../../../components/DownloadButton";
import { FormGroupUI } from "../../../components/FormGroup";
import { LabelUI } from "../../../components/Label";
import LinkTextUI from "../../../components/LinkText/LinkText";
import { TextUI } from "../../../components/Text";
import {
  Maybe,
  PaymentCourseClazzFragment,
  PaymentDetailFragment,
  PaymentType,
} from "../../../generated/graphql";
import {
  gmoFirstPaymentStatuses,
  GmoFirstPaymentStatusLabel,
} from "../../../lib/constants/GmoFirstPaymentStatus";
import {
  gmoRecurringPaymentStatuses,
  GmoRecurringPaymentStatusLabel,
} from "../../../lib/constants/GmoRecurringPaymentStatus";
import {
  paymentStatuses,
  PaymentStatusLable,
} from "../../../lib/constants/PaymentStatus";
import { fileSave } from "../../../lib/utils/fileUtils";
import { getPaiedProductName } from "../PaymentsSearchResultsPage/components/PaymentsTableRow";
import { CancelAuthorizedCourseButton } from "./components/CancelCourse/CancelAuthorizedCourseButton";
import { CancelSoldCourseButton } from "./components/CancelCourse/CancelSoldCourseButton";

dayjs.extend(utc);
dayjs.extend(timezone);
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済情報",
  },
];

export type PaymentPageProps = {
  payment: PaymentDetailFragment;
  clazz?: PaymentCourseClazzFragment;
  refetchPayment: () => void;
};

const PaymentPage: React.FC<PaymentPageProps> = ({
  payment,
  clazz,
  refetchPayment,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();

  const [productProvisionDate, setProductProvisionDate] = useState<Date | null>(
    !payment.clazzId ? payment!.submittedAt! : null
  );

  const receipt = payment.receipt;

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  if (clazz && clazz.lessons && !productProvisionDate) {
    // クラスの初回レッスンの開催日を取得する
    let startDateImes: Date[] = clazz.lessons.map(
      (lesson) => new Date(lesson.startedAt)
    );
    startDateImes = startDateImes.sort((a: Date, b: Date) => {
      return a.getTime() - b.getTime();
    });
    setProductProvisionDate(startDateImes[0]);
  }

  const getPaymentStatusJA = (paymentStatus: Maybe<number> | undefined) => {
    if (!paymentStatus) {
      return "";
    }
    const status: PaymentStatusLable | undefined = paymentStatuses.find(
      (item: PaymentStatusLable) => item.value === paymentStatus
    );
    return status && status.label;
  };

  const getGmoFirstPaymentStatusJA = (
    gmoFirstPaymentStatus: Maybe<string> | undefined
  ) => {
    if (!gmoFirstPaymentStatus) {
      return "";
    }
    const status: GmoFirstPaymentStatusLabel | undefined =
      gmoFirstPaymentStatuses.find(
        (item: GmoFirstPaymentStatusLabel) =>
          item.value === gmoFirstPaymentStatus
      );
    return status && status.label;
  };

  const getGmoRecurringPaymentStatusJA = (
    gmoRecurringPaymentStatus: Maybe<string> | undefined
  ) => {
    if (!gmoRecurringPaymentStatus) {
      return "";
    }
    const status: GmoRecurringPaymentStatusLabel | undefined =
      gmoRecurringPaymentStatuses.find(
        (item: GmoRecurringPaymentStatusLabel) =>
          item.value === gmoRecurringPaymentStatus
      );
    return status && status.label;
  };

  const getPaymentTypeJA = (paymentType: Maybe<String> | undefined) => {
    switch (paymentType) {
      case PaymentType.PayCert:
        return "従量課金";
      case PaymentType.ContBill:
        return "継続課金";
      case PaymentType.ContBillFirst:
        return "初月課金";
      case PaymentType.ContBillNext:
        return "継続課金";
      default:
        return "";
    }
  };

  const onClickDownloadReceipt = (url: string) => {
    fileSave(url, `領収書_${dayjs().tz("Asia/Tokyo").format("YYYYMMDD")}.pdf`);
  };

  return (
    <div>
      <h1 className="text-2xl mb-3">決済情報</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-5 mb-36">
        <FormGroupUI>
          <LabelUI className="text-lg">決済番号</LabelUI>
          <TextUI data-testid="payment-payInfoNo">
            {payment.payInfoNo ? payment.payInfoNo : payment.id}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">申込番号</LabelUI>
          <TextUI data-testid="payment-orderid">{payment.orderId}</TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">会員ID</LabelUI>
          <LinkTextUI
            to={`/customers/${payment.customerId}`}
            data-testid="payment-memberid"
          >
            {payment.memberId}
          </LinkTextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">氏名</LabelUI>
          <TextUI data-testid="payment-customer-name">
            {payment.customerName}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">フリガナ</LabelUI>
          <TextUI data-testid="payment-customer-name-kana">
            {payment.customerNameKana}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">決済商品</LabelUI>
          <TextUI data-testid="payment-product">
            {getPaiedProductName(payment)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">課金種別</LabelUI>
          <TextUI data-testid="payment-type">
            {getPaymentTypeJA(payment.typename)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">売上金額(税込)</LabelUI>
          <TextUI data-testid="payment-price">
            {payment!.priceValue &&
              Intl.NumberFormat("ja").format(payment!.priceValue)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">売上金額(税抜)</LabelUI>
          <TextUI data-testid="payment-price-no-tax">
            {payment!.priceValueNoTax &&
              Intl.NumberFormat("ja").format(payment!.priceValueNoTax)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">決済ステータス</LabelUI>
          <TextUI data-testid="payment-status">
            {getPaymentStatusJA(payment.paymentStatus)}
            {getGmoFirstPaymentStatusJA(payment.gmoFirstPaymentStatus)}
            {getGmoRecurringPaymentStatusJA(payment.gmoRecurringPaymentStatus)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">申込年月日（オーソリ日）</LabelUI>
          <TextUI data-testid="payment-submittedAt">
            {payment.submittedAt &&
              dayjs(payment.submittedAt).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">決済年月日（売上確定日）</LabelUI>
          <TextUI data-testid="payment-soldDatetime">
            {payment.soldDateTime &&
              dayjs(payment.soldDateTime).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />

        <FormGroupUI>
          <LabelUI className="text-lg">サービス/商品提供年月日</LabelUI>
          <TextUI data-testid="payment-product-provision-date">
            {productProvisionDate &&
              dayjs(productProvisionDate).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />

        <FormGroupUI>
          <LabelUI className="text-lg">領収書ダウンロード年月日</LabelUI>
          {receipt &&
            [...Array(2)].map((_, i) => {
              const isDownloaded = !!receipt.history[i];
              return (
                <TextUI
                  className={isDownloaded ? "" : "text-dark-gray"}
                  data-testid={`payment-receipt-date-${i + 1}`}
                  key={i}
                >
                  {i + 1}回目：
                  {isDownloaded ? (
                    <>
                      {dayjs(receipt.history[i].issuedAt)
                        .tz("Asia/Tokyo")
                        .format("YYYY/MM/DD")}
                      <DownloadButtonUI
                        onClickDownload={() =>
                          onClickDownloadReceipt(receipt.history[i].url)
                        }
                        data-testid={`download-btn-${i + 1}`}
                      />
                    </>
                  ) : (
                    "-"
                  )}
                </TextUI>
              );
            })}
        </FormGroupUI>
        <hr className="border-under-line" />

        <FormGroupUI>
          <LabelUI className="text-lg">領収書ダウンロード期限</LabelUI>
          <TextUI data-testid="payment-receipt-expiredAt">
            {receipt && dayjs(receipt.expiredAt).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />

        <FormGroupUI>
          {/* データベースのレコードの更新日時（注意： processDateTime と混同しない） */}
          <LabelUI className="text-lg">最終処理年月日</LabelUI>
          <TextUI data-testid="payment-last-process-date">
            {payment.updatedAt && dayjs(payment.updatedAt).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>

        <div className="flex justify-center space-x-10">
          <ButtonUI
            data-testid="payment-goback"
            buttonType="secondary"
            size="s"
            className="mt-20"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
          <CancelSoldCourseButton
            payment={payment}
            refetchPayment={refetchPayment}
          />
          <CancelAuthorizedCourseButton
            payment={payment}
            refetchPayment={refetchPayment}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
