export type DisplayRole = {
  id: string;
  roleId: string;
  label: string;
};

export const roles: DisplayRole[] = [
  { id: "systemAdministrator", roleId: "1", label: "システム管理者" },
  { id: "pfAppManager", roleId: "2", label: "PF運用責任者" },
  { id: "manager", roleId: "3", label: "管理者" },
  { id: "staff", roleId: "4", label: "スタッフ" },
  { id: "csStaff", roleId: "5", label: "CSスタッフ" },
  { id: "teacher", roleId: "6", label: "教師" },
  { id: "operationMonitor", roleId: "7", label: "運用監視者" },
];
