import React, { ButtonHTMLAttributes, MouseEventHandler } from "react";

import { ButtonUI } from "../Button";
import { ReactComponent as DownloadIcon } from "./download.svg";

export type DownloadButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClickDownload: MouseEventHandler<HTMLButtonElement>;
};

export const DownloadButtonUI: React.VFC<DownloadButtonProps> = ({
  onClickDownload,
  ...rest
}) => {
  return (
    <ButtonUI
      buttonType="secondary"
      size="custom"
      base="rounded15"
      className="border border-blue bg-white text-blue hover:bg-blue hover:text-white ml-4 inline-flex items-center justify-center w-36 h-5"
      onClick={onClickDownload}
      {...rest}
    >
      <DownloadIcon className="fill-current mr-2.5 w-3 h-3" />
      ダウンロード
    </ButtonUI>
  );
};
