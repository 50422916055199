import React, { MouseEventHandler, useEffect } from "react";

import { SubmitHandler } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ContactDetailUI } from "../../../components/ContactDetail";
import { SelectOption } from "../../../components/SelectCustomStyle";
import {
  ContactPageFragment,
  CreateContactHistoryInput,
  ContactHistory,
  ContactStatus,
} from "../../../generated/graphql";
import { ContactHistoryTableUI } from "./components/ContactHistoryTable";
import {
  ContactStatusUI,
  ChangeContactHistoryHandler,
} from "./components/ContactStatus";
import style from "./Trash.module.scss";
import { ReactComponent as TrashIcon } from "./trash.svg";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "お問い合わせ対応",
  },
  {
    label: "お問い合わせ詳細",
  },
];

export type ContactPageProps = {
  contact: ContactPageFragment;
  contactHistories: ContactHistory[];
  contactHistoryInput: CreateContactHistoryInput;
  contactServiceKinds: SelectOption[];
  onChangeContactHistory: ChangeContactHistoryHandler;
  onCreateContactHistory: SubmitHandler<CreateContactHistoryInput>;
  handleDelete: MouseEventHandler<HTMLButtonElement>;
};

const ContactPage: React.FC<ContactPageProps> = ({
  contact,
  contactHistories,
  contactHistoryInput,
  contactServiceKinds,
  onChangeContactHistory,
  onCreateContactHistory,
  handleDelete,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const contactStatus: ContactStatus =
    contact.histories && contact.histories[0] && contact.histories[0].status
      ? contact.histories[0].status
      : ContactStatus.Unsupported;

  return (
    <div>
      <h1 className="text-2xl mb-3">お問い合わせ詳細</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-5">
        <ContactDetailUI contact={contact}></ContactDetailUI>
        <h2 className="text-xl mt-6 mb-2">対応履歴</h2>
        {contactHistories && 1 <= contactHistories.length ? (
          <ContactHistoryTableUI
            contactHistories={contactHistories}
          ></ContactHistoryTableUI>
        ) : (
          <div className="bg-light-blue flex h-48">
            <div className="m-auto">
              <p
                className="text-lg"
                data-testid="contact-page-no-contact-history"
              >
                現在、対応履歴はありません。
              </p>
            </div>
          </div>
        )}
        <div className="mt-6">
          <ContactStatusUI
            contactStatus={contactStatus}
            contactHistoryInput={contactHistoryInput}
            contactServiceKinds={contactServiceKinds}
            onChangeContactHistory={onChangeContactHistory}
            onHandleSubmit={onCreateContactHistory}
          ></ContactStatusUI>
        </div>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="contact-page-delete-btn"
            buttonType="secondary"
            size="custom"
            className={`${style.trashContanier} mr-2.5 inline-flex items-center justify-center w-60 h-10`}
            onClick={handleDelete}
          >
            <TrashIcon className={`${style.trashSvg} mr-2.5 w-5 h-5`} />
            お問い合わせ情報の削除
          </ButtonUI>
        </div>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="contact-page-back-btn"
            buttonType="secondary"
            to={"/contacts"}
          >
            戻る
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
