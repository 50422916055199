import React, { ReactNode } from "react";
import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import { ConfirmErrorModalUI } from "../../components/ConfirmErrorModal";
import { useMeQuery } from "../../generated/graphql";
import { ErrorType } from "../../lib/constants/error";
import { AppInitWaiter } from "../AppInitWaiter";
import { BreadcrumbUI, BreadcrumbContextProvider } from "../Breadcrumb";
import { HeaderUI } from "../Header";
import { SideBarUI } from "../SideBar";

type Props = {
  children?: ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { data, error, loading } = useMeQuery();
  const history = useHistory();
  const [isOpenLogoutDiscard, setIsOpenLogoutDiscard] =
    useState<boolean>(false);

  const errCode = error && error.graphQLErrors[0]?.extensions?.code;
  const isMaintenanceNow =
    error && error.graphQLErrors[0]?.extensions?.isMaintenanceNow;

  if (!loading && !data) {
    errCode !== ErrorType.UnAuthenticated &&
      !isMaintenanceNow &&
      !isOpenLogoutDiscard &&
      setIsOpenLogoutDiscard(true);
  }

  useEffect(() => {
    if (data && !data.me.useableServiceKinds.length) {
      history.push("/error/serviceunauthorized");
    }
  }, [data, history]);

  useEffect(() => {
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    }
  }, [errCode, error, history]);

  return (
    <div className="flex flex-col h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <HeaderUI me={data?.me}></HeaderUI>
      <AppInitWaiter>
        <div className="flex-grow flex overflow-y-hidden">
          <SideBarUI className="flex-none"></SideBarUI>
          <div className="flex-grow px-6 pt-6 overflow-y-scroll">
            <BreadcrumbContextProvider>
              <BreadcrumbUI className="mb-4" />
              {children}
            </BreadcrumbContextProvider>
          </div>
        </div>
      </AppInitWaiter>
      <ConfirmErrorModalUI
        title="認証情報の取得に失敗しました"
        description="ログインユーザの認証情報に失敗しました。<br />再ログインをお願いいたします。"
        btnTitle="OK"
        onClick={() => {
          Auth.signOut();
        }}
        visibility={isOpenLogoutDiscard}
      />
    </div>
  );
};
