import React, { HTMLAttributes } from "react";

import { CoursePrice, Price, PriceType } from "../../generated/graphql";

type PriceUIProps = {
  price: Price | CoursePrice;
  className?: string;
} & HTMLAttributes<HTMLElement>;

export const PriceUI: React.FC<PriceUIProps> = ({
  price,
  className,
  ...rest
}) => (
  <div className={`flex items-center ${className}`} {...rest}>
    {price.type === PriceType.Monthly && "月額課金"}
    {price.type === PriceType.Once && "従量課金"}
    {price.type === PriceType.Free && "無料"}
    {price.type === PriceType.Monthly && (
      <span className="flex-grow ml-4">
        {/* TODO: コンテンツとコースで同じコンポーネントを使っていて、担当者が違うので一旦どっちでも通るように修正 */}
        {/* TODO: 後発の人はNoTaxを使うように修正しておいてください。 */}
        {`　初回金額（税抜）： ${
          price.initialValueNoTax?.toLocaleString("ja-JP") !== undefined
            ? price.initialValueNoTax?.toLocaleString("ja-JP")
            : price.initialValue?.toLocaleString("ja-JP")
        } 円`}{" "}
        {`　　月次以降金額（税抜）： ${
          price.valueNoTax !== undefined
            ? price.valueNoTax?.toLocaleString("ja-JP")
            : price.value?.toLocaleString("ja-JP")
        } 円`}
      </span>
    )}
    {price.type === PriceType.Once && (
      <span className="flex-grow ml-4">
        {`　金額（税抜）：　${price.valueNoTax?.toLocaleString("ja-JP")} 円`}
      </span>
    )}
  </div>
);
