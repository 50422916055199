import React, { HTMLAttributes } from "react";

import { useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../../components/Badge";
import { LIBERAL_ARTS_URL } from "../../../const";
import { FeaturedVideosInput } from "../../FeaturedVideosEditPage";
import { FeaturedVideoInputUI } from "../FeaturedVideoInput";
import { ImageAttr, ImageInputUI } from "../ImageInput";

export type FeaturedVideoEditUIProps = HTMLAttributes<HTMLDivElement> & {
  index: number;
  image?: ImageAttr;
};

export const FeaturedVideoEditUI: React.VFC<FeaturedVideoEditUIProps> = ({
  index,
  image,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FeaturedVideosInput>();

  return (
    <div {...rest}>
      <p className="font-bold text-1.5xl" data-testid="featured-video-index">
        {index + 1}枚目
      </p>
      <hr className="mt-2 border-b-1 border-under-line" />
      <div className="mt-4 divide-y space-y-5 divide-under-line">
        <div>
          <div className="flex flex-row items-center">
            <label className="text-lg mr-2.5 font-bold">カード画像</label>
            <BadgeUI>必須</BadgeUI>
          </div>
          <div className="mt-2.5">
            <ImageInputUI name={`featuredVideos.${index}.file`} image={image} />
          </div>
          {!!errors.featuredVideos &&
            !!errors.featuredVideos[index]?.file?.message && (
              <p className="mt-2.5 text-error">
                {errors.featuredVideos[index]?.file?.message}
              </p>
            )}
        </div>

        <div className="pt-5">
          <div className="flex flex-row items-center">
            <label
              className="text-lg mr-2.5 font-bold"
              htmlFor={`featuredVideos.${index}.alias`}
            >
              遷移先URL
            </label>
            <BadgeUI>必須</BadgeUI>
          </div>
          <div className="mt-2.5">
            <div className="flex flex-row items-center">
              <p className="flex-none">{LIBERAL_ARTS_URL}</p>
              <FeaturedVideoInputUI
                id={`featuredVideos.${index}.alias`}
                className="ml-2.5 w-80"
                placeholder="エイリアスを95文字以内で入力してください"
                {...register(`featuredVideos.${index}.alias`, {
                  required: "URLは必須項目です。",
                  validate: (value) => {
                    if (
                      value.length > 95 ||
                      !value.match(/^[A-Za-z0-9-_/]*$/)
                    ) {
                      return "95文字以内の-, _,/を含む半角英数字を入力してください。";
                    }
                  },
                })}
                error={
                  !!errors.featuredVideos &&
                  !!errors.featuredVideos[index]?.alias
                }
              />
            </div>

            {!!errors.featuredVideos &&
              !!errors.featuredVideos[index]?.alias?.message && (
                <p className="mt-2.5 text-error">
                  {errors.featuredVideos[index]?.alias?.message}
                </p>
              )}
          </div>
        </div>

        <div className="pt-5">
          <div className="flex flex-row items-center">
            <label
              className="text-lg mr-2.5 font-bold"
              htmlFor={`featuredVideos.${index}.name`}
            >
              イベントアクション名
            </label>
            <BadgeUI>必須</BadgeUI>
          </div>
          <div className="mt-2.5">
            <FeaturedVideoInputUI
              className="w-full"
              id={`featuredVideos.${index}.name`}
              placeholder="イベントアクション名を40文字以内で入力してください"
              {...register(`featuredVideos.${index}.name`, {
                required: "イベントアクション名は必須項目です。",
                maxLength: {
                  value: 40,
                  message: "40文字以内で入力してください。",
                },
              })}
              error={
                !!errors.featuredVideos &&
                !!errors.featuredVideos[index]?.name?.message && {
                  message: errors.featuredVideos[index]?.name?.message,
                }
              }
            />
            <p className="mt-2.5 text-sm text-dark-gray">
              Google Analyticsでデータを取得するために必要な項目です
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
