import { useCallback } from "react";

import {
  TableUI,
  DnDTrUI,
  DnDTrUIProps,
  TdUI,
} from "../../../../../components/TableUI";
import { CoursesDnDTableItemFragment } from "../../../../../generated/graphql";
import { formattedTerm } from "../../utils/date";

export type CoursesDnDTableUIProps = {
  courses: CoursesDnDTableItemFragment[];
  onChangeCourses: (courses: CoursesDnDTableItemFragment[]) => void;
};

const ITEM_TYPE = "course";

export const CoursesDnDTableUI: React.VFC<CoursesDnDTableUIProps> = ({
  courses,
  onChangeCourses,
}) => {
  const findCard: DnDTrUIProps<typeof ITEM_TYPE>["findCard"] = useCallback(
    (id: string) => {
      const index = courses.findIndex((c) => c.id === id);
      return {
        course: courses[index],
        index,
      };
    },
    [courses]
  );

  const moveCard: DnDTrUIProps<typeof ITEM_TYPE>["moveCard"] = useCallback(
    (id, atIndex) => {
      const index = findCard(id).index;
      if (index === atIndex) {
        return;
      }

      const _courses = [...courses];
      const value = _courses[index];
      _courses.splice(index, 1);
      _courses.splice(atIndex, 0, value);
      onChangeCourses(_courses);
    },
    [courses, onChangeCourses, findCard]
  );

  return (
    <TableUI
      className="mt-3 text-left"
      headers={["コード", "コース名", "授業日時", ""]}
      colClassNames={["w-24", "w-auto", "w-48", "w-12"]}
    >
      {courses.map((course) => (
        <DnDTrUI
          key={course.id}
          itemType={ITEM_TYPE}
          item={course}
          moveCard={moveCard}
          findCard={findCard}
        >
          <TdUI className="pl-5">{course.systemId}</TdUI>
          <TdUI className="pl-5">{course.name}</TdUI>
          <TdUI className="pl-5">{formattedTerm(course)}</TdUI>
        </DnDTrUI>
      ))}
    </TableUI>
  );
};
