import React, { HTMLAttributes } from "react";

export type TextUIProps = HTMLAttributes<HTMLElement>;

export const TextUI: React.FC<TextUIProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <p className={`tracking-wider whitespace-pre-wrap ${className}`} {...rest}>
      {children}
    </p>
  );
};
