import { MASTERS, MasterType } from "../../constants";
import { useCanUseMaster } from "../../hooks/canUserMaster";

export const MasterSwitchUI: React.VFC<{ masterType: MasterType }> = ({
  masterType,
}) => {
  const { canUseMaster } = useCanUseMaster();
  if (!canUseMaster(masterType)) {
    return <></>;
  }
  const master = MASTERS.find((master) => master.masterType === masterType);
  if (!master) {
    return <></>;
  }
  const MasterComponent = master.component;
  return <MasterComponent {...master.props} />;
};
