import React, { useCallback, useEffect } from "react";

import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useBreadcrumb, Breadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import {
  FeaturedVideosEditPageQuery,
  UpdateFeaturedVideoInput,
} from "../../../generated/graphql";
import { FeaturedVideosState } from "../FeaturedVideosEditConfirmPage";
import { FeaturedVideoEditUI } from "./components/FeaturedVideoEdit";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "注目の動画管理",
  },
];

export type FeaturedVideoInput = Pick<
  UpdateFeaturedVideoInput,
  "name" | "order"
> & {
  alias: string;
  // 既に画像が登録されていれば "default" にする
  file: File | "default";
};

export type FeaturedVideosInput = {
  featuredVideos: FeaturedVideoInput[];
};

export type FeaturedVideosEditPageProps = {
  data: FeaturedVideosEditPageQuery;
};

const FeaturedVideosEditPage: React.VFC<FeaturedVideosEditPageProps> = ({
  data,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const methods = useForm<FeaturedVideosInput>({
    defaultValues: data.featuredVideos.length
      ? {
          featuredVideos: data.featuredVideos.map((featuredVideo, index) => ({
            name: featuredVideo.name,
            order: index + 1,
            alias: featuredVideo.path,
            file: featuredVideo.thumbnailUrl ? "default" : undefined,
          })),
        }
      : {
          featuredVideos: [...new Array(3)].map((_item, index) => ({
            order: index + 1,
          })),
        },
  });
  const history = useHistory<FeaturedVideosState>();
  const onSubmit: SubmitHandler<FeaturedVideosInput> = useCallback(
    (input) => {
      const featuredVideos = input.featuredVideos.map((inp, i) => ({
        ...inp,
        fileName: data.featuredVideos[i]?.fileName,
        thumbnailUrl: data.featuredVideos[i]?.thumbnailUrl,
      }));

      history.push("/featuredvideos/edit/confirm", { featuredVideos });
    },
    [history, data]
  );

  const handleBack: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/featuredvideos");
    }, [history]);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">注目の動画管理</h1>
      <hr className="border-gray border mb-2" />
      <h2 className="text-1.5xl">注目の動画差し替え</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mt-10 space-y-20">
            {[0, 1, 2].map((index) => (
              <FeaturedVideoEditUI
                index={index}
                key={index}
                image={
                  data.featuredVideos[index] && {
                    src: data.featuredVideos[index].thumbnailUrl,
                    alt: data.featuredVideos[index].fileName,
                  }
                }
                data-testid="featured-video-edit"
              />
            ))}
          </div>
          <div className="flex flex-col mt-7 items-center">
            <p>※登録完了後、変更が即時にお客様側へ反映されます。</p>
            <div className="mt-4 mb-28 space-x-10">
              <ButtonUI buttonType="secondary" onClick={handleBack}>
                戻る
              </ButtonUI>
              <ButtonUI type="submit">確認</ButtonUI>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FeaturedVideosEditPage;
