import { HTMLAttributes } from "react";

export type Status = "success" | "particalSuccess" | "failed" | "processing";

const STATUS_TO_COLOR = {
  failed: "text-error bg-white border-error",
  particalSuccess: "text-light-orange bg-white border-light-orange",
  success: "text-dark-gray bg-white border-dark-gray",
  processing: "text-light-orange bg-white border-light-orange",
};

export type StatusBlockProps = {
  status: Status;
  customColor?: string;
} & HTMLAttributes<HTMLSpanElement>;

const StatusBlock: React.FC<StatusBlockProps> = ({
  children,
  status,
  className,
  customColor,
}) => {
  return (
    <span
      className={`flex justify-center items-center flex-wrap text-sm leading-none border rounded ${
        customColor ? customColor : STATUS_TO_COLOR[status]
      } ${className}`}
    >
      {children}
    </span>
  );
};

export default StatusBlock;
