import React from "react";

import dayjs from "dayjs";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import { ParticipantTableRowFragment } from "../../../../generated/graphql";
import { genders } from "../../../../lib/constants";
export type ParticipantItemProps = {
  participant: ParticipantTableRowFragment;
  index: number;
};

const ParticipantItemUI: React.FC<ParticipantItemProps> = ({
  participant,
  index,
}) => {
  return (
    <tr
      data-testid="participants-item"
      className={`h-12 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      {/* 順番 */}
      <td className="border-r border-dark-gray text-center">{index + 1}</td>
      {/* お客様番号 */}
      <td className="border-r border-dark-gray text-center">
        <LinkTextUI to={`/customers/${participant.customerId}`}>
          {participant.customerId}
        </LinkTextUI>
      </td>
      {/* 性別 */}
      <td className="border-r border-dark-gray text-center">
        {genders.find((gender) => gender.value === participant.gender)?.label}
      </td>
      {/* 年齢 */}
      <td className="border-r border-dark-gray text-center">
        {participant.age}
      </td>
      {/* 申し込み日 */}
      <td className="border-r border-dark-gray pl-5">
        {participant.applicatedAt &&
          dayjs(participant.applicatedAt).format("YYYY/MM/DD")}
      </td>
    </tr>
  );
};

export default ParticipantItemUI;
