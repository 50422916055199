import React, { useCallback, useState } from "react";

import { ApolloError, GraphQLErrors } from "@apollo/client/errors";

import { ButtonUI } from "../../../../../components/Button";
import { ConfirmModalUI } from "../../../../../components/ConfirmModal";
import {
  StepFunctionsCannotStartReason,
  StepFunctionsExecutionStatus,
} from "../../../../../generated/graphql";
import { usePolling } from "../../../../../hooks/polling";
import { NeedToWaitSettleSeminarBatchModal } from "./NeedToWaitSettleSeminarBatchModal";
import { StepFunctionsCouldNotBeginModal } from "./StepFunctionsCouldNotBeginModal";
import { StepFunctionsExecutionStatusModal } from "./StepFunctionsExecutionStatusModal";

export type ExecuteStepFunctionsButtonProps = {
  /** ボタンに表示する文字列 */
  label: string;
  /** ボタン押下時の確認モーダル */
  confirmModal: { title: string; description: string };
  /** Step Functionsを実行するmutationをコールする関数 */
  callMutation: () => Promise<
    [
      (
        | {
            executionARN?: string | null | undefined;
            failedReason?: StepFunctionsCannotStartReason | null | undefined;
          }
        | undefined
      ),
      GraphQLErrors | undefined
    ]
  >;
  /** Step Functionsの実行状況を取得するqueryをコールする関数 */
  pollExecutionStatus: (
    executionARN: string
  ) => Promise<
    [
      { status: StepFunctionsExecutionStatus } | undefined,
      ApolloError | undefined
    ]
  >;
  /** Step Functionsが完了し、モーダルの「閉じる」ボタンが押下された際に呼ばれるコールバック関数 */
  onComplete: (status: StepFunctionsExecutionStatus) => void;
};

/**
 * Step Functionsを起動するmutationを実行し、Step Functionsが終了するまでモーダルを表示するボタン。
 */
export const ExecuteStepFunctionsButton: React.VFC<
  ExecuteStepFunctionsButtonProps
> = ({
  label,
  confirmModal,
  onComplete,
  callMutation,
  pollExecutionStatus,
}) => {
  // 確認ダイアログ表示/非表示
  const [confirmDialogVisibility, setConfirmDialogVisibility] = useState(false);

  // Step Functions起動用のmutationコール後、Step Functionsの状況をポーリングする。
  const { beginPolling: beginPollingCancelAuthorizedCourseStatus } =
    usePolling();

  // mutationコール時にエラーが発生したか否か。
  const [hasErrorOnBegin, setHasErrorOnBegin] = useState(false);
  // mutationから「Step Functionsを開始できる状況では無い」とレスポンスが来た場合、その内容。
  const [cannotStartReason, setCannotStartReason] = useState<
    StepFunctionsCannotStartReason | undefined
  >(undefined);
  // Step Functionsの実行状況。未実行の場合、undefined。
  const [executionStatus, setExecutionStatus] = useState<
    StepFunctionsExecutionStatus | undefined
  >(undefined);

  const onClick = useCallback(() => {
    setConfirmDialogVisibility(true);
  }, []);

  const onCancelConfirmModal = useCallback(() => {
    setConfirmDialogVisibility(false);
  }, []);

  const onSubmitConfirmModal = useCallback(async () => {
    setConfirmDialogVisibility(false);
    setExecutionStatus(StepFunctionsExecutionStatus.Running);

    const [mutationData, mutationError] = await callMutation();
    if (mutationError || !mutationData) {
      setHasErrorOnBegin(true);
      setExecutionStatus(undefined);
      return;
    }

    if (mutationData.failedReason) {
      setCannotStartReason(mutationData.failedReason);
      setExecutionStatus(undefined);
    }

    if (!mutationData.executionARN) {
      // failedReasonとexecutionARNの両方が未定義な状況は、起こり得ない。
      setExecutionStatus(undefined);
      return;
    }

    const executionARN = mutationData.executionARN;

    const executeOnPolling = async (): Promise<boolean> => {
      const [pollData, pollError] = await pollExecutionStatus(executionARN);

      if (pollError || !pollData) {
        setExecutionStatus(undefined);
        return false;
      }

      const newExecutionStatus = pollData.status;
      setExecutionStatus(newExecutionStatus);

      if (newExecutionStatus === StepFunctionsExecutionStatus.Running) {
        return true;
      } else {
        return false;
      }
    };

    beginPollingCancelAuthorizedCourseStatus(executeOnPolling);
  }, [
    beginPollingCancelAuthorizedCourseStatus,
    callMutation,
    pollExecutionStatus,
  ]);

  const onClickCannotBeginCancelModalCloseButton = useCallback(() => {
    setHasErrorOnBegin(false);
  }, []);

  const onClickNeedToWaitSettleSeminarBatchModalCloseButton =
    useCallback(() => {
      setCannotStartReason(undefined);
    }, []);

  const onClickStatusModalCloseButton = useCallback(() => {
    if (executionStatus) {
      onComplete(executionStatus);
      setExecutionStatus(undefined);
    }
  }, [executionStatus, onComplete]);

  return (
    <>
      <ButtonUI
        data-testid="payment-ExecuteStepFunctionsButton"
        buttonType="danger"
        size="s"
        className="mt-20"
        onClick={onClick}
        disabled={!!executionStatus}
      >
        {label}
      </ButtonUI>
      <ConfirmModalUI
        {...confirmModal}
        onCancel={onCancelConfirmModal}
        onSubmit={onSubmitConfirmModal}
        visibility={confirmDialogVisibility}
      />
      <StepFunctionsCouldNotBeginModal
        visibility={hasErrorOnBegin}
        onClickCloseButton={onClickCannotBeginCancelModalCloseButton}
      />
      <NeedToWaitSettleSeminarBatchModal
        cannotStartReason={cannotStartReason}
        onClickCloseButton={onClickNeedToWaitSettleSeminarBatchModalCloseButton}
      />
      <StepFunctionsExecutionStatusModal
        status={executionStatus}
        onClickCloseButton={onClickStatusModalCloseButton}
      />
    </>
  );
};
