import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { VideoLessonDetailFragment } from "../../../generated/graphql";
import VideoLessonDetail from "../components/VideoLessonDetail/VideoLessonDetail";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "有識者一覧",
  },
];

export type VideoLessonPageProps = {
  expertId: string;
  videoLesson: VideoLessonDetailFragment;
};

const VideoLessonPage: React.VFC<VideoLessonPageProps> = ({
  expertId,
  videoLesson,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="pb-44">
      <h1 className="text-2xl mb-2">シリーズ詳細</h1>
      <hr className="border-gray border mb-4" />
      <VideoLessonDetail className="mt-8" videoLesson={videoLesson} />
      <div className="flex justify-center mt-20 space-x-10">
        <ButtonUI to="/experts" buttonType="secondary">
          戻る
        </ButtonUI>
        <ButtonUI
          to={`/experts/${expertId}/videolessons/${videoLesson.id}/edit`}
        >
          編集
        </ButtonUI>
      </div>
    </div>
  );
};

export default VideoLessonPage;
