import { useDrag, useDrop } from "react-dnd";

import { ReactComponent as DotsIcon } from "../dots.svg";
import { TdUI } from "./TdUI";
import { TrUI } from "./TrUI";

export type DnDTrUIProps<RowItem> = {
  itemType: string;
  item: RowItem;
  moveCard: (id: string, atIndex: number) => void;
  findCard: (id: string) => { index: number };
};

type Item = {
  id: string;
  originalIndex: number;
};

export type RowItem = {
  id: string;
};

export const DnDTrUI: React.FC<DnDTrUIProps<RowItem>> = ({
  itemType,
  item: rowItem,
  moveCard,
  findCard,
  children,
}) => {
  const originalIndex = findCard(rowItem.id).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: itemType,
      item: { id: rowItem.id, originalIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [rowItem, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: itemType,
      hover: ({ id: draggedId }: Item, _monitor) => {
        const overIndex = findCard(rowItem.id).index;
        moveCard(draggedId, overIndex);
      },
    }),
    [rowItem, moveCard]
  );

  return (
    <TrUI
      // captureDraggingStateをtrueにするとドラッグされたときに割り当てたスタイルが
      // ドラッグプレビュー（マウスにひっついている要素）に反映されるようになる
      ref={(node) => preview(drop(node), { captureDraggingState: true })}
      isDragging={isDragging}
      data-testid={`${itemType.toLowerCase()}-row-${rowItem.id}`}
    >
      {children}
      <TdUI
        className="pl-5 cursor-move"
        data-testid={`${itemType.toLowerCase()}-drag-button-${rowItem.id}`}
        ref={drag}
      >
        <DotsIcon />
      </TdUI>
    </TrUI>
  );
};
