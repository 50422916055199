import React from "react";

import { ButtonUI } from "../../../../../components/Button";
import { ModalUI } from "../../../../../components/Modal";

export type StepFunctionsCouldNotBeginModalProps = {
  visibility: boolean;
  onClickCloseButton: () => void;
};

export const StepFunctionsCouldNotBeginModal: React.VFC<
  StepFunctionsCouldNotBeginModalProps
> = ({ visibility, onClickCloseButton }) => {
  return (
    <ModalUI visibility={visibility}>
      <div
        data-testid="payment-StepFunctionsCouldNotBeginModal"
        className="inline-block px-5 py-5 text-center"
      >
        <div className="text-error">処理開始に失敗しました。</div>
        <div>
          <ButtonUI
            data-testid="payment-StepFunctionsCouldNotBeginModal-closeButton"
            className="px-2 mt-5"
            buttonType="secondary"
            onClick={onClickCloseButton}
          >
            閉じる
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};
