import React, { useCallback, useEffect, useState } from "react";

import { ButtonUI } from "../../../../components/Button";
import { InputUI } from "../../../../components/Input";
import { LabelUI } from "../../../../components/Label";
import { ContBillPaymentMismatchesFilterInput } from "../../../../generated/graphql";

export type ContBillPaymentMismatchesSearchPageProps = {
  onSearch: (filter: ContBillPaymentMismatchesFilterInput) => void;
};

type FilterInputs = {
  continueAccountId: string;
};

export const ContBillPaymentMismatchesSearchUI: React.VFC<
  ContBillPaymentMismatchesSearchPageProps
> = ({ onSearch }) => {
  const [inputs, setInputs] = useState<FilterInputs>({
    continueAccountId: "",
  });
  const [searchFilter, setSearchFilter] =
    useState<ContBillPaymentMismatchesFilterInput>({
      ...inputs,
    });

  useEffect(() => {
    setSearchFilter({
      ...inputs,
    });
    return () => {
      // unmount
    };
  }, [setSearchFilter, inputs]);

  return (
    <div className="max-w-screen-md">
      <p className="mt-8 mb-2 text-lg">絞込条件設定</p>
      <div className="border-b-2">
        <form
          onSubmit={useCallback(
            (event) => {
              event.preventDefault();
              onSearch(searchFilter);
            },
            [searchFilter, onSearch]
          )}
          className="mt-2.5 mb-4"
        >
          <div className="flex items-center mt-4 pl-5">
            <LabelUI className="w-3/12 text-sm text-left">継続課金ID</LabelUI>
            <InputUI
              data-testid="cont-bill-payment-mismatches-search-continue-account-id"
              value={inputs.continueAccountId}
              id="continueAccountId"
              name="continueAccountId"
              className="w-72"
              placeholder="123456789"
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  continueAccountId: text,
                });
              }}
            />
          </div>
          <div className="flex justify-end mt-12">
            <ButtonUI
              data-testid="cont-bill-payment-mismatches-search-submit"
              buttonType="primary"
              type="submit"
              size="xs"
              className="mr-6"
            >
              絞込
            </ButtonUI>
          </div>
        </form>
      </div>
    </div>
  );
};
