import React from "react";

import { Route, RouteProps, Redirect } from "react-router-dom";

import { useIsUserLoggedInQuery } from "../generated/graphql";
import { useQueryParams } from "../hooks/query";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { data, error, loading } = useIsUserLoggedInQuery();
  const params = useQueryParams();

  if (error) {
    console.error(error);
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }

  /**
   * cognito user poolを無効にした場合に
   * data.isLoggedInがfalse 且つ リダイレクトURLにerrorが含まれない
   * この状態でログインページにリダイレクトされると無限ループが発生する
   * 無限ループを抑止する為に、暫定的にdata.isLoggedInがfalse
   * 且つリダイレクトURLにcode parmasが含まれる場合にcognito userが無効と判定し、
   * errorページにリダイレクトさせるようにしている
   */
  const checkError = (params: URLSearchParams) =>
    params.get("code") || params.get("error");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        data.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: checkError(params) ? "/error" : "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
