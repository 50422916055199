import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import ServerErrorPageUI from "../components/ServerErrorPage/ServerErrorPage";
import notFoundSvg from "./notFound.svg";
const BREAD_CRUMBS: Breadcrumb[] = [];

const NotFoundErrorPage: React.VFC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return (
    <ServerErrorPageUI
      iconImage={
        <img
          className="object-contain mx-auto"
          src={notFoundSvg}
          alt="notFound"
          data-testid="error-image"
        />
      }
    >
      お探しのページが見つかりません。
    </ServerErrorPageUI>
  );
};

export default NotFoundErrorPage;
