import React, { HTMLAttributes } from "react";

import dayjs from "dayjs";

import { UserDetailFragment } from "../../generated/graphql";
import { genders, statuses } from "../../lib/constants";
import { FormGroupUI } from "../FormGroup";
import { LabelUI } from "../Label";
import { TextUI } from "../Text";
import { UserAvatorUI } from "../UserAvator";

export type UserDetailUIProps = {
  user: UserDetailFragment;
} & HTMLAttributes<HTMLDivElement>;

export const UserDetailUI: React.VFC<UserDetailUIProps> = ({
  user,
  ...rest
}) => {
  return (
    <div {...rest}>
      <FormGroupUI>
        <LabelUI data-testid="user-detail-userid-label" className="text-lg">
          {user.extra && "外部"}ユーザーID
        </LabelUI>
        <TextUI data-testid="user-detail-userId">{user.userId}</TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">名前</LabelUI>
        <TextUI data-testid="user-detail-name">
          {user.familyName} {user.givenName}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">名前（フリガナ）</LabelUI>
        <TextUI data-testid="user-detail-name-kana">
          {user.familyNameKana!} {user.givenNameKana!}
        </TextUI>
      </FormGroupUI>
      <hr />
      {!user.extra && (
        <>
          <FormGroupUI>
            <LabelUI>サービス別権限</LabelUI>
            <TextUI data-testid="users-detail-useableServiceKinds">
              {user.useableServiceKinds
                .map((serviceKind) => serviceKind.shortName)
                .join(", ")}
            </TextUI>
          </FormGroupUI>
          <hr />
        </>
      )}
      {!user.extra && (
        <>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">ロール別権限</LabelUI>
            </div>
            <TextUI data-testid="user-detail-roleIds">
              {user.roles.map((role) => role.name).join(", ")}
            </TextUI>
          </FormGroupUI>
          <hr />
        </>
      )}
      <FormGroupUI>
        <LabelUI className="text-lg">メールアドレス</LabelUI>
        <TextUI data-testid="user-detail-email">{user.email}</TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">電話番号</LabelUI>
        <TextUI data-testid="user-detail-tel">{user.tel!}</TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">性別</LabelUI>
        <TextUI data-testid="user-detail-gender">
          {genders.find((gender) => gender.value === user.gender)?.label}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">生年月日</LabelUI>
        <TextUI data-testid="user-detail-birthday">
          {user.birthday && dayjs(user.birthday).format("YYYY/MM/DD")}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">出身国</LabelUI>
        <TextUI data-testid="user-detail-birthplace">{user.birthplace}</TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI
          data-testid="user-detail-years-of-teaching-label"
          className="text-lg"
        >
          {user.extra ? "教師歴" : "職歴"}
        </LabelUI>
        <TextUI data-testid="user-detail-yearsOfTeaching">
          {user.yearsOfTeaching && `${user.yearsOfTeaching}年`}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">メッセージ</LabelUI>
        <TextUI data-testid="user-detail-message" className="w-11/12">
          {user.message}
        </TextUI>
      </FormGroupUI>

      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">自己紹介</LabelUI>
        <TextUI data-testid="user-detail-selfIntroduction" className="w-11/12">
          {user.selfIntroduction}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">趣味</LabelUI>
        <TextUI data-testid="user-detail-hobby" className="w-11/12">
          {user.hobby}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI className="text-lg">画像</LabelUI>
        <UserAvatorUI data-testid="user-detail-avator" src={user.avator} />
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">ステータス</LabelUI>
        </div>
        <TextUI data-testid="user-detail-status">
          {statuses.find((status) => status.value === user.status)?.label}
        </TextUI>
      </FormGroupUI>
    </div>
  );
};
