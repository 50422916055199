import { InMemoryCache, makeVar, useReactiveVar } from "@apollo/client";
import EventEmitter from "eventemitter2";

import { CurrentServiceKind, AuVideos } from "../generated/graphql";

const CURRENT_SERVICE_KIND_SESSION_STORAGE_KEY = "currentServiceKind";

const getFromSessionStorage = (): CurrentServiceKind | null => {
  const currentServiceKind = sessionStorage.getItem(
    CURRENT_SERVICE_KIND_SESSION_STORAGE_KEY
  );
  return currentServiceKind && JSON.parse(currentServiceKind);
};

const _currentServiceKind = makeVar<CurrentServiceKind | null>(
  getFromSessionStorage()
);

const emitter = new EventEmitter();
const currentServiceKindChangeEvent = Symbol();

export type CurrentServiceKindChangeEvent = {
  oldValue?: CurrentServiceKind | null;
  newValue?: CurrentServiceKind | null;
};

export type CurrentServiceKindChangeListener = (
  event: CurrentServiceKindChangeEvent
) => void;

export function addCurrentServiceKindChangeListener(
  listner: CurrentServiceKindChangeListener
) {
  emitter.addListener(currentServiceKindChangeEvent, listner);
}

export function removeCurrentServiceKindChangeListener(
  listner: CurrentServiceKindChangeListener
) {
  emitter.removeListener(currentServiceKindChangeEvent, listner);
}

export function currentServiceKind(arg?: CurrentServiceKind) {
  if (arg && _currentServiceKind()) {
    const event: CurrentServiceKindChangeEvent = {
      oldValue: _currentServiceKind(),
      newValue: arg,
    };
    sessionStorage.setItem(
      CURRENT_SERVICE_KIND_SESSION_STORAGE_KEY,
      JSON.stringify(arg)
    );
    emitter.emit(currentServiceKindChangeEvent, event);
  }
  return arg ? _currentServiceKind(arg) : _currentServiceKind();
}

export function useCurrentServiceKind() {
  return useReactiveVar(_currentServiceKind);
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        auVideos: {
          merge(
            existing: AuVideos,
            incoming: AuVideos,
            { args: { offset = 0 } }: any
          ): AuVideos {
            const merged = existing ? existing.nodes.slice(0) : [];
            for (let i = 0; i < incoming.nodes.length; ++i) {
              merged[offset + i] = incoming.nodes[i];
            }

            return {
              ...(incoming ?? {}),
              nodes: merged,
            };
          },
        },
      },
    },
    User: {
      fields: {
        currentServiceKind: {
          read() {
            return _currentServiceKind();
          },
        },
      },
    },
  },
});
