import React, { HTMLAttributes, useState } from "react";

import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { ExpertCardFragment } from "../../../../../generated/graphql";
import { ReactComponent as Plus } from "../../../../../images/plus.svg";
import { VideoLessonCard } from "../VideoLessonCard";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import ComingSoon from "./coming_soon.png";
import { ReactComponent as List } from "./list.svg";

export type ExpertCardProps = {
  expert: ExpertCardFragment;
} & HTMLAttributes<HTMLDivElement>;

const ExpertCard: React.VFC<ExpertCardProps> = ({ expert, ...rest }) => {
  const isComingSoon = dayjs().isBefore(expert.publishedAt);
  const [opened, setOpened] = useState(false);
  return (
    <div className=" py-4 pl-3 pr-5 even:bg-light-blue" {...rest}>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row">
          <div className="relative">
            <img
              className="w-24"
              src={isComingSoon ? ComingSoon : expert.profilePhoto ?? ""}
              alt={`${expert.familyName}${expert.givenName}`}
            />
            {isComingSoon && (
              <p
                className="absolute top-1/2 transform -translate-y-1/2 text-white text-xs text-center w-full"
                data-testid="expert-coming-soon"
              >
                {`${dayjs(expert.publishedAt).format("MM月DD日")}`}予定
              </p>
            )}
          </div>
          <div className="mt-4 ml-2.5 text-sm" data-testid="expert-name">
            {expert.familyName} {expert.givenName}
          </div>
        </div>
        <div className="flex flex-row space-x-4">
          <Link
            className="flex flex-row items-center border border-blue text-blue bg-white rounded-sm py-1 px-2.5 space-x-2"
            to={`/experts/${expert.id}/videolessons/new`}
          >
            <Plus className="fill-current w-3" />
            <span>シリーズ追加</span>
          </Link>
          <Link
            className="flex flex-row items-center border border-blue text-blue bg-white rounded-sm py-1 px-2.5 space-x-2"
            to={`/experts/${expert.id}`}
            data-testid="to-expert-detail"
          >
            <List className="stroke-current fill-current w-3" />
            <span>有識者詳細</span>
          </Link>
        </div>
      </div>
      <div className="space-x-2 border border-dark-gray py-2 mt-4 bg-white">
        <button
          data-testid="video-lessons-toggle-button"
          className="flex flex-row items-center mx-auto space-x-1 text-blue"
          onClick={() => setOpened(!opened)}
        >
          <span>シリーズ({expert.videoLessons.length}件)を表示</span>
          {opened ? (
            <ArrowUp className="h-2.5" />
          ) : (
            <ArrowDown className="h-2.5" />
          )}
        </button>
      </div>
      {opened &&
        expert.videoLessons.map((videoLesson) => (
          <VideoLessonCard
            key={videoLesson.id}
            expertId={expert.id}
            videoLesson={videoLesson}
          />
        ))}
    </div>
  );
};

export default ExpertCard;
