import React, { MouseEventHandler } from "react";

import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";
type Props = {
  title: string;
  description: string;
  visibility: boolean;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
};

export const ConfirmModalUI = ({
  title,
  description,
  visibility,
  onSubmit,
  onCancel,
}: Props) => {
  return (
    <ModalUI
      data-testid="confirm-modal"
      visibility={visibility}
      className="w-112"
    >
      <div className="inline-block pt-4 pb-10 px-3 text-center">
        <h1
          className="text-center text-lg tracking-wider text-subtitle"
          data-testid="confirm-modal-title"
        >
          {title}
        </h1>
        <hr className="border-horizontalrule mt-5"></hr>
        <div
          className="mt-4 tracking-wider"
          data-testid="confirm-modal-description"
        >
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
        <div className="flex justify-center space-x-10 mt-9">
          <ButtonUI
            data-testid="contents-confirm-cancel"
            buttonType="secondary"
            onClick={onCancel}
          >
            キャンセル
          </ButtonUI>
          <ButtonUI data-testid="contents-confirm-submit" onClick={onSubmit}>
            はい
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};
