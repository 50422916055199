import React, {
  useEffect,
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
} from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { FormGroupUI } from "../../../components/FormGroup";
import { InputUI } from "../../../components/Input";
import { LabelUI } from "../../../components/Label";
import { RadioUI } from "../../../components/Radio";
import {
  SelectCustomStyleUI,
  SelectOption,
} from "../../../components/SelectCustomStyle";
import { TextUI } from "../../../components/Text";
import { TextareaUI } from "../../../components/Textarea";
import { ValidatableTextareaUI } from "../../../components/ValidatableTextarea";
import {
  CreateContactHistoryInput,
  ContactStatus,
  ContactRoute,
} from "../../../generated/graphql";
import { statusTypes } from "../../../lib/constants";
import { preventFromSubmittingByEnter } from "../../../lib/functions";

import { CreateContactEdit } from "./index";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "お問い合わせ対応",
  },
  {
    label: "お問い合わせ新規登録",
  },
];

export type RadioOption = {
  value: string;
  label: string;
};

const routes: RadioOption[] = [
  { value: ContactRoute.Tel, label: "電話" },
  { value: ContactRoute.Email, label: "メール" },
  { value: ContactRoute.Others, label: "その他" },
];

export type ChangeContactHandler = (contactInput: CreateContactEdit) => void;
export type ChangeContactHistoryHandler = (
  contactHistoryInput: CreateContactHistoryInput
) => void;

export type ContactsNewPageProps = {
  member: boolean;
  contactInput: CreateContactEdit;
  contactHistoryInput: CreateContactHistoryInput;
  contactCategories: RadioOption[];
  contactServiceKinds: SelectOption[];
  onChangeFormType: (member: boolean) => void;
  onOpenCustomerSearchModal: MouseEventHandler<HTMLButtonElement>;
  onChangeContact: ChangeContactHandler;
  onChangeContactHistory: ChangeContactHistoryHandler;
  onCreateContact: (
    contactInput: CreateContactEdit,
    contactHistoryInput: CreateContactHistoryInput
  ) => void;
};

const ContactsNewPage: React.FC<ContactsNewPageProps> = ({
  member,
  contactInput,
  contactHistoryInput,
  contactCategories,
  contactServiceKinds,
  onChangeFormType,
  onOpenCustomerSearchModal,
  onChangeContact,
  onChangeContactHistory,
  onCreateContact,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateContactHistoryInput>({
    defaultValues: {
      ...contactHistoryInput,
    },
  });
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const onHandleSubmit: SubmitHandler<CreateContactHistoryInput> =
    useCallback(() => {
      onCreateContact(contactInput, contactHistoryInput);
    }, [contactInput, contactHistoryInput, onCreateContact]);

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // type = contact or history
      const [type, key] = event.target.id.split("-");
      if (type === "contact") {
        if (key === "content") {
          onChangeContact({
            ...contactInput,
            content: event.target.value,
          });
        } else {
          onChangeContact({
            ...contactInput,
            address: {
              ...contactInput.address,
              [key]: event.target.value,
            },
          });
        }
      } else {
        onChangeContactHistory({
          ...contactHistoryInput,
          [key]: event.target.value,
        });
      }
    },
    [contactInput, contactHistoryInput, onChangeContact, onChangeContactHistory]
  );

  const onChangeRadioInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeContact({
        ...contactInput,
        [event.target.name]: event.target.value,
        // ↑ あまりよろしくないコード。バグ修正を最小限にするために特に修正していない。
      });
    },
    [contactInput, onChangeContact]
  );

  const onChangeCustomSelect = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      onChangeContactHistory({
        ...contactHistoryInput,
        [name]: value,
      });
    },
    [contactHistoryInput, onChangeContactHistory]
  );

  return (
    <div>
      <h1 className="text-2xl mb-3">お問い合わせ新規登録</h1>
      <hr className="border-dark-gray border mb-6"></hr>
      <div>
        <h2 className="text-xl color-contact-subtitle mb-4">お客様情報</h2>
        <div className="flex justify-start space-x-5 mb-6 w-96 px-2.5">
          <ButtonUI
            data-testid="users-page-internal-teachers-btn"
            buttonType={member ? "selecting" : "secondary"}
            size="custom"
            className="w-44 h-10"
            base="rounded20"
            onClick={() => {
              onChangeFormType(true);
            }}
          >
            会員の方
          </ButtonUI>

          <ButtonUI
            data-testid="users-page-external-teachers-btn"
            buttonType={!member ? "selecting" : "secondary"}
            size="custom"
            className="w-44 h-10"
            base="rounded20"
            onClick={() => {
              onChangeFormType(false);
            }}
          >
            非会員の方
          </ButtonUI>
        </div>
        {member && (
          <div className="w-96 px-2.5">
            <ButtonUI
              data-testid="users-page-external-teachers-btn"
              buttonType="secondary"
              size="custom"
              className="w-full h-10"
              onClick={onOpenCustomerSearchModal}
            >
              検索
            </ButtonUI>
          </div>
        )}
        <FormGroupUI>
          <LabelUI>氏名</LabelUI>
          <div className="flex items-center space-x-3 w-96">
            <InputUI
              data-testid="contacts-new-page-familyName"
              value={contactInput?.address?.familyName || ""}
              id="contact-familyName"
              placeholder={`${member ? "" : "au"}`}
              className="border border-black w-44"
              disabled={member}
              onChange={onChangeInput}
            />
            <InputUI
              data-testid="contacts-new-page-givenName"
              value={contactInput?.address?.givenName || ""}
              id="contact-givenName"
              placeholder={`${member ? "" : "太郎"}`}
              className="border border-black  w-44"
              disabled={member}
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray"></hr>
        <FormGroupUI>
          <LabelUI>フリガナ</LabelUI>
          <div className="flex items-center space-x-3 w-96">
            <InputUI
              data-testid="contacts-new-page-familyNameKana"
              value={contactInput?.address?.familyNameKana || ""}
              id="contact-familyNameKana"
              placeholder={`${member ? "" : "エーユー"}`}
              className="border border-black w-44"
              disabled={member}
              onChange={onChangeInput}
            />
            <InputUI
              data-testid="contacts-new-page-givenNameKana"
              value={contactInput?.address?.givenNameKana || ""}
              id="contact-givenNameKana"
              placeholder={`${member ? "" : "タロウ"}`}
              className="border border-black  w-44"
              disabled={member}
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray"></hr>
        <FormGroupUI>
          <LabelUI>
            {member ? "会員ID(メールアドレス)" : "メールアドレス"}
          </LabelUI>
          <div className="flex items-center w-96 px-2.5">
            <InputUI
              data-testid="contacts-new-page-email"
              value={contactInput?.address?.email || ""}
              id="contact-email"
              placeholder={`${member ? "" : "sample@sample.com"}`}
              className="border border-black w-full"
              disabled={member}
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray"></hr>
        <FormGroupUI>
          <LabelUI>電話番号(ハイフンなし)</LabelUI>
          <div className="flex items-center w-96 px-2.5">
            <InputUI
              data-testid="contacts-new-page-tel"
              value={contactInput?.address?.tel || ""}
              id="contact-tel"
              placeholder={`${member ? "" : "0123456789"}`}
              className="border border-black w-full"
              disabled={member}
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray mb-6" />
        <h2 className="text-xl color-contact-subtitle mb-4">
          お問い合わせ情報
        </h2>
        <FormGroupUI>
          <LabelUI className="text-lg">お問い合わせ番号</LabelUI>
          <TextUI data-testid="contacts-new-page-id">
            登録後に発行されます。
          </TextUI>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray" />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>お問い合わせ経路</LabelUI>
          </div>
          <div className="flex space-x-10">
            {routes.map((route: RadioOption) => (
              <RadioUI
                data-testid="contacts-new-page-route"
                key={route.value}
                id={`route-${route.value}`}
                name="route"
                value={route.value}
                checked={contactInput.route === route.value}
                onChange={onChangeRadioInput}
              >
                {route.label}
              </RadioUI>
            ))}
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray" />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>お問い合わせ種別</LabelUI>
          </div>
          <div className="flex space-x-10">
            {contactCategories.map((contactCategory: RadioOption) => (
              <RadioUI
                data-testid="contacts-new-page-category"
                key={contactCategory.value}
                id={`categoryId-${contactCategory.value}`}
                name="categoryId"
                value={contactCategory.value}
                checked={contactInput.categoryId === contactCategory.value}
                onChange={onChangeRadioInput}
              >
                {contactCategory.label}
              </RadioUI>
            ))}
          </div>
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray" />
        <FormGroupUI>
          <LabelUI className="text-base mb-2.5">お問い合わせ内容</LabelUI>
          <TextareaUI
            data-testid="contacts-new-page-content"
            rows={6}
            className="w-full px-2.5 h-40"
            placeholder="お問い合わせ内容を入力してください。"
            id="contact-content"
            value={contactInput.content}
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr className="border-b-1 border-dark-gray mb-6" />
        <h2 className="text-xl color-contact-subtitle mb-4">対応履歴</h2>

        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          onKeyDown={(event) => preventFromSubmittingByEnter(event)}
          data-testid="contacts-new-page-history-form"
        >
          <div className="flex items-center space-x-3">
            <SelectCustomStyleUI
              data-testid="contacts-new-page-servicekind"
              className="w-48 mb-10"
              options={contactServiceKinds}
              id="serviceKind"
              errorBorder={errors.serviceKind ? true : false}
              value={`${
                contactServiceKinds.find(
                  (option) => option.value === contactHistoryInput.serviceKind
                )?.label
              }`}
              name="serviceKind"
              onChange={onChangeCustomSelect}
            />
          </div>
          <div className="flex items-center space-x-3 mt-4">
            <SelectCustomStyleUI
              data-testid="contacts-new-page-status"
              className="w-48 mb-10"
              options={statusTypes}
              id="status"
              errorBorder={errors.status ? true : false}
              value={`${
                statusTypes.find(
                  (status) => status.value === contactHistoryInput.status
                )?.label
              }`}
              name="status"
              onChange={onChangeCustomSelect}
            />
          </div>

          <ValidatableTextareaUI
            registerParams={{
              register,
              error: errors.memo,
              label: "memo",
              conditions: {
                maxLength: {
                  value: 400,
                  message: "４００文字以内で入力してください。",
                },
              },
            }}
            data-testid="contacts-new-page-memo"
            errorIdPrefix="contacts-new-page-memo-error"
            rows={6}
            className="mt-14 w-full px-2.5 h-40"
            placeholder="対応メモ（４００文字）"
            id="history-memo"
            value={contactHistoryInput.memo || ""}
            onChange={onChangeInput}
          />
          {errors.status && (
            <p
              className={`text-error mt-1.5 ${errors.memo ? "pl-8" : ""}`}
              data-testid="contacts-new-page-status-error-message"
            >
              対応ステータスの変更または対応履歴の記載を行ってください。
            </p>
          )}

          <div className="flex justify-center space-x-10 m-12">
            <ButtonUI
              type="button"
              data-testid="contacts-new-page-back-btn"
              buttonType="secondary"
              onClick={() => {
                history.push(`/contacts`);
              }}
            >
              戻る
            </ButtonUI>
            <ButtonUI
              type="submit"
              data-testid="contacts-new-page-register-btn"
              disabled={contactHistoryInput.status === ContactStatus.Supported}
            >
              登録
            </ButtonUI>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactsNewPage;
