import React from "react";

import dayjs from "dayjs";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import {
  ContactsTableRowFragment,
  ContactStatus,
} from "../../../../generated/graphql";
import { getRouteName, getStatusName } from "../../../../lib/utils/contacts";
import { StatusBadgeUI } from "./StatusBadge";

export type ContactsTableRowProps = {
  contact: ContactsTableRowFragment;
  index: number;
};

const ContactsTableRowUI: React.FC<ContactsTableRowProps> = ({
  contact,
  index,
}) => {
  const latestHistory =
    contact.histories && contact.histories.length > 0
      ? contact.histories[0]
      : null;
  const supporterName = latestHistory
    ? `${latestHistory.supporterFamilyName} ${latestHistory.supporterGivenName}`
    : "";

  return (
    <tr
      data-testid="contacts-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""} text-sm`}
    >
      <td className="border-dark-gray pl-5">
        <StatusBadgeUI status={latestHistory?.status}>
          {latestHistory
            ? getStatusName(latestHistory.status)
            : getStatusName(ContactStatus.Unsupported)}
        </StatusBadgeUI>
      </td>
      <td className="border-dark-gray pl-5">
        <LinkTextUI to={`/contacts/${contact.id}`}>
          {contact.systemId}
        </LinkTextUI>
      </td>
      <td className="border-dark-gray pl-5">
        {contact.customerId ? contact.customerId : "（非会員）"}
      </td>
      <td className="border-dark-gray pl-5">{contact.category?.value}</td>
      <td className="border-dark-gray pl-5">{getRouteName(contact.route!)}</td>
      <td className="border-dark-gray pl-5">
        {dayjs(contact.createdAt).format("YYYY/MM/DD")}
      </td>
      <td className="border-dark-gray pl-5">{supporterName}</td>
      <td className="border-dark-gray pl-5">
        {latestHistory && dayjs(latestHistory.createdAt).format("YYYY/MM/DD")}
      </td>
    </tr>
  );
};

export default ContactsTableRowUI;
