import React, { useState } from "react";

import { ServiceKind } from "../../generated/graphql";
import SideBarMenuItemUI, {
  SideBarMenuSubItem,
} from "../SideBarMenuItem/SideBarMenuItem";

interface SideBarMenuProps {
  onClickSubMenuItem?: () => void;
}

interface Menu {
  title: string;
  items: SideBarMenuSubItem[];
}

export const SideBarMenuUI: React.FC<SideBarMenuProps> = ({
  onClickSubMenuItem,
}) => {
  const menus: Menu[] = [
    {
      title: "Top",
      items: [],
    },
    {
      title: "設定",
      items: [
        {
          title: "教師スタッフ一覧",
          to: "/users",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "マスタ設定",
          to: "/masters",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "興味のあるカテゴリ並び替え",
          to: "/categories/sort",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "お知らせ管理",
          to: "/announcements",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "クーポン一覧",
          to: "/discounts",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "注目の動画管理",
          to: "/featuredvideos",
          availableServices: [ServiceKind.LiberalArts],
        },
      ],
    },
    {
      title: "商品",
      items: [
        {
          title: "コース一覧",
          to: "/courses",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "公開管理",
          to: "/publications",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "サービス並び替え",
          to: "/courses/sort",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "授業スケジュール",
          to: "/lessons",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "有識者一覧",
          to: "/experts",
          availableServices: [ServiceKind.LiberalArts],
        },
      ],
    },
    {
      title: "お客様情報",
      items: [
        {
          title: "個人情報照会",
          to: "/customer/search",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "月額会員照会",
          to: "/customer/billing/search",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "お問い合わせ対応",
          to: "/contacts",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "アンケート管理",
          to: "/questionnaires",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "法人管理",
          to: "/corporation",
          availableServices: [ServiceKind.LiberalArts],
        },
      ],
    },
    {
      title: "決済管理",
      items: [
        {
          title: "決済情報照会",
          to: "/payment/search",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "決済情報不整合",
          to: "/payment/mismatches",
          availableServices: [ServiceKind.LiberalArts],
        },
        {
          title: "決済情報不整合(継続課金)",
          to: "/payment/contbill/mismatches",
          availableServices: [ServiceKind.LiberalArts],
        },
      ],
    },
  ];

  const [openedIndex, setOpenedIndex] = useState<number | undefined>();

  const handleOnClickMenuItem = (open: boolean, index: number) => {
    setOpenedIndex(open ? index : undefined);
  };

  return (
    <ul>
      {menus.map((menu, index) => (
        <SideBarMenuItemUI
          key={menu.title}
          title={menu.title}
          opened={openedIndex === index}
          onClickMenuItem={(open) => handleOnClickMenuItem(open, index)}
          onClickSubMenuItem={onClickSubMenuItem}
          items={menu.items}
        ></SideBarMenuItemUI>
      ))}
    </ul>
  );
};
