import React, { HTMLAttributes } from "react";

export type TrUIProps = {
  isDragging?: boolean;
} & HTMLAttributes<HTMLTableRowElement>;

export const TrUI = React.forwardRef<HTMLTableRowElement, TrUIProps>(
  ({ className, isDragging, children, ...rest }, ref) => {
    return (
      <tr
        ref={ref}
        className={`border-b border-under-line ${className} ${
          isDragging ? "bg-light-gray" : "even:bg-light-blue"
        }`}
        {...rest}
      >
        {children}
      </tr>
    );
  }
);
