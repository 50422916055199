import React, { ChangeEventHandler } from "react";

import { CustomersForDisplay } from "..";
import { PageType } from "../../../../lib/constants/pageType";
import styles from "./CustomerIdsGrid.module.css";
import CustomerIdsGridCellUI from "./CustomerIdsGridCell";

export type CustomerIdsGridUIProps = {
  pageType?: PageType;
  customers: CustomersForDisplay;
  selectedCustomerId?: string | null;
  onSelectedCustomer?: ChangeEventHandler<HTMLInputElement>;
};

export const CustomerIdsGridUI: React.FC<CustomerIdsGridUIProps> = ({
  pageType = PageType.CustomerSearchResultsPage,
  customers,
  selectedCustomerId,
  onSelectedCustomer,
}) => {
  return (
    <div>
      <div>お客様番号</div>
      <div className={`${styles.grid}`}>
        {customers.nodes.map((customer, idx) => (
          <CustomerIdsGridCellUI
            key={`customer-search-result-${customer.id}`}
            customer={customer}
            index={idx}
            pageType={pageType}
            selectedCustomerId={selectedCustomerId}
            onSelectedCustomer={onSelectedCustomer}
          />
        ))}
      </div>
    </div>
  );
};
