import React, { useEffect, useRef, useState } from "react";

import {
  PaymentMismatchesTableRowFragment,
  PaymentMismatchResultsFragment,
} from "../../../../generated/graphql";
import PaymentsTableRowUI from "./PaymentMismatchesTableRow";

export type PaymentMismatchesTableUIProps = {
  paymentMismatches: PaymentMismatchResultsFragment;
  onWidthChange?: (width: number | undefined) => void;
};

export const PaymentMismatchesTableUI: React.FC<
  PaymentMismatchesTableUIProps
> = ({ paymentMismatches, onWidthChange }) => {
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);
  const table = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (table && onWidthChange) {
      const rect: DOMRect | undefined = table.current?.getBoundingClientRect();
      const w = rect !== undefined ? Math.ceil(rect.width) : undefined;
      if (tableWidth !== w) {
        setTableWidth(w);
        onWidthChange(w);
      }
    }
  }, [table, onWidthChange, setTableWidth, tableWidth]);

  return (
    <table
      ref={table}
      className="border-collapse border border-dark-gray"
      style={{ width: "max-content" }}
    >
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-16">
          <th className="border border-dark-gray pl-2 pr-1">対応状況</th>
          <th className="border border-dark-gray pl-2 pr-1">決済情報番号</th>
          <th className="border border-dark-gray pl-2 pr-1">検知日時</th>
          <th className="border border-dark-gray pl-2 pr-1">決済ステータス</th>
          <th className="border border-dark-gray pl-2 pr-1">不整合項目</th>
          <th className="border border-dark-gray pl-2 pr-1">最終更新日時</th>
        </tr>
      </thead>
      <tbody>
        {paymentMismatches.nodes.map(
          (
            paymentMismatch: PaymentMismatchesTableRowFragment,
            index: number
          ) => (
            <PaymentsTableRowUI
              data-testid="payment-mismatches-table-row"
              key={paymentMismatch.id}
              index={index}
              paymentMismatch={paymentMismatch}
            ></PaymentsTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
