import { Fragment, useCallback, useEffect, useState } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../components/Badge";
import { ButtonUI } from "../../../../components/Button";
import { DateTimeInputUI } from "../../../../components/DateTimeInput";
import NewFormGroupUI from "../../../../components/FormGroup/NewFormGroup";
import NewImageInputUI from "../../../../components/NewImageInput";
import { SelectCustomStyleUI } from "../../../../components/SelectCustomStyle";
import { NewValidatableInputUI } from "../../../../components/ValidatableInput";
import { NewValidatableTextareaUI } from "../../../../components/ValidatableTextarea";
import {
  CreateVideoLessonInput,
  FieldSelecterFragment,
  VideoTagSelecterFragment,
} from "../../../../generated/graphql";
import {
  getValidationUrl,
  validatePublishEndedAt,
} from "../../../../validation/regularExpressions";
import { VideoEditUI } from "../VideoEdit";
import { ReactComponent as ErrorCheckIcon } from "./errorCheck.svg";

export type VideoLessonEditFormState = {
  fieldItemImg: File | string;
} & CreateVideoLessonInput;

export type VideoLessonEditUIProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: (data: VideoLessonEditFormState) => void;
  videoTags: VideoTagSelecterFragment[];
  fields: FieldSelecterFragment[];
};
export const VideoLessonEditUI: React.VFC<VideoLessonEditUIProps> = ({
  onSubmit,
  onGoBack,
  videoTags,
  fields,
}) => {
  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<VideoLessonEditFormState>();

  const { fields: videos } = useFieldArray({
    control,
    name: "videos",
    rules: { minLength: 3, maxLength: 3 },
  });

  const [selectedVideTag, setSelectedVideTag] = useState<string>();
  const [selectedField, setSelectedField] = useState<string>();
  const [fieldItemImg, setFieldItemImg] = useState<string | File>();

  //初期化をwatchして、画面表示用のstateへ値を設定する
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name || name === "videoTagId") {
        if (!value.videoTagId) return;
        setSelectedLabel(value.videoTagId, videoTags, setSelectedVideTag);
      }
      if (!name || name === "fieldId") {
        if (!value.fieldId) return;
        setSelectedLabel(value.fieldId, fields, setSelectedField);
      }
      if (!name || name === "fieldItemImg") {
        setFieldItemImg(value.fieldItemImg);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, videoTags, fields]);

  const videoTagLabels = videoTags.map((videoTag) => {
    return { value: videoTag.id, label: videoTag.name };
  });

  const fieldLabels = fields.map((field) => {
    return { value: field.id, label: field.name };
  });

  const onChangeSelect = useCallback(
    ({ name, value }) => {
      setValue(name, value, { shouldValidate: true });

      if (name === "fieldId") {
        setSelectedLabel(value, fields, setSelectedField);
      }
      if (name === "videoTagId") {
        setSelectedLabel(value, videoTags, setSelectedVideTag);
      }
    },
    [setValue, fields, videoTags]
  );

  const setSelectedLabel = (
    id: string,
    array: Array<VideoTagSelecterFragment | FieldSelecterFragment>,
    setLabel: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    if (!id) return undefined;
    const selectedLabel = array.filter((array) => array.id === id)[0].name;
    return setLabel(selectedLabel);
  };

  return (
    <form className="pb-20" onSubmit={handleSubmit(onSubmit)}>
      <NewFormGroupUI label="シリーズタイトル" htmlFor="name" required={true}>
        <NewValidatableInputUI
          data-testid="video-lesson-name"
          {...register("name", {
            required: "シリーズタイトルは必須項目です。",
            maxLength: {
              value: 40,
              message: "40文字以内で入力してください。",
            },
          })}
          error={errors.name}
          className="w-full"
          placeholder="シリーズタイトルを入力してください"
          id="name"
        />
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI label="紹介文" htmlFor="name" required={true}>
        <NewValidatableTextareaUI
          data-testid="video-lesson-description"
          {...register("description", {
            required: "紹介文は必須項目です。",
            maxLength: {
              value: 300,
              message: "300文字以内で入力してください。",
            },
          })}
          rows={6}
          error={errors.description}
          className="w-full"
          placeholder="紹介文を入力してください"
          id="description"
          name="description"
        />
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI label="URL表記" htmlFor="alias" required={true}>
        <NewValidatableInputUI
          data-testid="video-lesson-alias"
          {...register("alias", {
            required: "URL表記は必須項目です。",
            pattern: {
              value: getValidationUrl(),
              message: "40文字以内の半角英数字,「_」で入力してください。",
            },
          })}
          error={errors.alias}
          className="w-full"
          placeholder="URL表記を入力してください"
          id="alias"
        />
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI label="分野" htmlFor="fieldId" required={true}>
        <div className="flex flex-row items-center space-x-3">
          {errors.fieldId && <ErrorCheckIcon />}
          <SelectCustomStyleUI
            placeholder="分野を選択してください"
            options={fieldLabels}
            value={selectedField}
            name="fieldId"
            id="fieldId"
            className="w-80 h-12"
            errorBorder={!!errors.fieldId}
            onChange={onChangeSelect}
            testId="video-lesson-fieldId"
          />
          <input
            className="hidden"
            {...register("fieldId", {
              required: true,
            })}
          />
        </div>
        {errors.fieldId && (
          <p
            className={`text-error  mt-1.5 ml-8`}
            data-testid="video-lesson-fieldId-error-message"
          >
            分野は必須項目です。
          </p>
        )}
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI
        label="分野から探す画像"
        htmlFor="fieldItemImg"
        required={true}
      >
        <div>
          <div className="flex flex-row">
            <NewImageInputUI
              testidPrefix="expert-edit-profile-photo"
              className="w-44 h-32"
              {...register("fieldItemImg", {
                required: "分野ラインナップカード画像は必須項目です。",
              })}
              imageConditions={{
                size: 10 * 1000 * 1000,
                px: { width: 604, height: 340 },
              }}
              error={!!errors.fieldItemImg}
              image={fieldItemImg}
            />
            <p className="mt-4 ml-4 text-xs text-dark-gray">
              アップロード可能なファイルサイズは最大10MBです
              <br /> 604px × 340pxの画像を指定してください
            </p>
          </div>
          {errors.fieldItemImg && (
            <p
              data-testid="expert-edit-profile-photo-error-message"
              className="mt-2.5 text-error"
            >
              {errors.fieldItemImg.message}
            </p>
          )}
        </div>
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI label="動画タグ" htmlFor="videoTagId" required={true}>
        <div className="flex flex-row items-center space-x-3">
          {errors.videoTagId && <ErrorCheckIcon />}
          <SelectCustomStyleUI
            placeholder="動画タグを選択してください"
            options={videoTagLabels}
            value={selectedVideTag}
            id="videoTagId"
            name="videoTagId"
            className="w-80 h-12"
            errorBorder={!!errors.videoTagId}
            onChange={onChangeSelect}
            testId="video-lesson-videoTagId"
          />
        </div>
        <input
          className="hidden"
          {...register("videoTagId", {
            required: true,
          })}
        />
        {errors.videoTagId && (
          <p
            className={`text-error  mt-1.5 ml-8`}
            data-testid="video-lesson-videoTag-error-message"
          >
            カテゴリは必須項目です。
          </p>
        )}
      </NewFormGroupUI>
      <hr />
      <NewFormGroupUI
        label="サンプル動画ID"
        htmlFor="sampleVideoId"
        required={true}
      >
        <NewValidatableInputUI
          id="sampleVideoId"
          data-testid="video-lesson-sample-video-id"
          placeholder="サンプル動画IDを入力してください"
          {...register("jStreamSampleMId", {
            required: "サンプル動画IDは必須項目です。",
            maxLength: {
              value: 100,
              message: "100文字以内で入力してください。",
            },
          })}
          error={errors.jStreamSampleMId}
          className="w-full"
        />
      </NewFormGroupUI>
      <hr />
      {videos.map((_video, index) => (
        <Fragment key={index}>
          <VideoEditUI index={index}></VideoEditUI>
          <hr />
        </Fragment>
      ))}
      <NewFormGroupUI label="公開設定" required={true}>
        <div>
          <div className="flex flex-row items-center">
            <BadgeUI className="mr-4">必須</BadgeUI>
            <DateTimeInputUI
              testidPrefix="video-lesson-published-at"
              icon={true}
              {...register("publishedAt", {
                required: "公開開始日時は必須項目です。",
              })}
              error={!!errors.publishedAt}
            />
            <div className="ml-4">〜</div>
          </div>
          {errors.publishedAt?.message && (
            <p
              data-testid="expert-edit-published-at-error-message"
              className="ml-20 mt-2.5 text-sm text-error"
            >
              {errors.publishedAt.message}
            </p>
          )}
        </div>

        <div className="mt-3">
          <div className="flex flex-row items-center">
            <BadgeUI type="optional" className="mr-4">
              任意
            </BadgeUI>
            <DateTimeInputUI
              testidPrefix="video-lesson-closed-at"
              icon={true}
              {...register("closedAt", {
                validate: (value) =>
                  validatePublishEndedAt(getValues("publishedAt"), value),
              })}
              error={!!errors.closedAt}
            />
          </div>
          {errors.closedAt?.message && (
            <p
              data-testid="expert-edit-closed-at-error-message"
              className="ml-20 mt-2.5 text-sm text-error"
            >
              {errors.closedAt.message}
            </p>
          )}
        </div>
      </NewFormGroupUI>
      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI
          type="button"
          data-testid="video-lesson-back"
          buttonType="secondary"
          onClick={onGoBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI type="submit" data-testid="video-lesson-submit">
          確認
        </ButtonUI>
      </div>
    </form>
  );
};
