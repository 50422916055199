import { useCallback, useEffect, useRef, useState } from "react";

import { Organizer } from "../../generated/graphql";
import { SelectCustomStyleUI, SelectOption } from "../SelectCustomStyle";

export interface OrganizerSelectorProps {
  placeholder?: string;
  id?: string;
  name: string;
  className?: string;
  value?: string;
  defaultOrganizer?: Organizer;
  errorBorder?: boolean;
  organizers: Organizer[];
  onOrganizerChange: (organizer: Organizer | undefined) => void;
}

export const OrganizerSelector: React.VFC<OrganizerSelectorProps> = ({
  id,
  name,
  placeholder = "選択項目",
  className,
  value,
  defaultOrganizer,
  errorBorder,
  organizers,
  onOrganizerChange,
  ...rest
}) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const organizersRef = useRef<Organizer[]>([]);
  const [organizerOptions, setOrganizerOptions] = useState<SelectOption[]>([]);
  const [responseOrganizers, setResponseOrganizers] = useState<Organizer[]>([]);
  const [selectedOrganizer, setSelectedOrganizer] = useState<string>("");

  useEffect(() => {
    if (organizersRef.current !== organizers) {
      organizersRef.current = organizers;
      setInitialized(false);
    }
  }, [organizers, onOrganizerChange]);

  useEffect(() => {
    if (!initialized) {
      setResponseOrganizers(organizers);
      const options: SelectOption[] = organizers.map((organizer: Organizer) => {
        if (defaultOrganizer && defaultOrganizer.id === organizer.id) {
          setSelectedOrganizer(defaultOrganizer.name);
        }
        return {
          label: organizer.name,
          value: organizer.id,
        };
      });
      options.unshift({
        label: "",
        value: "",
      });
      setOrganizerOptions(options);
      setInitialized(true);
    }
  }, [defaultOrganizer, initialized, organizers]);

  const onSelectionChange = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (!value) {
        onOrganizerChange(undefined);
        setSelectedOrganizer("");
        return;
      }
      for (const organizer of responseOrganizers) {
        if (organizer.id === value) {
          onOrganizerChange(organizer);
          setSelectedOrganizer(organizer.name);
          return;
        }
      }
    },
    [onOrganizerChange, responseOrganizers]
  );

  return (
    <SelectCustomStyleUI
      placeholder={placeholder}
      options={organizerOptions}
      name="organizer"
      className={className}
      id={id}
      value={selectedOrganizer}
      errorBorder={errorBorder}
      onChange={onSelectionChange}
      {...rest}
    />
  );
};

export const ORGANIZER_SELECTOR_QUERY_VARIABLES = {
  offset: 0,
  limit: 2 ** 31 - 1, // 2147483647 32 bit signed integer
};
