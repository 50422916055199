import React, { useState, useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";

import {
  useUpdateUserMutation,
  useGenerateUploadAvatorUrlMutation,
  UserDetailFragment,
  UsersEditPageFragment,
  ServiceInputFragment,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { UserEdit } from "../../../lib/types";
import UsersConfirmPage from "./UsersConfirmPage";

const confirmErrorModalDescriptionForNormal = `教師スタッフの情報登録に失敗しました。
  しばらく時間を置いてから、もう一度お試しください。`;
const confirmErrorModalDescriptionForRoles = `教師スタッフの情報登録に失敗しました。
  ステータス有効な管理者が0名となる変更となっています。
  1名以上となるようにしてください。`;

const UsersConfirmPageContainer = () => {
  const history = useHistory();
  const [updateUser] = useUpdateUserMutation();
  const [generateUploadAvatorUrl] = useGenerateUploadAvatorUrlMutation();
  const location = useLocation<{
    userEdit: UserEdit;
    user: UserDetailFragment | UsersEditPageFragment;
    from?: string;
    services?: ServiceInputFragment[];
  }>();
  const { user, userEdit, services } = location.state;
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalDescription, setConfirmErrorModalDescription] =
    useState<string>(confirmErrorModalDescriptionForNormal);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const uploadAvator = useCallback(async (): Promise<void> => {
    const presignedPostResult = await generateUploadAvatorUrl({
      variables: {
        userId: user.id,
        fileName: userEdit.avator?.name as string,
      },
    });
    const presignedPostData = presignedPostResult.data?.generateUploadAvatorUrl;

    if (!presignedPostData) {
      return;
    }
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      formData.append("file", userEdit.avator!);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${presignedPostData.url}`, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  }, [generateUploadAvatorUrl, user.id, userEdit.avator]);

  const handleUpdate = useCallback(async () => {
    const {
      userId,
      familyName,
      givenName,
      avator,
      yearsOfTeaching,
      extra,
      ...input
    } = userEdit;
    try {
      await updateUser({
        variables: {
          input: {
            ...input,
            // UserEditとUserInputでid, email, roleIdsがnullableかどうかの型不一致がある
            id: input.id!,
            email: input.email!,
            roleIds: input.roleIds!,
            yearsOfTeaching: yearsOfTeaching ? +yearsOfTeaching : null,
            serviceKindIds: userEdit.serviceKindIds
              ? userEdit.serviceKindIds
              : [],
          },
        },
      });
      if (avator) {
        await uploadAvator();
      }
      setIsOpenCompleteModal(true);
    } catch (error: any) {
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UpdateUserFailedRoles) {
        setConfirmErrorModalDescription(confirmErrorModalDescriptionForRoles);
      } else if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      setConfirmErrorModalVisibility(true);
    }
  }, [history, userEdit, updateUser, uploadAvator, setIsOpenCompleteModal]);

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
  };

  const clearErrorModal = () => {
    confirmErrorModalDescription &&
      setConfirmErrorModalDescription(confirmErrorModalDescriptionForNormal);
    confirmErrorModalVisibility && setConfirmErrorModalVisibility(false);
  };

  return (
    <UsersConfirmPage
      user={user}
      userEdit={userEdit}
      from={location?.state?.from}
      confirmModalVisibility={confirmModalVisibility}
      isOpenCompleteModal={isOpenCompleteModal}
      confirmErrorModalDescription={confirmErrorModalDescription}
      confirmErrorModalVisibility={confirmErrorModalVisibility}
      handleUpdate={handleUpdate}
      clearCompletedModal={clearCompletedModal}
      clearErrorModal={clearErrorModal}
      setConfirmModalVisibility={setConfirmModalVisibility}
      services={services}
    />
  );
};

export default UsersConfirmPageContainer;
