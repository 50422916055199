import React from "react";

import { useParams } from "react-router";

import { LoadingUI } from "../../../components/Loading";
import { useAnnouncementPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import AnnouncementPage from "./AnnouncementPage";

const AnnouncementPageContainer: React.VFC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const handleErrorRouter = useErrorRouter();
  const { data, loading } = useAnnouncementPageQuery({
    variables: { id },
    onError: handleErrorRouter,
    fetchPolicy: "no-cache",
  });

  if (loading || !data) {
    return <LoadingUI title="取得中" />;
  }

  return <AnnouncementPage data={data.announcement} />;
};

export default AnnouncementPageContainer;
