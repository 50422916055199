import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import {
  Discount,
  DiscountsQuery,
  useDeleteDiscountMutation,
  useDiscountsQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { discountsSearchSettings } from "../../../lib/constants";
import DiscountDeleteFailedModalUI from "../components/modals/DiscountDeleteFailedModal";
import DiscountDeleteModalUI from "../components/modals/DiscountDeleteModal";
import DiscountsPage from "./DiscountsPage";

/**
 * クーポン一覧画面（index）
 * @returns
 */
const DiscountsPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const history = useHistory();
  // discounts クエリー関連
  const [isReady, setIsReady] = useState<boolean>(false);
  const [limit, setLimit] = useState(discountsSearchSettings.limit);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dataList, setDataList] = useState<Discount[]>([]);
  // 削除確認モーダル表示
  const [deletingModalVisibility, setDeletingModalVisibility] =
    useState<boolean>(false);
  // 削除失敗時の確認モーダル表示
  const [deleteFailedModalVisibility, setDeleteFailedModalVisibility] =
    useState<boolean>(false);

  // 初回の discounts クエリーを実行
  const { refetch } = useDiscountsQuery({
    variables: {
      offset: 0,
      limit: discountsSearchSettings.limit,
    },
    onCompleted: (ret: DiscountsQuery) => {
      setDataList(ret.discounts.nodes as Discount[]);
      setTotalCount(ret.discounts.totalCount);
      setIsReady(true);
    },
    onError: errorRouter,
    fetchPolicy: "no-cache",
  });

  // 『もっと表示する』のクリック時のハンドラー
  const onDisplayMore = useCallback(async () => {
    setLimit(limit + discountsSearchSettings.offset);
    await refetch({
      offset: 0,
      limit: limit + discountsSearchSettings.offset,
    });
  }, [limit, refetch]);

  // 削除しよとしている discount.id
  const [deletingDiscountId, setDeletingDiscountId] = useState<string | null>(
    null
  );
  // 削除アイコンのクリックハンドラー
  const onDeletingClick = useCallback((id: string) => {
    setDeletingDiscountId(id);
    setDeletingModalVisibility(true);
  }, []);
  // deleteDiscount ミューテーション
  const [deleteMutation] = useDeleteDiscountMutation({
    onError: (err) => {
      if (
        !err.graphQLErrors ||
        err.graphQLErrors.length === 0 ||
        !err.graphQLErrors[0].extensions
      ) {
        errorRouter(err);
        return;
      }
      const { code } = err.graphQLErrors[0].extensions;
      if (code === "DELETE_DISCOUNT_FAILED_BECAUSE_DISCOUNT_WAS_USED") {
        // 既に注文で使用されていた場合はダイアログでその旨を伝える
        setDeleteFailedModalVisibility(true);
      } else {
        errorRouter(err);
      }
      return;
    },
    onCompleted: (res) => {
      setLimit(discountsSearchSettings.limit);
      refetch({
        offset: 0,
        limit: discountsSearchSettings.limit,
      });
    },
  });
  // 削除確認-->削除ボタンのクリック・ハンドラー
  const onDeletingConfirm = useCallback(async () => {
    if (deletingDiscountId === null) {
      return;
    }
    await deleteMutation({
      variables: {
        id: deletingDiscountId,
      },
    });
    setDeletingDiscountId(null);
    setDeletingModalVisibility(false);
  }, [deleteMutation, deletingDiscountId]);
  // 削除確認-->キャンセル・ボタンのクリック・ハンドラー
  const onDeletingCancel = useCallback(() => {
    setDeletingModalVisibility(false);
  }, []);
  // 削除失敗確認ハンドラー（割引が注文で使用されている場合のみ）
  const onFailedConfirm = useCallback(() => {
    setDeleteFailedModalVisibility(false);
  }, []);

  // 編集ボタンのクリック・ハンドラー
  const onEditClick = useCallback(
    (id: string) => {
      history.push(`/discounts/${id}/edit`);
    },
    [history]
  );

  // レンダリング
  return !isReady ? (
    <LoadingUI title="検索中" />
  ) : (
    <>
      <DiscountsPage
        totalCount={totalCount}
        dataList={dataList}
        onDisplayMore={onDisplayMore}
        onDeleting={onDeletingClick}
        onEditing={onEditClick}
      />
      {deletingModalVisibility ? (
        <DiscountDeleteModalUI
          title="削除確認"
          message="本当にクーポンを削除しますか？<br/>削除すると二度と戻せません。"
          onConfirm={onDeletingConfirm}
          onCancel={onDeletingCancel}
        />
      ) : (
        <></>
      )}
      {deleteFailedModalVisibility ? (
        <DiscountDeleteFailedModalUI
          title="削除失敗"
          message="既に注文に使用されているため<br/>削除できませんでした。"
          onConfirm={onFailedConfirm}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default DiscountsPageContainer;
