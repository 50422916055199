import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";

import { ButtonUI } from "../../../../components/Button";
import { ClazzItemFragment } from "../../../../generated/graphql";
// TODO: https://agile.kddi.com/jira/browse/STUDYPF-3336着手時にコメントアウト解除する
// import { ReactComponent as CopyIcon } from "./copy.svg";
// import { ReactComponent as CopyBlueIcon } from "./copyBlue.svg";
import { useQueryParams } from "../../../../hooks/query";
import styles from "./CoursesItem.module.scss";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as MenuBlueIcon } from "./menuBlue.svg";
export type ClazzesItemProps = {
  clazz: ClazzItemFragment;
};

type teachersArray = {
  __typename?: "User";
  familyName: string;
  givenName: string;
}[];

const ClazzesItemUI: React.FC<ClazzesItemProps> = ({ clazz }) => {
  const [participatnsCount, setParticipatnsCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);
  const [firstDate, setFirstDate] = useState("");
  const [teachers, setTeachers] = useState("");
  const history = useHistory();
  const location = useLocation();
  const params = useQueryParams();

  useEffect(() => {
    let teachersArray: teachersArray = [];
    clazz.lessons?.forEach((lesson) => {
      teachersArray.push(...lesson.teachers);
    });
    const filteredTeachers = teachersArray.reduce((arr: string[], curr, i) => {
      if (teachersArray.indexOf(curr) === i) {
        arr.push(`${curr.familyName} ${curr.givenName}`);
      }
      return arr;
    }, []);
    setTeachers(filteredTeachers.join("、"));

    if (clazz.lessons?.length) {
      const startedAt = dayjs(clazz?.lessons[0].startedAt!).format(
        "YYYY/MM/DD HH:mm"
      );
      const endedAt = dayjs(clazz?.lessons[0].endedAt!).format("HH:mm");
      let date = startedAt + "~" + endedAt;

      if (clazz.lessons?.length > 1) {
        date += " etc";
      }

      setFirstDate(date);
    }
    let particiCount = 0;
    if (clazz?.lessons) {
      particiCount += Number(clazz?.lessons[0].participantsCount);
    }
    setParticipatnsCount(particiCount);

    setCanceledCount(Number(clazz.canceledCount));
  }, [clazz, clazz.lessons]);

  const gotoClassPage = useCallback(() => {
    if (location.pathname === "/courses") {
      // コース一覧画面周りはブラウザバックを可能にするために URL パラメータで制御する
      // コース一覧画面に戻った際に当クラスが含まれるコースのクラス一覧を表示した状態にするために
      // URL パラメータにコース ID とクラス ID をセットする
      params.set("courseId", clazz.courseId);
      params.set("classId", clazz.id);
      history.replace(
        location.pathname + "?" + params.toString(),
        location.state
      );
    }
    history.push({
      pathname: `/courses/${clazz.courseId}/classes/${clazz.id}`,
    });
  }, [
    clazz.courseId,
    clazz.id,
    history,
    location.pathname,
    location.state,
    params,
  ]);

  return (
    <>
      <div className="border-b-2 pb-4">
        <div className="flex w-full mt-6">
          <div className="flex-none flex-shrink-0 w-7/12">
            <div className="flex flex-col">
              <div className="flex flex-row" id={`clazzes-clazzId-${clazz.id}`}>
                <div className="pl-3">
                  <h3
                    className="text-lg tracking-wider leading-none mb-3"
                    data-testid="clazz-item-system-id"
                  >
                    (システムID: {clazz.systemId})
                  </h3>
                  <p className="mb-1">
                    <span className="inline-block w-24">クラス名</span>
                    <span>:</span>
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-name"
                    >
                      {clazz.name}
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="inline-block w-24">クラスID</span>
                    <span>:</span>
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-clazz-id"
                    >
                      {clazz.anyClassId}
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="inline-block w-24">教師名</span>
                    <span>:</span>
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-teachers"
                    >
                      {teachers}
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="inline-block w-24">実績</span>
                    <span>:</span>
                    {participatnsCount >= clazz.capacity && (
                      <span
                        className="text-red pl-3"
                        data-testid="clazz-item-full-badge"
                      >
                        満員
                      </span>
                    )}
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-capacity"
                    >
                      {participatnsCount} / {clazz.capacity} 名
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="inline-block w-24">キャンセル数</span>
                    <span>:</span>
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-canceled-count"
                    >
                      {canceledCount} 名
                    </span>
                  </p>
                  <p className="mb-1">
                    <span className="inline-block w-24">日程</span>
                    <span>:</span>
                    <span
                      className="inline-block pl-3"
                      data-testid="clazz-item-first-date"
                    >
                      {firstDate}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full text-right flex justify-end">
          {/* TODO:
          下記チケット着手時にコメントアウトを解除する
          [1b-1][コース一覧画面] 複製ボタンを押下するとクラスが複製できる
          https://agile.kddi.com/jira/browse/STUDYPF-3336
          */}
          {/* <ButtonUI
            to={`/courses/${clazz.courseId}/class/new`}
            size="xs"
            className={`inline-flex items-center justify-items-center justify-center mr-4 ${styles.btnDetail}`}
          >
            <CopyIcon className={`icon mr-3 ${styles.icon}`} />
            <CopyBlueIcon className={`mr-3 ${styles.iconActive}`} />
            複製
          </ButtonUI> */}
          <ButtonUI
            size="xs"
            className={`inline-flex items-center justify-items-center justify-center ${styles.btnDetail}`}
            onClick={gotoClassPage}
          >
            <MenuIcon className={`icon mr-3 ${styles.icon}`} />
            <MenuBlueIcon className={`mr-3 ${styles.iconActive}`} />
            クラス詳細
          </ButtonUI>
        </div>
      </div>
    </>
  );
};

export default ClazzesItemUI;
