import React from "react";

import { ButtonUI } from "../../../../components/Button";
import { ModalUI } from "../../../../components/Modal";
import CautionMark from "./deleteCaution.svg";
import styles from "./DiscountDeleteFailedModal.module.css";

export type DiscountDeleteFailedModalUIProps = {
  title: string;
  message: string;
  onConfirm: () => void;
};

const DiscountDeleteFailedModalUI: React.FC<
  DiscountDeleteFailedModalUIProps
> = ({ title, message, onConfirm }) => {
  return (
    <ModalUI visibility={true} className={`${styles.box}`}>
      <div className={`flex flex-col mt-6 mb-6 items-center h-full`}>
        <img src={CautionMark} alt="警告" width="30px" height="30px" />
        <div
          className={`${styles.title} mt-3`}
          data-testid="discount-deleteFailed-modal-title"
        >
          {title}
        </div>
        <hr className={`${styles.titleLine} mt-4 mb-4`} />
        <div
          className={`${styles.message}  mb-4`}
          data-testid="discount-deleteFailed-modal-message"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></div>
        <div className="flex h-full">
          <ButtonUI
            data-testid="discount-deleteFailed-modal-btn-confirm"
            buttonType="primary"
            className={`${styles.okButton} self-end`}
            onClick={onConfirm}
          >
            OK
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default DiscountDeleteFailedModalUI;
