import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
} from "react";

import { useBreadcrumb, Breadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { PaginationUI } from "../../../components/Pagination";
import { ExpertsPageQuery } from "../../../generated/graphql";
import { ReactComponent as Plus } from "../../../images/plus.svg";
import { SearchInputUI } from "../../masters/MastersPage/components/SearchInput/SearchInput";
import ExpertCard from "./components/ExpertCard/ExpertCard";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "有識者一覧",
  },
];

export type ExpertsPageProps = {
  data?: ExpertsPageQuery;
  offset: number;
  limit: number;
  searchWord: string;
  onClickDelete: MouseEventHandler;
  onChangeSearchWord: ChangeEventHandler<HTMLInputElement>;
  onSearch: FormEventHandler;
  onClickPrevios: () => void;
  onClickNext: () => void;
  onClickPaginationItem: (index: number) => void;
};

const ExpertsPage: React.VFC<ExpertsPageProps> = ({
  data,
  limit,
  offset,
  searchWord,
  onChangeSearchWord,
  onSearch,
  onClickDelete,
  onClickPrevios,
  onClickNext,
  onClickPaginationItem,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const experts = data?.experts.nodes ?? [];
  const totalCount = data?.experts.totalCount ?? 0;
  return (
    <div className="pb-44">
      <h1 className="text-2xl">有識者一覧</h1>
      <hr className="border-gray border mt-3" />
      <form onSubmit={onSearch}>
        <SearchInputUI
          className="mt-7"
          placeholder="有識者名を入力"
          value={searchWord}
          onChange={onChangeSearchWord}
          onClickSearch={onSearch}
          onClickDelete={onClickDelete}
        />
      </form>
      <div className="mt-7 flex justify-end">
        <ButtonUI
          className="flex flex-row space-x-2 justify-center items-center mr-5"
          size="xs"
          to="/experts/new"
          data-testid="to-expert-new"
        >
          <Plus className="fill-current w-4 text-white" />
          <span>有識者追加</span>
        </ButtonUI>
      </div>

      {totalCount > 0 ? (
        <div>
          <p>
            全{totalCount}件中{offset + 1}件〜
            {offset + experts.length}
            件を表示
          </p>
          <hr className="border-gray border mt-3" />
          <div className="mt-5">
            {data?.experts.nodes.map((expert) => (
              <ExpertCard expert={expert} key={expert.id} />
            ))}
          </div>
          <PaginationUI
            className="mt-1"
            selectedIndex={Math.ceil(offset / limit)}
            count={Math.ceil(totalCount / limit)}
            onClickPrevios={onClickPrevios}
            onClickNext={onClickNext}
            onClickItem={onClickPaginationItem}
          />
        </div>
      ) : (
        <div
          className="text-center text-lg font-bold py-4 mt-6 bg-light-gray"
          data-testid="no-experts"
        >
          <p>まだ有識者が登録されていません。</p>
          <p>「有識者追加」ボタンから追加してください。</p>
        </div>
      )}
    </div>
  );
};

export default ExpertsPage;
