import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  MouseEventHandler,
} from "react";

import dayjs from "dayjs";
import { SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import TeachersListModalUI from "../../../components/TeachersListModal/TeachersListModal";
import {
  useCourseForCreatingClassQuery,
  useTeachersLazyQuery,
  TeachersTableRowFragment,
  LocationType,
} from "../../../generated/graphql";
import { eventLocations } from "../../../lib/constants";
import {
  Teacher,
  DisplayLesson,
  DisplayCourse,
  DisplayClass,
} from "../../../lib/constants/classes";
import { ErrorType } from "../../../lib/constants/error";
import ClassNewPage from "./ClassNewPage";

type ClassPageParams = {
  id: string;
};

const ClassNewPageContaier = () => {
  const { id } = useParams<ClassPageParams>();
  const history = useHistory();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [selectedLesson, setSelectedLesson] = useState<DisplayLesson>({
    lessonMasterId: "",
    locationType: LocationType.Zoom,
    lessonMaster: {
      id: "",
      order: 1,
      name: "",
    },
    teachers: [],
  });

  const [course, setCourse] = useState<DisplayCourse>({
    systemId: "",
    name: "",
  });

  const [clazzInput, setClazzInput] = useState<DisplayClass>({
    courseId: "",
    name: "",
    anyClassId: "",
    eventLocation: "",
    capacity: 0,
    applicantCountVisibility: false,
    lessons: [],
    autoCreateZoomURL: false,
  });

  const {
    data,
    error: courseResultError,
    loading,
  } = useCourseForCreatingClassQuery({
    variables: { id },
  });

  const onHandleSubmit: SubmitHandler<DisplayClass> = useCallback(() => {
    history.push(`/courses/${clazzInput.courseId}/class/confirm`, {
      course,
      clazzInput,
    });
  }, [course, clazzInput, history]);

  const [teacherListModalVisibility, setTeacherListModalVisibility] =
    useState<boolean>(false);
  const [fetchTeachers, { data: teachersData, error: teachersResultError }] =
    useTeachersLazyQuery();
  const onOpenTeacherListModal = useCallback(
    (selectedLesson: DisplayLesson) => {
      const selectedTeacherIds =
        selectedLesson.teachers.map((teacher: Teacher) => teacher.id) || [];

      const newTeachers = teachers.map((teacher: Teacher) => {
        const selected = selectedTeacherIds.includes(teacher.id);
        return {
          ...teacher,
          selected,
        };
      });
      setTeachers(newTeachers);
      setSelectedLesson(selectedLesson);
      setTeacherListModalVisibility(true);
    },
    [teachers]
  );
  const [searchFilter, setSearchFilter] = useState("");

  const onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchFilter(event.target.value);
  };

  const onClickDeleteHandler: MouseEventHandler = () => {
    setSearchFilter("");
  };

  const setTeachersToLesson = useCallback(
    (selectedLesson: DisplayLesson, selectedTeachers: Teacher[]) => {
      const lessons = clazzInput.lessons.map((lesson: DisplayLesson) => {
        return lesson.lessonMasterId === selectedLesson.lessonMasterId
          ? {
              ...lesson,
              teachers: selectedTeachers,
            }
          : lesson;
      });
      setClazzInput({
        ...clazzInput,
        lessons,
      });
    },
    [clazzInput]
  );

  const onChangeCheckedTeachers = useCallback(
    (selectedLesson: DisplayLesson, teachers: Teacher[]) => {
      setTeachersToLesson(
        selectedLesson,
        teachers.filter((teacher: Teacher) => teacher.selected === true)
      );
      setTeachers(teachers);
    },
    [setTeachersToLesson]
  );

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setClazzInput({
        ...clazzInput,
        [event.target.name]: event.target.value,
      });
    },
    [clazzInput]
  );

  const onChangeLessonInputs = useCallback(
    (lessons: DisplayLesson[]) => {
      setClazzInput({
        ...clazzInput,
        lessons,
      });
    },
    [clazzInput]
  );

  const onChangeRadioType: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      switch (event.target.name) {
        case "applicantCountVisibility":
          setClazzInput({
            ...clazzInput,
            applicantCountVisibility: event.target.value === "1" ? true : false,
          });
          break;
        default:
          setClazzInput({
            ...clazzInput,
            [event.target.name]: event.target.value,
          });
      }
    },
    [clazzInput]
  );

  const onRemoveTeacher = useCallback(
    (lesson: DisplayLesson, teacherId: string) => {
      setTeachersToLesson(
        lesson,
        lesson.teachers.filter((teacher: Teacher) => teacher.id !== teacherId)
      );
    },
    [setTeachersToLesson]
  );

  const onSearchTeachers = useCallback(
    async (searchWord: string) => {
      fetchTeachers({
        variables: {
          filter: {
            searchWord,
          },
        },
      });
    },
    [fetchTeachers]
  );

  const onSelectedTeachers = useCallback(
    (selectedLesson: DisplayLesson, selectedTeachers: Teacher[]) => {
      setTeachersToLesson(selectedLesson, selectedTeachers);
      setTeacherListModalVisibility(false);
    },
    [setTeachersToLesson]
  );

  const onChangeAutoCreateZoomURL = useCallback(
    (required: boolean) => {
      setClazzInput({
        ...clazzInput,
        autoCreateZoomURL: required,
      });
    },
    [clazzInput]
  );

  const onCancelSelectedTeachers = useCallback(
    (selectedLesson: DisplayLesson, cancelTeacherIds: string[]) => {
      setTeacherListModalVisibility(false);
      const newTeachers = teachers.map((teacher: Teacher) => {
        return cancelTeacherIds.includes(teacher.id)
          ? {
              ...teacher,
              selected: !teacher.selected,
            }
          : teacher;
      });
      setTeachersToLesson(
        selectedLesson,
        newTeachers.filter((teacher: Teacher) => teacher.selected === true)
      );
      setTeachers(newTeachers);
      setSearchFilter((prev) => prev);
    },
    [teachers, setTeachersToLesson]
  );

  useEffect(() => {
    const course = data?.course;
    if (!course) {
      return;
    }

    setCourse({
      systemId: course.systemId,
      name: course.name,
    });

    const lessons: DisplayLesson[] = course.lessonMasters?.map((l) => ({
      anyLessonId: "",
      lessonMasterId: l.id,
      lessonMaster: l,
      startedAt: dayjs().startOf("hour").toDate(),
      endedAt: dayjs().add(1, "hour").startOf("hour").toDate(),
      locationType: LocationType.Zoom,
      lessonUrl: "",
      teachers: [],
    }))!;

    setClazzInput({
      ...clazzInput,
      courseId: course.id,
      eventLocation: eventLocations.zoom,
      lessons: lessons ? lessons : [],
    });
    // eslint-disable-next-line
  }, [data?.course, setCourse, setClazzInput]);

  useEffect(() => {
    if (!teachersData?.teachers) {
      setTeachers([]);
      return;
    }
    const selectedTeacherIds =
      selectedLesson?.teachers.map((teacher: Teacher) => teacher.id) || [];
    const newTeachers: Teacher[] = teachersData.teachers.nodes.map(
      (teacher: TeachersTableRowFragment) => {
        const foundTeacher = teachers.find((t) => t.id === teacher.id);
        if (foundTeacher) {
          const selected = selectedTeacherIds.includes(teacher.id);
          return { ...foundTeacher, selected };
        } else {
          return {
            id: teacher.id,
            familyName: teacher.familyName,
            givenName: teacher.givenName,
            userId: teacher.userId,
            selected: false,
            extra: teacher.extra,
          };
        }
      }
    )!;
    setTeachers(newTeachers);
    // eslint-disable-next-line
  }, [teachersData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (courseResultError || !data) {
    console.error(courseResultError);
    const errCode = courseResultError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  } else if (teachersResultError) {
    console.error(teachersResultError);
    const errCode = teachersResultError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <>
      <ClassNewPage
        course={course}
        clazzInput={clazzInput}
        onChangeInput={onChangeInput}
        onChangeLessonInputs={onChangeLessonInputs}
        onChangeRadioType={onChangeRadioType}
        onOpenTeacherListModal={onOpenTeacherListModal}
        onHandleSubmit={onHandleSubmit}
        onRemoveTeacher={onRemoveTeacher}
        onChangeAutoCreateZoomURL={onChangeAutoCreateZoomURL}
      />
      <TeachersListModalUI
        selectedLesson={selectedLesson}
        teachers={teachers}
        visibility={teacherListModalVisibility}
        onSearchTeachers={onSearchTeachers}
        onChangeCheckedTeachers={onChangeCheckedTeachers}
        onSelectedTeachers={onSelectedTeachers}
        onCancel={onCancelSelectedTeachers}
        searchFilter={searchFilter}
        onChangeSearchFilterHandler={onChangeSearchFilterHandler}
        onClickDeleteHandler={onClickDeleteHandler}
      />
    </>
  );
};

export default ClassNewPageContaier;
