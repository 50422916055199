import React from "react";

import { ButtonUI } from "../../../../components/Button";
import { ModalUI } from "../../../../components/Modal";
import CautionMark from "./deleteCaution.svg";
import styles from "./DiscountDeleteModal.module.css";

export type DiscountDeleteModalUIProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const DiscountDeleteModalUI: React.FC<DiscountDeleteModalUIProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalUI visibility={true} className={`${styles.box}`}>
      <div className={`flex flex-col mt-6 mb-6 items-center h-full`}>
        <img src={CautionMark} alt="警告" width="30px" height="30px" />
        <div
          className={`${styles.title} mt-3`}
          data-testid="discount-delete-modal-title"
        >
          {title}
        </div>
        <hr className={`${styles.titleLine} mt-4 mb-4`} />
        <div
          className={`${styles.message} mb-4`}
          data-testid="discount-delete-modal-message"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <div className="flex flex-row h-full space-x-5">
          <ButtonUI
            data-testid="discount-delete-modal-btn-cancel"
            buttonType="secondary"
            className={`${styles.cancelButton} self-end`}
            onClick={onCancel}
          >
            閉じる
          </ButtonUI>
          <ButtonUI
            data-testid="discount-delete-modal-btn-delete"
            buttonType="primary"
            className={`${styles.okButton} self-end`}
            onClick={onConfirm}
          >
            削除する
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default DiscountDeleteModalUI;
