import React, {
  useState,
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useCallback,
} from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../components/Breadcrumb";
import { ButtonUI } from "../../components/Button";
import { ReactComponent as SmallPlusIcon } from "../../components/Button/small-plus.svg";
import { PaginationUI } from "../../components/Pagination";
import { useUsersQuery } from "../../generated/graphql";
import { ErrorType } from "../../lib/constants/error";
import UsersSearchInputUI from "./components/UsersSearchInput";
import UsersTableUI from "./components/UsersTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "教師スタッフ一覧",
  },
];

export type UsersPageProos = {
  limit?: number;
};

const UsersPage: React.FC<UsersPageProos> = ({ limit = 20 }) => {
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [extra, setExtra] = useState(false);
  const [offset, setOffset] = useState(0);
  const onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchFilter(event.target.value);
  };

  const { data, error, refetch } = useUsersQuery({
    variables: {
      filter: {
        searchWord,
        extra,
      },
      offset,
      limit,
    },
    fetchPolicy: "no-cache",
  });
  const search: FormEventHandler = async (event) => {
    event.preventDefault();
    setSearchWord(searchFilter);
    setOffset(0);
    await refetch({
      filter: {
        searchWord: searchFilter,
        extra,
      },
      offset: 0,
      limit,
    });
  };
  const onClickDeleteHandler: MouseEventHandler = () => {
    setSearchFilter("");
    setSearchWord("");
    setOffset(0);
  };
  const onClickPrevios = () => {
    setOffset(offset - limit);
  };
  const onClickNext = () => {
    setOffset(offset + limit);
  };
  const onClickPaginationItem = (index: number) => {
    setOffset(index * limit);
  };

  const onChangeExtra = useCallback(
    async (extra: boolean) => {
      setExtra(extra);
      setSearchWord("");
      setSearchFilter("");
      setOffset(0);
      await refetch({
        filter: {
          searchWord: searchFilter,
          extra,
        },
        offset: 0,
        limit,
      });
    },
    [limit, searchFilter, refetch]
  );

  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  if (error) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  const users = data ? data.users : { totalCount: 0, nodes: [] };
  return (
    <div>
      <h1 className="text-2xl mb-3">教師スタッフ一覧</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-12 mt-6 mb-9">
        <div className="flex justify-start space-x-5 w-full mb-4">
          <ButtonUI
            data-testid="users-page-internal-teachers-btn"
            buttonType={!extra ? "selecting" : "secondary"}
            size="custom"
            className="w-1/2 h-10"
            base="rounded20"
            onClick={() => {
              onChangeExtra(false);
            }}
          >
            【内部】教師スタッフ
          </ButtonUI>

          <ButtonUI
            data-testid="users-page-external-teachers-btn"
            buttonType={extra ? "selecting" : "secondary"}
            size="custom"
            className="w-1/2 h-10"
            base="rounded20"
            onClick={() => {
              onChangeExtra(true);
            }}
          >
            【外部】教師スタッフ
          </ButtonUI>
        </div>
        <form action="" onSubmit={search}>
          <UsersSearchInputUI
            className="mb-4"
            value={searchFilter}
            onChange={onChangeSearchFilterHandler}
            onClickSearch={search}
            onClickDelete={onClickDeleteHandler}
          ></UsersSearchInputUI>
        </form>
        {extra && (
          <div className="flex flex-col">
            <div className="flex justify-end mb-2">
              <ButtonUI
                data-testid="users-page-add-external-teachers"
                size="xs"
                buttonType="secondary"
                className={`inline-flex items-center justify-items-center justify-center`}
                to={`/user/external/new`}
              >
                <SmallPlusIcon className="stroke-current" />
                新規追加
              </ButtonUI>
            </div>
            <p className="text-dark-gray text-right">
              ログイン権限は発行されません。
            </p>
          </div>
        )}
      </div>
      {users.totalCount > 0 ? (
        <>
          <p
            className="ml-8 tracking-wider"
            data-testid="users-display-count"
          >{`全${users.totalCount}件中${offset + 1}件〜${
            offset + users.nodes.length
          }件を表示`}</p>
          <hr className="border ml-8 my-2"></hr>
          <UsersTableUI users={users} extra={extra}></UsersTableUI>
          <PaginationUI
            className="mt-20"
            selectedIndex={Math.ceil(offset / limit)}
            count={Math.ceil(users.totalCount / limit)}
            onClickPrevios={onClickPrevios}
            onClickNext={onClickNext}
            onClickItem={onClickPaginationItem}
          ></PaginationUI>
        </>
      ) : (
        <div className="bg-gray-200 text-center mt-5 ml-12">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-user-exists-message"
          >
            該当する教師スタッフがいません。
          </p>
          <p>検索条件に該当する教師スタッフがいませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
