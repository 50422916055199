import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { LoadingUI } from "../../../components/Loading";
import { useExpertsPageLazyQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { parseQueryParams, useQueryState } from "../../../hooks/queryState";
import ExpertsPage from "./ExpertsPage";

type State = {
  offset: number;
  searchWord: string;
};

const ExpertPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  // クエリーを実行した際のパラメータ Ref
  const lastExecParamsRef = useRef<string | undefined>(undefined);
  const [state, setState] = useQueryState<State>();
  const limit = 20;
  // ユーザーが入力している検索キーワードを管する変数
  const [searchWord, setSearchWord] = useState<string>(state.searchWord ?? "");
  const [expertsQuery, { data, loading }] = useExpertsPageLazyQuery({
    onError: errorRouter,
  });

  const runQueryAndSetState = useCallback(
    (s: Partial<State>) => {
      const newState = { ...state, ...s };
      const newQuery = setState(newState);
      lastExecParamsRef.current = newQuery;
      expertsQuery({
        variables: {
          filter: { name: newState.searchWord ?? "" },
          limit,
          offset: newState.offset ?? 0,
        },
      });
    },
    [state, setState, expertsQuery]
  );

  const onSearch = useCallback(
    (event) => {
      event.preventDefault();
      runQueryAndSetState({ offset: 0, searchWord });
    },
    [runQueryAndSetState, searchWord]
  );

  const onChangeSearchWord = useCallback(
    (e) => {
      const searchWord = e.target.value;
      setSearchWord(searchWord);
    },
    [setSearchWord]
  );

  const onClickDelete = useCallback<MouseEventHandler>(() => {
    const searchWord = "";
    setSearchWord(searchWord);
    runQueryAndSetState({ offset: 0, searchWord });
  }, [runQueryAndSetState]);

  const onClickNext = useCallback(() => {
    const nextOffset = (state.offset ?? 0) + limit;
    runQueryAndSetState({ offset: nextOffset });
  }, [runQueryAndSetState, state.offset]);

  const onClickPrevios = useCallback(() => {
    const previosOffset = Math.max((state.offset ?? 0) - limit, 0);
    runQueryAndSetState({ offset: previosOffset });
  }, [runQueryAndSetState, state.offset]);

  const onClickPaginationItem = useCallback(
    (index: number) => {
      const newOffset = limit * index;
      runQueryAndSetState({ offset: newOffset });
    },
    [runQueryAndSetState]
  );

  useEffect(() => {
    if (!lastExecParamsRef.current) {
      return;
    }
    // 前回 クエリー実行時のパラメータ
    const lastState = parseQueryParams<State>(lastExecParamsRef.current);
    // パラメータに変更があれば Lazy クエリーをキック
    if (
      state.offset !== lastState.offset ||
      state.searchWord !== lastState.searchWord
    ) {
      setSearchWord(state.searchWord ?? "");
      runQueryAndSetState({ ...state });
    }
  }, [runQueryAndSetState, state]);

  useEffect(() => {
    runQueryAndSetState(state);
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <LoadingUI />;
  }

  return (
    <ExpertsPage
      data={data}
      offset={state.offset ?? 0}
      limit={limit}
      searchWord={searchWord}
      onSearch={onSearch}
      onChangeSearchWord={onChangeSearchWord}
      onClickDelete={onClickDelete}
      onClickNext={onClickNext}
      onClickPrevios={onClickPrevios}
      onClickPaginationItem={onClickPaginationItem}
    />
  );
};

export default ExpertPageContainer;
