import React, { useCallback, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import Select from "react-select";

import {
  CourseSelectorRowFragment,
  useCoursesForMultiSelectorLazyQuery,
} from "../../generated/graphql";
import LinkTextUI from "../LinkText/LinkText";
import { ReactComponent as IconTrash } from "../trash.svg";
import buttonStyles from "./button.module.css";

interface selectorOption {
  label: string;
  value: CourseSelectorRowFragment;
}

export type CourseMultiSelectorUIProps = {
  containerClassName?: string;
  selectorBoxClassName?: string;
  selectorClassName?: string;
  tableClassName?: string;
  tdSystemIdClassName?: string;
  tdCourseNameClassName?: string;
  noSelectedCoursesBoxClassName?: string;
  noSelectedCoursesMessage?: string;
  values?: { id: string; systemId: string; name: string }[];
  onSelectedCoursesChange: (courses: CourseSelectorRowFragment[]) => void;
  testIdPrefix?: string;
};

const CourseMultiSelectorUI: React.VFC<CourseMultiSelectorUIProps> = ({
  containerClassName,
  selectorBoxClassName,
  selectorClassName,
  tableClassName,
  noSelectedCoursesBoxClassName,
  noSelectedCoursesMessage,
  tdSystemIdClassName,
  tdCourseNameClassName,
  values = [],
  onSelectedCoursesChange,
  testIdPrefix,
}) => {
  // 選択項目をクリアするために利用。型を設定すると options や onChange がエラーになるので、定義しない。
  const [selectValue, setSelectValue] = useState<any>();
  // クエリ・エラー
  const [queryErr, setQueryErr] = useState<ApolloError | undefined>(undefined);
  /**
   * 選択中のコース・リスト
   */
  const [selectedCourseList, setSelectedCourseList] = useState<
    CourseSelectorRowFragment[] | undefined
  >(undefined);
  /**
   * Select の options
   */
  const [selectorOptions, setSelectorOptions] = useState<
    { label: string; value: CourseSelectorRowFragment }[] | undefined
  >(undefined);

  /**
   * Select の option 形式のデータを作成する
   */
  const createSelectorOptions = useCallback(
    (courseList: CourseSelectorRowFragment[]): selectorOption[] => {
      if (!courseList) {
        return [];
      }
      return courseList.map((c) => {
        return {
          label: `${c.systemId} : ${c.name}`,
          value: c,
        };
      });
    },
    []
  );

  /**
   * コース・リストを取得する Lazy クエリー
   */
  const [getCourses] = useCoursesForMultiSelectorLazyQuery({
    fetchPolicy: "no-cache",
    // キャッシュしない設定なのに２回目の同じクエリーが発行されない Apollo Client のバグの回避策
    // https://github.com/apollographql/apollo-client/issues/9137
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      // UT で MockedResponse が意図したとおりコールされていない場合は、
      // ここの console.log を有効化すると原因究明の役に立つことがある。
      // console.log("***** err", { err });
      setQueryErr(err);
    },
    onCompleted: (res) => {
      setSelectorOptions(createSelectorOptions(res.courses.nodes));
    },
  });

  /**
   * 選択済みコースの差分検知
   */
  const isSame = useCallback(
    (
      a: CourseSelectorRowFragment[] | undefined,
      b: CourseSelectorRowFragment[] | undefined
    ): boolean => {
      if (!a && b) return false;
      if (a && !b) return false;
      if (a!.length !== b!.length) return false;
      for (const c1 of a!) {
        let has: boolean = false;
        for (const c2 of b!) {
          if (c1.id === c2.id) {
            has = true;
            break;
          }
        }
        if (!has) return false;
      }
      return true;
    },
    []
  );

  /**
   * 選択済みコースリスト
   */
  useEffect(() => {
    if (!isSame(selectedCourseList, values)) {
      const newList: CourseSelectorRowFragment[] = [];
      if (values && values.length) {
        values.forEach((c) => {
          newList.push({ ...c });
        });
      }
      setSelectedCourseList(newList);
    }
  }, [isSame, selectedCourseList, values]);

  /**
   * Select で入力時のイベント・ハンドラー
   */
  const onSelectorInputChange = useCallback(
    (searchWord: string) => {
      if (!searchWord || searchWord.length < 2) {
        return;
      }
      getCourses({
        variables: {
          filter: { searchWord },
          offset: 0,
          limit: 8,
        },
      });
    },
    [getCourses]
  );

  /**
   * 候補からコースを選択したときのハンドラー
   */
  const onCourseSelect = useCallback(
    (selectedOption: selectorOption) => {
      if (!selectedOption) {
        return;
      }

      // 重複チェック
      if (
        selectedCourseList &&
        selectedCourseList.find((c) => c.id === selectedOption.value.id)
      ) {
        return;
      }

      // 選択済みコース・リストを更新
      const newList: CourseSelectorRowFragment[] =
        selectedCourseList !== undefined
          ? selectedCourseList.concat([selectedOption.value])
          : [selectedOption.value];
      setSelectedCourseList(newList);
      onSelectedCoursesChange(newList);
      // 選択されたコースをクリア
      setSelectValue(null);
      setSelectorOptions([]);
    },
    [onSelectedCoursesChange, selectedCourseList]
  );

  /**
   * 選択済みコースの削除の時のハンドラー
   */
  const onSelectedCourseDelete = useCallback(
    (courseId: string) => {
      if (!selectedCourseList) {
        return;
      }
      const newList: CourseSelectorRowFragment[] = selectedCourseList.filter(
        (c) => c.id !== courseId
      );
      setSelectedCourseList(newList);
      onSelectedCoursesChange(newList);
    },
    [onSelectedCoursesChange, selectedCourseList]
  );

  return (
    <div className={containerClassName ? containerClassName : ""}>
      <div className={selectorBoxClassName ? selectorBoxClassName : ""}>
        {/* 選択したいコースの入力補完 */}
        <Select
          data-testid={`${testIdPrefix}-selector`}
          aria-label={`${testIdPrefix}-selector`}
          placeholder="コースの検索ができます"
          noOptionsMessage={() => null}
          className={selectorClassName ? selectorClassName : ""}
          value={selectValue}
          onInputChange={onSelectorInputChange}
          options={selectorOptions}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }
            setQueryErr(undefined);
            onCourseSelect({ ...newValue });
          }}
        />
      </div>
      {queryErr && (
        <div className="text-error">
          コース検索でエラーが発生しました。再度、お試し下さい。
        </div>
      )}
      {/* 選択済みコース・リスト */}
      {selectedCourseList && selectedCourseList.length ? (
        <table className={tableClassName ? tableClassName : "mt-4"}>
          <tbody>
            {selectedCourseList &&
              selectedCourseList.map((c) => {
                return (
                  <tr className=" px-2 py-1 text-base" key={c.id}>
                    {/* 削除ボタン */}
                    <td>
                      <div className="flex h-full items-center">
                        <button
                          type="button"
                          className={`${buttonStyles.trash} ${buttonStyles.trash}`}
                          data-testid={`${testIdPrefix}-selector-trash-${c.id}`}
                          onClick={() => onSelectedCourseDelete(c.id)}
                        >
                          <IconTrash className="ml-2 mr-2" />
                        </button>
                      </div>
                    </td>
                    {/* システムID */}
                    <td
                      className={
                        tdSystemIdClassName
                          ? tdSystemIdClassName
                          : "pl-2 pr-2 items-center bg-gray-200"
                      }
                    >
                      <LinkTextUI
                        to={`/courses/${c.id}`}
                        target="_blank"
                        data-testid={`${testIdPrefix}-selector-selectedSystemId-${c.id}`}
                      >
                        {c.systemId}
                      </LinkTextUI>

                      <span className=" ml-1">：</span>
                    </td>
                    {/* コース名 */}
                    <td
                      data-testid={`${testIdPrefix}-selector-selectedName-${c.id}`}
                      className={
                        tdCourseNameClassName
                          ? tdCourseNameClassName
                          : "items-center bg-gray-200 pr-4"
                      }
                    >
                      {c.name}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div
          className={
            noSelectedCoursesBoxClassName ? noSelectedCoursesBoxClassName : ""
          }
        >
          {noSelectedCoursesMessage}
        </div>
      )}
    </div>
  );
};

export default CourseMultiSelectorUI;
