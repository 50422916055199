import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  FieldSelecterFragment,
  VideoTagSelecterFragment,
} from "../../../generated/graphql";
import {
  VideoLessonEditUI,
  VideoLessonEditFormState,
} from "../components/VideoLessonEdit";

export type VideoLessonNewPageProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: (data: VideoLessonEditFormState) => void;
  videoTags: VideoTagSelecterFragment[];
  fields: FieldSelecterFragment[];
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "有識者一覧",
  },
];

const VideoLessonNewPage: React.VFC<VideoLessonNewPageProps> = ({
  onSubmit,
  onGoBack,
  videoTags,
  fields,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">シリーズ追加</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <VideoLessonEditUI
        onSubmit={onSubmit}
        onGoBack={onGoBack}
        videoTags={videoTags}
        fields={fields}
      />
    </div>
  );
};

export default VideoLessonNewPage;
