import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { MasterSwitchUI } from "./components/MasterSwitch/MasterSwitch";
import { MasterSwitchButtonUI } from "./components/MasterSwitchButton/MasterSwitchButton";
import { MASTERS, MasterType } from "./constants";
import { useCanUseMaster } from "./hooks/canUserMaster";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "マスタ設定",
  },
];

export type MasterPageProps = {
  masterType: MasterType;
  onChangeMaster: (type: MasterType) => void;
};

const MastersPage: React.FC<MasterPageProps> = ({
  masterType,
  onChangeMaster,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { canUseMaster } = useCanUseMaster();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">マスタ設定</h1>
      <hr className="border-dark-gray border mb-10"></hr>
      <div className="ml-5">
        <div className="flex justify-start space-x-5">
          {MASTERS.filter((master) => canUseMaster(master.masterType)).map(
            (master) => (
              <MasterSwitchButtonUI
                key={master.masterType}
                onChangeMaster={onChangeMaster}
                currentMasterType={masterType}
                masterType={master.masterType}
              >
                {master.text}
              </MasterSwitchButtonUI>
            )
          )}
        </div>
        <MasterSwitchUI masterType={masterType} />
      </div>
    </div>
  );
};

export default MastersPage;
