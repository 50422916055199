import React, { HTMLAttributes } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { ContactPageFragment, ContactStatus } from "../../generated/graphql";
import { getRouteName, getStatusName } from "../../lib/utils/contacts";
import { FormGroupUI } from "../FormGroup";
import { LabelUI } from "../Label";
import { TextUI } from "../Text";

dayjs.locale(ja);

export type ContactDetailUIProps = {
  contact: ContactPageFragment;
} & HTMLAttributes<HTMLDivElement>;

export const ContactDetailUI: React.VFC<ContactDetailUIProps> = ({
  contact,
  className,
  ...rest
}) => {
  const member: boolean = contact.customer ? true : false;
  return (
    <div className={className} {...rest}>
      <h2 className="text-xl mb-4">お客様情報</h2>
      {member && (
        <div>
          <FormGroupUI>
            <LabelUI className="text-lg">会員ID(メールアドレス)</LabelUI>
            <TextUI data-testid="contact-detail-memberid">
              {contact.customer?.profile
                ? contact.customer?.profile?.memberId
                : ""}
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
        </div>
      )}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">氏名</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-name">
          {member &&
            contact.customer?.profile &&
            contact.customer.profile.familyName +
              " " +
              contact.customer.profile.givenName}
          {!member &&
            contact.address?.familyName &&
            contact.address.familyName + " "}
          {!member && contact.address?.givenName && contact.address.givenName}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">フリガナ</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-name-kana">
          {member &&
            contact.customer?.profile &&
            contact.customer.profile.familyNameKana +
              " " +
              contact.customer.profile.givenNameKana}
          {!member &&
            contact.address?.familyNameKana &&
            contact.address.familyNameKana + " "}
          {!member &&
            contact.address?.givenNameKana &&
            contact.address.givenNameKana}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      {!member && (
        <div>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">メールアドレス</LabelUI>
            </div>
            <TextUI data-testid="contact-detail-email">
              {contact.address?.email && contact.address.email}
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
        </div>
      )}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">電話番号</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-tel">
          {member
            ? contact.customer?.profile
              ? contact.customer.profile?.tel
              : ""
            : contact.address?.tel && contact.address.tel}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line mb-6" />
      <h2 className="text-xl mb-4">お問い合わせ情報</h2>
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">お問い合わせ番号</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-contactid">
          {contact.systemId}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">登録日時</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-createdat">
          {dayjs(contact.createdAt).format("YYYY/MM/DD(dd) HH:mm")}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">お問い合わせ経路</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-route">
          {getRouteName(contact.route)}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">お問い合わせ種別</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-category">
          {contact.category?.value}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">お問い合わせ内容</LabelUI>
        </div>
        <TextUI data-testid="contact-detail-content">{contact.content}</TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">対応状況</LabelUI>
        </div>
        {contact.histories && (
          <TextUI data-testid="contact-detail-status">
            {contact.histories[0]
              ? getStatusName(contact.histories[0].status)
              : getStatusName(ContactStatus.Unsupported)}
          </TextUI>
        )}
      </FormGroupUI>
      <hr className="border-under-line" />
    </div>
  );
};
