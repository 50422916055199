import React, { useCallback } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { useExpertEditPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { ExpertEditFormState } from "../components/ExpertEdit";
import ExpertEditPage, { ExpertEditPageProps } from "./ExpertEditPage";

const ExpertEditPageContainer: React.VFC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const errorRouter = useErrorRouter();
  const location = useLocation<ExpertEditFormState>();

  const handleGoBack: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/experts");
    }, [history]);

  const { data, loading } = useExpertEditPageQuery({
    variables: { id },
    onError: errorRouter,
    skip: !!location.state,
  });

  const handleSubmit: ExpertEditPageProps["onSubmit"] = useCallback(
    (input) => {
      history.push(`/experts/${id}/confirm`, input);
    },
    [history, id]
  );

  if (loading || (!data && !location.state)) {
    return <LoadingUI />;
  }

  const defaultValues: ExpertEditFormState = data
    ? {
        ...data.expert,
        profilePhoto: data.expert.profilePhoto ?? undefined,
        headshot: data.expert.headshot ?? undefined,
      }
    : location.state;

  return (
    <ExpertEditPage
      defaultValues={defaultValues}
      onGoBack={handleGoBack}
      onSubmit={handleSubmit}
    />
  );
};

export default ExpertEditPageContainer;
