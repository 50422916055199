import React, { InputHTMLAttributes } from "react";

import {
  QuestionnaireOptionInput,
  QuestionnaireType,
} from "../../generated/graphql";
import { CourseQuestionnaire } from "../../lib/types/course";
import { BadgeUI } from "../Badge";
import { CheckBoxMarkUI } from "../CheckBoxMark";
import { LabelUI } from "../Label";
import { RadioMarkUI } from "../RadioMark";
import { TextUI } from "../Text";
import { ReactComponent as QuestionnaireBorder } from "./questionnaire-br.svg";
import styles from "./QuestionnairePreview.module.scss";

export type QuestionnairePreviewUIProps = {
  questionnaires: CourseQuestionnaire[];
} & InputHTMLAttributes<HTMLInputElement>;

export const QuestionnairePreviewUI: React.FC<QuestionnairePreviewUIProps> = ({
  questionnaires,
  ...rest
}) => {
  return (
    <div
      className={`w-96 ${questionnaires.length > 0 && "border border-black"}`}
    >
      {questionnaires.map((questionnaire: CourseQuestionnaire) => {
        return (
          <div
            key={questionnaire.order}
            data-testid="questionnaire-preview-item"
          >
            <div className="mt-5 ml-4">
              {questionnaire.required && (
                <div
                  className="ml-4 mb-2"
                  data-testid={`questionnaires${questionnaire.order}-required`}
                >
                  <BadgeUI>必須</BadgeUI>
                </div>
              )}
              <TextUI
                className={`ml-4 ${styles.titleColor}`}
                data-testid={`questionnaires${questionnaire.order}-order-title`}
              >
                {questionnaire.order}.{questionnaire.title}
              </TextUI>
              {questionnaire.typename ===
                QuestionnaireType.SelectQuestionnaire && (
                <div>
                  {questionnaire.options.map(
                    (option: QuestionnaireOptionInput, index: number) => {
                      return (
                        <div key={index} className="ml-8 mt-5">
                          <div className="inline-flex items-center">
                            <RadioMarkUI />
                            <TextUI
                              data-testid={`questionnaires${questionnaire.order}-radio-option${index}`}
                              className="pl-2.5"
                            >
                              {option.name}
                            </TextUI>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {questionnaire.typename ===
                QuestionnaireType.MultiSelectQuestionnaire && (
                <div>
                  <LabelUI className={`ml-6 mt-1 text-lg ${styles.titleColor}`}>
                    ※複数選択可
                  </LabelUI>
                  {questionnaire.options.map(
                    (option: QuestionnaireOptionInput, index: number) => {
                      return (
                        <div key={index} className="ml-8 mt-5">
                          <div className="inline-flex items-center">
                            <CheckBoxMarkUI />
                            <TextUI
                              data-testid={`questionnaires${questionnaire.order}-check-option${index}`}
                              className="pl-2.5"
                            >
                              {option.name}
                            </TextUI>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {questionnaire.typename ===
                QuestionnaireType.LinearScaleQuestionnaire && (
                <div className="ml-4 mt-6 h-12">
                  <div
                    className={`inline-block h-full ${styles.labelMaxMinColor}`}
                    data-testid={`questionnaires${questionnaire.order}-min-label`}
                  >
                    {questionnaire.minLabel}
                  </div>
                  <div className="inline-block w-9/12">
                    <div className="flex justify-evenly">
                      {Array.from(
                        Array(Number(questionnaire.maxScale)).keys()
                      ).map((index) => {
                        return (
                          <div
                            key={index}
                            className="inline-block text-center "
                            data-testid={`questionnaires${questionnaire.order}-linear-scales`}
                          >
                            <p>{index + 1}</p>
                            <div className="flex justify-center">
                              <RadioMarkUI />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={`inline-block h-full ${styles.labelMaxMinColor}`}
                    data-testid={`questionnaires${questionnaire.order}-max-label`}
                  >
                    {questionnaire.maxLabel}
                  </div>
                </div>
              )}
              {questionnaire.typename ===
                QuestionnaireType.FreeTextQuestionnaire && (
                <div className="ml-4 mt-6">
                  <input
                    type="text"
                    id="freeText"
                    name="freeText"
                    className={`${styles.freeTextField}`}
                    data-testid={`questionnaires${questionnaire.order}-free-text`}
                    disabled={true}
                  />
                </div>
              )}
            </div>
            <div className="mt-10 flex justify-center ">
              <QuestionnaireBorder />
            </div>
          </div>
        );
      })}
      {questionnaires.length !== 0 && (
        <LabelUI
          className={`text-lg text-center mt-10 mb-4 ${styles.titleColor}`}
        >
          ご回答ありがとうございました！
        </LabelUI>
      )}
    </div>
  );
};
