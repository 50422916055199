import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import {
  AutoSuggestUI,
  AutoSuggestDataType,
} from "../../../components/AutoSuggest";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CheckboxUI } from "../../../components/Checkbox";
import { DateInputUI } from "../../../components/DateInput";
import { InputUI } from "../../../components/Input";
import { LabelUI } from "../../../components/Label";
import {
  PaymentsFilterInput,
  PaymentStatus,
  CourseNamesFragment,
  CourseNameRowFragment,
  PaymentSearchPlanFragment,
  PriceType,
} from "../../../generated/graphql";
import { gmoFirstPaymentStatuses } from "../../../lib/constants/GmoFirstPaymentStatus";
import { GmoFirstPaymentStatus } from "../../../lib/constants/GmoFirstPaymentStatus";
import { gmoRecurringPaymentStatuses } from "../../../lib/constants/GmoRecurringPaymentStatus";
import { paymentServices } from "../../../lib/constants/PaymentService";
import { paymentStatuses } from "../../../lib/constants/PaymentStatus";
// TODO : local timezone を取れるようにする
dayjs.locale(ja);

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済情報照会",
  },
];

export type PaymentsSearchPageProps = {
  filter: PaymentsFilterInput;
  onSearch: (filter: PaymentsFilterInput) => void;
  onSearchCourses: (searchWord: string) => void;
  courseDataList: CourseNamesFragment;
  plans: PaymentSearchPlanFragment[];
};

type FilterInputs = {
  memberId: string;
  customerName: string;
  customerNameKana: string;
  orderId: string;
  courseName: string;
  contentName: string;
  orderFrom: string;
  orderTo: string;
  paymentFrom: string;
  paymentTo: string;
  paymentStatuses: number[];
  gmoFirstPaymentStatuses: string[];
  gmoRecurringPaymentStatuses: string[];
  paymentServices: number[];
  planIds: string[];
};

const PaymentsSearchPage: React.FC<PaymentsSearchPageProps> = ({
  filter,
  onSearch,
  onSearchCourses,
  courseDataList,
  plans,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [inputs, setInputs] = useState<FilterInputs>({
    memberId: filter.memberId || "",
    customerName: filter.customerName || "",
    customerNameKana: filter.customerNameKana || "",
    orderId: filter.orderId || "",
    courseName: filter.courseName || "",
    contentName: filter.contentName || "",
    orderFrom: filter.orderFrom
      ? dayjs(Date.parse(filter.orderFrom)).format("YYYY-MM-DD")
      : "",
    orderTo: filter.orderTo
      ? dayjs(Date.parse(filter.orderTo)).format("YYYY-MM-DD")
      : "",
    paymentFrom: filter.paymentFrom
      ? dayjs(Date.parse(filter.paymentFrom)).format("YYYY-MM-DD")
      : "",
    paymentTo: filter.paymentTo
      ? dayjs(Date.parse(filter.paymentTo)).format("YYYY-MM-DD")
      : "",
    paymentStatuses: filter.paymentStatuses || [],
    gmoFirstPaymentStatuses: filter.gmoFirstPaymentStatuses || [],
    gmoRecurringPaymentStatuses: filter.gmoRecurringPaymentStatuses || [],
    paymentServices: filter.paymentServices || [],
    planIds: filter.planIds || [],
  });
  const [searchFilter, setSearchFilter] = useState<PaymentsFilterInput>({
    ...filter,
  });

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
    setSearchFilter({
      ...inputs,
      orderFrom:
        inputs.orderFrom &&
        new Date(inputs.orderFrom + "T00:00:00.000+09:00").toISOString(), // TODO : 表示は JST（本来はブラウザのタイムゾーンから判断すべき）、検索条件は UTC
      orderTo:
        inputs.orderTo &&
        new Date(inputs.orderTo + "T23:59:59.999+09:00").toISOString(), // TODO : 表示は JST（本来はブラウザのタイムゾーンから判断すべき）、検索条件は UTC
      paymentFrom:
        inputs.paymentFrom &&
        new Date(inputs.paymentFrom + "T00:00:00.000+09:00").toISOString(), // TODO : 表示は JST（本来はブラウザのタイムゾーンから判断すべき）、検索条件は UTC
      paymentTo:
        inputs.paymentTo &&
        new Date(inputs.paymentTo + "T23:59:59.999+09:00").toISOString(), // TODO : 表示は JST（本来はブラウザのタイムゾーンから判断すべき）、検索条件は UTC
    });
    return () => {
      // unmount
    };
  }, [setBreadcrumbItems, setSearchFilter, inputs]);

  const [courseNames, setCourseNames] = useState<AutoSuggestDataType[]>([]);

  useEffect(() => {
    const values = courseDataList.nodes.map(
      (courseName: CourseNameRowFragment): AutoSuggestDataType => {
        return {
          id: courseName.id || "",
          systemId: courseName.systemId || undefined,
          name: courseName.name || "",
        };
      }
    );
    setCourseNames(values);
  }, [courseDataList]);

  return (
    <div>
      <h1 className="text-2xl mb-3">決済情報照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <p className="mt-8 ml-8 font-bold text-xl">検索条件設定</p>

      <form
        onSubmit={useCallback(
          (event) => {
            event.preventDefault();
            onSearch(searchFilter);
          },
          [searchFilter, onSearch]
        )}
        className="ml-20 mr-10 pb-20 mt-2.5"
      >
        <div className="flex justify-center items-center space-x-3">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            会員ID(メールアドレス)
          </LabelUI>
          <InputUI
            data-testid="payments-search-memberid"
            value={inputs.memberId}
            id="memberId"
            name="memberId"
            className="w-9/12"
            onTextChange={(text: string) => {
              setInputs({
                ...inputs,
                memberId: text,
              });
            }}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            氏名
          </LabelUI>
          <div className="w-9/12">
            <InputUI
              data-testid="payments-search-customer-name"
              value={inputs.customerName}
              id="customerName"
              name="customerName"
              className="w-80"
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  customerName: text,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            フリガナ
          </LabelUI>
          <div className="w-9/12">
            <InputUI
              data-testid="payments-search-customer-name-kana"
              value={inputs.customerNameKana}
              id="customerNameKana"
              name="customerNameKana"
              className="w-80"
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  customerNameKana: text,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            申込番号
          </LabelUI>
          <div className="w-9/12">
            <InputUI
              data-testid="payments-search-order-id"
              value={inputs.orderId}
              id="orderId"
              name="orderId"
              className="w-24"
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  orderId: text,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            コース名
          </LabelUI>
          <div className="w-9/12">
            <AutoSuggestUI
              data-testid="payments-search-course"
              id="course"
              name="course"
              placeholder={"コース名/システムID(cou-)を入力"}
              value={inputs.courseName}
              dataList={courseNames}
              onLazyQuery={onSearchCourses}
              onTextChange={(text: string) => {
                setInputs({
                  ...inputs,
                  courseName: text,
                });
              }}
            />
          </div>
        </div>
        {!!plans.length && (
          <div className="flex justify-center items-start space-x-3 mt-4">
            <LabelUI className="text-lg font-bold w-3/12 text-right">
              月額プラン名
            </LabelUI>
            <div className="w-9/12">
              {plans
                .filter((p) => p.price.type !== PriceType.Free)
                .map((plan) => (
                  <CheckboxUI
                    data-testid="payments-search-plan-id"
                    style={{ marginRight: "8px" }}
                    id={`plan-${plan.id}`}
                    name="plan-ids"
                    value={plan.id}
                    checked={!!inputs.planIds.includes(plan.id)}
                    key={`plan-${plan.id}`}
                    onCheckChange={(checked: boolean, value: string) => {
                      let planIds = inputs.planIds;
                      if (checked) {
                        planIds.push(value);
                      } else {
                        planIds = planIds.filter((v) => v !== value);
                      }
                      const unique = Array.from(new Set(planIds));
                      setInputs({
                        ...inputs,
                        planIds: unique,
                      });
                    }}
                  >
                    {plan.name}
                  </CheckboxUI>
                ))}
            </div>
          </div>
        )}
        <div className="flex justify-center items-start space-x-3 mt-4">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            決済方法
          </LabelUI>
          <div className="grid gap-4 items-start w-9/12">
            {paymentServices.map((service) => (
              <CheckboxUI
                data-testid="payments-search-payment-service"
                style={{ marginRight: "8px" }}
                id={service.id}
                name="service"
                value={service.value}
                checked={
                  inputs.paymentServices &&
                  inputs.paymentServices.findIndex(
                    (element) => element === service.value
                  ) >= 0
                }
                key={service.id}
                onCheckChange={(checked: boolean, value: string) => {
                  const a: number[] = inputs.paymentServices;
                  if (checked) {
                    a.push(parseInt(value));
                    setInputs({
                      ...inputs,
                      paymentServices: Array.from(new Set(a)), // 重複除去
                    });
                  } else {
                    const b = a.filter((item) => item !== parseInt(value));
                    setInputs({
                      ...inputs,
                      paymentServices: Array.from(new Set(b)), // 重複除去
                    });
                  }
                }}
              >
                {service.label}
              </CheckboxUI>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-4">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            申込年月日（オーソリ日）
          </LabelUI>
          <div className="flex items-center w-9/12 space-x-2.5">
            <DateInputUI
              data-testid="payments-search-order-from"
              value={inputs.orderFrom}
              id="orderFrom"
              name="orderFrom"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onDateChange={(dateStr: string) => {
                setInputs({
                  ...inputs,
                  orderFrom: dateStr,
                });
              }}
            />
            <span>{" 〜 "}</span>
            <DateInputUI
              data-testid="payments-search-order-to"
              value={inputs.orderTo}
              id="orderTo"
              name="orderTo"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onDateChange={(dateStr: string) => {
                setInputs({
                  ...inputs,
                  orderTo: dateStr,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            決済年月日（売上確定日）
          </LabelUI>
          <div className="flex items-center w-9/12 space-x-2.5">
            <DateInputUI
              data-testid="payments-search-payment-from"
              value={inputs.paymentFrom}
              id="paymentFrom"
              name="paymentFrom"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onDateChange={(dateStr: string) => {
                setInputs({
                  ...inputs,
                  paymentFrom: dateStr,
                });
              }}
            />
            <span>{" 〜 "}</span>
            <DateInputUI
              data-testid="payments-search-payment-to"
              value={inputs.paymentTo}
              id="paymentTo"
              name="paymentTo"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onDateChange={(dateStr: string) => {
                setInputs({
                  ...inputs,
                  paymentTo: dateStr,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-start space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            決済ステータス
          </LabelUI>
          <div className="grid gap-4 grid-cols-3 items-start w-9/12">
            {paymentStatuses.map((status) => {
              if (
                status.value !== PaymentStatus.Pending &&
                status.value !== PaymentStatus.NotApplicable
              ) {
                return (
                  <CheckboxUI
                    data-testid="payments-search-payment-status"
                    style={{ marginRight: "8px" }}
                    id={status.id}
                    name="status"
                    value={status.value}
                    checked={
                      inputs.paymentStatuses &&
                      inputs.paymentStatuses.findIndex(
                        (element) => element === status.value
                      ) >= 0
                    }
                    key={status.id}
                    onCheckChange={(checked: boolean, value: string) => {
                      const a: number[] = inputs.paymentStatuses;
                      if (checked) {
                        a.push(parseInt(value));
                        setInputs({
                          ...inputs,
                          paymentStatuses: Array.from(new Set(a)), // 重複除去
                        });
                      } else {
                        const b = a.filter((item) => item !== parseInt(value));
                        setInputs({
                          ...inputs,
                          paymentStatuses: Array.from(new Set(b)), // 重複除去
                        });
                      }
                    }}
                  >
                    {status.label}
                  </CheckboxUI>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="flex justify-center items-start space-x-3 mt-4">
          <LabelUI className="text-lg font-bold w-3/12 text-right"></LabelUI>
          <div className="grid gap-4 grid-cols-3 items-start w-9/12">
            {gmoFirstPaymentStatuses.map((status) => {
              if (
                status.value !== GmoFirstPaymentStatus.UnProcessed &&
                status.value !== GmoFirstPaymentStatus.Authenticated &&
                status.value !== GmoFirstPaymentStatus.Check &&
                status.value !== GmoFirstPaymentStatus.Auth &&
                status.value !== GmoFirstPaymentStatus.Sales &&
                status.value !== GmoFirstPaymentStatus.Void &&
                status.value !== GmoFirstPaymentStatus.Return &&
                status.value !== GmoFirstPaymentStatus.ReturnX &&
                status.value !== GmoFirstPaymentStatus.SAuth
              ) {
                return (
                  <CheckboxUI
                    data-testid="payments-search-gmoFirstPayment-status"
                    style={{ marginRight: "8px" }}
                    id={status.id}
                    name="status"
                    value={status.value}
                    checked={
                      inputs.gmoFirstPaymentStatuses &&
                      inputs.gmoFirstPaymentStatuses.findIndex(
                        (element) => element === status.value
                      ) >= 0
                    }
                    key={status.id}
                    onCheckChange={(checked: boolean, value: string) => {
                      const a: string[] = inputs.gmoFirstPaymentStatuses;
                      if (checked) {
                        a.push(value);
                        setInputs({
                          ...inputs,
                          gmoFirstPaymentStatuses: Array.from(new Set(a)), // 重複除去
                        });
                      } else {
                        const b = a.filter((item) => item !== value);
                        setInputs({
                          ...inputs,
                          gmoFirstPaymentStatuses: Array.from(new Set(b)), // 重複除去
                        });
                      }
                    }}
                  >
                    {status.label}
                  </CheckboxUI>
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="flex justify-center items-start space-x-3 mt-4">
          <LabelUI className="text-lg font-bold w-3/12 text-right"></LabelUI>
          <div className="grid gap-4 grid-cols-3 items-start w-9/12">
            {gmoRecurringPaymentStatuses.map((status) => {
              return (
                <CheckboxUI
                  data-testid="payments-search-gmoRecurringPayment-status"
                  style={{ marginRight: "8px" }}
                  id={status.id}
                  name="status"
                  value={status.value}
                  checked={
                    inputs.gmoRecurringPaymentStatuses &&
                    inputs.gmoRecurringPaymentStatuses.findIndex(
                      (element) => element === status.value
                    ) >= 0
                  }
                  key={status.id}
                  onCheckChange={(checked: boolean, value: string) => {
                    const a: string[] = inputs.gmoRecurringPaymentStatuses;
                    if (checked) {
                      a.push(value);
                      setInputs({
                        ...inputs,
                        gmoRecurringPaymentStatuses: Array.from(new Set(a)), // 重複除去
                      });
                    } else {
                      const b = a.filter((item) => item !== value);
                      setInputs({
                        ...inputs,
                        gmoRecurringPaymentStatuses: Array.from(new Set(b)), // 重複除去
                      });
                    }
                  }}
                >
                  {status.label}
                </CheckboxUI>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end my-12">
          <ButtonUI
            data-testid="payments-search-submit"
            buttonType="primary"
            base="rounded20"
            type="submit"
            size="xs"
            className="mr-2.5"
          >
            検索
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};

export default PaymentsSearchPage;
