import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ExpertDetailFragment } from "../../../generated/graphql";
import ExpertDetail from "../components/ExpertDetail/ExpertDetail";
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "有識者一覧",
  },
];

export type ExpertPageProps = {
  expert: ExpertDetailFragment;
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
};

const ExpertPage: React.VFC<ExpertPageProps> = ({ expert, onGoBack }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  const { id } = useParams<{ id: string }>();

  return (
    <div className="pb-44">
      <h1 className="text-2xl mb-2">有識者詳細</h1>
      <hr className="border-gray border mb-4" />
      <ExpertDetail className="mt-8" expert={expert} />
      <div className="flex justify-center mt-20 space-x-10">
        <ButtonUI buttonType="secondary" onClick={onGoBack}>
          戻る
        </ButtonUI>
        <ButtonUI to={`/experts/${id}/edit`}>編集</ButtonUI>
      </div>
    </div>
  );
};

export default ExpertPage;
