import React, { PropsWithChildren } from "react";

import { ButtonUI, ButtonUIProps } from "../../../../../components/Button";
import { MasterType } from "../../constants";

export type MasterSwitchButtonUIProps = {
  currentMasterType: MasterType;
  masterType: MasterType;
  onChangeMaster: (masterType: MasterType) => void;
} & ButtonUIProps;

export const MasterSwitchButtonUI: React.VFC<
  PropsWithChildren<MasterSwitchButtonUIProps>
> = ({ currentMasterType, masterType, onChangeMaster, children, ...props }) => {
  return (
    <ButtonUI
      buttonType={currentMasterType === masterType ? "selecting" : "secondary"}
      size="xs"
      base="rounded20"
      onClick={() => {
        onChangeMaster(masterType);
      }}
      {...props}
    >
      {children}
    </ButtonUI>
  );
};
