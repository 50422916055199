import React, {
  MouseEventHandler,
  ChangeEventHandler,
  useState,
  FormEventHandler,
  ChangeEvent,
  useCallback,
} from "react";

import { Teacher, DisplayLesson } from "../../lib/constants/classes";
import { ButtonUI } from "../Button";
import { CheckboxUI } from "../Checkbox";
import { ModalUI } from "../Modal";
import { ReactComponent as AccountIcon } from "./account.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as SearchIcon } from "./search.svg";

type Props = {
  selectedLesson: DisplayLesson;
  visibility: boolean;
  onCancel: (selectedLesson: DisplayLesson, cancelTeacherIds: string[]) => void;
  onSearchTeachers: (searchWord: string) => void;
  onChangeCheckedTeachers: (
    selectedLesson: DisplayLesson,
    teachers: Teacher[]
  ) => void;
  onSelectedTeachers: (
    selectedLesson: DisplayLesson,
    selectedTeachers: Teacher[]
  ) => void;
  teachers: Teacher[];
  searchFilter: string;
  onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement>;
  onClickDeleteHandler: MouseEventHandler;
};

const TeachersListModalUI = ({
  selectedLesson,
  visibility,
  onCancel,
  onSelectedTeachers,
  onChangeCheckedTeachers,
  onSearchTeachers,
  teachers,
  searchFilter,
  onChangeSearchFilterHandler,
  onClickDeleteHandler,
}: Props) => {
  const handleSearch: FormEventHandler = async (event) => {
    event.preventDefault();
    onSearchTeachers(searchFilter);
  };

  const [cancelCandidateTeacherIds, setCancelCandidateTeacherIds] = useState<
    string[]
  >([]);

  const selectTeachers = useCallback(() => {
    setCancelCandidateTeacherIds([]);
    const selectedTeachers = teachers.filter(
      (teacher: Teacher) => teacher.selected === true
    );
    onSelectedTeachers(selectedLesson, selectedTeachers);
  }, [onSelectedTeachers, teachers, selectedLesson]);

  const onChangeTeacherCheckbox: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const targetTeacher = teachers.find(
      (teacher: Teacher) => teacher.id === event.target.value
    )!;
    if (event.target.checked) {
      setCancelCandidateTeacherIds([
        ...cancelCandidateTeacherIds,
        targetTeacher.id,
      ]);
    }
    Object.assign(targetTeacher, {
      id: targetTeacher.id,
      selected: event.target.checked,
    });
    const newTeachers = teachers.map((teacher: Teacher) =>
      targetTeacher.id === teacher.id ? targetTeacher : teacher
    );
    onChangeCheckedTeachers(selectedLesson, newTeachers);
  };

  return (
    <ModalUI
      data-testid="completed-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-10 px-3">
        <h2 className="text-2xl">教師一覧</h2>
        <hr className="mt-2" />
        <form className="mt-6 mx-9" onSubmit={handleSearch}>
          <div className={`flex border-2 rounded h-10`}>
            <SearchIcon
              className="flex-none h-full mx-3 cursor-pointer"
              data-testid="courses-search-icon"
              onClick={handleSearch}
            />
            <input
              className="flex-grow h-full border-l-2 pl-3 outline-none"
              type="text"
              value={searchFilter}
              placeholder="教師名または、教師IDを入力"
              data-testid="courses-search-input"
              onChange={onChangeSearchFilterHandler}
            />
            <DeleteIcon
              className="h-full mr-5 cursor-pointer"
              data-testid="courses-delete-icon"
              onClick={onClickDeleteHandler}
            />
          </div>
        </form>
        <div className="flex justify-center">
          <div className="mt-5 grid grid-flow-row grid-cols-2 gap-y-2.5">
            {teachers &&
              teachers.length > 0 &&
              teachers.map((teacher: Teacher) => (
                <div key={teacher.id}>
                  <CheckboxUI
                    data-testid={`teacher-list-${teacher.id}`}
                    id={teacher.id}
                    name={`teacher-list-${teacher.id}`}
                    value={teacher.id}
                    checked={teacher.selected || false}
                    onChange={onChangeTeacherCheckbox}
                    type="s"
                  >
                    <div className="flex">
                      <AccountIcon
                        className="mx-2.5 cursor-pointer"
                        data-testid="courses-search-icon"
                      />
                      <div>
                        <p>
                          {teacher.familyName}
                          {teacher.givenName}
                          {teacher.extra && "【外部】"}
                        </p>
                        <p>ID:{teacher.userId}</p>
                      </div>
                    </div>
                  </CheckboxUI>
                </div>
              ))}
          </div>
        </div>
        <div className="flex justify-center space-x-10 mt-9">
          <ButtonUI
            data-testid="contents-confirm-cancel"
            buttonType="secondary"
            onClick={() => {
              onCancel(selectedLesson, cancelCandidateTeacherIds);
            }}
          >
            キャンセル
          </ButtonUI>
          <ButtonUI
            data-testid="contents-confirm-submit"
            onClick={selectTeachers}
          >
            決定
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default TeachersListModalUI;
