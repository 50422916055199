import React, { ReactElement } from "react";

import MasterTableRowUI from "./MasterTableRow";

import type { Row, Column } from "./types";

export type MasterTableUIProps<T extends Row> = {
  rows: T[];
  columns: Column[];
  willEdit: (row: T) => void;
};

export function MasterTableUI<T extends Row>({
  rows,
  columns,
  willEdit,
}: React.PropsWithChildren<MasterTableUIProps<T>>): ReactElement {
  return (
    <table className="w-full border-collapse border border-dark-gray">
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-16">
          <th className="border border-dark-gray w-10"></th>
          {columns.map((column) => (
            <th
              key={column.name}
              data-testid={`table-header-${column.name}`}
              className={`border border-dark-gray ${
                column.headerClassName ? column.headerClassName : ""
              }`}
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <MasterTableRowUI
            key={row.id}
            row={row}
            columns={columns}
            willEdit={willEdit}
          ></MasterTableRowUI>
        ))}
      </tbody>
    </table>
  );
}
