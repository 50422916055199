import React, { HTMLAttributes } from "react";

import { useFormContext } from "react-hook-form";

import { ReactComponent as ErrorCheckIcon } from "../../../../../components/errorCheck.svg";
import { ValidatableCheckboxUI } from "../../../../../components/ValidatableCheckbox";
import { ServiceInputFragment } from "../../../../../generated/graphql";

type ServiceInputUIProps = {
  services: ServiceInputFragment[];
} & HTMLAttributes<HTMLInputElement>;

type ServiceInputFormValue = {
  serviceKindIds: string[];
};

export const ServiceInputUI: React.VFC<ServiceInputUIProps> = ({
  services,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ServiceInputFormValue>();

  return (
    <div className="flex space-x-16">
      <div className="flex flex-col w-full">
        <div className="flex items-center space-x-3">
          {errors.serviceKindIds && <ErrorCheckIcon />}
          {services.map((service) => (
            <ValidatableCheckboxUI
              data-testid="users-edit-serviceKindIds"
              id={service.id}
              key={service.id}
              value={service.id}
              {...register("serviceKindIds", { required: "必須項目です。" })}
              {...rest}
            >
              {service.shortName}
            </ValidatableCheckboxUI>
          ))}
        </div>
        {errors.serviceKindIds && (
          <p className="text-error mt-1.5 ml-8">
            {errors.serviceKindIds.message}
          </p>
        )}
      </div>
    </div>
  );
};
