import React, { HTMLAttributes } from "react";

import { useFormContext } from "react-hook-form";

import { ReactComponent as ErrorCheckIcon } from "../../../../../components/errorCheck.svg";
import { NewValidatableRadioUI } from "../../../../../components/ValidatableRadio";
import { roles, DisplayRole } from "../../../../../lib/constants";

type RoleInputUIProps = HTMLAttributes<HTMLInputElement>;

export const RoleInputUI: React.VFC<RoleInputUIProps> = ({ ...rest }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex space-x-16">
      <div className="flex flex-col w-full">
        <div
          className="flex items-center space-x-3"
          data-testid="users-edit-roleIds-wrapper"
        >
          {errors.roleIds && (
            <ErrorCheckIcon data-testid="users-edit-roleids-error-icon" />
          )}
          {roles
            .filter(
              (role: DisplayRole) =>
                role.id !== "systemAdministrator" &&
                role.id !== "operationMonitor"
            )
            .map((role) => (
              <NewValidatableRadioUI
                id={role.id}
                key={role.id}
                value={role.roleId}
                {...register("roleIds", { required: "選択必須項目です。" })}
                {...rest}
              >
                {role.label}
              </NewValidatableRadioUI>
            ))}
        </div>
        {errors.roleIds && (
          <p
            data-testid="users-edit-roleids-error-message"
            className="text-error mt-1.5 ml-8"
          >
            {errors.roleIds.message}
          </p>
        )}
      </div>
    </div>
  );
};
