import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { PaymentMismatchCsvFragment } from "../../../../generated/graphql";
import { getPaymtdJA } from "../../../../lib/utils/payment";
dayjs.locale(ja);

export type mplStatusTableProps = {
  mplStatus?: PaymentMismatchCsvFragment;
};

export const MplStatusTableUI: React.FC<mplStatusTableProps> = ({
  mplStatus,
}) => {
  return (
    <table
      className="w-full h-52 mb-4 table-fixed break-words"
      data-testid="payment-mismatch-mpl-status"
    >
      <tbody>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">
            オーソリ年月日
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="payment-mismatch-mpl-auth-date"
          >
            {mplStatus?.authDateTime !== ""
              ? dayjs(mplStatus?.authDateTime).format("YYYY/MM/DD")
              : ""}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">決済情報番号</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="payment-mismatch-mpl-payinfo"
          >
            {mplStatus?.payInfoNo}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">売上確定年月日</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-sold-date"
          >
            {mplStatus?.soldDateTime !== ""
              ? dayjs(mplStatus?.soldDateTime).format("YYYY/MM/DD")
              : ""}
          </td>
          <td className="border border-dark-gray pl-2">継続課金ID</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-continue-account-id"
          >
            {mplStatus?.continueAccountId}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">調整年月日</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-adjust-date"
          >
            {mplStatus?.adjustedDateTime !== ""
              ? dayjs(mplStatus?.adjustedDateTime).format("YYYY/MM/DD")
              : ""}
          </td>
          <td className="border border-dark-gray pl-2">加盟店管理番号</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-manage-no"
          >
            {mplStatus?.memberManageNo}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">加盟店ID</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-member-id"
          >
            {mplStatus?.memberId}
          </td>
          <td className="border border-dark-gray pl-2">摘要</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-commodity"
          >
            {mplStatus?.commodity}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">サービスID</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-service-id"
          >
            {mplStatus?.serviceId}
          </td>
          <td className="border border-dark-gray pl-2">正負号</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-price-sign"
          >
            {mplStatus?.priceSign}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">au one-ID</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-auone-id"
          >
            {mplStatus?.auOneId}
          </td>
          <td className="border border-dark-gray pl-2">金額</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-price-value"
          >
            {Number(mplStatus?.priceValue).toLocaleString("ja")}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">電話番号</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-tel"
          >
            {mplStatus?.tel}
          </td>
          <td className="border border-dark-gray pl-2">支払方法</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-pay-md"
          >
            {getPaymtdJA(mplStatus?.paymtd)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2">OpenID</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-open-id"
          >
            {mplStatus?.openId}
          </td>
          <td className="border border-dark-gray pl-2">予約領域1</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-reserved-1"
          >
            {getPaymtdJA(mplStatus?.reserved1)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2"></td>
          <td className="border border-dark-gray pl-2"></td>
          <td className="border border-dark-gray pl-2">予約領域2</td>
          <td
            className="border border-dark-gray pl-2"
            data-testid="payment-mismatch-mpl-reserved-2"
          >
            {mplStatus?.reserved2}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
