import React, { ChangeEventHandler } from "react";

import { FieldErrors, UseFormRegister, UseFormReset } from "react-hook-form";

import { CoursePriceType } from "../../../generated/graphql";
import { CoursePriceInput } from "../../../generated/graphql";
import { prices, PriceTypeName } from "../../../lib/constants/courses";
import { PageType } from "../../../lib/constants/pageType";
import { BadgeUI } from "../../Badge";
import { ReactComponent as ErrorCheckIcon } from "../../errorCheck.svg";
import { FormGroupUI } from "../../FormGroup";
import { LabelUI } from "../../Label";
import { TextUI } from "../../Text";
import { ValidatablePriceValueInputUI } from "../../ValidatablePriceValueInput";
import { ValidatableRadioUI } from "../../ValidatableRadio";
import { ForValidation } from "../CourseEdit";

export type PriceFromValue = ForValidation & {
  price: ForValidation["price"] & { value: string };
};
export interface PriceFormGroupUIProps {
  pageType: PageType;
  price: CoursePriceInput;
  errors: FieldErrors<PriceFromValue>;
  register: UseFormRegister<any>;
  reset: UseFormReset<any>;
  onChangePrice: (input: CoursePriceInput) => void;
  maxPrice?: number;
}

export const PriceFormGroupUI: React.VFC<PriceFormGroupUIProps> = ({
  pageType,
  price,
  errors,
  register,
  reset,
  onChangePrice,
  maxPrice,
}) => {
  const handleChangePrice: ChangeEventHandler<HTMLInputElement> = (event) => {
    // 料金タイプの変更
    if (event.target.name === "price.type") {
      // 料金タイプ以外をリセット
      const input: CoursePriceInput = {
        type: event.target.value as CoursePriceType,
      };
      reset({ price: input });
      onChangePrice(input);
      return;
    }
    // 料金の変更
    if (price.type === CoursePriceType.Free) {
      onChangePrice({
        type: price.type,
      });
    } else {
      const input: CoursePriceInput = {
        ...price,
        valueNoTax: Number(event.target.value.replace(/[^0-9]+/gi, "")),
      };
      onChangePrice(input);
    }
  };

  return (
    <FormGroupUI>
      <div className="flex flex-row items-end space-x-2.5">
        <LabelUI className="text-lg">料金設定</LabelUI>
        {/* コース編集画面で無料が設定されているときは必須ラベルを表示しない */}
        {!(
          pageType === PageType.CoursesEditPage &&
          price.type === CoursePriceType.Free
        ) && <BadgeUI>必須</BadgeUI>}
      </div>
      {/* コース追加 => チェックボックスを表示 */}
      {pageType === PageType.CoursesNewPage ? (
        <div className="flex space-x-16">
          <div className="flex flex-col w-full">
            <div className="flex items-center space-x-3">
              {errors.price?.type && (
                <ErrorCheckIcon data-testid="course-edit-price-type-error-icon" />
              )}
              {prices.map((type) => (
                <ValidatableRadioUI
                  data-testid="course-edit-price-type"
                  registerParams={{
                    register,
                    error: errors.price?.type,
                    label: "price.type",
                    conditions: {
                      required: true,
                    },
                  }}
                  key={type.value}
                  id={`type-${type.value}`}
                  name="price.type"
                  value={type.value}
                  onChange={handleChangePrice}
                  checked={type.value === price?.type!}
                >
                  {type.label}
                </ValidatableRadioUI>
              ))}
            </div>
            {errors.price?.type && (
              <p
                className="text-error mt-1.5 ml-8"
                data-testid="course-edit-price-type-error-message"
              >
                料金体系は選択必須項目です。
              </p>
            )}
          </div>
        </div>
      ) : (
        <TextUI data-testid="course-edit-price-type">
          {PriceTypeName[price.type]}
        </TextUI>
      )}
      {/* 従量課金のとき */}
      {price.type === CoursePriceType.Once && (
        <div className="pt-5">
          <div className="flex justify-start w-full">
            {errors.price?.value && (
              <div className="flex flex-col mr-2.5">
                <div className="flex items-center h-12">
                  <ErrorCheckIcon data-testid="course-edit-price-value-error-icon" />
                </div>
                <div className="h-12"></div>
              </div>
            )}
            <div className="flex flex-col mr-2">
              <div className="h-12 flex items-end">金額（税抜）：</div>
              {/* Note:バリデーションエラー表示時の余白調整のために設けている */}
              {errors.price?.value && <div className="h-12"></div>}
            </div>
            <div className="flex flex-col">
              <div className="flex justify-start items-end">
                <ValidatablePriceValueInputUI
                  data-testid="course-edit-price-value"
                  registerParams={{
                    register,
                    error: errors.price?.value,
                    label: "price.value",
                    conditions: {
                      required: {
                        value: true,
                        message: "金額は必須入力項目です。",
                      },
                      max: {
                        value: maxPrice!,
                        message: `${maxPrice?.toLocaleString(
                          "ja-JP"
                        )}円以下の金額を入力してください。`,
                      },
                      pattern: {
                        value: /[1-9][0-9]*/,
                        message: "0より大きい金額を入力してください。",
                      },
                    },
                  }}
                  className="w-64"
                  value={
                    price.valueNoTax || price.valueNoTax === 0
                      ? price.valueNoTax
                      : ""
                  }
                  placeholder="金額（税抜）を入力してください"
                  id="priceForOnce"
                  name="price.value"
                  onChange={handleChangePrice}
                />
                <span className="ml-2.5">円</span>
              </div>
              {errors.price?.value && (
                <p
                  className="text-error mt-1.5 flex justify-start"
                  data-testid="course-edit-price-value-error-message"
                >
                  {errors.price?.value.message}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </FormGroupUI>
  );
};
