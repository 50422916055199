import React, { MouseEventHandler, useEffect } from "react";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { AnnouncementForEditPageFragment } from "../../../generated/graphql";
import { AnnouncementEdit } from "../../../lib/types/announcement";
import { AnnouncementEditUI } from "../components/AnnouncementEdit";

export type AnnouncementsEditPageProps = {
  announcement: AnnouncementForEditPageFragment;
  onSubmit: SubmitHandler<AnnouncementEdit>;
  onClickGoBack: MouseEventHandler<HTMLButtonElement>;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

const AnnouncementsEditPage: React.VFC<AnnouncementsEditPageProps> = ({
  announcement,
  onSubmit,
  onClickGoBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const methods = useForm<AnnouncementEdit>({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
    defaultValues: announcement,
  });

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-4"></hr>

      <div className="mb-10">
        <h1 className="text-1.5xl mb-10 text-title-black">お知らせ編集</h1>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <AnnouncementEditUI systemId={announcement.systemId} />
            <div className="flex flex-row justify-center space-x-10 mt-20">
              <ButtonUI
                type="button"
                buttonType="secondary"
                onClick={onClickGoBack}
                data-testid="announcements-edit-back"
              >
                戻る
              </ButtonUI>
              <ButtonUI type="submit" data-testid="announcements-edit-submit">
                確認
              </ButtonUI>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AnnouncementsEditPage;
