import React, { useMemo } from "react";

import { ButtonUI } from "../../../../../components/Button";
import { ModalUI } from "../../../../../components/Modal";
import { StepFunctionsCannotStartReason } from "../../../../../generated/graphql";

export type NeedToWaitSettleSeminarBatchModalProps = {
  cannotStartReason: StepFunctionsCannotStartReason | undefined;
  onClickCloseButton: () => void;
};

export const NeedToWaitSettleSeminarBatchModal: React.VFC<
  NeedToWaitSettleSeminarBatchModalProps
> = ({ cannotStartReason, onClickCloseButton }) => {
  const visibility = useMemo(
    () =>
      cannotStartReason ===
      StepFunctionsCannotStartReason.NeedToWaitSettleSeminarBatch,
    [cannotStartReason]
  );

  return (
    <ModalUI visibility={visibility}>
      <div
        data-testid="payment-NeedToWaitBatchProcessModal"
        className="inline-block px-5 py-5 text-center"
      >
        <div className="text-error">
          <p>
            売上確定バッチ処理が動作する時間帯ですので、決済データの修正を行うことができません。
          </p>
          <p>下記の時間帯を避けて実行お願いいたします。</p>
        </div>
        <ul className="pt-5 list-disc list-inside">
          <li>08:50 〜 10:00</li>
        </ul>
        <div>
          <ButtonUI
            data-testid="payment-NeedToWaitBatchProcessModal-closeButton"
            className="px-2 mt-5"
            buttonType="secondary"
            onClick={onClickCloseButton}
          >
            閉じる
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};
