import React, { MouseEventHandler } from "react";

import { ButtonUI } from "../Button";
import { ModalUI } from "../Modal";

type Props = {
  title: string;
  description: string;
  leftBtnTitle: string;
  rightBtnTitle: string;
  visibility: boolean;
  onClickLeftBtn: MouseEventHandler<HTMLButtonElement>;
  onClickRightBtn: MouseEventHandler<HTMLButtonElement>;
};

export const CourseCompletedModalUI = ({
  title,
  description,
  leftBtnTitle,
  rightBtnTitle,
  visibility,
  onClickLeftBtn,
  onClickRightBtn,
}: Props) => {
  return (
    <ModalUI
      data-testid="completed-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-10 px-3 text-center">
        <h1
          className="text-center text-2xl font-bold tracking-wider"
          data-testid="completed-modal-title"
        >
          {title}
        </h1>
        <hr className="border-under-line mt-5"></hr>
        <div
          className="mt-5 text-2xl tracking-wider"
          data-testid="completed-modal-description"
        >
          {description}
        </div>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="completed-modal-left-btn"
            buttonType="secondary"
            onClick={onClickLeftBtn}
          >
            {leftBtnTitle}
          </ButtonUI>
          <ButtonUI
            data-testid="completed-modal-right-btn"
            onClick={onClickRightBtn}
          >
            {rightBtnTitle}
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};
