import { useCallback, useEffect, useRef, useState } from "react";

import { SubjectSelectorItemFragment } from "../../generated/graphql";
import { SelectCustomStyleUI, SelectOption } from "../SelectCustomStyle";

export interface SubjectSelectorProps {
  placeholder?: string;
  id?: string;
  name: string;
  className?: string;
  value?: string;
  errorBorder?: boolean;
  defaultSubject?: SubjectSelectorItemFragment;
  subjects: SubjectSelectorItemFragment[];
  onSubjectChange: (subject: SubjectSelectorItemFragment | undefined) => void;
}

export const SubjectSelector: React.VFC<SubjectSelectorProps> = ({
  id,
  name,
  placeholder = "選択項目",
  className,
  value,
  errorBorder,
  defaultSubject,
  onSubjectChange,
  subjects,
  ...rest
}) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const subjectsRef = useRef<SubjectSelectorItemFragment[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<SelectOption[]>([]);
  const [responseSubjects, setResponseSubjects] = useState<
    SubjectSelectorItemFragment[]
  >([]);
  const [selectedSubject, setSelectedSubject] = useState<string>("");

  useEffect(() => {
    if (subjectsRef.current !== subjects) {
      subjectsRef.current = subjects;
      setInitialized(false);
      // onSubjectChange(undefined);
      // setSelectedSubject("");
    }
  }, [onSubjectChange, subjects]);

  useEffect(() => {
    if (!initialized) {
      setResponseSubjects(subjects);
      const options: SelectOption[] = subjects.map(
        (subject: SubjectSelectorItemFragment) => {
          if (defaultSubject && defaultSubject.id === subject.id) {
            setSelectedSubject(defaultSubject.name);
          }
          return {
            label: subject.name,
            value: subject.id,
          };
        }
      );
      if (defaultSubject && !defaultSubject.status) {
        // status がDB上で false となっているときに選択肢に追加する処理
        options.unshift({
          label: defaultSubject.name,
          value: defaultSubject.id,
        });
        setResponseSubjects([...subjects, defaultSubject]);
        setSelectedSubject(defaultSubject.name);
      }
      options.unshift({
        label: "",
        value: "",
      });
      setSubjectOptions(options);
      setInitialized(true);
    }
  }, [defaultSubject, initialized, subjects]);

  const onSelectionChange = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (!value) {
        onSubjectChange(undefined);
        setSelectedSubject("");
        return;
      }

      for (const subject of responseSubjects) {
        if (subject.id === value) {
          onSubjectChange(subject);
          setSelectedSubject(subject.name);
          return;
        }
      }
    },
    [onSubjectChange, responseSubjects]
  );

  // const onSelectionChange = ({
  //   name,
  //   value,
  // }: {
  //   name: string;
  //   value: string;
  // }): void => {
  //   if (!value) {
  //     onSubjectChange(undefined);
  //     setSelectedSubject("");
  //     return;
  //   }

  //   for (const subject of responseSubjects) {
  //     if (subject.id === value) {
  //       onSubjectChange(subject);
  //       setSelectedSubject(subject.name);
  //       return;
  //     }
  //   }
  // };

  return (
    <SelectCustomStyleUI
      placeholder={placeholder}
      options={subjectOptions}
      name="subject"
      className={className}
      id={id}
      value={selectedSubject}
      errorBorder={errorBorder}
      onChange={onSelectionChange}
      {...rest}
    />
  );
};

export const SUBJECT_SELECTOR_QUERY_VARIABLES = {
  filter: {
    active: true,
  },
  offset: 0,
  limit: 2 ** 31 - 1, // 2147483647 32 bit signed integer
};
