import React from "react";

import { Discount } from "../../../generated/graphql";
import DiscountConfirmUI from "../components/DiscountConfirm";
import { InternalDiscountCourse } from "../components/DiscountEdit";

export type DiscountEditConfirmPageProps = {
  input: Discount;
  limitedCourses?: InternalDiscountCourse[];
  onSubmit: () => void;
};

const DiscountEditConfirmPage: React.FC<DiscountEditConfirmPageProps> = ({
  input,
  limitedCourses,
  onSubmit,
}) => {
  return (
    <DiscountConfirmUI
      isNew={false}
      discount={input}
      limitedCourses={limitedCourses}
      onSubmit={onSubmit}
    />
  );
};

export default DiscountEditConfirmPage;
