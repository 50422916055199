import { useState, useCallback, MouseEventHandler, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { CourseCompletedModalUI } from "../../../components/CourseCompletedModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useCreateCourseMutation,
  useGenerateUploadCourseImageUrlMutation,
  useGenerateUploadCourseTeachingMaterialUrlMutation,
  CreateCourseInput,
  useGenerateUploadLessonMasterTeachingMaterialUrlMutation,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import { CourseNew } from "../../../lib/types/course";
import { CourseNewLocationState } from "../CoursesNewPage";
import CoursesNewConfirmPage from "./CoursesNewConfirmPage";

const CoursesNewConfirmPageContainer = () => {
  const history = useHistory();
  const [createCourse] = useCreateCourseMutation();
  const [generateUploadCourseImageUrl] =
    useGenerateUploadCourseImageUrlMutation();
  const [generateUploadCourseTeachingMaterialUrl] =
    useGenerateUploadCourseTeachingMaterialUrlMutation();
  const [generateUploadLessonMasterTeachingMaterialUrl] =
    useGenerateUploadLessonMasterTeachingMaterialUrlMutation();
  const location = useLocation<{
    courseNew: CourseNew;
  }>();
  const { courseNew } = location.state;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  // const [confirmModalVisibility, setConfirmModalVisibility] =
  //   useState<boolean>(false);
  const [errorModalTitle, setErrorModalTitle] = useState("登録に失敗しました");
  const [errorModalDescription, setErrorModalDescription] = useState(
    "コースの登録に失敗しました。<br />しばらく時間を置いてから、もう一度お試しください。"
  );
  const [createdCourseId, setCreatedCourseId] = useState("");
  useEffect(() => {
    return () => {
      isOpenCompleteModal && setIsOpenCompleteModal(false);
    };
  }, [isOpenCompleteModal]);

  const uploadCourseImages = useCallback(
    async (courseId: string): Promise<void> => {
      const uploadTasks = [];
      if (!courseNew || !courseNew.courseImages) {
        return;
      }
      try {
        // TODO: コードを時間があるときにでリファクタする
        let index = 0;
        for (const image of courseNew.courseImages) {
          if (!image) {
            index++;
            continue;
          }
          const presignedPostResult = await generateUploadCourseImageUrl({
            variables: {
              courseId,
              fileName: image.name as string,
              order: index + 1,
            },
          });
          const presignedPostData =
            presignedPostResult.data?.generateUploadCourseImageUrl;

          if (!presignedPostData) {
            return;
          }
          uploadTasks.push(
            new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", image);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadCourseImages error");
            })
          );
          index++;
        }
        await Promise.all(uploadTasks);
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setErrorModalTitle("一部登録に失敗しました");
        setErrorModalDescription(
          "コースの登録は完了しましたが<br />教材と商品イメージの登録に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadCourseImages error");
      }
    },
    [history, generateUploadCourseImageUrl, courseNew]
  );

  const uploadCourseTeachingMaterials = useCallback(
    async (courseId: string): Promise<void> => {
      const uploadTasks = [];
      if (!courseNew || !courseNew.teachingMaterials) {
        return;
      }
      try {
        for (const material of courseNew.teachingMaterials) {
          const presignedPostResult =
            await generateUploadCourseTeachingMaterialUrl({
              variables: {
                courseId,
                fileName: material?.name as string,
              },
            });
          const presignedPostData =
            presignedPostResult.data?.generateUploadCourseTeachingMaterialUrl;

          if (!presignedPostData) {
            return;
          }
          uploadTasks.push(
            new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", material);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadCourseTeachingMaterials error");
            })
          );
        }
        await Promise.all(uploadTasks);
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setErrorModalTitle("一部登録に失敗しました");
        setErrorModalDescription(
          "コースの登録は完了しましたが<br />教材の登録に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadCourseTeachingMaterials error");
      }
    },
    [history, generateUploadCourseTeachingMaterialUrl, courseNew]
  );

  const uploadLessonMasterTeachingMaterials = useCallback(
    async (
      lessonMasterId: string,
      lessonMasterOrder: number
    ): Promise<Promise<unknown>[]> => {
      const uploadTasks: Promise<unknown>[] = [];
      const lessonMaster = courseNew.lessonMasters.find(
        (master) => master.order === lessonMasterOrder
      );
      const masterTeachingLength = lessonMaster?.teachingMaterials?.length ?? 0;
      if (!(courseNew && masterTeachingLength > 0)) {
        return [];
      }

      try {
        for (const material of lessonMaster!.teachingMaterials!) {
          const presignedPostResult =
            await generateUploadLessonMasterTeachingMaterialUrl({
              variables: {
                lessonMasterId: lessonMasterId,
                fileName: material?.name as string,
              },
            });
          const presignedPostData =
            presignedPostResult.data
              ?.generateUploadLessonMasterTeachingMaterialUrl;

          if (!presignedPostData) {
            throw new Error("presignedPostData error");
          }
          uploadTasks.push(
            new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
              });
              formData.append("file", material);
              const xhr = new XMLHttpRequest();
              xhr.open("POST", `${presignedPostData.url}`, true);
              xhr.send(formData);
              xhr.onload = function () {
                this.status === 204 ? resolve(0) : reject(this.responseText);
              };
            }).catch(() => {
              throw new Error("uploadLessonMasterTeachingMaterials error");
            })
          );
        }
        return uploadTasks;
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return [];
        }
        setErrorModalTitle("一部登録に失敗しました");
        setErrorModalDescription(
          "コースの登録は完了しましたが<br />授業終了後配布教材の登録に失敗しました。<br />コース編集画面からもう一度お試しください。"
        );
        throw new Error("uploadLessonMasterTeachingMaterials error");
      }
    },
    [courseNew, generateUploadLessonMasterTeachingMaterialUrl, history]
  );

  const handleCreate = useCallback(async () => {
    const { courseImages, teachingMaterials, lessonMasters } = courseNew;
    const input: CreateCourseInput = {
      name: courseNew.name,
      courseId: courseNew.courseId,
      organizerId: courseNew.organizerId,
      targets: courseNew.targets,
      subjectIds: [
        courseNew.subjectEntity1?.id || "",
        courseNew.subjectEntity2?.id || "",
      ].filter((id) => id !== ""),
      levelId: courseNew.levelId,
      price: courseNew.price,
      overview: courseNew.overview,
      details: courseNew.details,
      lessonMasters: lessonMasters.map((master) => ({
        name: master.name,
        order: master.order,
      })),
      videos: courseNew.videos,
      // TODO: selectableNumberがstringで渡される問題を解決したらこっちでいい。
      // questionnaires: courseNew.questionnaires.map((questionnaire) => {
      //   const { typenameDisplay, ...ret } = questionnaire;
      //   return ret;
      // }),
      questionnaires: courseNew.questionnaires.map((questionnaire) => ({
        order: questionnaire.order,
        title: questionnaire.title,
        typename: questionnaire.typename,
        required: questionnaire.required,
        options: questionnaire.options,
        selectableNumber: Number(questionnaire.selectableNumber),
        condition: questionnaire.condition,
        maxScale: Number(questionnaire.maxScale),
        minLabel: questionnaire.minLabel,
        maxLabel: questionnaire.maxLabel,
      })),
    };
    try {
      if (!isLoading) {
        setIsLoading(true);
        const createResult = await createCourse({
          variables: {
            input,
          },
        });
        if (createResult.data?.createCourse && courseImages!.length > 0) {
          await uploadCourseImages(createResult.data?.createCourse.id!);
        }
        if (teachingMaterials && teachingMaterials.length > 0) {
          await uploadCourseTeachingMaterials(
            createResult.data?.createCourse.id!
          );
        }

        // lesson master teaching material tasks
        let uploadTasks: Promise<unknown>[] = [];
        const createdLessonMasters =
          createResult.data?.createCourse.lessonMasters ?? [];
        const existsMasterMaterials = lessonMasters.some(
          (master) => master?.teachingMaterials?.length ?? 0 > 1
        );
        if (existsMasterMaterials && createdLessonMasters.length) {
          for (const lessonMaster of createdLessonMasters) {
            const tasks = await uploadLessonMasterTeachingMaterials(
              lessonMaster.id!,
              lessonMaster.order
            );
            uploadTasks = uploadTasks.concat(tasks);
          }
        }
        if (uploadTasks.length > 0) {
          await Promise.all(uploadTasks);
        }

        if (createResult.data?.createCourse?.id) {
          setCreatedCourseId(createResult.data.createCourse.id);
        }
        setIsOpenCompleteModal(true);
      }
    } catch (error: any) {
      console.error(error);
      const graphQLErrors = error?.graphQLErrors ?? [];
      if (graphQLErrors.length) {
        const errCode: string = graphQLErrors[0]?.extensions?.code ?? "";
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
      }
      setConfirmErrorModalVisibility(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    courseNew,
    isLoading,
    createCourse,
    uploadCourseImages,
    uploadCourseTeachingMaterials,
    uploadLessonMasterTeachingMaterials,
    history,
  ]);

  const handleBack: MouseEventHandler<HTMLButtonElement> = () => {
    const state: CourseNewLocationState = {
      editingNewCourse: courseNew,
    };
    history.push("/course/new", state);
  };

  const linkToCourses = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    history.push("/courses");
  };

  const linkToClassNew = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    history.push(`/courses/${createdCourseId}/class/new`);
  };
  const clearErrorModal = () => {
    confirmErrorModalVisibility && setConfirmErrorModalVisibility(false);
  };

  return (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <CoursesNewConfirmPage
        isLoading={isLoading}
        courseNew={courseNew}
        handleCreate={handleCreate}
        handleBack={handleBack}
      />
      <CourseCompletedModalUI
        title="追加完了"
        description="追加が完了しました。"
        leftBtnTitle="コース一覧へ"
        rightBtnTitle="クラス登録へ"
        onClickLeftBtn={linkToCourses}
        onClickRightBtn={linkToClassNew}
        visibility={isOpenCompleteModal}
      />
      <ConfirmErrorModalUI
        title={errorModalTitle}
        description={errorModalDescription}
        btnTitle="閉じる"
        onClick={clearErrorModal}
        visibility={confirmErrorModalVisibility}
      />
    </>
  );
};

export default CoursesNewConfirmPageContainer;
