// このOrderStatusを修正する場合はstudypf-backend/packages/event-bridge/src/types.tsにも同様の修正を加えること。
export enum OrderStatus {
  /**
   * 申込処理中の状態。決済トランザクションがまだ完了していない状態。
   */
  InProgress = "inProgress",

  /**
   * 申込完了状態。決済トランザクションも環良している状態。
   */
  Submitted = "submitted",

  /**
   * キャンセル状態。決済のキャンセルも完了している状態。
   * ただし、購入した商品は利用可能な状態。
   */
  Canceled = "canceled",

  /**
   * 売上取消状態（売上確定と同月に売上取消した）。
   * 決済の売上取消も完了している状態。
   */
  SoldCancelled = "soldCancelled",

  /**
   * 返金状態（売上確定の次月以降に売上取消した）。
   * 決済の返金も完了している状態。
   */
  Refund = "refund",

  /**
   * 購入商品の期限切れ状態。
   * 時間経過によりキャンセル（継続課金解約）から遷移する状態。決済のキャンセルも完了している状態。
   * 購入した商品の利用可能も不可の状態。
   */
  Expired = "expired",

  /**
   * 注文完了していないが、セミナーの枠を確保している状態。
   * オーソリ確定前にこの状態に変更し、オーソリが完了したらSubmittedに変更する。
   * 注文履歴に表示されず購入した商品の利用可能も不可の状態。
   */
  Reserved = "reserved",
}
