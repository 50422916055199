import { ButtonUI, ButtonUIProps } from "../../../../../components/Button";
import styles from "./PlusButton.module.scss";

export const PlusButtonUI: React.FC<ButtonUIProps> = (props) => {
  return (
    <ButtonUI size="ll" className={styles.plusButton} {...props}>
      追加
    </ButtonUI>
  );
};
