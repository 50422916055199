import React from "react";

import { ButtonUI } from "../../../../components/Button";
import { ModalUI } from "../../../../components/Modal";
import styles from "./DiscountConfirmModal.module.css";

export type DiscountConfirmModalUIProps = {
  title: string;
  message: string;
  onConfirm: () => void;
};

const DiscountConfirmModalUI: React.FC<DiscountConfirmModalUIProps> = ({
  title,
  message,
  onConfirm,
}) => {
  return (
    <ModalUI visibility={true} className={`${styles.box}`}>
      <div className={`flex flex-col mt-6 mb-6 items-center h-full`}>
        <div
          className={`${styles.title}`}
          data-testid="discount-confirm-modal-title"
        >
          {title}
        </div>
        <hr className={`${styles.titleLine} mt-4 mb-4`} />
        <div
          className={`${styles.message} mb-4`}
          data-testid="discount-confirm-modal-message"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <div className="flex h-full">
          <ButtonUI
            data-testid="discount-confirm-modal-btn-confirm"
            buttonType="primary"
            className={`${styles.okButton} self-end`}
            onClick={onConfirm}
          >
            OK
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};

export default DiscountConfirmModalUI;
