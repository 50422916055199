import React, {
  useEffect,
  useState,
  useCallback,
  MouseEventHandler,
} from "react";

import * as History from "history";
import { useHistory, Prompt } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { UserConfirmUI } from "../../../components/UserConfirm";
import { ProfileEditFragment } from "../../../generated/graphql";
import { PageType } from "../../../lib/constants/pageType";
import { UserEdit } from "../../../lib/types";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "プロフィール設定",
  },
  {
    label: "プロフィール編集確認",
  },
];

export type ProfileConfirmPageProps = {
  user: ProfileEditFragment;
  profileEdit: UserEdit;
  isOpenCompleteModal: boolean;
  confirmErrorModalVisibility: boolean;
  confirmModalVisibility: boolean;
  handleUpdate: () => Promise<void>;
  clearErrorModal: () => void;
  clearCompletedModal: () => void;
  setConfirmModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileConfirmPage: React.FC<ProfileConfirmPageProps> = ({
  user,
  profileEdit,
  isOpenCompleteModal,
  confirmErrorModalVisibility,
  confirmModalVisibility,
  handleUpdate,
  clearErrorModal,
  clearCompletedModal,
  setConfirmModalVisibility,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [avatorUrl, setAvatorURL] = useState<string | null>(null);

  const history = useHistory();

  const onHandleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    handleUpdate();
  };

  const handleBack = useCallback(() => {
    history.push("/profile/edit");
  }, [history]);

  const confirmBack = useCallback(() => {
    setConfirmModalVisibility(true);
  }, [setConfirmModalVisibility]);

  const handleBackToTop: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/");
    }, [history]);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    let avator: string | null = null;
    if (profileEdit.avator) {
      avator = URL.createObjectURL(profileEdit.avator);
    } else if (user.avator) {
      avator = user.avator;
    }
    setAvatorURL(avator);
    return () => {
      avatorUrl && profileEdit.avator && URL.revokeObjectURL(avatorUrl);
      clearCompletedModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileEdit.avator]);

  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (location.pathname === "/profile/edit" && action === "POP") {
        confirmBack();
        return false;
      }
      return true;
    },
    [confirmBack]
  );

  return (
    <div>
      <h1 className="text-2xl mb-3">プロフィール編集確認</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <UserConfirmUI
          user={user}
          pageType={PageType.ProfileConfirmPage}
          userEdit={profileEdit}
          onHandleSubmit={onHandleSubmit}
          avatorUrl={avatorUrl}
          handleBack={confirmBack}
        ></UserConfirmUI>
        <CompletedModalUI
          title="設定完了"
          description="設定が完了しました。"
          btnTitle="TOPへ"
          onClick={handleBackToTop}
          visibility={isOpenCompleteModal}
        />
        <ConfirmErrorModalUI
          title="登録に失敗しました"
          description={`プロフィールの設定に失敗しました。
        しばらく時間を置いてから、もう一度お試しください。`}
          btnTitle="閉じる"
          onClick={clearErrorModal}
          visibility={confirmErrorModalVisibility}
        />
        <ConfirmModalUI
          title="確認"
          description={`編集中の内容は破棄されます。
よろしいですか？`}
          onCancel={() => {
            setConfirmModalVisibility(false);
          }}
          onSubmit={handleBack}
          visibility={confirmModalVisibility}
        />
      </div>
      <Prompt when={true} message={handleBlockBack} />
    </div>
  );
};

export default ProfileConfirmPage;
