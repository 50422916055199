import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import {
  Maybe,
  PaymentAccountTimingKbn,
  Paymtd,
} from "../../generated/graphql";
import { PaymentMismatchType } from "../constants/PaymentMismatch";
import { paymentStatuses } from "../constants/PaymentStatus";
import { Paymtd as PaymtdCd } from "../constants/Paymtd";
dayjs.locale(ja);

export const getMismatchTypeJA = (
  paymentMismatchType: Maybe<string> | undefined
) => {
  switch (paymentMismatchType) {
    case PaymentMismatchType.NotExistInMPL:
      return "かんたん決済に決済情報が存在しない";
    case PaymentMismatchType.NotExistInStudyPF:
      return "教育PFに決済情報が存在しない";
    case PaymentMismatchType.PriceMismatch:
      return "金額の不一致";
    case PaymentMismatchType.StatusMismatch:
      return "決済ステータスの不一致";
    case PaymentMismatchType.SoldDateTimeMismatch:
      return "決済処理日時の不一致";
    case PaymentMismatchType.MemberManageNoMismatch:
      return "加盟店管理番号の不一致";
    case PaymentMismatchType.SystemAuIdMismatch:
      return "システムau IDの不一致";
    default:
      return paymentMismatchType;
  }
};

export const getPaymtdJA = (paymtd: Maybe<string> | undefined) => {
  switch (paymtd) {
    case Paymtd.Combined:
      return "料金合算支払";
    case Paymtd.Credit:
      return "クレジットカード";
    case Paymtd.WebMoney:
      return "WebMoney";
    case Paymtd.AuPay:
      return "au PAY残高支払い";
    case Paymtd.AuPayCard:
      return "au PAYカード支払い";
    // TODO: PaymentMismatchCsvのpaymtdをstringからenumに変更対応したら下を消す
    case PaymtdCd.Combined:
      return "料金合算支払";
    case PaymtdCd.Credit:
      return "クレジットカード";
    case PaymtdCd.WebMoney:
      return "WebMoney";
    case PaymtdCd.AuPay:
      return "au PAY残高支払い";
    case PaymtdCd.AuPayCard:
      return "au PAYカード支払い";
    default:
      return "";
  }
};

export const formatYYYYMMDD = (target?: string | Date | null) => {
  return target ? dayjs(target).format("YYYY/MM/DD") : "";
};

export const formatPrice = (priceValue?: number | null) => {
  return priceValue != null ? Number(priceValue).toLocaleString("ja") : "";
};

export const getAccountTimingKbnJA = (
  accountTimingKbn: Maybe<PaymentAccountTimingKbn> | undefined
) => {
  switch (accountTimingKbn) {
    case PaymentAccountTimingKbn.SpecifiedDate:
      return "日指定";
    case PaymentAccountTimingKbn.EndOfMonth:
      return "月末";
    default:
      break;
  }
};

/**
 * 決済ステータスコードの文字列変換
 * @param target ステータスコード
 */
export const getPaymentStatusJA = (target: Maybe<number> | undefined) => {
  if (target == null) {
    // null, undefinedの場合は空文字を返す
    return "";
  }
  return paymentStatuses.find((item) => item.value === target)?.label;
};
