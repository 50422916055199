import React, { useRef } from "react";

import {
  CustomerBillingSearchResultsFragment,
  BillingCustomersTableRowFragment,
} from "../../../../generated/graphql";
import CustomersTableRowUI from "./BillingCustomersTableRow";

export const BillingCustomersTableUI: React.FC<{
  billingCustomers: CustomerBillingSearchResultsFragment;
}> = ({ billingCustomers }) => {
  const table = useRef<HTMLTableElement>(null);

  return (
    <table
      ref={table}
      className="w-full border-collapse border border-dark-gray"
      style={{ minWidth: "max-content" }}
    >
      <thead className="bg-subtitle text-white">
        <tr
          style={{ whiteSpace: "pre", overflowX: "scroll" }}
          className="text-left h-16"
        >
          <th className={`pl-5`}>項番</th>
          <th className={`pl-5`}>お客様番号</th>
          <th className={`pl-5`}>プラン名</th>
          <th className={`pl-5`}>決済方法</th>
          <th className={`pl-5`}>申込年月日</th>
          <th className={`px-5`}>解約年月日</th>
        </tr>
      </thead>
      <tbody style={{ whiteSpace: "pre", overflowX: "scroll" }}>
        {billingCustomers.nodes.map(
          (
            billingCustomer: BillingCustomersTableRowFragment,
            index: number
          ) => (
            <CustomersTableRowUI
              data-testid="billing-customers-table-row"
              key={billingCustomer.id}
              index={index}
              billingCustomer={billingCustomer}
            ></CustomersTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
