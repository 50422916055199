import React, { useEffect, useCallback, MouseEventHandler } from "react";

import { useHistory } from "react-router";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { UserDetailUI } from "../../../components/UserDetail";
import { ProfileQuery } from "../../../generated/graphql";

export type ProfilePageProps = {
  data: ProfileQuery;
};
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "プロフィール設定",
  },
];

const ProfilePage: React.VFC<ProfilePageProps> = ({ data }) => {
  const history = useHistory();
  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  const handleToProfileEdit: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/profile/edit");
    }, [history]);
  return (
    <div>
      <h1 className="text-2xl mb-3">プロフィール設定</h1>
      <hr className="border-dark-gray border mb-2.5" />
      <UserDetailUI className="ml-5" user={data.me} />
      <div className="flex justify-center space-x-10 mt-20 mb-40">
        <ButtonUI buttonType="secondary" onClick={history.goBack}>
          戻る
        </ButtonUI>
        <ButtonUI onClick={handleToProfileEdit}>編集</ButtonUI>
      </div>
    </div>
  );
};

export default ProfilePage;
