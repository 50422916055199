import React from "react";

import DiscountsTableRowUI, { discount } from "./DiscountsTableRow";
import styles from "./discountTable.module.css";

export type DiscountsTableUIProps = {
  dataList: {}[];
  onDeleting: (id: string) => void;
  onEditing: (is: string) => void;
};

export const DiscountsTableUI: React.FC<DiscountsTableUIProps> = ({
  dataList,
  onDeleting,
  onEditing,
}) => {
  return (
    <div className="overflow-scroll">
      <table className={`${styles.table}`}>
        <thead className={`${styles.thead}`}>
          <tr className={`${styles.tr}`}>
            <th className={`${styles.trCode}`}>クーポンコード</th>
            <th className={`${styles.trName}`}>クーポン名</th>
            <th className={`${styles.trComment}`}></th>
            <th className={`${styles.trStartDate}`}>発効日時</th>
            <th className={`${styles.trEndDate}`}>終了日時</th>
            <th className={`${styles.trPrice}`}>割引（税抜）</th>
            <th className={`${styles.trDurationMonths}`}>割引期間</th>
            <th className={`${styles.trButtons}`}></th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((row, index: number) => (
            <DiscountsTableRowUI
              data-testid="discounts-table-row"
              key={(row as discount).id}
              index={index}
              row={row as discount}
              onDeleting={onDeleting}
              onEditing={onEditing}
            ></DiscountsTableRowUI>
          ))}
        </tbody>
      </table>
    </div>
  );
};
