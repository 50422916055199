import React, { HTMLAttributes } from "react";

import styles from "./ModalFormGroup.module.css";

export type ModalFormGroupUIProps = HTMLAttributes<HTMLElement>;

export const ModalFormGroupUI: React.FC<ModalFormGroupUIProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={`form-group pt-3 pb-3 space-y-2 ${className} ${styles.formGroup}`}
    >
      {children}
    </div>
  );
};
