import React, {
  ChangeEvent,
  ChangeEventHandler,
  InputHTMLAttributes,
} from "react";

import styles from "./Checkbox.module.css";

export type CheckboxUIProps = {
  type?: "s" | "m";
  "data-testid"?: string;
  onCheckChange?: (checked: boolean, value: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckboxUI: React.FC<CheckboxUIProps> = ({
  className = "",
  type = "s",
  children,
  id,
  name,
  onCheckChange,
  ...rest
}) => {
  const boxSize = type === "s" ? "h-4 w-4" : "h-7 w-7";
  const dataTestid = rest["data-testid"] || "checkbox";

  const onChange: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checked: boolean = event.target.checked;
    onCheckChange && onCheckChange(checked, event.target.value);
    if (rest.onChange !== undefined) {
      rest.onChange(event);
    }
  };
  return (
    <div className={`flex items-center ${className}`}>
      <input
        className={`appearance-none bg-white border border-dark-gray ${boxSize} rounded ${styles.check}`}
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        {...rest}
      />
      <label htmlFor={id} data-testid={`${dataTestid}-label`}>
        {children}
      </label>
    </div>
  );
};
