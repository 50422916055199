import React from "react";

import { TableUI } from "../../../../components/TableUI";
import {
  AnnouncementsFragment,
  AnnouncementsTableRowFragment,
} from "../../../../generated/graphql";
import AnnouncementsTableRowUI from "./AnnouncementsTableRow";

export type AnnouncementsTableUIProps = {
  announcements: AnnouncementsFragment;
};

export const AnnouncementsTableUI: React.FC<AnnouncementsTableUIProps> = ({
  announcements,
}) => {
  return (
    <TableUI
      className="mt-3 text-left"
      headers={["公開ステータス", "お知らせID", "種別", "お知らせ件名"]}
      colClassNames={["w-32", "w-24", "w-auto"]}
    >
      {announcements.nodes.map(
        (announcement: AnnouncementsTableRowFragment, index: number) => (
          <AnnouncementsTableRowUI
            key={announcement.id}
            index={index}
            announcement={announcement}
          ></AnnouncementsTableRowUI>
        )
      )}
    </TableUI>
  );
};
