import React, { useState, useEffect, useRef, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { ContentImage, CourseImage } from "../../generated/graphql";
import { ValidatedFile } from "../../lib/types";
import { DnDThumb, DnDThumbProps } from "./DnDThumb";
import styles from "./ImagesInput.module.scss";
import { ReactComponent as JPG } from "./JPG.svg";
import { ReactComponent as JpgInActive } from "./JpgInActive.svg";
import { ReactComponent as PNG } from "./PNG.svg";
import { ReactComponent as PngInActive } from "./PngInActive.svg";

export interface ImagesInputUIProps<T extends ContentImage | CourseImage> {
  initialImages?: (T | File)[];
  editingImageFiles?: File[]; // 編集中のコース画像
  onFilesChange?: (files: ValidatedFile[]) => void;
}

export const ImagesInputUI = ({
  initialImages,
  editingImageFiles,
  onFilesChange,
  ...rest
}: ImagesInputUIProps<ContentImage | CourseImage>) => {
  const [urlDict, setUrlDict] = useState<{ [key: string]: string }>({});
  const [files, setFiles] = useState<ValidatedFile[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const checkAndAssign = useCallback(
    (file: File, previewId: number, imageId?: string): ValidatedFile => {
      let isError;
      let errSize;
      let errExt;
      if (file.size > 10 * 1000 * 1000) {
        isError = true;
        errSize = "some error of size";
      }
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        isError = true;
        errExt = "some error of type";
      }
      let obj: ValidatedFile = Object.assign(file, {
        previewId: previewId,
        url: isError ? null : "",
        isError: isError,
        errSize: errSize,
        errExt: errExt,
        imageId: imageId ? imageId : "",
        key: uuidv4(),
      });
      return obj;
    },
    []
  );

  const updateFilesState = useCallback(
    (newFiles: ValidatedFile[]) => {
      const prevFiles = files;
      const compareResult1 = prevFiles.map((f: ValidatedFile) => {
        const index1 = prevFiles.indexOf(f);
        const index2 = newFiles.indexOf(f);
        return index1 === index2;
      });
      const compareResult2 = files.map((f: ValidatedFile) => {
        const index1 = prevFiles.indexOf(f);
        const index2 = newFiles.indexOf(f);
        return index1 === index2;
      });
      if (
        compareResult1.indexOf(false) >= 0 ||
        compareResult2.indexOf(false) >= 0 ||
        compareResult1.length !== compareResult2.length ||
        newFiles.length !== prevFiles.length
      ) {
        const newUrlDict: { [key: string]: string } = {};
        const deletingUrlDict: { [key: string]: string } = { ...urlDict };
        setFiles(newFiles);
        // 新規に追加された画像を allocate
        newFiles.forEach((f: ValidatedFile) => {
          const key: string = f.key!;
          if (!urlDict[key]) {
            newUrlDict[key] = URL.createObjectURL(f);
          } else {
            newUrlDict[key] = urlDict[key];
            delete deletingUrlDict[key];
          }
        });
        // 削除された画像のメモリを開放
        Object.keys(deletingUrlDict).forEach((key: string) => {
          const url = deletingUrlDict[key];
          url && URL.revokeObjectURL(url);
        });
        setUrlDict(newUrlDict);
        onFilesChange && onFilesChange(newFiles);
      }
    },
    [files, onFilesChange, urlDict]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = [
        ...files,
        ...acceptedFiles.map((file: File, index: number) => {
          return checkAndAssign(file, files.length + index);
        }),
      ];
      updateFilesState(newFiles);
    },
    [checkAndAssign, files, updateFilesState]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    maxFiles: 9 - files.length,
  });

  const handleDelete = useCallback(
    (previewId: string) => {
      const newFiles = [
        ...files.filter((item: ValidatedFile) => {
          if (item.previewId === Number(previewId)) {
            const url = item.key && urlDict[item.key];
            if (url) {
              URL.revokeObjectURL(url);
            }
          }
          return item.previewId !== Number(previewId);
        }),
      ];
      // 削除後に画像を新たに追加した場合にpreviewIdが被るパターンがあるため、previewIdをセットし直す
      newFiles.forEach((f, index) => {
        f.previewId = index;
      });
      updateFilesState(newFiles);
    },
    [files, updateFilesState, urlDict]
  );

  const reselect = useCallback((previewId: string) => {
    inputRef.current?.setAttribute("data-image-id", previewId);
    inputRef?.current?.click();
  }, []);

  const onReselect: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e.target.files && e.target.files[0];
      const previewId = e.target.getAttribute("data-image-id");
      if (file === null) {
        return;
      }
      const newFiles = files.map((item: ValidatedFile) => {
        let convertId = parseInt(previewId!);
        if (item.previewId === convertId) {
          return checkAndAssign(file, item.previewId);
        }
        return item;
      });
      updateFilesState(newFiles);
    },
    [checkAndAssign, files, updateFilesState]
  );

  const fetchFile = useCallback(async (url: string, fileName: string) => {
    try {
      const res = await fetch(url);
      const blob: Blob = await res.blob();
      const f: File = new File([blob], fileName, { type: blob.type });
      return f;
    } catch (err) {
      console.error("catch error at downloadFile", err);
    }
  }, []);

  const downloadImages = useCallback(
    async (images: (ContentImage | CourseImage)[]) => {
      const results: (ValidatedFile | undefined)[] = await Promise.all(
        images.map(async (item: CourseImage | ContentImage, index: number) => {
          // URLがない時は編集中のファイルを取得する
          if (!item.url) {
            const file: File | undefined = editingImageFiles![index];
            if (file === undefined) {
              return undefined;
            }
            const noFile: ValidatedFile = Object.assign(file!, {
              previewId: index,
              url: "",
              imageId: item.id ? item.id : "",
              isError: false,
              errSize: "",
              errExt: "",
              key: uuidv4(),
            });
            return noFile;
          }
          const promise: File | undefined = await fetchFile(
            item.url,
            item.name
          );
          return promise && checkAndAssign(promise, index, item.id);
        })
      );
      const newUrlDict: { [key: string]: string } = { ...urlDict };
      results.forEach((f: ValidatedFile | undefined) => {
        if (f === undefined) {
          return;
        }
        const key: string = f.key!;
        if (newUrlDict[key]) {
          return;
        }
        newUrlDict[key] = URL.createObjectURL(f);
      });
      setUrlDict(newUrlDict);
      setFiles(results.filter((r) => r !== undefined) as ValidatedFile[]);
    },
    [checkAndAssign, editingImageFiles, fetchFile, urlDict]
  );

  const ITEM_TYPE = "image";
  const findCard: DnDThumbProps<typeof ITEM_TYPE>["findCard"] = useCallback(
    (id: number) => {
      const index = files.findIndex((f) => f.previewId === id);
      return {
        file: files[index],
        index,
      };
    },
    [files]
  );

  const moveCard: DnDThumbProps<typeof ITEM_TYPE>["moveCard"] = useCallback(
    (id, atIndex) => {
      const index = findCard(id).index;
      if (index === atIndex) {
        return;
      }

      const _files = [...files];
      const value = _files[index];
      _files.splice(index, 1);
      _files.splice(atIndex, 0, value);
      updateFilesState(_files);
    },
    [files, findCard, updateFilesState]
  );

  const setCard: DnDThumbProps<typeof ITEM_TYPE>["setCard"] =
    useCallback(() => {
      files.forEach((f) => {
        f.previewId = files.indexOf(f);
      });
      onFilesChange && onFilesChange(files);
    }, [files, onFilesChange]);

  // この useEffect を別の useEffect と一緒にしてはいけない。
  // 依存関係が増えると意図しないタイミングで処理が実行されてしまい画像がうまく表示されない。
  useEffect(() => {
    return () => {
      const newUrlDict = { ...urlDict };
      const keys = Object.keys(urlDict);
      let hasDeleted: boolean = false;
      keys.forEach((key: string) => {
        // 画像を表示しているエレメントが画面から削除されていたら revokeObjectURL する
        const div = window.document.getElementById(key);
        if (!div) {
          const url = urlDict[key];
          url && URL.revokeObjectURL(url);
          delete newUrlDict[key];
          hasDeleted = true;
        }
      });
      if (hasDeleted) {
        setUrlDict(newUrlDict);
      }
    };
  }, [urlDict]);

  // 初期化処理
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (!initialImages || !initialImages.length) {
        return;
      }
      if (initialImages[0] instanceof File) {
        onDrop(initialImages as File[]);
      } else {
        downloadImages(initialImages as (CourseImage | ContentImage)[]);
      }
    }
  }, [
    checkAndAssign,
    downloadImages,
    files,
    initialImages,
    initialized,
    onDrop,
  ]);
  return (
    <>
      {files.length < 9 ? (
        <div className="place-content-center">
          <div className={styles.container} {...getRootProps()}>
            <input {...getInputProps()} data-testid="content-images-input" />
            <div className="flex flex-row mb-3">
              <PNG className="mr-3"></PNG>
              <JPG></JPG>
            </div>
            <p className="mb-3">
              ドラッグアンドドロップまたは
              <span className="text-blue">ファイルをアップロード</span>
              してください。
            </p>
            <p className="text-gray-dark">最大9枚までアップロード可能</p>
          </div>
        </div>
      ) : (
        <div className={styles.containerInActive}>
          <div className="flex flex-row mb-3">
            <PngInActive className="mr-3"></PngInActive>
            <JpgInActive></JpgInActive>
          </div>
          <p className="mb-3">
            ドラッグアンドドロップまたはファイルをアップロードしてください。
          </p>
          <p className="text-gray-dark">最大9枚までアップロード可能</p>
        </div>
      )}
      <div
        className={`${styles.previewContainer} place-content-center ${
          files.length === 0 ? "hidden" : ""
        }`}
        style={{ paddingLeft: "0px" }}
      >
        <div className="grid grid-cols-3 gap-4 place-content-evenly">
          {files.map((file: ValidatedFile, index: number) => {
            return (
              <DnDThumb
                key={index}
                itemType={ITEM_TYPE}
                file={file}
                url={urlDict[file.key!]}
                onDelete={handleDelete}
                onReselect={reselect}
                moveCard={moveCard}
                findCard={findCard}
                setCard={setCard}
              />
            );
          })}
        </div>
      </div>
      <input
        type="file"
        ref={inputRef}
        className="hidden"
        data-image-id=""
        data-testid="content-images-hidden-input"
        onChange={onReselect}
      />
    </>
  );
};
