import dayjs from "dayjs";

// カタカナ(20文字以内)
export const getValidationKana = (): RegExp => {
  return /^[\u30A0-\u30FF]{1,20}$/;
};

// URL(半角英数字または_40文字以内)
export const getValidationUrl = (): RegExp => {
  return /^[a-zA-Z0-9_]{1,40}$/;
};

export const validatePublishEndedAt = (
  publishStartedAt?: Date | null,
  publishEndedAt?: Date | null
) => {
  if (!publishStartedAt || !publishEndedAt) {
    return undefined;
  }
  if (!dayjs(publishEndedAt).isAfter(dayjs(publishStartedAt), "minute")) {
    return "公開開始日時よりも後の日時を設定してください。";
  }
  return undefined;
};
