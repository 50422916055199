import React, { useState } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import {
  PaymentMismatchDetailFragment,
  PaymentType,
} from "../../../../generated/graphql";
import { getPaymentStatusJA } from "../../../../lib/utils/payment";
dayjs.locale(ja);

export type PaymentTableRowProps = {
  payment: PaymentMismatchDetailFragment["payment"];
};

const getStartedAt = (payment: PaymentMismatchDetailFragment["payment"]) => {
  if (!payment) {
    return undefined;
  }
  if (!payment.lessons) {
    return undefined;
  }
  const lessons = payment.lessons;
  // クラスの初回レッスンの開催日を取得する
  let tmp: Date[] = lessons.map((lesson) => new Date(lesson.startedAt));
  const startDateItems = tmp.sort((a: Date, b: Date) => {
    return a.getTime() - b.getTime();
  });
  return startDateItems[0];
};

const PaymentTableRowUI: React.FC<PaymentTableRowProps> = ({ payment }) => {
  const [productProvisionDate, setProductProvisionDate] = useState<Date | null>(
    !payment?.clazzId ? payment!.submittedAt! : null
  );

  if (!payment) {
    return <></>;
  }

  const startedAt = getStartedAt(payment);
  if (startedAt && !productProvisionDate) {
    setProductProvisionDate(startedAt);
  }

  return (
    <tr data-testid="payment-mismatch-payment-table-row" className="h-12">
      <td
        className="border-r border-dark-gray pl-1 pr-1 text-center"
        data-testid="payInfoNo"
      >
        {payment.payInfoNo}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1" data-testid="orderId">
        {payment.orderId}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="customerId"
      >
        <LinkTextUI to={`/customers/${payment.customerId}`}>
          {payment.memberId}
        </LinkTextUI>
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="customerName"
      >
        {payment.customerName}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="customerNameKana"
      >
        {payment.customerNameKana}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="contentSytemId"
      >
        {payment.contentSytemId
          ? `${payment.contentName}(ID:${payment.contentSytemId})`
          : `${payment.courseName}(ID:${payment.courseSytemId})`}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="priceType"
      >
        {payment.typename === PaymentType.ContBill ? `継続` : `従量`}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="priceValue"
      >
        {payment.priceValue &&
          Intl.NumberFormat("ja").format(payment.priceValue)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="paymentStatus"
      >
        {getPaymentStatusJA(payment.paymentStatus)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="submittedAt"
      >
        {payment.submittedAt && dayjs(payment.submittedAt).format("YYYY/MM/DD")}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="soldDateTime"
      >
        {payment.soldDateTime &&
          dayjs(payment.soldDateTime).format("YYYY/MM/DD")}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="productProvisionDate"
      >
        {productProvisionDate &&
          dayjs(productProvisionDate).format("YYYY/MM/DD")}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="updatedAt"
      >
        {payment.updatedAt && dayjs(payment.updatedAt).format("YYYY/MM/DD")}
      </td>
    </tr>
  );
};

export default PaymentTableRowUI;
