import React, { MouseEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ReactComponent as MoreIcon } from "../../../components/more.svg";
import { LessonsResultsFragment } from "../../../generated/graphql";
import { LessonsUI } from "./components/Lessons";

import { teachingMaterial } from ".";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "授業スケジュール",
  },
];

export type LessonsPageProps = {
  lessons: LessonsResultsFragment;
  isOperationMonitor: boolean;
  onDisplayMore: MouseEventHandler;
  openTeachingMaterialsModal: (
    courseTitle: string,
    teachingMaterials: teachingMaterial[]
  ) => void;
};

const LessonsPage: React.FC<LessonsPageProps> = ({
  lessons,
  isOperationMonitor,
  onDisplayMore,
  openTeachingMaterialsModal,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">授業スケジュール</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      {lessons.nodes.length > 0 ? (
        <div className="flex justify-center mt-5 mb-10">
          <div>
            <span data-testid="lesson-schedules-total-count" className="ml-5">
              全{lessons.totalCount}件中{lessons.nodes.length}件表示
            </span>
            <LessonsUI
              lessons={lessons}
              isOperationMonitor={isOperationMonitor}
              openTeachingMaterialsModal={openTeachingMaterialsModal}
            ></LessonsUI>
            {lessons.totalCount !== lessons.nodes.length && (
              <div className="flex justify-center">
                <button
                  data-testid="lessons-page-display-more-btn"
                  className="text-blue hover:underline active:text-blue-active active:underline h-8 mt-5 mb-10"
                  onClick={onDisplayMore}
                >
                  <MoreIcon></MoreIcon>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container mt-5">
          {lessons.totalCount === 0 && (
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="lesson-schedules-no-data-message"
              >
                該当する授業がありません。
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LessonsPage;
