import React, { HTMLAttributes } from "react";

import { useBreadcrumb } from "./context";

export type Breadcrumb = {
  label: string;
};

export type BreadcrumbProps = {} & HTMLAttributes<HTMLElement>;

export const BreadcrumbUI: React.FC<BreadcrumbProps> = ({ className }) => {
  const context = useBreadcrumb();
  return (
    <div
      className={`text-sm flex space-x-2 ${className}`}
      data-testid="breadcrumb"
    >
      {context.breadcrumbItems.map((v, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 0 && <span>{">"}</span>}
            <span>{v.label}</span>
          </React.Fragment>
        );
      })}
    </div>
  );
};
