import React from "react";

import { ContBillPaymentDetailFragment } from "../../../../../generated/graphql";
import {
  formatPrice,
  formatYYYYMMDD,
  getAccountTimingKbnJA,
  getPaymtdJA,
} from "../../../../../lib/utils/payment";

export type ContBillPaymentTableRowProps = {
  paymentTransaction: ContBillPaymentDetailFragment;
};

export const ContBillPaymentTableRowUI: React.VFC<
  ContBillPaymentTableRowProps
> = ({ paymentTransaction }) => {
  return (
    <tr data-testid="payment-mismatch-payment-table-row" className="h-12">
      <td
        className="border-r border-dark-gray pl-1 pr-1 text-center"
        data-testid="continueAccountId"
      >
        {paymentTransaction.continueAccountId}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="memberId"
      >
        {paymentTransaction.order.customer?.profile?.memberId}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="planName"
      >
        {paymentTransaction.order.name}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="accountTimingKbn"
      >
        {getAccountTimingKbnJA(paymentTransaction.accountTimingKbn)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="paymentDay"
      >
        {paymentTransaction.paymentDay}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="firstPaymentDate"
      >
        {formatYYYYMMDD(paymentTransaction.firstPaymentDate)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="initialValue"
      >
        {formatPrice(paymentTransaction.price.initialValue)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="priceValue"
      >
        {formatPrice(paymentTransaction.price.value)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1" data-testid="orderId">
        {paymentTransaction.order.systemId}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1" data-testid="paymtd">
        {getPaymtdJA(paymentTransaction.paymtd)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1" data-testid="status">
        {paymentTransaction.order.canceledAt == null ? "入会" : "退会"}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="submittedAt"
      >
        {formatYYYYMMDD(paymentTransaction.order.submittedAt)}
      </td>
      <td
        className="border-r border-dark-gray pl-2 pr-1"
        data-testid="canceledAt"
      >
        {formatYYYYMMDD(paymentTransaction.order.canceledAt)}
      </td>
    </tr>
  );
};
