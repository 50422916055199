import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SubmitHandler } from "react-hook-form";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { UserEditUI } from "../../../components/UserEdit";
import { UsersEditQuery } from "../../../generated/graphql";
import { PageType } from "../../../lib/constants/pageType";
import { UserEdit } from "../../../lib/types";

export type UsersEditPageProps = {
  data: UsersEditQuery;
  from?: string;
};

type Params = {
  id: string;
};

const UsersExternalEditPage: React.FC<UsersEditPageProps> = ({
  data,
  from,
}) => {
  const { id } = useParams<Params>();
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const [avatorUrl, setAvatorURL] = useState<string | null>(null);
  const [isErrorSelectImage, setIsErrorSelectImage] = useState(false);
  const [isErrorFileSizeOver, setIsErrorFileSizeOver] = useState(false);
  const [hasAdminAuthority] = useState(data.hasAdminAuthority);

  const [user, setUser] = useState<UserEdit>({
    id: data.user.id,
    userId: data.user.userId,
    familyName: data.user.familyName,
    givenName: data.user.givenName,
    familyNameKana: data.user.familyNameKana || "",
    givenNameKana: data.user.givenNameKana || "",
    email: data.user.email,
    roleIds: data.user.roles.map((role) => role.id),
    status: data.user.status,
    tel: data.user.tel || "",
    gender: data.user.gender,
    birthday: data.user.birthday || "",
    birthplace: data.user.birthplace || "",
    yearsOfTeaching: !!data.user.yearsOfTeaching
      ? data.user.yearsOfTeaching
      : null,
    message: data.user.message || "",
    selfIntroduction: data.user.selfIntroduction || "",
    hobby: data.user.hobby || "",
    avator: null,
    extra: data.user.extra || false,
  });

  const onHandleSubmit: SubmitHandler<UserEdit> = () => {
    if (isErrorSelectImage || isErrorFileSizeOver) {
      return;
    }
    history.push(`/users/${id}/confirm`, {
      userEdit: user,
      user: data?.user,
      from,
    });
  };

  const handleBack = () => {
    if (from === undefined) {
      history.push(`/users`);
    } else {
      history.push(`/users/${user.id}`);
    }
  };

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      });
    },
    [user]
  );

  const onChangeRoles: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const roleIds = [event.target.value];
      setUser({
        ...user,
        roleIds,
      });
    },
    [user]
  );

  const onChangeRadio: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setUser({
        ...user,
        [event.target.name]: event.target.value,
      });
    },
    [user]
  );

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let isFileError = false;
      setIsErrorFileSizeOver(false);
      setIsErrorSelectImage(false);
      if (
        event.target?.files &&
        event.target?.files[0] &&
        event.target?.files[0].size > 10 * 1000 * 1000
      ) {
        setIsErrorFileSizeOver(true);
        isFileError = true;
      }
      if (
        event.target?.files &&
        event.target?.files[0] &&
        !["image/jpeg", "image/png"].includes(event.target?.files[0].type)
      ) {
        setIsErrorSelectImage(true);
        isFileError = true;
      }

      if (!event.target?.files || isFileError) {
        return;
      }
      setUser({
        ...user,
        [event.target.name]: event.target.files && event.target.files[0],
      });
    },
    [user]
  );

  useEffect(() => {
    const BREAD_CRUMBS: Breadcrumb[] = [
      {
        label: "TOP",
      },
      {
        label: "設定",
      },
      {
        label: "教師スタッフ一覧",
      },
      {
        label: "【外部】教師スタッフ編集",
      },
    ];
    setBreadcrumbItems(BREAD_CRUMBS);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let avator: string | null = null;
    if (user.avator) {
      avator = URL.createObjectURL(user.avator);
    } else if (data?.user.avator) {
      avator = data.user.avator;
    }
    setAvatorURL(avator);
    return () => {
      avatorUrl && URL.revokeObjectURL(avatorUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <h1 className="text-2xl mb-3" data-testid="users-edit-page-title">
        【外部】教師スタッフ編集
      </h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      {user.userId ? (
        <UserEditUI
          type="user"
          user={user}
          hasAdminAuthority={hasAdminAuthority}
          onHandleSubmit={onHandleSubmit}
          onChangeInput={onChangeInput}
          onChangeRoles={onChangeRoles}
          onChangeRadio={onChangeRadio}
          onChangeFile={onChangeFile}
          handleBack={handleBack}
          avatorUrl={avatorUrl}
          isErrorSelectImage={isErrorSelectImage}
          isErrorFileSizeOver={isErrorFileSizeOver}
          pageType={PageType.UsersEditPage}
          isErrorExistsUser={false}
          useableServiceKinds={data.user.useableServiceKinds}
        ></UserEditUI>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default UsersExternalEditPage;
