import React, { InputHTMLAttributes, useState, useRef, useEffect } from "react";

import { ContentImage } from "../../generated/graphql";
import { TextUI } from "../Text";
import styles from "./FileInputTypeImage.module.scss";

export type FileInputTypeImageUIProps = {
  src?: string;
  fname?: string;
  type?: string;
  previewWidth?: number;
  previewHeight?: number;
  isErrorSelectImage?: boolean;
  isErrorFileSizeOver?: boolean;
  errorIdPrefix?: string;
  exitstImage?: ContentImage;
  onDelete?: (removeFileName: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const FileInputTypeImageUI: React.FC<FileInputTypeImageUIProps> = ({
  id,
  className = "",
  src,
  fname,
  type = "add",
  previewWidth = "214px",
  previewHeight = "131px",
  onChange,
  onDelete,
  children,
  isErrorSelectImage,
  isErrorFileSizeOver,
  errorIdPrefix,
  exitstImage,
  ...rest
}) => {
  const [url, setUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (fileName) {
      onDelete && onDelete(fileName!);
    }
    url && URL.revokeObjectURL(url);
    const file = e.target.files && e.target.files[0];

    setFileName(file?.name);
    const newUrl =
      file && file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : undefined;
    setUrl(newUrl);
    onChange && onChange(e);
  };
  const handleDelete = () => {
    if (fileName) {
      onDelete && onDelete(fileName!);
    }
    setUrl("");
    setFileName("");
  };

  const handleSelectImage = () => {
    inputRef?.current?.click();
  };
  const previewUrl = url || src || exitstImage?.url;

  useEffect(() => {
    if (exitstImage) {
      setFileName(exitstImage.name);
      setUrl(previewUrl);
    }
  }, [exitstImage, previewUrl]);

  useEffect(() => {
    return () => {
      if (url) {
        // メモリ解放
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  return (
    <div className="flex flex-col">
      <label htmlFor={id} className={styles.label}>
        {previewUrl ? (
          <div
            className={styles.previewBox}
            data-testid="user-avator-preview"
            style={{
              backgroundImage: `url(${previewUrl})`,
              width: previewWidth,
              height: previewHeight,
              objectFit: "cover",
            }}
          ></div>
        ) : (
          <div className={styles.labelText}>
            <span className="text-blue text-2xl">＋</span>
            <span className="leading-3 text-gray-dark pt-3 pb-3">
              画像形式 : JPEG または PNG
              <br />
              サイズ : 1500px<small>&times;</small>1500pxまで
              <br />
              ファイルサイズ : 最大 10 MB
            </span>
          </div>
        )}
      </label>
      {fileName ? <p>{fileName}</p> : <></>}
      {type === "confirm" && fname && <p>{fname}</p>}
      {type === "add" && (
        <>
          <div
            className={`${styles.btnWrap} ${
              fileName ? styles.btnWrapActive : ""
            }`}
          >
            <span onClick={handleDelete}>画像削除</span>
            <span onClick={handleSelectImage}>画像変更</span>
          </div>
          <input
            id={id}
            type="file"
            ref={inputRef}
            className="hidden"
            onChange={handleChange}
            {...rest}
          />
          {isErrorSelectImage && (
            <TextUI
              className="text-error"
              data-testid={`${errorIdPrefix}-image-type`}
            >
              .jpg または .png 形式の画像を指定してください。
            </TextUI>
          )}
          {isErrorFileSizeOver && (
            <TextUI
              className="text-error"
              data-testid={`${errorIdPrefix}-image-size`}
            >
              アップロード可能なファイルサイズは最大10MBです。
            </TextUI>
          )}
        </>
      )}
    </div>
  );
};
