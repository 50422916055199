import React, { useEffect } from "react";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { UsersEditQuery } from "../../../generated/graphql";
import { UserEditUI } from "./components/UserEdit";
import { UserEditFormValues } from "./types";

export type UsersEditPageProps = {
  data: UsersEditQuery;
  from?: string;
  onSubmit: SubmitHandler<UserEditFormValues>;
  onBack: React.MouseEventHandler<HTMLButtonElement>;
};

const UsersEditPage: React.FC<UsersEditPageProps> = ({
  data,
  from,
  onSubmit,
  onBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    const BREAD_CRUMBS: Breadcrumb[] = [
      {
        label: "TOP",
      },
      {
        label: "設定",
      },
      {
        label: "教師スタッフ一覧",
      },
      {
        label: "【内部】教師スタッフ編集",
      },
    ];
    setBreadcrumbItems(BREAD_CRUMBS);
    // eslint-disable-next-line
  }, []);
  const { avator, roles, __typename, useableServiceKinds, ...defaultValues } =
    data.user;
  const methods = useForm<UserEditFormValues>({
    defaultValues: {
      ...defaultValues,
      roleIds: roles.map((role) => role.id).join(),
      serviceKindIds: useableServiceKinds.map((serviceKind) => serviceKind.id),
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          <h1 className="text-2xl mb-3" data-testid="users-edit-page-title">
            【内部】教師スタッフ編集
          </h1>
          <hr className="border-dark-gray border mb-2.5"></hr>
          <UserEditUI
            user={data.user}
            onBack={onBack}
            hasAdminAuthority={data.hasAdminAuthority}
            services={data.services}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default UsersEditPage;
