import React, { MouseEventHandler, useEffect, useState } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  ContBillPaymentMismatchesFilterInput,
  ContBillPaymentMismatchResultsFragment,
} from "../../../generated/graphql";
import { ContBillPaymentMismatchesSearchUI } from "./components/ContBillPaymentMismatchesSearch";
import { ContBillPaymentMismatchesTableUI } from "./components/ContBillPaymentMismatchesTable";

export type ContBillPaymentMismatchesPageProps = {
  contBillPaymentMismatches: ContBillPaymentMismatchResultsFragment;
  loading: boolean;
  onDisplayMore: MouseEventHandler;
  onSearch: (inputs: ContBillPaymentMismatchesFilterInput) => void;
};

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済情報不整合(継続課金)",
  },
];

const ContBillPaymentMismatchesPage: React.VFC<
  ContBillPaymentMismatchesPageProps
> = ({ contBillPaymentMismatches, loading, onDisplayMore, onSearch }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
    return () => {
      // unmount
    };
  }, [setBreadcrumbItems]);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">決済情報不整合(継続課金)</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-6">
        <ContBillPaymentMismatchesSearchUI onSearch={onSearch} />
        {/* 検索中 */}
        {loading && (
          <div className="mt-8">
            <h2>検索中．．．</h2>
          </div>
        )}

        {/* 検索結果あり */}
        {!loading && contBillPaymentMismatches.totalCount > 0 && (
          <>
            <div
              className="flex justify-between mt-5 items-center mb-1.5"
              style={{ maxWidth: tableWidth }}
            >
              <span data-testid="payment-mismatches-search-results-total-count">
                全{contBillPaymentMismatches.totalCount}件中
                {contBillPaymentMismatches.nodes.length}件表示
              </span>
            </div>
            <div className="overflow-x-auto">
              <ContBillPaymentMismatchesTableUI
                contBillPaymentMismatches={contBillPaymentMismatches}
                onWidthChange={(w) => {
                  setTableWidth(w);
                }}
              />

              {contBillPaymentMismatches.totalCount !==
                contBillPaymentMismatches.nodes.length && (
                <button
                  data-testid="payment-mismatches-search-results-display-more-btn"
                  className="border border-dark-gray text-blue hover:underline active:text-blue-active active:underline w-full h-8 mt-5"
                  onClick={onDisplayMore}
                >
                  もっと表示する
                </button>
              )}
            </div>
          </>
        )}
        {/* 検索結果がゼロ件 */}
        {!loading && contBillPaymentMismatches.totalCount === 0 && (
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="payment-mismatches-search-results-no-payments-exists-message"
              >
                該当する決済不整合がありませんでした。
              </p>
              <p>検索条件に該当するが決済不整合がありませんでした。</p>
              <p>内容をご確認のうえ、再度検索を行ってください。</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ContBillPaymentMismatchesPage;
