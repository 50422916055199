import React, { ChangeEventHandler, useEffect } from "react";

import { SubmitHandler } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  DisplayClass,
  DisplayCourse,
  DisplayLesson,
} from "../../../lib/constants/classes";
import { PageType } from "../../../lib/constants/pageType";
import { ClassEditUI } from "../components/ClassEdit";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "クラス詳細",
  },
  {
    label: "クラス編集",
  },
];

type ClassEditPageProps = {
  course: DisplayCourse;
  clazzInput: DisplayClass;
  onOpenTeacherListModal: (lesson: DisplayLesson) => void;
  onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeLessonInputs: (lessonInputs: DisplayLesson[]) => void;
  onChangeRadioType: ChangeEventHandler<HTMLInputElement>;
  onHandleSubmit: SubmitHandler<DisplayClass>;
  onRemoveTeacher: (lesson: DisplayLesson, teacherId: string) => void;
};

const ClassEditPage: React.FC<ClassEditPageProps> = ({
  course,
  clazzInput,
  onOpenTeacherListModal,
  onChangeInput,
  onChangeLessonInputs,
  onChangeRadioType,
  onHandleSubmit,
  onRemoveTeacher,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">クラス編集</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <ClassEditUI
          pageType={PageType.ClassEditPage}
          course={course}
          clazzInput={clazzInput}
          onHandleSubmit={onHandleSubmit}
          onChangeInput={onChangeInput}
          onChangeLessonInputs={onChangeLessonInputs}
          onChangeRadioType={onChangeRadioType}
          onOpenTeacherListModal={onOpenTeacherListModal}
          onRemoveTeacher={onRemoveTeacher}
          onChangeAutoCreateZoomURL={() => {}} // TODO: クラス編集画面でZoomURLを再発行する場合は実装する
        ></ClassEditUI>
      </div>
    </div>
  );
};

export default ClassEditPage;
