import React from "react";

import { useMeQuery } from "../../generated/graphql";
import ErrorPageUI from "./components/ErrorPage/ErrorPage";

const ErrorPage: React.VFC = () => {
  const { data } = useMeQuery();
  return (
    <ErrorPageUI title="ログインエラー" me={data?.me}>
      無効なユーザーです。管理者にお問い合わせください。
    </ErrorPageUI>
  );
};

export default ErrorPage;
