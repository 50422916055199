import React, {
  ChangeEvent,
  ChangeEventHandler,
  InputHTMLAttributes,
} from "react";

export type InputUIProps = {
  onTextChange?: (text: string) => void;
  height?: string;
  padding?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputUI = React.forwardRef<HTMLInputElement, InputUIProps>(
  (
    {
      className = "",
      onTextChange,
      children,
      height = "h-12",
      padding = "pl-4",
      ...rest
    },
    ref
  ) => {
    const onChange: ChangeEventHandler<HTMLInputElement> = (
      event: ChangeEvent<HTMLInputElement>
    ) => {
      const txt: string = event.target.value;
      onTextChange && onTextChange(txt);
      if (rest.onChange !== undefined) {
        rest.onChange(event);
      }
    };

    return (
      <input
        ref={ref}
        className={`${className} ${height} ${padding} leading-5 tracking-wider border rounded`}
        onChange={onChange}
        {...rest}
      />
    );
  }
);
