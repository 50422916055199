import { useCallback, useMemo } from "react";

import { ServiceKind } from "../../../../generated/graphql";
import { useCurrentServiceKind } from "../../../../lib/cache";
import { Master, MASTERS } from "../constants";
import { MasterType } from "../constants";

export const useCanUseMaster = () => {
  const masters = useMemo(() => MASTERS, []);
  const currentServiceKind = useCurrentServiceKind();

  const canUseMaster = useCallback(
    (masterType: MasterType) =>
      canUserMaster(masters, masterType, currentServiceKind?.key),
    [masters, currentServiceKind?.key]
  );
  return { canUseMaster };
};

const canUserMaster = (
  masters: Master[],
  masterType: MasterType,
  currentServiceKind?: ServiceKind
): boolean => {
  return !!(
    currentServiceKind &&
    masters
      .find((master) => master.masterType === masterType)
      ?.availableServices?.includes(currentServiceKind)
  );
};
