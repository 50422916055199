import React, { MouseEventHandler } from "react";

import { ReactComponent as MoreIcon } from "../../../components/more.svg";
import { AnsweredCoursesFragment } from "../../../generated/graphql";
import { CourseQuestionnaire } from "../../../lib/types/course";
import { QuestionnairesTableUI } from "./components/QuestionnairesTable";

export type LessonRequestQuestionnairesPageProps = {
  hasAdminAuthority: boolean;
  answeredCourses: AnsweredCoursesFragment;
  onDisplayMore: MouseEventHandler;
  onPreview: (questionnaires: CourseQuestionnaire[]) => void;
  onClickDownload: (courseId: string) => void;
};

const RequestQuestionnairesPage: React.VFC<
  LessonRequestQuestionnairesPageProps
> = ({
  hasAdminAuthority,
  answeredCourses,
  onDisplayMore,
  onPreview,
  onClickDownload,
}) => {
  return (
    <div>
      {answeredCourses.totalCount > 0 ? (
        <>
          <div className="flex justify-between mt-5 mb-1.5 items-end">
            <span data-testid="questionnaires-page-total-count">
              全{answeredCourses.totalCount}件中{answeredCourses.nodes.length}
              件表示
            </span>
          </div>
          <QuestionnairesTableUI
            hasAdminAuthority={hasAdminAuthority}
            answeredCourses={answeredCourses}
            onPreview={onPreview}
            onClickDownload={onClickDownload}
          ></QuestionnairesTableUI>
          {answeredCourses.totalCount !== answeredCourses.nodes.length && (
            <div className="flex justify-center mt-9 mb-10">
              <button
                data-testid="questionnaires-page-display-more-btn"
                className="text-blue hover:underline active:text-blue-active active:underline h-8"
                onClick={onDisplayMore}
              >
                <MoreIcon></MoreIcon>
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="container mt-5">
          <div className="bg-gray-200 text-center py-2">
            <p
              className="text-lg font-bold mb-2.5"
              data-testid="questionnaires-page-no-questionnaire-exists-message"
            >
              アンケートがありません。
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestQuestionnairesPage;
