export enum DiscountType {
  /**
   * クーポン
   */
  Coupon = "coupon",
  /**
   * コース限定クーポン
   * ※ 指定されたコースでのみ使用できるクーポン
   */
  CourseCoupon = "courseCoupon",
  /**
   * 初月割引クーポン
   * ※ 初月割引でのみ使用できるクーポン
   */
  InitialCoupon = "initialCoupon",
  /**
   * 月額クーポン
   * ※ 複数ヶ月対応
   */
  PlanCoupon = "planCoupon",
}
