import React, { ReactNode } from "react";

type ServerErrorPageUIProps = {
  iconImage: ReactNode;
};

const ServerErrorPageUI: React.VFC<
  React.PropsWithChildren<ServerErrorPageUIProps>
> = ({ iconImage, children }) => {
  return (
    <div className="mx-auto mt-11">
      {iconImage}
      <div
        className="mt-2 tracking-wider text-center"
        data-testid="error-overview"
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ServerErrorPageUI;
