import React, { useMemo } from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { ReactComponent as Comments } from "../../../../components/comments.svg";
import { ReactComponent as Pen } from "../../../../components/pen.svg";
import { ReactComponent as Trash } from "../../../../components/trash.svg";
import buttonStyles from "./button.module.css";
import dataStyles from "./discountTableData.module.css";

dayjs.extend(utc);
dayjs.extend(tz);

export type discount = {
  id: string;
  code: string;
  name: string;
  description: string;
  startedAt: Date;
  endedAt: Date;
  priceValueNoTax: number;
  durationMonths: number;
};

export type DiscountsTableRowProps = {
  row: discount;
  index: number;
  onDeleting: (id: string) => void;
  onEditing: (is: string) => void;
};

const DiscountsTableRowUI: React.FC<DiscountsTableRowProps> = ({
  row,
  index,
  onDeleting,
  onEditing,
}) => {
  const rowColor = useMemo((): string => {
    // typeof row.endedAt => string なので dayjs で Date 化している
    // そうしないと row.endedAt.getTime() で getTime is not a function 例外になる
    const endTime: number =
      row && row.endedAt ? dayjs(row.endedAt).toDate().getTime() : 0;
    const currentTime: number = new Date().getTime();
    if (endTime < currentTime) {
      return "bg-gray-300";
    } else {
      if (index % 2 !== 0) {
        return "bg-light-blue";
      } else {
        return "";
      }
    }
  }, [row, index]);

  /**
   * ReactToolTip で複数行モードを使用する際の
   * クラス名の指定にマジックワードが必要だが
   * module.css を使用するとマジックワードにもハッシュ値が付与されてしまい
   * CSS がツールチップの中身に適用されないので、HTML 加工して
   * `data-tip` プロパティーに文字列を設定する
   */
  const __tooltipHtml: string = useMemo((): string => {
    if (row.description) {
      return `<p className="text-left">${row.description.replace(
        /\n/g,
        "<br/>"
      )}</p>`;
    } else {
      return "（社内メモ無し）";
    }
  }, [row.description]);

  return (
    <tr
      data-testid={`discounts-table-row-${index}`}
      className={`${dataStyles.tr} ${rowColor}`}
    >
      {/* クーポンコード */}
      <td
        className={`${dataStyles.td} ${dataStyles.tdCode}`}
        data-testid={`discounts-table-row-code-${index}`}
      >
        {row.code}
      </td>
      {/* クーポン名 */}
      <td
        className={`${dataStyles.td} ${dataStyles.tdName}`}
        data-testid={`discounts-table-row-name-${index}`}
      >
        {row.name}
      </td>
      {/* 社内メモ */}
      <td className={`${dataStyles.tdComments}`}>
        <div
          className="justify-items-center"
          data-tooltip-id={`discounts-table-row-description-${index}`}
          data-tooltip-html={`${__tooltipHtml}`}
          data-tooltip-variant="info"
          data-tooltip-float={true}
          data-tooltip-delay-show={200}
          data-tooltip-delay-hide={160}
          data-testid={`discounts-table-row-description-${index}`}
        >
          <Comments />
        </div>
        <Tooltip
          id={`discounts-table-row-description-${index}`}
          place="top"
          classNameArrow={`${dataStyles.tooltipArrow}`}
          style={{
            backgroundColor: "white",
            color: "#00a0e8",
            border: "1px solid gray",
            borderColor: "gray",
            borderRadius: "6px",
            opacity: 0.9,
          }}
        />
      </td>
      {/* 発効日時 */}
      <td
        className={`${dataStyles.td}`}
        data-testid={`discounts-table-row-startedAt-${index}`}
      >
        {dayjs(row.startedAt).format("YYYY/MM/DD HH:mm")}
      </td>
      {/* 終了日時 */}
      <td
        className={`${dataStyles.td}`}
        data-testid={`discounts-table-row-endedAt-${index}`}
      >
        {dayjs(row.endedAt).format("YYYY/MM/DD HH:mm")}
      </td>
      {/* 割引（税抜） */}
      <td
        className={`${dataStyles.td} text-right`}
        data-testid={`discounts-table-row-priceNoTax-${index}`}
      >
        {row.priceValueNoTax.toLocaleString("ja-jp")}円
      </td>
      {/* 割引期間 */}
      <td
        className={`${dataStyles.td} text-right`}
        data-testid={`discounts-table-row-durationMonths-${index}`}
      >
        {row.durationMonths ? `${row.durationMonths}ヶ月` : ""}
      </td>
      {/* 削除・編集ボタン */}
      <td
        className={`${dataStyles.td} ${dataStyles.tdButtons}`}
        data-testid={`discounts-table-row-buttons-${index}`}
      >
        <InternalButton
          type="trash"
          className={`${buttonStyles["trash"]}`}
          testid={`discounts-row-deleting-btn-${index}`}
          onClick={() => onDeleting(row.id)}
        />
        <InternalButton
          type="pen"
          className={`${buttonStyles["pen"]}`}
          testid={`discounts-row-editing-btn-${index}`}
          onClick={() => onEditing(row.id)}
        />
      </td>
    </tr>
  );
};

type InternalButtonProps = {
  type: "pen" | "trash";
  testid?: string;
  className: string;
  onClick: () => void;
};

const InternalButton: React.FC<InternalButtonProps> = ({
  type,
  className,
  testid,
  onClick,
  ...rest
}) => {
  return (
    <button
      className={`${buttonStyles.button} ${className}`}
      data-testid={`${testid}`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        {type === "pen" ? (
          <>
            <Pen className="ml-2 mr-2 stroke-current" />
            編集
          </>
        ) : (
          <>
            <Trash className="ml-2 mr-2 stroke-current" />
            削除
          </>
        )}
      </div>
    </button>
  );
};

export default DiscountsTableRowUI;
