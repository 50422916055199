import React, { useContext, useState } from "react";

import { Breadcrumb } from "./Breadcrumb";

type IBreadcrumbContext = {
  breadcrumbItems: Breadcrumb[];
  setBreadcrumbItems: (items: Breadcrumb[]) => void;
};

export const BreadcrumbContext = React.createContext<IBreadcrumbContext>({
  breadcrumbItems: [],
  setBreadcrumbItems: () => {},
});

export const BreadcrumbContextProvider: React.FC = ({ children }) => {
  const [items, setItems] = useState<Breadcrumb[]>([]);

  const setBreadcrumbItems = (items: Breadcrumb[]) => {
    setItems(items);
  };
  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbItems: items, setBreadcrumbItems }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);
