import React, { useCallback, useState } from "react";

import { useLocation, useHistory, useParams } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import { useUpdateAnnouncementMutation } from "../../../generated/graphql";
import { useErrorState } from "../../../hooks/errorRouter";
import { AnnouncementEdit } from "../../../lib/types/announcement";
import AnnouncementsEditConfirmPage from "./AnnouncementsEditConfirmPage";

const AnnouncementsEditConfirmPageContainer: React.VFC = () => {
  const { state } = useLocation<AnnouncementEdit>();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { error, clearError, onError } = useErrorState();
  const [visibility, setVisibility] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [confirmeModalVisibility, setConfirmeModalVisibility] = useState(false);

  const [updateAnnouncement] = useUpdateAnnouncementMutation({
    onError: (e) => {
      onError(e);
      setUpdating(false);
    },
    onCompleted: () => {
      setUpdating(false);
      setVisibility(true);
    },
  });

  const handleOnRegister = useCallback(() => {
    const {
      id,
      kind,
      subject,
      body,
      publishStartedAt,
      publishEndedAt,
      published,
    } = state;
    if (published) {
      setConfirmeModalVisibility(true);
    } else {
      setUpdating(true);
      updateAnnouncement({
        variables: {
          input: {
            id,
            kind,
            subject,
            body,
            publishStartedAt,
            publishEndedAt,
          },
        },
      });
    }
  }, [state, setUpdating, updateAnnouncement]);

  const handleOnBack = useCallback(() => {
    history.replace(`/announcements/${id}/edit`, state);
  }, [id, history, state]);

  const handleOnClickModalButton = useCallback(() => {
    history.push(`/announcements`);
  }, [history]);

  const handleOnClickErrorModalButton = useCallback(() => {
    clearError();
  }, [clearError]);

  const handleConfirmModalSubmit = useCallback(() => {
    const { id, kind, subject, body, publishStartedAt, publishEndedAt } = state;
    setConfirmeModalVisibility(false);
    setUpdating(true);
    updateAnnouncement({
      variables: {
        input: {
          id,
          kind,
          subject,
          body,
          publishStartedAt,
          publishEndedAt,
        },
      },
    });
  }, [state, setUpdating, updateAnnouncement]);

  const handleConfirmModalCancel = useCallback(() => {
    setConfirmeModalVisibility(false);
  }, [setConfirmeModalVisibility]);

  if (updating) {
    return <LoadingUI title="送信中" />;
  }

  return (
    <>
      <ConfirmModalUI
        title="確認"
        description={`お客様画面で既に公開されているため、変更が即時で反映されますが、よろしいでしょうか？`}
        onCancel={handleConfirmModalCancel}
        onSubmit={handleConfirmModalSubmit}
        visibility={confirmeModalVisibility}
      />
      <CompletedModalUI
        title="編集完了"
        btnTitle="お知らせ一覧へ"
        description="お知らせの編集が完了しました。"
        visibility={visibility}
        onClick={handleOnClickModalButton}
      />
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        onClick={handleOnClickErrorModalButton}
        visibility={!!error}
      >
        <p>お知らせの登録に失敗しました。</p>
        <p>しばらく時間をおいてから、もう一度お試しください。</p>
      </ErrorModalUI>
      <AnnouncementsEditConfirmPage
        input={state}
        onBack={handleOnBack}
        onRegister={handleOnRegister}
      />
    </>
  );
};

export default AnnouncementsEditConfirmPageContainer;
