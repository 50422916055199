import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../components/Breadcrumb";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
];

const IndexPage = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return <h1>Top</h1>;
};

export default IndexPage;
