import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CoursesDetailUI } from "../../../components/CoursesDetail";
import { FileSave } from "../../../components/FileSave";
import { FormGroupUI } from "../../../components/FormGroup";
import { LabelUI } from "../../../components/Label";
import {
  CourseDetail,
  CoursePageFragment,
  CourseTeachingMaterial,
  LessonMaster,
  LessonMasterTeachingMaterial,
  Questionnaire,
} from "../../../generated/graphql";
import { PageType } from "../../../lib/constants/pageType";
import { CourseClassDisplay, CourseDisplay } from "../../../lib/types/course";
import {
  getQuestionnaireTypeByString,
  getSelectableOptionConditionTypeByString,
} from "../../../lib/utils/questionnaires";
import { ClassesUI } from "./components/Classes";
import CourseDeleteButton from "./components/CourseDeleteButton";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "コース詳細",
  },
];

export type CoursePageProps = {
  course: CoursePageFragment;
};

const CoursePage: React.FC<CoursePageProps> = ({ course }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  let clazzCapacity = 0;
  let clazzParticipantsCount = 0;
  let clazzCanceledCount = 0;
  course.clazzes.forEach((clazz) => {
    clazzCapacity += clazz.capacity;
    clazzParticipantsCount +=
      (clazz.lessons && clazz.lessons[0].participantsCount) || 0;
    clazzCanceledCount += clazz.canceledCount || 0;
  });

  const [courseDisplay] = useState<CourseDisplay>({
    id: course.id,
    systemId: course.systemId,
    name: course.name,
    courseId: course.courseId || "",
    organizer: course.organizer || undefined,
    levelEntity: course.levelEntity || undefined,
    targets: {
      type: course.targets?.type!,
      from: course.targets?.from,
      to: course.targets?.to,
    },
    subjectEntity1:
      course.subjects && course.subjects[0] ? course.subjects[0] : undefined,
    subjectEntity2:
      course.subjects && course.subjects[1] ? course.subjects[1] : undefined,
    price: {
      type: course.price?.type!,
      valueNoTax: course.price?.valueNoTax,
    },
    overview: course.overview,
    details:
      course?.details.map((detail: CourseDetail) => ({
        category: detail.category,
        description: detail.description,
      })) || [],
    images: course.images || [],
    videos: course.videos || [],
    teachingMaterials:
      course?.teachingMaterials?.map((material: CourseTeachingMaterial) => ({
        name: material.name,
        url: material.url,
      })) || [],
    lessonMasters:
      course?.lessonMasters?.map((lessonMaster: LessonMaster) => ({
        order: lessonMaster.order,
        name: lessonMaster.name,
        teachingMaterials:
          lessonMaster?.teachingMaterials?.map(
            (material: LessonMasterTeachingMaterial) => ({
              name: material.name,
              url: material.url,
            })
          ) ?? [],
      })) || [],
    clazzCapacity: clazzCapacity.toString(),
    clazzParticipantsCount: clazzParticipantsCount.toString(),
    canceledCount: clazzCanceledCount.toString(),
    questionnaires:
      course?.questionnaires?.map((que: Questionnaire) => ({
        id: que.id,
        typenameDisplay: "",
        typename: getQuestionnaireTypeByString(que.typename),
        order: que.order,
        title: que.title,
        required: que.required,
        options: que.options?.map((opt) => ({
          name: opt.name,
        })) || [{ name: "" }],
        selectableNumber: que.selectableNumber || 1,
        condition: getSelectableOptionConditionTypeByString(
          que.condition || ""
        ),
        maxScale: que.maxScale || 5,
        minLabel: que.minLabel || "",
        maxLabel: que.maxLabel || "",
      })) || [],
  });

  const courseClasses: CourseClassDisplay[] = course.clazzes.map((clazz) => {
    let teacherNames: string[] = [];
    clazz.lessons &&
      clazz.lessons[0].teachers.forEach((teacher) => {
        teacherNames.push(teacher.familyName + "　" + teacher.givenName);
      });
    return {
      id: clazz.id,
      courseId: clazz.courseId,
      systemId: clazz.systemId,
      anyClassId: clazz.anyClassId || "",
      name: clazz.name,
      teacherNames: teacherNames,
      schedule: (clazz.lessons && clazz.lessons[0]?.startedAt) || new Date(),
      achievement:
        ((clazz.lessons && clazz.lessons[0]?.participantsCount.toString()) ||
          "0") +
        "/" +
        clazz.capacity.toString() +
        "名",
      canceledCount: (clazz.canceledCount?.toString() || "0") + "名",
    };
  });

  const onClickEdit = () => {
    history.push(`${course.id}/edit`);
  };

  return (
    <div>
      <h1 className="text-2xl mb-3">コース詳細</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="ml-5">
        <CoursesDetailUI
          course={courseDisplay}
          pageType={PageType.CoursePage}
          fileSave={FileSave}
        ></CoursesDetailUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">クラス一覧</LabelUI>
          <ClassesUI classes={courseClasses}></ClassesUI>
        </FormGroupUI>

        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="course-back-btn"
            buttonType="secondary"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI data-testid="course-edit-btn" onClick={onClickEdit}>
            編集
          </ButtonUI>
          <CourseDeleteButton
            courseId={course.id}
            onCompleteDelete={history.goBack}
          />
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
