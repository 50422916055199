import React, { HTMLAttributes } from "react";

type Props = {
  visibility: boolean;
  children?: JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

export const ModalUI: React.FC<Props> = ({
  visibility,
  children,
  className,
}) => {
  return visibility ? (
    <div
      className="fixed z-20 inset-0 overflow-y-auto pl-44"
      data-testid="base-modal"
    >
      <div className="flex justify-center pt-40 px-3 w-full max-w-screen-md mx-auto">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div
          className={`flex flex-col bg-white rounded transform transition-all shadow-lg ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
