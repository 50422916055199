import React, { useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { ExpertEditFormState } from "../components/ExpertEdit";
import ExpertNewPage, { ExpertNewPageProps } from "./ExpertNewPage";

const ExpertNewPageContainer: React.VFC = () => {
  const location = useLocation<ExpertEditFormState>();
  const history = useHistory();

  const handleGoBack: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/experts");
    }, [history]);

  const handleSubmit: ExpertNewPageProps["onSubmit"] = useCallback(
    (input) => {
      history.push("/experts/confirm", input);
    },
    [history]
  );

  return (
    <ExpertNewPage
      defaultValues={location.state}
      onGoBack={handleGoBack}
      onSubmit={handleSubmit}
    />
  );
};

export default ExpertNewPageContainer;
