import React, {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";

import { useHistory, useLocation } from "react-router-dom";

import { BillingCustomerFilterInput } from "../../../generated/graphql";
import CustomerBillingSearchPage from "./CustomerBillingSearchPage";

const CustomerBillingSearchPageContainer: React.VFC = () => {
  const location = useLocation<{
    filter: BillingCustomerFilterInput;
  }>();

  const history = useHistory();
  const [filter, setFilter] = useState<BillingCustomerFilterInput>(
    location.state
      ? location.state.filter
      : {
          memberId: "",
        }
  );

  const onChangeFilterInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const value = event.target.value;
      setFilter({
        ...filter,
        [event.target.name]: value,
      });
    },
    [filter]
  );

  const onSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push("/customer/billing/search/results", {
        filter,
      });
    },
    [history, filter]
  );

  return (
    <CustomerBillingSearchPage
      filter={filter}
      onSubmit={onSubmit}
      onChangeFilterInput={onChangeFilterInput}
    ></CustomerBillingSearchPage>
  );
};

export default CustomerBillingSearchPageContainer;
