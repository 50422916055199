import React, { useCallback, useEffect } from "react";

import dayjs from "dayjs";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { FormGroupUI } from "../../../components/FormGroup";
import { LabelUI } from "../../../components/Label";
import { TableUI, TdUI, TrUI } from "../../../components/TableUI";
import { TextUI } from "../../../components/Text";
import { ValidatableTextareaUI } from "../../../components/ValidatableTextarea";
import { AgreementStatus, CustomerFragment } from "../../../generated/graphql";
import { genders } from "../../../lib/constants";
import { ReactComponent as AgreementAgreeIcon } from "./agreement_agree.svg";
import { ReactComponent as AgreementDisagreeIcon } from "./agreement_disagree.svg";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "個人情報照会",
  },
];

export type CustomerPageProps = {
  customer: CustomerFragment;
  onChangeInput?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  note?: string;
  onHandleSubmit: SubmitHandler<NoteEditForm>;
};

type NoteEditForm = {
  note: string;
};

const CustomerPage: React.FC<CustomerPageProps> = ({
  customer,
  note,
  onChangeInput,
  onHandleSubmit,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  const history = useHistory();

  const getEnrollmentPeriod = useCallback((createdAt: Date) => {
    const months = dayjs().diff(dayjs(createdAt), "month");
    const year = Math.floor(months / 12);
    const month = months % 12;
    return `${year > 0 ? year + "年" : ""}${month}カ月`;
  }, []);

  const { profile } = customer;

  /*********************
   * Form Validator
   *********************/
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<NoteEditForm>({
    mode: "onSubmit",
    defaultValues: {
      note,
    },
  });

  return (
    <div>
      <h1 className="text-2xl mb-3">個人情報照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-5 mb-36">
        <p className="mt-8 font-bold text-xl">基本情報</p>
        <FormGroupUI>
          <LabelUI className="text-lg">会員ID(メールアドレス)</LabelUI>
          <TextUI data-testid="customer-memberid">
            {customer.profile?.memberId}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">氏名</LabelUI>
          <TextUI data-testid="customer-customer-name">
            {customer.profile?.familyName} {customer.profile?.givenName}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">フリガナ</LabelUI>
          <TextUI data-testid="customer-customer-name-kana">
            {customer.profile?.familyNameKana} {customer.profile?.givenNameKana}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">性別</LabelUI>
          <TextUI data-testid="customer-gender">
            {
              genders.find(
                (gender) => gender.value === customer.profile?.gender
              )?.label
            }
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">電話番号</LabelUI>
          <TextUI data-testid="customer-tel">{customer.profile?.tel}</TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">郵便番号</LabelUI>
          <TextUI data-testid="customer-postal-code">
            {customer.profile?.postalCode}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">住所</LabelUI>
          <TextUI data-testid="customer-postal-address">
            {customer.profile?.prefecture}
            {customer.profile?.city}
            {customer.profile?.houseNumber}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">生年月日</LabelUI>
          <TextUI data-testid="customer-postal-birthday">
            {customer.profile?.birthday &&
              dayjs(customer.profile?.birthday).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">年齢</LabelUI>
          <TextUI data-testid="customer-age">{customer.profile?.age}</TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">会員登録年月日</LabelUI>
          <TextUI data-testid="customer-register-date">
            {customer.createdAt &&
              dayjs(customer.createdAt).format("YYYY/MM/DD")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">会員登録時サービス</LabelUI>
          <TextUI data-testid="customer-register-service-kind">
            {customer.customerServices
              .map((customerService) => customerService.serviceKindName)
              .join("/")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        <FormGroupUI>
          <LabelUI className="text-lg">在籍期間</LabelUI>
          <TextUI data-testid="customer-enrollment-period">
            {getEnrollmentPeriod(customer.createdAt)}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />

        <p className="mt-8 font-bold text-xl">その他情報</p>
        <FormGroupUI>
          <LabelUI className="text-lg">興味のあるカテゴリ</LabelUI>
          <TextUI data-testid="customer-subjects">
            {customer.profile?.subjects
              .map((subject) => subject.name)
              .join("/")}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />
        {customer?.profile?.parentProfile && (
          <>
            <p className="mt-8 font-bold text-xl">保護者情報</p>

            <FormGroupUI>
              <LabelUI className="text-lg">氏名</LabelUI>
              <TextUI data-testid="customer-parent-name">
                {customer.profile?.parentProfile?.familyName}{" "}
                {customer.profile?.parentProfile?.givenName}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">フリガナ</LabelUI>
              <TextUI data-testid="customer-parent-kana">
                {customer.profile?.parentProfile?.familyNameKana}{" "}
                {customer.profile?.parentProfile?.givenNameKana}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">生年月日</LabelUI>
              <TextUI data-testid="customer-parent-birthday">
                {customer.profile?.parentProfile?.birthday &&
                  dayjs(customer.profile?.parentProfile?.birthday).format(
                    "YYYY/MM/DD"
                  )}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">メール</LabelUI>
              <TextUI data-testid="customer-parent-email">
                {customer.profile?.parentProfile?.email}
              </TextUI>
            </FormGroupUI>
          </>
        )}

        {profile?.userAttribute && (
          <>
            <p
              data-testid="customer-user-attribute"
              className="mt-8 font-bold text-xl"
            >
              属性
            </p>
            <FormGroupUI>
              <LabelUI className="text-lg">職業</LabelUI>
              <TextUI data-testid="customer-user-attribute-occupation">
                {profile.userAttribute.occupation}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">業種</LabelUI>
              <TextUI data-testid="customer-user-attribute-industry">
                {profile.userAttribute.industry}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">職種</LabelUI>
              <TextUI data-testid="customer-user-attribute-job-title">
                {profile.userAttribute.jobTitle}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">役職</LabelUI>
              <TextUI data-testid="customer-user-attribute-position">
                {profile.userAttribute.position}
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
            <FormGroupUI>
              <LabelUI className="text-lg">年収</LabelUI>
              <TextUI data-testid="customer-user-attribute-annual-income">
                {profile.userAttribute.annualIncome}
              </TextUI>
            </FormGroupUI>
          </>
        )}
        <hr className="border-under-line" />

        <p className="mt-8 font-bold text-xl">au ID連携設定</p>
        <FormGroupUI className="pl-3">
          <TextUI data-testid="customer-externalid-auid">
            {customer.auId ? "連携済" : "未連携"}
          </TextUI>
        </FormGroupUI>
        <hr className="border-under-line" />

        <p className="mt-8 font-bold text-xl">プライバシー設定</p>
        {customer.agreements.length > 0 ? (
          <FormGroupUI className="pl-3">
            <TableUI
              data-testid="customer-agreement-table"
              headers={["規約名", "バージョン", "同意状況", "最終更新日"]}
            >
              {customer.agreements.map((agreement, index) => (
                <TrUI key={index}>
                  <TdUI
                    data-testid={`customer-agreement-${index}-termsName`}
                    className="pl-5 text-center"
                  >
                    {agreement.terms.name}
                  </TdUI>
                  <TdUI
                    data-testid={`customer-agreement-${index}-termsVersion`}
                    className="pl-5 text-center"
                  >
                    {agreement.terms.version}
                  </TdUI>
                  <TdUI
                    data-testid={`customer-agreement-${index}-status`}
                    className="pl-5 text-center"
                  >
                    {agreement.status === AgreementStatus.Agreement ? (
                      <div className="flex flex-nowrap justify-center items-center gap-1">
                        <AgreementAgreeIcon />
                        <TextUI>同意する</TextUI>
                      </div>
                    ) : (
                      <div className="flex flex-nowrap justify-center items-center gap-1">
                        <AgreementDisagreeIcon />
                        <TextUI>同意しない</TextUI>
                      </div>
                    )}
                  </TdUI>
                  <TdUI
                    data-testid={`customer-agreement-${index}-updatedAt`}
                    className="pl-5 text-center"
                  >
                    {dayjs(agreement.updatedAt).format("YYYY/MM/DD")}
                  </TdUI>
                </TrUI>
              ))}
            </TableUI>
          </FormGroupUI>
        ) : (
          <>
            <FormGroupUI className="pl-3">
              <TextUI data-testid="customer-agreements-is-empty">
                同意事項はありません。
              </TextUI>
            </FormGroupUI>
            <hr className="border-under-line" />
          </>
        )}
        <p className="mt-8 font-bold text-xl">スタッフメモ</p>
        <FormGroupUI>
          <div className="flex">
            <ValidatableTextareaUI
              registerParams={{
                register,
                error: errors.note,
                label: "note",
                conditions: {
                  maxLength: {
                    value: 500,
                    message: "５００文字以内で入力してください。",
                  },
                },
              }}
              data-testid="customer-note"
              errorIdPrefix="customer-note-error"
              rows={8}
              value={note}
              placeholder="５００文字以内"
              id="note"
              name="note"
              style={{ width: "800px", height: "400px" }}
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr className="border-under-line" />

        <div className="flex justify-center space-x-10 mt-20">
          <ButtonUI
            data-testid="customer-goback"
            buttonType="primary"
            size="s"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI
            type="submit"
            data-testid="customer-note-submit"
            buttonType="primary"
            size="s"
            onClick={handleSubmit(onHandleSubmit)}
          >
            更新
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default CustomerPage;
