import React, { HTMLAttributes } from "react";

import {
  CourseTargetType,
  CourseImage,
  CreateCourseDetailInput,
  UpdateCourseDetailInput,
  ServiceKind,
} from "../../generated/graphql";
import { currentServiceKind } from "../../lib/cache";
import {
  courseDetail,
  courseDetailSortOrder,
} from "../../lib/constants/courses";
import { PageType } from "../../lib/constants/pageType";
import { TeachingMaterialsDisplay } from "../../lib/types";
import { CourseDisplay, LessonMasterDisplay } from "../../lib/types/course";
import { BadgeUI } from "../Badge";
import { FileInputTypeImageUI } from "../FileImputTypeImage";
import { FormGroupUI } from "../FormGroup";
import { LabelUI } from "../Label";
import LinkTextUI from "../LinkText/LinkText";
import noImage from "../no-image.svg";
import { PriceUI } from "../Price";
import { QuestionnairePreviewUI } from "../QuestionnairePreview";
import { TextUI } from "../Text";

export type CoursesDetailUIProps = {
  course: CourseDisplay;
  pageType: PageType;
  fileSave?: (url: string, name: string) => void;
} & HTMLAttributes<HTMLDivElement>;

export const CoursesDetailUI: React.VFC<CoursesDetailUIProps> = ({
  course,
  pageType,
  className,
  fileSave,
  ...rest
}) => {
  return (
    <div className={className} {...rest}>
      {/* システムID */}
      <FormGroupUI>
        <LabelUI className="text-lg">システムID</LabelUI>
        <TextUI data-testid="courses-confirm-systemid">
          {pageType === PageType.CoursesNewConfirmPage
            ? "登録後に発行されます。"
            : course.systemId}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* コース名 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コース名</LabelUI>
          {pageType === PageType.CoursesNewConfirmPage && (
            <BadgeUI>必須</BadgeUI>
          )}
        </div>
        <TextUI data-testid="courses-confirm-course-name">{course.name}</TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コースID</LabelUI>
        </div>
        <TextUI data-testid="courses-confirm-courseid">
          {course.courseId}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* 事業者 */}
      {currentServiceKind()?.key === ServiceKind.ElementarySchool && (
        <>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">事業者</LabelUI>
              {pageType === PageType.CoursesNewConfirmPage && (
                <BadgeUI>必須</BadgeUI>
              )}
            </div>
            <TextUI data-testid="courses-confirm-organizer">
              {course.organizer?.name}
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
        </>
      )}

      {/* 対象年齢/学年 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">対象年齢/学年</LabelUI>
        </div>
        <TextUI data-testid="courses-confirm-target">
          {course.targets?.type === CourseTargetType.Age && (
            <span>
              {course.targets.from ? `${course.targets.from}歳` : "指定なし"}
              　〜　
              {course.targets.to ? `${course.targets.to}歳` : "指定なし"}
            </span>
          )}
          {course.targets?.type === CourseTargetType.Grade && (
            <span>
              {course.targets.from ? `${course.targets.from}` : "指定なし"}
              　〜　
              {course.targets.to ? `${course.targets.to}` : "指定なし"}
            </span>
          )}
          {course.targets?.type === CourseTargetType.None && (
            <span>指定なし</span>
          )}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* レベル */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">レベル</LabelUI>
          {pageType === PageType.CoursesNewConfirmPage &&
            currentServiceKind()?.key !== ServiceKind.LiberalArts && (
              <BadgeUI>必須</BadgeUI>
            )}
        </div>
        <TextUI data-testid="courses-confirm-level">
          {course.levelEntity?.name}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* カテゴリ */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">カテゴリ</LabelUI>
        </div>
        <TextUI data-testid="courses-confirm-category">
          {course.subjectEntity1?.name}
          {course.subjectEntity2 && (
            <span className="ml-4">{course.subjectEntity2.name}</span>
          )}
        </TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* 教材 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">教材（コース共通）</LabelUI>
        </div>
        {pageType === PageType.CoursePage
          ? (course.teachingMaterials as Array<TeachingMaterialsDisplay>).map(
              (material, index) => (
                <div key={`course-teaching-material-${index}`}>
                  {material.url ? (
                    <LinkTextUI
                      to="#"
                      data-testid="courses-confirm-teaching-material"
                      onClick={() =>
                        fileSave && fileSave(material.url, material.name)
                      }
                    >
                      {material.name}
                    </LinkTextUI>
                  ) : (
                    <TextUI data-testid="courses-confirm-teaching-material-no-file">
                      {material.name}
                    </TextUI>
                  )}
                </div>
              )
            )
          : (course.teachingMaterials as Array<string>)?.map(
              (material, index) => {
                return (
                  <div key={`course-teaching-material-${index}`}>
                    <TextUI
                      data-testid={`courses-confirm-teaching-material-${index}`}
                    >
                      {material}
                    </TextUI>
                  </div>
                );
              }
            )}
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* 予約人数＆定員 */}
      {pageType === PageType.CoursePage && (
        <div>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">定員</LabelUI>
            </div>
            <TextUI data-testid="courses-course-clazz-capacity">
              {course.clazzCapacity}名
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">予約人数</LabelUI>
            </div>
            <TextUI data-testid="courses-course-clazz-participants-count">
              {course.clazzParticipantsCount}名
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">キャンセル人数</LabelUI>
            </div>
            <TextUI data-testid="courses-course-clazz-canceled-count">
              {course.canceledCount}名
            </TextUI>
          </FormGroupUI>
          <hr className="border-under-line" />
        </div>
      )}

      {/* 料金設定 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">料金設定</LabelUI>
          {pageType === PageType.CoursesNewConfirmPage && (
            <BadgeUI>必須</BadgeUI>
          )}
        </div>
        <PriceUI
          data-testid="courses-confirm-price"
          price={course.price}
        ></PriceUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* コース概要 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">コース概要</LabelUI>
          {pageType === PageType.CoursesNewConfirmPage && (
            <BadgeUI>必須</BadgeUI>
          )}
        </div>
        <TextUI data-testid="courses-course-overview">{course.overview}</TextUI>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* コース詳細 */}
      <div className="mt-4">
        <LabelUI className="text-lg">コース詳細</LabelUI>
        <div className="pl-2.5">
          {courseDetailSortOrder.map((c) => {
            const detail:
              | CreateCourseDetailInput
              | UpdateCourseDetailInput
              | undefined = course.details.find((d) => d.category === c);
            return (
              detail !== undefined &&
              detail.description !== "" && (
                <div key={detail.category} className="mt-3">
                  <LabelUI
                    className="text-base"
                    data-testid={`courses-confirm-course-detail-title-${detail.category}`}
                  >
                    {courseDetail[detail.category]}
                  </LabelUI>
                  <TextUI
                    data-testid={`courses-confirm-course-detail-description-${detail.category}`}
                    className="pl-2.5 mt-1.5"
                  >
                    {detail.description}
                  </TextUI>
                </div>
              )
            );
          })}
        </div>
      </div>
      <hr className="mt-5 border-under-line" />

      {/* コース画像 */}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5 mb-2.5">
          <LabelUI className="text-lg">コース・イメージ</LabelUI>
        </div>
        <div className="grid grid-cols-3">
          {course.images?.map((image: CourseImage, index: number) => (
            <FileInputTypeImageUI
              key={`course-image-${index}`}
              data-testid="courses-confirm-images"
              type="confirm"
              src={image.url ? image.url : noImage}
              fname={image.name}
            />
          ))}
        </div>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* コース動画 */}
      {currentServiceKind()?.key !== ServiceKind.LiberalArts && (
        <>
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI className="text-lg">イメージ動画URL</LabelUI>
            </div>
            {course.videos?.length > 0 && (
              <TextUI data-testid="courses-course-clazz-canceled-count">
                {course.videos[0].url}
              </TextUI>
            )}
          </FormGroupUI>
          <hr className="border-under-line" />
        </>
      )}

      {/* レッスン・マスター */}
      <FormGroupUI>
        <div className="mt-4">
          <LabelUI className="text-lg">授業回数/授業名</LabelUI>
          <LabelUI
            className="text-lg ml-4 mt-3"
            data-testid="courses-confirm-lesson-count"
          >
            全{course.lessonMasters.length}回
          </LabelUI>
          <div className="ml-8">
            {course.lessonMasters.map((lesson: LessonMasterDisplay, lIndex) => {
              return (
                <div key={`course-lesson-${lIndex}`} className="mt-3">
                  <LabelUI
                    className="text-base inline-block"
                    data-testid={`courses-confirm-course-lesson-order-${lesson.order}`}
                  >
                    第{lesson.order}回
                  </LabelUI>
                  <TextUI
                    data-testid={`courses-confirm-course-lesson-name-${lesson.order}`}
                    className="pl-2.5 mt-1.5 inline-block"
                  >
                    {lesson.name}
                  </TextUI>
                  <div className="ml-8">
                    {pageType === PageType.CoursePage
                      ? (
                          lesson.teachingMaterials as Array<TeachingMaterialsDisplay>
                        ).map((material, mIndex) => (
                          <div
                            key={`lesson-master-teaching-material-${mIndex}`}
                          >
                            {material.url ? (
                              <LinkTextUI
                                to="#"
                                data-testid="courses-confirm-teaching-material"
                                onClick={() =>
                                  fileSave &&
                                  fileSave(material.url, material.name)
                                }
                              >
                                {material.name}
                              </LinkTextUI>
                            ) : (
                              <TextUI data-testid="courses-confirm-teaching-material-no-file">
                                {material.name}
                              </TextUI>
                            )}
                          </div>
                        ))
                      : (lesson.teachingMaterials as Array<string>)?.map(
                          (material, mIndex) => (
                            <div key={`course-teaching-material-${mIndex}`}>
                              <TextUI
                                data-testid={`courses-confirm-teaching-material-${mIndex}`}
                              >
                                {material}
                              </TextUI>
                            </div>
                          )
                        )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </FormGroupUI>
      <hr className="border-under-line" />

      {/* アンケート */}
      <FormGroupUI>
        <div className="mt-4">
          <LabelUI className="text-lg">申込時アンケート</LabelUI>
          <div className="ml-2 mt-4">
            <QuestionnairePreviewUI questionnaires={course.questionnaires} />
          </div>
        </div>
      </FormGroupUI>
    </div>
  );
};
