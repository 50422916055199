export const announcementsSearchSettings = {
  offsetDelta: 20,
};

export enum AnnouncementKind {
  /**
   * お知らせ
   */
  News = "news",

  /**
   * メンテナンス
   */
  Maintenance = "maintenance",

  /**
   * キャンペーン
   */
  Campaign = "campaign",
}
