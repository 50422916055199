import React, { TextareaHTMLAttributes } from "react";

import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";

export type NewValidatableTextareaUIProps = {
  error?:
    | boolean
    | {
        message?: string;
      };
  "data-testid": string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const NewValidatableTextareaUI = React.forwardRef<
  HTMLTextAreaElement,
  NewValidatableTextareaUIProps
>(
  (
    { className = "", "data-testid": testid, children, error, ...rest },
    ref
  ) => {
    return (
      <div>
        <div className="flex items-center space-x-3">
          {error && <ErrorCheckIcon />}
          <textarea
            ref={ref}
            className={`leading-5 pl-4 pt-2 tracking-wider border rounded ${className} ${
              error ? "border-error" : ""
            }`}
            data-testid={testid}
            {...rest}
          />
        </div>
        {typeof error !== "boolean" && error?.message && (
          <p
            data-testid={`${testid}-error-message`}
            className="text-error mt-1.5 ml-8"
          >
            {error?.message}
          </p>
        )}
      </div>
    );
  }
);
