import React, { useCallback } from "react";

import { ButtonUI } from "../../../../components/Button";
import {
  AnsweredCoursesTableRowFragment,
  Questionnaire,
  QuestionnaireOption,
} from "../../../../generated/graphql";
import { CourseQuestionnaire } from "../../../../lib/types/course";
import { getQuestionnaireTypeByString } from "../../../../lib/utils/questionnaires";

export type QuestionnairesTableRowProps = {
  hasAdminAuthority: boolean;
  answeredCourse: AnsweredCoursesTableRowFragment;
  index: number;
  onPreview: (questionnaires: CourseQuestionnaire[]) => void;
  onClickDownload: (courseId: string) => void;
};

const QuestionnairesTableRowUI: React.FC<QuestionnairesTableRowProps> = ({
  hasAdminAuthority,
  answeredCourse,
  index,
  onPreview,
  onClickDownload,
}) => {
  const onClickPreview = useCallback(() => {
    const selectedQuestionnaires: CourseQuestionnaire[] =
      answeredCourse.questionnaires!.map((questionnaire: Questionnaire) => {
        return {
          id: questionnaire.id || undefined,
          typenameDisplay: questionnaire.typename,
          typename: getQuestionnaireTypeByString(questionnaire.typename),
          order: questionnaire.order,
          title: questionnaire.title,
          required: questionnaire.required,
          options:
            questionnaire.options?.map((option: QuestionnaireOption) => ({
              name: option.name,
            })) || [],
          selectableNumber: questionnaire.selectableNumber || 0,
          condition: questionnaire.condition || undefined,
          maxScale: questionnaire.maxScale || 0,
          minLabel: questionnaire.minLabel || "",
          maxLabel: questionnaire.maxLabel || "",
        };
      });
    onPreview(selectedQuestionnaires);
  }, [answeredCourse.questionnaires, onPreview]);
  return (
    <tr
      data-testid="questionnaires-table-row"
      className={`h-12 ${index % 2 !== 0 ? "bg-light-blue" : ""} text-sm`}
    >
      <td className="border-r border-dark-gray pl-5">{index + 1}</td>
      <td className="border-dark-gray pl-5">
        {`${answeredCourse?.name}（${answeredCourse?.courseId}）`}
      </td>
      <td className="flex justify-end">
        <ButtonUI
          data-testid="questionnaires-info-preview-btn"
          buttonType="secondary"
          size="xs"
          className="ml-1 mr-4 bg-white"
          onClick={onClickPreview}
        >
          プレビュー
        </ButtonUI>
        {hasAdminAuthority && (
          <ButtonUI
            data-testid="questionnaires-info-download-btn"
            type="button"
            size="custom"
            className="mr-4 w-40 h-10"
            onClick={() => {
              onClickDownload(answeredCourse?.id);
            }}
          >
            結果ダウンロード
          </ButtonUI>
        )}
      </td>
    </tr>
  );
};

export default QuestionnairesTableRowUI;
