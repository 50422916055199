import { ServiceKind } from "../../generated/graphql";

/**
 * データベースに保存する際に使用する文字列キー
 * @deprecated
 */
export enum ServiceKindDBValue {
  /** AEON */
  Aeon = "aeon",
  /** 小学生 */
  ElementarySchool = "elementary",
  /** 社会人（リベラルアーツ） */
  LiberalArts = "liberal_arts",
}

export const getServiceKindLable = (
  kind: ServiceKindDBValue | ServiceKind | undefined | null
): string => {
  switch (kind) {
    case (ServiceKindDBValue.Aeon, ServiceKind.Aeon):
      return "AEON";
    case (ServiceKindDBValue.ElementarySchool, ServiceKind.ElementarySchool):
      return "小学生";
    case (ServiceKindDBValue.LiberalArts, ServiceKind.LiberalArts):
      return "社会人";
    default:
      // undefined, null
      return "AEON";
  }
};
