import { MouseEventHandler } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../../components/Button";
import { LabelUI } from "../../../../../components/Label";
import { ModalUI } from "../../../../../components/Modal";
import { ModalFormGroupUI } from "../../../../../components/MordalFormGroup";
import { NewValidatableInputUI } from "../../../../../components/ValidatableInput";
import {
  CreateOrganizerInput,
  UpdateOrganizerInput,
  Organizer,
} from "../../../../../generated/graphql";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";

export enum OrganizerEditMode {
  CREATE = "create",
  UPDATE = "update",
}

type Props = {
  visibility: boolean;
  onCreate?: (input: CreateOrganizerInput) => void;
  onUpdate?: (input: UpdateOrganizerInput) => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  editMode: OrganizerEditMode;
  organizer?: Organizer;
};

type OrganizerInput = {
  name: string;
};

export const OrganizerEditModalUI = ({
  visibility,
  onCreate,
  onUpdate,
  onCancel,
  editMode,
  organizer,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizerInput>({
    defaultValues: {
      name: organizer?.name,
    },
  });

  const onHandleSubmit: SubmitHandler<OrganizerInput> = (input) => {
    if (editMode === OrganizerEditMode.CREATE) {
      onCreate!({
        name: input.name,
      });
    } else {
      onUpdate!({
        id: organizer!.id,
        name: input.name,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
    >
      <ModalUI
        data-testid="organizer-edit-modal"
        visibility={visibility}
        className="w-full"
      >
        <div className="inline-block pt-4 pb-10 px-3 text-center">
          <h1
            className="text-left text-2xl tracking-wider text-subtitle"
            data-testid="organizer-edit-modal-title"
          >
            {editMode === OrganizerEditMode.CREATE ? "項目追加" : "項目編集"}
          </h1>
          <hr className="border-under-line mt-1"></hr>
          <div
            className="mt-4 text-2xl tracking-wider"
            data-testid="organizer-edit-description"
          >
            <div className="whitespace-pre-wrap"></div>
          </div>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">コード</LabelUI>
              <p data-testid="organizer-system-code">
                {editMode === OrganizerEditMode.CREATE
                  ? "自動で採番されます。"
                  : `org-${("000000" + organizer?.id).slice(-6)}`}
              </p>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">事業者名</LabelUI>
              <div
                data-testid="organizer-edit-name-container"
                className="text-left w-10/12"
              >
                <NewValidatableInputUI
                  data-testid="organizer-edit-name"
                  className="w-10/12"
                  placeholder="事業者名を入力してください。"
                  id="name"
                  error={errors.name}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "事業者名は必須項目です。",
                    },
                    maxLength: {
                      value: 20,
                      message: "２０文字以内で入力してください。",
                    },
                  })}
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              data-testid="organizer-edit-cancel"
              type="button"
              buttonType="secondary"
              onClick={onCancel}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="organizer-edit-submit" type="submit">
              {editMode === OrganizerEditMode.CREATE ? "登録" : "更新"}
            </ButtonUI>
          </div>
        </div>
      </ModalUI>
    </form>
  );
};
