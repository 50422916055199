import React, { InputHTMLAttributes } from "react";

import styles from "./ValidatableRadio.module.css";

export type NewValidatableRadioUIProps = InputHTMLAttributes<HTMLInputElement>;

export const NewValidatableRadioUI = React.forwardRef<
  HTMLInputElement,
  NewValidatableRadioUIProps
>(({ className, children, id, ...rest }, ref) => {
  return (
    <div className={`flex items-center ${className}`}>
      <input
        ref={ref}
        className={`appearance-none mr-2.5 ${styles.check}`}
        id={id}
        type="radio"
        {...rest}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
});
