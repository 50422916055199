import React, { ChangeEventHandler } from "react";

import {
  CustomerSearchResultsFragment,
  CustomersTableRowFragment,
} from "../../generated/graphql";
import CustomersTableRowUI from "./CustomersTableRow";

export type CustomersTableUIProps = {
  customers: CustomerSearchResultsFragment;
  selectedCustomerId?: string | null;
  selectedCustomerFamilyName?: string | null;
  selectedCustomerGiveName?: string | null;
  selectedCustomerFamilyNameKana?: string | null;
  selectedCustomerGiveNameKana?: string | null;
  selectedCustomerEmail?: string | null;
  selectedCustomerTel?: string | null;
  onSelectedCustomer?: ChangeEventHandler<HTMLInputElement>;
};

export const CustomersTableUI: React.FC<CustomersTableUIProps> = ({
  customers,
  selectedCustomerId,
  selectedCustomerFamilyName,
  selectedCustomerFamilyNameKana,
  selectedCustomerGiveName,
  selectedCustomerGiveNameKana,
  selectedCustomerEmail,
  selectedCustomerTel,
  onSelectedCustomer,
}) => {
  return (
    <div className="flex">
      <div className="overflow-y-auto h-60">
        <table>
          <thead className="sticky top-0 bg-subtitle text-white z-50">
            <tr className="text-left h-16">
              <th className="border w-20 text-center z-20">選択</th>
              <th className="border w-32 text-center z-20">お客様番号</th>
            </tr>
          </thead>
          <tbody>
            {customers.nodes.map(
              (customer: CustomersTableRowFragment, index: number) => (
                <CustomersTableRowUI
                  data-testid="customers-table-row"
                  key={customer.id}
                  index={index}
                  customer={customer}
                  selectedCustomerId={selectedCustomerId}
                  onSelectedCustomer={onSelectedCustomer}
                ></CustomersTableRowUI>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="overflow-y-auto h-60 ml-6">
        {!selectedCustomerId && (
          <p className="leading-10">対象のお客様番号を選択してください</p>
        )}
        {selectedCustomerId && (
          <table>
            <tbody>
              <tr className="text-left h-12">
                <th className="w-20">氏名：</th>
                <td>
                  <span
                    className="mr-3"
                    data-testid="selected-customer-family-name"
                  >
                    {selectedCustomerFamilyName}
                  </span>
                  <span data-testid="selected-customer-give-name">
                    {selectedCustomerGiveName}
                  </span>
                </td>
              </tr>
              <tr className="text-left h-12">
                <th className="w-20">フリガナ：</th>
                <td>
                  <span
                    className="mr-3"
                    data-testid="selected-customer-family-name-kana"
                  >
                    {selectedCustomerFamilyNameKana}
                  </span>
                  <span data-testid="selected-customer-give-name-kana">
                    {selectedCustomerGiveNameKana}
                  </span>
                </td>
              </tr>
              <tr className="text-left h-12">
                <th className="w-20">会員ID：</th>
                <td data-testid="selected-customer-email">
                  {selectedCustomerEmail}
                </td>
              </tr>
              <tr className="text-left h-12">
                <th className="w-20">電話番号：</th>
                <td data-testid="selected-customer-tel">
                  {selectedCustomerTel}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
