import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SubmitHandler } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import {
  useCreateCustomerNoteMutation,
  useCustomerQuery,
} from "../../../generated/graphql";
import { useErrorState } from "../../../hooks/errorRouter";
import { ErrorType } from "../../../lib/constants/error";
import CustomerPage from "./CustomerPage";
type CustomerPageParams = {
  id: string;
};

const CustomerPageContainer: React.VFC = () => {
  const history = useHistory();
  const { id } = useParams<CustomerPageParams>();
  const { onError } = useErrorState();
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const { data, error, loading } = useCustomerQuery({
    variables: { id },
    fetchPolicy: "no-cache",
  });

  const [createCustomerNote] = useCreateCustomerNoteMutation({
    onError: (e) => {
      onError(e);
      setIsOpenErrorModal(true);
    },
    onCompleted: () => {
      setVisibility(true);
    },
  });

  const [note, setNote] = useState("");

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback((event) => {
    setNote(event.target.value);
  }, []);

  type NoteEditForm = {
    note: string;
  };

  const onHandleSubmit: SubmitHandler<NoteEditForm> = useCallback(() => {
    createCustomerNote({
      variables: {
        input: {
          note: note,
          customerId: id,
        },
      },
    });
  }, [createCustomerNote, id, note]);

  useEffect(() => {
    if (error) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    }
  }, [error, history]);

  useEffect(() => {
    if (data)
      if (data.customer.profile?.note?.note) {
        setNote(data.customer.profile?.note?.note);
      }
  }, [data]);

  const clickModalButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setVisibility(false);
    }, [setVisibility]);

  const clearErrorModalButton: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      isOpenErrorModal && setIsOpenErrorModal(false);
    }, [isOpenErrorModal]);

  if (loading) {
    return <LoadingUI title="検索中" />;
  }

  if (!data) {
    console.error("No Data");
    history.push("/error/internalservererror");
    return <></>;
  }

  return (
    <>
      <CustomerPage
        customer={data.customer}
        note={note}
        onChangeInput={onChangeInput}
        onHandleSubmit={onHandleSubmit}
      />
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        onClick={clearErrorModalButton}
        visibility={isOpenErrorModal}
      >
        <p>スタッフメモの登録に失敗しました。</p>
        <p>しばらく時間をおいてから、もう一度お試しください。</p>
      </ErrorModalUI>
      <CompletedModalUI
        title="更新完了"
        btnTitle="閉じる"
        description="スタッフメモを更新しました。"
        visibility={visibility}
        onClick={clickModalButton}
      />
    </>
  );
};
export default CustomerPageContainer;
