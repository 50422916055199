import { useParams } from "react-router";

import { useCoursePageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import CoursePage from "./CoursePage";

type CoursePageParams = {
  id: string;
};

const CoursePageContainer = () => {
  const { id } = useParams<CoursePageParams>();
  const errorRouter = useErrorRouter();
  const { data, loading } = useCoursePageQuery({
    variables: { id },
    // レスポンスの中身がキャッシュを用いられることがありバグのもとになるので、再取得させる。
    fetchPolicy: "no-cache",
    onError: errorRouter,
  });

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CoursePage course={data.course} />
    </>
  );
};

export default CoursePageContainer;
