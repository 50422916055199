import React from "react";

import { useParams } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { useContBillPaymentMismatchQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import ContBillPaymentMismatchPage from "./ContBillPaymentMismatchPage";

type ContBillPaymentMismatchParams = {
  id: string;
};

const ContBillPaymentMismatchPageContainer: React.VFC = () => {
  const { id } = useParams<ContBillPaymentMismatchParams>();

  const handleErrorRouter = useErrorRouter();
  const { data, loading } = useContBillPaymentMismatchQuery({
    variables: { id },
    onError: handleErrorRouter,
  });

  if (loading || !data) {
    return <LoadingUI />;
  }

  return <ContBillPaymentMismatchPage data={data} />;
};

export default ContBillPaymentMismatchPageContainer;
