import React from "react";

import { useHistory } from "react-router-dom";

import { useProfileEditQuery } from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import ProfileEditPage from "./ProfileEditPage";

const ProfileEditPageContainer = () => {
  const history = useHistory();
  const { data, error, loading } = useProfileEditQuery();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return <ProfileEditPage data={data} />;
};

export default ProfileEditPageContainer;
