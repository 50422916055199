import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CourseEditUI } from "../../../components/CourseEdit";
import {
  CourseDetail,
  CourseForEditPageFragment,
  CourseImage,
  CoursePriceInput,
  CourseTeachingMaterial,
  LessonMaster,
  SubjectSelectorItemFragment,
  Questionnaire,
  QuestionnaireType,
  LevelFragment,
  OrganizerFragment,
  LessonMasterTeachingMaterial,
} from "../../../generated/graphql";
import {
  courseDetailSortOrder,
  targetTypes,
} from "../../../lib/constants/courses";
import { PageType } from "../../../lib/constants/pageType";
import { SelectType } from "../../../lib/constants/SelectType";
import { ValidatedFile } from "../../../lib/types";
import { CourseEdit, CourseQuestionnaire } from "../../../lib/types/course";
import {
  getInitialQuestionnaireData,
  getQuestionnaireTypeByString,
  getSelectableOptionConditionTypeByString,
  getConditionByQuestionnaireInfo,
  getSelectableNumberByQuestionnaireInfo,
} from "../../../lib/utils/questionnaires";

import { CourseEditLocationState } from ".";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "コース詳細",
  },
  {
    label: "コース編集",
  },
];

type CoursesEditPageProps = {
  courseOrigin: CourseForEditPageFragment;
  state?: CourseEditLocationState;
  loadingMasters: boolean;
  organizers?: OrganizerFragment[];
  subjects: SubjectSelectorItemFragment[];
  levels: LevelFragment[];
};

const CoursesEditPage: React.FC<CoursesEditPageProps> = ({
  courseOrigin,
  state,
  loadingMasters,
  organizers,
  subjects,
  levels,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const {
    images,
    __typename,
    subjects: _subjects,
    ...omittedCourse
  } = courseOrigin;
  const [course, setCourse] = useState<CourseEdit>(() => {
    if (state) {
      if (
        !state.courseEdit.questionnaires ||
        state.courseEdit.questionnaires.length === 0
      ) {
        state.courseEdit.questionnaires = [getInitialQuestionnaireData(1)];
      }
      return state.courseEdit;
    }
    return {
      ...omittedCourse,
      details: courseDetailSortOrder.map((c) => {
        // フロントの UT で配列の中身が期待値と結果が同じになるようにするためだけにソートしている
        // バックエンドの処理では詳細の順番は全く関係ない
        const detail = courseOrigin.details.find((d) => d.category === c);
        if (detail === undefined) {
          // 既存データに存在しない詳細カテゴリの情報
          return {
            category: c,
            description: "",
          };
        } else {
          const { __typename, ...omittedDetail } = detail;
          return omittedDetail;
        }
      }),
      price: {
        type: courseOrigin.price.type,
        valueNoTax: courseOrigin.price.valueNoTax,
      },
      teachingMaterials: undefined,
      courseImages: undefined,
      organizer: courseOrigin.organizer || undefined,
      subjectEntity1:
        courseOrigin.subjects && courseOrigin.subjects[0]
          ? {
              id: courseOrigin.subjects[0].id,
              name: courseOrigin.subjects[0].name,
              status: courseOrigin.subjects[0].status,
            }
          : undefined,
      subjectEntity2:
        courseOrigin.subjects && courseOrigin.subjects[1]
          ? {
              id: courseOrigin.subjects[1].id,
              name: courseOrigin.subjects[1].name,
              status: courseOrigin.subjects[1].status,
            }
          : undefined,
      levelEntity: courseOrigin.levelEntity || undefined,
      videos:
        courseOrigin.videos && courseOrigin.videos[0]
          ? [
              {
                id: courseOrigin.videos[0].id,
                courseId: courseOrigin.id,
                url: courseOrigin.videos[0].url,
              },
            ]
          : [],
      lessonMasters:
        courseOrigin.lessonMasters?.map((lessonMaster: LessonMaster) => {
          const { __typename, teachingMaterials, ...omittedLessonMaster } =
            lessonMaster;
          return { ...omittedLessonMaster, teachingMaterials: undefined };
        }) ?? [],
      questionnaires:
        courseOrigin?.questionnaires?.length === 0
          ? [getInitialQuestionnaireData(1)] // アンケートが設定されていない場合は編集画面で初期値を表示
          : courseOrigin?.questionnaires?.map((que: Questionnaire) => ({
              id: que.id,
              typenameDisplay: getQuestionnaireTypeByString(que.typename),
              // 編集画面ではDisplayの方を利用するので初期値で良い
              typename: QuestionnaireType.SelectQuestionnaire,
              order: que.order,
              title: que.title,
              required: que.required,
              options: que.options?.map((opt) => ({
                name: opt.name,
              })) || [{ name: "" }],
              selectableNumber: que.selectableNumber || 1,
              condition: getSelectableOptionConditionTypeByString(
                que.condition || ""
              ),
              maxScale: que.maxScale || 5,
              minLabel: que.minLabel || "",
              maxLabel: que.maxLabel || "",
            })) || [getInitialQuestionnaireData(1)], // アンケートが設定されていない場合は空配列が帰ってくるが、Type上未設定を許容しているのでやむを得ず。
      questionnaireAnswered: courseOrigin.questionnaireAnswered,
    };
  });

  const history = useHistory();

  const [courseImages, setCourseImages] = useState<CourseImage[]>(
    state?.courseImages || courseOrigin.images || []
  );
  const [teachingMaterials, setTeachingMaterial] = useState<
    CourseTeachingMaterial[]
  >(state?.teachingMaterials || courseOrigin.teachingMaterials!);
  const [orderToLessonMaterialMap, setOrderToLessonMaterialMap] = useState<
    Map<number, LessonMasterTeachingMaterial[]>
  >(() => {
    if (state?.orderToLessonMaterialMap) {
      return state.orderToLessonMaterialMap;
    }
    const map = new Map<number, LessonMasterTeachingMaterial[]>();
    courseOrigin.lessonMasters?.forEach((master) => {
      map.set(master.order, master.teachingMaterials ?? []);
    });
    return map;
  });
  const [imageFiles, setImageFiles] = useState<ValidatedFile[]>([]);
  const [errMaterialFiles, setErrMaterialFiles] = useState<string[]>([]);
  const [deleteCourseImageIds, setDeleteCourseImageIds] = useState<string[]>(
    state?.deleteCourseImageIds || []
  );
  const [deleteTeachingMaterialIds, setDeleteTeachingMaterialIds] = useState<
    string[]
  >(state?.deleteTeachingMaterialIds || []);
  const [
    deleteLessonMasterTeachingMaterialIds,
    setDeleteLessonMasterTeachingMaterialIds,
  ] = useState<string[]>(state?.deleteLessonMasterTeachingMaterialIds ?? []);

  type VideoUrlErrorType = {
    message: string;
  };
  const [videoUrlError, setVideoUrlError] = useState<
    boolean | VideoUrlErrorType
  >(false);

  const onHandleSubmit: SubmitHandler<CourseEdit> = () => {
    if (imageFiles.find((imageFile) => imageFile.isError === true)) {
      return;
    }
    if (errMaterialFiles.length > 0) {
      return;
    }

    // 動画URLのバリデーション判定
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    if (
      course.videos !== null &&
      course.videos !== undefined &&
      course.videos.length > 0
    ) {
      if (course.videos[0].url !== "") {
        const matchPattern = regExp.test(course.videos[0].url);
        const match = course.videos[0].url.match(regExp);
        if (!matchPattern) {
          setVideoUrlError({ message: "正しいURLの形式で入力してください" });
          return;
        }
        if (match !== null && match[7].length !== 11) {
          setVideoUrlError({ message: "正しいURLの形式で入力してください" });
          return;
        }
      }
    }

    // タイトルが設定されていないアンケートを除外し、バックエンド用に差し替える
    const newQuestionnaires = course.questionnaires
      .filter(
        (questionnaire: CourseQuestionnaire) =>
          questionnaire.title !== "" && questionnaire.typenameDisplay !== ""
      )
      .map((questionnaire: CourseQuestionnaire) => {
        questionnaire.typename = getQuestionnaireTypeByString(
          questionnaire.typenameDisplay || ""
        );
        questionnaire.minLabel =
          questionnaire.minLabel === "" ? "低い" : questionnaire.minLabel;
        questionnaire.maxLabel =
          questionnaire.maxLabel === "" ? "高い" : questionnaire.maxLabel;
        questionnaire.condition =
          getConditionByQuestionnaireInfo(questionnaire);
        questionnaire.selectableNumber =
          getSelectableNumberByQuestionnaireInfo(questionnaire);
        return questionnaire;
      });
    course.questionnaires = newQuestionnaires;

    const state: CourseEditLocationState = {
      courseEdit: course,
      courseImages,
      teachingMaterials,
      orderToLessonMaterialMap,
      deleteTeachingMaterialIds,
      deleteLessonMasterTeachingMaterialIds,
      deleteCourseImageIds,
    };
    history.replace(`/courses/${course.id}/confirm`, state);
  };

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setCourse({
        ...course,
        [event.target.name]: event.target.value,
      });
    },
    [course]
  );

  const onChangeVideoInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      const videos = [];
      if (
        course.videos !== null &&
        course.videos !== undefined &&
        course.videos.length > 0
      ) {
        videos.push({
          id: course.videos[0].id,
          courseId: course.id,
          url: event.target.value,
        });
      } else {
        videos.push({
          courseId: course.id,
          url: event.target.value,
        });
      }
      setCourse({
        ...course,
        videos: videos,
      });
    },
    [course]
  );

  const onChangeCustomSelect = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (name === SelectType.TargetFrom) {
        setCourse({
          ...course,
          targets: {
            type: course.targets?.type!,
            from: value,
            to: course.targets?.to,
          },
        });
      }
      if (name === SelectType.TargetTo) {
        setCourse({
          ...course,
          targets: {
            type: course.targets?.type!,
            from: course.targets?.from,
            to: value,
          },
        });
      }
    },
    [course]
  );

  const onOrganizerChange = (organizer: OrganizerFragment | undefined) => {
    setCourse({
      ...course,
      organizer: organizer,
      organizerId: organizer ? organizer.id : null,
    });
  };

  const onSubject1Change = (
    subject: SubjectSelectorItemFragment | undefined
  ) => {
    setCourse({
      ...course,
      subjectEntity1: subject,
    });
  };

  const onSubject2Change = (
    subject: SubjectSelectorItemFragment | undefined
  ) => {
    setCourse({
      ...course,
      subjectEntity2: subject,
    });
  };

  const onLevelChange = (level: LevelFragment | undefined) => {
    setCourse({
      ...course,
      levelEntity: level,
      levelId: level ? level.id : null,
    });
  };

  const onChangeInputDetail: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      const newCourseDetail: CourseDetail[] | undefined = course.details?.map(
        (detail: CourseDetail) => {
          return detail.category === event.target.name
            ? {
                ...detail,
                description: event.target.value,
              }
            : detail;
        }
      );

      setCourse({
        ...course,
        details: newCourseDetail,
      });
    },
    [course]
  );

  const onChangeLessonMasters = useCallback(
    (lessons) => {
      setCourse({
        ...course,
        lessonMasters: [...lessons],
      });
    },
    [course]
  );

  const onChangePrice = useCallback(
    (price: CoursePriceInput) => {
      setCourse({
        ...course,
        price,
      });
    },
    [course]
  );

  const onChangeRadioType: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const targetType = targetTypes.find(
        (item) => item.value === event.target.value
      );
      setCourse({
        ...course,
        targets: { type: targetType?.value! },
      });
    },
    [course]
  );

  const onChangeErrMaterialFiles = (files: string[]) => {
    setErrMaterialFiles(files);
  };

  const onChangeMaterialFile = (file: File) => {
    setTeachingMaterial([
      {
        __typename: "CourseTeachingMaterial",
        id: "",
        name: file.name,
        url: "",
      },
      ...teachingMaterials,
    ]);
    if (!course.teachingMaterials) {
      course.teachingMaterials = [];
    }

    // アップロード用
    // courseEdit.teachingMaterialsにあるファイルがアップロード対象
    setCourse({
      ...course,
      teachingMaterials: [file, ...course.teachingMaterials!],
    });
  };

  const onChangeLessonMasterMaterialFile = useCallback(
    (targetOrder: number, file: File) => {
      const value = orderToLessonMaterialMap.get(targetOrder);
      setOrderToLessonMaterialMap(
        orderToLessonMaterialMap.set(targetOrder, [
          {
            __typename: "LessonMasterTeachingMaterial",
            id: "",
            name: file.name,
            url: "",
          },
          ...(value ?? []),
        ])
      );

      // アップロード用をセット
      const { lessonMasters } = course;
      lessonMasters.map((lessonMaster) => {
        if (lessonMaster.order !== targetOrder) {
          return lessonMaster;
        }
        lessonMaster.teachingMaterials = [
          file,
          ...(lessonMaster?.teachingMaterials ?? []),
        ];
        return lessonMaster;
      });
      setCourse({
        ...course,
        lessonMasters,
      });
    },
    [course, orderToLessonMaterialMap]
  );

  const onChangeCourseImages = (files: ValidatedFile[]) => {
    let uploadImageFiles: (CourseImage & ValidatedFile)[] = [];
    let uploadCourseImages: CourseImage[] = [];
    for (let f of files) {
      uploadImageFiles.push(
        Object.assign(f, {
          id: f.imageId ? f.imageId : "",
          order: f.previewId + 1,
          url:
            courseImages.find((img: CourseImage) => img.id === f.imageId)
              ?.url || "",
        })
      );
      uploadCourseImages.push({
        id: f.imageId ? f.imageId : "",
        order: f.previewId + 1,
        url:
          courseImages.find((img: CourseImage) => img.id === f.imageId)?.url ||
          "",
        name: f.name,
      });
    }
    setCourseImages(uploadCourseImages);
    setImageFiles(files);

    // アップロード用
    // courseEdit.courseImages にあるファイルがアップロード対象
    setCourse({
      ...course,
      courseImages: uploadImageFiles,
    });
  };

  const onDeleteImageFile = (removeFileName: string) => {
    const targetCourseImage: CourseImage | undefined = courseImages.find(
      (courseImage: CourseImage) =>
        courseImage && courseImage.name === removeFileName
    );
    if (targetCourseImage !== undefined) {
      let newList: string[] = deleteCourseImageIds.concat([
        targetCourseImage.id,
      ]);
      newList = Array.from(new Set(newList));
      setDeleteCourseImageIds(newList);
      setCourseImages(
        courseImages.filter((img) => img.id !== targetCourseImage!.id)
      );
    }
    const targetFile: File | undefined = course.courseImages?.find(
      (courseImage: File) => courseImage && courseImage.name === removeFileName
    );
    if (course.courseImages && targetFile !== undefined) {
      setCourse({
        ...course,
        courseImages: course.courseImages.map((f) => {
          return f && f.name === removeFileName
            ? ({} as CourseImage & File)
            : f;
        }),
      });
    }
  };

  const onDeleteMaterialFile = (removeFileName: string) => {
    const findMaterial = teachingMaterials.find(
      (teachingMaterial: CourseTeachingMaterial) =>
        teachingMaterial.name === removeFileName
    );
    if (findMaterial) {
      setTeachingMaterial([
        ...teachingMaterials!.filter(
          (item: CourseTeachingMaterial) => item.name !== removeFileName
        ),
      ]);
      setDeleteTeachingMaterialIds([
        ...deleteTeachingMaterialIds!,
        findMaterial.id,
      ]);
    }
    if (course.teachingMaterials) {
      setCourse({
        ...course,
        teachingMaterials: course.teachingMaterials!.filter(
          (item: File) => item.name !== removeFileName
        ),
      });
    }
  };

  const onDeleteLessonMasterMaterialFile = useCallback(
    (targetOrder: number, removeFileName: string) => {
      const targetMaterial = orderToLessonMaterialMap
        .get(targetOrder)
        ?.find((material) => material.name === removeFileName);
      const value =
        orderToLessonMaterialMap
          .get(targetOrder)
          ?.filter((material) => material.name !== removeFileName) ?? [];
      setOrderToLessonMaterialMap(
        orderToLessonMaterialMap.set(targetOrder, value)
      );

      // 削除するIDをセット
      if (targetMaterial?.id) {
        setDeleteLessonMasterTeachingMaterialIds([
          ...deleteLessonMasterTeachingMaterialIds,
          targetMaterial.id,
        ]);
      }

      // アップロード用から削除
      const { lessonMasters } = course;
      lessonMasters.map((lessonMaster) => {
        const materials = lessonMaster.teachingMaterials;
        if (!(lessonMaster.order === targetOrder && materials)) {
          return lessonMaster;
        }
        lessonMaster.teachingMaterials = materials.filter(
          (material) => material.name !== removeFileName
        );
        return lessonMaster;
      });
      setCourse({
        ...course,
        lessonMasters,
      });
    },
    [course, deleteLessonMasterTeachingMaterialIds, orderToLessonMaterialMap]
  );

  const handleBack = () => {
    history.goBack();
  };

  const onChangeQuestionnaires = useCallback(
    (questionnaires: CourseQuestionnaire[]) => {
      setCourse({
        ...course,
        questionnaires,
      });
    },
    [course]
  );

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">コース編集</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <CourseEditUI
          pageType={PageType.CoursesEditPage}
          loadingMasters={loadingMasters}
          organizers={organizers}
          subjects={subjects}
          levels={levels}
          course={course}
          courseImages={courseImages}
          teachingMaterials={teachingMaterials}
          editingCourseImageFiles={
            state !== undefined && state.courseEdit.courseImages
              ? state.courseEdit.courseImages
              : undefined
          }
          editingTeachingMaterialFiles={
            state !== undefined && state.courseEdit.teachingMaterials
              ? state.courseEdit.teachingMaterials
              : undefined
          }
          orderToLessonMaterialMap={orderToLessonMaterialMap}
          onHandleSubmit={onHandleSubmit}
          onChangeInput={onChangeInput}
          onChangeCustomSelect={onChangeCustomSelect}
          onOrganizerChange={onOrganizerChange}
          onSubject1Change={onSubject1Change}
          onSubject2Change={onSubject2Change}
          onLevelChange={onLevelChange}
          onChangeInputDetail={onChangeInputDetail}
          onChangeVideoInput={onChangeVideoInput}
          onChangeLessonMasters={onChangeLessonMasters}
          onChangePrice={onChangePrice}
          onChangeRadioType={onChangeRadioType}
          onChangeMaterialFile={onChangeMaterialFile}
          onChangeErrMaterialFiles={onChangeErrMaterialFiles}
          onDeleteMaterialFile={onDeleteMaterialFile}
          onChangeLessonMasterMaterialFile={onChangeLessonMasterMaterialFile}
          onDeleteLessonMasterMaterialFile={onDeleteLessonMasterMaterialFile}
          handleBack={handleBack}
          onDeleteImageFile={onDeleteImageFile}
          onChangeCourseImages={onChangeCourseImages}
          onChangeQuestionnaires={onChangeQuestionnaires}
          videoUrlError={videoUrlError}
        ></CourseEditUI>
      </div>
    </div>
  );
};

export default CoursesEditPage;
