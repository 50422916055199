import { MouseEventHandler } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../../components/Button";
import { LabelUI } from "../../../../../components/Label";
import { ModalUI } from "../../../../../components/Modal";
import { ModalFormGroupUI } from "../../../../../components/MordalFormGroup";
import { NewValidatableInputUI } from "../../../../../components/ValidatableInput";
import { NewValidatableRadioUI } from "../../../../../components/ValidatableRadio";
import {
  CreateVideoTagInput,
  UpdateVideoTagInput,
  VideoTag,
} from "../../../../../generated/graphql";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";

export enum VideoTagEditMode {
  CREATE = "create",
  UPDATE = "update",
}

enum VideoTagStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

type Props = {
  visibility: boolean;
  onCreate?: (input: CreateVideoTagInput) => void;
  onUpdate?: (input: UpdateVideoTagInput) => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  editMode: VideoTagEditMode;
  videoTag?: VideoTag;
};

type VideoTagInput = {
  name: string;
  status: VideoTagStatus;
};

export const VideoTagEditModalUI = ({
  visibility,
  onCreate,
  onUpdate,
  onCancel,
  editMode,
  videoTag,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VideoTagInput>({
    defaultValues: {
      name: videoTag?.name,
      status:
        videoTag?.status || editMode === VideoTagEditMode.CREATE
          ? VideoTagStatus.ACTIVE
          : VideoTagStatus.INACTIVE,
    },
  });

  const onHandleSubmit: SubmitHandler<VideoTagInput> = (input) => {
    const status = input.status === VideoTagStatus.ACTIVE;
    if (editMode === VideoTagEditMode.CREATE) {
      onCreate!({
        name: input.name,
        status,
      });
    } else {
      onUpdate!({
        id: videoTag!.id,
        name: input.name,
        status,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
    >
      <ModalUI
        data-testid="videoTag-edit-modal"
        visibility={visibility}
        className="w-full"
      >
        <div className="inline-block pt-4 pb-10 px-3 text-center">
          <h1
            className="text-left text-2xl tracking-wider text-subtitle"
            data-testid="videoTag-edit-modal-title"
          >
            {editMode === VideoTagEditMode.CREATE ? "項目追加" : "項目編集"}
          </h1>
          <hr className="border-under-line mt-1"></hr>
          <div
            className="mt-4 text-2xl tracking-wider"
            data-testid="videoTag-edit-description"
          >
            <div className="whitespace-pre-wrap"></div>
          </div>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">コード</LabelUI>
              <p data-testid="videoTag-system-code">
                {editMode === VideoTagEditMode.CREATE
                  ? "自動で採番されます。"
                  : videoTag?.id}
              </p>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">動画タグ名</LabelUI>
              <div
                data-testid="videoTag-edit-name-container"
                className="text-left w-10/12"
              >
                <NewValidatableInputUI
                  data-testid="videoTag-edit-name"
                  className="w-10/12"
                  placeholder="動画タグ名を入力してください。"
                  id="name"
                  error={errors.name}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "動画タグ名は必須項目です。",
                    },
                    maxLength: {
                      value: 20,
                      message: "２０文字以内で入力してください。",
                    },
                  })}
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">ステータス</LabelUI>
              <div className="flex space-x-10">
                {[
                  { label: "有効", value: VideoTagStatus.ACTIVE },
                  { label: "無効", value: VideoTagStatus.INACTIVE },
                ].map((status) => (
                  <NewValidatableRadioUI
                    data-testid={`videoTag-edit-status-${status.value}`}
                    key={status.value}
                    id={`status-${status.value}`}
                    value={status.value}
                    {...register("status")}
                  >
                    {status.label}
                  </NewValidatableRadioUI>
                ))}
              </div>
            </div>
          </ModalFormGroupUI>
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              data-testid="videoTag-edit-cancel"
              type="button"
              buttonType="secondary"
              onClick={onCancel}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="videoTag-edit-submit" type="submit">
              {editMode === VideoTagEditMode.CREATE ? "登録" : "更新"}
            </ButtonUI>
          </div>
        </div>
      </ModalUI>
    </form>
  );
};
