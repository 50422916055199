import React, { MouseEventHandler, useEffect, useState } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CsvDownloadButtonUI } from "../../../components/CsvDownloadButton";
import LinkTextUI from "../../../components/LinkText/LinkText";
import { PaymentsSearchResultsFragment } from "../../../generated/graphql";
import { PaymentsTableUI } from "./components/PaymentsTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済情報照会",
  },
];

export type PaymentsSearchResultsProps = {
  payments: PaymentsSearchResultsFragment;
  loading: boolean;
  hasAdminAuthority: boolean;
  onDisplayMore: MouseEventHandler;
  onGoBackSearchCondition: () => void;
  onClickDownload: () => void;
};

const PaymentsSearchResults: React.VFC<PaymentsSearchResultsProps> = ({
  payments,
  loading,
  hasAdminAuthority,
  onDisplayMore,
  onGoBackSearchCondition,
  onClickDownload,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);

    return () => {
      // unmount
    };
  }, [setBreadcrumbItems]);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">決済情報照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-6">
        <p className="mt-8 font-bold text-xl">
          検索結果｜
          <LinkTextUI
            data-testid="payments-search-results-to-seach-page-link"
            to="#"
            onClick={(event) => {
              event.preventDefault();
              onGoBackSearchCondition();
            }}
          >
            検索画面へ
          </LinkTextUI>
        </p>

        {/* 検索中 */}
        {loading && (
          <div className="mt-8">
            <h2>検索中．．．</h2>
          </div>
        )}

        {/* 検索結果あり */}
        {!loading && payments.totalCount > 0 && (
          <>
            <div
              className="flex justify-between mt-5 items-end mb-1.5"
              style={{ maxWidth: tableWidth }}
            >
              <span data-testid="payments-search-results-total-count">
                全{payments.totalCount}件中{payments.nodes.length}件表示
              </span>
              {hasAdminAuthority && (
                <CsvDownloadButtonUI
                  disabled={payments.totalCount > 1000}
                  onClick={onClickDownload}
                />
              )}
            </div>
            <div className="overflow-x-auto">
              <PaymentsTableUI
                payments={payments}
                onWidthChange={(w) => {
                  setTableWidth(w);
                }}
              />
              {payments.totalCount !== payments.nodes.length && (
                <button
                  data-testid="payments-search-results-display-more-btn"
                  className="border border-dark-gray text-blue hover:underline active:text-blue-active active:underline w-full h-8 mt-5"
                  onClick={onDisplayMore}
                >
                  もっと表示する
                </button>
              )}
            </div>
          </>
        )}
        {/* 検索結果がゼロ件 */}
        {!loading && payments.totalCount === 0 && (
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="payments-search-results-no-payments-exists-message"
              >
                該当する決済情報がありませんでした。
              </p>
              <p>検索条件に該当するが決済情報がありませんでした。</p>
              <p>内容をご確認のうえ、再度検索を行ってください。</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentsSearchResults;
