import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Layout } from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import AnnouncementPage from "./routes/announcements/AnnouncementPage";
import AnnouncementsEditConfirmPage from "./routes/announcements/AnnouncementsEditConfirmPage";
import AnnouncementsEditPage from "./routes/announcements/AnnouncementsEditPage";
import AnnouncementsNewConfirmPage from "./routes/announcements/AnnouncementsNewConfirmPage";
import AnnouncementsNewPage from "./routes/announcements/AnnouncementsNewPage";
import AnnouncementsPage from "./routes/announcements/AnnouncementsPage";
import CategoriesSortPage from "./routes/categories/CategoriesSortPage";
import ClassEditConfirmPage from "./routes/classes/ClassEditConfirmPage";
import ClassEditPage from "./routes/classes/ClassEditPage";
import ClassNewConfirmPage from "./routes/classes/ClassNewConfirmPage";
import ClassNewPage from "./routes/classes/ClassNewPage";
import ClassPage from "./routes/classes/ClassPage";
import ContactPage from "./routes/contacts/ContactPage";
import ContactsNewPage from "./routes/contacts/ContactsNewPage";
import ContactsPage from "./routes/contacts/ContactsPage";
// import ContentPage from "./routes/contents/ContentPage";
// import ContentsEditConfirmPage from "./routes/contents/ContentsEditConfirmPage";
// import ContentsEditPage from "./routes/contents/ContentsEditPage";
// import ContentsNewConfirmPage from "./routes/contents/ContentsNewConfirmPage";
// import ContentsNewPage from "./routes/contents/ContentsNewPage";
// import ContentsPage from "./routes/contents/ContentsPage";
import CorporationPage from "./routes/corporation/CorporationPage";
import CoursePage from "./routes/courses/CoursePage";
import CoursesEditConfirmPage from "./routes/courses/CoursesEditConfirmPage";
import CoursesEditPage from "./routes/courses/CoursesEditPage";
import CoursesNewConfirmPage from "./routes/courses/CoursesNewConfirmPage";
import CoursesNewPage from "./routes/courses/CoursesNewPage";
import CoursesPage from "./routes/courses/CoursesPage";
import CoursesSortPage from "./routes/courses/CoursesSortPage";
import CustomerBillingSearchPage from "./routes/customers/CustomerBillingSearchPage";
import CustomerBillingSearchResultsPage from "./routes/customers/CustomerBillingSearchResultsPage";
import CustomerPage from "./routes/customers/CustomerPage";
import CustomerSearchPage from "./routes/customers/CustomerSearchPage";
import CustomerSearchResultsPage from "./routes/customers/CustomerSearchResultsPage";
import DiscountEditConfirmPage from "./routes/discounts/DiscountEditConfirmPage";
import DiscountEditPage from "./routes/discounts/DiscountEditPage";
import DiscountNewConfirmPage from "./routes/discounts/DiscountNewConfirmPage";
import DiscountNewPage from "./routes/discounts/DiscountNewPage";
import DiscountsPage from "./routes/discounts/DiscountsPage";
import ErrorPage from "./routes/error/ErrorPage";
import InternalServerErrorPage from "./routes/error/InternalServerErrorPage";
import NotFoundErrorPage from "./routes/error/NotFoundErrorPage";
import ServiceUnAuthorizedErrorPage from "./routes/error/ServiceUnAuthorizedErrorPage";
import UnAuthenticatedPage from "./routes/error/UnAuthenticatedErrorPage";
import ExpertEditConfirmPage from "./routes/experts/ExpertEditConfirmPage/";
import ExpertEditPage from "./routes/experts/ExpertEditPage/";
import ExpertNewConfirmPage from "./routes/experts/ExpertNewConfirmPage";
import ExpertNewPage from "./routes/experts/ExpertNewPage";
import ExpertPage from "./routes/experts/ExpertPage";
import ExpertsPage from "./routes/experts/ExpertsPage";
import FeaturedVideosEditConfirmPage from "./routes/featuredvideos/FeaturedVideosEditConfirmPage";
import FeaturedVideosEditPage from "./routes/featuredvideos/FeaturedVideosEditPage";
import FeaturedVideosPage from "./routes/featuredvideos/FeaturedVideosPage";
import Home from "./routes/Home";
import LessonParticipantsPage from "./routes/lessons/LessonParticipantsPage";
import LessonsPage from "./routes/lessons/LessonsPage";
import Login from "./routes/Login";
import MaintenancePage from "./routes/maintenance";
import MastersPage from "./routes/masters/MastersPage";
import ContBillPaymentMismatchesPage from "./routes/payments/ContBillPaymentMismatchesPage";
import ContBillPaymentMismatchPage from "./routes/payments/ContBillPaymentMismatchPage";
import PaymentMismatchesPage from "./routes/payments/PaymentMismatchesPage";
import PaymentMismatchPage from "./routes/payments/PaymentMismatchPage";
import PaymentPage from "./routes/payments/PaymentPage";
import PaymentsSearchPage from "./routes/payments/PaymentsSearchPage";
import PaymentsSearchResultsPage from "./routes/payments/PaymentsSearchResultsPage";
import ProfileConfirmPage from "./routes/profile/ProfileConfirmPage";
import ProfileEditPage from "./routes/profile/ProfileEditPage";
import ProfilePage from "./routes/profile/ProfilePage";
import PublishesPage from "./routes/publications/PublicationsPage";
import QuestionnairesPage from "./routes/questionnaires/QuestionnairesPage";
import UserPage from "./routes/users/UserPage";
import UsersConfirmPage from "./routes/users/UsersConfirmPage";
import UsersEditPage from "./routes/users/UsersEditPage";
import UsersExternalNewConfirmPage from "./routes/users/UsersExternalNewConfirmPage";
import UsersExternalNewPage from "./routes/users/UsersExternalNewPage";
import UsersPage from "./routes/users/UsersPage";
import VideoLessonEditPage from "./routes/videolessons/VideoLessonEditPage";
import VideoLessonNewPage from "./routes/videolessons/VideoLessonNewPage";
import VideoLessonPage from "./routes/videolessons/VideoLessonPage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route exact path="/error">
          <ErrorPage />
        </Route>
        <PrivateRoute exact path="/">
          <Layout>
            <Home />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/users/:id">
          <Layout>
            <UserPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/users/:id/edit">
          <Layout>
            <UsersEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/users/:id/confirm">
          <Layout>
            <UsersConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/users">
          <Layout>
            <UsersPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/user/external/new">
          <Layout>
            <UsersExternalNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/user/external/confirm">
          <Layout>
            <UsersExternalNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/profile">
          <Layout>
            <ProfilePage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/profile/edit">
          <Layout>
            <ProfileEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/profile/confirm">
          <Layout>
            <ProfileConfirmPage />
          </Layout>
        </PrivateRoute>
        {/* <PrivateRoute exact path="/content/new">
          <Layout>
            <ContentsNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/content/confirm">
          <Layout>
            <ContentsNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contents">
          <Layout>
            <ContentsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contents/:id">
          <Layout>
            <ContentPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contents/:id/edit">
          <Layout>
            <ContentsEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contents/:id/confirm">
          <Layout>
            <ContentsEditConfirmPage />
          </Layout>
        </PrivateRoute> */}
        <PrivateRoute exact path="/customers/:id">
          <Layout>
            <CustomerPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/customer/search/results">
          <Layout>
            <CustomerSearchResultsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/customer/search">
          <Layout>
            <CustomerSearchPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/customer/billing/search/results">
          <Layout>
            <CustomerBillingSearchResultsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/customer/billing/search">
          <Layout>
            <CustomerBillingSearchPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/masters">
          <Layout>
            <MastersPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/categories/sort">
          <Layout>
            <CategoriesSortPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/publications">
          <Layout>
            <PublishesPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/course/new">
          <Layout>
            <CoursesNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/course/confirm">
          <Layout>
            <CoursesNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses">
          <Layout>
            <CoursesPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/sort">
          <Layout>
            <CoursesSortPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:id">
          <Layout>
            <CoursePage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/lessons">
          <Layout>
            <LessonsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/lessons/:id">
          <Layout>
            <LessonParticipantsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:id/edit">
          <Layout>
            <CoursesEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:id/confirm">
          <Layout>
            <CoursesEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:id/class/new">
          <Layout>
            <ClassNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:id/class/confirm">
          <Layout>
            <ClassNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:courseId/classes/:classId">
          <Layout>
            <ClassPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:courseId/classes/:classId/edit">
          <Layout>
            <ClassEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/courses/:courseId/classes/:classId/confirm">
          <Layout>
            <ClassEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/search/results">
          <Layout>
            <PaymentsSearchResultsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/search">
          <Layout>
            <PaymentsSearchPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payments/:id">
          <Layout>
            <PaymentPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/mismatches">
          <Layout>
            <PaymentMismatchesPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/mismatches/:id">
          <Layout>
            <PaymentMismatchPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/contbill/mismatches">
          <Layout>
            <ContBillPaymentMismatchesPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/payment/contbill/mismatches/:id">
          <Layout>
            <ContBillPaymentMismatchPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts">
          <Layout>
            <ContactsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts/:id">
          <Layout>
            <ContactPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/contact/new">
          <Layout>
            <ContactsNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/questionnaires">
          <Layout>
            <QuestionnairesPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcements">
          <Layout>
            <AnnouncementsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcements/:id">
          <Layout>
            <AnnouncementPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcement/new">
          <Layout>
            <AnnouncementsNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcement/confirm">
          <Layout>
            <AnnouncementsNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcements/:id/edit">
          <Layout>
            <AnnouncementsEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/announcements/:id/confirm">
          <Layout>
            <AnnouncementsEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/discounts">
          <Layout>
            <DiscountsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/discount/new">
          <Layout>
            <DiscountNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/discount/confirm">
          <Layout>
            <DiscountNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/discounts/:id/edit">
          <Layout>
            <DiscountEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/discounts/:id/confirm">
          <Layout>
            <DiscountEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/featuredvideos">
          <Layout>
            <FeaturedVideosPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/featuredvideos/edit">
          <Layout>
            <FeaturedVideosEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/featuredvideos/edit/confirm">
          <Layout>
            <FeaturedVideosEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts">
          <Layout>
            <ExpertsPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/:id/edit">
          <Layout>
            <ExpertEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/:id/confirm">
          <Layout>
            <ExpertEditConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/new">
          <Layout>
            <ExpertNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/confirm">
          <Layout>
            <ExpertNewConfirmPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/:id">
          <Layout>
            <ExpertPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/experts/:id/videolessons/new">
          <Layout>
            <VideoLessonNewPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/experts/:expertId/videolessons/:videoLessonId/edit"
        >
          <Layout>
            <VideoLessonEditPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/experts/:expertId/videolessons/:videoLessonId"
        >
          <Layout>
            <VideoLessonPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/corporation">
          <Layout>
            <CorporationPage />
          </Layout>
        </PrivateRoute>
        <Route exact path="/maintenance">
          <MaintenancePage />
        </Route>
        <PrivateRoute exact path="/error/notfound">
          <Layout>
            <NotFoundErrorPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/error/internalservererror">
          <Layout>
            <InternalServerErrorPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/error/unauthenticated">
          <Layout>
            <UnAuthenticatedPage />
          </Layout>
        </PrivateRoute>
        <PrivateRoute exact path="/error/serviceunauthorized">
          <ServiceUnAuthorizedErrorPage />
        </PrivateRoute>
        <Route path="*">
          <Layout>
            <NotFoundErrorPage />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
