import React, {
  useState,
  useCallback,
  MouseEventHandler,
  useEffect,
} from "react";

import * as History from "history";
import { Prompt, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { LoadingUI } from "../../../components/Loading";
import {
  UpdateClassAndLessonsInput,
  useUpdateClassAndLessonsMutation,
} from "../../../generated/graphql";
import { DisplayCourse, DisplayClass } from "../../../lib/constants/classes";
import { ErrorType } from "../../../lib/constants/error";
import ClassEditConfirmPage from "./ClassEditConfirmPage";

const ClassEditConfirmPageContainer = () => {
  const history = useHistory();
  const [updateClassAndLessons] = useUpdateClassAndLessonsMutation();

  const location = useLocation<{
    course: DisplayCourse;
    clazzInput: DisplayClass;
  }>();
  const { course } = location.state;
  const { clazzInput } = location.state;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  const [confirmModalVisibility, setConfirmModalVisibility] =
    useState<boolean>(false);
  const [confirmPublicModalVisibility, setConfirmPublicModalVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    return () => {
      isOpenCompleteModal && setIsOpenCompleteModal(false);
    };
  }, [isOpenCompleteModal]);

  const onPublicConfirm = useCallback(async () => {
    setConfirmPublicModalVisibility(false);
    try {
      if (clazzInput.id) {
        const input: UpdateClassAndLessonsInput = {
          id: clazzInput.id,
          name: clazzInput.name,
          anyClassId: clazzInput.anyClassId,
          courseId: clazzInput.courseId,
          eventLocation: clazzInput.eventLocation,
          capacity: Number(clazzInput.capacity),
          applicantCountVisibility: clazzInput.applicantCountVisibility,
          lessons: clazzInput.lessons.map((lesson) => {
            if (lesson?.id) {
              return {
                id: lesson.id,
                teacherIds: lesson.teachers.map((teacher) => teacher.id),
                anyLessonId: lesson.anyLessonId,
                lessonMasterId: lesson.lessonMasterId,
                startedAt: lesson.startedAt,
                endedAt: lesson.endedAt,
                locationType: lesson.locationType,
                lessonUrl: lesson.lessonUrl,
              };
            } else {
              throw new Error();
            }
          }),
        };
        if (!isLoading) {
          setIsLoading(true);
          await updateClassAndLessons({
            variables: {
              input,
            },
          });
          setIsOpenCompleteModal(true);
        }
      } else {
        throw new Error();
      }
    } catch (error: any) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      }
      setConfirmErrorModalVisibility(true);
    } finally {
      setIsLoading(false);
    }
  }, [history, clazzInput, updateClassAndLessons, setIsLoading, isLoading]);

  // 公開設定がTrueの場合、警告モーダルを表示する
  const onConfirm = useCallback(async () => {
    if (clazzInput.published) {
      setConfirmPublicModalVisibility(true);
    } else {
      onPublicConfirm();
    }
  }, [clazzInput.published, onPublicConfirm]);

  const handleBack: MouseEventHandler<HTMLButtonElement> = () => {
    history.replace(
      `/courses/${clazzInput.courseId}/classes/${clazzInput.id}/edit`
    );
  };

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    history.replace("/courses");
  };
  const clearErrorModal = () => {
    confirmErrorModalVisibility && setConfirmErrorModalVisibility(false);
  };

  const confirmBack = useCallback(() => {
    setConfirmModalVisibility(true);
  }, [setConfirmModalVisibility]);

  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (
        location.pathname ===
          `/courses/${clazzInput.courseId}/classes/${clazzInput.id}/edit` &&
        action === "POP"
      ) {
        setConfirmModalVisibility(true);
        return false;
      }
      return true;
    },
    [clazzInput, setConfirmModalVisibility]
  );

  return (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <ClassEditConfirmPage
        isLoading={isLoading}
        course={course}
        clazz={clazzInput}
        onConfirm={onConfirm}
        handleBack={confirmBack}
      />
      <CompletedModalUI
        title="編集完了"
        description="編集が完了しました。"
        btnTitle="コース一覧へ"
        onClick={clearCompletedModal}
        visibility={isOpenCompleteModal}
      />
      <ConfirmErrorModalUI
        title="登録に失敗しました"
        description={`クラスの登録に失敗しました。
        しばらく時間を置いてから、もう一度お試しください。`}
        btnTitle="閉じる"
        onClick={clearErrorModal}
        visibility={confirmErrorModalVisibility}
      />
      <ConfirmModalUI
        title="確認"
        description={`編集中の内容は破棄されます。
  よろしいですか？`}
        onCancel={() => {
          setConfirmModalVisibility(false);
        }}
        onSubmit={handleBack}
        visibility={confirmModalVisibility}
      />
      <ConfirmModalUI
        title="確認"
        description={`お客様画面で既に公開されているため、<br />変更が即時で反映されますが、よろしいでしょうか？`}
        onCancel={() => {
          setConfirmPublicModalVisibility(false);
        }}
        onSubmit={onPublicConfirm}
        visibility={confirmPublicModalVisibility}
      />
      <Prompt when={true} message={handleBlockBack} />
    </>
  );
};

export default ClassEditConfirmPageContainer;
