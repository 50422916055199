import React, { HTMLAttributes } from "react";
export type LabelUIProps = { textColor?: string } & HTMLAttributes<HTMLElement>;

export const LabelUI: React.FC<LabelUIProps> = ({
  className,
  children,
  textColor = "text-subtitle",
  ...rest
}) => {
  return (
    <p
      className={`${textColor} text-lg tracking-wider leading-none ${className}`}
      {...rest}
    >
      {children}
    </p>
  );
};
