import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { LabelUI } from "../../../components/Label";
import { ContBillPaymentMismatchQuery } from "../../../generated/graphql";
import { ContBillMplStatusTableUI } from "./components/ContBillMplStatusTable";
import { ContBillPaymentMismatchHeaderUI } from "./components/ContBillPaymentMismatchHeader";
import { ContBillPaymentTableUI } from "./components/ContBillPaymentTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "決済管理",
  },
  {
    label: "決済不整合",
  },
  {
    label: "決済不整合詳細(継続課金)",
  },
];

export type ContBillPaymentMismatchPageProps = {
  data: ContBillPaymentMismatchQuery;
};

const ContBillPaymentMismatchPage: React.VFC<
  ContBillPaymentMismatchPageProps
> = ({ data }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const [, setTableWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
    return () => {
      // unmount
    };
  }, [setBreadcrumbItems]);

  const paymentTransaction = data.paymentMismatch.paymentTransaction;
  return (
    <div>
      <h1 className="text-2xl mb-3">決済情報不整合詳細(継続課金)</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-5 mb-36">
        <ContBillPaymentMismatchHeaderUI data={data.paymentMismatch} />
        <div className="mb-8">
          <LabelUI className="mt-9 mb-3">かんたん決済の決済情報</LabelUI>
          {data.paymentMismatch.paymentMismatchCsvs.length > 0 ? (
            data.paymentMismatch.paymentMismatchCsvs.map((csv) => (
              <ContBillMplStatusTableUI key={csv.id} mplStatus={csv} />
            ))
          ) : (
            <div className="bg-light-blue flex h-24">
              <div className="m-auto">
                <p className="text-lg" data-testid="payment-mismatch-no-csvs">
                  かんたん決済に決済情報が存在しません。
                </p>
              </div>
            </div>
          )}
        </div>
        <LabelUI className="mb-3">教育PFの決済情報</LabelUI>
        {paymentTransaction ? (
          <div className="overflow-x-auto">
            <ContBillPaymentTableUI
              paymentTransaction={paymentTransaction}
              onWidthChange={(w) => setTableWidth(w)}
            />
          </div>
        ) : (
          <div className="bg-light-blue flex h-24">
            <div className="m-auto">
              <p className="text-lg" data-testid="payment-mismatch-no-payment">
                教育PFに決済情報が存在しません。
              </p>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <ButtonUI
            data-testid="payment-goback"
            buttonType="primary"
            size="s"
            className="mt-20"
            onClick={history.goBack}
          >
            戻る
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default ContBillPaymentMismatchPage;
