import React from "react";

import { CSVLink } from "react-csv";

export type CsvLinkProps = {
  data: CSVLink["props"]["data"];
  headers: CSVLink["props"]["headers"];
  filename: CSVLink["props"]["filename"];
};

/**
 * @see CSVLink (react-csv)
 * ダウンロード用の型
 */
export type DownloadCsvProps = {
  csvLinkProps: CsvLinkProps | undefined;
};

/**
 * CSVダウンロード用。
 * csvLinkPropsの値 undefined ⇄ CsvLinkPropsを検知しaタグのダウンロードを発火させる。
 * 画面上ではhiddenでの描画のみ。
 */
const DownloadCsv: React.VFC<DownloadCsvProps> = ({ csvLinkProps }) => {
  const csvLinkRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  /**
   * デフォルトundefinedとし、ダウンロードする間のみデータを格納する
   */
  const [csvRefParam, setCsvRefParam] = React.useState<
    CsvLinkProps | undefined
  >();

  /**
   * 親から受け取ったCSVデータをセットする
   */
  React.useEffect(() => {
    if (!csvLinkProps) {
      return;
    }

    setCsvRefParam(csvLinkProps);
  }, [csvLinkProps]);

  /**
   * CSVをダウンロード
   */
  React.useEffect(() => {
    const validate = !!(csvRefParam && csvLinkRef.current?.link);
    if (!validate) {
      return;
    }

    csvLinkRef.current.link.click();

    // CSVダウンロード後はaタグ用データはクリア
    setCsvRefParam(undefined);
  }, [csvRefParam]);

  return (
    <>
      {csvRefParam && (
        <CSVLink ref={csvLinkRef} {...csvRefParam} className="hidden" />
      )}
    </>
  );
};

export default DownloadCsv;
