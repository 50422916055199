import React, { ChangeEventHandler, useCallback, useState } from "react";

import dayjs from "dayjs";

import { range } from "../../../../../lib/functions";
import { ModifyDateType } from "../../../../../lib/types/class";
import { ReactComponent as CalendarIcon } from "./calendar.svg";

export type PublishDateFormUIProps = {
  clazz: UpdateDateAndStatus;
  updateClazz: (clazz: UpdateDateAndStatus) => void;
  isSaleDatesError: boolean;
};

const getDateInputRange = (dateType: ModifyDateType): number[] => {
  // monthとdateは0はあり得ないが、入力中は0も許容しておかないと使い勝手が悪くなるため配列に含めている
  switch (dateType) {
    case "year":
      return range(0, 9999);
    case "month":
      return range(0, 12);
    case "date":
      return range(0, 31);
    case "hour":
      return range(0, 23);
    case "minute":
      return range(0, 59);
  }
};

export const PublishDateFormUI: React.FC<PublishDateFormUIProps> = ({
  clazz,
  updateClazz,
  isSaleDatesError,
}) => {
  const saleStartedAt = dayjs(clazz.saleStartedAt);
  const saleEndedAt = dayjs(clazz.saleEndedAt);
  const [editingStartDate, setEditingStartDate] = useState({
    year: saleStartedAt.year(),
    month: saleStartedAt.month() + 1,
    date: saleStartedAt.date(),
    hour: saleStartedAt.hour(),
    minute: saleStartedAt.minute(),
  });
  const [editingEndDate, setEditingEndDate] = useState({
    year: saleEndedAt.year(),
    month: saleEndedAt.month() + 1,
    date: saleEndedAt.date(),
    hour: saleEndedAt.hour(),
    minute: saleEndedAt.minute(),
  });

  const convertDate = useCallback(
    (type: ModifyDateType, fromOrTo: "from" | "to"): string => {
      const value = fromOrTo === "from" ? editingStartDate : editingEndDate;
      return `${value[type]}`.padStart(2, "0");
    },
    [editingStartDate, editingEndDate]
  );

  const getDate = (fromOrTo: "from" | "to"): Date => {
    const date = fromOrTo === "from" ? editingStartDate : editingEndDate;
    return dayjs()
      .year(date.year)
      .month(date.month - 1)
      .date(date.date)
      .hour(date.hour)
      .minute(date.minute)
      .second(0)
      .toDate();
  };

  const onChangeDateInputs: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const keys = event.target.id.split("-");
      const dateType = keys[1] as ModifyDateType;
      const valueNum = Number(event.target.value);

      if (!getDateInputRange(dateType).includes(valueNum)) return;

      if (keys[0] === "from") {
        setEditingStartDate((prev) => ({
          ...prev,
          [dateType]: valueNum,
        }));
      } else {
        setEditingEndDate((prev) => ({
          ...prev,
          [dateType]: valueNum,
        }));
      }
    },
    []
  );

  const onBlur = () => {
    // monthとdateは入力欄からfocusが外れるまでは0を許容するためここでチェックしている
    setEditingStartDate((prev) => ({
      ...prev,
      month: prev.month === 0 ? 1 : prev.month,
      date: prev.date === 0 ? 1 : prev.date,
    }));
    setEditingEndDate((prev) => ({
      ...prev,
      month: prev.month === 0 ? 1 : prev.month,
      date: prev.date === 0 ? 1 : prev.date,
    }));
    updateClazz({
      ...clazz,
      saleStartedAt: getDate("from"),
      saleEndedAt: getDate("to"),
    });
  };

  return (
    <>
      <div className="flex ml-12 mt-5">
        <CalendarIcon className="mt-1.5 mr-2.5" />
        <div className="flex flex-col">
          <div className="flex flex-row items-center space-x-2.5">
            <input
              id={`from-year-${clazz?.id}`}
              type="number"
              data-testid={`publish-date-form-year-${clazz?.id}`}
              placeholder="YYYY"
              min="1"
              value={convertDate("year", "from")}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-20`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
            />
            <div>/</div>
            <input
              type="number"
              placeholder="MM"
              data-testid={`publish-date-form-month-${clazz?.id}`}
              min="1"
              max="12"
              id={`from-month-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("month", "from")}
            />
            <div>/</div>
            <input
              type="number"
              data-testid={`publish-date-form-date-${clazz?.id}`}
              placeholder="DD"
              min="1"
              max={dayjs(
                new Date(editingStartDate.year, editingStartDate.month - 1)
              ).daysInMonth()}
              id={`from-date-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("date", "from")}
            />
            <input
              type="number"
              data-testid={`publish-date-form-from-hour-${clazz?.id}`}
              placeholder="HH"
              min="0"
              max="23"
              id={`from-hour-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("hour", "from")}
            />
            <div>:</div>
            <input
              type="number"
              data-testid={`publish-date-form-from-minute-${clazz?.id}`}
              placeholder="MM"
              min="0"
              max="59"
              id={`from-minute-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("minute", "from")}
            />
            <div>〜</div>
          </div>
        </div>
      </div>

      <div className="flex ml-12 mt-4">
        <CalendarIcon className="mt-1.5 mr-2.5" />
        <div className="flex flex-col">
          <div className="flex flex-row items-center space-x-2.5">
            <input
              id={`to-year-${clazz?.id}`}
              type="number"
              data-testid={`publish-date-form-year-${clazz?.id}`}
              placeholder="YYYY"
              min="1"
              value={convertDate("year", "to")}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-20`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
            />
            <div>/</div>
            <input
              type="number"
              placeholder="MM"
              data-testid={`publish-date-to-month-${clazz?.id}`}
              min="1"
              max="12"
              id={`to-month-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("month", "to")}
            />
            <div>/</div>
            <input
              type="number"
              data-testid={`publish-date-to-date-${clazz?.id}`}
              placeholder="DD"
              min="1"
              max={dayjs(
                new Date(editingEndDate.year, editingEndDate.month - 1)
              ).daysInMonth()}
              id={`to-date-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("date", "to")}
            />
            <input
              type="number"
              data-testid={`publish-date-form-from-hour-${clazz?.id}`}
              placeholder="HH"
              min="0"
              max="23"
              id={`to-hour-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("hour", "to")}
            />
            <div>:</div>
            <input
              type="number"
              data-testid={`publish-date-form-from-minute-${clazz?.id}`}
              placeholder="MM"
              min="0"
              max="59"
              id={`to-minute-${clazz?.id}`}
              className={`h-9 leading-5 pl-4 tracking-wider border rounded w-14`}
              onChange={onChangeDateInputs}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              onBlur={onBlur}
              value={convertDate("minute", "to")}
            />
          </div>
        </div>
      </div>
      {isSaleDatesError && (
        <p className="text-error mt-1.5 ml-10" data-testid={`publish-message`}>
          正しい販売期間を設定してください
        </p>
      )}
    </>
  );
};
