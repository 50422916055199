import React from "react";

import { LoadingUI } from "../../../components/Loading";
import { useFeaturedVideosEditPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import FeaturedVideosEditPage from "./FeaturedVideosEditPage";

const FeaturedVideosEditPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const { data, loading } = useFeaturedVideosEditPageQuery({
    onError: errorRouter,
  });

  if (loading || !data) {
    return <LoadingUI />;
  }

  return <FeaturedVideosEditPage data={data} />;
};

export default FeaturedVideosEditPageContainer;
