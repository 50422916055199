import React from "react";

import { useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../components/Badge";
import { ButtonUI } from "../../../../components/Button";
import { DateTimeInputUI } from "../../../../components/DateTimeInput";
import NewFormGroupUI from "../../../../components/FormGroup/NewFormGroup";
import NewImageInputUI from "../../../../components/NewImageInput";
import { NewValidatableInputUI } from "../../../../components/ValidatableInput";
import { NewValidatableTextareaUI } from "../../../../components/ValidatableTextarea";
import { CreateExpertInput } from "../../../../generated/graphql";
import {
  getValidationKana,
  getValidationUrl,
  validatePublishEndedAt,
} from "../../../../validation/regularExpressions";

type ExpertEditPageUIProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
};

export type ExpertEditFormState = {
  profilePhoto?: File | string;
  headshot?: File | string;
} & CreateExpertInput;

export const ExpertEditUI: React.VFC<ExpertEditPageUIProps> = ({
  onGoBack,
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<ExpertEditFormState>();

  const profilePhoto = getValues("profilePhoto");
  const headshot = getValues("headshot");
  return (
    <div>
      <div className="divide-y divide-light-blue">
        <NewFormGroupUI label="有識者名" required={true}>
          <div className="flex flex-row space-x-4 w-11/12">
            <NewValidatableInputUI
              data-testid="expert-edit-family-name"
              {...register("familyName", {
                required: "姓は必須項目です。",
                maxLength: {
                  value: 20,
                  message: "20文字以内で入力してください。",
                },
              })}
              error={errors.familyName}
              className="w-full"
              placeholder="au"
              id="familyName"
            />
            <NewValidatableInputUI
              data-testid="expert-edit-given-name"
              {...register("givenName", {
                required: "名は必須項目です。",
                maxLength: {
                  value: 20,
                  message: "20文字以内で入力してください。",
                },
              })}
              error={errors.givenName}
              className="w-full"
              placeholder="太郎"
              id="givenName"
            />
          </div>
        </NewFormGroupUI>
        <NewFormGroupUI label="名前(フリガナ)" required={true}>
          <div className="flex flex-row space-x-4 w-11/12">
            <NewValidatableInputUI
              data-testid="expert-edit-family-name-kana"
              {...register("familyNameKana", {
                required: "有識者名(セイ)は必須項目です。",
                pattern: {
                  value: getValidationKana(),
                  message: "カタカナ20文字以内で入力してください。",
                },
              })}
              error={errors.familyNameKana}
              className="w-full"
              placeholder="エーユー"
              id="familyNameKana"
            />
            <NewValidatableInputUI
              data-testid="expert-edit-given-name-kana"
              {...register("givenNameKana", {
                required: "有識者名(メイ)は必須項目です。",
                pattern: {
                  value: getValidationKana(),
                  message: "カタカナ20文字以内で入力してください。",
                },
              })}
              error={errors.givenNameKana}
              className="w-full"
              placeholder="タロウ"
              id="givenNameKana"
            />
          </div>
        </NewFormGroupUI>
        <NewFormGroupUI label="URL表記" htmlFor="alias" required={true}>
          <NewValidatableInputUI
            data-testid="expert-edit-alias"
            {...register("alias", {
              required: "URL表記は必須項目です。",
              pattern: {
                value: getValidationUrl(),
                message: "40文字以内の半角英数字,「_」で入力してください。",
              },
            })}
            error={errors.alias}
            className="w-full"
            placeholder="URL表記を入力してください"
            id="alias"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="所属" htmlFor="company">
          <NewValidatableInputUI
            data-testid="expert-edit-company"
            {...register("company", {
              maxLength: {
                value: 40,
                message: "40文字以内で入力してください。",
              },
            })}
            error={errors.company}
            className="w-full"
            placeholder="所属を入力してください"
            id="company"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="役職" htmlFor="position">
          <NewValidatableInputUI
            data-testid="expert-edit-position"
            {...register("position", {
              maxLength: {
                value: 40,
                message: "40文字以内で入力してください。",
              },
            })}
            error={errors.position}
            className="w-full"
            placeholder="役職を入力してください"
            id="position"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="肩書き" htmlFor="title" required={true}>
          <NewValidatableInputUI
            data-testid="expert-edit-title"
            {...register("title", {
              required: "肩書きは必須項目です。",
              maxLength: {
                value: 40,
                message: "40文字以内で入力してください。",
              },
            })}
            error={errors.title}
            className="w-full"
            placeholder="肩書きを入力してください"
            id="title"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="プロフィール" htmlFor="career">
          <NewValidatableTextareaUI
            data-testid="expert-edit-career"
            {...register("career", {
              maxLength: {
                value: 300,
                message: "300文字以内で入力してください。",
              },
            })}
            error={errors.career}
            className="w-full"
            placeholder="プロフィールを300文字以内で入力してください"
            id="career"
            rows={6}
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="著作・共著" htmlFor="booksListText">
          <div className="mt-2.5 mb-3 pl-2.5 text-base text-dark-gray">
            著作が複数ある場合は改行区切りで入力してください。
            <br />
            著作・共著を全体で10000文字以内で入力してください。
            <br />
            例）『書籍名』出版社名　出版年　共著：共著者名
          </div>
          <NewValidatableTextareaUI
            data-testid="expert-edit-books-list-text"
            {...register("booksListText", {
              maxLength: {
                value: 10000,
                message: "10000文字以内で入力してください。",
              },
            })}
            error={errors.booksListText}
            className="w-full"
            id="booksListText"
            rows={6}
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="関連リンク　公式サイトURL" htmlFor="siteUrl">
          <NewValidatableInputUI
            data-testid="expert-edit-site-url"
            {...register("siteUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.siteUrl}
            className="w-full"
            placeholder="公式サイトURLを入力してください"
            id="siteUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="関連リンク　Twitter URL" htmlFor="twitterUrl">
          <NewValidatableInputUI
            data-testid="expert-edit-twitter-url"
            {...register("twitterUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.twitterUrl}
            className="w-full"
            placeholder="Twitter URLを入力してください"
            id="twitterUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="関連リンク　Facebook URL" htmlFor="facebookUrl">
          <NewValidatableInputUI
            data-testid="expert-edit-facebook-url"
            {...register("facebookUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.facebookUrl}
            className="w-full"
            placeholder="Facebook URLを入力してください"
            id="facebookUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI
          label="関連リンク　Instagram URL"
          htmlFor="instagramUrl"
        >
          <NewValidatableInputUI
            data-testid="expert-edit-instagram-url"
            {...register("instagramUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.instagramUrl}
            className="w-full"
            placeholder="Instagram URLを入力してください"
            id="instagramUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="関連リンク　Youtube URL" htmlFor="youtubeUrl">
          <NewValidatableInputUI
            data-testid="expert-edit-youtube-url"
            {...register("youtubeUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.youtubeUrl}
            className="w-full"
            placeholder="Youtube URLを入力してください"
            id="youtubeUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI
          label="関連リンク　Wikipedia URL"
          htmlFor="wikipediaUrl"
        >
          <NewValidatableInputUI
            data-testid="expert-edit-wikipedia-url"
            {...register("wikipediaUrl", {
              maxLength: {
                value: 5000,
                message: "5000文字以内で入力してください。",
              },
            })}
            error={errors.wikipediaUrl}
            className="w-full"
            placeholder="Wikipedia URLを入力してください"
            id="wikipediaUrl"
          />
        </NewFormGroupUI>
        <NewFormGroupUI label="プロフィール画像" htmlFor="profilePhoto">
          <div>
            <div className="flex flex-row">
              <NewImageInputUI
                testidPrefix="expert-edit-profile-photo"
                className="w-44 h-32"
                {...register("profilePhoto")}
                imageConditions={{
                  size: 10 * 1000 * 1000,
                  px: { width: 532, height: 357 },
                }}
                error={!!errors.profilePhoto}
                image={profilePhoto}
              />
              <p className="mt-4 ml-4 text-xs text-dark-gray">
                アップロード可能なファイルサイズは最大10MBです
                <br /> 532px X 357pxの画像を指定してください
              </p>
            </div>
            {errors.profilePhoto && (
              <p
                data-testid="expert-edit-profile-photo-error-message"
                className="mt-2.5 text-error"
              >
                {errors.profilePhoto.message}
              </p>
            )}
          </div>
        </NewFormGroupUI>
        <NewFormGroupUI label="アイコン画像" htmlFor="headshot">
          <div>
            <div className="flex flex-row">
              <NewImageInputUI
                testidPrefix="expert-edit-headshot"
                className="w-36 h-36"
                rounded={true}
                imageConditions={{
                  size: 10 * 1000 * 1000,
                  px: { width: 166, height: 166 },
                }}
                {...register("headshot")}
                error={!!errors.headshot}
                image={headshot}
              />
              <p className="mt-4 ml-4 text-xs text-dark-gray">
                アップロード可能なファイルサイズは最大10MBです
                <br /> 166px X 166pxの画像を指定してください
              </p>
            </div>
            {errors.headshot && (
              <p
                data-testid="expert-edit-headshot-error-message"
                className="mt-2.5 text-error"
              >
                {errors.headshot.message}
              </p>
            )}
          </div>
        </NewFormGroupUI>
        <NewFormGroupUI label="公開設定" required={true}>
          <div>
            <div className="flex flex-row items-center">
              <BadgeUI className="mr-4">必須</BadgeUI>
              <DateTimeInputUI
                testidPrefix="expert-edit-published-at"
                icon={true}
                {...register("publishedAt", {
                  required: "公開開始日時は必須項目です。",
                })}
                error={!!errors.publishedAt}
              />
              <div className="ml-4">〜</div>
            </div>
            {errors.publishedAt?.message && (
              <p
                data-testid="expert-edit-published-at-error-message"
                className="ml-20 mt-2.5 text-sm text-error"
              >
                {errors.publishedAt.message}
              </p>
            )}
          </div>

          <div className="mt-3">
            <div className="flex flex-row items-center">
              <BadgeUI type="optional" className="mr-4">
                任意
              </BadgeUI>
              <DateTimeInputUI
                testidPrefix="expert-edit-closed-at"
                icon={true}
                {...register("closedAt", {
                  validate: (value) =>
                    validatePublishEndedAt(getValues("publishedAt"), value),
                })}
                error={!!errors.closedAt}
              />
            </div>
            {errors.closedAt?.message && (
              <p
                data-testid="expert-edit-closed-at-error-message"
                className="ml-20 mt-2.5 text-sm text-error"
              >
                {errors.closedAt.message}
              </p>
            )}
          </div>
        </NewFormGroupUI>
      </div>
      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI
          type="button"
          data-testid="expert-edit-back"
          buttonType="secondary"
          onClick={onGoBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI type="submit" data-testid="expert-edit-submit">
          確認
        </ButtonUI>
      </div>
    </div>
  );
};
