import React, { HTMLAttributes, useMemo } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { CsvDownloadButtonUI } from "../../../components/CsvDownloadButton";
import StatusBlock from "../../../components/StatusBlock";
import { UploadResult } from "../../../generated/graphql";

dayjs.locale(ja);
dayjs.extend(utc);
dayjs.extend(timezone);

export type CorporationRowProps = {
  index: number;
  className?: string;
  status: UploadResult;
  fileName: string;
  uploadDate: Date;
  totalCount: number;
  errorCount: number;
  csvUrl?: string;
  onCsvDownload: (url: string, fileName: string) => void;
} & HTMLAttributes<HTMLDivElement>;

const getStatusText = (
  uploadResult: UploadResult,
  errorCount: number,
  totalCount: number
): string => {
  switch (uploadResult) {
    case "processing":
      return "処理中";
    case "failed":
      return "失敗";
    case "particalSuccess":
      if (errorCount === totalCount) {
        return "失敗";
      }
      return "一部成功";
    case "success":
      return "成功";
    default:
      throw new Error(`invalid status. status: ${uploadResult}`);
  }
};

const getStatusColor = (
  uploadResult: UploadResult,
  errorCount: number
): string | undefined => {
  // 処理中
  if (uploadResult === UploadResult.Processing) {
    return "text-light-orange bg-white border-light-orange";
  }
  const isValid = uploadResult === UploadResult.Success && errorCount === 0;
  if (isValid) {
    return "bg-white text-blue-navy border-blue-navy";
  }

  return undefined;
};

const getCsvResult = (
  errorCount: number,
  totalCount: number,
  uploadResult: UploadResult
): string => {
  if (uploadResult === UploadResult.Failed) {
    return "アップロード失敗";
  }

  const base = `${totalCount}件`;
  if (errorCount < 1) {
    return base;
  }

  return `${base}（エラー${errorCount}件）`;
};

const formatDate = (date: Date) => {
  return dayjs(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
};

const CsvButton: React.FC<{
  onCsvDownload: (url: string, fileName: string) => void;
  fileName: string;
  csvUrl?: string;
  uploadResult: UploadResult;
}> = ({ csvUrl, onCsvDownload, fileName, uploadResult }) => {
  const isValid =
    uploadResult !== UploadResult.Failed && csvUrl && csvUrl.length > 0;
  if (!isValid) {
    return <></>;
  }
  return (
    <CsvDownloadButtonUI
      buttonTitle="CSVダウンロード"
      onClick={(evnet) => {
        evnet.preventDefault();
        onCsvDownload(csvUrl, fileName);
      }}
    />
  );
};

const CorporationRowUI: React.FC<CorporationRowProps> = ({
  index,
  className,
  status,
  fileName,
  uploadDate,
  totalCount,
  errorCount,
  csvUrl,
  onCsvDownload,
  ...rest
}) => {
  const isError = useMemo<boolean>(() => {
    return status === UploadResult.Failed;
  }, [status]);

  const isResultError = useMemo<boolean>(() => {
    return (
      status === UploadResult.ParticalSuccess || status === UploadResult.Failed
    );
  }, [status]);

  const statusForBlock = useMemo<UploadResult>(() => {
    switch (status) {
      case UploadResult.Success:
        return UploadResult.Success;
      case UploadResult.ParticalSuccess:
        if (errorCount === totalCount) {
          return UploadResult.Failed;
        }
        return UploadResult.ParticalSuccess;
      case UploadResult.Failed:
      default:
        return UploadResult.Failed;
    }
  }, [errorCount, status, totalCount]);
  return (
    <div
      className={`grid h-16 border-b border-dark-gray ${
        index % 2 !== 0 ? "bg-light-blue" : ""
      } ${className ?? ""}`}
      {...rest}
    >
      <span className="flex items-center pl-2 pr-1 divide-x-2">
        <StatusBlock
          status={statusForBlock}
          className={`h-6 w-16`}
          customColor={getStatusColor(status, errorCount)}
        >
          {getStatusText(status, errorCount, totalCount)}
        </StatusBlock>
      </span>
      <span
        className={`flex items-center pl-2 pr-1 divide-x-2  ${
          isError ? "text-error" : ""
        }`}
      >
        {fileName}
      </span>
      <span
        className={`flex items-center pl-2 pr-1 text-right divide-x-2 ${
          isError ? "text-error" : ""
        }`}
      >
        {formatDate(uploadDate)}
      </span>
      <span
        className={`flex items-center pl-2 pr-1 divide-x-2 ${
          isResultError ? "text-error" : ""
        }`}
      >
        {getCsvResult(errorCount, totalCount, status)}
      </span>
      <span className="flex items-center justify-center">
        <CsvButton
          csvUrl={csvUrl}
          onCsvDownload={onCsvDownload}
          fileName={fileName}
          uploadResult={status}
        />
      </span>
    </div>
  );
};

/**
 * UT用
 */
export const _test = {
  getStatusText,
  getStatusColor,
  getCsvResult,
};

export default CorporationRowUI;
