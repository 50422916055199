import { useEffect } from "react";

import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth } from "aws-amplify";

import awsmobile from "../aws-exports";
import { useQueryParams } from "../hooks/query";

// COGNITOログインを許可しない環境のブラックリスト※cognitoのユーザプールIDで判定
const COGNITO_BLACKLIST = [
  "ap-northeast-1_q5ro1ZnsT", // studypf-b2b-api-cognito-userpool-stg
  "ap-northeast-1_kL4H1JcZx", // studypf-b2b-api-cognito-userpool-prd
];

const LoginPage: React.VFC = () => {
  const params = useQueryParams();
  const provider = params.get("provider") || "KBI";
  useEffect(() => {
    const awsUserPoolsId = awsmobile.aws_user_pools_id || "";
    if (COGNITO_BLACKLIST.includes(awsUserPoolsId)) {
      // ブラックリストリストにある場合はKBI固定
      Auth.federatedSignIn({ customProvider: "KBI" });
    } else {
      // ブラックリストリストにない場合はこれまで通りの処理
      isProvider(provider)
        ? Auth.federatedSignIn({ provider })
        : Auth.federatedSignIn({ customProvider: provider });
    }
  }, [provider]);
  return <></>;
};

const isProvider = (
  provider: string
): provider is CognitoHostedUIIdentityProvider => {
  return Object.values(CognitoHostedUIIdentityProvider).includes(
    provider as any
  );
};

export default LoginPage;
