import React, { useEffect } from "react";

import { useBreadcrumb, Breadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { TableUI, TdUI, TrUI } from "../../../components/TableUI";
import { FeaturedVideosPageQuery } from "../../../generated/graphql";
import { LIBERAL_ARTS_URL } from "../const";
import styles from "./FeaturedVideosPage.module.css";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "注目の動画管理",
  },
];

export type FeaturedVideosPageProps = {
  data: FeaturedVideosPageQuery;
};

const FeaturedVideosPage: React.VFC<FeaturedVideosPageProps> = ({ data }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">注目の動画管理</h1>
      <hr className="border-gray border mb-2" />
      <p className="mt-4">
        社会人向けサービス「トップ画面」「有識者詳細画面」に表示される「注目の動画」セクションの内容を差し替えられます。
      </p>
      <div className="mt-10 flex flex-row justify-between items-end">
        <h2 className="text-xl">公開中の注目の動画</h2>
        <ButtonUI className="mr-5" size="xs" to="/featuredvideos/edit">
          差し替え
        </ButtonUI>
      </div>

      <TableUI
        className="table-fixed mt-6 text-left"
        headers={["カード画像", "遷移先URL", "イベントアクション名"]}
        colClassNames={["w-32", "", ""]}
      >
        {data.featuredVideos.map((featuredVideo) => (
          <TrUI
            className="border-none"
            data-testid="featured-video-row"
            key={featuredVideo.id}
          >
            <TdUI className="pl-5">
              <img
                className={styles.featuredVideoImg}
                src={featuredVideo.thumbnailUrl}
                alt={featuredVideo.name}
              />
            </TdUI>
            <TdUI className="pl-5 break-all">
              {LIBERAL_ARTS_URL}
              {featuredVideo.path}
            </TdUI>
            <TdUI className="pl-5 break-all">{featuredVideo.name}</TdUI>
          </TrUI>
        ))}
      </TableUI>
    </div>
  );
};

export default FeaturedVideosPage;
