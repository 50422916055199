import React, { useState, useEffect, useCallback } from "react";

import { useParams, useHistory } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import {
  MismatchHandlingHistoryFragment,
  PaymentMismatchCsvFragment,
  PaymentMismatchDetailFragment,
  PaymentMismatchType,
  useCreateMismatchHandlingHistoryMutation,
  useMismatchHandlingHistoriesQuery,
  usePaymentMismatchCsvQuery,
  usePaymentMismatchQuery,
  useProfileQuery,
  UserDetailFragment,
  useUpdatePaymentMismatchMutation,
} from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import PaymentMismatchPage from "./PaymentMismatchPage";

type PaymentMismatchParams = {
  id: string;
};

const PaymentMismatchPageContainer: React.VFC = () => {
  const history = useHistory();
  const { id } = useParams<PaymentMismatchParams>();
  const [paymentMismatch, setPaymentMismatch] =
    useState<PaymentMismatchDetailFragment>({
      id: "",
      handlingStatus: "",
      payInfoNo: "",
      type: "",
      serviceId: "",
      detectedDateTime: new Date(),
    });
  const [handlingHistories, setHandlingHistories] = useState<
    MismatchHandlingHistoryFragment[]
  >([]);
  const [payment, setPayment] =
    useState<PaymentMismatchDetailFragment["payment"]>();
  const [me, setMe] = useState<UserDetailFragment>();
  const [createMismatchHandlingHistory] =
    useCreateMismatchHandlingHistoryMutation();
  const [updatePaymentMismatch] = useUpdatePaymentMismatchMutation();
  const [mplStatuses, setMplStatuses] =
    useState<PaymentMismatchCsvFragment[]>();

  // 不整合詳細取得
  const {
    data: paymentMismatchData,
    error: paymentMismatchError,
    loading: paymentMismatchLoading,
    refetch: paymentMismatchRefetch,
  } = usePaymentMismatchQuery({
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setPaymentMismatch(data.paymentMismatch);
    },
  });
  // 対応状況取得
  const {
    data: mismatchHandlingHistoryData,
    error: mismatchHanldingHistoryError,
    loading: mismatchHandlingHistoryLoading,
    refetch: mismatchHandlingHistoriesRefetch,
  } = useMismatchHandlingHistoriesQuery({
    variables: { id },
  });
  const { error: meError, loading: meLoading } = useProfileQuery({
    onCompleted: (data) => {
      setMe(data.me);
    },
  });
  // かんたん決済csv情報取得
  const {
    data: paymentMismatchCsvData,
    error: paymentMismatchCsvError,
    loading: paymentMismatchCsvLoading,
  } = usePaymentMismatchCsvQuery({
    variables: {
      id,
    },
  });

  const createHandlingHistory = useCallback(
    async (handlingStatus: string, note: string) => {
      try {
        // 対応履歴の作成
        await createMismatchHandlingHistory({
          variables: {
            input: {
              handlingStatus,
              note,
              // localで動かす場合はKBIでログインしたアカウントをユーザーに追加する必要あり
              employeeId: me?.id!,
              paymentMismatchId: id,
            },
          },
        });
        // 不整合データが持つ対応状況の更新
        await updatePaymentMismatch({
          variables: {
            input: {
              id,
              handlingStatus,
            },
          },
        });
      } catch (error: any) {
        console.error(error);
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
        } else {
          history.push("/error/internalservererror");
        }
        return;
      }
      await mismatchHandlingHistoriesRefetch();
      await paymentMismatchRefetch();
    },
    [
      history,
      createMismatchHandlingHistory,
      id,
      me?.id,
      mismatchHandlingHistoriesRefetch,
      paymentMismatchRefetch,
      updatePaymentMismatch,
    ]
  );

  useEffect(() => {
    if (!paymentMismatchData || !paymentMismatchData.paymentMismatch.payment) {
      return;
    }
    if (
      paymentMismatchData.paymentMismatch.type !==
      PaymentMismatchType.NotExistInStudyPF
    ) {
      setPayment(paymentMismatchData.paymentMismatch.payment);
    }
  }, [setPayment, paymentMismatchData]);

  useEffect(() => {
    if (mismatchHandlingHistoryData) {
      setHandlingHistories(
        mismatchHandlingHistoryData.mismatchHandlingHistories!
      );
    }
  }, [mismatchHandlingHistoryData]);

  useEffect(() => {
    if (paymentMismatchCsvData) {
      setMplStatuses(paymentMismatchCsvData.paymentMismatchCsv!);
    }
  }, [mismatchHandlingHistoryData, paymentMismatchCsvData]);

  if (
    paymentMismatchLoading ||
    mismatchHandlingHistoryLoading ||
    meLoading ||
    paymentMismatchCsvLoading
  ) {
    return <LoadingUI />;
  }

  if (paymentMismatchError) {
    console.error(paymentMismatchError);
    const errCode = paymentMismatchError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  if (mismatchHanldingHistoryError) {
    console.error(mismatchHanldingHistoryError);
    const errCode =
      mismatchHanldingHistoryError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  if (meError) {
    console.error(meError);
    const errCode = meError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  if (paymentMismatchCsvError) {
    console.error(paymentMismatchCsvError);
    const errCode = paymentMismatchCsvError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <PaymentMismatchPage
      paymentMismatch={paymentMismatch}
      handlingHistories={handlingHistories}
      mplStatuses={mplStatuses}
      payment={payment}
      createHandlingHistory={createHandlingHistory}
    />
  );
};
export default PaymentMismatchPageContainer;
