import React, { ButtonHTMLAttributes } from "react";

import { LocationDescriptor } from "history";
import { useHistory } from "react-router-dom";

import styles from "./Button.module.css";

export type ButtonUIProps = {
  size?:
    | "xs"
    | "s"
    | "m"
    | "l"
    | "ll"
    | "xl"
    | "xxl"
    | "csvBtnSize"
    | "auto"
    | "custom";
  buttonType?: "primary" | "secondary" | "selecting" | "danger";
  base?: "base" | "rounded15" | "rounded20";
  to?: LocationDescriptor;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonUI: React.FC<ButtonUIProps> = ({
  size = "s",
  buttonType = "primary",
  base = "base",
  children,
  onClick,
  to,
  className,
  ...rest
}) => {
  const history = useHistory();
  return (
    <button
      {...rest}
      onClick={(e) => {
        if (onClick) {
          return onClick(e);
        }
        if (to) {
          history.push(to);
        }
      }}
      className={`${styles[base]} ${styles[size]} ${styles[buttonType]} ${className}`}
    >
      {children}
    </button>
  );
};
