import React, { HTMLAttributes } from "react";

import { ArrowButton } from "./ArrowButton";
import { PaginationItem } from "./PaginationItem";

export type PaginationProps = {
  selectedIndex: number;
  count: number;
  onClickItem: (index: number) => void;
  onClickPrevios: () => void;
  onClickNext: () => void;
} & HTMLAttributes<HTMLElement>;

export const PaginationUI: React.FC<PaginationProps> = ({
  selectedIndex,
  count,
  className,
  onClickItem,
  onClickPrevios,
  onClickNext,
}) => {
  return (
    <div className={`flex flex-row justify-center ${className}`}>
      <ArrowButton
        data-testid="pagination-left-arrow"
        arrowType="left"
        className="mr-4"
        disabled={selectedIndex === 0}
        onClick={onClickPrevios}
      ></ArrowButton>
      <div className="flex flex-row space-x-1">
        {[...Array(count)].map((_, index) => (
          <PaginationItem
            data-testid="pagination-item"
            selected={index === selectedIndex}
            onClick={() => onClickItem(index)}
            key={index}
          >
            {index + 1}
          </PaginationItem>
        ))}
      </div>
      <ArrowButton
        data-testid="pagination-right-arrow"
        arrowType="right"
        className="ml-4"
        disabled={selectedIndex === count - 1}
        onClick={onClickNext}
      ></ArrowButton>
    </div>
  );
};
