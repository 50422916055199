import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import {
  CategoriesDnDTableItemFragment,
  CategoriesSortPageQuery,
} from "../../../generated/graphql";
import {
  CategoriesDnDTableUI,
  CategoriesDnDTableUIProps,
} from "./CategoriesDnDTableUI";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "興味のあるカテゴリ並び替え",
  },
];

export type CategoriesSortPageProps = {
  data: CategoriesSortPageQuery;
  onClickDone: (categories: CategoriesDnDTableItemFragment[]) => void;
};

const dirty = (
  categories: CategoriesDnDTableItemFragment[],
  org: CategoriesDnDTableItemFragment[]
) => {
  return categories.some((category, index) => category.id !== org[index].id);
};

const CategoriesSortPage: React.VFC<CategoriesSortPageProps> = ({
  data,
  onClickDone,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  // キャッシュ更新の場合はorder順になっていないのでソートする
  const [categories, setCategories] = useState<
    CategoriesDnDTableItemFragment[]
  >(data.activeSubjects.nodes.concat().sort((a, b) => a.order - b.order));

  useEffect(() => {
    if (data) {
      setCategories(data.activeSubjects.nodes);
    }
  }, [data]);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const handleChangeCategories: CategoriesDnDTableUIProps["onChangeCategories"] =
    useCallback((categories: CategoriesDnDTableItemFragment[]) => {
      setCategories(categories);
    }, []);

  const handleClickButton: MouseEventHandler<HTMLButtonElement> = useCallback(
    (_event) => {
      onClickDone(categories);
    },
    [onClickDone, categories]
  );

  return (
    <div className="mb-40 px-6">
      <h1 className="text-2xl mb-3">興味のあるカテゴリ並び替え</h1>
      <hr className="border-dark-gray border mb-4"></hr>

      <div className="mb-10">
        <div className="mb-10">
          <p>
            お客様側で会員登録時にご選択いただく「興味のあるカテゴリ」の並び順を変更します。
          </p>
          <p>
            「興味のあるカテゴリ」は、マスタ設定画面の「商品カテゴリ」と連動しており、
          </p>
          <p>ステータスが有効のもののみ表示されています。</p>
        </div>
        <p data-testid="categories-totalCount">
          全{data.activeSubjects.totalCount}件中1〜
          {data.activeSubjects.nodes.length}件表示
        </p>
        <CategoriesDnDTableUI
          onChangeCategories={handleChangeCategories}
          categories={categories}
        />
        <div className="flex justify-center mt-20">
          <ButtonUI
            onClick={handleClickButton}
            disabled={!dirty(categories, data.activeSubjects.nodes)}
          >
            並び替え完了
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default CategoriesSortPage;
