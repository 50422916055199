import React, { HTMLAttributes } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TextFieldUI } from "../../../../components/TextField";
import { VideoLessonDetailFragment } from "../../../../generated/graphql";
dayjs.extend(utc);
dayjs.extend(timezone);

export type ExpertDetailProps = {
  videoLesson: VideoLessonDetailFragment;
} & HTMLAttributes<HTMLDivElement>;

const POSFIX = ["①", "②", "③"];
const TextFieldPClassName = "ml-2.5 text-lg";

const VideoLessonDetail: React.VFC<ExpertDetailProps> = ({
  videoLesson,
  className,
  ...rest
}) => {
  return (
    <div className={`space-y-5 ${className}`} {...rest}>
      <TextFieldUI
        testIdPrefix="videolesson-name"
        label="シリーズタイトル"
        value={videoLesson.name}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="videolesson-description"
        label="紹介文"
        value={videoLesson.description}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="videolesson-alias"
        label="URL表記"
        value={videoLesson.alias}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="videolesson-field"
        label="分野"
        value={videoLesson.field.name}
        pClassName={TextFieldPClassName}
      />
      <div className="pb-4 border-b border-under-line">
        <label className="text-lg font-bold">分野から探すカード画像</label>
        {videoLesson.fieldItemImg && (
          <img
            data-testid="videolesson-field-item-img"
            src={videoLesson.fieldItemImg}
            width={188}
            height={106}
            className="mt-2.5"
            alt="分野から探すカード画像"
          />
        )}
      </div>
      <TextFieldUI
        testIdPrefix="videolesson-videotag"
        label="カテゴリ"
        value={videoLesson.videoTag?.name}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="videolesson-sample-mid"
        label="サンプル動画ID"
        value={videoLesson.jStreamSampleMId}
        pClassName={TextFieldPClassName}
      />
      {videoLesson.videos.map((video, idx) => {
        const pos = POSFIX[idx];
        return (
          <div key={video.id} className="border-b border-under-line pb-4">
            <p className="text-lg font-bold">{`レッスン${pos}`}</p>
            <div className="ml-2.5 mt-2.5">
              <p className="text-base font-bold">{`レッスンタイトル${pos}`}</p>
              <p
                data-testid={`video-${idx + 1}-name`}
                className="ml-2.5 mt-2.5"
              >
                {video.name}
              </p>
            </div>
            <div className="ml-2.5 mt-4">
              <p className="text-base font-bold">{`レッスン動画ID${pos}`}</p>
              <p data-testid={`video-${idx + 1}-mid`} className="ml-2.5 mt-2.5">
                {video.jStreamMId}
              </p>
            </div>
          </div>
        );
      })}
      <TextFieldUI
        testIdPrefix="videolesson-publish-date"
        label="公開設定"
        value={`${dayjs(videoLesson.publishedAt)
          .tz("Asia/Tokyo")
          .format("YYYY年MM月DD日HH:mm")} ~ ${
          videoLesson.closedAt
            ? dayjs(videoLesson.closedAt)
                .tz("Asia/Tokyo")
                .format("YYYY年MM月DD日HH:mm")
            : ""
        }`}
        pClassName={TextFieldPClassName}
      />
    </div>
  );
};

export default VideoLessonDetail;
