import {
  CoursePriceType,
  CourseTargetType,
  CourseDetailCategory,
  QuestionnaireType,
  ServiceKind,
} from "../../generated/graphql";

export const targetGrade = [
  { value: "年少（３～４歳）", label: "年少（３～４歳）", order: 1 },
  { value: "年中（４～５歳）", label: "年中（４～５歳）", order: 2 },
  { value: "年長（５～６歳）", label: "年長（５～６歳）", order: 3 },
  { value: "小学１年生", label: "小学１年生", order: 4 },
  { value: "小学２年生", label: "小学２年生", order: 5 },
  { value: "小学３年生", label: "小学３年生", order: 6 },
  { value: "小学４年生", label: "小学４年生", order: 7 },
  { value: "小学５年生", label: "小学５年生", order: 8 },
  { value: "小学６年生", label: "小学６年生", order: 9 },
  { value: "中学１年生", label: "中学１年生", order: 10 },
  { value: "中学２年生", label: "中学２年生", order: 11 },
  { value: "中学３年生", label: "中学３年生", order: 12 },
  { value: "高校１年生", label: "高校１年生", order: 13 },
  { value: "高校２年生", label: "高校２年生", order: 14 },
  { value: "高校３年生", label: "高校３年生", order: 15 },
  { value: "大学生", label: "大学生", order: 16 },
];

export const targetAges = Array.from(new Array(116)).map((v, i) => ({
  value: `${i + 5}`,
  label: `${i + 5}歳`,
  order: i,
}));

export const targetTypes = [
  { value: CourseTargetType.Age, label: "年齢" },
  { value: CourseTargetType.Grade, label: "学年" },
  { value: CourseTargetType.None, label: "指定なし" },
];

export const subjects = [
  { value: "TOEIC", label: "TOEIC" },
  { value: "TOEFL", label: "TOEFL" },
  { value: "英検", label: "英検" },
  { value: "GTEC", label: "GTEC" },
  { value: "ビジネス英会話", label: "ビジネス英会話" },
  { value: "日常英会話", label: "日常英会話" },
];

export const prices = [
  { value: CoursePriceType.Once, label: "従量課金" },
  { value: CoursePriceType.Free, label: "無料" },
];

export const PriceTypeName = {
  [CoursePriceType.Once]: "従量課金",
  [CoursePriceType.Free]: "無料",
} as const;

export const targetOption: {
  [key: string]: [] | { value: string; label: string; order: number }[];
} = {
  [CourseTargetType.None]: [],
  [CourseTargetType.Age]: targetAges,
  [CourseTargetType.Grade]: targetGrade,
};

export const contentDetail: {
  [key: string]: [] | { value: string; label: string }[];
} = {
  [CourseTargetType.None]: [],
  [CourseTargetType.Age]: targetAges,
  [CourseTargetType.Grade]: targetGrade,
};

/**
 * コース詳細項目のラベル
 * ※ 項目が増えたら『courseDetailSortOrder』にも追加する
 */
export const courseDetail: {
  [key: string]: string;
} = {
  [CourseDetailCategory.Description]: "コース詳細",
  [CourseDetailCategory.Recommendation]: "こんな人におすすめ！",
  [CourseDetailCategory.Preparation]: "準備物",
  [CourseDetailCategory.Lessonflow]: "当日の流れ",
  [CourseDetailCategory.Notes]: "注意事項",
};

/**
 * コース詳細編集画面での表示順序と UT を通すための設定
 * ※ 注意：
 * CourseEntity.details 配列の中身のオーダーが期待値と違うと
 * UT が通らないのでソート順を指定
 */
export const courseDetailSortOrder: CourseDetailCategory[] = [
  CourseDetailCategory.Description,
  CourseDetailCategory.Recommendation,
  CourseDetailCategory.Preparation,
  CourseDetailCategory.Lessonflow,
  CourseDetailCategory.Notes,
];

export const courseQuestionnaireType = [
  { value: "", label: "回答形式" },
  { value: QuestionnaireType.SelectQuestionnaire, label: "ラジオボタン" },
  {
    value: QuestionnaireType.MultiSelectQuestionnaire,
    label: "チェックボックス",
  },
  // https://agile.kddi.com/jira/browse/STUDYPF-3846 2C側からの依頼で選択不可に変更
  // { value: QuestionnaireType.LinearScaleQuestionnaire, label: "均等目盛" },
  { value: QuestionnaireType.FreeTextQuestionnaire, label: "記述式" },
];

export const multiSelectCondition = [
  { value: "equal", label: "等しい" },
  { value: "lessThanOrEqual", label: "以下" },
];

export const maxPrice: { [key: string]: number } = {
  [ServiceKind.Aeon]: 45455,
  [ServiceKind.ElementarySchool]: 45455,
  [ServiceKind.LiberalArts]: 90909,
};
