export enum PaymentStatus {
  /**
   * 未確定
   */
  Pending = 0,
  /**
   * オーソリ
   */
  Authorized = 10,
  /**
   * オーソリ取り消し
   */
  AuthorizationCancelled = 15,
  /**
   * 売上
   */
  Sold = 20,
  /**
   * 売上取消
   */
  SoldCancelled = 25,
  /**
   * 請求
   */
  Claim = 30,
  /**
   * 返金
   */
  Refund = 40,
  /**
   * 該当無し ※従量課金の場合は設定されない
   */
  NotApplicable = 90,
}

export type PaymentStatusLable = {
  id: string;
  value: number;
  label: string;
};

export const paymentStatuses: PaymentStatusLable[] = [
  {
    id: "pending",
    value: PaymentStatus.Pending,
    label: "未確定",
  },
  {
    id: "authority",
    value: PaymentStatus.Authorized,
    label: "オーソリ",
  },
  {
    id: "authorityCansel",
    value: PaymentStatus.AuthorizationCancelled,
    label: "オーソリ取消",
  },
  {
    id: "sold",
    value: PaymentStatus.Sold,
    label: "売上確定",
  },
  {
    id: "soldCansel",
    value: PaymentStatus.SoldCancelled,
    label: "売上取消",
  },
  {
    id: "billing",
    value: PaymentStatus.Claim,
    label: "請求",
  },
  {
    id: "refund",
    value: PaymentStatus.Refund,
    label: "返金",
  },
  {
    id: "refund",
    value: PaymentStatus.NotApplicable,
    label: "該当なし",
  },
];
