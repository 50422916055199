import React from "react";

import { useHistory } from "react-router-dom";

import { useProfileQuery } from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import ProfilePage from "./ProfilePage";

const ProfilePageContaier = () => {
  const history = useHistory();
  const { data, error, loading } = useProfileQuery();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return <ProfilePage data={data} />;
};

export default ProfilePageContaier;
