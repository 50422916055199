import React, {
  useCallback,
  MouseEventHandler,
  useState,
  useEffect,
} from "react";

import { LoadingUI } from "../../../components/Loading";
import {
  AnnouncementsFragment,
  useAnnouncementsQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { announcementsSearchSettings as setting } from "../../../lib/constants";
import AnnouncementsPage from "./AnnouncementsPage";

const AnnouncementsPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const [limit, setLimit] = useState(setting.offsetDelta);
  const [announcements, setAnnouncements] = useState<AnnouncementsFragment>({
    totalCount: 0,
    nodes: [],
  });
  const { data, loading, refetch } = useAnnouncementsQuery({
    variables: {
      limit,
    },
    fetchPolicy: "no-cache",
    onError: errorRouter,
  });

  useEffect(() => {
    if (data?.announcements) {
      setAnnouncements(data.announcements);
    }
  }, [data?.announcements]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + setting.offsetDelta);
    await refetch({
      limit: limit + setting.offsetDelta,
    });
  }, [limit, setLimit, refetch]);

  // もっとみるが押されたときは検索中をださない
  if (loading && announcements.nodes.length === 0) {
    return <LoadingUI title="検索中" />;
  }

  return (
    <AnnouncementsPage
      announcements={announcements}
      onDisplayMore={onDisplayMore}
      loading={loading}
    />
  );
};
export default AnnouncementsPageContainer;
