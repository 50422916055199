import { useCallback, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import {
  CreateDiscountInput,
  DiscountType,
  useCreateDiscountMutation,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { getGraphQLEnum } from "../../../lib/utils/grapghQlEnum";
import { EditingDiscount } from "../components/DiscountEdit";
import DiscountConfirmModalUI from "../components/modals/DiscountConfirmModal";
import DiscountNewConfirmPage from "./DiscountNewConfirmPage";

const DiscountNewConfirmPageContainer: React.VFC = () => {
  const history = useHistory();
  const errorRouter = useErrorRouter();
  const { state } = useLocation<EditingDiscount>();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // 登録データ
  const [input] = useState<CreateDiscountInput>({
    type: state.type,
    name: state.name!,
    code: state.code!,
    startedAt: state.startedAt!,
    endedAt: state.endedAt!,
    durationMonths: state.durationMonths,
    description: state.description,
    priceValueNoTax: state.priceValueNoTax!,
    limitedCourseIds:
      state.limitedCourses && state.limitedCourses.map((c) => c.id),
  });

  // createDiscount ミューテーション
  const [createFailedModalVisibility, setCreateFailedModalVisibility] =
    useState<boolean>(false);
  const [mutation] = useCreateDiscountMutation({
    variables: {
      input: {
        ...input,
        type: getGraphQLEnum(DiscountType, input.type) as DiscountType,
      },
    },
    onError: (err) => {
      if (
        !err.graphQLErrors ||
        err.graphQLErrors.length === 0 ||
        !err.graphQLErrors[0].extensions
      ) {
        errorRouter(err);
        return;
      }
      const { code } = err.graphQLErrors[0].extensions;
      if (code === "CREATE_DISCOUNT_FAILED_BECAUSE_CODE_IS_DUPLICATED") {
        // 登録時にコードが既に登録されていた
        setCreateFailedModalVisibility(true);
      } else {
        errorRouter(err);
      }
      return;
    },
    onCompleted: (res) => {
      setModalVisibility(true);
    },
  });

  /**
   * 登録処理
   */
  const onSubmit = useCallback(async () => {
    await mutation();
  }, [mutation]);

  /**
   * 登録完了ダイアログの OK ハンドラー
   */
  const onCompleteConfirm = useCallback(() => {
    history.push(`/discounts`);
  }, [history]);

  /**
   * コード重複のため登録失敗モーダルを閉じるハンドラー
   */
  const onFailedMessageModalClose = useCallback(() => {
    setCreateFailedModalVisibility(false);
  }, []);

  return (
    <>
      <DiscountNewConfirmPage
        input={input}
        limitedCourses={state.limitedCourses}
        onSubmit={onSubmit}
      />
      {modalVisibility && (
        <DiscountConfirmModalUI
          title="登録完了"
          message="クーポンを登録しました。"
          onConfirm={onCompleteConfirm}
        />
      )}
      {createFailedModalVisibility && (
        <DiscountConfirmModalUI
          title="登録失敗"
          message={`コード『${input.code}』は<br/>既に登録されています。`}
          onConfirm={onFailedMessageModalClose}
        />
      )}
    </>
  );
};

export default DiscountNewConfirmPageContainer;
