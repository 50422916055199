import { ValidateResult } from "react-hook-form";

import { DiscountType } from "../../../generated/graphql";

export const validatePriceValueNoTax = (
  value: string,
  discountType: DiscountType,
  maximumDiscountAmount: number
): ValidateResult => {
  try {
    const price: number = parseInt(`${value}`.replace(",", ""));
    if (isNaN(price) || !price || price === 0) {
      return "税抜価格を入力してください。";
    }
    if (price % 100 !== 0) {
      return "100円単位で入力してください。";
    }
    if (price > 45400) {
      return "45,400円以下を入力してください。";
    }
    if (
      price > maximumDiscountAmount &&
      discountType === DiscountType.InitialCoupon
    ) {
      return `初月割引クーポン（月額）の場合は、${maximumDiscountAmount}円以下を入力してください。`;
    }
    if (
      price > maximumDiscountAmount &&
      discountType === DiscountType.PlanCoupon
    ) {
      return `クーポン（月額）の場合は、${maximumDiscountAmount}円以下を入力してください。`;
    }
  } catch (e) {
    return "税抜価格を入力してください";
  }
  return undefined;
};
