import { useHistory } from "react-router-dom";

import { useQueryParams } from "../../hooks/query";
import MaintenancePage from "./MaintenancePage";

const MaintenancePageContainer: React.VFC = () => {
  const history = useHistory();

  const params = useQueryParams();
  const startDate = params.get("startDate");
  const endDate = params.get("endDate");

  if (typeof startDate === "string" && typeof endDate === "string") {
    return <MaintenancePage startDate={startDate} endDate={endDate} />;
  } else {
    history.push("/error/internalserver");
    return <></>;
  }
};

export default MaintenancePageContainer;
