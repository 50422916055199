import React, {
  useState,
  useCallback,
  MouseEventHandler,
  MouseEvent,
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  FormEvent,
  useEffect,
  useMemo,
} from "react";

import { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import CustomerSearchModalUI from "../../../components/CustomerSearchModal/CustomerSearchModal";
import { LoadingUI } from "../../../components/Loading";
import {
  CreateContactInput,
  CreateContactHistoryInput,
  ContactRoute,
  ContactStatus,
  useContactSelectOptionsQuery,
  ContactCategoryFragment,
  useCustomerSearchResultsLazyQuery,
  CustomerSearchResultsFragment,
  CustomerFilterInput,
  CustomersTableRowFragment,
  useCreateContactMutation,
  useCreateContactHistoryMutation,
} from "../../../generated/graphql";
import { currentServiceKind } from "../../../lib/cache";
import { ErrorType } from "../../../lib/constants/error";
import ContactsNewPage, {
  ChangeContactHandler,
  ChangeContactHistoryHandler,
} from "./ContactsNewPage";

export type CreateContactEdit = {
  categoryId: string;
  route: ContactRoute;
} & Omit<CreateContactInput, "route" | "categoryId">;

type ConfirmErrorConfig = {
  title: string;
  description: string;
  btnTitle: string;
};

const ContactsNewPageContainer = () => {
  const history = useHistory();
  const [member, setMember] = useState(true);
  // 最初にバリデーションメッセージを表示したくないので、初期値はfalse(ユーザーは選択されている)
  const [noUserSelect, setNoUserSelect] = useState(false);
  const [customerSearchModalVisibility, setCustomerSearchModalVisibility] =
    useState(false);
  const [completedModalVisibility, setCompletedModalVisibility] =
    useState(false);
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [confirmErrorConfig, setConfirmErrorConfig] =
    useState<ConfirmErrorConfig>({
      title: "",
      description: "",
      btnTitle: "",
    });

  const { data, loading, error } = useContactSelectOptionsQuery();
  const [createContact] = useCreateContactMutation();
  const [createContactHistory] = useCreateContactHistoryMutation({
    onCompleted: () => {
      setCompletedModalVisibility(true);
    },
  });

  const [customersSearchInput, setCustomersSearchInput] =
    useState<CustomerFilterInput>({
      memberId: "",
      customerName: "",
      customerNameKana: "",
      tel: "",
      // NOTE: registerFrom, registerToを設定しないと
      // 検索が動かないので暫定で空文字を入れている
      registerFrom: "",
      registerTo: "",
      isDeletedUserIncluded: true,
    });

  const [customersLazyQuery, { data: searchData }] =
    useCustomerSearchResultsLazyQuery({
      // 検索モーダルを閉じたあと、再度開いたときに、searchedCustomersを空配列で初期化するが、
      // customersSearchInputの値に変更がない（検索フォームを変更しない）場合再検索が発生しないので、いづれの場合でもサーバからデータを取得するようにしている
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        customerSearchModalVisibility &&
          setCustomerSearchModalVisibility(false);
        !errorModalVisibility && setErrorModalVisibility(true);
        setConfirmErrorConfig({
          title: "会員の検索に失敗しました。",
          description: `会員の検索に失敗しました。
            しばらく時間を置いてから、もう一度お試しください。`,
          btnTitle: "閉じる",
        });
      },
    });

  const [searchedCustomers, setSearchedCustomers] =
    useState<CustomerSearchResultsFragment>({
      totalCount: 0,
      nodes: [],
    });

  const contactCategories = useMemo(() => {
    return (
      data?.contactCategories.map((category: ContactCategoryFragment) => ({
        value: category.id,
        label: category.value,
      })) || []
    );
  }, [data?.contactCategories]);

  const contactServiceKinds = useMemo(() => {
    return (
      data?.services.map((service) => ({
        value: service.key,
        label: service.shortName,
      })) || []
    );
  }, [data?.services]);

  useEffect(() => {
    setSearchedCustomers(searchData?.customers || { totalCount: 0, nodes: [] });
  }, [searchData?.customers]);

  const initialServiceKind = currentServiceKind()!.key;

  const [contactInput, setContactInput] = useState<CreateContactEdit>({
    content: "",
    customerId: "",
    address: {},
    serviceKind: initialServiceKind,
    categoryId: "1",
    route: ContactRoute.Tel,
  });

  const [contactHistoryInput, setContactHistoryInput] =
    useState<CreateContactHistoryInput>({
      contactId: "",
      oldStatus: ContactStatus.Unsupported,
      status: ContactStatus.Unsupported,
      serviceKind: initialServiceKind,
    });

  const onChangeFormType = useCallback(
    (member: boolean) => {
      setMember(member);
      let newContactInput: CreateContactEdit = contactInput;
      newContactInput.customerId && delete newContactInput.customerId;
      newContactInput.address && delete newContactInput.address;
      setContactInput(newContactInput);
    },
    [contactInput]
  );

  const onOpenCustomerSearchModal: MouseEventHandler<HTMLButtonElement> =
    useCallback((event: MouseEvent) => {
      event.preventDefault();
      setCustomerSearchModalVisibility(true);
    }, []);

  const onChangeCustomersSearchInput: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setCustomersSearchInput({
          ...customersSearchInput,
          [event.target.id]: event.target.value,
        });
      },
      [customersSearchInput]
    );

  const onChangeContact: ChangeContactHandler = useCallback(
    (contactInput: CreateContactEdit) => {
      setContactInput(contactInput);
    },
    []
  );

  const onChangeContactHistory: ChangeContactHistoryHandler = useCallback(
    (contactInput: CreateContactHistoryInput) => {
      setContactHistoryInput(contactInput);
    },
    []
  );

  const onSelectedCustomer: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedCustomer: CustomersTableRowFragment =
        searchedCustomers.nodes.find(
          (customer: CustomersTableRowFragment) =>
            customer.id === event.target.value
        )!;
      setContactInput({
        ...contactInput,
        customerId: selectedCustomer.id,
        address: {
          familyName: selectedCustomer.profile?.familyName,
          givenName: selectedCustomer.profile?.givenName,
          familyNameKana: selectedCustomer.profile?.familyNameKana,
          givenNameKana: selectedCustomer.profile?.givenNameKana,
          email: selectedCustomer.profile?.memberId,
          tel: selectedCustomer.profile?.tel,
        },
      });
    },
    [contactInput, searchedCustomers.nodes]
  );

  const onSearchCustomers: FormEventHandler = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      customersLazyQuery({
        variables: {
          filter: customersSearchInput,
          offset: 0,
          limit: 20,
        },
      });
      setContactInput({
        content: "",
        customerId: "",
        address: {},
        serviceKind: initialServiceKind,
        categoryId: contactInput.categoryId,
        route: contactInput.route,
      });
    },
    [
      contactInput.categoryId,
      contactInput.route,
      customersLazyQuery,
      customersSearchInput,
      initialServiceKind,
    ]
  );

  const onApplyCustomer: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      // ユーザーが１人も選択されていない場合は処理を中断
      if (
        !searchedCustomers.nodes.some(
          (customer: CustomersTableRowFragment) =>
            customer.id === contactInput.customerId
        )
      ) {
        setNoUserSelect(true);
        return;
      }
      setNoUserSelect(false);
      setCustomerSearchModalVisibility(false);
      setSearchedCustomers({ totalCount: 0, nodes: [] });
      setCustomersSearchInput({
        customerName: "",
        customerNameKana: "",
        memberId: "",
        tel: "",
        registerFrom: "",
        registerTo: "",
        isDeletedUserIncluded: true,
      });
    },
    [contactInput.customerId, searchedCustomers.nodes]
  );

  const onCancelCustomerSearchModal: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let newContactInput: CreateContactEdit = contactInput;
        newContactInput.customerId && delete newContactInput.customerId;
        newContactInput.address && delete newContactInput.address;
        setContactInput(newContactInput);
        setCustomerSearchModalVisibility(false);
        setSearchedCustomers({ totalCount: 0, nodes: [] });
        setCustomersSearchInput({
          customerName: "",
          customerNameKana: "",
          memberId: "",
          tel: "",
          registerFrom: "",
          registerTo: "",
          isDeletedUserIncluded: true,
        });
        // モーダル表示時に、バリデーションメッセージを表示したくないので、初期値はfalse(ユーザーは選択されている)
        setNoUserSelect(false);
      },
      [contactInput]
    );

  const onCreateContact = useCallback(
    async (
      contactInput: CreateContactEdit,
      contactHistoryInput: CreateContactHistoryInput
    ) => {
      setIsLoading(true);
      let createdId;
      try {
        const input: CreateContactInput = {
          categoryId: contactInput.categoryId!,
          content: contactInput.content,
          route: contactInput.route!,
          customerId: contactInput.customerId,
          serviceKind: contactHistoryInput.serviceKind,
        };
        if (!member) {
          Object.assign(input, { address: contactInput.address });
        }
        const createResult = await createContact({
          variables: {
            input,
          },
        });
        createdId = createResult?.data?.createContact.id;
        if (!createdId) {
          throw new Error("creating contact failed.");
        }
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
          return;
        }
        setIsLoading(false);
        console.error(JSON.stringify(error, null, 2));
        setConfirmErrorConfig({
          title: "登録に失敗しました。",
          description: `お問い合わせの登録に失敗しました。
          しばらく時間を置いてから、もう一度お試しください。`,
          btnTitle: "閉じる",
        });
        !errorModalVisibility && setErrorModalVisibility(true);
        return;
      }
      // 対応履歴に変更がある場合、対応履歴の登録を実施する
      if (
        contactHistoryInput.status !== ContactStatus.Unsupported ||
        (contactHistoryInput.memo !== "" &&
          contactHistoryInput.memo !== undefined)
      ) {
        try {
          Object.assign(contactHistoryInput, { contactId: createdId });
          await createContactHistory({
            variables: {
              input: contactHistoryInput,
            },
          });
        } catch (error: any) {
          const errCode = error?.graphQLErrors[0]?.extensions?.code;
          if (errCode === ErrorType.UnAuthenticated) {
            history.push("/error/unauthenticated");
            return;
          }
          setConfirmErrorConfig({
            title: "登録に失敗しました。",
            description: `お問い合わせ履歴の登録に失敗しました。
          お問い合わせ情報自体の登録は成功していますので、
          しばらく時間を置いたのちに、
          詳細画面からもう一度お試しください。`,
            btnTitle: "閉じる",
          });
          !errorModalVisibility && setErrorModalVisibility(true);
          return;
        } finally {
          setIsLoading(false);
        }
      } else {
        // 対応履歴に変更がない場合、対応履歴は未登録
        setIsLoading(false);
        setCompletedModalVisibility(true);
      }
    },
    [errorModalVisibility, member, createContact, createContactHistory, history]
  );

  const closeCompletedModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setCompletedModalVisibility(false);
      history.push("/contacts");
    },
    [history]
  );

  const clearErrorModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      errorModalVisibility && setErrorModalVisibility(false);
    },
    [errorModalVisibility]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (!errCode) {
      // エラーコードを取れない場合は内部サーバエラーに飛ばす
      history.push("/error/internalservererror");
    } else if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    }
    return <></>;
  }

  if (!data) {
    console.error("No Data");
    history.push("/error/internalservererror");
    return <></>;
  }

  return (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <ContactsNewPage
        member={member}
        contactInput={contactInput}
        contactHistoryInput={contactHistoryInput}
        contactCategories={contactCategories}
        contactServiceKinds={contactServiceKinds}
        onChangeFormType={onChangeFormType}
        onOpenCustomerSearchModal={onOpenCustomerSearchModal}
        onChangeContact={onChangeContact}
        onChangeContactHistory={onChangeContactHistory}
        onCreateContact={onCreateContact}
      />
      <CustomerSearchModalUI
        visibility={customerSearchModalVisibility}
        searchedCustomers={searchedCustomers}
        customersSearchInput={customersSearchInput}
        selectedCustomerId={contactInput.customerId}
        selectedCustomerFamilyName={contactInput.address?.familyName}
        selectedCustomerGiveName={contactInput.address?.givenName}
        selectedCustomerFamilyNameKana={contactInput.address?.familyNameKana}
        selectedCustomerGiveNameKana={contactInput.address?.givenNameKana}
        selectedCustomerEmail={contactInput.address?.email}
        selectedCustomerTel={contactInput.address?.tel}
        noUserSelect={noUserSelect}
        onChangeCustomersSearchInput={onChangeCustomersSearchInput}
        onSearchCustomers={onSearchCustomers}
        onSelectedCustomer={onSelectedCustomer}
        onApply={onApplyCustomer}
        onCancel={onCancelCustomerSearchModal}
      ></CustomerSearchModalUI>
      <CompletedModalUI
        title="登録完了"
        description="お問い合わせ内容を新規登録しました。"
        btnTitle="お問い合わせ一覧へ"
        onClick={closeCompletedModal}
        visibility={completedModalVisibility}
      />
      <ConfirmErrorModalUI
        title={confirmErrorConfig.title}
        description={confirmErrorConfig.description}
        btnTitle={confirmErrorConfig.btnTitle}
        onClick={clearErrorModal}
        visibility={errorModalVisibility}
      />
    </>
  );
};
export default ContactsNewPageContainer;
