import React, { ChangeEventHandler, FormEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { CheckboxUI } from "../../../components/Checkbox";
import { DateInputUI } from "../../../components/DateInput";
import { InputUI } from "../../../components/Input";
import { LabelUI } from "../../../components/Label";
import { RadioUI } from "../../../components/Radio";
import { CustomerFilterInput, ServicesQuery } from "../../../generated/graphql";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "個人情報照会",
  },
];

export type CustomerSearchPageProps = {
  filter: CustomerFilterInput;
  data?: ServicesQuery;
  onSubmit: FormEventHandler;
  onChangeFilterInput: ChangeEventHandler<HTMLInputElement>;
  onCheckChangeFilterInput: (checked: boolean, value: string) => void;
};

const CustomerSearchPage: React.FC<CustomerSearchPageProps> = ({
  filter,
  data,
  onSubmit,
  onChangeFilterInput,
  onCheckChangeFilterInput,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">個人情報照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <p className="mt-8 ml-8 font-bold text-xl">検索条件設定</p>

      <p className="mt-8 ml-12 font-bold text-xl">プロフィール</p>

      <form onSubmit={onSubmit} className="ml-20 mr-10 pb-20 mt-2.5">
        <div className="flex justify-center items-center space-x-3">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            会員ID(メールアドレス)
          </LabelUI>
          <InputUI
            data-testid="customers-search-memberid"
            value={filter.memberId}
            id="memberId"
            name="memberId"
            className="w-9/12"
            onChange={onChangeFilterInput}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            氏名
          </LabelUI>
          <InputUI
            data-testid="customers-search-customer-name"
            value={filter.customerName}
            id="customerName"
            name="customerName"
            className="w-9/12"
            onChange={onChangeFilterInput}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            フリガナ
          </LabelUI>
          <InputUI
            data-testid="customers-search-customer-name-kana"
            value={filter.customerNameKana}
            id="customerNameKana"
            name="customerNameKana"
            className="w-9/12"
            onChange={onChangeFilterInput}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            電話番号(ハイフンなし)
          </LabelUI>
          <InputUI
            data-testid="customers-search-tel"
            value={filter.tel}
            id="tel"
            name="tel"
            className="w-9/12"
            onChange={onChangeFilterInput}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            会員登録年月日
          </LabelUI>
          <div className="flex items-center w-9/12 space-x-2.5">
            <DateInputUI
              data-testid="customers-search-register-from"
              value={filter.registerFrom || ""}
              id="registerFrom"
              name="registerFrom"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onChange={onChangeFilterInput}
            />
            <span>{" 〜 "}</span>
            <DateInputUI
              data-testid="customers-search-register-to"
              value={filter.registerTo || ""}
              id="registerTo"
              name="registerTo"
              className="w-40 text-center"
              placeholder="YYYY/MM/DD"
              onChange={onChangeFilterInput}
            />
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            退会済みユーザー
          </LabelUI>
          <div className="flex items-center w-9/12 space-x-2.5">
            {[
              { label: "含む", value: "included", isChecked: true },
              { label: "含まない", value: "notIncluded", isChecked: false },
            ].map((status, index) => (
              <RadioUI
                data-testid="customers-search-is-deleted-user-included"
                key={index}
                id={`status-${status.value}`}
                name="isDeletedUserIncluded"
                value={status.value}
                checked={filter.isDeletedUserIncluded === status.isChecked}
                onChange={onChangeFilterInput}
              >
                {status.label}
              </RadioUI>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center space-x-3 mt-2.5">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            会員登録時サービス
          </LabelUI>
          <div className="flex space-x-3 w-9/12">
            {data?.services.map((serviceKind) => {
              return (
                <CheckboxUI
                  data-testid="customers-search-search-customer-service-kind"
                  style={{ marginRight: "8px" }}
                  id={serviceKind.id}
                  name="searchCustomerServiceKind"
                  value={serviceKind.id}
                  checked={
                    (filter.searchCustomerServiceKind &&
                      filter.searchCustomerServiceKind.length > 0 &&
                      filter.searchCustomerServiceKind.findIndex(
                        (element) => element === serviceKind.id
                      ) >= 0) ??
                    false
                  }
                  key={serviceKind.id}
                  onCheckChange={onCheckChangeFilterInput}
                >
                  {serviceKind.shortName}
                </CheckboxUI>
              );
            })}
          </div>
        </div>

        <div className="flex justify-end">
          <ButtonUI
            data-testid="customers-search-submit"
            buttonType="primary"
            base="rounded20"
            type="submit"
            size="xs"
            className="mr-2.5"
          >
            検索
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};

export default CustomerSearchPage;
