import React, { useState } from "react";

import Select, {
  components,
  DropdownIndicatorProps,
  SingleValue,
} from "react-select";

import { UseableSerivceFragment } from "../../generated/graphql";
import { ReactComponent as ArrowDown } from "./arrow_down.svg";
import { ReactComponent as ArrowUp } from "./arrow_up.svg";

export type ServiceSelectProps = {
  useableServiceKinds: UseableSerivceFragment[];
  onChange: (selected: UseableSerivceFragment) => void;
  currentServiceKindId?: string;
};

type Option = {
  label: string;
  value: string;
};

const createDropdownIndicator =
  (isMenuOpen: boolean) => (props: DropdownIndicatorProps<Option, false>) => {
    return (
      <components.DropdownIndicator {...props}>
        {isMenuOpen ? <ArrowUp /> : <ArrowDown />}
      </components.DropdownIndicator>
    );
  };

export const ServiceSelect: React.VFC<ServiceSelectProps> = ({
  useableServiceKinds,
  onChange,
  currentServiceKindId,
}) => {
  const options: Option[] = (useableServiceKinds ?? []).map((service) => ({
    value: service.id,
    label: service.shortName,
  }));

  const currentOption = options.find(
    (option) => currentServiceKindId === option.value
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnChange = (option: SingleValue<Option>) => {
    // サービスが変わった時だけonChangeを実行する
    if (currentServiceKindId !== option?.value)
      onChange(useableServiceKinds.find((s) => s.id === option?.value)!);
  };

  return (
    <Select
      styles={{
        container: (provided) => ({
          ...provided,
          width: 165,
          height: 32,
        }),
        control: (provided, state) => ({
          ...provided,
          height: 32,
          minHeight: 32,
          outline: "none",
          border: state.isFocused ? "1px solid #0091DC" : "1px solid #34495E",
          borderBottom: state.menuIsOpen ? "none" : provided.borderBottom,
          borderRadius: state.menuIsOpen
            ? "3px 3px 0 0"
            : provided.borderRadius,
          boxShadow: "none",
        }),
        option: (provided, state) => {
          return {
            ...provided,
            color: "#34495E",
            opacity: state.isSelected ? 0.5 : provided.opacity,
            backgroundColor: state.isSelected
              ? undefined
              : provided.backgroundColor,
          };
        },
        valueContainer: (provided) => ({
          ...provided,
          height: 32,
          minHeight: 32,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: 32,
          minHeight: 32,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        menu: (provided) => ({
          ...provided,
          margin: 0,
          boxShadow: "none",
        }),
        menuList: (provided) => ({
          ...provided,
          paddingTop: "0px",
          paddingBottom: "0px",
          border: "1px solid #0091DC",
          borderTop: "none",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }),
      }}
      value={currentOption}
      placeholder={false}
      onChange={handleOnChange}
      options={options}
      isSearchable={false}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      components={{
        DropdownIndicator: createDropdownIndicator(isMenuOpen),
      }}
    ></Select>
  );
};
