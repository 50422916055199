import React, { HTMLAttributes } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Link } from "react-router-dom";

import { VideoLessonCardFragment } from "../../../../../generated/graphql";
import { ReactComponent as List } from "../ExpertCard/list.svg";

dayjs.extend(utc);
dayjs.extend(timezone);

export type VideoLessonCardProps = {
  expertId: string;
  videoLesson: VideoLessonCardFragment;
} & HTMLAttributes<HTMLDivElement>;

const formatYMD = (date?: Date | string | null) => {
  return date && dayjs(date).tz("Asia/Tokyo").format("YYYY年MM月DD日");
};

export const VideoLessonCard: React.VFC<VideoLessonCardProps> = ({
  expertId,
  videoLesson,
}) => {
  return (
    <div data-testid="video-lesson-card">
      <div className="flex flex-col my-4 mx-28 gap-2">
        <div className="flex">
          <div className="flex-none w-32">シリーズタイトル</div>
          <div className="flex-none w-5">:</div>
          <div className="flex-grow" data-testid="video-lesson-name">
            {videoLesson.name}
          </div>
        </div>
        <div className="flex">
          <div className="flex-none w-32">公開期間</div>
          <div className="flex-none w-5">:</div>
          <div
            className="flex-grow"
            data-testid="video-lesson-publishedAt-closedAt"
          >
            {formatYMD(videoLesson.publishedAt)}~
            {formatYMD(videoLesson.closedAt)}
          </div>
        </div>
        <div className="flex">
          <div className="flex-none w-32">シリーズURLパス</div>
          <div className="flex-none w-5">:</div>
          <div className="flex-grow" data-testid="video-lesson-url-path">
            {`/field/${videoLesson.field.alias}/content/${videoLesson.alias}`}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Link
          className="flex flex-row items-center border border-blue text-blue bg-white rounded-sm py-1 px-2.5 space-x-2"
          to={`/experts/${expertId}/videolessons/${videoLesson.id}`}
          data-testid="to-video-lesson-detail"
        >
          <List className="stroke-current fill-current w-3" />
          <span>シリーズ詳細</span>
        </Link>
      </div>
    </div>
  );
};
