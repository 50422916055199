import React from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { useUserQuery } from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import UserPage from "./UserPage";

type UserPageParams = {
  id: string;
};

const UserPageContaier = () => {
  const { id } = useParams<UserPageParams>();
  const { data, error, loading } = useUserQuery({ variables: { id } });
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return <UserPage data={data} />;
};

export default UserPageContaier;
