import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { AnnouncementPageQuery } from "../../../generated/graphql";
import AnnouncementDetailUI from "../components/AnnouncementDetail/AnnouncementDetail";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

const AnnouncementPage: React.FC<{
  data: AnnouncementPageQuery["announcement"];
}> = ({ data }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <h2 className="text-1.5xl text-title-black">お知らせ詳細</h2>
      <AnnouncementDetailUI
        className="mt-8"
        systemId={data.systemId}
        kind={data.kind}
        publishStartedAt={data.publishStartedAt}
        publishEndedAt={data.publishEndedAt}
        subject={data.subject}
        body={data.body}
      />
      <div className="py-20 flex justify-center space-x-10">
        <ButtonUI
          data-testid="announcement-back-btn"
          buttonType="secondary"
          to={`/announcements`}
        >
          戻る
        </ButtonUI>
        <ButtonUI
          data-testid="announcement-edit-btn"
          to={`/announcements/${data.id}/edit`}
        >
          編集
        </ButtonUI>
      </div>
    </div>
  );
};

export default AnnouncementPage;
