import React, { useCallback, useEffect, useRef } from "react";

import { Auth } from "aws-amplify";

import { DropdownMenuItemUI } from "./DropdownMenuItem";

export type DropdownMenuUIProps = {
  hasUseableServices: boolean;
  visibility: boolean;
  onHidden: () => void;
};

export const DropdownMenuUI: React.FC<DropdownMenuUIProps> = ({
  hasUseableServices,
  visibility,
  onHidden,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onBlurHandler = useCallback(
    (event) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        onHidden();
      }
    },
    [onHidden]
  );

  const onClickItemHandler: React.MouseEventHandler<HTMLLIElement> =
    useCallback((event) => {
      event.stopPropagation();
      ref.current && ref.current.blur();
    }, []);

  useEffect(() => {
    visibility && ref.current && ref.current.focus();
  }, [visibility]);
  const logout = () => {
    Auth.signOut();
  };
  if (!visibility) {
    return <></>;
  }
  return (
    <div
      ref={ref}
      className="absolute top-16 right-0 w-36 text-xs border border-base-blue focus:outline-none"
      tabIndex={1}
      onBlur={onBlurHandler}
    >
      <ul>
        {hasUseableServices && (
          <DropdownMenuItemUI to={"/profile"} onClick={onClickItemHandler}>
            プロフィール設定
          </DropdownMenuItemUI>
        )}
        <DropdownMenuItemUI onClick={logout}>ログアウト</DropdownMenuItemUI>
      </ul>
    </div>
  );
};
