import { ChangeEventHandler, MouseEventHandler, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonUI } from "../../../../../components/Button";
import { LabelUI } from "../../../../../components/Label";
import { ModalUI } from "../../../../../components/Modal";
import { ModalFormGroupUI } from "../../../../../components/MordalFormGroup";
import { RadioUI } from "../../../../../components/Radio";
import { ValidatableInputUI } from "../../../../../components/ValidatableInput";
import { Level } from "../../../../../generated/graphql";
import { preventFromSubmittingByEnter } from "../../../../../lib/functions";
import { LevelNew } from "../../../../../lib/types";

export enum LevelEditMode {
  CREATE = "create",
  UPDATE = "update",
}

enum LevelStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

type Props = {
  visibility: boolean;
  onCreate?: (name: string, status: boolean) => Promise<void>;
  onUpdate?: (id: string, name: string, status: boolean) => Promise<void>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  editMode: LevelEditMode;
  level?: Level;
};

export const LevelEditModalUI = ({
  visibility,
  onCreate,
  onUpdate,
  onCancel,
  editMode,
  level,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LevelNew>({
    defaultValues: {
      name: level?.name,
    },
  });
  const [levelName, setLevelName] = useState<string>(
    editMode === LevelEditMode.UPDATE ? level!.name : ""
  );
  const [levelStatus, setLevelStatus] = useState<string>(
    editMode === LevelEditMode.UPDATE && level!.status === false
      ? LevelStatus.INACTIVE
      : LevelStatus.ACTIVE
  );

  const onStatusChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setLevelStatus(event.target.value);
  };

  const onChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
    setLevelName(event.target.value);
  };

  const onCancelProxy: MouseEventHandler<HTMLButtonElement> = (event) => {
    onCancel(event);
  };

  const onHandleSubmit: SubmitHandler<LevelNew> = () => {
    if (editMode === LevelEditMode.CREATE) {
      onCreate!(levelName, levelStatus === LevelStatus.ACTIVE);
    } else if (editMode === LevelEditMode.UPDATE) {
      onUpdate!(level!.id, levelName, levelStatus === LevelStatus.ACTIVE);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      onKeyDown={(event) => preventFromSubmittingByEnter(event)}
    >
      <ModalUI
        data-testid="level-edit-modal"
        visibility={visibility}
        className="w-full"
      >
        <div className="inline-block pt-4 pb-10 px-3 text-center">
          <h1
            className="text-left text-2xl tracking-wider text-subtitle"
            data-testid="level-edit-modal-title"
          >
            項目{editMode === LevelEditMode.CREATE ? "追加" : "編集"}
          </h1>
          <hr className="border-under-line mt-1"></hr>
          <div
            className="mt-4 text-2xl tracking-wider"
            data-testid="level-edit-description"
          >
            <div className="whitespace-pre-wrap"></div>
          </div>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">コード</LabelUI>
              <p data-testid="level-system-code">
                {editMode === LevelEditMode.CREATE
                  ? "自動で採番されます。"
                  : level?.systemId}
              </p>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start space-x-2.5">
              <LabelUI className="flex pt-3.5 w-32">レベル名</LabelUI>
              <div className="text-left w-10/12">
                <ValidatableInputUI
                  data-testid="level-edit-name"
                  errorIdPrefix="level-edit-name-error"
                  registerParams={{
                    register,
                    error: errors.name,
                    label: "name",
                    conditions: {
                      required: {
                        value: true,
                        message: "レベル名は必須項目です。",
                      },
                      maxLength: {
                        value: 20,
                        message: "２０文字以内で入力してください。",
                      },
                    },
                  }}
                  className="w-10/12"
                  placeholder="レベル名を入力してください。"
                  id="name"
                  name="name"
                  value={levelName}
                  onChange={onChangeName}
                />
              </div>
            </div>
          </ModalFormGroupUI>
          <ModalFormGroupUI>
            <div className="flex justify-start items-center space-x-2.5">
              <LabelUI className="flex w-32">ステータス</LabelUI>
              <div className="flex space-x-10">
                {[
                  { label: "有効", value: LevelStatus.ACTIVE },
                  { label: "無効", value: LevelStatus.INACTIVE },
                ].map((status) => (
                  <RadioUI
                    data-testid="level-edit-status"
                    key={status.value}
                    id={`status-${status.value}`}
                    name="status"
                    value={status.value}
                    checked={levelStatus === status.value}
                    onChange={onStatusChange}
                  >
                    {status.label}
                  </RadioUI>
                ))}
              </div>
            </div>
          </ModalFormGroupUI>
          <div className="flex justify-center space-x-10 mt-9">
            <ButtonUI
              data-testid="level-edit-cancel"
              type="button"
              buttonType="secondary"
              onClick={onCancelProxy}
            >
              キャンセル
            </ButtonUI>
            <ButtonUI data-testid="level-edit-submit" type="submit">
              {editMode === LevelEditMode.CREATE && "登録"}
              {editMode === LevelEditMode.UPDATE && "更新"}
            </ButtonUI>
          </div>
        </div>
      </ModalUI>
    </form>
  );
};
