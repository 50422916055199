import React, { InputHTMLAttributes } from "react";

import { RegisterParams } from "../common-interfaces";
import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";

/** @deprecated 代わりに studypf-b2b-frontend/src/components/ValidatableInput/NewValidatableInput.tsx を使用してください */
export type ValidatableInputUIProps = {
  registerParams: RegisterParams;
  errorIdPrefix?: string;
} & InputHTMLAttributes<HTMLInputElement>;

/** @deprecated 代わりに studypf-b2b-frontend/src/components/ValidatableInput/NewValidatableInput.tsx を使用してください */
export const ValidatableInputUI: React.FC<ValidatableInputUIProps> = ({
  className = "",
  registerParams,
  errorIdPrefix,
  children,
  ...rest
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center space-x-3">
        {registerParams.error && (
          <ErrorCheckIcon data-testid={`${errorIdPrefix}-icon`} />
        )}
        <input
          className={`h-12 leading-5 pl-4 tracking-wider border rounded ${
            className ? className : ""
          } ${registerParams.error ? "border-error" : ""}`}
          {...registerParams.register(registerParams.label, {
            ...registerParams.conditions,
          })}
          {...rest}
        />
      </div>
      {registerParams.error && (
        <p
          className="text-error mt-1.5 ml-8"
          data-testid={`${errorIdPrefix}-message`}
        >
          {registerParams.error.message}
        </p>
      )}
    </div>
  );
};
