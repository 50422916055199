import React, { HTMLAttributes } from "react";

import StatusBlock, { Status } from "../../../../components/StatusBlock";
import { ContactStatus } from "../../../../generated/graphql";
export type StatusBadgeUIProps = {
  status?: ContactStatus | undefined | null;
} & HTMLAttributes<HTMLSpanElement>;

const contactStatusToBlockStatus = {
  [ContactStatus.Unsupported]: "failed" as Status,
  [ContactStatus.InProgress]: "particalSuccess" as Status,
  [ContactStatus.Supported]: "success" as Status,
};

export const StatusBadgeUI: React.FC<StatusBadgeUIProps> = ({
  children,
  className,
  status = ContactStatus.Unsupported,
}) => {
  return (
    <StatusBlock
      status={contactStatusToBlockStatus[status ?? ContactStatus.Unsupported]}
      className={`w-12 h-6 ${className}`}
    >
      {children}
    </StatusBlock>
  );
};
