import React, { HTMLAttributeAnchorTarget, HTMLAttributes } from "react";

import { LocationDescriptor } from "history";
import { Link } from "react-router-dom";

export type LinkTextUIProps = {
  to: LocationDescriptor;
  target?: HTMLAttributeAnchorTarget;
} & HTMLAttributes<HTMLElement>;

const LinkTextUI: React.FC<LinkTextUIProps> = ({
  to,
  children,
  className,
  onFocus,
  onFocusCapture,
  onBlur,
  onBlurCapture,
  onKeyDown,
  onKeyDownCapture,
  onKeyPress,
  onKeyPressCapture,
  onKeyUp,
  onKeyUpCapture,
  target = "_self",
  ...rest
}) => {
  return (
    <Link
      className={`text-blue hover:underline active:text-blue-active active:underline ${className}`}
      to={to}
      target={target}
      referrerPolicy={target === "_blank" ? "no-referrer" : ""}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default LinkTextUI;
