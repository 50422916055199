import React, { MouseEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CsvDownloadButtonUI } from "../../../components/CsvDownloadButton";
import LinkTextUI from "../../../components/LinkText/LinkText";
import { CustomerBillingSearchResultsFragment } from "../../../generated/graphql";
import { BillingCustomersTableUI } from "./components/BillingCustomersTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "月額会員照会",
  },
];

export type CustomerBillingSearchResultsProps = {
  billingCustomers: CustomerBillingSearchResultsFragment;
  hasAdminAuthority: boolean;
  onDisplayMore: MouseEventHandler;
  toSearchPage: MouseEventHandler;
  onClickDownload: () => void;
};

const CustomerBillingSearchResults: React.VFC<
  CustomerBillingSearchResultsProps
> = ({
  billingCustomers,
  hasAdminAuthority,
  onDisplayMore,
  toSearchPage,
  onClickDownload,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const { nodes, totalCount } = billingCustomers;

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">月額会員照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <div className="ml-6">
        <p className="mt-8 font-bold text-xl">
          検索結果｜
          <LinkTextUI
            data-testid="to-search-page-link"
            to="#"
            onClick={toSearchPage}
          >
            検索画面へ
          </LinkTextUI>
        </p>
        {totalCount > 0 ? (
          <>
            <div className="flex justify-between mt-5 mb-1.5 items-end">
              <span data-testid="customer-billing-search-results-total-count">
                全{totalCount}件中{nodes.length}件を表示
              </span>
              {hasAdminAuthority && (
                <CsvDownloadButtonUI
                  disabled={totalCount > 1000}
                  onClick={onClickDownload}
                />
              )}
            </div>
            <div className="overflow-x-auto">
              <BillingCustomersTableUI billingCustomers={billingCustomers} />
            </div>
            {totalCount !== nodes.length && (
              <button
                data-testid="billing-customer-search-results-display-more-btn"
                className="border border-dark-gray text-blue hover:underline active:text-blue-active active:underline w-full h-8 mt-5"
                onClick={onDisplayMore}
              >
                もっと表示する
              </button>
            )}
          </>
        ) : (
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="billing-customer-search-results-no-customer-exists-message"
              >
                該当する生徒がいません。
              </p>
              <p>検索条件に該当する生徒がいませんでした。</p>
              <p>内容をご確認のうえ、再度検索を行ってください。</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerBillingSearchResults;
