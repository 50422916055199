import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useHistory, useLocation } from "react-router-dom";

import { SelectModalUI } from "../../../components/SelectModal";
import {
  PublishClazzFragment,
  PublishCoursesItemFragment,
  usePublishCoursesQuery,
  useUpdateClassPublicationSettingMutation,
  useCreateCoursePreviewTokenMutation,
  useDeleteCoursePreviewTokenMutation,
  PublishCoursesFragment,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { searchSettings } from "../../../lib/constants";
import { ErrorType } from "../../../lib/constants/error";
import { PublishModalUI } from "./components/PublishModal";
import PublicationsPage from "./PublicationsPage";

const PublishPageContainer: React.VFC = () => {
  const [updateClassPublicationSettingMutation] =
    useUpdateClassPublicationSettingMutation();
  const [createCoursePreviewTokenMutation] =
    useCreateCoursePreviewTokenMutation();
  const [deleteCoursePreviewTokenMutation] =
    useDeleteCoursePreviewTokenMutation();

  const [clazz, setClazz] = useState<UpdateDateAndStatus>();

  // URLの制御
  const errorRouter = useErrorRouter();

  // 検索ワード
  const history = useHistory();
  const location = useLocation();
  const state = location.state as string;
  const [searchWord, setSearchWord] = useState<string>(state ?? "");
  const [inputtingKeyword, setInputtingKeyword] = useState<string>(searchWord);

  // 表示件数
  const [limit, setLimit] = useState(searchSettings.limit);
  const [offset, setOffset] = useState(0);

  // コース一覧
  const [courses, setCourses] = useState<PublishCoursesFragment>({
    totalCount: 0,
    nodes: [],
  });

  // 公開モーダル
  const [publishModalVisibility, setPublishModalVisibility] =
    useState<boolean>(false);
  const [sortModalVisibility, setSortModalVisibility] =
    useState<boolean>(false);

  const { data, loading, refetch } = usePublishCoursesQuery({
    variables: {
      filter: {
        searchWord,
      },
      offset,
      limit,
    },
    fetchPolicy: "no-cache",
    onError: errorRouter,
  });

  useEffect(() => {
    if (data?.courses) {
      setCourses(data?.courses);
    }
  }, [data?.courses]);

  // 検索アイコン押下時のハンドラー
  const onSearch: FormEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setOffset(0);
      await refetch({
        filter: {
          searchWord: inputtingKeyword,
        },
        offset: 0,
        limit,
      });
      setSearchWord(inputtingKeyword);
    },
    [refetch, inputtingKeyword, limit]
  );

  // 検索ワード変更時のハンドラー
  const onChangeSearchFilterHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        const keyword: string = event.target.value;
        if (inputtingKeyword !== keyword) setInputtingKeyword(keyword);
      },
      [inputtingKeyword]
    );

  // 検索欄の×ボタンを押下した時のハンドラー
  const onClickDeleteHandler: MouseEventHandler = useCallback(() => {
    setInputtingKeyword("");
    setSearchWord("");
    setOffset(0);
  }, []);

  // 「もっと表示する」を押下時のハンドラー
  const onDisplayMore = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter: {
        searchWord: inputtingKeyword,
      },
      limit: limit + searchSettings.offset,
    });
  }, [refetch, inputtingKeyword, limit]);

  const onOpenPublishModal = useCallback(
    (courseId: string, clazz: PublishClazzFragment) => {
      const { id, saleStartedAt, saleEndedAt, published, lessons } = clazz;
      const beforePublished =
        courses.nodes.find((course) => course.id === courseId)?.published ||
        false;
      setClazz({
        id,
        saleStartedAt: saleStartedAt!,
        saleEndedAt: saleEndedAt!,
        published: published!,
        lessons: lessons!,
        beforePublished,
      });
      setPublishModalVisibility(true);
    },
    [setClazz, setPublishModalVisibility, courses]
  );

  // プレビュー公開のON/OFF切り替え時のハンドラー
  const changePreviewToggle = async (course: PublishCoursesItemFragment) => {
    try {
      if (course.previewToken?.id === undefined) {
        // Token情報を保持していない場合は作成
        await createCoursePreviewTokenMutation({
          variables: {
            courseId: course.id,
          },
        });
      } else {
        // Token情報を保持している場合は削除
        await deleteCoursePreviewTokenMutation({
          variables: {
            id: course.previewToken.id,
          },
        });
      }
      // 再検索する
      await refetch({
        filter: {
          searchWord: inputtingKeyword,
        },
        limit: limit,
      });
    } catch (error: any) {
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    }
  };

  const updateClazz = useCallback(
    (clazz: UpdateDateAndStatus) => {
      setClazz(clazz);
    },
    [setClazz]
  );

  const onHandleSubmit = async () => {
    try {
      const result = await updateClassPublicationSettingMutation({
        variables: {
          input: {
            id: clazz!.id,
            saleStartedAt: clazz!.saleStartedAt!,
            saleEndedAt: clazz!.saleEndedAt!,
            published: clazz!.published!,
          },
        },
      });
      await refetch({
        filter: {
          searchWord: inputtingKeyword,
        },
        limit: limit + searchSettings.offset,
      });
      if (
        !clazz!.beforePublished &&
        result.data?.updateClassAndLessons?.course?.published
      ) {
        setSortModalVisibility(true);
      }
    } catch (error: any) {
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    } finally {
      setPublishModalVisibility(false);
    }
  };

  return (
    <>
      <PublicationsPage
        loading={loading}
        courses={courses}
        searchFilter={inputtingKeyword}
        offset={offset}
        onSearch={onSearch}
        onChangeSearchFilterHandler={onChangeSearchFilterHandler}
        onClickDeleteHandler={onClickDeleteHandler}
        onDisplayMore={onDisplayMore}
        onOpenPublishModal={onOpenPublishModal}
        changePreviewToggle={changePreviewToggle}
      />
      <PublishModalUI
        title="公開管理"
        clazz={clazz!}
        onCancel={() => {
          setPublishModalVisibility(false);
        }}
        updateClazz={updateClazz}
        onSubmit={onHandleSubmit}
        visibility={publishModalVisibility}
      />
      <SelectModalUI
        title="公開完了"
        leftBtnTitle="閉じる"
        rightBtnTitle="サービス並び替えへ"
        onLeftButtonClick={() => {
          setSortModalVisibility(false);
        }}
        onRightButtonClick={() => {
          history.push("/courses/sort");
        }}
        visibility={sortModalVisibility}
      >
        公開が完了しました。
        <br />
        公開されたサービスはTOP画面の一番下に表示
        <br />
        されています。
        <br />
        並び順を変更する場合はサービス並び替え画面で
        <br />
        操作してください。
      </SelectModalUI>
    </>
  );
};

export default PublishPageContainer;
