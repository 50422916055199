import React, { useCallback, useMemo } from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useHistory } from "react-router-dom";

import { ButtonUI } from "../../../components/Button";
import { ReactComponent as IconLesson } from "../../../components/lessonIcon.svg";
import { Discount, DiscountType } from "../../../generated/graphql";
import styles from "./DiscountComfirm.module.css";
import { InternalDiscountCourse } from "./DiscountEdit";

dayjs.extend(utc);
dayjs.extend(tz);

export type DiscountConfirmUIProps = {
  isNew: boolean;
  discount: Omit<Discount, "id" | "priceValue">;
  limitedCourses?: InternalDiscountCourse[];
  onSubmit: () => void;
};

const DiscountConfirmUI: React.FC<DiscountConfirmUIProps> = ({
  isNew,
  discount,
  limitedCourses,
  onSubmit,
}) => {
  const history = useHistory();

  const discountTypeLable: string = useMemo((): string => {
    switch (discount.type) {
      case DiscountType.Coupon:
        return "クーポン（従量）";
      case DiscountType.CourseCoupon:
        return "コース限定クーポン（従量）";
      case DiscountType.InitialCoupon:
        return "初月割引クーポン（月額）";
      case DiscountType.PlanCoupon:
        return "クーポン（月額）";
      default:
        return "";
    }
  }, [discount.type]);

  const formatDate = useCallback((date: Date): string => {
    if (!date) {
      return "";
    }
    return dayjs(date).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm");
  }, []);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-2" data-testid="discount-confirm-title">
        {isNew ? "クーポン登録確認画面" : "クーポン更新確認画面"}
      </h1>
      <hr className="border-gray border mb-2"></hr>

      <div className="">
        {/* FormGroupUI を使用すると配置がデザイン通りにならないので使用しない */}
        <span className={`${styles.confirmAnnotation}`}>
          下記の内容でクーポンを登録します。
        </span>
        <div>
          {/* 種別 */}
          <div className="flex flex-col align-middle mt-5">
            <p className={`flex ${styles.confirmLable}`}>種別</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-discountType"
            >
              {discountTypeLable}
            </p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* 対象コース */}
          {discount.type === DiscountType.CourseCoupon && (
            <>
              <div className="flex flex-col align-middle mt-5">
                <p className={`flex ${styles.confirmLable}`}>対象コース</p>

                {limitedCourses && limitedCourses.length && (
                  <ul
                    className={`${styles.confirmValue}`}
                    data-testid="discount-confirm-limitedCourses"
                  >
                    {limitedCourses.map((c) => {
                      return (
                        <li
                          className={`align-middle`}
                          key={c.id}
                          data-testid={`discount-confirm-limitedCourse-${c.id}`}
                        >
                          <IconLesson
                            width="16px"
                            height="16px"
                            className="inline-block mr-2"
                          />
                          <span
                            className="mr-1"
                            data-testid={`discount-confirm-limitedCourse-${c.id}-systemId`}
                          >
                            {c.systemId}
                          </span>
                          <span className="mr-1">：</span>
                          <span
                            className="mr-1"
                            data-testid={`discount-confirm-limitedCourse-${c.id}-name`}
                          >
                            {c.name}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <hr className="border-gray border mt-5"></hr>
            </>
          )}

          {/* クーポンコード */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>クーポンコード</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-code"
            >
              {discount.code}
            </p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* クーポン名 */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>クーポン名</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-name"
            >
              {discount.name}
            </p>{" "}
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* 発効日時 */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>発効日時</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-startedAt"
            >
              {formatDate(discount.startedAt)}
            </p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* 終了日時 */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>終了日時</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-endedAt"
            >
              {formatDate(discount.endedAt)}
            </p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* 割引期間（月） */}
          {discount.type === DiscountType.PlanCoupon && (
            <>
              <div className="flex flex-col align-middle mt-6 ml-0">
                <p className={`flex ${styles.confirmLable}`}>割引期間</p>
                <p
                  className={`${styles.confirmValue}`}
                  data-testid="discount-confirm-durationMonths"
                >
                  {discount.durationMonths}ヶ月
                </p>
              </div>
              <hr className="border-gray border mt-5"></hr>
            </>
          )}

          {/* 割引金額 */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>割引金額</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-priceValueNoTax"
            >
              {`${Intl.NumberFormat("ja-JP").format(
                discount.priceValueNoTax
              )} 円（税抜）`}
            </p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          {/* 社内メモ */}
          <div className="flex flex-col align-middle mt-6 ml-0">
            <p className={`flex ${styles.confirmLable}`}>社内メモ</p>
            <p
              className={`${styles.confirmValue}`}
              data-testid="discount-confirm-description"
              dangerouslySetInnerHTML={{
                __html: discount.description
                  ? discount.description.replace(/\n/g, "<br/>")
                  : "",
              }}
            ></p>
          </div>
          <hr className="border-gray border mt-5"></hr>

          <div className="flex  w-full justify-center mt-6 space-x-4">
            <ButtonUI
              data-testid="discount-confirm-btn-back"
              type="button"
              size="l"
              buttonType="secondary"
              style={{ width: "120px", height: "40px" }}
              onClick={() => {
                history.goBack();
              }}
            >
              戻る
            </ButtonUI>
            <ButtonUI
              data-testid="discount-confirm-btn-submit"
              type="button"
              size="l"
              buttonType="primary"
              style={{ width: "120px", height: "40px" }}
              onClick={onSubmit}
            >
              {isNew ? "登録" : "更新"}
            </ButtonUI>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountConfirmUI;
