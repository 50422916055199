export enum PageType {
  ContentPage = "ContentPage",
  ContentsNewConfirmPage = "ContentsNewConfirmPage",
  ContentsEditConfirmPage = "ContentsEditConfirmPage",
  ContentNewPage = "ContentNewPage",
  ContentEditPage = "ContentEditPage",
  UsersConfirmPage = "UsersConfirmPage",
  ProfileConfirmPage = "ProfileConfirmPage",
  CoursesNewPage = "CoursesNewPage",
  CoursesEditPage = "CoursesEditPage",
  CoursePage = "CoursePage",
  CoursesNewConfirmPage = "CoursesNewConfirmPage",
  CoursesEditConfirmPage = "CoursesEditConfirmPage",
  CourseEditPage = "CourseEditPage",
  ClassNewPage = "ClassNewPage",
  ClassNewConfirmPage = "ClassNewConfirmPage",
  ClassEditConfirmPage = "ClassEditConfirmPage",
  ClassEditPage = "ClassEditPage",
  ClassPage = "ClassPage",
  UsersExternalNewPage = "UsersExternalNewPage",
  UsersExternalNewConfirmPage = "UsersExternalNewConfirmPage",
  ProfileEditPage = "ProfileEditPage",
  UsersEditPage = "UsersEditPage",
  ProfileEditConfirmPage = "ProfileEditConfirmPage",
  UsersEditConfirmPage = "UsersEditConfirmPage",
  ProfilePage = "ProfilePage",
  UserPage = "UserPage",
  ContactsNewPage = "ContactsNewPage",
  CustomerSearchResultsPage = "CustomerSearchResultsPage",
}
