import React, { HTMLAttributes } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { TextFieldUI } from "../../../../components/TextField";
dayjs.extend(utc);
dayjs.extend(timezone);

export type ExpertDetailProps = {
  expert: {
    familyName: string;
    givenName: string;
    familyNameKana: string;
    givenNameKana: string;
    company?: string | null;
    position?: string | null;
    title?: string | null;
    career?: string | null;
    alias?: string | null;
    profilePhoto?: string | null;
    headshot?: string | null;
    alt: string;
    publishedAt: Date;
    closedAt?: Date | null;
    booksListText?: string | null;
    siteUrl?: string | null;
    twitterUrl?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;
    youtubeUrl?: string | null;
    wikipediaUrl?: string | null;
  };
} & HTMLAttributes<HTMLDivElement>;

const TextFieldPClassName = "text-base";

const ExpertDetail: React.VFC<ExpertDetailProps> = ({
  expert,
  className,
  ...rest
}) => {
  return (
    <div className={`space-y-5 ${className}`} {...rest}>
      <TextFieldUI
        testIdPrefix="expert-name"
        label="有識者名"
        value={`${expert.familyName}　${expert.givenName}`}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-name-kana"
        label="有識者名(フリガナ)"
        value={`${expert.familyNameKana}　${expert.givenNameKana}`}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-alias"
        label="URL表記"
        value={expert.alias}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-company"
        label="所属"
        value={expert.company}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-position"
        label="役職"
        value={expert.position}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-title"
        label="肩書き"
        value={expert.title}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-career"
        label="プロフィール"
        value={expert.career}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-books-list-text"
        label="著作・共著"
        value={expert.booksListText?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-site-url"
        label="関連リンク　公式サイトURL"
        value={expert.siteUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-twitter-url"
        label="関連リンク　Twitter URL"
        value={expert.twitterUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-facebook-url"
        label="関連リンク　Facebook URL"
        value={expert.facebookUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-instagram-url"
        label="関連リンク　Instagram URL"
        value={expert.instagramUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-youtube-url"
        label="関連リンク　Youtube URL"
        value={expert.youtubeUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <TextFieldUI
        testIdPrefix="expert-wikipedia-url"
        label="関連リンク　Wikipedia URL"
        value={expert.wikipediaUrl?.trim()}
        pClassName={TextFieldPClassName}
      />
      <div className="pb-4 border-b border-under-line">
        <label className="text-lg font-bold">プロフィール画像</label>
        {expert.profilePhoto && (
          <img
            data-testid="expert-profilePhoto-img"
            src={expert.profilePhoto}
            width={188}
            height={132}
            className="mt-2.5"
            alt={expert.alt}
          />
        )}
      </div>
      <div className="pb-4 border-b border-under-line">
        <label className="text-lg font-bold">アイコン画像</label>
        {expert.headshot && (
          <img
            data-testid="expert-headshot-img"
            src={expert.headshot}
            width={140}
            height={140}
            className="mt-2.5 ml-6"
            alt={expert.alt}
          />
        )}
      </div>
      <TextFieldUI
        testIdPrefix="expert-publish-date"
        label="公開設定"
        value={`${dayjs(expert.publishedAt)
          .tz("Asia/Tokyo")
          .format("YYYY年MM月DD日HH:mm")} ~ ${
          expert.closedAt
            ? dayjs(expert.closedAt)
                .tz("Asia/Tokyo")
                .format("YYYY年MM月DD日HH:mm")
            : ""
        }`}
        pClassName={TextFieldPClassName}
      />
    </div>
  );
};

export default ExpertDetail;
