import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CourseEditUI } from "../../../components/CourseEdit";
import {
  CourseDetailCategory,
  CourseImage,
  CoursePriceInput,
  CourseTargetType,
  CreateCourseDetailInput,
  LevelFragment,
  OrganizerFragment,
  SubjectSelectorItemFragment,
} from "../../../generated/graphql";
import {
  courseDetailSortOrder,
  targetTypes,
} from "../../../lib/constants/courses";
import { PageType } from "../../../lib/constants/pageType";
import { SelectType } from "../../../lib/constants/SelectType";
import { ValidatedFile } from "../../../lib/types";
import {
  CourseEdit,
  CourseNew,
  CourseQuestionnaire,
} from "../../../lib/types/course";
import { LessonMasterEdit } from "../../../lib/types/lessonMaster";
import {
  getInitialQuestionnaireData,
  getQuestionnaireTypeByString,
  getConditionByQuestionnaireInfo,
  getSelectableNumberByQuestionnaireInfo,
} from "../../../lib/utils/questionnaires";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "コース追加",
  },
];

type CoursesNewPageProps = {
  loadingMasters: boolean;
  editingNewCourse?: CourseNew;
  organizers?: OrganizerFragment[];
  subjects: SubjectSelectorItemFragment[];
  levels: LevelFragment[];
};

const CoursesNewPage: React.FC<CoursesNewPageProps> = ({
  loadingMasters,
  editingNewCourse,
  organizers,
  subjects,
  levels,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [imageFiles, setImageFiles] = useState<ValidatedFile[]>(() => {
    if (
      editingNewCourse &&
      editingNewCourse.courseImages &&
      editingNewCourse.courseImages.length
    ) {
      return editingNewCourse.courseImages.map((f, idx) => {
        const v: ValidatedFile = Object.assign(f, {
          previewId: idx,
          url: "",
          isError: false,
          errSize: "",
          errExt: "",
          imageId: "",
        });
        return v;
      });
    }
    return [];
  });
  const [courseImages] = useState<CourseImage[]>(() => {
    if (
      editingNewCourse &&
      editingNewCourse.courseImages &&
      editingNewCourse.courseImages.length
    ) {
      return editingNewCourse.courseImages.map((f) => {
        const v: CourseImage = Object.assign({
          id: "",
          name: f.name,
          order: f.order,
          url: f.url,
        });
        return v;
      });
    }
    return [];
  });
  const [teachingMaterials, setTeachingMaterials] = useState<File[]>(() => {
    if (
      editingNewCourse &&
      editingNewCourse.teachingMaterials &&
      editingNewCourse.teachingMaterials.length
    ) {
      return editingNewCourse.teachingMaterials;
    }
    return [];
  });
  const [orderToLessonMaterialMap, setIndexToLessonMaterialMap] = useState<
    Map<number, File[]>
  >(() => {
    const lessonMasters = editingNewCourse?.lessonMasters ?? [];
    const map = new Map<number, File[]>();
    const existsLessonMasters = !!lessonMasters.some(
      (lessonMaster) => lessonMaster?.teachingMaterials?.length
    );
    if (!existsLessonMasters) {
      return map;
    }
    lessonMasters.forEach((lessonMaster) => {
      const materials = lessonMaster?.teachingMaterials ?? [];
      if (materials.length) {
        map.set(lessonMaster.order, materials);
      }
    });
    return map;
  });
  const editingNewCourseRef = useRef<CourseNew>();
  const [course, setCourse] = useState<CourseNew>(() => {
    if (editingNewCourse === undefined) {
      editingNewCourseRef.current = undefined;
    }
    if (editingNewCourseRef.current !== editingNewCourse) {
      editingNewCourseRef.current = editingNewCourse;
      if (editingNewCourse) {
        if (
          !editingNewCourse.questionnaires ||
          editingNewCourse.questionnaires.length === 0
        ) {
          editingNewCourse.questionnaires = [getInitialQuestionnaireData(1)];
        }
        return editingNewCourse;
      }
    }

    // コース追加画面初期表示時用データ
    return {
      systemId: "",
      name: "",
      courseId: "",
      targets: {
        type: CourseTargetType.None,
        from: null,
        to: null,
      },
      subjectEntity1: undefined,
      subjectEntity2: undefined,
      levelEntity: undefined,
      levelId: undefined,
      price: {
        type: null!,
        valueNoTax: null!,
      },
      overview: "",
      details: [
        {
          category: CourseDetailCategory.Description,
          description: "",
        },
        {
          category: CourseDetailCategory.Recommendation,
          description: "",
        },
        {
          category: CourseDetailCategory.Preparation,
          description: "",
        },
        {
          category: CourseDetailCategory.Lessonflow,
          description: "",
        },
        {
          category: CourseDetailCategory.Notes,
          description: "",
        },
      ],
      teachingMaterials: [],
      videos: [],
      lessonMasters: [
        {
          order: 1,
          name: "",
          teachingMaterials: [],
        },
      ],
      questionnaires: [getInitialQuestionnaireData(1)],
      questionnaireAnswered: false,
    };
  });

  const history = useHistory();

  type VideoUrlErrorType = {
    message: string;
  };
  const [videoUrlError, setVideoUrlError] = useState<
    boolean | VideoUrlErrorType
  >(false);

  const [errMaterialFiles, setErrMaterialFiles] = useState<string[]>([]);
  const onHandleSubmit: SubmitHandler<CourseEdit> = () => {
    if (imageFiles.find((imageFile) => imageFile.isError === true)) {
      return;
    }
    if (errMaterialFiles.length > 0) {
      return;
    }

    // 動画URLのバリデーション判定
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    if (
      course.videos !== null &&
      course.videos !== undefined &&
      course.videos.length > 0
    ) {
      if (course.videos[0].url !== "") {
        const matchPattern = regExp.test(course.videos[0].url);
        const match = course.videos[0].url.match(regExp);
        if (!matchPattern) {
          setVideoUrlError({ message: "正しいURLの形式で入力してください" });
          return;
        }
        if (match !== null && match[7].length !== 11) {
          setVideoUrlError({ message: "正しいURLの形式で入力してください" });
          return;
        }
      }
    }

    // タイトルが設定されていないアンケートを除外し、バックエンド用に差し替える
    const newQuestionnaires = course.questionnaires
      .filter(
        (questionnaire: CourseQuestionnaire) =>
          questionnaire.title !== "" && questionnaire.typenameDisplay !== ""
      )
      .map((questionnaire: CourseQuestionnaire) => {
        questionnaire.typename = getQuestionnaireTypeByString(
          questionnaire.typenameDisplay || ""
        );
        questionnaire.minLabel =
          questionnaire.minLabel === "" ? "低い" : questionnaire.minLabel;
        questionnaire.maxLabel =
          questionnaire.maxLabel === "" ? "高い" : questionnaire.maxLabel;
        questionnaire.condition =
          getConditionByQuestionnaireInfo(questionnaire);
        questionnaire.selectableNumber =
          getSelectableNumberByQuestionnaireInfo(questionnaire);
        return questionnaire;
      });
    course.questionnaires = newQuestionnaires;

    const courseNew: CourseNew = {
      ...course,
      courseImages: imageFiles.map((f, idx) => {
        return Object.assign(f, {
          id: "",
          order: idx,
          url: "",
        });
      }),
    };
    history.push(`/course/confirm`, {
      courseNew,
    });
  };

  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setCourse({
        ...course,
        [event.target.name]: event.target.value,
      });
    },
    [course]
  );

  const onChangeCustomSelect = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (name === SelectType.TargetFrom) {
        setCourse({
          ...course,
          targets: {
            type: course.targets?.type!,
            from: value,
            to: course.targets?.to,
          },
        });
      }
      if (name === SelectType.TargetTo) {
        setCourse({
          ...course,
          targets: {
            type: course.targets?.type!,
            from: course.targets?.from,
            to: value,
          },
        });
      }
    },
    [course]
  );

  const onOrganizerChange = useCallback(
    (organizer: OrganizerFragment | undefined) => {
      setCourse({
        ...course,
        organizer: organizer,
        organizerId: organizer ? organizer.id : null,
      });
    },
    [course]
  );

  const onSubject1Change = useCallback(
    (subject: SubjectSelectorItemFragment | undefined) => {
      setCourse({
        ...course,
        subjectEntity1: subject,
      });
    },
    [course]
  );

  const onSubject2Change = useCallback(
    (subject: SubjectSelectorItemFragment | undefined) => {
      setCourse({
        ...course,
        subjectEntity2: subject,
      });
    },
    [course]
  );

  const onLevelChange = useCallback(
    (level: LevelFragment | undefined) => {
      setCourse({
        ...course,
        levelEntity: level,
        levelId: level ? level.id : null,
      });
    },
    [course]
  );

  const onChangeInputDetail: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      const detail = course.details.find(
        (d) => d.category === event.target.name
      );
      if (detail === undefined) {
        course.details.push({
          category: event.target.name as CourseDetailCategory,
          description: event.target.value,
        });
      } else {
        detail.description = event?.target.value;
      }

      // フロントの UT で配列の中身が期待値と結果が同じになるようにするためだけにソートしている
      // バックエンドの処理では詳細の順番は全く関係ない
      const details: CreateCourseDetailInput[] = [];
      for (let i: number = 0; i < courseDetailSortOrder.length; i++) {
        const c = courseDetailSortOrder[i];
        const d = course.details.find((d) => d.category === c);
        if (d !== undefined) {
          details.push(d);
        }
      }

      setCourse({
        ...course,
        details: [...details],
      });
    },
    [course]
  );

  const onChangeLessonMasters = useCallback(
    (lessonMasters: LessonMasterEdit[]) => {
      setCourse({
        ...course,
        lessonMasters,
      });
    },
    [course]
  );

  const onChangePrice = useCallback(
    (price: CoursePriceInput) => {
      setCourse({
        ...course,
        price,
      });
    },
    [course]
  );

  const onChangeRadioType: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const targetType = targetTypes.find(
        (item) => item.value === event.target.value
      );
      setCourse({
        ...course,
        targets: { type: targetType?.value! },
      });
    },
    [course]
  );

  const onChangeVideoInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      setCourse({
        ...course,
        videos: [
          {
            id:
              course.videos !== null &&
              course.videos !== undefined &&
              course.videos.length > 0
                ? course.videos[0].id
                : "",
            url: event.target.value,
          },
        ],
      });
    },
    [course]
  );

  const onChangeErrMaterialFiles = useCallback((files: string[]) => {
    setErrMaterialFiles([...files]);
  }, []);

  const onChangeMaterialFile = useCallback(
    (file: File) => {
      setCourse({
        ...course,
        teachingMaterials: [file, ...course.teachingMaterials!],
      });
      setTeachingMaterials([file, ...course.teachingMaterials!]);
    },
    [course]
  );

  const onChangeLessonMasterMaterialFile = useCallback(
    (targetOrder: number, file: File) => {
      const value = orderToLessonMaterialMap.get(targetOrder);
      setIndexToLessonMaterialMap(
        orderToLessonMaterialMap.set(targetOrder, [file, ...(value ?? [])])
      );

      // アップロード用をセット
      const { lessonMasters } = course;
      lessonMasters.map((lessonMaster) => {
        if (lessonMaster.order !== targetOrder) {
          return lessonMaster;
        }
        lessonMaster.teachingMaterials = [
          file,
          ...(lessonMaster?.teachingMaterials ?? []),
        ];
        return lessonMaster;
      });
      setCourse({
        ...course,
        lessonMasters,
      });
    },
    [course, orderToLessonMaterialMap]
  );

  const onChangeCourseImages = useCallback((files: ValidatedFile[]) => {
    setImageFiles(files);
  }, []);

  const onDeleteMaterialFile = useCallback(
    (removeFileName: string) => {
      setCourse({
        ...course,
        teachingMaterials: [
          ...course.teachingMaterials!.filter(
            (item: File) => item.name !== removeFileName
          ),
        ],
      });
    },
    [course]
  );

  const onDeleteLessonMasterMaterialFile = useCallback(
    (targetOrder: number, removeFileName: string) => {
      const { lessonMasters } = course;
      lessonMasters.map((lessonMaster) => {
        const materials = lessonMaster.teachingMaterials;
        if (!(lessonMaster.order === targetOrder && materials)) {
          return lessonMaster;
        }
        lessonMaster.teachingMaterials = materials.filter(
          (material) => material.name !== removeFileName
        );
        return lessonMaster;
      });

      // アップロード用
      // courseEdit.lessonMasters.teachingMaterialsにあるファイルがアップロード対象
      setCourse({
        ...course,
        lessonMasters,
      });
    },
    [course]
  );

  const handleBack = useCallback(() => {
    history.push(`/courses`);
  }, [history]);

  const onChangeQuestionnaires = useCallback(
    (questionnaires: CourseQuestionnaire[]) => {
      setCourse({
        ...course,
        questionnaires,
      });
    },
    [course]
  );

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">コース追加</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <CourseEditUI
          pageType={PageType.CoursesNewPage}
          loadingMasters={loadingMasters}
          organizers={organizers}
          subjects={subjects}
          levels={levels}
          course={course}
          courseImages={courseImages}
          editingCourseImageFiles={imageFiles}
          editingTeachingMaterialFiles={teachingMaterials}
          onHandleSubmit={onHandleSubmit}
          onChangeInput={onChangeInput}
          onChangeVideoInput={onChangeVideoInput}
          onChangeCustomSelect={onChangeCustomSelect}
          onOrganizerChange={onOrganizerChange}
          onSubject1Change={onSubject1Change}
          onSubject2Change={onSubject2Change}
          onLevelChange={onLevelChange}
          onChangeInputDetail={onChangeInputDetail}
          onChangeLessonMasters={onChangeLessonMasters}
          onChangePrice={onChangePrice}
          onChangeRadioType={onChangeRadioType}
          onChangeMaterialFile={onChangeMaterialFile}
          onChangeErrMaterialFiles={onChangeErrMaterialFiles}
          onDeleteMaterialFile={onDeleteMaterialFile}
          onChangeLessonMasterMaterialFile={onChangeLessonMasterMaterialFile}
          onDeleteLessonMasterMaterialFile={onDeleteLessonMasterMaterialFile}
          handleBack={handleBack}
          onChangeCourseImages={onChangeCourseImages}
          onChangeQuestionnaires={onChangeQuestionnaires}
          videoUrlError={videoUrlError}
        ></CourseEditUI>
      </div>
    </div>
  );
};

export default CoursesNewPage;
