import React, { useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useHistory } from "react-router-dom";

import DownloadCsv, { CsvLinkProps } from "../../../components/DownloadCsv";
import { LoadingUI } from "../../../components/Loading";
import {
  LiberalArtsVideoLessonRatingCsvQuery,
  useLiberalArtsVideoLessonRatingCsvLazyQuery,
  useLiberalArtsVideoLessonRatingPageQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { genders } from "../../../lib/constants";
import { ErrorType } from "../../../lib/constants/error";
import LiberalArtsVideoLessonRating from "./LiberalArtsVideoLessonRatingPage";
dayjs.extend(utc);
dayjs.extend(tz);

type CsvData = {
  index: string;
  videoLessonId: string;
  videoLessonName: string;
  jStreamMId: string;
  videoName: string;
  rating: string;
  freeTextValue: string;
  customerId: string;
  gender: string;
  age: string;
  occupation: string;
  industry: string;
  jobTitle: string;
  position: string;
  annualIncome: string;
  createdAt: string;
};

const headers = [
  { label: "項番", key: "index" },
  { label: "シリーズID", key: "videoLessonId" },
  { label: "シリーズ名", key: "videoLessonName" },
  { label: "動画ID", key: "jStreamMId" },
  { label: "動画名", key: "videoName" },
  { label: "評価", key: "rating" },
  { label: "評価コメント", key: "freeTextValue" },
  { label: "カスタマーID", key: "customerId" },
  { label: "性別", key: "gender" },
  { label: "年齢", key: "age" },
  { label: "職業", key: "occupation" },
  { label: "業種", key: "industry" },
  { label: "職種", key: "jobTitle" },
  { label: "役職", key: "position" },
  { label: "年収", key: "annualIncome" },
  { label: "回答年月日", key: "createdAt" },
];

const LiberalArtsVideoLessonRatingPage: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const { data, error, loading } = useLiberalArtsVideoLessonRatingPageQuery({
    fetchPolicy: "no-cache",
  });

  const [csvData, setCsvData] = useState<CsvData[]>();
  const [fetchCSVData, { loading: loadingCSVQuery }] =
    useLiberalArtsVideoLessonRatingCsvLazyQuery({
      onCompleted: (data) => {
        const replacedData = replaceToCsvData(data);
        setCsvData(replacedData);
      },
      onError: errorRouter,
    });

  /**
   * graphql → CSV用のオブジェクトへ変換
   */
  const replaceToCsvData = useCallback(
    (queryData: LiberalArtsVideoLessonRatingCsvQuery): CsvData[] => {
      return queryData.videoLessonRatingDetails.nodes.map(
        (detail, index): CsvData => {
          const {
            customer,
            video,
            videoLesson,
            freeTextValue,
            rating,
            createdAt,
          } = detail;
          return {
            index: `${index + 1}`,
            videoLessonId: videoLesson.id,
            videoLessonName: videoLesson.name,
            jStreamMId: video.jStreamMId,
            videoName: video.name,
            rating: `${rating}`,
            freeTextValue: freeTextValue || "",
            customerId: customer.id,
            gender:
              genders.find(
                (gender) => gender.value === customer.profile?.gender
              )?.label || "",
            age: `${customer.profile?.age}` || "",
            occupation: customer.profile?.userAttribute?.occupation || "",
            industry: customer.profile?.userAttribute?.industry || "",
            jobTitle: customer.profile?.userAttribute?.jobTitle || "",
            position: customer.profile?.userAttribute?.position || "",
            annualIncome: customer.profile?.userAttribute?.annualIncome || "",
            createdAt: dayjs(createdAt).tz("Asia/Tokyo").format("YYYY/MM/DD"),
          };
        }
      );
    },
    []
  );

  const csvLinkProps: CsvLinkProps | undefined = useMemo(() => {
    // validate
    if (loadingCSVQuery || !csvData) {
      return undefined;
    }

    return {
      filename: `受講後アンケート結果_${dayjs().format("YYYYMMDD")}.csv`,
      headers: headers,
      data: csvData,
    };
  }, [csvData, loadingCSVQuery]);

  const history = useHistory();
  if (error) {
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
  }
  if (loading || !data) {
    return <LoadingUI />;
  }
  return (
    <>
      <LiberalArtsVideoLessonRating
        data={data}
        onClickDownload={fetchCSVData}
      />
      <DownloadCsv csvLinkProps={csvLinkProps} />
    </>
  );
};

export default LiberalArtsVideoLessonRatingPage;
