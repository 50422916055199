import { useState } from "react";

import { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { ErrorType } from "../lib/constants/error";

export const useErrorRouter = () => {
  const history = useHistory();

  const handleErrorRouter = (error: ApolloError) => {
    switch (error.graphQLErrors[0]?.extensions?.code) {
      case ErrorType.UnAuthenticated:
        history.push("/error/unauthenticated");
        break;
      default:
        history.push("/error/internalservererror");
        break;
    }
  };
  return handleErrorRouter;
};

export const useErrorState = () => {
  const history = useHistory();
  const [error, setError] = useState<ApolloError | null>(null);

  const onError = (error: ApolloError) => {
    if (
      error.graphQLErrors[0]?.extensions?.code === ErrorType.UnAuthenticated
    ) {
      history.push("/error/unauthenticated");
      return;
    }
    setError(error);
  };

  const clearError = () => {
    setError(null);
  };
  return { error, clearError, onError };
};
