import React, { PropsWithChildren, TableHTMLAttributes } from "react";

type TableUIProps = TableHTMLAttributes<HTMLTableElement> & {
  colClassNames?: string[];
  thClassNames?: string[];
  headers: React.ReactNode[];
};

export const TableUI: React.VFC<PropsWithChildren<TableUIProps>> = ({
  children,
  colClassNames,
  headers,
  className,
  thClassNames,
  ...rest
}) => {
  return (
    <table className={`table-auto w-full ${className}`} {...rest}>
      {colClassNames && (
        <colgroup>
          {colClassNames.map((className, i) => (
            <col key={i} className={className} />
          ))}
        </colgroup>
      )}
      <thead className="bg-subtitle text-white">
        <tr>
          {headers.map((header, i) => (
            <th
              className={`pl-5 py-4 font-medium last:pr-5 ${
                thClassNames ? thClassNames[i] ?? "" : ""
              }`}
              key={i}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
