import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import QuestionnairePreviewModalUI from "../../../components/QuestionnairePreviewModal/QuestionnairePreviewModal";
import {
  useAnsweredCoursesQuery,
  useQuestionnaireAnswersLazyQuery,
  AnsweredCoursesFragment,
  QuestionnaireAnswerRowFragment,
  QuestionnaireType,
  QuestionnaireOption,
} from "../../../generated/graphql";
import {
  answeredCoursesSearchSettings,
  csvSearchLimitSettings,
} from "../../../lib/constants/answeredCourses";
import { ErrorType } from "../../../lib/constants/error";
import { CourseQuestionnaire } from "../../../lib/types/course";
import RequestQuestionnairesPage from "./LessonRequestQuestionnairesPage";

dayjs.locale(ja);

const RequestQuestionnairesPageContainer: React.VFC = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(answeredCoursesSearchSettings.limit);
  const [
    questionnairePreviewModalVisibility,
    setQuestionnairePreviewModalVisibility,
  ] = useState(false);
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    CourseQuestionnaire[]
  >([]);
  const [answeredCourses, setAnsweredCourses] =
    useState<AnsweredCoursesFragment>({
      totalCount: 0,
      nodes: [],
    });
  const { data, error } = useAnsweredCoursesQuery({
    variables: {
      limit,
    },
    // レスポンスの中身がキャッシュを用いられることがありバグのもとになるので、再取得させる。
    fetchPolicy: "no-cache",
  });

  const [fetchAnswers, { error: answerError }] =
    useQuestionnaireAnswersLazyQuery({
      onCompleted: (data) => {
        makeCsvData(data.questionnaireAnswers.nodes);
        setFetchDone(true);
      },
    });

  const [selectOptionsAry, setSelectOptionAry] =
    useState<QuestionnaireOption[]>();
  const [multiSelectOptionsAry, setMultiSelectOptionAry] =
    useState<QuestionnaireOption[]>();

  const [makeLinkDone, setMakeLinkDone] = useState<boolean>(false);
  const [fetchDone, setFetchDone] = useState(false);
  const fetchDoneRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [csvHeader, setCsvHeader] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvFileName, setCsvFileName] = useState<string>("default.csv");

  const [hasAdminAuthority, setHasAdminAuthority] = useState(false);

  useEffect(() => {
    if (data?.answeredCourses) {
      setAnsweredCourses(data?.answeredCourses);
    }
  }, [data]);

  useEffect(() => {
    setHasAdminAuthority(data?.hasAdminAuthority || false);
  }, [data?.hasAdminAuthority]);

  useEffect(() => {
    if (makeLinkDone) {
      fetchDoneRef?.current?.link.click();
      setMakeLinkDone(false);
      setFetchDone(false);
    }
  }, [makeLinkDone]);

  useEffect(() => {
    if (fetchDone) {
      if (fetchDoneRef.current !== null) {
        setMakeLinkDone(true);
      }
    }
  }, [fetchDone, csvData]);

  const makeCsvData = useCallback(
    (questionnaireAnswers: QuestionnaireAnswerRowFragment[]) => {
      const bodyData = questionnaireAnswers.map((queAns, index) => {
        // 固定部分の情報を設定
        let bodyLine = {
          index: index + 1,
          memberId: queAns.customer.profile?.memberId,
          name: `${queAns.customer.profile?.familyName} ${queAns.customer.profile?.givenName}`,
          submittedAt: queAns.submittedAt
            ? dayjs(queAns.submittedAt).format("YYYY/MM/DD")
            : "",
          courseId: queAns.course?.systemId,
          courseName: queAns.course?.name,
          clazzId: queAns.clazz?.systemId,
          clazzName: queAns.clazz?.name,
        };
        // 動的部分（アンケート回答内容）部分を設定。質問回数、質問形式、回答件数が全て動的。
        queAns.answers?.forEach((ans) => {
          // 質問IDによるKeyを動的に生成
          const key = `questionTitle${ans.questionnaireId}`;
          // 選ばられた選択肢IDを保持していた候補から探して選択肢名を設定。自由記述の場合はそのまま設定
          let queAnswer =
            selectOptionsAry?.find(
              (opt) => opt.id === ans.selectQuestionnaireOptionId?.toString()
            )?.name ||
            multiSelectOptionsAry?.find(
              (opt) =>
                opt.id === ans.multiSelectQuestionnaireOptionId?.toString()
            )?.name ||
            ans.freeTextValue ||
            "";
          // 複数回答の場合。既に同じKey名で回答を設定してあるか判定。
          if (bodyLine.hasOwnProperty(key)) {
            // @ts-ignore TODO: 動的プロパティ名の値を取得する箇所でエラーが出るのでやむを得ずigonoreを指定。
            queAnswer = `${bodyLine[key]},${queAnswer}`;
          }
          bodyLine = {
            ...bodyLine,
            ...{
              [key]: queAnswer,
            },
          };
        });
        return bodyLine;
      });
      setCsvData(bodyData);
    },
    [selectOptionsAry, multiSelectOptionsAry]
  );

  const makeHeaderData = useCallback(
    (courseId: String) => {
      if (!answeredCourses) {
        return;
      }
      const ques = answeredCourses.nodes.find(
        (ansCourse) => ansCourse.id === courseId
      )?.questionnaires;
      let header = [
        { label: "項番", key: "index" },
        { label: "会員ID", key: "memberId" },
        { label: "名前", key: "name" },
        { label: "回答年月日", key: "submittedAt" },
        { label: "コースID", key: "courseId" },
        { label: "コース名", key: "courseName" },
        { label: "クラスID", key: "clazzId" },
        { label: "クラス名", key: "clazzName" },
      ];
      // 選択内容の文字列が必要なので動的に設定するために保持する
      let tmpSelectOptions: QuestionnaireOption[] = [];
      let multiSelectOptions: QuestionnaireOption[] = [];
      // baseに質問番号「質問タイトル」の動的ヘッダを追加。件数不定のためkey名も動的にする。
      ques?.forEach((que, index) => {
        if (que.typename === QuestionnaireType.SelectQuestionnaire) {
          tmpSelectOptions = tmpSelectOptions.concat(que.options || []);
        } else if (
          que.typename === QuestionnaireType.MultiSelectQuestionnaire
        ) {
          multiSelectOptions = multiSelectOptions.concat(que.options || []);
        }
        const queHead = {
          label: `質問${index + 1}「${que.title}」`,
          key: `questionTitle${que.id}`,
        };
        header.push(queHead);
      });
      setSelectOptionAry(tmpSelectOptions);
      setMultiSelectOptionAry(multiSelectOptions);
      setCsvHeader(header);
    },
    [answeredCourses]
  );

  const makeFileName = useCallback(
    (courseId: String) => {
      if (!answeredCourses) {
        return;
      }
      const name = answeredCourses.nodes.find(
        (ansCourse) => ansCourse.id === courseId
      )?.name;
      setCsvFileName(
        `アンケート結果_${name}_${dayjs().format("YYYYMMDD")}.csv`
      );
    },
    [answeredCourses]
  );

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + answeredCoursesSearchSettings.offset);
  }, [limit]);

  const onCloseQuestionnairePreviewModal: MouseEventHandler =
    useCallback(async () => {
      setQuestionnairePreviewModalVisibility(false);
    }, []);

  const onPreview = useCallback(
    async (questionnaires: CourseQuestionnaire[]) => {
      setSelectedQuestionnaires(questionnaires);
      setQuestionnairePreviewModalVisibility(true);
    },
    [setSelectedQuestionnaires]
  );

  const onClickDownload = useCallback(
    async (courseId: string) => {
      fetchAnswers({
        variables: {
          courseId,
          offset: csvSearchLimitSettings.offset,
          limit: csvSearchLimitSettings.limit,
        },
      });
      makeHeaderData(courseId);
      makeFileName(courseId);
    },
    [fetchAnswers, makeHeaderData, makeFileName]
  );

  if (error) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  if (answerError) {
    console.error(answerError);
    const errCode = answerError?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <>
      <RequestQuestionnairesPage
        hasAdminAuthority={hasAdminAuthority}
        answeredCourses={answeredCourses}
        onDisplayMore={onDisplayMore}
        onPreview={onPreview}
        onClickDownload={onClickDownload}
      />
      <QuestionnairePreviewModalUI
        questionnaires={selectedQuestionnaires}
        visibility={questionnairePreviewModalVisibility}
        onClose={onCloseQuestionnairePreviewModal}
      />
      {fetchDone && (
        <CSVLink
          data={csvData}
          headers={csvHeader}
          filename={csvFileName}
          ref={fetchDoneRef}
          target="_blank"
        />
      )}
    </>
  );
};
export default RequestQuestionnairesPageContainer;
