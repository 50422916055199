import { useDrag, useDrop } from "react-dnd";

import { TrUI, TdUI } from "../../../../components/TableUI";
import { CategoriesDnDTableItemFragment } from "../../../../generated/graphql";
import { ReactComponent as DotsIcon } from "../dots.svg";

export type CategoriesDnDTrUIProps = {
  category: CategoriesDnDTableItemFragment;
  moveCard: (id: string, atIndex: number) => void;
  findCard: (id: string) => { index: number };
};

type Item = {
  id: string;
  originalIndex: number;
};

export const ItemTypes = {
  CATEGORY: "category",
};

export const CategoriesDnDTrUI: React.VFC<CategoriesDnDTrUIProps> = ({
  category,
  moveCard,
  findCard,
}) => {
  const originalIndex = findCard(category.id).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.CATEGORY,
      item: { id: category.id, originalIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [category, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CATEGORY,
      hover: ({ id: draggedId }: Item, _monitor) => {
        const overIndex = findCard(category.id).index;
        moveCard(draggedId, overIndex);
      },
    }),
    [category, moveCard]
  );

  return (
    <TrUI
      // captureDraggingStateをtrueにするとドラッグされたときに割り当てたスタイルが
      // ドラッグプレビュー（マウスにひっついている要素）に反映されるようになる
      ref={(node) => preview(drop(node), { captureDraggingState: true })}
      isDragging={isDragging}
      data-testid={`category-row-${category.id}`}
    >
      <TdUI className="pl-5">{category.systemId}</TdUI>
      <TdUI className="pl-5">{category.name}</TdUI>
      <TdUI
        className="pl-5 cursor-move"
        data-testid={`category-drag-button-${category.id}`}
        ref={drag}
      >
        <DotsIcon />
      </TdUI>
    </TrUI>
  );
};
