import gql from "graphql-tag";

export const UserDetailFragment = gql`
  fragment UserDetail on User {
    id
    userId
    familyName
    givenName
    familyNameKana
    givenNameKana
    email
    roles {
      id
      name
    }
    status
    tel
    gender
    birthday
    birthplace
    yearsOfTeaching
    message
    selfIntroduction
    hobby
    avator
    extra
    useableServiceKinds {
      id
      shortName
    }
  }
`;
