import React from "react";

export type TextFieldUIProps = {
  label: string;
  value: string | null | undefined;
  testIdPrefix?: string;
  divClassName?: string;
  labelClassName?: string;
  pClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const TextFieldUI: React.FC<TextFieldUIProps> = ({
  label,
  value,
  testIdPrefix = "",
  divClassName = "",
  labelClassName = "",
  pClassName = "",
  ...rest
}) => {
  return (
    <div
      className={`pb-4 border-b border-under-line ${divClassName}`}
      {...rest}
    >
      <label className={`text-lg font-bold ${labelClassName}`}>{label}</label>
      <p
        data-testid={`${testIdPrefix}-value`}
        className={`mt-2.5 ${pClassName}`}
      >
        {value?.split("\n").map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item}
              <br />
            </React.Fragment>
          );
        }) ?? ""}
      </p>
    </div>
  );
};
