import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import {
  CoursesDnDTableItemFragment,
  CoursesSortPageQuery,
} from "../../../generated/graphql";
import {
  CoursesDnDTableUI,
  CoursesDnDTableUIProps,
} from "./components/CoursesDnDTableUI";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "サービス並び替え",
  },
];

export type CoursesSortPageProps = {
  data: CoursesSortPageQuery;
  onClickDone: (courses: CoursesDnDTableItemFragment[]) => void;
};

const dirty = (
  courses: CoursesDnDTableItemFragment[],
  org: CoursesDnDTableItemFragment[]
) => {
  return courses.some((course, index) => course.id !== org[index].id);
};

const CoursesSortPage: React.VFC<CoursesSortPageProps> = ({
  data,
  onClickDone,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [courses, setCourses] = useState<CoursesDnDTableItemFragment[]>(
    data.publishedCourses.nodes.concat()
  );

  useEffect(() => {
    setCourses(data.publishedCourses.nodes.concat());
  }, [data]);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const handleChangeCourses: CoursesDnDTableUIProps["onChangeCourses"] =
    useCallback((course: CoursesDnDTableItemFragment[]) => {
      setCourses(course);
    }, []);

  const handleClickButton: MouseEventHandler<HTMLButtonElement> = useCallback(
    (_event) => {
      onClickDone(courses);
    },
    [onClickDone, courses]
  );

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">サービス並び替え</h1>
      <hr className="border-dark-gray border mb-4"></hr>

      <div className="px-6 mb-10">
        <div className="mb-10">
          <p>
            お客様側に表示される商品の並び順を変更します。トップ画面に表示されているもののみ一覧に表示されています。
          </p>
        </div>
        <p data-testid="courses-totalCount">
          全{data.publishedCourses.totalCount}件中1〜
          {data.publishedCourses.nodes.length}件表示
        </p>
        <CoursesDnDTableUI
          onChangeCourses={handleChangeCourses}
          courses={courses}
        />
        <div className="flex justify-center mt-20">
          <ButtonUI
            onClick={handleClickButton}
            disabled={!dirty(courses, data.publishedCourses.nodes)}
          >
            並び替え完了
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};

export default CoursesSortPage;
