import { useEffect, useMemo } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { PaginationUI } from "../../../components/Pagination";
import UploadButton from "../../../components/UploadButton";
import { CorporationUploadedCsvFragment } from "../../../generated/graphql";
import { CorporationGridUI } from "../components/CorporationGrid";

export type CorporationPageProps = {
  currentPage: number;
  totalPage: number;
  corpoCsvList: CorporationUploadedCsvFragment[];
  pushPage: (pageNumber: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  uploadFile: (file: File) => void;
  onCsvDownload: (url: string, fileName: string) => void;
};

const TITLE = "法人管理";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: TITLE,
  },
];

const CorporationPage: React.FC<CorporationPageProps> = ({
  currentPage,
  totalPage,
  corpoCsvList,
  pushPage,
  nextPage,
  prevPage,
  uploadFile,
  onCsvDownload,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const selectedIndex = useMemo(() => {
    const index = currentPage - 1;
    return index < 0 ? 0 : index;
  }, [currentPage]);
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <>
      <h1 className="text-2xl mb-3">{TITLE}</h1>
      <hr className="border-dark-gray border" />
      <div className="ml-1.5 mt-8">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl">CSVアップロード履歴</h2>
          <UploadButton
            id="corporation-csv-upload-button"
            buttonName="CSVアップロード"
            typeErrorMessage="CSV以外が選択されました。"
            fileTypes={["text/csv"]}
            onChangeFile={uploadFile}
          />
        </div>
        <CorporationGridUI
          className="mt-8"
          corpoCsvList={corpoCsvList}
          onCsvDownload={onCsvDownload}
        />
      </div>
      <PaginationUI
        className="my-20"
        selectedIndex={selectedIndex}
        count={totalPage}
        onClickItem={(index: number) => pushPage(index + 1)}
        onClickPrevios={prevPage}
        onClickNext={nextPage}
      />
    </>
  );
};

export default CorporationPage;
