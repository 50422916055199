import React, { useState, useCallback, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import {
  PaymentsFilterInput,
  useCourseNamesLazyQuery,
  CourseNamesFragment,
  usePlansQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { ErrorType } from "../../../lib/constants/error";
import PaymentsSearchPage from "./PaymentsSearchPage";

const PaymentsSearchPageContainer: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{
    prevFilter: PaymentsFilterInput;
  }>();
  const [filter, setFilter] = useState<PaymentsFilterInput>(
    location.state && location.state.prevFilter !== undefined
      ? location.state.prevFilter
      : {}
  );
  const [gotoResult, setGotoResult] = useState<boolean>(false);
  const errorRouter = useErrorRouter();

  const onSearch = useCallback(
    (inputs: PaymentsFilterInput) => {
      setFilter(inputs);
      setGotoResult(true);
    },
    [setGotoResult, setFilter]
  );

  const [fetchCourseNames, { data: coursesData, error: coursesError }] =
    useCourseNamesLazyQuery({
      // LazyQueryが実行されるたびに再取得させる。（サジェストの挙動がバグるのでキャッシュを利用させない）
      fetchPolicy: "no-cache",
    });
  const onSearchCourses = useCallback(
    async (searchWord: string) => {
      fetchCourseNames({
        variables: {
          filter: {
            searchWord,
          },
          limit: 20,
        },
      });
    },
    [fetchCourseNames]
  );

  const { data: plansData, loading: plansLoading } = usePlansQuery({
    onError: errorRouter,
  });

  const [courseDataList, setCourseDataList] = useState<CourseNamesFragment>({
    totalCount: 0,
    nodes: [],
  });

  useEffect(() => {
    if (coursesData?.courses) {
      setCourseDataList(coursesData?.courses);
    }
  }, [coursesData?.courses]);

  useEffect(() => {
    // mount & render
    if (gotoResult) {
      setGotoResult(false);
      history.replace({ ...location, state: { prevFilter: filter } });
      history.push("/payment/search/results", {
        filter,
      });
    }
    return () => {
      // unmount
    };
  }, [history, filter, gotoResult, location]);

  useEffect(() => {
    if (coursesError) {
      console.error(coursesError);
      const errCode = coursesError?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
      } else {
        history.push("/error/internalservererror");
      }
    }
  }, [coursesError, history]);

  if (plansLoading || !plansData) {
    return <LoadingUI />;
  }

  return (
    <PaymentsSearchPage
      filter={filter}
      onSearch={onSearch}
      onSearchCourses={onSearchCourses}
      courseDataList={courseDataList}
      plans={plansData.plans}
    />
  );
};
export default PaymentsSearchPageContainer;
