import React, { useEffect } from "react";

import { useBreadcrumb } from "../../../components/Breadcrumb";
import { Breadcrumb } from "../../../components/Breadcrumb/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ReactComponent as SmallPlusIcon } from "../../../components/Button/small-plus.svg";
import { ReactComponent as MoreIcon } from "../../../components/more.svg";
import { DiscountsTableUI } from "./components/DiscountsTable";

// 足跡
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "クーポン一覧",
  },
];

/**
 * クーポン一覧画面のプロパティ
 */
export type DiscountsPageProps = {
  totalCount: number;
  dataList: {}[];
  onDisplayMore: () => void;
  onDeleting: (id: string) => void;
  onEditing: (id: string) => void;
};

/**
 * クーポン一覧画面（実態）
 * @param param0
 * @returns
 */
const DiscountsPage: React.FC<DiscountsPageProps> = ({
  totalCount,
  dataList,
  onDisplayMore,
  onDeleting,
  onEditing,
}) => {
  // 足跡
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">クーポン一覧</h1>
      <hr className="border-gray border mb-2"></hr>
      <div className="ml-6">
        <div className="mt-5 flex justify-between">
          <div className="flex-col space-x-5">
            {/* 新規登録 */}
            <ButtonUI
              data-testid="discounts-add-discount-btn"
              size="ll"
              buttonType="primary"
              className={`inline-flex items-center justify-items-center justify-center`}
              to={`/discount/new`}
            >
              <SmallPlusIcon className="stroke-current mr-4" />
              新規登録
            </ButtonUI>
          </div>
        </div>
        <hr className="mt-5 mb-6 border-gray border"></hr>{" "}
        {totalCount > 0 ? (
          <>
            <div className="flex justify-between mb-1.5 items-end">
              <span data-testid="discounts-page-total-count">
                全{totalCount}件中{dataList.length}件表示
              </span>
            </div>
            <DiscountsTableUI
              dataList={dataList}
              onDeleting={onDeleting}
              onEditing={onEditing}
            ></DiscountsTableUI>
            {totalCount !== dataList.length && (
              <div className="flex justify-center mt-9 mb-10">
                <button
                  data-testid="discounts-page-display-more-btn"
                  className="text-blue hover:underline active:text-blue-active active:underline h-8"
                  onClick={onDisplayMore}
                >
                  <MoreIcon></MoreIcon>
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="discounts-page-no-data-message"
              >
                登録済みのクーポンはまだありません。
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountsPage;
