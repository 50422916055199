import { useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../components/Badge";
import { FormGroupUI } from "../../../../components/FormGroup";
import { LabelUI } from "../../../../components/Label";
import { NewValidatableInputUI } from "../../../../components/ValidatableInput";
import { VideoLessonEditFormState } from "../VideoLessonEdit";

export type VideoEditUIProps = {
  index: number;
};

const POSFIX = ["①", "②", "③"];

export const VideoEditUI: React.VFC<VideoEditUIProps> = ({ index }) => {
  const postfix = POSFIX[index] ?? "";
  const {
    register,
    formState: { errors },
  } = useFormContext<VideoLessonEditFormState>();
  return (
    <FormGroupUI>
      <LabelUI className="text-lg pb-2">{`レッスン${postfix}`}</LabelUI>
      <div className="space-y-2 pb-4">
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-base">{`レッスンタイトル${postfix}`}</LabelUI>
          {index === 0 && <BadgeUI>必須</BadgeUI>}
        </div>
        <NewValidatableInputUI
          id={`video-name-${index}`}
          {...register(`videos.${index}.name`, {
            required:
              index === 0
                ? `レッスンタイトル${postfix}は必須項目です。`
                : undefined,
            maxLength: {
              value: 30,
              message: "30文字以内で入力してください。",
            },
          })}
          data-testid="video-name"
          className="w-full"
          placeholder={`レッスンタイトル${postfix}を入力してください`}
          error={errors?.videos?.[index]?.name}
        />
      </div>
      <div className="space-y-2">
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-base">{`レッスン動画${postfix}`}</LabelUI>
          {index === 0 && <BadgeUI>必須</BadgeUI>}
        </div>
        <NewValidatableInputUI
          id={`video-jStreamMId-${index}`}
          {...register(`videos.${index}.jStreamMId`, {
            required:
              index === 0
                ? `レッスン動画ID${postfix}は必須項目です。`
                : undefined,
            maxLength: {
              value: 100,
              message: "100文字以内で入力してください。",
            },
          })}
          data-testid="video-jStreamMId"
          className="w-full"
          placeholder={`レッスン動画ID${postfix}を入力してください`}
          error={errors?.videos?.[index]?.jStreamMId}
        />
      </div>
    </FormGroupUI>
  );
};
