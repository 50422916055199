import React, { useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { preventFromSubmittingByEnter } from "../../../lib/functions";
import { ExpertEditFormState, ExpertEditUI } from "../components/ExpertEdit";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "有識者一覧",
  },
];

export type ExpertEditPageProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: (data: ExpertEditFormState) => void;
  defaultValues?: ExpertEditFormState;
};

const ExpertEditPage: React.VFC<ExpertEditPageProps> = ({
  onGoBack,
  onSubmit,
  defaultValues,
}) => {
  const methods = useForm<ExpertEditFormState>({
    defaultValues,
  });

  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <FormProvider {...methods}>
      <div>
        <h1 className="text-left text-2xl">有識者編集</h1>
        <hr className="border-gray border mt-2" />
        <p className="mt-4">
          登録が完了すると設定した公開日時までのあいだ、「有識者ラインナップ」にcoming
          soonが表示されます。
        </p>
      </div>
      <form
        className="mt-2"
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyDown={(event) => preventFromSubmittingByEnter(event)}
      >
        <ExpertEditUI onGoBack={onGoBack} />
      </form>
    </FormProvider>
  );
};

export default ExpertEditPage;
