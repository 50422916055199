import React from "react";

import { LoadingUI } from "../../../components/Loading";
import { useFeaturedVideosPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import FeaturedVideosPage from "./FeaturedVideosPage";

const FeaturedVideosPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const { data, loading } = useFeaturedVideosPageQuery({
    onError: errorRouter,
  });

  if (loading || !data) {
    return <LoadingUI />;
  }

  return <FeaturedVideosPage data={data} />;
};

export default FeaturedVideosPageContainer;
