import React, {
  useEffect,
  useState,
  useCallback,
  MouseEventHandler,
} from "react";

import * as History from "history";
import { useHistory, Prompt } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { UserConfirmUI } from "../../../components/UserConfirm";
import { PageType } from "../../../lib/constants/pageType";
import { UserEdit } from "../../../lib/types";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "教師スタッフ一覧",
  },
  {
    label: "【外部】教師スタッフ追加確認",
  },
];

export type UsersExternalNewConfirmPageProps = {
  userEdit: UserEdit;
  isOpenCompleteModal: boolean;
  confirmErrorModalDescription: string;
  confirmErrorModalVisibility: boolean;
  confirmModalVisibility: boolean;
  handleUpdate: () => Promise<void>;
  clearErrorModal: () => void;
  clearCompletedModal: () => void;
  setConfirmModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const UsersExternalNewConfirmPage: React.FC<
  UsersExternalNewConfirmPageProps
> = ({
  userEdit,
  isOpenCompleteModal,
  confirmErrorModalDescription,
  confirmErrorModalVisibility,
  confirmModalVisibility,
  handleUpdate,
  clearErrorModal,
  clearCompletedModal,
  setConfirmModalVisibility,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [avatorUrl, setAvatorURL] = useState<string | null>(null);
  const history = useHistory();

  const onHandleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      handleUpdate();
    },
    [handleUpdate]
  );

  const handleBack = useCallback(() => {
    history.push("/user/external/new");
  }, [history]);

  const confirmBack = useCallback(() => {
    setConfirmModalVisibility(true);
  }, [setConfirmModalVisibility]);

  const handleBackToUserList: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      history.push("/users");
    }, [history]);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    let avator: string | null = null;
    if (userEdit.avator) {
      avator = URL.createObjectURL(userEdit.avator);
    } else if (userEdit.avator) {
      avator = userEdit.avator;
    }
    setAvatorURL(avator);
    return () => {
      avatorUrl && userEdit.avator && URL.revokeObjectURL(avatorUrl);
      clearCompletedModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEdit.avator]);

  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (
        location.pathname === `/users/${userEdit.id}/edit` &&
        action === "POP"
      ) {
        confirmBack();
        return false;
      }
      return true;
    },
    [confirmBack, userEdit.id]
  );

  return (
    <div>
      <h1 className="text-2xl mb-3">【外部】教師スタッフ追加確認</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <UserConfirmUI
          pageType={PageType.UsersExternalNewConfirmPage}
          userEdit={userEdit}
          onHandleSubmit={onHandleSubmit}
          avatorUrl={avatorUrl}
          handleBack={confirmBack}
        ></UserConfirmUI>
        <CompletedModalUI
          title="登録完了"
          description="登録が完了しました。"
          btnTitle="教師スタッフ一覧へ"
          onClick={handleBackToUserList}
          visibility={isOpenCompleteModal}
        />
        <ConfirmErrorModalUI
          title="登録に失敗しました"
          description={confirmErrorModalDescription}
          btnTitle="閉じる"
          onClick={clearErrorModal}
          visibility={confirmErrorModalVisibility}
        />
        <ConfirmModalUI
          title="確認"
          description={`編集中の内容は破棄されます。
よろしいですか？`}
          onCancel={() => {
            setConfirmModalVisibility(false);
          }}
          onSubmit={handleBack}
          visibility={confirmModalVisibility}
        />
        <Prompt when={true} message={handleBlockBack} />
      </div>
    </div>
  );
};

export default UsersExternalNewConfirmPage;
