export enum GmoFirstPaymentStatus {
  /**
   * 未決済
   */
  UnProcessed = "UNPROCESSED",
  /**
   * 未決済(3DS登録済)
   */
  Authenticated = "AUTHENTICATED",
  /**
   * 有効性チェック
   */
  Check = "CHECK",
  /**
   * 即時売上
   */
  Capture = "CAPTURE",
  /**
   * 仮売上
   */
  Auth = "AUTH",
  /**
   * 実売上
   */
  Sales = "SALES",
  /**
   * 取消
   */
  Void = "VOID",
  /**
   * 返品
   */
  Return = "RETURN",
  /**
   * 月跨り返品
   */
  ReturnX = "RETURNX",
  /**
   * 簡易オーソリ
   */
  SAuth = "SAUTH",
}

export type GmoFirstPaymentStatusLabel = {
  id: string;
  value: string;
  label: string;
};

export const gmoFirstPaymentStatuses: GmoFirstPaymentStatusLabel[] = [
  {
    id: "gmoFirstUnProcessed",
    value: GmoFirstPaymentStatus.UnProcessed,
    label: "未決済",
  },
  {
    id: "gmoFirstAuthenticated",
    value: GmoFirstPaymentStatus.Authenticated,
    label: "未決済(3DS登録済)",
  },
  {
    id: "gmoFirstCheck",
    value: GmoFirstPaymentStatus.Check,
    label: "有効性チェック",
  },
  {
    id: "gmoFirstCapture",
    value: GmoFirstPaymentStatus.Capture,
    label: "即時売上",
  },
  {
    id: "gmoFirstAuth",
    value: GmoFirstPaymentStatus.Auth,
    label: "仮売上",
  },
  {
    id: "gmoFirstSales",
    value: GmoFirstPaymentStatus.Sales,
    label: "実売上",
  },
  {
    id: "gmoFirstVoid",
    value: GmoFirstPaymentStatus.Void,
    label: "取消",
  },
  {
    id: "gmoFirstReturn",
    value: GmoFirstPaymentStatus.Return,
    label: "返品",
  },
  {
    id: "gmoFirstReturnX",
    value: GmoFirstPaymentStatus.ReturnX,
    label: "月跨り返品",
  },
  {
    id: "gmoFirstSAuth",
    value: GmoFirstPaymentStatus.SAuth,
    label: "簡易オーソリ",
  },
];
