export const aeonHeaders = [
  { label: "項番", key: "index" },
  { label: "決済番号", key: "payInfoNo" },
  { label: "申込番号", key: "orderId" },
  { label: "会員ID", key: "memberId" },
  { label: "氏名", key: "customerName" },
  { label: "決済商品", key: "paiedProductName" },
  { label: "課金種別", key: "paymentTypeJA" },
  { label: "売上金額(税込)", key: "priceValue" },
  { label: "売上金額(税抜)", key: "priceValueNoTax" },
  { label: "決済ステータス", key: "paymentStatusJA" },
  { label: "申込年月日（オーソリ日）", key: "submittedAt" },
  { label: "決済年月日（売上確定日）", key: "soldDateTime" },
];

export const liberalArtsHeaders = [
  { label: "項番", key: "index" },
  { label: "決済番号", key: "payInfoNo" },
  { label: "申込番号", key: "orderId" },
  { label: "決済方法", key: "payService" },
  { label: "会員ID", key: "memberId" },
  { label: "氏名", key: "customerName" },
  { label: "決済商品", key: "paiedProductName" },
  { label: "課金種別", key: "paymentTypeJA" },
  { label: "売上金額(税込)", key: "priceValue" },
  { label: "売上金額(税抜)", key: "priceValueNoTax" },
  { label: "決済ステータス", key: "paymentStatusJA" },
  { label: "申込年月日（オーソリ日）", key: "submittedAt" },
  { label: "決済年月日（売上確定日）", key: "soldDateTime" },
];

export const elementaryHeaders = [
  { label: "項番", key: "index" },
  { label: "決済番号", key: "payInfoNo" },
  { label: "申込番号", key: "orderId" },
  { label: "会員ID", key: "memberId" },
  { label: "氏名", key: "customerName" },
  { label: "事業者名", key: "organizerName" },
  { label: "決済商品", key: "paiedProductName" },
  { label: "課金種別", key: "paymentTypeJA" },
  { label: "売上金額(税込)", key: "priceValue" },
  { label: "売上金額(税抜)", key: "priceValueNoTax" },
  { label: "決済ステータス", key: "paymentStatusJA" },
  { label: "申込年月日（オーソリ日）", key: "submittedAt" },
  { label: "決済年月日（売上確定日）", key: "soldDateTime" },
];
