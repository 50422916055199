import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { BadgeUI } from "../../../../components/Badge";
import LinkTextUI from "../../../../components/LinkText/LinkText";
import {
  Maybe,
  PaymentMismatchesTableRowFragment,
  PaymentMismatchStatus,
  PaymentMismatchType,
} from "../../../../generated/graphql";
import {
  getMismatchTypeJA,
  getPaymentStatusJA,
} from "../../../../lib/utils/payment";
dayjs.locale(ja);

export type PaymentMismatchesTableRowProps = {
  paymentMismatch: PaymentMismatchesTableRowFragment;
  index: number;
};

export const getHandlingStatusJA = (
  handlingStatus: Maybe<string> | undefined
) => {
  switch (handlingStatus) {
    case PaymentMismatchStatus.Unhandled:
      return <BadgeUI type={"unhandle"}>未対応</BadgeUI>;
    case PaymentMismatchStatus.Handling:
      return <BadgeUI type={"handling"}>対応中</BadgeUI>;
    case PaymentMismatchStatus.Done:
      return <BadgeUI type={"done"}>対応済</BadgeUI>;
  }
};

export const checkPaymentStatusMismatch = (
  paymentMismatch: PaymentMismatchesTableRowFragment
) => {
  if (paymentMismatch.type === PaymentMismatchType.StatusMismatch) {
    return "不一致";
  }
  return getPaymentStatusJA(paymentMismatch.paymentStatus);
};

const PaymentMismatchesTableRowUI: React.FC<PaymentMismatchesTableRowProps> = ({
  paymentMismatch,
  index,
}) => {
  return (
    <tr
      data-testid="payment-mismatches-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className="border-r border-dark-gray pl-1 pr-1 text-center">
        {getHandlingStatusJA(paymentMismatch.handlingStatus)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        <LinkTextUI to={`/payment/mismatches/${paymentMismatch.id}`}>
          {paymentMismatch.payInfoNo ? paymentMismatch.payInfoNo : ""}
        </LinkTextUI>
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {dayjs(paymentMismatch.detectedDateTime).format("YYYY/MM/DD HH:mm")}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {checkPaymentStatusMismatch(paymentMismatch)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {getMismatchTypeJA(paymentMismatch.type)}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {dayjs(paymentMismatch.latestUpdatedAt).format("YYYY/MM/DD HH:mm")}
      </td>
    </tr>
  );
};

export default PaymentMismatchesTableRowUI;
