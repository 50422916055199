import React, { InputHTMLAttributes } from "react";

import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";

export type NewValidatableInputUIProps = {
  error?:
    | boolean
    | {
        message?: string;
      };
  heightCustomSize?: string;
  withErrorIcom?: boolean;
  "data-testid": string;
} & InputHTMLAttributes<HTMLInputElement>;

export const NewValidatableInputUI = React.forwardRef<
  HTMLInputElement,
  NewValidatableInputUIProps
>(
  (
    {
      className = "",
      error,
      children,
      heightCustomSize = "h-12",
      withErrorIcom = true,
      "data-testid": testid,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="flex flex-col  w-full">
        <div className="flex items-center space-x-3">
          {error && withErrorIcom && <ErrorCheckIcon />}
          <input
            ref={ref}
            className={`${heightCustomSize} leading-5 pl-4 tracking-wider border rounded ${className} 
          ${error ? "border-error placeholder-error-placeholder" : ""}
          `}
            data-testid={testid}
            {...rest}
          />
        </div>
        {typeof error !== "boolean" && error?.message && (
          <p
            data-testid={`${testid}-error-message`}
            className="text-error mt-1.5 ml-8"
          >
            {error?.message}
          </p>
        )}
      </div>
    );
  }
);
