import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { useExpertPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import ExpertPage from "./ExpertPage";

const ExpertPageContainer: React.VFC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const errorRouter = useErrorRouter();
  const { data, loading } = useExpertPageQuery({
    variables: { id },
    onError: errorRouter,
  });
  if (loading || !data) {
    return <LoadingUI />;
  }

  const handleGoBack = () => {
    history.goBack();
  };

  return <ExpertPage expert={data.expert} onGoBack={handleGoBack} />;
};

export default ExpertPageContainer;
