import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";

import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useLocation, useHistory } from "react-router-dom";

import DownloadCsv, { CsvLinkProps } from "../../../components/DownloadCsv";
import { LoadingUI } from "../../../components/Loading";
import {
  CustomerCsvDownloadsQuery,
  CustomerCsvRowFragment,
  CustomerFilterInput,
  useCustomerIdsSearchResultQuery,
} from "../../../generated/graphql";
import { useCustomerCsvDownloadsLazyQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import {
  searchSettings,
  searchSettingsForAllData as csvSetting,
} from "../../../lib/constants";
import CustomerSearchResultsPage from "./CustomerSearchResultsPage";

dayjs.extend(utc);
dayjs.extend(tz);

type CsvData = {
  customerId: string;
  name: string;
  kana: string;
  memberId: string;
  tel: string;
  age: number;
  dmAgreed: string; // yes/no
  interests: string;
  occupation: string;
  industry: string;
  jobTitle: string;
  position: string;
  annualIncome: string;
  createdAt: string;
  registration: string;
  serviceKindName: string;
};

const headers = [
  { label: "お客様番号", key: "customerId" },
  { label: "名前", key: "name" },
  { label: "フリガナ", key: "kana" },
  { label: "会員ID", key: "memberId" },
  { label: "電話番号", key: "tel" },
  { label: "年齢", key: "age" },
  { label: "DM同意", key: "dmAgreed" },
  { label: "興味のあるカテゴリ", key: "interests" },
  { label: "職業", key: "occupation" },
  { label: "業種", key: "industry" },
  { label: "職種", key: "jobTitle" },
  { label: "役職", key: "position" },
  { label: "年収", key: "annualIncome" },
  { label: "会員登録年月日", key: "createdAt" },
  { label: "会員登録時サービス", key: "serviceKindName" },
  { label: "会員登録経路", key: "registration" },
];

export type CustomerIdNode = {
  id: string;
};

export type CustomersForDisplay = {
  totalCount: number;
  nodes: CustomerIdNode[];
};

const CustomerSearchResultsPageContainer: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{
    filter: CustomerFilterInput;
  }>();
  const { filter } = location.state;
  const [limit, setLimit] = useState(searchSettings.limit);
  const [customers, setCustomers] = useState<CustomersForDisplay>({
    totalCount: 0,
    nodes: [],
  });
  const [csvData, setCsvData] = useState<CsvData[]>();

  const errorRouter = useErrorRouter();

  /**
   * graphql → CSV用のオブジェクトへ変換
   */
  const replaceToCsvData = useCallback(
    (queryData: CustomerCsvDownloadsQuery): CsvData[] => {
      return queryData.customers.nodes.map(
        (customer: CustomerCsvRowFragment): CsvData => {
          return {
            customerId: customer.id,
            name: `${customer.profile?.familyName} ${customer.profile?.givenName}`,
            kana: `${customer.profile?.familyNameKana} ${customer.profile?.givenNameKana}`,
            memberId: customer.profile?.memberId || "",
            tel: customer.profile?.tel || "",
            age: customer.profile?.age || -1,
            dmAgreed:
              customer.agreedTerms &&
              customer.agreedTerms.find((term) => term.key === "mailMagazine")
                ? "yes"
                : "no",
            interests: customer.profile?.subjects
              ? customer.profile.subjects.map((s) => s.name).join("|")
              : "",
            occupation: customer.profile?.userAttribute?.occupation || "",
            industry: customer.profile?.userAttribute?.industry || "",
            jobTitle: customer.profile?.userAttribute?.jobTitle || "",
            position: customer.profile?.userAttribute?.position || "",
            annualIncome: customer.profile?.userAttribute?.annualIncome || "",
            createdAt: dayjs(customer.createdAt).format("YYYY/MM/DD"),
            serviceKindName: customer.customerServices
              ? customer.customerServices
                  .map((s) => s.serviceKindName)
                  .join("|")
              : "",
            registration: customer.registration?.eventKey || "",
          };
        }
      );
    },
    []
  );

  const { data, loading, refetch } = useCustomerIdsSearchResultQuery({
    onError: errorRouter,
    variables: {
      filter,
      limit,
    },
  });

  const [fetchCSVData, { loading: loadingCSVQuery }] =
    useCustomerCsvDownloadsLazyQuery({
      onCompleted: (data) => {
        const replacedData = replaceToCsvData(data);
        setCsvData(replacedData);
      },
      onError: errorRouter,
      variables: {
        filter: filter,
        offset: csvSetting.offset,
        limit: csvSetting.limit,
      },
    });

  const csvLinkProps: CsvLinkProps | undefined = useMemo(() => {
    // validate
    if (loadingCSVQuery || !csvData) {
      return undefined;
    }

    return {
      filename: `会員一覧_${dayjs().format("YYYYMMDD")}.csv`,
      headers: headers,
      data: csvData,
    };
  }, [csvData, loadingCSVQuery]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter,
      limit: limit + searchSettings.offset,
    });
  }, [filter, refetch, limit]);

  const toSearchPage: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push("/customer/search", {
        filter,
      });
    },
    [history, filter]
  );

  useEffect(() => {
    if (data?.customersForDisplay) {
      setCustomers(data?.customersForDisplay);
    }
  }, [data?.customersForDisplay]);

  return loading && customers.totalCount === 0 ? (
    <LoadingUI title="検索中" />
  ) : (
    <>
      <CustomerSearchResultsPage
        customers={customers}
        hasAdminAuthority={data?.hasAdminAuthority || false}
        onDisplayMore={onDisplayMore}
        toSearchPage={toSearchPage}
        onClickDownload={fetchCSVData}
      />
      <DownloadCsv csvLinkProps={csvLinkProps} />
    </>
  );
};
export default CustomerSearchResultsPageContainer;
