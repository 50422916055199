import React, { ChangeEventHandler, FormEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { InputUI } from "../../../components/Input";
import { LabelUI } from "../../../components/Label";
import { BillingCustomerFilterInput } from "../../../generated/graphql";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "お客様情報",
  },
  {
    label: "月額会員照会",
  },
];

export type CustomerBillingSearchPageProps = {
  filter: BillingCustomerFilterInput;
  onSubmit: FormEventHandler;
  onChangeFilterInput: ChangeEventHandler<HTMLInputElement>;
};

const CustomerBillingSearchPage: React.VFC<CustomerBillingSearchPageProps> = ({
  filter,
  onSubmit,
  onChangeFilterInput,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">月額会員照会</h1>
      <hr className="border-dark-gray border mb-8"></hr>
      <p className="mt-8 ml-8 font-bold text-xl">検索条件設定</p>
      <form onSubmit={onSubmit} className="ml-14 mr-10 pb-20 pl-5 mt-2.5">
        <div className="flex justify-center items-center space-x-3">
          <LabelUI className="text-lg font-bold w-3/12 text-right">
            会員ID(メールアドレス)
          </LabelUI>
          <InputUI
            className="w-9/12"
            data-testid="customers-billing-search-memberid"
            value={filter.memberId}
            id="memberId"
            name="memberId"
            placeholder="sample@xxx.yy.jp"
            onChange={onChangeFilterInput}
          />
        </div>
        <div className="flex justify-center items-center space-x-3 space-y-3">
          <div className="w-3/12"></div>
          <div className="w-9/12">
            <p className="text-sm">※空欄の場合は一覧が表示されます。</p>
          </div>
        </div>
        <div className="flex justify-end">
          <ButtonUI
            data-testid="customers-billing-search-submit"
            buttonType="primary"
            base="rounded20"
            type="submit"
            size="xs"
            className="mr-2.5"
          >
            検索
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};

export default CustomerBillingSearchPage;
