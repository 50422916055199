import React, { useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ClazzDetailFragment } from "../../../generated/graphql";
import { ClassDetailUI } from "../components/ClassDetail";
import ClassDeleteButton from "./components/ClassDeleteButton";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "クラス詳細",
  },
];

export type ClassPageProps = {
  clazz: ClazzDetailFragment;
};

const ClassPage: React.FC<ClassPageProps> = ({ clazz }) => {
  const history = useHistory();
  // const location = useLocation<{
  //   from?: string;
  // }>();

  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div>
      <h1 className="text-2xl mb-3">クラス詳細</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="ml-5">
        <ClassDetailUI clazz={clazz}></ClassDetailUI>

        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            data-testid="class-back-btn"
            buttonType="secondary"
            onClick={handleGoBack}
          >
            戻る
          </ButtonUI>
          {/* TODO: 削除ボタンはversion2以降に復元する予定だが、現時点では不要なので、一旦コメントアウトする。
          <ButtonUI data-testid="class-delete-btn" buttonType="secondary">
            削除
          </ButtonUI>
          */}
          <ButtonUI
            data-testid="class-edit-btn"
            to={`/courses/${clazz.courseId}/classes/${clazz.id}/edit`}
          >
            編集
          </ButtonUI>
          <ClassDeleteButton
            clazzId={clazz.id}
            onCompleteDelete={handleGoBack}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassPage;
