import React from "react";

import { VideoRatingTableRowFragment } from "../../../../generated/graphql";

export type VideoRatingTableRowProps = {
  videoRowData: VideoRatingTableRowFragment;
};

const style = {
  cell: "border border-dark-gray pl-2 pr-1",
};

const VideoRatingTableRowUI: React.VFC<VideoRatingTableRowProps> = ({
  videoRowData,
}) => {
  return (
    <tr data-testid="video-rating-table-row" className="h-9 bg-white">
      <td className={`${style.cell}`} data-testid="video-rating-table-row-name">
        {videoRowData.video.name}
      </td>
      <td
        className={`${style.cell}`}
        data-testid="video-rating-table-row-rating"
      >
        {videoRowData.rating == null
          ? "-"
          : Number(videoRowData.rating).toFixed(2)}
      </td>
      <td
        className={`${style.cell}`}
        data-testid="video-rating-table-row-rating-count"
      >
        {videoRowData.ratingCount}
      </td>
    </tr>
  );
};

export default VideoRatingTableRowUI;
