import { useCallback } from "react";

import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";

export const useQueryState = <State = Record<string, any>>(): [
  State,
  (state: Partial<State>) => string
] => {
  const location = useLocation();
  const history = useHistory();
  const setQuery = useCallback(
    (value) => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify(
        { ...existingQueries, ...value },
        { skipNulls: true }
      );

      history.push(`${location.pathname}?${queryString}`);
      return queryString;
    },
    [history, location]
  );

  return [
    parseValue(qs.parse(location.search, { ignoreQueryPrefix: true })) as State,
    setQuery,
  ];
};

export const parseQueryParams = <State>(query: string): State => {
  return parseValue(qs.parse(query, { ignoreQueryPrefix: true })) as State;
};

function isObject(val: any) {
  return val.constructor === Object;
}

function isNumber(val: any) {
  return !isNaN(parseFloat(val)) && isFinite(val);
}

function isBoolean(val: any) {
  return val === "false" || val === "true";
}

function isArray(val: any) {
  return Array.isArray(val);
}

function parseValue(val: any) {
  if (typeof val === "undefined" || val === "") {
    return null;
  } else if (isBoolean(val)) {
    return parseBoolean(val);
  } else if (isArray(val)) {
    return parseArray(val);
  } else if (isObject(val)) {
    return parseObject(val);
  } else if (isNumber(val)) {
    return parseNumber(val);
  } else {
    return val;
  }
}

function parseObject(obj: any) {
  const result: any = {};
  for (const key in obj) {
    const val = parseValue(obj[key]);
    if (val !== null) {
      result[key] = val; // ignore null values
    }
  }
  return result;
}

function parseArray(arr: any): Array<any> {
  var result = [];
  for (var i = 0; i < arr.length; i++) {
    result[i] = parseValue(arr[i]);
  }
  return result;
}

function parseNumber(val: any): number {
  return Number(val);
}

function parseBoolean(val: any): boolean {
  return val === "true";
}
