import React, {
  useState,
  useCallback,
  MouseEventHandler,
  useEffect,
} from "react";

import * as History from "history";
import { Prompt, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ConfirmErrorModalUI } from "../../../components/ConfirmErrorModal";
import { ConfirmModalUI } from "../../../components/ConfirmModal";
import { LoadingUI } from "../../../components/Loading";
import {
  CreateClassAndLessonsInput,
  useCreateClassAndLessonsMutation,
} from "../../../generated/graphql";
import {
  DisplayCourse,
  DisplayClass,
  eventLocations,
  eventLocationType,
} from "../../../lib/constants/classes";
import { ErrorType } from "../../../lib/constants/error";
import ClassNewConfirmPage from "./ClassNewConfirmPage";

const confirmErrorModalTitleForNormal = "登録に失敗しました";
const confirmErrorModalTitleForZoom = "一部登録に失敗しました";
const confirmErrorModalDescriptionForNormal = `クラスの登録に失敗しました。
  しばらく時間を置いてから、もう一度お試しください。`;
const confirmErrorModalDescriptionForZoom = `クラスの登録は完了しましたが、
Zoom URLの自動発行に失敗しました。
クラス編集画面から設定を行ってください。`;

const ClassNewConfirmPageContainer = () => {
  const history = useHistory();
  const [createClassAndLessons] = useCreateClassAndLessonsMutation();

  const location = useLocation<{
    course: DisplayCourse;
    clazzInput: DisplayClass;
  }>();
  const { course } = location.state;
  const { clazzInput } = location.state;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] =
    useState<boolean>(false);
  const [confirmErrorModalTitle, setConfirmErrorModalTitle] = useState<string>(
    confirmErrorModalTitleForNormal
  );
  const [confirmErrorModalDescription, setConfirmErrorModalDescription] =
    useState<string>(confirmErrorModalDescriptionForNormal);
  const [confirmErrorModalVisibility, setConfirmErrorModalVisibility] =
    useState<boolean>(false);
  const [confirmModalVisibility, setConfirmModalVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    return () => {
      isOpenCompleteModal && setIsOpenCompleteModal(false);
    };
  }, [isOpenCompleteModal]);

  const onConfirm = useCallback(async () => {
    const input: CreateClassAndLessonsInput = {
      name: clazzInput.name,
      anyClassId: clazzInput.anyClassId,
      courseId: clazzInput.courseId,
      eventLocation: clazzInput.eventLocation,
      capacity: Number(clazzInput.capacity),
      applicantCountVisibility: clazzInput.applicantCountVisibility,
      autoCreateZoomURL:
        clazzInput.eventLocation === eventLocations[eventLocationType.Zoom]
          ? clazzInput.autoCreateZoomURL
          : false,
      lessons: clazzInput.lessons.map((lesson) => ({
        teacherIds: lesson.teachers.map((teacher) => teacher.id),
        anyLessonId: lesson.anyLessonId,
        lessonMasterId: lesson.lessonMasterId,
        startedAt: lesson.startedAt,
        endedAt: lesson.endedAt,
        locationType: lesson.locationType,
        lessonUrl: lesson.lessonUrl,
      })),
    };
    try {
      if (!isLoading) {
        setIsLoading(true);
        await createClassAndLessons({
          variables: {
            input,
          },
        });
        setIsOpenCompleteModal(true);
      }
    } catch (error: any) {
      console.error(error);
      const errCode = error?.graphQLErrors[0]?.extensions?.code;
      if (errCode === ErrorType.UnAuthenticated) {
        history.push("/error/unauthenticated");
        return;
      } else if (errCode === ErrorType.CreateZoomUrlFailed) {
        setConfirmErrorModalTitle(confirmErrorModalTitleForZoom);
        setConfirmErrorModalDescription(confirmErrorModalDescriptionForZoom);
      }
      setConfirmErrorModalVisibility(true);
    } finally {
      setIsLoading(false);
    }
  }, [history, clazzInput, createClassAndLessons, setIsLoading, isLoading]);

  const handleBack: MouseEventHandler<HTMLButtonElement> = () => {
    history.push(`/courses/${clazzInput.courseId}/class/new`);
  };

  const clearCompletedModal = () => {
    isOpenCompleteModal && setIsOpenCompleteModal(false);
    history.push("/courses");
  };

  const confirmBack = useCallback(() => {
    setConfirmModalVisibility(true);
  }, [setConfirmModalVisibility]);

  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (
        location.pathname === `/courses/${clazzInput.courseId}/class/new` &&
        action === "POP"
      ) {
        confirmBack();
        return false;
      }
      return true;
    },
    [clazzInput, confirmBack]
  );

  return (
    <>
      {isLoading ? <LoadingUI title="送信中" /> : ""}
      <ClassNewConfirmPage
        isLoading={isLoading}
        course={course}
        clazz={clazzInput}
        onConfirm={onConfirm}
        handleBack={confirmBack}
      />
      <CompletedModalUI
        title="追加完了"
        description="追加が完了しました。"
        btnTitle="コース一覧へ"
        onClick={clearCompletedModal}
        visibility={isOpenCompleteModal}
      />
      <ConfirmErrorModalUI
        title={confirmErrorModalTitle}
        description={confirmErrorModalDescription}
        btnTitle="コース一覧へ"
        onClick={clearCompletedModal}
        visibility={confirmErrorModalVisibility}
      />
      <ConfirmModalUI
        title="確認"
        description={`編集中の内容は破棄されます。
  よろしいですか？`}
        onCancel={() => {
          setConfirmModalVisibility(false);
        }}
        onSubmit={handleBack}
        visibility={confirmModalVisibility}
      />
      <Prompt when={true} message={handleBlockBack} />
    </>
  );
};

export default ClassNewConfirmPageContainer;
