import React, { useEffect, useRef, useState } from "react";

import { ContBillPaymentDetailFragment } from "../../../../../generated/graphql";
import { ContBillPaymentTableRowUI } from "./ContBillPaymentTableRow";

export type ContBillPaymentTableUIProps = {
  paymentTransaction: ContBillPaymentDetailFragment;
  onWidthChange?: (width: number | undefined) => void;
};

export const ContBillPaymentTableUI: React.VFC<ContBillPaymentTableUIProps> = ({
  paymentTransaction,
  onWidthChange,
}) => {
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);
  const table = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (table && onWidthChange) {
      const rect: DOMRect | undefined = table.current?.getBoundingClientRect();
      const w = rect !== undefined ? Math.ceil(rect.width) : undefined;
      if (tableWidth !== w) {
        setTableWidth(w);
        onWidthChange(w);
      }
    }
  }, [table, onWidthChange, setTableWidth, tableWidth]);

  return (
    <table
      ref={table}
      className="border-collapse border border-dark-gray"
      style={{ width: "max-content" }}
    >
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-12">
          <th className="border border-dark-gray pl-2 pr-1">継続課金ID</th>
          <th className="border border-dark-gray pl-2 pr-1">会員ID</th>
          <th className="border border-dark-gray pl-2 pr-1">決済商品</th>
          <th className="border border-dark-gray pl-2 pr-1">
            課金タイミング区分
          </th>
          <th className="border border-dark-gray pl-2 pr-1">課金タイミング</th>
          <th className="border border-dark-gray pl-2 pr-1">初回課金年月日</th>
          <th className="border border-dark-gray pl-2 pr-1">初回金額</th>
          <th className="border border-dark-gray pl-2 pr-1">次回以降金額</th>
          <th className="border border-dark-gray pl-2 pr-1">加盟店管理番号</th>
          <th className="border border-dark-gray pl-2 pr-1">支払方法</th>
          <th className="border border-dark-gray pl-2 pr-1">ステータス</th>
          <th className="border border-dark-gray pl-2 pr-1">入会処理日</th>
          <th className="border border-dark-gray pl-2 pr-1">退会処理日</th>
        </tr>
      </thead>
      <tbody>
        <ContBillPaymentTableRowUI
          data-testid="payment-mismatch-payment-table"
          paymentTransaction={paymentTransaction}
        />
      </tbody>
    </table>
  );
};
