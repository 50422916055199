import React, {
  InputHTMLAttributes,
  ChangeEventHandler,
  useCallback,
} from "react";

import { FieldErrors, UseFormRegister } from "react-hook-form";

import { InputUI } from "../../components/Input";
import { ToggleButtonUI } from "../../components/ToggleButton";
import { QuestionnaireType } from "../../generated/graphql";
import {
  courseQuestionnaireType,
  multiSelectCondition,
} from "../../lib/constants/courses";
import {
  CourseEdit,
  CourseNew,
  CourseQuestionnaire,
} from "../../lib/types/course";
import { ForValidation } from "../CourseEdit";
import { SelectCustomStyleUI } from "../SelectCustomStyle";
import { CheckQuestionsUI } from "./CheckBoxQuestion";
import { LinearScaleQuestionUI } from "./LinearScaleQuestion";
import styles from "./Questionnaire.module.scss";
import { QuestionnaireTitleUI } from "./QuestionnaireTitle";
import { RadioQuestionsUI } from "./RadioButtonQuestion";
import { ReactComponent as TrashIcon } from "./trash.svg";

export type QuestionnaireUIProps = {
  questionnaire: CourseQuestionnaire;
  deleteDisabled?: boolean;
  editable: boolean;
  onChangeQuestionnaire: (questionnaire: CourseQuestionnaire) => void;
  onDeleteQuestionnaire: (order: number) => void;
  register: UseFormRegister<CourseNew | CourseEdit>;
  errors: FieldErrors<ForValidation>;
} & InputHTMLAttributes<HTMLInputElement>;

export const QuestionnaireUI: React.FC<QuestionnaireUIProps> = ({
  questionnaire,
  value,
  className = "",
  children,
  deleteDisabled = false,
  editable = true,
  onChangeQuestionnaire,
  onDeleteQuestionnaire,
  register,
  errors,
  ...rest
}) => {
  const onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        [event.target.name]: event.target.value,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  const onChangeSelectCustomInput = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        [name]: value,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  const onChangeRequired = useCallback(
    (checked: boolean) => {
      const newQuestionnaire: CourseQuestionnaire = {
        ...questionnaire,
        required: checked,
      };
      onChangeQuestionnaire(newQuestionnaire);
    },
    [questionnaire, onChangeQuestionnaire]
  );

  const onValidate = useCallback((questionnaire: CourseQuestionnaire) => {
    if (
      questionnaire.typenameDisplay === undefined ||
      questionnaire.typenameDisplay === ""
    ) {
      return true;
    }
    if (questionnaire.title === "") {
      return false;
    }
    return true;
  }, []);

  return (
    <div className={`flex flex-row ${className}`}>
      <label className="text-subtitle text-lg pr-4 leading-10">
        設問{questionnaire.order}
      </label>
      <div className={`${styles.base}`}>
        <div className="ml-5 mt-3 w-full">
          <div className="inline-block w-11/12 my-1">アンケートタイトル</div>
          {editable && (
            <div className="inline-block w-1/12 align-middle">
              <button
                type="button"
                onClick={() => {
                  !deleteDisabled && onDeleteQuestionnaire(questionnaire.order);
                }}
              >
                <TrashIcon
                  data-testid="course-questionnaire-trash"
                  className={`fill-current ${
                    deleteDisabled ? "text-dark-gray" : "text-blue"
                  }`}
                />
              </button>
            </div>
          )}
        </div>
        <QuestionnaireTitleUI
          disabled={!editable}
          questionnaire={questionnaire}
          onChangeInput={onChangeInput}
          errorIdPrefix={`questionnaires-${questionnaire.order}-title-error`}
          registerParams={{
            register,
            error: errors.questionnaires?.[questionnaire.order]?.title,
            label: `questionnaires.${questionnaire.order}.title`,
            conditions: {
              validate: () =>
                onValidate(questionnaire) ||
                "アンケートタイトルは必須項目です。",
            },
          }}
        />
        <div className="ml-5 mt-5 w-full">
          <div className="inline-block w-7/12">回答形式</div>
          {questionnaire.typenameDisplay ===
            QuestionnaireType.MultiSelectQuestionnaire &&
            questionnaire.required && (
              <div className="inline-block w-5/12">回答個数設定(任意)</div>
            )}
        </div>
        <div className="ml-8 flex items-center mb-2.5 mt-2.5">
          <div className="flex flex-row items-center">
            <SelectCustomStyleUI
              data-testid={`questionnaire-type-${questionnaire.order}`}
              disabled={!editable}
              className="w-40 text-black border-black mb-12 flex-none"
              options={courseQuestionnaireType}
              id={`typenameDisplay-${questionnaire.order}`}
              name="typenameDisplay"
              value={`${
                courseQuestionnaireType.find(
                  (q) => q.value === questionnaire.typenameDisplay
                )?.label
              }`}
              blackBorder={true}
              onChange={onChangeSelectCustomInput}
              aria-label={`questionnaire-type-${questionnaire.order}`}
            />
            <div className="flex flex-row space-x-2 ml-3">
              <div>必須設定</div>
              <ToggleButtonUI
                required={questionnaire.required}
                id="required"
                disabled={!editable}
                onCheckChange={onChangeRequired}
              />
            </div>
          </div>

          {questionnaire.typenameDisplay ===
            QuestionnaireType.MultiSelectQuestionnaire &&
            questionnaire.required && (
              <div className="flex flex-row items-end ml-16">
                <InputUI
                  id="selectableNumber"
                  name="selectableNumber"
                  className="border mr-2 h-12 w-12 text-lg text-center border-black"
                  data-testid={`course-questionnaires-${questionnaire.order}-selectable-number`}
                  type="number"
                  min="1"
                  max={`${questionnaire.options?.length}`}
                  value={questionnaire.selectableNumber}
                  disabled={!editable}
                  onChange={onChangeInput}
                  onWheelCapture={(e) => {
                    e.currentTarget.blur();
                  }}
                />
                個
                <SelectCustomStyleUI
                  className="ml-2.5 text-black w-24 border-black mb-12"
                  options={multiSelectCondition}
                  id={`condition-${questionnaire.order}`}
                  name="condition"
                  disabled={!editable}
                  blackBorder={true}
                  value={`${
                    multiSelectCondition.find(
                      (condition) => condition.value === questionnaire.condition
                    )?.label
                  }`}
                  onChange={onChangeSelectCustomInput}
                />
              </div>
            )}
          {questionnaire.typenameDisplay ===
            QuestionnaireType.LinearScaleQuestionnaire && (
            <div className="ml-5">
              最大値
              <InputUI
                id="maxScale"
                name="maxScale"
                data-testid={`course-questionnaires-${questionnaire.order}-linear-limit`}
                className="border ml-6 mr-2 h-9 w-14 text-lg text-left border-black"
                type="number"
                min="1"
                max="10"
                disabled={!editable}
                value={questionnaire.maxScale || 1}
                onChange={onChangeInput}
              />
            </div>
          )}
        </div>
        <div className="ml-8 mt-5">
          {questionnaire.typenameDisplay ===
            QuestionnaireType.SelectQuestionnaire && (
            <RadioQuestionsUI
              questionnaireKey={questionnaire.order}
              questionnaire={questionnaire}
              disabled={!editable}
              onChangeQuestionnaire={onChangeQuestionnaire}
              register={register}
              errors={errors}
            />
          )}
          {questionnaire.typenameDisplay ===
            QuestionnaireType.MultiSelectQuestionnaire && (
            <CheckQuestionsUI
              questionnaireKey={questionnaire.order}
              questionnaire={questionnaire}
              disabled={!editable}
              onChangeQuestionnaire={onChangeQuestionnaire}
              register={register}
              errors={errors}
            />
          )}
          {questionnaire.typenameDisplay ===
            QuestionnaireType.LinearScaleQuestionnaire && (
            <LinearScaleQuestionUI
              questionnaire={questionnaire}
              disabled={!editable}
              onChangeQuestionnaire={onChangeQuestionnaire}
            />
          )}
        </div>
      </div>
    </div>
  );
};
