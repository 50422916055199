import React, { ChangeEventHandler } from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import { UsersEditPageFragment } from "../../generated/graphql";
import {
  genders,
  roles,
  statuses,
  yearsOfTeaching,
  DisplayRole,
} from "../../lib/constants";
import { PageType } from "../../lib/constants/pageType";
import { preventFromSubmittingByEnter } from "../../lib/functions";
import { UserEdit } from "../../lib/types";
import { BadgeUI } from "../Badge";
import { ButtonUI } from "../Button";
import { ReactComponent as ErrorCheckIcon } from "../errorCheck.svg";
import { FileInputUI } from "../FileInput";
import { FormGroupUI } from "../FormGroup";
import { InputUI } from "../Input";
import { LabelUI } from "../Label";
import { RadioUI } from "../Radio";
import { SelectUI } from "../Select";
import { TextUI } from "../Text";
import { ValidatableInputUI } from "../ValidatableInput";
import { ValidatableRadioUI } from "../ValidatableRadio";
import { ValidatableTextareaUI } from "../ValidatableTextarea";
import { DateInputWithCalendarIconUI } from "./components/DateInputWithCalendarIcon";

export interface UserEditUIProps {
  user: UserEdit;
  type: string;
  hasAdminAuthority?: boolean;
  avatorUrl: string | null;
  onHandleSubmit: SubmitHandler<UserEdit>;
  onChangeInput: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeRoles?: ChangeEventHandler<HTMLInputElement>;
  onChangeRadio: ChangeEventHandler<HTMLInputElement>;
  onChangeFile: ChangeEventHandler<HTMLInputElement>;
  handleBack: () => void;
  isErrorSelectImage: boolean;
  isErrorFileSizeOver: boolean;
  pageType: PageType;
  isErrorExistsUser: boolean;
  useableServiceKinds?: UsersEditPageFragment["useableServiceKinds"];
}

export const UserEditUI: React.VFC<UserEditUIProps> = ({
  user,
  type,
  hasAdminAuthority = false,
  onHandleSubmit,
  onChangeInput,
  onChangeRoles,
  onChangeRadio,
  onChangeFile,
  handleBack,
  avatorUrl,
  isErrorSelectImage,
  isErrorFileSizeOver,
  pageType,
  isErrorExistsUser,
  useableServiceKinds = [],
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserEdit>({
    defaultValues: {
      email: user.email,
      tel: user.tel,
      birthplace: user.birthplace,
      message: user.message,
      selfIntroduction: user.selfIntroduction,
      hobby: user.hobby,
      familyName: user.familyName,
      givenName: user.givenName,
    },
  });

  return (
    <div className="divide-y divide-under-line ml-5">
      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        onKeyDown={(event) => preventFromSubmittingByEnter(event)}
        className="pb-20"
      >
        {pageType === PageType.UsersExternalNewPage ? (
          <>
            <FormGroupUI>
              <div className="flex flex-row items-end space-x-2.5">
                <LabelUI>メールアドレス(外部ユーザーID)</LabelUI>
                <BadgeUI>必須</BadgeUI>
              </div>
              <div className="flex-col">
                <ValidatableInputUI
                  data-testid="users-edit-external-userid"
                  errorIdPrefix="users-edit-external-userid-error"
                  registerParams={{
                    register,
                    label: "email",
                    error: errors.email,
                    conditions: {
                      pattern: {
                        // eslint-disable-next-line
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        message: "メールアドレス形式で入力してください。",
                      },
                      required: {
                        value: true,
                        message: "メールアドレスは必須入力項目です。",
                      },
                      validate: () => !isErrorExistsUser,
                    },
                  }}
                  className="w-7/12"
                  value={user.email}
                  placeholder="メールアドレスを入力してください。"
                  id="email"
                  name="email"
                  onChange={onChangeInput}
                />
              </div>
              {isErrorExistsUser ? (
                <>
                  {errors.email?.type === "validate" && (
                    <p
                      className="text-error mt-1.5 ml-8"
                      data-testid={`error-exist-user-message`}
                    >
                      すでに登録されているユーザーIDです。
                    </p>
                  )}
                </>
              ) : (
                ""
              )}

              <div className="text-gray-dark mt-4">
                外部ユーザーIDは登録後変更できません。送信先のメールアドレスは後でも変更できます。
              </div>
            </FormGroupUI>
          </>
        ) : (
          <FormGroupUI>
            <LabelUI data-testid="users-title-userId">
              {user.extra && "外部"}ユーザーID
            </LabelUI>
            <TextUI data-testid="users-edit-userId">{user.userId}</TextUI>
          </FormGroupUI>
        )}
        <hr />
        {pageType === PageType.UsersExternalNewPage ? (
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI>名前</LabelUI>
              <BadgeUI>必須</BadgeUI>
            </div>
            <div className="flex items-center space-x-10">
              <div className="space-x-2.5">
                <ValidatableInputUI
                  data-testid="users-edit-familyName"
                  errorIdPrefix="users-edit-familyName-error"
                  registerParams={{
                    register,
                    label: "familyName",
                    error: errors.familyName,
                    conditions: {
                      required: {
                        value: true,
                        message: "名前は必須入力項目です。",
                      },
                    },
                  }}
                  value={user.familyName}
                  placeholder="au"
                  id="familyName"
                  name="familyName"
                  onChange={onChangeInput}
                />
              </div>
              <div className="space-x-2.5">
                <ValidatableInputUI
                  data-testid="users-edit-givenName"
                  errorIdPrefix="users-edit-givenName-error"
                  registerParams={{
                    register,
                    label: "givenName",
                    error: errors.givenName,
                    conditions: {
                      required: {
                        value: true,
                        message: "名前は必須入力項目です。",
                      },
                    },
                  }}
                  value={user.givenName}
                  placeholder="太郎"
                  id="givenName"
                  name="givenName"
                  onChange={onChangeInput}
                />
              </div>
            </div>
          </FormGroupUI>
        ) : (
          <FormGroupUI>
            <LabelUI>名前</LabelUI>
            <TextUI data-testid="users-edit-name">
              {user.familyName} {user.givenName}
            </TextUI>
          </FormGroupUI>
        )}

        <hr />
        <FormGroupUI>
          <LabelUI>名前（フリガナ）</LabelUI>
          <div className="flex items-center space-x-10">
            <div className="space-x-2.5">
              <InputUI
                data-testid="users-edit-familyNameKana"
                value={user.familyNameKana!}
                id="familyNameKana"
                name="familyNameKana"
                placeholder="エーユー"
                onChange={onChangeInput}
              />
            </div>
            <div className="space-x-2.5">
              <InputUI
                data-testid="users-edit-givenNameKana"
                value={user.givenNameKana!}
                id="givenNameKana"
                name="givenNameKana"
                placeholder="タロウ"
                onChange={onChangeInput}
              />
            </div>
          </div>
        </FormGroupUI>
        <hr />
        {!user.extra && (
          <>
            <FormGroupUI>
              <LabelUI>サービス別権限</LabelUI>
              <TextUI data-testid="users-edit-serviceKinds">
                {useableServiceKinds
                  .map((serviceKind) => serviceKind.shortName)
                  .join(", ")}
              </TextUI>
            </FormGroupUI>
            <hr />
            <FormGroupUI>
              <LabelUI data-testid="users-title-role">ロール別権限</LabelUI>
              {/* ユーザー編集ページで管理者権限を持つユーザーがシステム管理者または運用監視者ロール"以外"のユーザーを閲覧するとき */}
              {type === "user" &&
              hasAdminAuthority &&
              !user.roleIds?.some(
                (roleId: string) => roleId === "1" || roleId === "7"
              ) ? (
                <div className="flex space-x-16">
                  <div className="flex flex-col w-full">
                    <div
                      className="flex items-center space-x-3"
                      data-testid="users-edit-roleIds-wrapper"
                    >
                      {errors.roleIds && (
                        <ErrorCheckIcon data-testid="users-edit-roleids-error-icon" />
                      )}
                      {roles
                        .filter(
                          (role: DisplayRole) =>
                            role.id !== "systemAdministrator" &&
                            role.id !== "operationMonitor"
                        )
                        .map((role) => (
                          <ValidatableRadioUI
                            registerParams={{
                              register,
                              error: errors.roleIds,
                              label: "roleIds",
                              conditions: {
                                required: true,
                              },
                            }}
                            data-testid="users-edit-roleIds"
                            id={role.id}
                            name="roleIds"
                            key={role.id}
                            value={role.roleId}
                            checked={user.roleIds!.includes(role.roleId)}
                            onChange={onChangeRoles}
                          >
                            {role.label}
                          </ValidatableRadioUI>
                        ))}
                    </div>
                    {errors.roleIds && (
                      <p
                        data-testid="users-edit-roleids-error-message"
                        className="text-error mt-1.5 ml-8"
                      >
                        選択必須項目です。
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <TextUI data-testid="users-edit-roleIds">
                  {user
                    .roleIds!.map(
                      (roleId: string) =>
                        roles.find(
                          (role: DisplayRole) => role.roleId === roleId
                        )?.label
                    )
                    .join(", ")}
                </TextUI>
              )}
            </FormGroupUI>
            <hr />
          </>
        )}

        {pageType === PageType.UsersExternalNewPage ? (
          ""
        ) : (
          <FormGroupUI>
            <div className="flex flex-row items-end space-x-2.5">
              <LabelUI>メールアドレス</LabelUI>
            </div>
            <div className="flex-col">
              <ValidatableInputUI
                data-testid="users-edit-email"
                errorIdPrefix="users-edit-email-error"
                registerParams={{
                  register,
                  label: "email",
                  error: errors.email,
                  conditions: {
                    pattern: {
                      // eslint-disable-next-line
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      message: "メールアドレス形式で入力してください。",
                    },
                  },
                }}
                className="w-7/12"
                value={user.email}
                placeholder="メールアドレスを入力してください。"
                id="email"
                name="email"
                onChange={onChangeInput}
              />
            </div>
          </FormGroupUI>
        )}

        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>電話番号(ハイフンなし)</LabelUI>
          </div>
          <div className="flex">
            <ValidatableInputUI
              data-testid="users-edit-tel"
              errorIdPrefix="users-edit-tel-error"
              registerParams={{
                register,
                error: errors.tel,
                label: "tel",
                conditions: {
                  pattern: {
                    value: /^[0-9]{0,30}$/,
                    message: "30文字以内の半角数字を入力してください。",
                  },
                },
              }}
              className="w-7/12"
              value={user.tel!}
              placeholder="電話番号(ハイフンなし)を入力してください。"
              id="tel"
              name="tel"
              onChange={onChangeInput}
            />
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>性別</LabelUI>
          </div>
          <div className="flex space-x-10">
            {genders.map((gender) => (
              <RadioUI
                data-testid="users-edit-gender"
                key={gender.value}
                id={`gender-${gender.value}`}
                name="gender"
                value={gender.value}
                checked={user.gender === gender.value}
                onChange={onChangeRadio}
              >
                {gender.label}
              </RadioUI>
            ))}
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>生年月日</LabelUI>
          <DateInputWithCalendarIconUI
            data-testid="users-edit-birthday"
            value={user.birthday!}
            id="birthday"
            name="birthday"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>出身国</LabelUI>
          <ValidatableInputUI
            registerParams={{
              register,
              error: errors.birthplace,
              label: "birthplace",
              conditions: {
                maxLength: {
                  value: 100,
                  message: "100文字以内で入力してください。",
                },
              },
            }}
            data-testid="users-edit-birthplace"
            errorIdPrefix="users-edit-birthplace-error"
            className="w-7/12"
            value={user.birthplace!}
            type="text"
            id="birthplace"
            name="birthplace"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />

        <FormGroupUI>
          <LabelUI data-testid="users-title-years-of-teaching">
            {user.extra ? "教師歴" : "職歴"}
          </LabelUI>
          <SelectUI
            data-testid="users-edit-yearsOfTeaching"
            className="w-5/12"
            options={yearsOfTeaching}
            value={`${user.yearsOfTeaching}`}
            id="yearsOfTeaching"
            name="yearsOfTeaching"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>メッセージ</LabelUI>
          <ValidatableTextareaUI
            registerParams={{
              register,
              error: errors.message,
              label: "message",
              conditions: {
                maxLength: {
                  value: 100,
                  message: "100文字以内で入力してください。",
                },
              },
            }}
            data-testid="users-edit-message"
            errorIdPrefix="users-edit-message-error"
            rows={3}
            className="w-10/12"
            value={user.message!}
            id="message"
            name="message"
            placeholder="メッセージを入力してください。"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>自己紹介</LabelUI>
          <ValidatableTextareaUI
            registerParams={{
              register,
              error: errors.selfIntroduction,
              label: "selfIntroduction",
              conditions: {
                maxLength: {
                  value: 200,
                  message: "200文字以内で入力してください。",
                },
              },
            }}
            data-testid="users-edit-selfIntroduction"
            errorIdPrefix="users-edit-self-introduction-error"
            rows={3}
            className="w-10/12"
            value={user.selfIntroduction!}
            placeholder="自己紹介を入力してください。"
            id="selfIntroduction"
            name="selfIntroduction"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>趣味</LabelUI>
          <ValidatableTextareaUI
            registerParams={{
              register,
              error: errors.hobby,
              label: "hobby",
              conditions: {
                maxLength: {
                  value: 200,
                  message: "200文字以内で入力してください。",
                },
              },
            }}
            data-testid="users-edit-hobby"
            rows={3}
            className="w-10/12"
            value={user.hobby!}
            id="hobby"
            name="hobby"
            placeholder="趣味を入力してください。"
            onChange={onChangeInput}
          />
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <LabelUI>画像</LabelUI>
          <div className="flex flex-row">
            <FileInputUI
              data-testid="users-edit-avator"
              id="avatorUpload"
              name="avator"
              src={avatorUrl!}
              onChange={onChangeFile}
            />
            <div className="flex flex-col justify-center pl-16">
              <TextUI>画像形式 : JPEG または PNG</TextUI>
              <TextUI>推奨サイズ : 1500ピクセル × 1500ピクセル</TextUI>
              <TextUI>ファイルサイズ : 最大 10 MB</TextUI>
              {isErrorSelectImage && (
                <TextUI
                  className="text-error"
                  data-testid="users-edit-avator-type-error-message"
                >
                  .jpg または .png 形式の画像を指定してください。
                </TextUI>
              )}
              {isErrorFileSizeOver && (
                <TextUI
                  className="text-error"
                  data-testid="users-edit-avator-size-error-message"
                >
                  アップロード可能なファイルサイズは最大10MBです。
                </TextUI>
              )}
            </div>
          </div>
        </FormGroupUI>
        <hr />
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI>ステータス</LabelUI>
            {type === "user" && <BadgeUI>必須</BadgeUI>}
          </div>
          {type === "user" ? (
            <div className="flex space-x-10">
              {statuses.map((status) => (
                <RadioUI
                  data-testid="users-edit-status"
                  key={status.value}
                  id={`status-${status.value}`}
                  name="status"
                  value={status.value}
                  checked={user.status === status.value}
                  onChange={onChangeRadio}
                >
                  {status.label}
                </RadioUI>
              ))}
            </div>
          ) : (
            <TextUI data-testid="users-edit-status">
              {statuses.find((status) => status.value === user.status)?.label}
            </TextUI>
          )}
        </FormGroupUI>
        <div className="flex justify-center space-x-10 m-12">
          <ButtonUI
            type="button"
            data-testid="users-edit-back"
            buttonType="secondary"
            onClick={handleBack}
          >
            戻る
          </ButtonUI>
          <ButtonUI type="submit" data-testid="users-edit-submit">
            確認
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};

export default UserEditUI;
