import React, { SelectHTMLAttributes } from "react";

import styles from "./Select.module.css";

export type SelectOption = {
  value: string;
  label: string;
};
export type SelectUIProps = {
  options: SelectOption[];
  placeholder?: string | number;
  hiddenPlaceholder?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement>;

export const SelectUI = React.forwardRef<HTMLSelectElement, SelectUIProps>(
  (
    {
      options,
      className = "",
      id,
      name,
      placeholder = "選択項目",
      hiddenPlaceholder = false,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <select
        ref={ref}
        className={`appearance-none h-12 leading-5 pl-4 tracking-wider border rounded relative ${styles.arrow} ${className}`}
        id={id}
        name={name}
        {...rest}
      >
        {!hiddenPlaceholder && <option>{placeholder}</option>}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }
);
