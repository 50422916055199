import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import isBetween from "dayjs/plugin/isBetween";

import { ButtonUI } from "../../../../components/Button";
import { LabelUI } from "../../../../components/Label";
import { ToggleButtonUI } from "../../../../components/ToggleButton";
import {
  PublishClazzFragment,
  PublishCoursesItemFragment,
} from "../../../../generated/graphql";
import { ReactComponent as CheckBlueIcon } from "./checkBlue.svg";
import styles from "./CoursesItem.module.scss";
import { ReactComponent as ErrorIcon } from "./errorIcon.svg";
import { ReactComponent as PenIcon } from "./pen.svg";
import { ReactComponent as StopIcon } from "./stopIcon.svg";

dayjs.extend(isBetween);

dayjs.locale(ja);
export type CoursesItemProps = {
  course: PublishCoursesItemFragment;
  onOpenPublishModal: (courseId: string, clazz: PublishClazzFragment) => void;
  changePreviewToggle: (course: PublishCoursesItemFragment) => Promise<void>;
};

const CoursesItemUI: React.FC<CoursesItemProps> = ({
  course,
  onOpenPublishModal,
  changePreviewToggle,
}) => {
  const getScheduledDate = (startedAt: Date, endedAt: Date) => {
    return (
      startedAt &&
      endedAt &&
      `${dayjs(startedAt).format("YYYY/MM/DD HH:mm")} ~ ${dayjs(endedAt).format(
        "YYYY/MM/DD HH:mm"
      )}`
    );
  };

  const compareDay = (startedAt: Date, endedAt: Date): boolean => {
    const now = dayjs();
    return now.isBetween(dayjs(startedAt), dayjs(endedAt));
  };

  // FIXME: 一旦ローカルは考慮しない。対応する場合は、ローカルかどうかを判定した上でport番号変換でよさそう。
  const b2cDomain = `${window.location.origin}`.replace("staff.", "");

  return (
    <div className="mb-12 bg-light-blue" data-testid="courses-item">
      <div
        className={`flex flex-row bg-white items-center p-2 mb-5 ${styles.boxShadow}`}
      >
        <p
          className="flex-none w-24 text-center"
          data-testid="course-item-system-id"
        >
          {course.systemId}
        </p>
        <h3
          className="flex-none text-lg tracking-wider leading-none"
          data-testid="course-item-course-name"
        >
          {course.name}
        </h3>
        <div className="ml-auto flex-none inline-flex justify-items-center justify-center items-center">
          <LabelUI className="text-lg mr-3">プレビュー公開</LabelUI>
          <ToggleButtonUI
            data-testid={`course-item-preview-toggle-${course.id}`}
            required={course.previewToken?.token !== undefined}
            className="mr-5"
            id="required"
            onClick={() => changePreviewToggle(course)}
          />
          <ButtonUI
            data-testid={`course-item-preview-btn-${course.id}`}
            size="xs"
            base="rounded20"
            disabled={course.previewToken?.token === undefined}
            onClick={() => {
              window.open(
                `${b2cDomain}/courses/${course.id}/preview/${course.previewToken?.token}`,
                "_blank"
              );
            }}
          >
            プレビュー
          </ButtonUI>
        </div>
      </div>

      <table className="w-full border-collapse text-center">
        <thead className="bg-light-blue text-black align-top border-b-4 border-gray-light">
          <tr>
            <th className="w-2/24 pb-4"></th>
            <th className="w-3/24 pb-4">システムID</th>
            <th className="w-2/12 pb-4">授業開始日時</th>
            <th className="w-2/12 pb-4">授業終了日時</th>
            <th className="w-4/12 pb-4">販売期間（キャンセル可能期間）</th>
            <th className="w-3/24 pb-4">公開ステータス</th>
          </tr>
        </thead>
        <tbody className="">
          {course?.clazzes?.map((clazz: PublishClazzFragment, i) => (
            <tr
              key={i}
              data-testid="class-detail-table-row"
              className={`h-16 ${i % 2 !== 0 ? "" : "bg-white"}`}
            >
              <td className="border-r-2 border-under-line px-1 text-subtitle text-center">
                <PenIcon
                  className="mx-auto"
                  onClick={() => onOpenPublishModal(course.id, clazz)}
                />
              </td>
              <td
                data-testid={`clazz-systemId-${i}`}
                className="border-r-2 border-under-line px-1"
              >
                {clazz.systemId}
              </td>
              <td
                data-testid={`clazz-lesson-startedAt-${i}`}
                className="border-r-2 border-under-line px-1"
              >
                {clazz.lessons!.length &&
                  dayjs(clazz.lessons![0].startedAt).format("YYYY/MM/DD HH:mm")}
              </td>
              <td
                data-testid={`clazz-lesson-endedAt-${i}`}
                className="border-r-2 border-under-line px-1"
              >
                {clazz.lessons!.length &&
                  dayjs(
                    clazz.lessons![clazz.lessons!.length - 1].endedAt
                  ).format("YYYY/MM/DD HH:mm")}
              </td>
              <td
                data-testid={`clazz-scheduled-date-${i}`}
                className="border-r-2 border-under-line px-1"
              >
                {!clazz.published &&
                compareDay(clazz.saleStartedAt!, clazz.saleEndedAt!) ? (
                  <div className="flex mx-auto justify-center">
                    <span className={styles.saleError}>
                      <div className={styles.errorText}>
                        <span>販売期間内で、非公開になっています。</span>
                      </div>
                      <ErrorIcon className="mr-2 cursor-pointer" />
                    </span>

                    <span className="text-red">
                      {getScheduledDate(
                        clazz.saleStartedAt || dayjs().toDate(),
                        clazz.saleEndedAt || dayjs().toDate()
                      )}
                    </span>
                  </div>
                ) : (
                  <>
                    {getScheduledDate(
                      clazz.saleStartedAt || dayjs().toDate(),
                      clazz.saleEndedAt || dayjs().toDate()
                    )}
                  </>
                )}
              </td>
              <td
                className="px-1"
                data-testid={`clazz-publication-status-${i}`}
              >
                <div className="flex mx-auto justify-center">
                  {clazz.published ? (
                    <>
                      <CheckBlueIcon className="mr-2" />
                      <span className={`text-blue-navy`}>公開</span>
                    </>
                  ) : (
                    <>
                      <StopIcon className="mr-2" />
                      <span className={`text-red`}>非公開</span>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesItemUI;
