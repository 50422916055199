import React from "react";

import { ContBillPaymentMismatchMplStatusFragment } from "../../../../../generated/graphql";
import {
  formatYYYYMMDD,
  getPaymentStatusJA,
  getPaymtdJA,
} from "../../../../../lib/utils/payment";

export type ContBillMplStatusTableProps = {
  mplStatus: ContBillPaymentMismatchMplStatusFragment;
};

export const ContBillMplStatusTableUI: React.FC<
  ContBillMplStatusTableProps
> = ({ mplStatus }) => {
  return (
    <table
      className="w-full h-52 mb-4 table-fixed break-words"
      data-testid="payment-mismatch-mpl-status"
    >
      <tbody>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">処理種別</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-processType"
          >
            {mplStatus?.processType}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">
            最終処理年月日
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-processDateTime"
          >
            {formatYYYYMMDD(mplStatus?.processDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">決済情報番号</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-payInfoNo"
          >
            {mplStatus?.payInfoNo}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">
            オーソリ年月日
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-authDateTime"
          >
            {formatYYYYMMDD(mplStatus?.authDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">金額</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-priceValue"
          >
            {Number(mplStatus?.priceValue).toLocaleString("ja")}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">
            売上確定年月日
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-soldDateTime"
          >
            {formatYYYYMMDD(mplStatus?.soldDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">加盟店ID</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-memberId"
          >
            {mplStatus?.memberId}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">取消年月日</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-cancelDateTime"
          >
            {formatYYYYMMDD(mplStatus?.cancelDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">サービスID</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-serviceId"
          >
            {mplStatus?.serviceId}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">請求年月日</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-claimDateTime"
          >
            {formatYYYYMMDD(mplStatus?.claimDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">OpenID</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-openId"
          >
            {mplStatus?.openId}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">
            返金処理年月日
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-refundDateTime"
          >
            {formatYYYYMMDD(mplStatus?.refundDateTime)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">au one-ID</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-auOneID"
          >
            {/* NOTE: システムauIDは流出してはいけない情報なので「あり」か「なし」が表示される */}
            {mplStatus.auOneId}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">備考</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-note"
          >
            {mplStatus?.note}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">電話番号</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-tel"
          >
            {mplStatus?.tel}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">予約領域1</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-reserved1"
          >
            {mplStatus?.reserved1}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">
            加盟店管理番号
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-memberManageNo"
          >
            {mplStatus?.memberManageNo}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">継続課金ID</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-continueAccountId"
          >
            {mplStatus?.continueAccountId}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">摘要</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-commodity"
          >
            {mplStatus?.commodity}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">支払方法</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-paymtd"
          >
            {getPaymtdJA(mplStatus?.paymtd)}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">
            処理結果コード
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-resultCode"
          >
            {mplStatus?.resultCode}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">予約領域3</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-reserved3"
          >
            {mplStatus?.reserved3}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">
            サービス名略称
          </td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-serviceShortName"
          >
            {mplStatus?.serviceShortName}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24">予約領域4</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-reserved4"
          >
            {mplStatus?.reserved4}
          </td>
        </tr>
        <tr>
          <td className="border border-dark-gray pl-2 w-5/24">ステータス</td>
          <td
            className="border border-dark-gray pl-2 w-7/24"
            data-testid="cont-bill-payment-mismatch-mpl-status"
          >
            {getPaymentStatusJA(mplStatus?.status)}
          </td>
          <td className="border border-dark-gray pl-2 w-5/24"></td>
          <td className="border border-dark-gray pl-2 w-7/24"></td>
        </tr>
      </tbody>
    </table>
  );
};
