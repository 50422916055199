import {
  ContactRoute,
  ContactStatus,
  ServiceKind,
} from "../../generated/graphql";
export const getRouteName = (contactRoute: ContactRoute) => {
  return {
    [ContactRoute.Tel]: "電話",
    [ContactRoute.Email]: "メール",
    [ContactRoute.Form]: "フォーム",
    [ContactRoute.Others]: "その他",
  }[contactRoute];
};

export const getStatusName = (
  contactStatus: ContactStatus | undefined | null
) => {
  if (!contactStatus) {
    return "";
  }
  return {
    [ContactStatus.Unsupported]: "未対応",
    [ContactStatus.InProgress]: "対応中",
    [ContactStatus.Supported]: "対応済",
  }[contactStatus];
};

export const getServiceKind = (serviceKind: ServiceKind | undefined | null) => {
  if (!serviceKind) {
    return "AEON";
  }
  return {
    [ServiceKind.Aeon]: "AEON",
    [ServiceKind.LiberalArts]: "社会人",
    [ServiceKind.ElementarySchool]: "小学生",
    [ServiceKind.AuEnglish]: "共創事業",
  }[serviceKind];
};
