import React from "react";

import { useParams } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { useVideoLessonPageQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import VideoLessonPage from "./VideoLessonPage";

const VideoLessonPageContainer: React.VFC = () => {
  const { expertId, videoLessonId } = useParams<{
    expertId: string;
    videoLessonId: string;
  }>();

  const errorRouter = useErrorRouter();
  const { data, loading } = useVideoLessonPageQuery({
    variables: { id: videoLessonId },
    onError: errorRouter,
  });
  if (loading || !data) {
    return <LoadingUI />;
  }

  return <VideoLessonPage expertId={expertId} videoLesson={data.videoLesson} />;
};

export default VideoLessonPageContainer;
