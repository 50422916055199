import React, { useCallback, useEffect, useState } from "react";

import { useCurrentServiceKind } from "../../../lib/cache";
import { MasterType, MASTERS } from "./constants";
import MastersPage from "./MastersPage";

const MastersPageContainer: React.VFC = () => {
  const currentServiceKind = useCurrentServiceKind();
  const [masterType, setMasterType] = useState<MasterType | null>(null);

  useEffect(() => {
    if (masterType || !currentServiceKind) return;

    // masterTypeの初期値設定
    setMasterType(
      MASTERS.find((m) => m.availableServices.includes(currentServiceKind.key))
        ?.masterType ?? null
    );
  }, [currentServiceKind, masterType]);

  const onChangeMaster = useCallback((type: MasterType) => {
    setMasterType(type);
  }, []);

  if (!masterType) return <></>;

  return (
    <>
      <MastersPage masterType={masterType} onChangeMaster={onChangeMaster} />
    </>
  );
};

export default MastersPageContainer;
