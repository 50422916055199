import React, { useEffect, useState } from "react";
import { useCallback } from "react";

import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useFormContext } from "react-hook-form";

import { ReactComponent as JpgIcon } from "./jpg.svg";
import { ReactComponent as PngIcon } from "./png.svg";

export type ImageAttr = {
  src: string;
  alt: string;
};

export type ImageConditions = {
  px: { width: number; height: number };
  size: number;
};

export type NewImageInputUIProps = {
  image?: string | File;
  rounded?: boolean;
  imageConditions: ImageConditions;
  error?: boolean;
  testidPrefix?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const NewImageInputUI: React.VFC<NewImageInputUIProps> = React.forwardRef<
  HTMLInputElement,
  NewImageInputUIProps
>(
  (
    {
      name = "",
      image,
      error = false,
      className = "",
      rounded = false,
      imageConditions: { px, size },
      testidPrefix,
    },
    _ref
  ) => {
    const { setValue, setError } = useFormContext();
    const [imageAttr, setImageAttr] = useState<ImageAttr | undefined>();

    // imageAttrの初期化
    useEffect(() => {
      // 何も設定されていない（追加画面の初期表示）
      if (!image) {
        return;
      }

      // クエリで取ってきたS3のURLが入っている（編集画面の初期表示）
      if (!(image instanceof File)) {
        setImageAttr({
          src: image,
          alt: name,
        });
      }

      // File形式で入っている（編集確認画面から戻ってきた初期表示）
      if (image instanceof File) {
        const objectUrl = URL.createObjectURL(image);
        setImageAttr({
          src: objectUrl,
          alt: image.name,
        });
      }
    }, [image, name]);

    useEffect(() => {
      return () => {
        if (imageAttr && image instanceof File)
          URL.revokeObjectURL(imageAttr.src);
      };
    }, [imageAttr, image]);

    const onDrop: DropzoneOptions["onDrop"] = useCallback(
      (acceptedFiles: File[]) => {
        if (!acceptedFiles || !acceptedFiles[0]) {
          return;
        }
        const file = acceptedFiles[0];
        if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
          setError(name, {
            type: "custom",
            message: `.jpg または .png 形式で、${px.width} x ${px.height}px 以下の画像を指定してください。`,
          });
          return;
        }
        if (size && file.size > size) {
          setError(name, {
            type: "custom",
            message: `アップロード可能なファイルサイズは最大${
              size / (1000 * 1000)
            }MBです。`,
          });
          return;
        }

        const image = new Image();
        if (imageAttr && imageAttr.src) URL.revokeObjectURL(imageAttr.src);
        const objectUrl = URL.createObjectURL(file);

        image.onload = () => {
          if (px && (image.width > px.width || image.height > px.height)) {
            setError(name, {
              type: "custom",
              message: `.jpg または .png 形式で、${px.width} x ${px.height}px 以下の画像を指定してください。`,
            });
            return;
          }
          setImageAttr({
            src: objectUrl,
            alt: file.name,
          });
          setValue(name, file, { shouldValidate: true });
        };

        image.src = objectUrl;
      },
      [imageAttr, setImageAttr, setValue, name, setError, size, px]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
    });

    return (
      <div className={`cursor-pointer ${className}`} {...getRootProps()}>
        <input
          {...getInputProps()}
          data-testid={`${testidPrefix}-file-input`}
        />
        {imageAttr ? (
          <img
            className="object-contain"
            src={imageAttr.src}
            alt={imageAttr.alt}
            onLoad={() => {
              URL.revokeObjectURL(imageAttr.src);
            }}
            onError={() => {
              URL.revokeObjectURL(imageAttr.src);
            }}
          />
        ) : (
          <div
            className={`flex flex-col items-center px-3 pt-2 pb-4 border border-dashed ${
              error ? "border-error text-error" : "border-base-blue"
            } ${rounded ? "rounded-full whitespace-pre-wrap" : ""}`}
          >
            <p className="text-xs text-left">
              {`ファイルを\nドラッグアンド\nドロップまたは\nアップロード\nしてください`}
            </p>
            <div className="mt-2.5 flex flex-row space-x-1 justify-center">
              <JpgIcon className="w-8" />
              <PngIcon className="w-8" />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default NewImageInputUI;
