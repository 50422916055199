import React, { InputHTMLAttributes } from "react";

import { RegisterParams } from "../common-interfaces";
import styles from "./ValidatableRadio.module.css";

/** @deprecated 代わりに /studypf-b2b-frontend/src/components/ValidatableRadio/NewValidatableRadio.tsx を使用してください */
export type ValidatableRadioUIProps = {
  registerParams: RegisterParams;
} & InputHTMLAttributes<HTMLInputElement>;

/** @deprecated 代わりに /studypf-b2b-frontend/src/components/ValidatableRadio/NewValidatableRadio.tsx を使用してください */
export const ValidatableRadioUI: React.FC<ValidatableRadioUIProps> = ({
  className,
  registerParams,
  children,
  id,
  ...rest
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <input
        className={`appearance-none mr-2.5 ${styles.check}`}
        {...registerParams.register(registerParams.label, {
          ...registerParams.conditions,
        })}
        id={id}
        type="radio"
        {...rest}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};
