import React, { useEffect, useRef, useState } from "react";

import {
  ContBillPaymentMismatchesTableRowFragment,
  ContBillPaymentMismatchResultsFragment,
} from "../../../../generated/graphql";
import ContBillPaymentMismatchesTableRowUI from "./ContBillPaymentMismatchesTableRow";

export type ContBillPaymentMismatchesTableUIProps = {
  contBillPaymentMismatches: ContBillPaymentMismatchResultsFragment;
  onWidthChange?: (width: number | undefined) => void;
};

export const ContBillPaymentMismatchesTableUI: React.VFC<
  ContBillPaymentMismatchesTableUIProps
> = ({ contBillPaymentMismatches, onWidthChange }) => {
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);
  const table = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (table && onWidthChange) {
      const rect: DOMRect | undefined = table.current?.getBoundingClientRect();
      const w = rect !== undefined ? Math.ceil(rect.width) : undefined;
      if (tableWidth !== w) {
        setTableWidth(w);
        onWidthChange(w);
      }
    }
  }, [table, onWidthChange, setTableWidth, tableWidth]);

  return (
    <table
      ref={table}
      className="border-collapse border border-dark-gray"
      style={{ width: "max-content" }}
    >
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-16">
          <th className="border border-dark-gray pl-2 pr-1">継続課金ID</th>
          <th className="border border-dark-gray pl-2 pr-1">検知日時</th>
          <th className="border border-dark-gray pl-2 pr-1">不整合項目</th>
        </tr>
      </thead>
      <tbody>
        {contBillPaymentMismatches.nodes.map(
          (
            contBillPaymentMismatch: ContBillPaymentMismatchesTableRowFragment,
            index: number
          ) => (
            <ContBillPaymentMismatchesTableRowUI
              data-testid="cont-bill-payment-mismatches-table-row"
              key={contBillPaymentMismatch.id}
              index={index}
              contBillPaymentMismatch={contBillPaymentMismatch}
            ></ContBillPaymentMismatchesTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
