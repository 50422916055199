import React from "react";

import { teachingMaterial } from "..";
import {
  LessonItemRowFragment,
  LessonsResultsFragment,
} from "../../../../generated/graphql";
import LessonsItemUI from "./LessonsItem";

export type LessonsUIProps = {
  lessons: LessonsResultsFragment;
  isOperationMonitor: boolean;
  openTeachingMaterialsModal: (
    courseTitle: string,
    teachingMaterials: teachingMaterial[]
  ) => void;
};
export const LessonsUI: React.FC<LessonsUIProps> = ({
  lessons,
  isOperationMonitor,
  openTeachingMaterialsModal,
}) => {
  return (
    <div className="grid gap-3 small:grid-cols-1 middle:grid-cols-2 large:grid-cols-3">
      {lessons.nodes.map((lesson: LessonItemRowFragment, index: number) => (
        <LessonsItemUI
          key={index}
          lesson={lesson}
          isOperationMonitor={isOperationMonitor}
          openTeachingMaterialsModal={openTeachingMaterialsModal}
        ></LessonsItemUI>
      ))}
    </div>
  );
};
