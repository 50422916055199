import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useHistory } from "react-router-dom";

import {
  PaymentMismatchesFilterInput,
  PaymentMismatchResultsFragment,
  PaymentMismatchStatus,
  usePaymentMismatchesQuery,
} from "../../../generated/graphql";
import { searchSettings } from "../../../lib/constants";
import { ErrorType } from "../../../lib/constants/error";
import PaymentMismatchesPage from "./PaymentMismatchesPage";

const PaymentMismatchesPageContainer: React.VFC = () => {
  const history = useHistory();
  const [limit, setLimit] = useState<number>(searchSettings.limit);
  const [paymentMismatches, setPaymentMismatches] =
    useState<PaymentMismatchResultsFragment>({
      totalCount: -1,
      nodes: [],
    });

  const [filter, setFilter] = useState<PaymentMismatchesFilterInput>({
    payInfoNo: "",
    handlingStatus: [
      PaymentMismatchStatus.Unhandled,
      PaymentMismatchStatus.Handling,
    ],
  });
  const { data, error, loading, refetch } = usePaymentMismatchesQuery({
    onCompleted: () => {},
    variables: {
      filter,
      limit,
    },
  });

  useEffect(() => {
    if (data?.paymentMismatches) {
      setPaymentMismatches(data?.paymentMismatches);
    }
  }, [data?.paymentMismatches, setPaymentMismatches]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter,
      limit: limit + searchSettings.offset,
    });
  }, [refetch, limit, filter]);

  const onSearch = useCallback(
    async (inputs: PaymentMismatchesFilterInput) => {
      setFilter(inputs);
      setLimit(searchSettings.limit);
      await refetch({
        filter,
        limit,
      });
    },
    [refetch, filter, limit]
  );

  if (error) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <PaymentMismatchesPage
      onSearch={onSearch}
      loading={loading}
      paymentMismatches={paymentMismatches}
      onDisplayMore={onDisplayMore}
    />
  );
};
export default PaymentMismatchesPageContainer;
