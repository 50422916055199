import React from "react";

import { CorporationUploadedCsvFragment } from "../../../generated/graphql";
import style from "./CorporationGrid.module.css";
import CorporationRowUI from "./CorporationRow";

export type CorporationGridUIProps = {
  className?: string;
  corpoCsvList: CorporationUploadedCsvFragment[];
  onCsvDownload: (url: string, fileName: string) => void;
};

export const CorporationGridUI: React.FC<CorporationGridUIProps> = ({
  className,
  corpoCsvList,
  onCsvDownload,
}) => {
  return (
    <div
      className={`border-t border-dark-gray overflow-x-auto ${className ?? ""}`}
    >
      {/* 見出し */}
      <div
        className={`grid align-center text-left h-16 bg-subtitle text-white ${style.grid}`}
      >
        <span className="flex items-center border-r border-white pl-2 pr-1">
          状態
        </span>
        <span className="flex items-center border-r border-white pl-2 pr-1">
          ファイル名
        </span>
        <span className="flex items-center border-r border-white pl-2 pr-1">
          アップロード日時
        </span>
        <span className="flex items-center pl-2 pr-1">登録結果</span>
        <span className="flex items-center pl-2 pr-1"></span>
      </div>
      {/* 一覧 */}
      {corpoCsvList.map((corpoCsv, index) => {
        return (
          <CorporationRowUI
            key={`corporation-csv-${corpoCsv.id}`}
            index={index}
            className={style.grid}
            status={corpoCsv.uploadResult}
            fileName={corpoCsv.fileName}
            uploadDate={corpoCsv.uploadTime}
            totalCount={corpoCsv.failedCount + corpoCsv.successesCount}
            errorCount={corpoCsv.failedCount}
            csvUrl={corpoCsv?.filePath ?? undefined}
            onCsvDownload={onCsvDownload}
          />
        );
      })}
    </div>
  );
};
