import React, { HTMLAttributes, useMemo } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { UrlToLink } from "../../../../components/UrlToLink/UrlToLink";
import { Scalars, Announcement } from "../../../../generated/graphql";
import { AnnouncementKind } from "../../../../lib/constants";
dayjs.locale(ja);
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDatetime = (datetime: Scalars["DateTime"]) => {
  const djs = dayjs(datetime).tz("Asia/Tokyo");
  return (
    <>
      {djs.format("YYYY")}
      <span className="mx-2">/</span>
      {djs.format("MM")}
      <span className="mx-2">/</span>
      {djs.format("DD")}
      <span className="ml-6" /> {djs.format("HH")}
      <span className="mx-2">:</span>
      {djs.format("mm")}
    </>
  );
};

type AnnouncementDetailUIProps = HTMLAttributes<HTMLDivElement> &
  Pick<
    Announcement,
    | "systemId"
    | "kind"
    | "publishStartedAt"
    | "publishEndedAt"
    | "subject"
    | "body"
  > & {
    /**
     * trueの場合、公開開始日時の欄に注釈を表示する。
     */
    showAnnotation?: boolean;
  };

const AnnouncementDetailUI: React.FC<AnnouncementDetailUIProps> = ({
  systemId,
  kind,
  publishStartedAt,
  publishEndedAt,
  subject,
  body,
  showAnnotation,
  className = "",
  ...rest
}) => {
  const kindLabel = useMemo(() => {
    switch (kind as AnnouncementKind) {
      case AnnouncementKind.News:
        return "お知らせ";
      case AnnouncementKind.Maintenance:
        return "メンテナンス";
      case AnnouncementKind.Campaign:
        return "キャンペーン";
    }
  }, [kind]);

  return (
    <div className={`space-y-5 ${className}`} {...rest}>
      <div>
        <p className="text-lg font-bold">お知らせID</p>
        <p className="mt-2.5" data-testid="announcement-system-id">
          {systemId}
        </p>
        <hr className="mt-5 border-under-line" />
      </div>
      <div>
        <p className="text-lg font-bold">お知らせ種別</p>
        <p className="mt-2.5" data-testid="announcement-kind">
          {kindLabel}
        </p>
        <hr className="mt-5 border-under-line" />
      </div>
      <div>
        <p className="text-lg font-bold">公開開始日時</p>
        <p className="mt-2.5" data-testid="announcement-started-at">
          {formatDatetime(publishStartedAt)}
        </p>
        <hr className="mt-5 border-under-line" />
      </div>
      <div>
        <p className="text-lg font-bold">公開終了日時</p>
        <p className="mt-2.5" data-testid="announcement-ended-at">
          {formatDatetime(publishEndedAt)}
        </p>
        <hr className="mt-5 border-under-line" />
      </div>
      <div>
        <p className="text-lg font-bold">お知らせ件名</p>
        <p className="mt-2.5" data-testid="announcement-subject">
          {subject}
        </p>
        <hr className="mt-5 border-under-line" />
      </div>
      <div>
        <p className="text-lg font-bold">お知らせ内容</p>
        <p
          className="mt-2.5 whitespace-pre-wrap break-words"
          data-testid="announcement-body"
        >
          <UrlToLink value={body} />
        </p>
      </div>
    </div>
  );
};

export default AnnouncementDetailUI;
