import {
  CreateQuestionnaireInput,
  QuestionnaireType,
  SelectableOptionConditionType,
} from "../../generated/graphql";
import { CourseQuestionnaire } from "../types/course";

export const getInitialQuestionnaireData = (
  order: number
): CourseQuestionnaire => ({
  typenameDisplay: "",
  typename: QuestionnaireType.SelectQuestionnaire,
  title: "",
  order,
  required: false,
  options: [{ name: "" }],
  selectableNumber: 1,
  condition: SelectableOptionConditionType.Equal,
  maxScale: 5,
  minLabel: "",
  maxLabel: "",
});

export const extractQuestionnaireData = (
  questionnaires: CourseQuestionnaire[] & CreateQuestionnaireInput[]
) => {
  return questionnaires.map((q) => {
    return {
      typenameDisplay: q.typenameDisplay,
      typename: q.typename,
      title: q.title,
      order: q.order,
      required: q.required,
      options: q.options,
      selectableNumber: q.selectableNumber,
      condition: q.condition,
      maxScale: q.maxScale,
      minLabel: q.minLabel,
      maxLabel: q.maxLabel,
    };
  });
};

export const getQuestionnaireTypeByString = (
  type: string
): QuestionnaireType => {
  switch (type) {
    case QuestionnaireType.SelectQuestionnaire:
      return QuestionnaireType.SelectQuestionnaire;
    case QuestionnaireType.MultiSelectQuestionnaire:
      return QuestionnaireType.MultiSelectQuestionnaire;
    case QuestionnaireType.LinearScaleQuestionnaire:
      return QuestionnaireType.LinearScaleQuestionnaire;
    case QuestionnaireType.FreeTextQuestionnaire:
      return QuestionnaireType.FreeTextQuestionnaire;
    // DBに自由記述形式で登録した際にtypenameがQuestionnaireで設定されるので変換する。
    case QuestionnaireType.Questionnaire:
      return QuestionnaireType.FreeTextQuestionnaire;

    default:
      return QuestionnaireType.SelectQuestionnaire;
  }
};

export const getSelectableOptionConditionTypeByString = (
  type: string
): SelectableOptionConditionType => {
  switch (type) {
    case SelectableOptionConditionType.Equal:
      return SelectableOptionConditionType.Equal;
    case SelectableOptionConditionType.LessThanOrEqual:
      return SelectableOptionConditionType.LessThanOrEqual;

    default:
      return SelectableOptionConditionType.Equal;
  }
};

export const getConditionByQuestionnaireInfo = (
  que: CourseQuestionnaire
): SelectableOptionConditionType => {
  // 複数選択ではない場合は初期値を返す
  if (que.typename !== QuestionnaireType.MultiSelectQuestionnaire) {
    return SelectableOptionConditionType.Equal;
  }
  // 必須じゃない場合は「以下」を返す
  if (!que.required) {
    return SelectableOptionConditionType.LessThanOrEqual;
  }

  // 選択内容または、undefineの場合は初期値を返す
  return que.condition || SelectableOptionConditionType.Equal;
};

export const getSelectableNumberByQuestionnaireInfo = (
  que: CourseQuestionnaire
): number => {
  // 複数選択ではない場合は初期値を返す
  if (que.typename !== QuestionnaireType.MultiSelectQuestionnaire) {
    return 1;
  }
  // 必須じゃない場合はチェックボックスの最大数を返す
  if (!que.required) {
    return que.options.length;
  }

  // 選択内容または、undefineの場合は初期値を返す
  return que.selectableNumber || 1;
};
