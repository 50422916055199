import React, { HTMLAttributes } from "react";

import { ReactComponent as NoAvatorIcon } from "./no-avator.svg";
import styles from "./UserAvator.module.scss";

export type UserAvatorUIProps = {
  src?: string | null;
  previewWidth?: number;
  previewHeight?: number;
} & HTMLAttributes<HTMLElement>;

export const UserAvatorUI: React.FC<UserAvatorUIProps> = ({
  id,
  className = "",
  src,
  previewWidth = 100,
  previewHeight = 100,
  ...rest
}) => {
  return (
    <>
      <div className={styles.label}>
        {src ? (
          <div
            data-testid="user-avator-preview"
            className={styles.preview}
            style={{
              backgroundImage: `url(${src})`,
              width: previewWidth,
              height: previewHeight,
            }}
          ></div>
        ) : (
          <NoAvatorIcon data-testid="no-avator-icon" />
        )}
      </div>
    </>
  );
};
