import React, {
  ChangeEventHandler,
  useState,
  useCallback,
  FormEventHandler,
} from "react";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  CustomerFilterInput,
  useServicesQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import CustomerSearchPage from "./CustomerSearchPage";

const CustomerSearchPageContainer: React.VFC = () => {
  const location = useLocation<{
    filter: CustomerFilterInput;
  }>();

  const history = useHistory();
  const [filter, setFilter] = useState<CustomerFilterInput>(
    location.state
      ? location.state.filter
      : {
          memberId: "",
          customerName: "",
          customerNameKana: "",
          tel: "",
          registerFrom: "",
          registerTo: "",
          isDeletedUserIncluded: true,
          searchCustomerServiceKind: [],
        }
  );

  const errorRouter = useErrorRouter();
  const { data, loading } = useServicesQuery({
    onError: errorRouter,
  });

  const onChangeFilterInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let value;
      if (event.target.name === "isDeletedUserIncluded") {
        value = event.target.value === "included" ? true : false;
      } else {
        value = event.target.value;
      }

      setFilter({
        ...filter,
        [event.target.name]: value,
      });
    },
    [filter]
  );

  const onCheckChangeFilterInput = useCallback(
    (checked: boolean, value: string) => {
      const a: string[] = filter.searchCustomerServiceKind ?? [];
      if (checked) {
        a.push(value);
        setFilter({
          ...filter,
          searchCustomerServiceKind: Array.from(new Set(a)), // 重複除去
        });
      } else {
        const b = a.filter((item) => item !== value);
        setFilter({
          ...filter,
          searchCustomerServiceKind: Array.from(new Set(b)), // 重複除去
        });
      }
    },
    [filter]
  );

  const onSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push("/customer/search/results", {
        filter,
      });
    },
    [history, filter]
  );

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <CustomerSearchPage
      filter={filter}
      data={data}
      onChangeFilterInput={onChangeFilterInput}
      onCheckChangeFilterInput={onCheckChangeFilterInput}
      onSubmit={onSubmit}
    />
  );
};
export default CustomerSearchPageContainer;
