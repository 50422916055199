import React, { useEffect, useRef, useState } from "react";

import {
  PaymentsSearchResultsFragment,
  PaymentsTableRowFragment,
} from "../../../../generated/graphql";
import PaymentsTableRowUI from "./PaymentsTableRow";

export type PaymentsTableUIProps = {
  payments: PaymentsSearchResultsFragment;
  onWidthChange?: (width: number | undefined) => void;
};

export const PaymentsTableUI: React.FC<PaymentsTableUIProps> = ({
  payments,
  onWidthChange,
}) => {
  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined);
  const table = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (table && onWidthChange) {
      const rect: DOMRect | undefined = table.current?.getBoundingClientRect();
      const w = rect !== undefined ? Math.ceil(rect.width) : undefined;
      if (tableWidth !== w) {
        setTableWidth(w);
        onWidthChange(w);
      }
    }
  }, [table, onWidthChange, setTableWidth, tableWidth]);

  return (
    <table
      ref={table}
      className="border-collapse border border-dark-gray"
      style={{ width: "max-content" }}
    >
      <thead className="border border-dark-gray bg-subtitle text-white">
        <tr className="text-left h-16">
          <th className="border border-dark-gray pl-2 pr-1">項番</th>
          <th className="border border-dark-gray pl-2 pr-1">決済番号</th>
          <th className="border border-dark-gray pl-2 pr-1">申込番号</th>
          <th className="border border-dark-gray pl-2 pr-1">決済方法</th>
          <th className="border border-dark-gray pl-2 pr-1">お客様番号</th>
          <th className="border border-dark-gray pl-2 pr-1">決済商品</th>
          <th className="border border-dark-gray pl-2 pr-1">課金種別</th>
          <th className="border border-dark-gray pl-2 pr-1">売上金額(税込)</th>
          <th className="border border-dark-gray pl-2 pr-1">売上金額(税抜)</th>
          <th className="border border-dark-gray pl-2 pr-1">
            <p>決済</p>
            <p>ステータス</p>
          </th>
          <th className="border border-dark-gray pl-2 pr-1">
            <p>申込年月日</p>
            <p>（オーソリ日）</p>
          </th>
          <th className="border border-dark-gray pl-2 pr-1">
            <p>決済年月日</p>
            <p>（売上確定日）</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {payments.nodes.map(
          (payment: PaymentsTableRowFragment, index: number) => (
            <PaymentsTableRowUI
              data-testid="payments-table-row"
              key={payment.id}
              index={index}
              payment={payment}
            ></PaymentsTableRowUI>
          )
        )}
      </tbody>
    </table>
  );
};
