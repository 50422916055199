import { useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  Discount,
  DiscountCourse,
  DiscountType,
} from "../../../generated/graphql";
import DiscountEditUI, { EditingDiscount } from "../components/DiscountEdit";

// 足跡
const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "クーポン一覧",
  },
  {
    label: "クーポン編集",
  },
];

export type DiscountEditPageProps = {
  discount: Discount;
  limitedCourses?: DiscountCourse[];
};

const DiscountEditPage: React.FC<DiscountEditPageProps> = ({
  discount,
  limitedCourses,
}) => {
  // 足跡
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const history = useHistory();

  const onConfirm = useCallback(
    (values: EditingDiscount) => {
      history.push(`/discounts/${values.id}/confirm`, values);
    },
    [history]
  );

  return (
    <DiscountEditUI
      isNew={false}
      title="クーポン編集"
      discount={{
        id: discount.id,
        type: discount.type as DiscountType,
        code: discount.code,
        name: discount.name,
        description: discount.description ? discount.description : "",
        startedAt: discount.startedAt,
        endedAt: discount.endedAt,
        durationMonths: discount.durationMonths,
        priceValueNoTax: discount.priceValueNoTax,
        limitedCourses: limitedCourses,
      }}
      onConfirm={onConfirm}
    />
  );
};

export default DiscountEditPage;
