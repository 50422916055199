import React from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { useClazzQuery } from "../../../generated/graphql";
import { ErrorType } from "../../../lib/constants/error";
import ClassPage from "./ClassPage";

type ClassPageParams = {
  courseId: string;
  classId: string;
};

const ClassPageContainer = () => {
  const { classId } = useParams<ClassPageParams>();
  const { data, error, loading } = useClazzQuery({
    variables: { id: classId },
    fetchPolicy: "no-cache",
  });
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    console.error(error);
    const errCode = error?.graphQLErrors[0]?.extensions?.code;
    if (errCode === ErrorType.UnAuthenticated) {
      history.push("/error/unauthenticated");
    } else {
      history.push("/error/internalservererror");
    }
    return <></>;
  }

  return (
    <>
      <ClassPage clazz={data.clazz} />
    </>
  );
};

export default ClassPageContainer;
