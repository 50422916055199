import React, { ChangeEventHandler, useEffect } from "react";

import { SubmitHandler } from "react-hook-form";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import {
  DisplayLesson,
  DisplayCourse,
  DisplayClass,
} from "../../../lib/constants/classes";
import { PageType } from "../../../lib/constants/pageType";
import { ClassEditUI } from "../components/ClassEdit";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "クラス追加",
  },
];

type ClassesNewPageProps = {
  course: DisplayCourse;
  clazzInput: DisplayClass;
  onOpenTeacherListModal: (lesson: DisplayLesson) => void;
  onChangeInput: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  onChangeLessonInputs: (lessonInputs: DisplayLesson[]) => void;
  onChangeRadioType: ChangeEventHandler<HTMLInputElement>;
  onHandleSubmit: SubmitHandler<DisplayClass>;
  onRemoveTeacher: (lesson: DisplayLesson, teacherId: string) => void;
  onChangeAutoCreateZoomURL: (required: boolean) => void;
};

const ClassesNewPage: React.FC<ClassesNewPageProps> = ({
  course,
  clazzInput,
  onOpenTeacherListModal,
  onChangeInput,
  onChangeLessonInputs,
  onChangeRadioType,
  onHandleSubmit,
  onRemoveTeacher,
  onChangeAutoCreateZoomURL,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);
  return (
    <div>
      <h1 className="text-2xl mb-3">クラス追加</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className="divide-y divide-under-line ml-5">
        <ClassEditUI
          pageType={PageType.ClassNewPage}
          course={course}
          clazzInput={clazzInput}
          onHandleSubmit={onHandleSubmit}
          onChangeInput={onChangeInput}
          onChangeLessonInputs={onChangeLessonInputs}
          onChangeRadioType={onChangeRadioType}
          onOpenTeacherListModal={onOpenTeacherListModal}
          onRemoveTeacher={onRemoveTeacher}
          onChangeAutoCreateZoomURL={onChangeAutoCreateZoomURL}
        ></ClassEditUI>
      </div>
    </div>
  );
};

export default ClassesNewPage;
