import React from "react";

import { UsersTableFragment } from "../../../generated/graphql";
import UsersTableRowUI from "./UsersTableRow";

export type UsersTableProps = {
  users: UsersTableFragment;
  extra: boolean;
};

const UsersTable: React.FC<UsersTableProps> = ({ users, extra }) => {
  return (
    <div className="ml-12">
      <table className="w-full">
        <thead className="border-b-2 border-dark-gray">
          <tr className="text-left h-10">
            <th></th>
            <th>{extra ? "外部ユーザーID" : "ユーザーID"}</th>
            <th>名前</th>
            {!extra && <th>サービス別権限</th>}
            {!extra && <th>ロール別権限</th>}
            <th>ステータス</th>
          </tr>
        </thead>
        <tbody>
          {users.nodes.map((user) => (
            <UsersTableRowUI
              key={user.userId}
              user={user}
              extra={extra}
            ></UsersTableRowUI>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
