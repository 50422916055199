import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { LoadingUI } from "../../../components/Loading";
import { Discount, useEditingDiscountQuery } from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import {
  EditingDiscount,
  InternalDiscountCourse,
} from "../components/DiscountEdit";
import DiscountEditPage from "./DiscountEditPage";

const DiscountEditPageContainer: React.VFC = () => {
  const errorRouter = useErrorRouter();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [data, setData] = useState<Discount | null>(null);
  const [limitedCourses, setLimitedCourses] = useState<
    InternalDiscountCourse[]
  >([]);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<EditingDiscount>();
  const { loading } = useEditingDiscountQuery({
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    onError: errorRouter,
    onCompleted: (res) => {
      const d: Discount = {
        ...res.discount,
      };
      if (state !== undefined) {
        // 確認画面からのブラウザバック時
        const editing: EditingDiscount = state;
        // 編集可能な項目だけ上書き
        setData({
          ...d,
          type: editing.type,
          name: editing.name!,
          startedAt: editing.startedAt!,
          endedAt: editing.endedAt!,
          description: editing.description,
        });
        setLimitedCourses(state.limitedCourses || []);
      } else {
        setData(d);
        setLimitedCourses(res.discountCourses);
      }
    },
  });

  useEffect(() => {
    if (data && limitedCourses) {
      setIsReady(true);
    }
  }, [data, limitedCourses]);

  if (loading || !data || !isReady) {
    return <LoadingUI title="データ取得中" />;
  }

  return <DiscountEditPage discount={data} limitedCourses={limitedCourses} />;
};

export default DiscountEditPageContainer;
