import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { HeaderUI } from "../../components/Header/Header";
import maintenanceSvg from "./maintenance.svg";
import styles from "./MaintenancePage.module.scss";

dayjs.locale(ja);

const editTime = (time: string) => {
  return dayjs(time).format("YYYY年MM月DD日(dd) HH:mm");
};

export type MaintenancePageProps = {
  startDate: string;
  endDate: string;
};

const MaintenancePage: React.FC<MaintenancePageProps> = ({
  startDate,
  endDate,
}) => {
  return (
    <>
      <HeaderUI />
      <div className="flex flex-col items-center justify-center mt-10">
        <h3 data-testid="maintenance-title" className={styles.fontSizeHeader}>
          メンテナンス中
        </h3>
        <img
          className="font-medium h-32 mx-auto object-contain pb-9"
          src={maintenanceSvg}
          alt="メンテナンス中"
        />
        <h4 className="font-medium pb-4 text-lg">【メンテナンス時間】</h4>
        <p data-testid="maintenance-time" className="font-medium pb-20 text-sm">
          {editTime(startDate)}〜{editTime(endDate)}
        </p>
      </div>
    </>
  );
};

export default MaintenancePage;
