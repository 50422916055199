import { useCallback } from "react";

import { TableUI } from "../../../../components/TableUI";
import { CategoriesDnDTableItemFragment } from "../../../../generated/graphql";
import { CategoriesDnDTrUI, CategoriesDnDTrUIProps } from "./CategoriesDnDTrUI";

export type CategoriesDnDTableUIProps = {
  categories: CategoriesDnDTableItemFragment[];
  onChangeCategories: (categories: CategoriesDnDTableItemFragment[]) => void;
};

export const CategoriesDnDTableUI: React.VFC<CategoriesDnDTableUIProps> = ({
  categories,
  onChangeCategories,
}) => {
  const findCard: CategoriesDnDTrUIProps["findCard"] = useCallback(
    (id: string) => {
      const category = categories.filter((c) => c.id === id)[0];
      return {
        category,
        index: categories.indexOf(category),
      };
    },
    [categories]
  );

  const moveCard: CategoriesDnDTrUIProps["moveCard"] = useCallback(
    (id, atIndex) => {
      const index = findCard(id).index;
      if (index === atIndex) {
        return;
      }

      const _categories = [...categories];
      const value = _categories[index];
      _categories.splice(index, 1);
      _categories.splice(atIndex, 0, value);
      onChangeCategories(_categories);
    },
    [categories, onChangeCategories, findCard]
  );

  return (
    <TableUI
      className="mt-3 text-left"
      headers={["コード", "商品カテゴリ名", ""]}
      colClassNames={["w-28", "w-auto", "w-12"]}
    >
      {categories.map((category) => (
        <CategoriesDnDTrUI
          key={category.id}
          category={category}
          moveCard={moveCard}
          findCard={findCard}
        />
      ))}
    </TableUI>
  );
};
