import React from "react";

import { useLocation } from "react-router-dom";

import { EditingDiscount } from "../components/DiscountEdit";
import DiscountNewPage from "./DiscountNewPage";

const DiscountNewPageContainer: React.VFC = () => {
  // Location に保存されている情報を取得
  const { state } = useLocation<EditingDiscount>();

  return state !== undefined ? (
    <DiscountNewPage editingDiscount={state} />
  ) : (
    <DiscountNewPage />
  );
};

export default DiscountNewPageContainer;
