import React, { useEffect, useCallback, useMemo } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import lessonIcon from "../../../components/lessonIcon.svg";
import LinkTextUI from "../../../components/LinkText/LinkText";
import { TextUI } from "../../../components/Text";
import {
  ParticipantTableFragment,
  LessonParticipantsPageItemFragment,
} from "../../../generated/graphql";
import { ParticipantsUI } from "./components/Participants";
import styles from "./LessonParticipants.module.scss";
dayjs.locale(ja);

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "授業スケジュール",
  },
  {
    label: "受講者情報",
  },
];

export type LessonParticipantsPageProps = {
  lesson: LessonParticipantsPageItemFragment;
  participants: ParticipantTableFragment;
};

const LessonParticipantsPage: React.FC<LessonParticipantsPageProps> = ({
  lesson,
  participants,
}) => {
  const gradeCount = useMemo(() => {
    const grades: { [key: string]: number } = {
      幼稚園年少: 0,
      幼稚園年中: 0,
      幼稚園年長: 0,
      小学1年生: 0,
      小学2年生: 0,
      小学3年生: 0,
      小学4年生: 0,
      小学5年生: 0,
      小学6年生: 0,
      中学1年生: 0,
      中学2年生: 0,
      中学3年生: 0,
      高校1年生: 0,
      高校2年生: 0,
      高校3年生: 0,
      その他: 0,
    };
    participants.nodes.forEach((ele) => {
      grades[ele.grade]++;
    });
    return [
      { label: "幼稚園年少", value: grades["幼稚園年少"] },
      { label: "幼稚園年中", value: grades["幼稚園年中"] },
      { label: "幼稚園年長", value: grades["幼稚園年長"] },
      { label: "小学1年生", value: grades["小学1年生"] },
      { label: "小学2年生", value: grades["小学2年生"] },
      { label: "小学3年生", value: grades["小学3年生"] },
      { label: "小学4年生", value: grades["小学4年生"] },
      { label: "小学5年生", value: grades["小学5年生"] },
      { label: "小学6年生", value: grades["小学6年生"] },
      { label: "中学1年生", value: grades["中学1年生"] },
      { label: "中学2年生", value: grades["中学2年生"] },
      { label: "中学3年生", value: grades["中学3年生"] },
      { label: "高校1年生", value: grades["高校1年生"] },
      { label: "高校2年生", value: grades["高校2年生"] },
      { label: "高校3年生", value: grades["高校3年生"] },
      { label: "その他", value: grades["その他"] },
    ];
  }, [participants]);

  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  const getScheduledDate = useCallback((startedAt: Date, endedAt: Date) => {
    return (
      startedAt &&
      endedAt &&
      `${dayjs(startedAt).format("YYYY/MM/DD(dd) HH:mm")} - ${dayjs(
        endedAt
      ).format("HH:mm")}`
    );
  }, []);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3">受講者情報</h1>
      <hr className="border-dark-gray mb-8"></hr>
      <div className="mx-6">
        <div className={styles.container}>
          <div className="m-3">
            <div className="ml-2 mt-2">
              <img
                className="w-6 h-6 object-cover inline-block"
                src={lessonIcon}
                alt="lessonIcon"
              />
              <TextUI
                data-testid="lesson-item-schedule-date"
                className="inline-block pl-3 align-middle"
              >
                {getScheduledDate(lesson.startedAt, lesson.endedAt)}
              </TextUI>
            </div>
            <div className="ml-2 mt-2">
              <p
                data-testid="lesson-item-course-name"
                className="inline-block text-xl"
              >
                {lesson.clazz?.course?.name}
              </p>
              <p className="inline-block text-xl">
                <LinkTextUI
                  data-testid="lesson-item-course-systemId"
                  className="pl-3"
                  to={`/courses/${lesson.clazz?.course?.id}`}
                >
                  (ID:{lesson.clazz?.course?.systemId})
                </LinkTextUI>
              </p>
            </div>
            <div className="inline-block ml-2 mt-2">
              <TextUI
                data-testid="lesson-item-class-name"
                className="inline-block"
              >
                {lesson.clazz?.name}
              </TextUI>
              <LinkTextUI
                data-testid="lesson-item-class-systemId"
                className="pl-3"
                to={`/courses/${lesson.clazz?.course?.id}/classes/${lesson.clazz?.id}`}
              >
                (ID:{lesson.clazz?.systemId})
              </LinkTextUI>
            </div>
            <div className="ml-2 mt-2">
              <TextUI
                data-testid="lesson-item-lesson-order"
                className="inline-block"
              >
                第{lesson.lessonMaster.order}回
              </TextUI>
              <TextUI
                data-testid="lesson-item-lesson-name"
                className="inline-block ml-5"
              >
                {lesson.lessonMaster.name}
              </TextUI>
            </div>
          </div>
        </div>
        <div>
          <h2 className="border-b pb-2 mt-6 mb-5">学年内訳</h2>
          <ul className={styles.listGrades}>
            {gradeCount?.map((g, index) => {
              return (
                g.value > 0 && (
                  <li className={styles.itemGrades} key={index}>
                    <span className={styles.label}>{g.label}</span>
                    <span className={styles.gradesCount}>{g.value}</span>
                  </li>
                )
              );
            })}
          </ul>
        </div>
        {participants.nodes.length > 0 ? (
          <div className="mt-5">
            <span data-testid="lesson-participants-item-total-count">
              全{participants.totalCount}件中 1 〜 {participants.nodes.length}
              件表示
            </span>
            <ParticipantsUI participants={participants}></ParticipantsUI>
          </div>
        ) : (
          <div className="container mt-5">
            {participants.totalCount === 0 && (
              <div className="bg-gray-200 text-center py-2">
                <p
                  className="text-lg font-bold mb-2.5"
                  data-testid="lesson-participants-item-no-data-message"
                >
                  該当する参加者がいません。
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonParticipantsPage;
