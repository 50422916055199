import React, { useEffect, MouseEventHandler } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { ReactComponent as SmallPlusIcon } from "../../../components/Button/small-plus.svg";
import { ReactComponent as MoreIcon } from "../../../components/more.svg";
import { AnnouncementsFragment } from "../../../generated/graphql";
import { AnnouncementsTableUI } from "./components/AnnouncementsTable";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

export type AnnouncementsPageProps = {
  announcements: AnnouncementsFragment;
  onDisplayMore: MouseEventHandler;
  loading: boolean;
};

const AnnouncementsPage: React.FC<AnnouncementsPageProps> = ({
  announcements,
  onDisplayMore,
  loading,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div className="mb-40">
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-2"></hr>
      <div className="flex flex-row justify-between items-center w-full mt-6">
        <span className="text-1.5xl text-title-black">お知らせ一覧</span>
      </div>
      <div className="flex flex-row justify-between items-center w-full mt-4">
        <span className="text-base">
          公開ステータスを「公開」とするのは、お客様画面における表示上、1個までを推奨とします。
        </span>
      </div>
      <div className="mt-6 flex justify-between items-end">
        <p
          className="align-bottom"
          data-testid="announcements-page-total-count"
        >
          {announcements.totalCount > 0 &&
            `全${announcements.totalCount}件中1〜${announcements.nodes.length}件表示`}
        </p>

        <ButtonUI
          data-testid="announcement-new-button"
          size="xs"
          className={`inline-flex items-center justify-items-center justify-center`}
          to={`/announcement/new`}
        >
          <SmallPlusIcon className="stroke-current" />
          <p className="ml-2">新規登録</p>
        </ButtonUI>
      </div>
      {announcements.totalCount > 0 ? (
        <>
          <AnnouncementsTableUI
            announcements={announcements}
          ></AnnouncementsTableUI>
          {announcements.totalCount !== announcements.nodes.length && (
            <div className="flex justify-center mt-6 mb-10">
              <button
                data-testid="announcements-page-display-more-btn"
                className="text-blue hover:underline active:text-blue-active active:underline h-8"
                onClick={onDisplayMore}
                disabled={loading}
              >
                <MoreIcon></MoreIcon>
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="ml-6">
          <div className="container mt-5">
            <div className="bg-gray-200 text-center py-2">
              <p
                className="text-lg font-bold mb-2.5"
                data-testid="announcements-page-no-announcements-message"
              >
                お知らせがありません。
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementsPage;
