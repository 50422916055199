import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import {
  LevelFragment,
  OrganizerFragment,
  SubjectSelectorItemFragment,
  useCoursesNewPageLazyQuery,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { CourseNew } from "../../../lib/types/course";
import CoursesNewPage from "./CoursesNewPage";

export type CourseNewLocationState = {
  editingNewCourse?: CourseNew; // 確認画面からの戻る / ブラウザバック時に設定される
};

const CoursesNewPageContaier = () => {
  const errorRouter = useErrorRouter();
  const location = useLocation();
  const state: CourseNewLocationState | undefined =
    location?.state as CourseNewLocationState;
  const [organizers, setOrganizers] = useState<OrganizerFragment[]>([]);
  const [subjects, setSubjects] = useState<SubjectSelectorItemFragment[]>([]);
  const [levels, setLevels] = useState<LevelFragment[]>([]);
  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);
  const [loadSubjectsAndLevels, { loading }] = useCoursesNewPageLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onError: errorRouter,
    onCompleted: (data) => {
      setOrganizers(data.organizers.nodes);
      setSubjects(data.subjects.nodes);
      setLevels(data.levels.nodes);
    },
  });

  useEffect(() => {
    if (execQueryTrigger) {
      setExecQueryTrigger(false);
      loadSubjectsAndLevels({
        variables: {
          selectorsLimit: 2 ** 31 - 1,
        },
      });
    }
  }, [execQueryTrigger, loadSubjectsAndLevels]);
  return (
    <CoursesNewPage
      loadingMasters={loading}
      subjects={subjects}
      organizers={organizers}
      levels={levels}
      editingNewCourse={state?.editingNewCourse}
    />
  );
};

export default CoursesNewPageContaier;
