import React, {
  ChangeEventHandler,
  FormEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import { NetworkStatus } from "@apollo/client";
import * as History from "history";
import { Prompt, useHistory } from "react-router-dom";

import { CheckboxUI } from "../../../../../components/Checkbox";
import { PaginationUI } from "../../../../../components/Pagination";
import {
  SubjectFilterInput,
  SubjectsResultItemFragment,
  useCreateSubjectMutation,
  useSubjectsLazyQuery,
  useUpdateSubjectMutation,
} from "../../../../../generated/graphql";
import { useErrorRouter } from "../../../../../hooks/errorRouter";
import { ErrorType } from "../../../../../lib/constants/error";
import { Column, MasterTableUI } from "../MasterTable";
import { PlusButtonUI } from "../PlusButton/PlusButton";
import { SearchInputUI } from "../SearchInput/SearchInput";
import { SubjectEditModalUI, SubjectEditMode } from "../SubjectEditModal";

type ModalControll = {
  visible: boolean;
  mode: SubjectEditMode;
  subject?: SubjectsResultItemFragment;
};

export type SubjectsUIProps = {} & HTMLAttributes<HTMLDivElement>;

const columns: Column[] = [
  {
    title: "コード",
    name: "systemId",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
  },
  {
    title: "商品カテゴリ名",
    name: "name",
    headerClassName: "text-left pl-2.5 w-auto",
    rowClassName: "text-left pl-2.5 ",
  },
  {
    title: "ステータス",
    name: "status",
    headerClassName: "text-center w-24",
    rowClassName: "text-center",
    formatter: (value) => (value ? "有効" : "無効"),
  },
];

export const SubjectsUI: React.FC<SubjectsUIProps> = (props) => {
  const history = useHistory();
  const limit = 20;

  // カテゴリ追加/編集モーダル制御
  const [modalControll, setModalControll] = useState<ModalControll>({
    visible: false,
    mode: SubjectEditMode.CREATE,
    subject: undefined,
  });

  const errorRouter = useErrorRouter();
  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);
  // 検索文字列
  const [searchWord, setSearchWord] = useState<string>("");

  const [loadSubjects, { loading, data, networkStatus }] = useSubjectsLazyQuery(
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onError: errorRouter,
    }
  );
  const [offset, setOffset] = useState(0);

  // カテゴリ作成 Mutation
  const [createSubject] = useCreateSubjectMutation();
  // カテゴリ更新 Mutation
  const [updateSubject] = useUpdateSubjectMutation();

  // カテゴリ追加/編集モーダルの表示制御
  const setModalVisible = useCallback(
    (visible: boolean) => {
      setModalControll({
        ...modalControll,
        visible: visible,
      });
    },
    [setModalControll, modalControll]
  );

  // ブラウザバック時にモーダルを非表示化
  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (location.pathname === "/masters" && action === "POP") {
        setModalVisible(false);
        return false;
      }
      return true;
    },
    [setModalVisible]
  );

  // カテゴリ作成ハンドラー
  const handleCreateSubject = useCallback(
    async (name: string, status: boolean) => {
      try {
        await createSubject({
          variables: {
            input: {
              name,
              status,
            },
          },
        });
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (!errCode) {
          // エラーコードを取れない場合は内部サーバエラーに飛ばす
          history.push("/error/internalservererror");
        } else if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
        } else {
          history.push("/error/internalservererror");
        }
        return;
      }
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    [setModalVisible, history, createSubject]
  );

  // カテゴリ更新ハンドラー
  const handleUpdateSubject = useCallback(
    async (id: string, name: string, status: boolean) => {
      try {
        await updateSubject({
          variables: {
            input: {
              id,
              name,
              status,
            },
          },
        });
      } catch (error: any) {
        const errCode = error?.graphQLErrors[0]?.extensions?.code;
        if (!errCode) {
          // エラーコードを取れない場合は内部サーバエラーに飛ばす
          history.push("/error/internalservererror");
        } else if (errCode === ErrorType.UnAuthenticated) {
          history.push("/error/unauthenticated");
        } else {
          history.push("/error/internalservererror");
        }
        return;
      }
      setModalVisible(false);
      setExecQueryTrigger(true);
    },
    [setModalVisible, history, updateSubject]
  );

  // 検索用「status」パラメータ変更ハンドラー
  const [filterActiveFlg, setFilterActiveFlg] = useState<true | undefined>(
    undefined
  );
  const onChangeAciveFilterCheckbox: ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      const active: true | undefined = e.target.checked ? true : undefined;
      setFilterActiveFlg(active);
      setOffset(0);
      setExecQueryTrigger(true);
    }, []);

  // 検索用カテゴリ名パラメータ変更ハンドラー
  const onChangeSubjectsSearchFilter: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        setSearchWord(event.currentTarget.value);
      },
      [setSearchWord]
    );

  // 検索用カテゴリ名クリアハンドラー
  const onDeleteSearchFilter: MouseEventHandler = useCallback(() => {
    setSearchWord("");
    setOffset(0);
    setExecQueryTrigger(true);
  }, [setSearchWord, setOffset, setExecQueryTrigger]);

  // 検索ボタンのクリックハンドラー
  const onSearch: FormEventHandler = (event) => {
    event.preventDefault();
    setOffset(0);
    setExecQueryTrigger(true);
  };

  // ページネーションの前ページボタンクリックハンドラー
  const onClickPrevios = useCallback(() => {
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションの次ページボタンクリックハンドラー
  const onClickNext = useCallback(() => {
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションのページ指定ボタンクリックハンドラー
  const onClickPaginationItem = useCallback(
    (index: number) => {
      setOffset(index * limit);
      setExecQueryTrigger(true);
    },
    [limit]
  );

  // カテゴリ作成ボタンのクリックハンドラー
  const onSubjectNewBtnClick: MouseEventHandler = useCallback(
    (event) => {
      setModalControll({
        mode: SubjectEditMode.CREATE,
        visible: true,
        subject: undefined,
      });
    },
    [setModalControll]
  );

  // カテゴリ編集ボタンのクリックハンドラー
  const onSubjectEditBtnClick = useCallback(
    (subject: SubjectsResultItemFragment) => {
      setModalControll({
        mode: SubjectEditMode.UPDATE,
        visible: true,
        subject,
      });
    },
    [setModalControll]
  );

  useEffect(() => {
    if (execQueryTrigger) {
      const filter: SubjectFilterInput = {};
      if (filterActiveFlg) {
        filter.active = filterActiveFlg;
      }
      if (searchWord) {
        filter.searchWord = searchWord;
      }
      loadSubjects({
        variables: {
          filter,
          offset: offset,
          limit,
        },
      });
      setExecQueryTrigger(false);
    }
  }, [execQueryTrigger, loadSubjects, offset, searchWord, filterActiveFlg]);

  return (
    <div {...props}>
      <form className="flex space-x-7 mt-4" onSubmit={onSearch}>
        <SearchInputUI
          className="flex-grow"
          data-testid="subjects-search-input"
          placeholder="コード、商品カテゴリ名"
          value={searchWord}
          onChange={onChangeSubjectsSearchFilter}
          onClickSearch={onSearch}
          onClickDelete={onDeleteSearchFilter}
        ></SearchInputUI>
        <CheckboxUI
          className="flex-none"
          style={{ marginRight: "10px" }}
          data-testid="subjects-active-checkbox"
          type="m"
          onChange={onChangeAciveFilterCheckbox}
        >
          ステータスが有効のみ表示する
        </CheckboxUI>
      </form>
      <div className="flex mt-5 justify-end">
        <PlusButtonUI
          data-testid="subject-new-btn"
          to="/subject/new"
          onClick={onSubjectNewBtnClick}
        ></PlusButtonUI>
      </div>
      {(loading ||
        data === undefined ||
        networkStatus === NetworkStatus.refetch) && (
        <div className="mt-4">Loading...</div>
      )}
      {!loading && data && data.subjects.totalCount > 0 && (
        <>
          <p
            className="tracking-wider mt-2 mb-3"
            data-testid="subjects-display-count"
          >{`全${data.subjects!.totalCount}件中${offset + 1}件〜${
            offset + data.subjects!.nodes.length
          }件を表示`}</p>
          <MasterTableUI
            rows={data.subjects.nodes}
            columns={columns}
            willEdit={onSubjectEditBtnClick}
          ></MasterTableUI>
          {data.subjects!.totalCount > 0 && (
            <PaginationUI
              className="mt-20 mb-20"
              selectedIndex={Math.ceil(offset / limit)}
              count={Math.ceil(data.subjects!.totalCount / limit)}
              onClickPrevios={onClickPrevios}
              onClickNext={onClickNext}
              onClickItem={onClickPaginationItem}
            ></PaginationUI>
          )}
        </>
      )}
      {!loading && data && data.subjects && data.subjects.totalCount === 0 && (
        <div className="bg-gray-200 text-center mt-5">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-subject-exists-message"
          >
            該当する商品カテゴリがありません。
          </p>
          <p>検索条件に該当する商品カテゴリがありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
      {modalControll.visible && (
        <SubjectEditModalUI
          editMode={modalControll.mode}
          visibility={true}
          subject={modalControll.subject}
          onCancel={() => {
            setModalVisible(false);
          }}
          onCreate={handleCreateSubject}
          onUpdate={handleUpdateSubject}
        />
      )}
      <Prompt when={true} message={handleBlockBack} />{" "}
    </div>
  );
};
