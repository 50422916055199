import React, { HTMLAttributes } from "react";
export type BadgeUIProps = {
  type?:
    | "required"
    | "monthly"
    | "once"
    | "unhandle"
    | "handling"
    | "done"
    | "optional";
} & HTMLAttributes<HTMLSpanElement>;

const typeClassNameMap = {
  required: "text-white bg-orange border-orange",
  monthly: "text-light-orange bg-white border-light-orange",
  once: "text-red bg-white border-red",
  unhandle: "text-sm text-red bg-white border-red",
  handling: "text-sm text-light-orange bg-white border-light-orange",
  done: "text-sm text-dark-gray bg-white border-dark-gray",
  optional: "text-sm text-white bg-dark-gray border-dark-gray",
};

export const BadgeUI: React.FC<BadgeUIProps> = ({
  children,
  className,
  type = "required",
}) => {
  return (
    <span
      className={`text-sm leading-none h-4 px-1 border rounded ${
        type && typeClassNameMap[type]
      } ${className}`}
    >
      {children}
    </span>
  );
};
