import { ApolloClient, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";

import { IsUserLoggedInDocument } from "../generated/graphql";
import {
  cache,
  currentServiceKind,
  addCurrentServiceKindChangeListener,
} from "./cache";
import { setLocation } from "./functions";
export { currentServiceKind, useCurrentServiceKind } from "./cache";

const ENDPOINT = process.env.REACT_APP_PUBLIC_GRAPHQL_ENDPOINT;

const httpLink = new HttpLink({
  uri: ENDPOINT,
});

export const authLink = setContext(async (_, { headers }) => {
  try {
    const user = await Auth.currentSession();
    const token = user.getAccessToken().getJwtToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } catch (e) {
    return headers;
  }
});

const activityMiddleware = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Page-Path": window.location.pathname || null,
      "StudyPF-ServiceKind": currentServiceKind()?.id,
    },
  };
});

export const handleMaintenanceLink = onError(
  ({ graphQLErrors }: ErrorResponse) => {
    if (graphQLErrors) {
      const maintenanceError = graphQLErrors.find(
        (err) => err.extensions.isMaintenanceNow
      );
      if (maintenanceError) {
        const startDate = maintenanceError.extensions.maintenanceStartDate;
        const endDate = maintenanceError.extensions.maintenanceEndDate;
        if (typeof startDate === "string" && typeof endDate === "string") {
          const sd = encodeURIComponent(startDate);
          const ed = encodeURIComponent(endDate);
          setLocation(`/maintenance/?startDate=${sd}&endDate=${ed}`);
        }
      }
    }
  }
);

function createApolloClient() {
  return new ApolloClient({
    link: from([handleMaintenanceLink, authLink, activityMiddleware, httpLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
}

export const client = createApolloClient();

addCurrentServiceKindChangeListener(() => client.clearStore());

Auth.currentAuthenticatedUser()
  .then((user) => {
    client.writeQuery({
      query: IsUserLoggedInDocument,
      data: {
        isLoggedIn: !!user,
      },
    });
  })
  .catch(() => {
    client.writeQuery({
      query: IsUserLoggedInDocument,
      data: {
        isLoggedIn: false,
      },
    });
  });
