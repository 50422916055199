import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import LinkTextUI from "../../../../components/LinkText/LinkText";
import { ContBillPaymentMismatchesTableRowFragment } from "../../../../generated/graphql";
import { getMismatchTypeJA } from "../../../../lib/utils/payment";
dayjs.locale(ja);

export type ContBillPaymentMismatchesTableRowProps = {
  contBillPaymentMismatch: ContBillPaymentMismatchesTableRowFragment;
  index: number;
};

const ContBillPaymentMismatchesTableRowUI: React.VFC<
  ContBillPaymentMismatchesTableRowProps
> = ({ contBillPaymentMismatch, index }) => {
  return (
    <tr
      data-testid="payment-mismatches-table-row"
      className={`h-16 ${index % 2 !== 0 ? "bg-light-blue" : ""}`}
    >
      <td className="border-r border-dark-gray pl-2 pr-1">
        <LinkTextUI
          to={`/payment/contbill/mismatches/${contBillPaymentMismatch.id}`}
        >
          {contBillPaymentMismatch.continueAccountId}
        </LinkTextUI>
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {dayjs(contBillPaymentMismatch.detectedDateTime).format(
          "YYYY/MM/DD HH:mm"
        )}
      </td>
      <td className="border-r border-dark-gray pl-2 pr-1">
        {getMismatchTypeJA(contBillPaymentMismatch.type)}
      </td>
    </tr>
  );
};

export default ContBillPaymentMismatchesTableRowUI;
