import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useFormContext } from "react-hook-form";

import { range } from "../../lib/functions";
import { InputUI } from "../Input";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type Input = {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
};

const convertValueZeroPad = (value: number, isYear?: boolean) => {
  if (value === -1) return "";
  return isYear
    ? `${value}`.slice(-4).padStart(4, "0")
    : `${value}`.slice(-2).padStart(2, "0");
};

const dateToNumber = (date?: Date) => {
  if (!date) return undefined;
  // なぜかDateオブジェクトではなくなってしまう場合があるので
  // dayjsに変換してチェックする
  const djs = dayjs(date).tz("Asia/Tokyo");
  if (!djs.isValid()) return undefined;
  const year = djs.year();
  const month = djs.month() + 1;
  const day = djs.date();
  const hour = djs.hour();
  const minute = djs.minute();
  return { year, month, day, hour, minute };
};

const inputToDate = ({ year, month, day, hour, minute }: Input) => {
  return dayjs(
    `${year}-${month}-${day} ${hour}:${minute}`,
    "YYYY-M-D H:m"
  ).toDate();
};

export type DateTimeInputUIProps = {
  error?:
    | boolean
    | {
        message?: string;
      };
  isCheckIcon?: boolean;
  testidPrefix?: string;
  icon?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const DateTimeInputUI = React.forwardRef<
  HTMLInputElement,
  DateTimeInputUIProps
>(
  (
    {
      error,
      className = "",
      children,
      isCheckIcon = true,
      testidPrefix = "datetime-input",
      name = "",
      icon = false,
      disabled = false,
      ...rest
    },
    _ref
  ) => {
    const { setValue, setError, getValues } = useFormContext();
    const date = getValues(name) as Date;
    const initalValue = useMemo(() => dateToNumber(date), [date]);
    const [isTouched, setIsTouched] = useState(false);
    // 初期値は-1で空文字を表示する
    const [state, setState] = useState({
      year: -1,
      month: -1,
      day: -1,
      hour: -1,
      minute: -1,
    });

    // クエリから取ってきた値はundefinedになる可能性があるため、useStateの初期化では使えない。
    // また、react-hook-formで管理されている日時はdayjsを使っている仕様上０日等は１になってしまい
    // そのままsetStateをしてしまうと、バックスペースで消しても1が常に残り、使い勝手がわるくなる
    // 以上からクエリで取ってきた場合でも最初の初期化のみuseEffect内でsetStateするために以下のようにしている。
    useEffect(() => {
      if (
        state.year === -1 &&
        state.month === -1 &&
        state.day === -1 &&
        state.hour === -1 &&
        state.minute === -1
      ) {
        if (isTouched) {
          setState(state);
          setValue(name, undefined, {
            shouldValidate: isTouched,
          });
        } else if (!!initalValue) {
          setState(initalValue);
          setValue(name, inputToDate(initalValue));
        }
      }
    }, [initalValue, isTouched, name, setValue, state]);

    const getDateInputRange = useCallback(
      (dateType: string): number[] => {
        // monthとdateは0はあり得ないが、入力中は0も許容しておかないと使い勝手が悪くなるため配列に含めている
        switch (dateType) {
          case "year":
            return range(0, 9999);
          case "month":
            return range(0, 12);
          case "day":
            const { year, month } = state;
            const max = dayjs(`${year}-${month}`, "YYYY-M").daysInMonth() || 31;
            return range(0, max);
          case "hour":
            return range(0, 23);
          case "minute":
            return range(0, 59);
          default:
            return [0];
        }
      },
      [state]
    );

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setIsTouched(true);
        // valueを削除したときは-1にして空文字を表示する
        const value = event.target.value ? parseInt(event.target.value) : -1;
        if (!getDateInputRange(event.target.id).includes(value) && value !== -1)
          return;

        const next = { ...state, [event.target.id]: value };
        const { year, month, day, hour, minute } = next;
        // 日の値が月末より大きい場合は自動で月末の日付に設定する
        const lastDate = dayjs(`${year}-${month}`).endOf("month").date();
        const validDate = lastDate < day ? lastDate : day;
        setState({ ...next, day: validDate });

        //TODO:https://agile.kddi.com/jira/browse/STUDYPF-9260で修正する
        if (
          !dayjs(
            `${year}-${month}-${validDate} ${hour}:${minute}`,
            "YYYY-M-D H:m",
            true
          ).isValid()
        ) {
          setError(name, { message: "正しい日時を入力してください。" });
        }
        // 未入力項目がある場合はsetValueしない
        !Object.values(next).includes(-1) &&
          setValue(
            name,
            dayjs(
              `${year}-${month}-${validDate} ${hour}:${minute}`,
              "YYYY-M-D H:m"
            ).toDate(),
            { shouldValidate: true }
          );
      },
      [state, setState, setValue, name, getDateInputRange, setError]
    );

    return (
      <div
        className={`${className}`}
        data-testid={`${testidPrefix}-container`}
        {...rest}
      >
        <div className="flex items-center">
          {icon && <CalendarIcon className="mr-2.5" />}
          <div className="flex items-center">
            {/* バリデーション後にフォーカスが移動するようにreadOnlyのinputを可視状態でおいておく */}
            <input
              className="opacity-0 w-0"
              {...rest}
              readOnly={true}
              data-testid={`${testidPrefix}-read-only`}
            />
            <div className="space-x-1.5">
              <InputUI
                id="year"
                className={`w-14 ${error ? "border-error" : ""}`}
                height="h-8"
                padding="pl-2.5"
                placeholder="YYYY"
                value={convertValueZeroPad(state.year, true)}
                onChange={handleOnChange}
                data-testid={`${testidPrefix}-year`}
                disabled={disabled}
              />
              <span>/</span>
              <InputUI
                id="month"
                height="h-8"
                padding="pl-2.5"
                className={`w-12 ${error ? "border-error" : ""}`}
                placeholder="MM"
                value={convertValueZeroPad(state.month)}
                onChange={handleOnChange}
                data-testid={`${testidPrefix}-month`}
                disabled={disabled}
              />{" "}
              <span>/</span>
              <InputUI
                id="day"
                height="h-8"
                padding="pl-2.5"
                className={`w-12 ${error ? "border-error" : ""}`}
                placeholder="DD"
                value={convertValueZeroPad(state.day)}
                onChange={handleOnChange}
                data-testid={`${testidPrefix}-day`}
                disabled={disabled}
              />
              <InputUI
                id="hour"
                height="h-8"
                padding="pl-2.5"
                className={`w-12 ${error ? "border-error" : ""}`}
                placeholder="HH"
                value={convertValueZeroPad(state.hour)}
                onChange={handleOnChange}
                data-testid={`${testidPrefix}-hour`}
                disabled={disabled}
              />
              <span>:</span>
              <InputUI
                id="minute"
                height="h-8"
                padding="pl-2.5"
                className={`w-12 ${error ? "border-error" : ""}`}
                placeholder="MM"
                value={convertValueZeroPad(state.minute)}
                onChange={handleOnChange}
                data-testid={`${testidPrefix}-minute`}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        {typeof error !== "boolean" && error?.message && (
          <p
            className="text-error mt-1.5 ml-8"
            data-testid={`${testidPrefix}-error-message`}
          >
            {error?.message}
          </p>
        )}
      </div>
    );
  }
);
