import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { BadgeUI } from "../../../../components/Badge";
import { ButtonUI } from "../../../../components/Button";
import noImage from "../../../../components/no-image.svg";
import {
  CoursePriceType,
  CoursesItemFragment,
} from "../../../../generated/graphql";
import { useQueryParams } from "../../../../hooks/query";
import { ReactComponent as ArrowFilledIcon } from "./arrowFilled.svg";
import ClazzesItemUI from "./ClazzesItem";
import styles from "./CoursesItem.module.scss";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as MenuBlueIcon } from "./menuBlue.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as PlusBlueIcon } from "./plusBlue.svg";

export type CoursesItemProps = {
  course: CoursesItemFragment;
  isShow: boolean;
};

const CoursesItemUI: React.FC<CoursesItemProps> = ({
  course,
  isShow = false,
}) => {
  const [isShowClazzes, setIsShowClazzes] = useState(isShow);
  const [particiCounts, setParticiCounts] = useState(0);
  const [capacities, setCapacities] = useState(0);
  const [canceledCounts, setCanceledCounts] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const params = useQueryParams();

  useEffect(() => {
    let particiCount = 0;
    let capacity = 0;
    let canceledCount = 0;
    if (course?.clazzes?.length) {
      course?.clazzes?.forEach((clazz) => {
        capacity += Number(clazz.capacity);
        if (clazz?.lessons) {
          particiCount += clazz.lessons[0].participantsCount;
          canceledCount += clazz.canceledCount || 0;
        }
      });
    }
    setParticiCounts(particiCount);
    setCapacities(capacity);
    setCanceledCounts(canceledCount);
  }, [course?.clazzes]);

  const gotoCourseDetail = useCallback(() => {
    params.set("courseId", course.id);
    history.replace(
      location.pathname + "?" + params.toString(),
      location.state
    );
    history.push(`/courses/${course.id}`);
  }, [course.id, history, location.pathname, location.state, params]);

  return (
    <div className="pt-5 pb-5" data-testid="courses-item">
      <div className="flex w-full" id={`courses-courseId-${course.id}`}>
        <div className="flex-none flex-shrink-0 w-7/12">
          <div className="flex flex-col">
            <div className="flex flex-row">
              {course.images &&
              course.images[0] &&
              course.images[0].url !== "" ? (
                <img
                  className="w-48 h-36 object-cover"
                  src={course.images[0].url}
                  alt="noimage"
                />
              ) : (
                <img
                  className="w-48 h-36 object-cover"
                  src={noImage}
                  alt="noimage"
                />
              )}
              <div className="pl-3">
                <h3
                  className="text-lg tracking-wider leading-none mb-3"
                  data-testid="course-item-course-name"
                >
                  {course.name}
                </h3>
                <p className="mb-1">
                  <span className="inline-block w-24">コースID </span>
                  <span>:</span>
                  <span
                    className="inline-block pl-3"
                    data-testid="course-item-course-id"
                  >
                    {course.courseId}
                  </span>
                </p>
                <p className="mb-1">
                  <span className="inline-block w-24">商品カテゴリ </span>
                  <span>:</span>
                  <span
                    className="inline-block pl-3"
                    data-testid="course-item-subject-name"
                  >
                    {course.subjects?.map((subject) => (
                      <span className="mr-4" key={subject.id}>
                        {subject.name}
                      </span>
                    ))}
                  </span>
                </p>
                <p className="mb-1">
                  <span className="inline-block w-24">金額（税抜） </span>
                  <span>:</span>
                  {course?.price?.type === CoursePriceType.Free && (
                    <span
                      className="inline-block pl-3"
                      data-testid="course-item-price"
                    >
                      {course.price.valueNoTax?.toLocaleString("ja-JP")}円
                    </span>
                  )}
                  {course?.price?.type === CoursePriceType.Once && (
                    <span
                      className="inline-block pl-3"
                      data-testid="course-item-price"
                    >
                      <BadgeUI className="mr-1" type={"once"}>
                        一括
                      </BadgeUI>{" "}
                      {course.price.valueNoTax?.toLocaleString("ja-JP")}円
                    </span>
                  )}
                </p>
                <p className="mb-1">
                  <span className="inline-block w-24">実績 </span>
                  <span>:</span>
                  {particiCounts >= capacities && (
                    <span
                      className="text-red pl-3"
                      data-testid="course-item-full-badge"
                    >
                      満員
                    </span>
                  )}
                  <span
                    className="inline-block pl-3"
                    data-testid="course-item-capacities"
                  >
                    {particiCounts} / {capacities} 名
                  </span>
                </p>
                <p className="mb-1">
                  <span className="inline-block w-24">キャンセル数 </span>
                  <span>:</span>
                  <span
                    className="inline-block pl-3"
                    data-testid="course-item-canceled-count"
                  >
                    {canceledCounts} 名
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow flex-shrink-0 w-5/12">
          <p className="text-right" data-testid="course-item-system-id">
            &#040;システムID：{course.systemId}&#041;
          </p>
        </div>
      </div>

      <div className="w-full text-right flex justify-end">
        <ButtonUI
          to={`/courses/${course.id}/class/new`}
          size="xs"
          className={`inline-flex items-center justify-items-center justify-center mr-4 ${styles.btnDetail}`}
        >
          <PlusIcon className={`icon mr-3 ${styles.icon}`} />
          <PlusBlueIcon className={`mr-3 ${styles.iconActive}`} />
          クラス追加
        </ButtonUI>
        <ButtonUI
          onClick={gotoCourseDetail}
          size="xs"
          className={`inline-flex items-center justify-items-center justify-center ${styles.btnDetail}`}
        >
          <MenuIcon className={`icon mr-3 ${styles.icon}`} />
          <MenuBlueIcon className={`mr-3 ${styles.iconActive}`} />
          コース詳細
        </ButtonUI>
      </div>

      <div
        className="border-2 p-2 mt-3 flex justify-center items-center cursor-pointer"
        onClick={() => setIsShowClazzes(!isShowClazzes)}
      >
        <span className="pr-5">クラス({course?.clazzes?.length}件)を表示</span>
        <ArrowFilledIcon
          className={isShowClazzes ? `${styles.arrowIconActive}` : ""}
        />
      </div>
      {isShowClazzes &&
        course?.clazzes?.map((clazz, i) => (
          <ClazzesItemUI key={i} clazz={clazz} />
        ))}
    </div>
  );
};

export default CoursesItemUI;
