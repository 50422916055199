import React, { InputHTMLAttributes } from "react";

import { ReactComponent as CalendarIcon } from "./calendar.svg";

export type DateInputWithCalendarIconUIProps =
  InputHTMLAttributes<HTMLInputElement>;

export const DateInputWithCalendarIconUI = React.forwardRef<
  HTMLInputElement,
  DateInputWithCalendarIconUIProps
>(({ className = "", children, ...rest }, ref) => {
  return (
    <div className="flex items-center space-x-2.5">
      <CalendarIcon />
      <input
        ref={ref}
        type="date"
        className={`${className} h-9 leading-5 pl-4 tracking-wider border rounded`}
        max="9999-12-31"
        {...rest}
      />
    </div>
  );
});
