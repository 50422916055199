import React, { useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { AnnouncementEdit } from "../../../lib/types/announcement";
import AnnouncementDetailUI from "../components/AnnouncementDetail/AnnouncementDetail";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "設定",
  },
  {
    label: "お知らせ管理",
  },
];

const AnnouncementsEditConfirmPage: React.FC<{
  input: AnnouncementEdit;
  onBack: () => void;
  onRegister: () => void;
}> = ({ input, onBack, onRegister }) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3 text-title-black">お知らせ管理</h1>
      <hr className="border-dark-gray border mb-2.5" />
      <h2 className="text-1.5xl text-title-black">お知らせ編集確認</h2>
      <AnnouncementDetailUI
        className="mt-8"
        showAnnotation={false}
        systemId={input.systemId}
        kind={input.kind}
        publishStartedAt={input.publishStartedAt}
        publishEndedAt={input.publishEndedAt}
        subject={input.subject}
        body={input.body}
      />
      <div className="py-20 flex justify-center space-x-10">
        <ButtonUI
          data-testid="announcement-back-btn"
          buttonType="secondary"
          onClick={onBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI data-testid="announcement-register-btn" onClick={onRegister}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default AnnouncementsEditConfirmPage;
