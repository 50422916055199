import React, { useEffect, useCallback } from "react";

import { useHistory } from "react-router";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { UserDetailUI } from "../../../components/UserDetail";
import { UserQuery } from "../../../generated/graphql";

export type UserPageProps = {
  data: UserQuery;
};

const UserPage: React.VFC<UserPageProps> = ({ data }) => {
  const history = useHistory();
  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    const BREAD_CRUMBS: Breadcrumb[] = [
      {
        label: "TOP",
      },
      {
        label: "設定",
      },
      {
        label: "教師スタッフ一覧",
      },
      {
        label: `${data.user.extra ? "【外部】" : "【内部】"}教師スタッフ詳細`,
      },
    ];
    setBreadcrumbItems(BREAD_CRUMBS);
    // eslint-disable-next-line
  }, []);

  const onClickEdit = () => {
    history.push({
      pathname: `/users/${data.user.id}/edit`,
      state: {
        from: `/users/${data.user.id}`,
      },
    });
  };

  const handleGoBack = useCallback(() => {
    history.push(`/users`);
  }, [history]);

  return (
    <div>
      <h1 data-testid="user-page-title" className="text-2xl mb-3">
        {data.user.extra ? "【外部】" : "【内部】"}教師スタッフ詳細
      </h1>
      <hr className="border-dark-gray border mb-2.5" />
      <UserDetailUI className="ml-5" user={data.user} />
      <div className="flex justify-center mt-20 mb-40 space-x-10">
        <ButtonUI
          data-testid="user-page-back-btn"
          buttonType="secondary"
          onClick={handleGoBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI data-testid="user-page-edit-btn" onClick={onClickEdit}>
          編集
        </ButtonUI>
      </div>
    </div>
  );
};

export default UserPage;
