/**
 * 例： getGraphQLEnumValue(
 *          ServiceKind,
 *          ServiceKinfDBValue.ElementarySchool)
 *       => "ElementarySchool" (GraphQL のスキーマで定義した enum のキー名)
 * ※ codegen で値を指定した enum をクライアントから
 * 　サーバーに送信する際は GraphQL スキーマで定義した enum のキーを渡す必要がある
 * @param enumObj
 * @param enumValue
 * @returns
 */
export const getGraphQLEnum = (
  enumObj: { [key: string]: any },
  enumValue: any
) => {
  for (const key of Object.keys(enumObj)) {
    if (enumObj[key] === enumValue) {
      return key;
    }
  }
  return undefined;
};
