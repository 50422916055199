import React from "react";

import { teachingMaterial } from "../..";
import { ButtonUI } from "../../../../../components/Button";
import { FileSave } from "../../../../../components/FileSave";
import LinkTextUI from "../../../../../components/LinkText/LinkText";
import { ModalUI } from "../../../../../components/Modal";

type Props = {
  title: string;
  visibility: boolean;
  onCancel: () => void;
  teachingMaterials: teachingMaterial[];
};

export const TeachingMaterialsModalUI = ({
  title,
  visibility,
  onCancel,
  teachingMaterials,
}: Props) => {
  return (
    <ModalUI
      data-testid="lessons-modal"
      visibility={visibility}
      className="w-full"
    >
      <div className="inline-block pt-4 pb-10 px-3">
        <h1
          className="text-center text-2xl text-subtitle tracking-wider"
          data-testid="lessons-modal-title"
        >
          {title}
        </h1>
        <hr className="border-under-line mt-5"></hr>
        <div className="mt-2 text-xl tracking-wider text-subtitle flex justify-center items-center">
          ファイル名をクリックしてダウンロードしてください。
        </div>

        {teachingMaterials.map((teachingMaterial, i) => (
          <div
            key={i}
            className="tracking-wider flex justify-center items-center mt-5 mt"
          >
            <LinkTextUI
              to="#"
              data-testid={`lessons-modal-teaching-material-${i}`}
              onClick={() =>
                FileSave &&
                FileSave(teachingMaterial.url, teachingMaterial.name)
              }
            >
              {teachingMaterial.name}
            </LinkTextUI>
          </div>
        ))}
        <div className="flex justify-center mt-9">
          <ButtonUI
            data-testid="lessons-modal-cancel"
            buttonType="secondary"
            onClick={() => onCancel()}
          >
            閉じる
          </ButtonUI>
        </div>
      </div>
    </ModalUI>
  );
};
