import React, {
  BaseSyntheticEvent,
  useState,
  useEffect,
  InputHTMLAttributes,
} from "react";

import Autosuggest from "react-autosuggest";
import "./Autosuggest.scss";

export type AutoSuggestDataType = {
  id?: string;
  systemId?: string;
  name: string;
};

export type AutosuggestUIProps = {
  onLazyQuery: (searchWord: string) => void;
  dataList: AutoSuggestDataType[];
  placeholder?: string;
  onTextChange?: (text: string) => void;
  searchOffset?: number;
} & InputHTMLAttributes<HTMLInputElement>;

const DEF_SEARCH_OFFSET: number = 3;

// TODO: 汎用化するならバリデーションとかもいずれはできるようにしたい
export const AutoSuggestUI: React.FC<AutosuggestUIProps> = ({
  onLazyQuery,
  dataList,
  placeholder,
  onTextChange,
  searchOffset,
  value,
  className = "",
  children,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value?.toString() || "");
  const [suggestions, setSuggestions] = useState<AutoSuggestDataType[]>([]);
  const offset = searchOffset || DEF_SEARCH_OFFSET;

  useEffect(() => {
    setSuggestions(dataList);
  }, [dataList]);

  const getSuggestionValue = (suggestion: AutoSuggestDataType): string => {
    const { name } = suggestion;
    return name;
  };

  const renderSuggestion = (suggestion: AutoSuggestDataType) => {
    if (!suggestion.systemId) return <div>{suggestion.name}</div>;
    // systemIdが渡されていた場合のみ表示形式を変更する
    return (
      <div>
        {suggestion.name}({suggestion.systemId})
      </div>
    );
  };

  const onChange = (
    event: BaseSyntheticEvent,
    { newValue }: { newValue: string }
  ) => {
    if (event) {
      setInputValue(newValue);
      onTextChange && onTextChange(newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestions([]);
    if (value.length < offset) {
      return;
    }
    onLazyQuery(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // 内部的に使っている<input>項目へ渡すprops
  const inputProps = {
    value: inputValue,
    placeholder,
    onChange,
    // @ts-ignore TODO: いずれはts-ignore使わないよう対応したい。
    "data-testid": rest["data-testid"],
  };
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};
