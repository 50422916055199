export enum PaymentService {
  /**
   * auかんたん決済
   */
  AuPayment = 0,
  /**
   * GMOクレジット決済
   */
  GmoPayment = 1,
}

export type PaymentServiceLabel = {
  id: string;
  value: number;
  label: string;
};

export const paymentServices: PaymentServiceLabel[] = [
  {
    id: "auPayment",
    value: PaymentService.AuPayment,
    label: "かんたん決済",
  },
  {
    id: "gmoPayment",
    value: PaymentService.GmoPayment,
    label: "クレカ決済",
  },
];
