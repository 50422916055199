import React from "react";

import dayjs from "dayjs";

import {
  ServiceInputFragment,
  UserDetailFragment,
  UsersEditPageFragment,
  ProfileEditFragment,
} from "../../generated/graphql";
import { roles, genders, statuses, DisplayRole } from "../../lib/constants";
import { PageType } from "../../lib/constants/pageType";
import { UserEdit } from "../../lib/types";
import { ButtonUI } from "../Button";
import { FormGroupUI } from "../FormGroup";
import { LabelUI } from "../Label";
import { TextUI } from "../Text";
import { UserAvatorUI } from "../UserAvator";

export interface UserConfirmUIProps {
  user?: UserDetailFragment | UsersEditPageFragment | ProfileEditFragment;
  userEdit: UserEdit;
  pageType:
    | PageType.ProfileConfirmPage
    | PageType.UsersExternalNewConfirmPage
    | PageType.UsersConfirmPage;
  onHandleSubmit: (event: React.FormEvent) => Promise<void>;
  avatorUrl: string | null;
  handleBack: () => void;
  services?: ServiceInputFragment[];
}

export const UserConfirmUI: React.VFC<UserConfirmUIProps> = ({
  user,
  userEdit,
  pageType,
  onHandleSubmit,
  avatorUrl,
  handleBack,
  services,
}) => {
  return (
    <div className="pb-20">
      <FormGroupUI>
        <LabelUI data-testid="users-confirm-title-userId">
          {(pageType === PageType.UsersExternalNewConfirmPage || user!.extra) &&
            "外部"}
          ユーザーID
        </LabelUI>
        <TextUI data-testid="users-confirm-userId">
          {pageType === PageType.UsersExternalNewConfirmPage
            ? userEdit.userId
            : user!.userId}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>名前</LabelUI>
        <TextUI data-testid="users-confirm-name">
          {pageType === PageType.UsersExternalNewConfirmPage
            ? `${userEdit.familyName} ${userEdit.givenName}`
            : `${user!.familyName} ${user!.givenName}`}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI>名前（フリガナ）</LabelUI>
        </div>
        <TextUI data-testid="users-confirm-name-kana">
          {userEdit.familyNameKana} {userEdit.givenNameKana}
        </TextUI>
      </FormGroupUI>
      <hr />
      {pageType !== PageType.UsersExternalNewConfirmPage && (
        <>
          <FormGroupUI>
            <LabelUI>サービス別権限</LabelUI>
            <TextUI data-testid="users-confirm-serviceKindIds">
              {pageType === PageType.UsersConfirmPage
                ? userEdit.serviceKindIds
                    ?.map((serviceKindId) => {
                      return services?.find(
                        (service) => service.id === serviceKindId
                      )?.shortName;
                    })
                    .join(", ")
                : user?.useableServiceKinds
                    .map((serviceKind) => serviceKind.shortName)
                    .join(", ")}
            </TextUI>
          </FormGroupUI>
          <hr />
        </>
      )}
      {pageType !== PageType.UsersExternalNewConfirmPage && (
        <>
          <FormGroupUI>
            <LabelUI>ロール別権限</LabelUI>

            <TextUI data-testid="users-confirm-roleIds">
              {userEdit
                .roleIds!.map(
                  (roleId) =>
                    roles.find((role: DisplayRole) => role.roleId === roleId)
                      ?.label
                )
                .join(", ")}
            </TextUI>
          </FormGroupUI>
          <hr />
        </>
      )}
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI>メールアドレス</LabelUI>
        </div>
        <TextUI data-testid="users-confirm-email">
          {pageType === PageType.UsersExternalNewConfirmPage
            ? userEdit.userId
            : userEdit.email}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI>電話番号</LabelUI>
        </div>
        <TextUI data-testid="users-confirm-tel">{userEdit.tel!}</TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI>性別</LabelUI>
        </div>
        <div data-testid="users-confirm-gender" className="flex space-x-10">
          {genders.find((gender) => gender.value === userEdit.gender)?.label}
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>生年月日</LabelUI>
        <TextUI data-testid="users-confirm-birthday">
          {userEdit.birthday && dayjs(userEdit.birthday).format("YYYY/MM/DD")}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>出身国</LabelUI>
        <TextUI data-testid="users-confirm-birthplace">
          {userEdit.birthplace}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI data-testid="users-confirm-title-yearsOfTeaching">
          {pageType === PageType.UsersExternalNewConfirmPage || user!.extra
            ? "教師歴"
            : "職歴"}
        </LabelUI>
        <TextUI data-testid="users-confirm-yearsOfTeaching">
          {userEdit.yearsOfTeaching}年
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>メッセージ</LabelUI>
        <TextUI data-testid="users-confirm-message" className="w-11/12">
          {userEdit.message}
        </TextUI>
      </FormGroupUI>

      <hr />
      <FormGroupUI>
        <LabelUI>自己紹介</LabelUI>
        <TextUI
          data-testid="users-confirm-selfIntroduction"
          className="w-11/12"
        >
          {userEdit.selfIntroduction}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>趣味</LabelUI>
        <TextUI data-testid="users-confirm-hobby" className="w-11/12">
          {userEdit.hobby}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>画像</LabelUI>
        <UserAvatorUI data-testid="users-confirm-avator" src={avatorUrl} />
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <LabelUI>ステータス</LabelUI>
        <TextUI data-testid="users-confirm-status">
          {statuses.find((status) => status.value === userEdit.status)?.label}
        </TextUI>
      </FormGroupUI>
      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI
          data-testid="users-confirm-back"
          buttonType="secondary"
          onClick={handleBack}
        >
          戻る
        </ButtonUI>
        <ButtonUI data-testid="users-confirm-update" onClick={onHandleSubmit}>
          登録
        </ButtonUI>
      </div>
    </div>
  );
};
