import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useFormContext } from "react-hook-form";

import { BadgeUI } from "../../../../components/Badge";
import { DateTimeInputUI } from "../../../../components/DateTimeInput";
import { LabelUI } from "../../../../components/Label";
import { RadioUI } from "../../../../components/Radio";
import { TextUI } from "../../../../components/Text";
import { ValidatableInputUI } from "../../../../components/ValidatableInput";
import { ValidatableTextareaUI } from "../../../../components/ValidatableTextarea";
import { CreateAnnouncementInput } from "../../../../generated/graphql";
import { AnnouncementKind } from "../../../../lib/constants";
import { validatePublishEndedAt } from "../../../../validation/regularExpressions";
dayjs.extend(isSameOrBefore);

export type AnnouncementEditUIProps = {
  testidPrefix?: string;
  systemId?: string;
};
export const AnnouncementEditUI: React.VFC<AnnouncementEditUIProps> = ({
  testidPrefix = "announcement-edit",
  systemId,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<CreateAnnouncementInput>();

  /**
   * 種別（お知らせ、メンテナンス、キャンペーン）
   */
  const [selectedKind, setSelectedKind] = useState<AnnouncementKind>();
  useEffect(() => {
    if (selectedKind === undefined) {
      const values = getValues();
      setSelectedKind(values.kind as AnnouncementKind);
    }
  }, [getValues, selectedKind]);
  const onKindSelectHandler = useCallback(
    (kind) => {
      setValue("kind", kind);
      setSelectedKind(kind);
    },
    [setValue]
  );

  return (
    <div className="divide-y divide-under-line border-b border-under-line">
      <div className="pb-5">
        <LabelUI className="text-lg font-bold" textColor="text-black">
          お知らせID
        </LabelUI>
        <TextUI data-testid={`${testidPrefix}-system-id`} className="mt-2.5">
          {systemId ? systemId : "登録後に発行されます。"}
        </TextUI>
      </div>
      <div className="py-5">
        <div className="flex flex-row items-center">
          <LabelUI className="text-lg font-bold" textColor="text-black">
            公開開始日時
          </LabelUI>
          <BadgeUI className="ml-2">必須</BadgeUI>
        </div>
        <p className="text-dark-gray mt-2.5">
          お客様画面にて公開日時として年月日が表示されます。
        </p>
        <DateTimeInputUI
          className="mt-2.5"
          {...register("publishStartedAt", { required: "入力必須項目です。" })}
          testidPrefix={`${testidPrefix}-publishStartedAt`}
          error={errors.publishStartedAt}
        />
        <p className={`mt-2 ${errors.publishStartedAt ? "ml-8" : ""}`}>
          半角数字入力
        </p>
      </div>
      <div className="py-5">
        <div className="flex flex-row items-center">
          <LabelUI className="text-lg font-bold" textColor="text-black">
            公開終了日時
          </LabelUI>
          <BadgeUI className="ml-2">必須</BadgeUI>
        </div>
        <DateTimeInputUI
          className="mt-2.5"
          {...register("publishEndedAt", {
            required: "入力必須項目です。",
            validate: (value) =>
              validatePublishEndedAt(getValues("publishStartedAt"), value),
          })}
          testidPrefix={`${testidPrefix}-publishEndedAt`}
          error={errors.publishEndedAt}
        />
        <p className={`mt-2 ${errors.publishEndedAt ? "ml-8" : ""}`}>
          半角数字入力
        </p>
      </div>
      <div className="py-5">
        <LabelUI className="text-lg font-bold" textColor="text-black">
          種別
        </LabelUI>
        <div className="mt-2.5 flex flex-row space-x-4">
          <RadioUI
            name={`${testidPrefix}-kind`}
            data-testid={`${testidPrefix}-kind-${AnnouncementKind.News}`}
            checked={selectedKind === AnnouncementKind.News}
            value={AnnouncementKind.News}
            onChange={() => onKindSelectHandler(AnnouncementKind.News)}
          >
            お知らせ
          </RadioUI>
          <RadioUI
            name={`${testidPrefix}-kind`}
            data-testid={`${testidPrefix}-kind-${AnnouncementKind.Maintenance}`}
            checked={selectedKind === AnnouncementKind.Maintenance}
            value={AnnouncementKind.Maintenance}
            onChange={() => onKindSelectHandler(AnnouncementKind.Maintenance)}
          >
            メンテナンス
          </RadioUI>
          <RadioUI
            name={`${testidPrefix}-kind`}
            data-testid={`${testidPrefix}-kind-${AnnouncementKind.Campaign}`}
            checked={selectedKind === AnnouncementKind.Campaign}
            value={AnnouncementKind.Campaign}
            onChange={() => onKindSelectHandler(AnnouncementKind.Campaign)}
          >
            キャンペーン
          </RadioUI>
        </div>
      </div>
      <div className="py-5">
        <div className="flex flex-row items-center">
          <LabelUI className="text-lg font-bold" textColor="text-black">
            お知らせ件名
          </LabelUI>
          <BadgeUI className="ml-2">必須</BadgeUI>
        </div>
        <div className="mt-2.5 ">
          <ValidatableInputUI
            className="w-full"
            registerParams={{
              register,
              label: "subject",
              error: errors.subject,
              conditions: {
                required: "入力必須項目です。",
                maxLength: {
                  value: 50,
                  message: "50文字以内で入力してください。",
                },
              },
            }}
            data-testid={`${testidPrefix}-subject`}
            errorIdPrefix={`${testidPrefix}-subject-error`}
          />
        </div>
      </div>
      <div className="py-5">
        <div className="flex flex-row items-center">
          <LabelUI className="text-lg font-bold" textColor="text-black">
            お知らせ内容
          </LabelUI>
          <BadgeUI className="ml-2 text-sm">必須</BadgeUI>
        </div>
        <div className="mt-2.5 ">
          <ValidatableTextareaUI
            className="mt-2.5 w-full"
            rows={18}
            registerParams={{
              register,
              label: "body",
              error: errors.body,
              conditions: {
                required: "入力必須項目です。",
                maxLength: {
                  value: 10000,
                  message: "1万文字以内で入力してください。",
                },
              },
            }}
            data-testid={`${testidPrefix}-body`}
            errorIdPrefix={`${testidPrefix}-body-error`}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementEditUI;
