import React, { MouseEventHandler, useEffect } from "react";

import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button/Button";
import { DisplayCourse, DisplayClass } from "../../../lib/constants/classes";
import { PageType } from "../../../lib/constants/pageType";
import { ClassConfirmUI } from "../components/ClassConfirm/ClassConfirm";

const BREAD_CRUMBS: Breadcrumb[] = [
  {
    label: "TOP",
  },
  {
    label: "商品",
  },
  {
    label: "コース一覧",
  },
  {
    label: "クラス詳細",
  },
  {
    label: "クラス編集",
  },
  {
    label: "クラス編集確認",
  },
];

export type ClassEditConfirmPageProps = {
  isLoading: boolean;
  course: DisplayCourse;
  clazz: DisplayClass;
  onConfirm: () => Promise<void>;
  handleBack: MouseEventHandler<HTMLButtonElement>;
};

const ClassEditConfirmPage: React.FC<ClassEditConfirmPageProps> = ({
  isLoading,
  course,
  clazz,
  onConfirm,
  handleBack,
}) => {
  const { setBreadcrumbItems } = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  return (
    <div>
      <h1 className="text-2xl mb-3">クラス編集確認</h1>
      <hr className="border-dark-gray border mb-2.5"></hr>
      <div className=" ml-5">
        <ClassConfirmUI
          course={course}
          clazz={clazz}
          pageType={PageType.ClassEditConfirmPage}
        ></ClassConfirmUI>
      </div>
      <div className="flex justify-center space-x-10 m-12">
        <ButtonUI data-testid="clazz-new-confirm-back" onClick={handleBack}>
          戻る
        </ButtonUI>
        <ButtonUI
          disabled={isLoading}
          data-testid="clazz-new-confirm-submit"
          onClick={onConfirm}
        >
          登録
        </ButtonUI>
      </div>
    </div>
  );
};

export default ClassEditConfirmPage;
