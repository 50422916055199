import { ServiceKind } from "../../../generated/graphql";
import { FieldsUI } from "./components/Fields";
import { LevelsUI } from "./components/Levels";
import { OrganizersUI } from "./components/Organizers";
import { SubjectsUI } from "./components/Subjects";
import { VideoTagsUI } from "./components/VideoTags";

export enum MasterType {
  Subject = "subject",
  Level = "level",
  Field = "field",
  VideoTag = "videoTag",
  Organizer = "organizer",
}

export type Master = {
  masterType: MasterType;
  text: string;
  availableServices: ServiceKind[];
  component: React.FC;
  props?: any;
};

export const MASTERS: Master[] = [
  {
    masterType: MasterType.Subject,
    availableServices: [
      ServiceKind.Aeon,
      ServiceKind.ElementarySchool,
      ServiceKind.LiberalArts,
    ],
    text: "商品カテゴリ",
    component: SubjectsUI,
    props: { "data-testid": "master-subject" },
  },
  {
    masterType: MasterType.Level,
    availableServices: [
      ServiceKind.Aeon,
      ServiceKind.ElementarySchool,
      ServiceKind.LiberalArts,
    ],
    text: "レベル",
    component: LevelsUI,
    props: { "data-testid": "master-level" },
  },
  {
    masterType: MasterType.Field,
    availableServices: [ServiceKind.LiberalArts],
    text: "分野",
    component: FieldsUI,
    props: { "data-testid": "master-field" },
  },
  {
    masterType: MasterType.VideoTag,
    availableServices: [ServiceKind.LiberalArts],
    text: "動画タグ",
    component: VideoTagsUI,
    props: { "data-testid": "master-videoTag" },
  },
  {
    masterType: MasterType.Organizer,
    availableServices: [ServiceKind.ElementarySchool],
    text: "事業者",
    component: OrganizersUI,
    props: { "data-testid": "master-organizer" },
  },
];
