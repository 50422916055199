import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";

import { ApolloError, NetworkStatus } from "@apollo/client";
import * as History from "history";
import { Prompt } from "react-router-dom";

import { ButtonUI } from "../../../../../components/Button";
import { ErrorModalUI } from "../../../../../components/ErrorModal";
import { PaginationUI } from "../../../../../components/Pagination";
import {
  CreateFieldInput,
  Field,
  UpdateFieldInput,
  useCreateFieldMutation,
  useFieldsLazyQuery,
  useUpdateFieldMutation,
} from "../../../../../generated/graphql";
import { useErrorRouter } from "../../../../../hooks/errorRouter";
import { ErrorType } from "../../../../../lib/constants/error";
import { FieldEditModalUI, FieldEditMode } from "../FieldEditModal";
import { MasterTableUI, Column } from "../MasterTable";
import styles from "./Fields.module.scss";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as PlusBlueIcon } from "./plusBlue.svg";

export type FieldsUIProps = {} & HTMLAttributes<HTMLDivElement>;

type ModalControll = {
  visible: boolean;
  mode: FieldEditMode;
  field?: Field;
};

const columns: Column[] = [
  {
    title: "コード",
    name: "id",
    headerClassName: "text-center w-1/12",
    rowClassName: "text-center",
  },
  {
    title: "URL表記",
    name: "alias",
    headerClassName: "text-left pl-2.5 w-2/12",
    rowClassName: "text-left pl-2.5 ",
  },
  {
    title: "メインタイトル",
    name: "name",
    headerClassName: "text-left pl-2.5 w-2/12",
    rowClassName: "text-left pl-2.5 ",
  },
  {
    title: "サブタイトル",
    name: "subTitle",
    headerClassName: "text-left pl-2.5 w-2/12",
    rowClassName: "text-left pl-2.5",
  },
  {
    title: "説明文",
    name: "summary",
    headerClassName: "text-left pl-2.5 w-4/12",
    rowClassName: "text-left pl-2.5 py-4",
  },
];

export const FieldsUI: React.FC<FieldsUIProps> = (props) => {
  const handleErrorRouter = useErrorRouter();
  const limit = 20;
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  // クエリー実行トリガー
  const [execQueryTrigger, setExecQueryTrigger] = useState<boolean>(true);
  // クエリー・パラメター
  const [offset, setOffset] = useState(0);
  const [modalControll, setModalControll] = useState<ModalControll>({
    visible: false,
    mode: FieldEditMode.CREATE,
    field: undefined,
  });

  const [loadFields, { data, loading, networkStatus }] = useFieldsLazyQuery();

  // レベル追加/編集モーダルの表示制御
  const setModalVisible = useCallback(
    (visible: boolean) => {
      setModalControll((prev) => ({
        ...prev,
        visible,
      }));
    },
    [setModalControll]
  );

  const handleOnMutationError = useCallback(
    (error: ApolloError) => {
      if (
        error.graphQLErrors[0]?.extensions?.code ===
        ErrorType.DuplicateFieldAlias
      ) {
        setIsOpenErrorModal(true);
        return;
      }
      handleErrorRouter(error);
    },
    [setIsOpenErrorModal, handleErrorRouter]
  );

  const handleOnMutationCompleted = useCallback(() => {
    setModalVisible(false);
    setExecQueryTrigger(true);
  }, [setModalVisible, setExecQueryTrigger]);

  const [createField] = useCreateFieldMutation({
    onError: (error) => handleOnMutationError(error),
    onCompleted: handleOnMutationCompleted,
  });

  const [updateField] = useUpdateFieldMutation({
    onError: (error) => handleOnMutationError(error),
    onCompleted: handleOnMutationCompleted,
  });

  const handleOnCreateField = useCallback(
    (input: CreateFieldInput) => {
      createField({
        variables: {
          input,
        },
      });
    },
    [createField]
  );

  const handleOnUpdateField = useCallback(
    (input: UpdateFieldInput) => {
      updateField({
        variables: {
          input,
        },
      });
    },
    [updateField]
  );

  // ブラウザバック時にモーダルを非表示化
  const handleBlockBack = useCallback(
    (location: History.Location, action: History.Action) => {
      if (location.pathname === "/masters" && action === "POP") {
        setModalVisible(false);
        return false;
      }
      return true;
    },
    [setModalVisible]
  );

  const onClickAddNewFieldBtn = useCallback(() => {
    setModalControll({
      visible: true,
      mode: FieldEditMode.CREATE,
      field: undefined,
    });
  }, [setModalControll]);

  const onClickEditFieldBtn = useCallback(
    (field: Field) => {
      setModalControll({
        visible: true,
        mode: FieldEditMode.UPDATE,
        field,
      });
    },
    [setModalControll]
  );

  // ページネーションの前ページボタンクリックハンドラー
  const onClickPrevios = useCallback(() => {
    setOffset(offset - limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションの次ページボタンクリックハンドラー
  const onClickNext = useCallback(() => {
    setOffset(offset + limit);
    setExecQueryTrigger(true);
  }, [offset, limit]);

  // ページネーションのページ指定ボタンクリックハンドラー
  const onClickPaginationItem = useCallback(
    (index: number) => {
      setOffset(index * limit);
      setExecQueryTrigger(true);
    },
    [limit]
  );

  useEffect(() => {
    if (execQueryTrigger) {
      loadFields({
        variables: {
          offset: offset,
          limit,
        },
        onError: handleErrorRouter,
      });
      setExecQueryTrigger(false);
    }
  }, [execQueryTrigger, loadFields, handleErrorRouter, offset]);

  return (
    <div {...props}>
      <div className="flex mt-5 justify-end">
        <ButtonUI
          data-testid="field-new-btn"
          onClick={onClickAddNewFieldBtn}
          size="ll"
          className={`inline-flex items-center justify-items-center justify-center ${styles.btnAdd}`}
        >
          <PlusBlueIcon className={`mr-2.5 w-4 h-4 ${styles.iconActive}`} />
          <PlusIcon className={`mr-2.5 w-4 h-4 ${styles.icon}`} />
          追加
        </ButtonUI>
      </div>
      {(loading || networkStatus === NetworkStatus.refetch) && (
        <div className="mt-4">Loading...</div>
      )}
      {!loading && data && data.fields!.totalCount > 0 ? (
        <>
          <p
            className="tracking-wider mt-2 mb-3"
            data-testid="fields-display-count"
          >{`全${data.fields!.totalCount}件中${offset + 1}件〜${
            offset + data.fields!.nodes.length
          }件を表示`}</p>
          <MasterTableUI
            rows={data.fields.nodes}
            columns={columns}
            willEdit={onClickEditFieldBtn}
          ></MasterTableUI>
          {data.fields!.totalCount > 0 && (
            <PaginationUI
              className="mt-20 mb-20"
              selectedIndex={Math.ceil(offset / limit)}
              count={Math.ceil(data.fields.totalCount / limit)}
              onClickPrevios={onClickPrevios}
              onClickNext={onClickNext}
              onClickItem={onClickPaginationItem}
            ></PaginationUI>
          )}
        </>
      ) : (
        <div className="bg-gray-200 text-center mt-5">
          <p
            className="text-lg font-bold mb-2.5"
            data-testid="no-field-exists-message"
          >
            該当する分野がありません。
          </p>
          <p>検索条件に該当する分野がありませんでした。</p>
          <p>内容をご確認のうえ、再度検索を行ってください。</p>
        </div>
      )}
      {modalControll.visible && (
        <FieldEditModalUI
          visibility={modalControll.visible}
          onCreate={handleOnCreateField}
          onCancel={() => setModalVisible(false)}
          onUpdate={handleOnUpdateField}
          editMode={modalControll.mode}
          field={modalControll.field}
        />
      )}
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        visibility={isOpenErrorModal}
        onClick={() => setIsOpenErrorModal(false)}
      >
        <p>同じURL表記が既に登録されています。</p>
        <p>別のURL表記を設定してください。</p>
      </ErrorModalUI>
      <Prompt when={true} message={handleBlockBack} />{" "}
    </div>
  );
};
