import React from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { ContactHistory } from "../../../../generated/graphql";
import { getStatusName, getServiceKind } from "../../../../lib/utils/contacts";
dayjs.locale(ja);

export type ContactHistoryTableRowProps = {
  contactHistory: ContactHistory | null | undefined;
};

const ContactHistoryTableRowUI: React.FC<ContactHistoryTableRowProps> = ({
  contactHistory,
}) => {
  // ステータスのメッセージ
  let changeStatusMsg: string =
    "「" + getStatusName(contactHistory?.status) + "」";
  if (contactHistory?.oldStatus) {
    changeStatusMsg =
      "「" +
      getStatusName(contactHistory?.oldStatus) +
      "」から" +
      changeStatusMsg;
  }

  // サービス種別のメッセージ
  let changeServiceKindMsg: string =
    "「" + getServiceKind(contactHistory?.serviceKind) + "」";
  if (contactHistory?.oldServiceKind) {
    changeServiceKindMsg =
      "「" +
      getServiceKind(contactHistory?.oldServiceKind) +
      "」から" +
      changeServiceKindMsg;
  }

  return (
    <div
      className="bg-light-blue p-3 border-b border-under-line"
      data-testid="contact-history-table-row"
    >
      <div className="grid grid-cols-4 divide-x-2 divide-under-line">
        <div className="col-span-1">
          <div>
            <p
              className="font-bold mb-1"
              data-testid="contact-history-supporter-name"
            >
              {`${contactHistory?.supporterFamilyName} ${contactHistory?.supporterGivenName}`}
            </p>
            <p
              className="text-sm"
              data-testid="contact-history-supporter-user-id"
            >
              {contactHistory?.supporterEmail}
            </p>
          </div>
        </div>
        <div className="col-span-3">
          <div className="pl-2">
            <p
              className="text-base mb-1"
              style={{ whiteSpace: "pre-line" }}
              data-testid="contact-history-memo"
            >
              {contactHistory?.memo
                ? contactHistory.memo
                : `対応状況を${changeStatusMsg}に変更`}
            </p>
            {contactHistory?.memo &&
              contactHistory.oldStatus !== contactHistory.status && (
                <p
                  className="text-base text-dark-gray mb-1"
                  style={{ whiteSpace: "pre-line" }}
                  data-testid="contact-history-memo-sub"
                >
                  {`対応状況を${changeStatusMsg}に変更`}
                </p>
              )}
            {contactHistory?.oldServiceKind !== contactHistory?.serviceKind && (
              <p
                className="text-base text-dark-gray mb-1"
                style={{ whiteSpace: "pre-line" }}
                data-testid="contact-history-memo-servicekind"
              >
                {`サービス種別を${changeServiceKindMsg}に変更`}
              </p>
            )}
            <p
              className="text-dark-gray text-right text-sm"
              data-testid="contact-history-date"
            >
              {dayjs(contactHistory?.createdAt).format("YYYY/MM/DD HH:mm")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHistoryTableRowUI;
