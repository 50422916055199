import React, { InputHTMLAttributes } from "react";

import { RegisterParams } from "../common-interfaces";

export type ValidatablePriceValueInputUIProps = {
  registerParams: RegisterParams;
} & InputHTMLAttributes<HTMLInputElement>;

export const ValidatablePriceValueInputUI: React.FC<
  ValidatablePriceValueInputUIProps
> = ({ className = "", registerParams, children, ...rest }) => {
  return (
    <input
      className={`h-12 leading-5 pl-4 tracking-wider border rounded ${className} ${
        registerParams.error ? "border-error" : ""
      }`}
      {...registerParams.register(registerParams.label, {
        ...registerParams.conditions,
      })}
      {...rest}
    />
  );
};
