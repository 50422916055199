import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  ContBillPaymentMismatchesFilterInput,
  useContBillPaymentMismatchesQuery,
  ContBillPaymentMismatchResultsFragment,
} from "../../../generated/graphql";
import { useErrorRouter } from "../../../hooks/errorRouter";
import { searchSettings } from "../../../lib/constants";
import ContBillPaymentMismatchesPage from "./ContBillPaymentMismatchesPage";

const ContBillPaymentMismatchesPageContainer: React.VFC = () => {
  const [limit, setLimit] = useState<number>(searchSettings.limit);
  const [contBillPaymentMismatches, setContBillPaymentMismatches] =
    useState<ContBillPaymentMismatchResultsFragment>({
      totalCount: -1,
      nodes: [],
    });

  const [filter, setFilter] = useState<ContBillPaymentMismatchesFilterInput>({
    continueAccountId: "",
  });
  const handleErrorRouter = useErrorRouter();
  const { data, loading, refetch } = useContBillPaymentMismatchesQuery({
    onCompleted: () => {},
    variables: {
      filter,
      limit,
    },
    onError: handleErrorRouter,
  });

  useEffect(() => {
    if (data?.contBillPaymentMismatches) {
      setContBillPaymentMismatches(data?.contBillPaymentMismatches);
    }
  }, [data?.contBillPaymentMismatches, setContBillPaymentMismatches]);

  const onDisplayMore: MouseEventHandler = useCallback(async () => {
    setLimit(limit + searchSettings.offset);
    await refetch({
      filter,
      limit: limit + searchSettings.offset,
    });
  }, [refetch, limit, filter]);

  const onSearch = useCallback(
    async (inputs: ContBillPaymentMismatchesFilterInput) => {
      setFilter(inputs);
      setLimit(searchSettings.limit);
      await refetch({
        filter,
        limit,
      });
    },
    [refetch, filter, limit]
  );

  return (
    <ContBillPaymentMismatchesPage
      onSearch={onSearch}
      loading={loading}
      contBillPaymentMismatches={contBillPaymentMismatches}
      onDisplayMore={onDisplayMore}
    />
  );
};
export default ContBillPaymentMismatchesPageContainer;
