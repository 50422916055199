import React, { useCallback } from "react";

import dayjs from "dayjs";
import ja from "dayjs/locale/ja";

import { BadgeUI } from "../../../../components/Badge";
import { FormGroupUI } from "../../../../components/FormGroup";
import { LabelUI } from "../../../../components/Label";
import { TextUI } from "../../../../components/Text";
import {
  DisplayClass,
  DisplayCourse,
  DisplayLesson,
  eventLocations,
  eventLocationType,
  Teacher,
} from "../../../../lib/constants/classes";
import { PageType } from "../../../../lib/constants/pageType";
dayjs.locale(ja);

export interface ClassConfirmUIProps {
  pageType: PageType;
  course: DisplayCourse;
  clazz: DisplayClass;
}

export const ClassConfirmUI: React.VFC<ClassConfirmUIProps> = ({
  pageType,
  course,
  clazz,
}) => {
  const getScheduledDate = useCallback((startedAt: Date, endedAt: Date) => {
    return (
      startedAt &&
      endedAt &&
      `${dayjs(startedAt).format("YYYY/MM/DD(dd) HH:mm")} - ${dayjs(
        endedAt
      ).format("HH:mm")}`
    );
  }, []);

  return (
    <div>
      <FormGroupUI>
        <LabelUI className="text-lg">システムID</LabelUI>
        <TextUI data-testid="clazz-system-id">
          {pageType === PageType.ClassNewConfirmPage
            ? "登録後に発行されます。"
            : clazz.systemId}
        </TextUI>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">システムID:コース名</LabelUI>
          {pageType === PageType.ClassNewConfirmPage && <BadgeUI>必須</BadgeUI>}
        </div>
        <div className="flex">
          <TextUI data-testid="course-system-id-course-name">
            {course.systemId}： {course.name}
          </TextUI>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">クラス名</LabelUI>
        </div>
        <div className="flex">
          <TextUI data-testid="clazz-name">{clazz.name}</TextUI>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">クラスID</LabelUI>
        </div>
        <div className="flex">
          <TextUI data-testid="any-clazz-id">{clazz.anyClassId}</TextUI>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">開催場所</LabelUI>
          <BadgeUI>必須</BadgeUI>
        </div>
        <div data-testid="clazz-event-location" className="flex">
          {clazz.eventLocation}
        </div>
      </FormGroupUI>
      <hr />
      {pageType === PageType.ClassNewConfirmPage &&
      clazz.eventLocation === eventLocations[eventLocationType.Zoom] ? (
        <FormGroupUI>
          <div className="flex flex-row items-end space-x-2.5">
            <LabelUI className="text-lg">ZoomURL自動発行設定</LabelUI>
          </div>
          <div data-testid="auto-create-zoom-url" className="flex">
            URLを発行{clazz.autoCreateZoomURL ? "する" : "しない"}
          </div>
        </FormGroupUI>
      ) : (
        ""
      )}
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">定員</LabelUI>
          <BadgeUI>必須</BadgeUI>
        </div>
        <div data-testid="clazz-capacity" className="flex">
          <TextUI>{clazz.capacity}</TextUI>
          <TextUI className="ml-2">名</TextUI>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <div className="flex flex-row items-end space-x-2.5">
          <LabelUI className="text-lg">申込者数表示</LabelUI>
        </div>
        <div data-testid="clazz-applicant-count-visibility" className="flex">
          <TextUI>{clazz.applicantCountVisibility ? "表示" : "非表示"}</TextUI>
        </div>
      </FormGroupUI>
      <hr />
      <FormGroupUI>
        <h2 className="text-subtitle text-xl">授業設定</h2>
        {clazz?.lessons.map((lesson: DisplayLesson, lessonIndex: number) => (
          <div key={lesson.lessonMasterId} className="pb-4">
            <p
              data-testid={`lesson-order-${lessonIndex}`}
              className="text-subtitle text-xl"
            >
              第{lesson.lessonMaster.order}回
            </p>
            <div className="flex flex-col space-y-3 ml-4 mt-4">
              <LabelUI className="text-lg">授業ID</LabelUI>
              <TextUI
                className="ml-2.5 mt-2"
                data-testid={`lesson-id-${lessonIndex}`}
              >
                {lesson.anyLessonId}
              </TextUI>
              <div className="mt-2.5">
                <LabelUI className="text-lg">授業名</LabelUI>
                <TextUI
                  className="ml-2.5 mt-2"
                  data-testid={`lesson-name-${lessonIndex}`}
                >
                  {lesson.lessonMaster.name}
                </TextUI>
              </div>
              <div className="mt-2.5">
                <div className="flex flex-row items-end space-x-2.5">
                  <LabelUI className="text-lg">日時</LabelUI>
                  <BadgeUI>必須</BadgeUI>
                </div>
                <TextUI
                  className="ml-2.5 mt-2"
                  data-testid={`lesson-scheduled-date-${lessonIndex}`}
                >
                  {getScheduledDate(
                    lesson.startedAt || dayjs().toDate(),
                    lesson.endedAt || dayjs().toDate()
                  )}
                </TextUI>
              </div>
              <div className="mt-2.5">
                <div className="flex flex-row items-end space-x-2.5">
                  <LabelUI className="text-lg">教師</LabelUI>
                </div>
                {lesson.teachers.map(
                  (teacher: Teacher, teacherIndex: number) => (
                    <div className="mt-2.5" key={teacherIndex}>
                      <TextUI
                        className="ml-4"
                        data-testid={`lesson-${lessonIndex}-teacher-name-${teacherIndex}`}
                      >
                        {teacher.familyName} {teacher.givenName}
                        {teacher.extra && "【外部】"}
                      </TextUI>
                      <TextUI
                        data-testid={`lesson-${lessonIndex}-teacher-id-${teacherIndex}`}
                        className="ml-4"
                      >
                        ID: {teacher.userId}
                      </TextUI>
                    </div>
                  )
                )}
              </div>
              <div className="mt-2.5">
                {clazz.eventLocation ===
                  eventLocations[eventLocationType.Zoom] && (
                  <LabelUI className="text-lg">授業URL</LabelUI>
                )}
                <TextUI
                  className="ml-2.5 mt-2"
                  data-testid={`lesson-url-${lessonIndex}`}
                >
                  {pageType === PageType.ClassNewConfirmPage &&
                  clazz.autoCreateZoomURL
                    ? "クラス追加完了後、自動的に発行されます。"
                    : ""}
                  {pageType === PageType.ClassNewConfirmPage &&
                  !clazz.autoCreateZoomURL
                    ? "クラス編集画面から入力してください。"
                    : ""}
                  {pageType === PageType.ClassEditConfirmPage &&
                  clazz.eventLocation === eventLocations[eventLocationType.Zoom]
                    ? lesson.lessonUrl
                    : ""}
                </TextUI>
              </div>
            </div>
          </div>
        ))}
      </FormGroupUI>
    </div>
  );
};
