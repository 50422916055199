import React, { useCallback, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { CompletedModalUI } from "../../../components/CompletedModal";
import { ErrorModalUI } from "../../../components/ErrorModal";
import { LoadingUI } from "../../../components/Loading";
import {
  CreateAnnouncementInput,
  useCreateAnnouncementMutation,
} from "../../../generated/graphql";
import { useErrorState } from "../../../hooks/errorRouter";
import AnnouncementsNewConfirmPage from "./AnnouncementsNewConfirmPage";

const AnnouncementsNewConfirmPageContainer: React.VFC = () => {
  const { state } = useLocation<CreateAnnouncementInput>();
  const history = useHistory();
  const { error, clearError, onError } = useErrorState();
  const [visibility, setVisibility] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [createAnnouncement] = useCreateAnnouncementMutation({
    variables: {
      input: state,
    },
    onError: (e) => {
      onError(e);
      setUpdating(false);
    },
    onCompleted: () => {
      setUpdating(false);
      setVisibility(true);
    },
  });

  const handleOnRegister = useCallback(() => {
    setUpdating(true);
    createAnnouncement();
  }, [setUpdating, createAnnouncement]);

  const handleOnBack = useCallback(() => {
    history.replace(`/announcement/new`, state);
  }, [history, state]);

  const handleOnClickModalButton = useCallback(() => {
    history.push(`/announcements`);
  }, [history]);

  const handleOnClickErrorModalButton = useCallback(() => {
    clearError();
  }, [clearError]);

  if (updating) {
    return <LoadingUI title="送信中" />;
  }

  return (
    <>
      <CompletedModalUI
        title="登録完了"
        btnTitle="お知らせ一覧へ"
        description="お知らせの新規登録が完了しました。"
        visibility={visibility}
        onClick={handleOnClickModalButton}
      />
      <ErrorModalUI
        title="登録に失敗しました"
        btnTitle="閉じる"
        onClick={handleOnClickErrorModalButton}
        visibility={!!error}
      >
        <p>お知らせの登録に失敗しました。</p>
        <p>しばらく時間をおいてから、もう一度お試しください。</p>
      </ErrorModalUI>
      <AnnouncementsNewConfirmPage
        input={state}
        onBack={handleOnBack}
        onRegister={handleOnRegister}
      />
    </>
  );
};

export default AnnouncementsNewConfirmPageContainer;
